import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { CommitteeService } from '@services/Committee.service';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import { CoreService } from '@services/CoreService';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import { constants } from '@shared/config/constants';
import { Input } from '@angular/core';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';

export interface CommitteeElement {
  committeeId: number;
  categories: string;
  type: string;
  title: string;
  status: string;
}

@Component({
  selector: 'app-committeetemplate',
  templateUrl: './committeetemplate.component.html',
  styleUrls: ['./committeetemplate.component.css'],
})
export class CommitteetemplateComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constant = constants;

  showFiller = false;
  numberOfMeetings = 0;
  ELEMENT_DATA: CommitteeElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = [
    'category',
    'committeeType',
    'title',
    'is_published',
    'Action',
  ];
  listData = new MatTableDataSource<CommitteeElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<CommitteeElement>(true, []);
  tooltip: string = '';

  @Input() modulefor: string;
  @Input() allowAdd: boolean;
  @Input() showEdit: boolean = true;
  @Input() showDelete: boolean = true;
  @Input() showTemplate: boolean = false;
  @Input() allowsearch: boolean = true;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<CommitteeElement>;

  resource = language;
  CATEGORY_LIST = [];
  TYPE_LIST: any;

  constructor(
    private committeeService: CommitteeService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private http: HttpClient,
    private coreservice: CoreService
  ) {
    //this.listData = new MatTableDataSource<CommitteeElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<CommitteeElement>(true, []);

    this.getAllCategories();
  }

  ngOnInit(): void {
    console.log('>>>>>>>>>>>>>>>>>>>>', this.allowAdd);
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.TYPE_LIST = constants.committee_categoryies;
    console.log(this.TYPE_LIST);
    if (this.modulefor == 'template') {
      this.getAllCommitteeTemplates();
      this.displayedColumns.splice(3, 1);
      //  this.tooltip='Choose from the committe templates or create a new committee';
    } else {
      this.getAllCommittee();
      this.tooltip =
        'Choose from the committe templates or create a new committee';
    }

    this.table.renderRows();
  }

  getAllCommitteeTemplates() {
    this.committeeService.getCommitteeTemplates().subscribe(
      (response) => {
        const result = response.body;
        this.listData.data = response.body['data'] as CommitteeElement[];
        this.listData.data.forEach((item) => {
          item['category'] = item['categories']['title'];
          item['committeeType'] =
            this.constant.committee_categoryies[item['type']];
        });
        this.table.renderRows();
      },
      (err) => {}
    );
  }

  getAllCommittee() {
    this.committeeService.getCommittees().subscribe(
      (response) => {
        const result = response.body;
        console.log('result  ', result);

        this.listData.data = response.body['data'] as CommitteeElement[];
        this.listData.data.forEach((item) => {
          item['category'] = item['categories']['title'];
          item['committeeType'] =
            this.constant.committee_categoryies[item['type']];
        });
        this.table.renderRows();
      },
      (err) => {
        console.log('err', err);
      }
    );
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listData.data.forEach((row) => this.selection.select(row));
  }

  addrole() {
    if (this.modulefor == 'template')
      this.router.navigate(['/committee/add/s1'], {
        queryParams: { template: 1 },
      });
    else
      this.router.navigate(['/committee/add/s1'], {
        queryParams: { template: 0 },
      });
  }

  edit(role) {
    if (this.modulefor == 'template')
      this.router.navigate(['/committee/add/s1'], {
        queryParams: { id: role._id, template: 1 },
      });
    else
      this.router.navigate(['/committee/add/s1'], {
        queryParams: { id: role._id, template: 0 },
      });
  }

  async onDelete(row) {
    this.committeeService
      .getCountOfCommitteeMeetingsByCommitteeId(row._id)
      .subscribe(
        (response: any) => {
          this.numberOfMeetings = response.data.totalCount;
          if (this.numberOfMeetings > 0) {
            this.coreservice.success(
              'Please delete the committee meetings belonging to this committee before continuing. You currently have ' +
                this.numberOfMeetings +
                ' committee meeting(s).'
            );
            // this.coreservice.openAcceptDialog(
            //   'Please the committee meetings belonging to this committee before continuing. You currently have ' +
            //     this.numberOfMeetings +
            //     ' meeting(s).',
            //   'Okay',
            //   null
            // );
          } else {
            this.coreservice
              .openConfirmDialog(this.resource.message('COMMITTEE_DELETE'))
              .afterClosed()
              .subscribe((res) => {
                if (res) {
                  this.committeeService.removeCommittee(row).subscribe(
                    (response) => {
                      const result = response;
                      this.getAllCommittee();
                      this.coreservice.error(
                        this.resource.message('COMMITTEE_DELETED')
                      );
                    },
                    (err) => {}
                  );
                }
              });
          }
        },
        (err) => {
          console.log('errrrrrrr', err);
        }
      );
  }

  onPublic(committee) {
    const new_committee = committee;
    new_committee['_id'] = '';
    new_committee['title'] = new_committee['title'] + '    ';

    console.log('committee', committee);
    console.log('this.CATEGORY_LIST', this.CATEGORY_LIST);

    for (var i = 0; i < this.CATEGORY_LIST.length; i++) {
      if (
        this.CATEGORY_LIST[i]['title'] == new_committee['categories']['title']
      ) {
        new_committee['categories'] = this.CATEGORY_LIST[i];
      }
    }

    //delete committee["categories"];
    const commitee = { ...committee, title: new_committee['title'].trim() + ' UNIQUE ' + Date.now() + ' COMMITTEE', is_published: false}
    console.log(new_committee);
    this.committeeService.addCommittee(commitee).subscribe((response) => {
      const result = response.body;
      this.router.navigate(['committee/add/s1'], {
        queryParams: { id: result['data']['_id'], template: 0 },
      });
    });
  }

  getAllCategories() {
    this.committeeService.getCommitteeCategories('').subscribe(
      (response) => {
        const result = response.body;
        this.CATEGORY_LIST = response.body['data'];
      },
      (err) => {}
    );
  }

  closeMyMenu() {
    this.trigger.closeMenu();
  }

  filterData(ev, col, val) {
    if (ev.checked) {
      col = String(col);

      // let value = { [col]: val };
      // this.filterValues.push(value);
      if (col === 'category') {
        this.filterValues = {
          category: [...this.filterValues.category, val.toLowerCase()],
          type: [...this.filterValues.type],
        };
      } else if (col === 'type') {
        this.filterValues = {
          category: [...this.filterValues.category],
          type: [...this.filterValues.type, val.toLowerCase()],
        };
      }
    } else {
      const index = this.filterValues[col].indexOf(val);
      this.filterValues[col].splice(index, 1);
    }

    console.log('filterValues', this.filterValues);

    this.setupFilter();

    this.applyFilter(val);
  }

  stoppro($event) {
    $event.stopPropagation();
  }

  setupFilter() {
    this.listData.filterPredicate = (d: any, filter: string) => {
      const filterVal = JSON.parse(filter);
      console.log(filterVal);
      var flag1 = false;
      var flag2 = false;

      // filterVal.category = filterVal.category.map((item) => {
      //   return item.replace(' ', '');
      // });
      filterVal.type = [
        ...filterVal.type.map((item) => {
          return item.replace(/\s/g, '');
        }),
      ];

      console.log('category   ', d.category);
      console.log('d?.type  ', d?.type);
      console.log('filter', filterVal);

      if (filterVal.category.length === 0) {
        flag1 = true;
      } else if (filterVal.category.includes(d?.category.toLowerCase())) {
        flag1 = true;
      }

      if (filterVal.type.length === 0) {
        flag2 = true;
      } else if (filterVal.type.includes(d?.type)) {
        flag2 = true;
      }
      console.log(flag1 + '  ' + flag2);

      return flag1 && flag2;
    };
  }

  applyFilter(filterValue: string) {
    this.listData.filter = JSON.stringify(this.filterValues); // filterValue.trim().toLowerCase();

    //  this.dataSource.filter = '';
    // this.filter = '';
  }

  preventSubmit(event) {
    return event.key != 'Enter';
  }
  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [['Category', 'Committee Type', 'Committee Name', 'Status']];
    this.listData.data.forEach((obj) => {
      let cat = obj['category'];
      let type = obj['type'];
      let title = obj['title'];
      let status = obj['is_published'] == true ? 'Published' : 'Draft';

      let arr = [cat, type, title, status];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      columnStyles: { 0: { cellWidth: 50 } },
      rowPageBreak: 'avoid',
    });
    if (action === 'save') {
      doc.save('committees.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      // window.open(doc.output('bloburl') as unknown as string, '_blank');
      doc.output('dataurlnewwindow', { filename: 'committees.pdf' });
    }
  }

  filterValues = { category: [], type: [] };
  filterColumns = ['category', 'type'];
}

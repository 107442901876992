<div
  style="
    background: #fff;
    padding: 16px;
    border-radius: 5px;
    margin-bottom: 32px;
  "
>
  <h4><i class="fa fa-video"></i> &nbsp;Meetings</h4>
  <mat-table
    #MeetingsTable
    MeetingsTable
    [dataSource]="listData"
    matSort
    class="mattable card"
  >
    <!-- Date -->
    <ng-container matColumnDef="start_date">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
      <mat-cell *matCellDef="let element">{{
        element.start_date | customDate
      }}</mat-cell>
    </ng-container>
    <!-- Start Time -->
    <ng-container matColumnDef="start">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Start Time</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{ element?.start_time }}</mat-cell>
    </ng-container>
    <!-- End Time -->
    <ng-container matColumnDef="end">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >End Time</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{ element?.end_time }}</mat-cell>
    </ng-container>
    <!-- Participants -->
    <ng-container matColumnDef="members">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Participants</mat-header-cell
      >
      <mat-cell *matCellDef="let element"
        >{{ element.members[0]?.name
        }}{{
          element.members[1] ? ", " + element.members[1].name : ""
        }}
        &nbsp;<span
          *ngIf="element.members.length >= 3"
          style="
            padding: 2px 4px;
            color: #fff;
            background-color: #095294;
            border-radius: 50px;
          "
        >
          {{
            element.members.length >= 3
              ? "&#43;" + (element.members.length - 2).toString() + "&nbsp;"
              : ""
          }}
        </span></mat-cell
      >
    </ng-container>

    <!-- Meeting Type -->
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Meeting Type</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{ element.type }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <div *ngIf="total > 5" style="display: flex; justify-content: end">
    <button
      style="float: none"
      (click)="viewMore()"
      mat-stroked-button
      class="viewmore"
    >
      View More
    </button>
  </div>
</div>

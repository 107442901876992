import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';


@Injectable({
    providedIn:'root'
})

export class CommonSpaceCategoryService {

    constructor(private http: HttpClient){


    }

   
    getCommonSpaceCategories(){

      return this.http.get(apiendpoints.COMMON_SPACE_CATEGORY,
          {
              headers: new HttpHeaders()
                .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
              observe: 'response'
            }
          );      
  }

  getCommonSpaceCategory(id){


    return this.http.get(apiendpoints.COMMON_SPACE_CATEGORY+"/"+id,
        {
            headers: new HttpHeaders()
              .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
            observe: 'response'
          }
        );      
  }

  addCommonSpaceCategory(data:any){
    console.log(data);
    return this.http.post(apiendpoints.COMMON_SPACE_CATEGORY, {data:data},  {
      headers: new HttpHeaders()
        .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
      observe: 'response'
    });      
  }

  removeCommonSpaceCategory(id){
  
    const options = {
      headers: new HttpHeaders({
        'Authorization': `Token `+localStorage.getItem('ACCESS_TOKEN')
      }),
      body: {
        id: id,
      },        
    };
    
    return this.http.delete(apiendpoints.COMMON_SPACE_CATEGORY, options);
  }


   


}

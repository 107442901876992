import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators} from '@angular/forms';
import { SubscriptionService } from '@services/Subscription.service';
import { ActivatedRoute, Router } from '@angular/router';
import {CoreService} from '../../../shared/services/CoreService';
import {language} from '@shared/config/language';
@Component({
  selector: 'app-addsubscription',
  templateUrl: './addsubscription.component.html',
  styleUrls: ['./addsubscription.component.css']
})

export class AddsubscriptionComponent implements OnInit {

  isEdit:Boolean = false;
  form :FormGroup = new FormGroup({
    _id:new FormControl(0),
    subscription:new FormControl('',Validators.required),
    details:new FormControl('',Validators.required),
    users_allowed:new FormControl('',Validators.required),
    cost:new FormControl('',Validators.required),
    cycle:new FormControl('',Validators.required)
  })

  resource = language;
  labelText:String='Add Subscription';

  constructor(private subscriptionService: SubscriptionService, private router : Router,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService
   
    ) {

      this.form.setValue({
        _id:0,
        subscription:"",
        details:"",
        users_allowed:"",
        cost:"",
        cycle:""
      })
      
    this.activeRoute.queryParams.subscribe(data => {

      if(data.id!=undefined)
      {
        this.labelText='Edit Subscription';
        this.subscriptionService.getSubscription(data.id).subscribe(response=> {
      
          const result = response.body["subscription"];
          console.log(result.role);
            this.form.setValue({_id:result._id,subscription:result.subscription,details:result.details,users_allowed:result.users_allowed,cost:result.cost,cycle:result.cycle })
            this.isEdit = true;
            console.log(this.form);
        });
      } 
    })

   }

  ngOnInit(): void {

   

  }

   

  submit():void{ 

    console.log("IN SUB")
    if (this.form.invalid) {
        return;
    }

    if(!this.isEdit)         this.form.removeControl('_id');



    this.subscriptionService.addSubscription(this.form.getRawValue()).subscribe(response=> {
      
      const result = response.body;
   
      this.coreservice.success("Subscription saved");
     this.router.navigate(['subscriptions']);

     }, (err) => {

      this.coreservice.error(err.error.message);
      this.form.addControl('_id', new FormControl(''));

      
   })
   
  }
 
}

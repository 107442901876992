import { environment } from '../../../environments/environment';
import { SettingsService } from '@services/Settings.service';

export class constants {
  constructor(private settingsService: SettingsService) {}

  public static site: { [key: string]: any } = {
    SOCIAL_SITES: ['Linked In', 'Instagram', 'Facebook'],
    ID_TYPES: [
      'Adhar',
      'PAN',
      'Voter Card',
      'Passport',
      'Driving License',
      'Other',
    ],
    DATE_FORMAT: 'dd/mm/yy',
  };

  public static task_status = ['Not started', 'On Going', 'Completed'];
  public static task_priority = ['High', 'Medium', 'Low'];
  public static declaration_category = ['Family', 'Business', 'Ownership'];

  public static committee_steps = [
    'Description',
    'Roles & Responsibilities',
    'Members',
    'Forum Rules',
    'Meeting Rules',
    'Decision Criteria',
    'Code of Conduct',
  ];
  public static committee_template_steps = [
    'Description',
    'Roles & Responsibilities',
    'Forum Rules',
    'Meeting Rules',
    'Decision Criteria',
    'Code of Conduct',
  ];

  public static committee_categoryies: { [key: string]: string } = {
    committee: 'Committee',
    subcommittee: 'Sub Committee',
    subsubcommittee: 'Sub Sub Committee',
  };

  public static committee_member_roles: { [key: string]: string } = {
    chairman: 'Chairman',
    secretary: 'Secretary',
    advisor: 'Advisor',
    member: 'Member',
    invitee: 'Invitee',
  };

  public static committee_member_types: { [key: string]: string } = {
    member: 'Permanent Member',
    permanent_invitee: 'Permanent Invitee',
  };

  public static getConstant(key) {
    return constants.site[key];
  }

  public static CURRENT_COMMITTEE = {};

  public static user_status: { [key: string]: string } = {
    active: 'Active',
    inactive: 'Inactive',
    suspended: 'Suspended',
  };

  public static CORE_FAMILY_NAME = 'Core Family';

  public static committee_meeting_frquency: { [key: string]: string } = {
    Daily: 'Daily',
    Weekly: 'Weekly',
    'Bi - Weekly': 'Bi-weekly',
    Monthly: 'Monthly',
    'Bi - Monthly': 'Bi-monthly',
    Quaterly: 'Quarterly',
    'Half - Yearly': 'Half-yearly',
    Yearly: 'Yearly',
  };

  public static GLOBAL_DATE_FORMAT = 'dd-MM-yyyy';
  public static YYYY_MM_DD_DATE_FORMAT = 'yyyy-MM-dd';
  public static reads_category: { [key: string]: string } = {
    agenda: 'Agenda',
    pre_reads: 'Pre Reads',
    mom: 'MOM',
  };

  public static poll_status: { [key: string]: string } = {
    active: 'Active',
    completed: 'Completed',
    cancelled: 'Cancelled',
  };

  public static ticket_status: { [key: string]: string } = {
    in_review: 'Open',
    in_progress: 'In Progress',
    declined: 'Declined',
    completed: 'Completed',
  };
  public static IDP_STATUSES: { [key: string]: string } = {
    in_review: 'In Review',
    in_progress: 'In Progress',
    abandoned: 'Abandoned',
    completed: 'Completed',
  };
}

<mat-card class="">
  <mat-card-header>
    <mat-card-title *ngIf="source != 'familyadmin' && source != 'template'">
      {{ resource.message("KNOWLEDGE") }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form class="form-inline" *ngIf="allowsearch == true">
      <div class="form-group mr-2">
        <!-- not in mockup 
          <button mat-stroked-button class="iconcss"><i class="fa fa-file-pdf fa-lg"></i></button>
          <button mat-stroked-button class="iconcss" (click)="exporter.exportTable('csv')"><i class="fa fa-file-excel fa-lg"></i></button>
          <button mat-stroked-button class="iconcss"><i class="fa fa-print fa-lg"></i></button>
        -->
      </div>
      <span class="example-spacer"></span>
      <div class="mr-1">
        <button
          [disabled]="!PERMISSION_ADD"
          mat-raised-button
          color="primary"
          class="addbutton"
          class="buttonfloatright"
          (click)="addKnwoledge()"
        >
          <i class="fas fa-plus"></i>&nbsp;&nbsp;{{
            resource.message("KNOWLEDGE_ACTION") | uppercase
          }}
        </button>
        <input
          matInput
          [(ngModel)]="searchKey"
          placeholder="{{ resource.message('SEARCH_BY_KNOWLEDGE') }}"
          autocomplete="off"
          (keyup)="applyfilter()"
          (keydown.enter)="$event.preventDefault()"
          name="searchKey"
          class="searchfloatright"
        />
      </div>
    </form>
    <p>
      <br />
      <mat-table
        #roleTable
        roleTable
        matTableExporter
        [dataSource]="listData"
        matSort
        class="mattable"
        #exporter="matTableExporter"
        [hiddenColumns]="[3]"
      >
        <!-- Name Column -->
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Name</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="download">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >File</mat-header-cell
          >
          <mat-cell *matCellDef="let element"
            ><a href="{{ element.filename }}" target="_blank"
              >Download</a
            ></mat-cell
          >
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="categoryname">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Category</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.categoryname
          }}</mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="created_at">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Date of Upload</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.created_at | customDate
          }}</mat-cell>
        </ng-container>

        <!-- System Column MM/dd/YYYY h:mm:ss a ':'IST'-->

        <!-- Action Column -->
        <ng-container matColumnDef="Action">
          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button
              mat-icon-button
              (click)="edit(row)"
              *ngIf="source != 'template' && source != 'superadmin'"
              [disabled]="!PERMISSION_EDIT"
            >
              <mat-icon>mode_edit</mat-icon>
            </button>
            <button
              mat-icon-button
              color="warn"
              (click)="onDelete(row._id)"
              *ngIf="source != 'template'"
              [disabled]="!PERMISSION_DELETE"
            >
              <mat-icon>delete_outline</mat-icon>
            </button>
            <button
              mat-button
              (click)="onPublic(row)"
              class="user-template"
              *ngIf="source == 'template'"
            >
              Use Template
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
       [pageSizeOptions]="[5, 10, 15, 20]"
        [pageSize]="5"
        showFirstLastButtons
      ></mat-paginator>
    </p>
  </mat-card-content>
</mat-card>

<div class="content p30">
  <div id="block_container">
    <a
    routerLink="/committee/attendence"
    [queryParams]="{ id: parentid }"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >
    <div id="bloc2">
      <h5>
        Committee Meetings / {{ endpointResults?.committee?.title }} /
            {{ endpointResults?.title }}
      </h5>
    </div>
  </div>
  <form>
    <fieldset>
      <!--[disabled]=" (familyname!=select_user_family) ? isDisabled :''"-->
      <mat-card class="">
        <mat-card-content>
          <span class="devarea">Add Outcome</span>
          <br />
          <hr />
          <!-- <div class="row cancel">
                <button mat-stroked-button>Cancel Meeting</button>
              </div> -->
          <div class="row maindiv">
            <div class="row">
              <div class="meetingcol">{{meetingTitle}}</div>
            </div>
            <div class="row">
              <div class="col-md-3 firstcol">Start Date and Time</div>
              <div class="col-md-3 firstcol">End Date and Time</div>
              <div class="col-md-2 firstcol">Participants</div>
              <div class="col-md-2 firstcol">Venue</div>
              <div class="col-md-2 firstcol">Actions</div>
              <div class="col-md-3 secondcol">
                {{ start_date | customDate }}
              </div>
              <div class="col-md-3 secondcol">{{ end_date | customDate }}</div>
              <div class="col-md-2 secondcol1">
                <button
                  mat-button
                  color="primary"
                  (click)="openparticipants('', '', '')"
                >
                  View Invitees
                </button>
              </div>
              <div class="col-md-2 secondcol">{{ venue }}</div>
              <div class="col-md-2 secondcol">
                <button mat-icon-button disabled="true">
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </div>
            </div>
          </div>

          <br />
          <div class="row maindiv">
            <div class="row">
              <div class="meetingcol">Agenda</div>
            </div>
            <div class="row wholerow" *ngFor="let topic of topics; index as i">
              <div class="col-md-6 firstcol">
                <div class="row">
                  Agenda: <strong>&nbsp; {{ topic.title }}</strong>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">Description</div>
                    <div class="row">
                      <strong>{{ topic.description }}</strong>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row" *ngIf="topic.pre_reads">pre-reads</div>
                    <div class="row">
                      <strong>{{ (topic?.pre_reads?.length>90)? (topic?.pre_reads | slice:0:90)+'...':(topic?.pre_reads) }}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 buttoncol">
                <div class="row buttonrow">
                  <button
                    mat-stroked-button
                    type="button"
                    class="wide1"
                    (click)="outcome_tab(topic, i)"
                  >
                    Add Outcome
                  </button>
                </div>
                <!-- <div class="row buttonrow">
                  <button
                    mat-raised-button
                    color="primary"
                    type="button"
                    (click)="defere_tab(topic, i)"
                  >
                    Defer
                  </button>
                </div> -->
              </div>
              <div class="col-md-12 newcomp" *ngIf="showView === i">
                <app-commiteeoutcometab
                  [parentid]="parentid"
                  [agendaid]="agendaid"
                  [viewdata]="viewdata"
                  [isVoting]="topic.resolution !== 'consensus'"
                ></app-commiteeoutcometab>
              </div>
            </div>
          </div>
        </mat-card-content>

        <span class="blankline"></span>
        <div class="form-group mr-2">
          <button
            mat-stroked-button
            type="button"
            class="wide"
            routerLink="/committee/attendence"
            [queryParams]="{ id: parentid }"
          >
            PREVIOUS
          </button>
        </div>
        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="button"
            (click)="mom()"
          >
            Next
          </button>
        </mat-card-actions>
      </mat-card>
    </fieldset>
  </form>
</div>

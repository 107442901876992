<div class="content p30"> 

    <h5>{{resource.message('COMMONSPACE')}}</h5>    
 

    
    <mat-card class="">
      <mat-card-header >            
        <mat-card-title > {{resource.message('COMMONSPACE')}} </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <br />
        <mat-tab-group class="tabgroup" [selectedIndex]="activePath" (selectedTabChange)="changePath($event)">
          <mat-tab label="Space Type" class="test">
            <app-commonspacecategory></app-commonspacecategory>
          </mat-tab>
          <mat-tab label="Space"> 
            <app-commonspaces></app-commonspaces>

          </mat-tab>
          <mat-tab label="Bookings" > 
            <app-commonspacebooking></app-commonspacebooking>

          </mat-tab>
          
          
        </mat-tab-group>



      </mat-card-content>
    </mat-card>



  </div>


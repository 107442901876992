<div class="content p30">
  <div id="block_container">
    <ng-container [ngSwitch]="TEMPLATE">
      <a
        *ngSwitchCase="'1'"
        routerLink="/templatemanagement"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
      <a
        *ngSwitchCase="'0'"
        routerLink="/committee"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
    </ng-container>
    <div id="bloc2"><h5>Committee / Add</h5></div>
  </div>
  <form [formGroup]="form" (ngSubmit)="submit('final')">
    <input type="hidden" formControlName="_id" />
    <mat-card class="">
      <mat-card-content>
        <app-stepper
          [isTemplate]="isTemplate"
          [currentStep]="isTemplate ? 3 : 4"
        ></app-stepper>
        <br />
        <h4 class="steph4">{{ isTemplate ? steps[3] : steps[4] }}</h4>
        <hr />
        <mat-grid-list cols="2" rowHeight="80px">
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <input type="hidden" formControlName="_id" />
              <mat-select
                placeholder="Meeting Frequency"
                formControlName="meeting_frequency"
              >
                <mat-option
                  *ngFor="let type of TYPE_LIST | keyvalue"
                  [value]="type.key"
                  >{{ type.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <label>Meeting Notice : </label><br />
            <mat-radio-group
              appearance="outline"
              class="smallRadio"
              [formControl]="notice"
            >
              <!-- <mat-radio-button value="NA" [checked]="notice.value == 'na'" (change)="this.form.patchValue({'meeting_notice':'na'})" >NA</mat-radio-button>
                                    -->
              <mat-radio-button
                value="15"
                [checked]="notice.value == '15'"
                class="m20"
                (change)="this.form.patchValue({ meeting_notice: '15' })"
                ><span>15 Days</span>
              </mat-radio-button>
              <mat-radio-button
                value="30"
                [checked]="notice.value == '30'"
                class="m20"
                (change)="this.form.patchValue({ meeting_notice: '30' })"
                >30 Days</mat-radio-button
              >
              <mat-radio-button
                value="others"
                [checked]="notice.value == 'others'"
                class="m20"
                (change)="this.form.patchValue({ meeting_notice: 'others' })"
                >Others</mat-radio-button
              >
            </mat-radio-group>
            <mat-form-field
              class="field-spacer3"
              *ngIf="notice.value == 'others'"
            >
              <input matInput formControlName="meeting_days" />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile colspan="2" class="attendence">
            <span class="text-inside-grid">
              <label>Attendence Quorum : </label>
              <mat-radio-group
                appearance="outline"
                class="field-spacer"
                [formControl]="attend"
              >
                <mat-radio-button
                  value="number"
                  [checked]="attend.value == 'number'"
                  (change)="this.form.patchValue({ attendence_type: 'number' })"
                  >Number</mat-radio-button
                >
                <mat-radio-button
                  value="percentage"
                  [checked]="attend.value == 'percentage'"
                  (change)="
                    this.form.patchValue({ attendence_type: 'percentage' })
                  "
                  >Percentage</mat-radio-button
                >
              </mat-radio-group>
              <mat-form-field
                appearance="outline"
                class="field-spacer3"
                *ngIf="attend.value == 'number'"
              >
                <input
                  matInput
                  placeholder="Number"
                  type="number"
                  min="0"
                  formControlName="attendence"
                />
              </mat-form-field>
              <span
                appearance="outline"
                class="field-spacer4 txt"
                *ngIf="attend.value == 'percentage'"
              >
                <mat-slider
                  [formControl]="valueControl"
                  style="width: 300px"
                  thumbLabel
                  [displayWith]="formatLabel"
                  tickInterval="100"
                  step="1"
                  min="1"
                  max="100"
                ></mat-slider>
              </span>
            </span>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
      <mat-card-actions>
        <!-- <div class="form-group mr-2">  
                            <button mat-stroked-button type="button" class="wide" (click)="previous()">PREVIOUS</button>
                            <button mat-raised-button color="primary" class="btn btn-primary login-btn wide" type="button" [disabled]="form.invalid" (click)="submit('draft')">Save As Draft</button>

                        </div> -->
        <div class="row">
          <div class="col col-md-6" align="left">
            <button
              mat-stroked-button
              type="button"
              class="wide"
              (click)="previous()"
            >
              PREVIOUS
            </button>
            <button
              mat-raised-button
              color="primary"
              class="btn btn-primary login-btn wide"
              type="button"
              [disabled]="form.invalid"
              (click)="submit('draft')"
            >
              Save As Draft
            </button>
          </div>
          <div class="col col-md-6" align="right">
            <button
              mat-raised-button
              color="primary"
              class="btn btn-primary login-btn wide"
              type="submit"
              [disabled]="form.invalid"
            >
              Next
            </button>
            <button
              mat-raised-button
              color="clear"
              class="wide"
              [routerLink]="isTemplate ? '/templatemanagement' : '/committee'"
            >
              Cancel
            </button>
          </div>
        </div>
      </mat-card-actions>
    </mat-card>
  </form>
</div>

import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { language } from '@shared/config/language';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@services/UserService';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MemberElement } from '../committee-s3.component';
import { CommitteeService } from '@services/Committee.service';
import { constants } from '@shared/config/constants';

@Component({
  selector: 'app-addcommitteemembers',
  templateUrl: './addcommitteemembers.component.html',
  styleUrls: ['./addcommitteemembers.component.css'],
})
export class AddcommitteemembersComponent implements OnInit {
  resource = language;

  showFiller = false;
  searchKey: string = '';
  rowModelType: 'serverSide';
  rowData: any;
  selectedRow: any;
  isChairmanSelected = false;
  isSecretarySelected = false;

  displayedColumns: string[] = ['select', 'name', 'type', 'role'];
  listData = new MatTableDataSource<MemberElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<MemberElement>(true, []);

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<MemberElement>;

  TYPE_LIST = constants.committee_member_types;
  ROLES_LIST = constants.committee_member_roles;

  committee_id = '';
  errorMessage = null;
  uncompletedDataSet = [];

  MEMBERS;
  selectedrole: string;
  constructor(
    private committeeService: CommitteeService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddcommitteemembersComponent>
  ) {
    this.committee_id = data.committeeId;
    this.MEMBERS = data.members;
  }

  ngOnInit(): void {
    this.userService.getFamilyUses().subscribe((response) => {
      const result = response.body;

      console.log(' response.body', response.body['data']);
      console.log('this.MEMBERS', this.MEMBERS);

      this.listData.data = response.body['data'].filter((item) => {
        return !this.MEMBERS.map((item) => {
          return item['id'];
        }).includes(item._id);
      });
      console.log(constants.committee_member_roles);

      console.log('this.ROLES_LIST  ', this.ROLES_LIST);

      if (
        this.MEMBERS.find((item) => {
          return item['committee_role'] === 'chairman';
        })
      ) {
        console.log(' delete this.ROLES_LIST.chairman;');
        this.ROLES_LIST = Object.keys(this.ROLES_LIST)
          .filter((key) => key !== 'chairman')
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: this.ROLES_LIST[key] });
          }, {});
        // delete this.ROLES_LIST.chairman;
      }
      if (
        this.MEMBERS.find((item) => {
          return item['committee_role'] === 'secretary';
        })
      ) {
        console.log('delete this.ROLES_LIST.secretary;');
        this.ROLES_LIST = Object.keys(this.ROLES_LIST)
          .filter((key) => key !== 'secretary')
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: this.ROLES_LIST[key] });
          }, {});
        // delete this.ROLES_LIST.secretary;
      }

      this.table.renderRows();
    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey?.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listData.data.forEach((row) => {
          this.selection.select(row);
        });
  }

  rolesCondition(type, element) {

    let includeChairman = false;
    let includeSecretary = false;

    if (type.key === 'chairman' && this.isChairmanSelected === false) {
      includeChairman = true;
      return true;
    } else if (type.key === 'secretary' && this.isSecretarySelected === false) {
      includeSecretary = true;
      return true;
    } else {
      if (type.key === 'chairman') {
        if (
          this.isChairmanSelected === true &&
          this.selection.isSelected(element) &&
          element.committee_role === 'chairman'
        ) {
          includeChairman = true;
        }
        includeSecretary = false;
      } else if (type.key === 'secretary') {
        if (
          this.isSecretarySelected === true &&
          this.selection.isSelected(element) &&
          element.committee_role === 'secretary'
        ) {
          includeSecretary = true;
        }
        includeChairman = false;
      } else {
        return true;
      }
      return includeChairman || includeSecretary;
    }

  }

  onRoleChange(event, element, index) {
    this.selection.select(this.selectedRow);
    console.log('event:::', event);
    console.log('element:::', element);
    console.log(this.ROLES_LIST);

    this.listData.data[index]['committee_role'] = event.value;

    if (element['type'] && event.value) {
      this.uncompletedDataSet[element['_id']] = false;
    } else {
      if (this.selection.isSelected(element)) this.uncompletedDataSet[element['_id']] = true;
    }


    if (
      element.committee_role &&
      element.committee_role === 'chairman' &&
      this.selection.isSelected(element) &&
      event.value !== 'chairman'
    ) {
      this.isChairmanSelected = false;
    } else if (
      this.selection.isSelected(element) &&
      event.value === 'chairman'
    ) {
      this.isChairmanSelected = true;
    }

    // ------------------

    if (
      element.committee_role &&
      element.committee_role === 'secretary' &&
      this.selection.isSelected(element) &&
      event.value !== 'secretary'
    ) {
      this.isSecretarySelected = false;
    } else if (
      this.selection.isSelected(element) &&
      event.value === 'secretary'
    ) {
      this.isSecretarySelected = true;
    }


    this.checkSelectedUsers();
  }
  checkboxToggle(event, row) {
    console.log('event:::', event);

    if (
      row.committee_role &&
      event.checked === true &&
      row.committee_role === 'chairman'
    ) {
      this.isChairmanSelected = true;
      console.log(' this.isChairmanSelected', this.isChairmanSelected);
    } else if (
      row.committee_role &&
      event.checked === false &&
      row.committee_role === 'chairman'
    ) {
      this.isChairmanSelected = false;
      console.log(' this.isChairmanSelected', this.isChairmanSelected);
    }

    // --------------------------------
    if (
      row.committee_role &&
      event.checked === true &&
      row.committee_role === 'secretary'
    ) {
      this.isSecretarySelected = true;
    } else if (
      row.committee_role &&
      event.checked === false &&
      row.committee_role === 'secretary'
    ) {
      this.isSecretarySelected = false;
    }
    console.log('selection  ', this.selection);

    this.selection.toggle(row);

    if ((!row['type'] || !row['committee_role']) && this.selection.isSelected(row)) {
      this.uncompletedDataSet[row['_id']] = true;
    } else {
      this.uncompletedDataSet[row['_id']] = false;
    }

    this.checkSelectedUsers();
  }

  save() {
    this.errorMessage = null;
    let sel = this.selection['selected'];
    let userCount = {'chairman': 0, 'secretary': 0, 'member': 0};

    if (!sel || (sel == undefined)) { this.errorMessage = 'You have to select the users from the list'; return; }

    var data = this.MEMBERS.map(tempData => {
      // console.log(tempData);
      switch (tempData['committee_role']) {
        case 'chairman':  userCount.chairman++; break;
        case 'secretary': userCount.secretary++; break;
        case 'member': userCount.member++; break;
      }
      return tempData;
    });

    for (var i = 0; i < sel.length; i++) {

      if (sel[i]['type'] == 'permanent_invitee') sel[i]['committee_role'] = '';

      var name = sel[i]['name'];
      if (sel[i]['middlename'] != null) name += ' ' + sel[i]['middlename'];
      if (sel[i]['lastname'] != null) name += ' ' + sel[i]['lastname'];

      switch (sel[i]['committee_role']) {
        case 'chairman': userCount.chairman++; break;
        case 'secretary': userCount.secretary++; break;
        case 'member': userCount.member++; break;
      }

      data.push({
        id: sel[i]['_id'],
        mobile: sel[i]['mobile'],
        name: name,
        committee_role:
          sel[i]['committee_role'] != null
            ? sel[i]['committee_role']
            : 'Member',
        type: sel[i]['type'] != null ? sel[i]['type'] : 'Permanent Member',
        profile_photo: '',
        role: sel[i]['role'],
      });
      this.isChairmanSelected = false;
    }

    var rawcommittee = { members: data, _id: this.committee_id };

    if (userCount.secretary > 0 && userCount.chairman > 0) {
      this.committeeService.addCommittee(rawcommittee).subscribe(
        (response) => {
          const result = response.body;
          this.dialogRef.close(false);
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      // this.errorMessage = 'You have to select one secretary & one chairman';
      if (userCount.chairman < 1) {
        this.errorMessage = 'You have to select one chairman';
      } else if (userCount.secretary < 1) {
        this.errorMessage = 'You have to select one secretary';
      }
      // else if (userCount.member < 2) {
      //   this.errorMessage = 'You have to select at least two members';
      // }
    }

  }
  checkValidations() {
    let sel = this.selection['_selected'];

    const seldata = sel.filter(
      (text) => text.type === this.ROLES_LIST.chairman.toLocaleLowerCase()
    );
    console.log(seldata);
  }

  onTypeChanged(event, element, index): void {
    
    this.listData.data[index]['type'] = event.value;

    this.listData.data[index]['committee_role'] = (element.type == 'permanent_invitee') ? 'invitee' : null;

    if(element.type == 'permanent_invitee') {
      this.selection.select(this.selectedRow);
    }

    if ((!element['type'] || !element['committee_role']) && this.selection.isSelected(element)) {
      this.uncompletedDataSet[element['_id']] = true;
    } else {
      this.uncompletedDataSet[element['_id']] = false;
    }
  }

  checkSelectedUsers() {
    this.isChairmanSelected = false;
    this.isSecretarySelected = false;

    this.MEMBERS.map(tempData => {
      if (tempData['committee_role'] == 'chairman') {
        this.isChairmanSelected = true;
      } else if (tempData['committee_role'] == 'secretary') {
        this.isSecretarySelected = true;
      }
    });

    let sel = this.selection['selected'];
    if (sel) for (var i = 0; i < sel.length; i++) {
      if (sel[i]['committee_role'] == 'chairman') {
        this.isChairmanSelected = true;
      } else if (sel[i]['committee_role'] == 'secretary') {
        this.isSecretarySelected = true;
      }
    }

  }
}

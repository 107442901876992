<div style="background: #fff; padding: 16px; border-radius: 5px">
  <h4><i class="fa fa-poll"></i>&nbsp;Polls</h4>

  <mat-table
    #roleTable
    roleTable
    [dataSource]="listData"
    matSort
    class="mattable card"
  >
    <!-- Name Column -->
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Poll Title</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="committeename">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Committee</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{
        element?.committeename
      }}</mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="active_till">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Active Till</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{
        element.active_till | customDate
      }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <div *ngIf="total > 5" style="display: flex; justify-content: end">
    <button
      (click)="viewMore()"
      style="float: none"
      mat-stroked-button
      class="viewmore"
    >
      View More
    </button>
  </div>
</div>

<div class="content p30"> 

       
 
    <div id="block_container">
        <a  routerLink="/role" id="bloc1" mat-flat-button class="mat-flat-button-transperent" ><span  class="material-icons">keyboard_backspace</span></a> 
          
        <div id="bloc2"><h5>{{resource.message('ROLES')}} / {{labelText}}</h5></div>
    </div>
    
    <form [formGroup]="form" (ngSubmit)="submit()" >
    <mat-card class="">
      <mat-card-header>            
        <mat-card-title> Role </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <hr />
      
          <mat-form-field appearance="outline">
            <input type="hidden" formControlName="_id" >
            <input type="hidden" formControlName="isDel" >
            <input type="hidden" formControlName="isSystem" >
            <input type="hidden" formControlName="updated_by" >
            <mat-label>Role Name</mat-label>
            <input matInput placeholder="Placeholder" formControlName="role">
          </mat-form-field>
              
          <mat-form-field appearance="outline" >
            <mat-label>Family</mat-label>
            <mat-select  placeholder="Family"  formControlName="family">
                <mat-option *ngFor="let families of FAMILY_LIST" [value]="families._id+''">{{families.name}}</mat-option>
             </mat-select>
             <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
       


      </mat-card-content>

      <mat-card-actions align="end">
        <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="submit" [disabled]="form.invalid">Save</button>
          <button mat-raised-button color="clear" class="button-wider"  routerLink="/role">Cancel</button>
      </mat-card-actions>
      

    </mat-card>
  </form>


  </div>


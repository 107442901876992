<div class="container-fluid">
  <div class="row">
    <div class="col-sm-7">
      <div class="main-div align-middle">
        <h3 class="bold-text">Login</h3>

        <form [formGroup]="loginform" (ngSubmit)="login()">
          <div class="form-group">
            <label class="login-label"> Customer Id </label>
            <input
              type="text"
              formControlName="email"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="submitted && f.email.errors.required">
                Customer Id is required
              </div>
            </div>
          </div>
          <a
            class="btn btn-default forgot-pwd-btn"
            href="forget"
          >
            <u>Forgot Customer Id</u>
          </a>
          <br />
          <hr />

          <div class="form-group">
            <label class="login-label"> Password </label>
            <input
              type="password"
              formControlName="password"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            />
            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
            >
              <div *ngIf="submitted && f.password.errors.required">
                Password is required
              </div>
              <div *ngIf="submitted && f.password.errors.minlength">
                Password should be minimum 5 characters long
              </div>
            </div>
          </div>

          <hr />
          <a class="btn btn-default forgot-pwd-btn" href="password-recovery-by-email" >
            <u>Forgot Password</u>
          </a>
          <br /><br />
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="errmessage !== ''"
            align="center"
          >
            {{ errmessage }}
          </div>
          <br /><br />
          <button class="btn btn-primary login-btn" type="submit">LOGIN</button>
        </form>
      </div>
    </div>

    <div class="col-sm-5">
      <div class="login-bg">
        <img src="./assets/images/fb-logo-white.png" class="fb-logo" />
      </div>
    </div>
  </div>
</div>
<!--container-fluid-->

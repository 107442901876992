import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { FamiliesService } from '@services/Families.service';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import { CoreService } from '@services/CoreService';
import { MatMenuTrigger } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import { AuthService } from '@services/Auth.service';
import { constants } from '@shared/config/constants';
export interface FamiliesElement {
  roleId: number;
  role: string;
  created: string;
  updated: string;
}

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DatePipe, TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-families',
  templateUrl: './families.component.html',
  styleUrls: ['./families.component.css'],
  providers: [DatePipe, TitleCasePipe],
})
export class FamiliesComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;

  ELEMENT_DATA: FamiliesElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = [
    'name',
    'created_at',
    'email',
    'mobile',
    'paymentstatus',
    'appstatus',
    'portalstatus',
    'actions',
  ];
  listData = new MatTableDataSource<FamiliesElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<FamiliesElement>(true, []);

  PERMISSION_EDIT = AuthService.authentication('Family Management', 'READ');
  PERMISSION_ADD = AuthService.authentication('Family Management', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Family Management', 'DELETE');
  DATE_FORMAT = constants.GLOBAL_DATE_FORMAT;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<FamiliesElement>;

  resource = language;

  constructor(
    private familiesService: FamiliesService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private http: HttpClient,
    private coreservice: CoreService,
    private datePipe: DatePipe,
    private titlecasePipe: TitleCasePipe
  ) {
    AuthService.authenticate_redir('Family Management', ['READ']);

    this.listData = new MatTableDataSource<FamiliesElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<FamiliesElement>(true, []);

    console.log(language.message('a'));
  }

  ngOnInit(): void {
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllFamilies();
    this.table.renderRows();
  }

  getAllFamilies() {
    this.familiesService.getFamilies().subscribe(
      (response) => {
        const result = response.body;
        this.listData.data = response.body['results'] as FamiliesElement[];

        this.table.renderRows();
      },
      (err) => {
        if (err.error.status != null) {
          //          this.errmessage = err.error.message;
        } else {
          //         this.errmessage = err.error.errors[0].msg;
        }
      }
    );
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listData.data.forEach((row) => this.selection.select(row));
  }

  addrole() {
    this.router.navigate(['/role/add']);
  }

  edit(role) {
    this.router.navigate(['families/add'], { queryParams: { id: role._id } });
  }

  onDelete(id) {
    this.coreservice
      .openConfirmDialog(this.resource.message('ROLE_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.familiesService.removeRole(id).subscribe(
            (response) => {
              const result = response;
              this.getAllFamilies();
              this.coreservice.success(this.resource.message('ROLE_SAVE'));
            },
            (err) => {}
          );
        }
      });
  }

  closeMyMenu() {
    this.trigger.closeMenu();
  }

  filterData(ev, col, val) {
    if (ev.checked) {
      col = String(col);

      let value = { [col]: val };
      this.filterValues.push(value);
    } else {
      let value = { [col]: val };
      const index = this.filterValues.indexOf(value);
      this.filterValues.splice(index - 1, 1);
    }

    this.setupFilter(col);
    this.applyFilter(val);
  }

  stoppro($event) {
    $event.stopPropagation();
  }

  setupFilter(column: string) {
    this.listData.filterPredicate = (d: any, filter: string) => {
      const val = JSON.parse(filter);
      var flag = false;

      val.forEach(function (item) {
        let obj = item;
        const textToSearch = (d[column] && d[column].toLowerCase()) || '';
        if (obj[column] != undefined) {
          if (
            textToSearch.toLowerCase().indexOf(obj[column].toLowerCase()) !== -1
          ) {
            flag = true;
          }
        }
      });
      return flag;
    };
  }

  applyFilter(filterValue: string) {
    this.listData.filter = JSON.stringify(this.filterValues); // filterValue.trim().toLowerCase();
  }

  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [
      [
        'Family Name',
        'Date of Registration',
        'Email ID',
        'Contact No',
        'Payment Status',
        'Family App Status',
        'Family Portal Status',
      ],
    ];
    this.listData.data.forEach((obj) => {
      let name = obj['name'] ? obj['name'] : '';
      let created_at = obj['created_at']
        ? this.datePipe.transform(obj['created_at'], this.DATE_FORMAT)
        : '';
      let email = obj['email'] ? obj['email'] : '';
      let mobile = obj['mobile'] ? obj['mobile'] : '';
      let payment_status = obj['payment_status'] ? obj['payment_status'] : '';
      let app_status = obj['app_status']
        ? obj['app_status'] == true
          ? 'Active'
          : 'In Active'
        : '';
      let admin_portal_status = obj['admin_portal_status']
        ? obj['admin_portal_status'] == true
          ? 'Active'
          : 'In Active'
        : '';

      let arr = [
        name,
        created_at,
        email,
        mobile,
        payment_status,
        app_status,
        admin_portal_status,
      ];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      styles: { overflow: 'linebreak' },
      rowPageBreak: 'avoid',
    });

    if (action === 'save') {
      doc.save('families.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      doc.output('dataurlnewwindow', { filename: 'families.pdf' });
    }
  }

  filterValues = [];
}

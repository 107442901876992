import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './Auth.service';
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(): boolean {
    console.log('this.auth.isLoggedIn()', this.auth.isLoggedIn());

    if (this.auth.isLoggedIn()) {
      return true;
    } else {
      // window.alert("You don't have permission to view this page");
      this.router.navigate(['login']);
      return false;
    }
  }
}

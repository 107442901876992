import { Component, OnInit } from '@angular/core';
import { language } from '@shared/config/language';
import { CommitteeService } from '@services/Committee.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { FileService } from '@services/File.service';
import { IdpService } from '@services/Idp.service';
import { CoreService } from '@services/CoreService';
import { MatDialog } from '@angular/material/dialog';
import { DialogviewattendanceComponent } from '@shared/components/dialogviewattendance/dialogviewattendance.component';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import * as moment from 'moment';


@Component({
  selector: 'app-committeemom',
  templateUrl: './committeemom.component.html',
  styleUrls: ['./committeemom.component.css'],
})
export class CommitteemomComponent implements OnInit {
  resource = language;
  endpointResults: any;
  parentId: string;
  start_date: string;
  end_date: string;
  venue: string;
  currentfile = {};
  members: any[] = [];
  currentfilename = '';
  selectedfile: any;
  createdfor: string;
  isEdit: boolean = false;
  showoutcome: boolean = false;
  fileId: string;
  url: string = '';
  agendas:any=[];
  minutesData:any;
  comments: any;
  constructor(
    private dialog: MatDialog,
    private committeeService: CommitteeService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fileService: FileService,
    private idp: IdpService,
    private coreservice: CoreService
  ) {}
  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    file: new FormControl(''),
    filename: new FormControl(''),
    title: new FormControl(''),
  });
  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentId = data.id;
        this.getAllCommiteeMeetings(this.parentId);
      }
    });
  }
  getAllCommiteeMeetings(id) {
    var filter = '/' + id;

    this.committeeService.getCommitteeMeetings(filter).subscribe(
      (response: any) => {
        console.log(response.data)
        const result = response.data;
        this.endpointResults = result;
        this.minutesData = result
        this.start_date = result.start_date;
        this.end_date = result.end_date;
        this.venue = result.venue;
        let special_invitees = response.data.temporary_members.members
        this.members = [...response.data.members,...special_invitees];
        this.agendas = result.agendas
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  showOutcome(id){
    this.showoutcome=true;
  }
  onFileChange(event) {
    console.log((event.target as HTMLInputElement).files[0]);
    const latestfile = (event.target as HTMLInputElement).files[0];
    this.currentfile = latestfile;
    this.currentfilename = latestfile.name;
    this.selectedfile = latestfile;
    var filename = latestfile.name;

    this.form.patchValue({
      filename: this.currentfilename,
    });

    this.form.patchValue({
      file: this.currentfile,
    });

    this.form.get('file').updateValueAndValidity();
  }
  async submit(): Promise<void> {
    console.log(this.form.get('file').value);
    if (this.form.invalid) {
      return;
    }
    var rawuser = this.form.getRawValue();
    console.log(this.form.get('file').value);
    if (
      this.form.get('file').value !== undefined &&
      this.form.get('file').value !== ''
    ) {
      let fileurl = this.form.get('file').value;
      var formData: any = new FormData();
      formData.append('file', this.form.get('file').value);
      fileurl = await this.fileService.uploadFile(formData, true, false);
      rawuser.file_url = fileurl;
    }

    delete rawuser['file'];
    let finaldata;
    if (this.isEdit === false) {
      if (rawuser.title != '') {
        finaldata = {
          mom: rawuser.title,
        };
      } else {
        finaldata = {
          mom: rawuser.file_url,
        };
      }
    } else {
      if (rawuser.title != '') {
        finaldata = {
          mom: rawuser.title,
        };
      } else {
        finaldata = {
          mom: rawuser.file_url,
        };
      }
    }
    this.router.navigate(['committee-meetings']);
    this.committeeService.committeemompost(finaldata, this.parentId).subscribe(
      (response) => {
        this.coreservice.success(this.resource.message('MOM_PUBLISHED'));

        // const result = response.body;
        this.router.navigate(['committee-meetings']);
      },
      (err) => {}
    );
  }
  openparticipants(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogviewattendanceComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        members: this.members,
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        parentId: this.parentId,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('>><<<<>>>>', result.data.length);
    });
  }
 
  generatePdf() {
    let doc = new jsPDF();
    doc.setFont("", "bold");
    doc.setFontSize(11);
    let date = this.minutesData.start_date?.split('T')[0] ? this.minutesData.start_date?.split('T')[0] : '-'
    const allInvitees = [];
    this.minutesData.members.forEach((element) => {
      const index = this.minutesData.committee.members.findIndex(val => val.id === element.id);
      if(index === -1) {
        allInvitees.push(element);
      }
    });
    let invitees = allInvitees.map((o)=>o.name)
    let invitees_text = invitees.join(', ') || 'None';
	  const commitee_members = this.minutesData.members.filter(val => val.attendance).map((o)=>o.name).join(', ') || 'None';
    let tmp_invitees = this.minutesData.temporary_members.members.filter(val=> val.attendance).map((o)=>o.name)
    let text_tmp_invitees = tmp_invitees.join(', ') || 'None';
    autoTable(doc, {
      theme: 'plain',
      styles: {minCellWidth: 30,fontStyle: 'bold', font: 'times',fontSize: 10},
      body: [
        ["MEETING NAME: "+ this.minutesData.title, "DATE: "+ date, "VENUE: "+ this.minutesData.venue],
        [{ content: "ATTENDEES: ", colSpan: 3}], [{ content: commitee_members, colSpan: 3}],[{ content: "INVITEES: ", colSpan: 3}],[{ content: invitees_text, colSpan: 3}],[{ content: "TEMPORARY INVITEES: ", colSpan: 3},{ content: text_tmp_invitees, colSpan: 3}]
      ]
    })
    autoTable(doc, {
      theme: 'grid',
      styles: {minCellWidth: 30,fontStyle: 'bold', font: 'times',halign: 'center', fontSize: 14,lineWidth: 1},
      body: [
        ["MINUTES OF MEETING"]
      ]
    })
		this.minutesData.agendas.forEach((agenda,index)=> {
      let preReads = [];
      if(agenda.pre_reads) {
        preReads.push(['Pre Reads '+parseInt(index+1), agenda.pre_reads])
      }
			let assignTasks = [];
			if (agenda.tasks.length !== 0)
			{
       // assignTasks.push(['Outcomes :-'])
				agenda.tasks.forEach((task, index) => {
					if (task.description) {      
						assignTasks.push([`Task ${index + 1}`, task.description])
            assignTasks.push([`Assignee`, task?.assigned_to.map(val => val.name + ' ' + val.lastname).join(', ')])
						if (task.supporters.length > 0) {
							assignTasks.push([`Supporters`, task.supporters.map(val => val.name + ' ' + val.lastname).join(', ')])
						}
						if (task.start_date && task.end_date) {
              assignTasks.push([`From`, task.start_date.split('T')[0]]);
              assignTasks.push([`To`, task.end_date.split('T')[0]]);
						}
					}
				});
			}
			let commentTasks = [];
			if (agenda.comments.length !== 0) {
        commentTasks.push(['All Outcomes :-'])
        agenda.comments.forEach((c, index) => {
				commentTasks.push([`Outcome${index + 1}`, c.comment])
				});
			}
      autoTable(doc, {
        theme: 'grid',
        columnStyles: { 0: {  cellWidth: 40 } },
        styles: {minCellWidth: 30,fontStyle: 'bold', font: 'times'},
        body: [
          ['AGENDA '+parseInt(index+1), agenda.title],
          ['Description '+parseInt(index+1), agenda.description],
          ...preReads,
          ...assignTasks,
          ...commentTasks
        ]
      })
		});
    let start_date = this.minutesData?.start_date?.split('T')[0] ? this.minutesData.start_date?.split('T')[0] : '-'
    let end_date = this.minutesData.end_date?.split('T')[0] ? this.minutesData.end_date?.split('T')[0] : '-'
    autoTable(doc, {
      theme: 'plain',
      styles: {minCellWidth: 30,fontStyle: 'bold', font: 'times'},
      body: [
        ["START DATE: "+start_date, "END DATE: "+end_date],
        ["START TIME: "+moment(this.minutesData?.start_date).utc().format('HH:mm a'), "END TIME: "+moment(this.minutesData?.end_date).utc().format('HH:mm a')]

      ]
    })
    doc.save('MEETING-MINUTES.pdf');
  }
}

import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FileService } from '@services/File.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { language } from '@shared/config/language';
import { CategoriesReportsService } from '@services/CategoriesReports.service';
import { UserService } from '@services/UserService';
import { IdpService } from '@services/Idp.service';

@Component({
  selector: 'app-dialogremark',
  templateUrl: './dialogremark.component.html',
  styleUrls: ['./dialogremark.component.css'],
})
export class DialogremarkComponent implements OnInit {
  resource = language;
  form: FormGroup = new FormGroup({
    remark: new FormControl('', Validators.required),
    user: new FormControl('', Validators.required),
  });
  ADVISOR_LIST: any;
  localstoragedata: any;
  select_user_family: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogremarkComponent>,
    private categoriesReportsService: CategoriesReportsService,
    private userservice: UserService,
    private idp: IdpService
  ) {}

  ngOnInit(): void {
    this.localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    this.getAllAdvisors();
  }
  getAllAdvisors() {
    var filter = '';

    filter =
      '?filter[family]=' +
      this.localstoragedata.family._id +
      '&filter[role]=facilitator';
    this.userservice.getUsers(filter).subscribe(
      (response) => {
        const result = response.body;
        this.ADVISOR_LIST = response.body['results'];
      },
      (err) => {}
    );
  }
  submitRemark() {
    const rawData = this.form.getRawValue();

    this.idp
      .idpaddremark(this.data.parentId, {
        remark: rawData.remark,
        user: rawData.user,
      })
      .subscribe(
        (response: any) => {
          const result = response;
          this.dialogRef.close(false);
        },
        (err) => {
          console.log('errrrrrrr', err);
        }
      );
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { language } from '@shared/config/language';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthService } from '@services/Auth.service';
import { CommitteeService } from '@services/Committee.service';
import { ActivatedRoute, Router } from '@angular/router';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable'
import { environment } from '@environments/environment';
import * as moment from 'moment';

export interface MeetingElement {
  title: string;
  committee: string;
  upcoming: string;
  previous: string;
}

@Component({
  selector: 'app-minutes',
  templateUrl: './minutes.component.html',
  styleUrls: ['./minutes.component.css'],
})
export class MinutesComponent implements OnInit {
  resource = language;
  searchKey: string;
  minutesData: any;
  displayedColumns: string[] = ['committee', 'title', 'start_date', 'minutes_of_meeting'];
  ELEMENT_DATA: MeetingElement[];
  listData = new MatTableDataSource<MeetingElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<MeetingElement>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('MeetingsTable') table: MatTable<MeetingElement>;
  PERMISSION_EDIT = AuthService.authentication('CommitteeMeeting', 'READ');
  PERMISSION_ADD = AuthService.authentication('CommitteeMeeting', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('CommitteeMeeting', 'DELETE');

  constructor(
    private commiteeService: CommitteeService,
    private router: Router,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    AuthService.authenticate_redir('CommitteeMeeting', ['READ']);
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllCommiteeMeetings();
  }
  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    console.log('SEARCH KEY', this.searchKey);
    this.listData.filterPredicate = function (data, filter) {
      console.log(data);
      var dataStr = ['committeeTitle', 'title']
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }
  // Fetch committee meetings
  getAllCommiteeMeetings() {
    var filter = '?filter[type]=committee';
    this.commiteeService.getCommitteeMeetings(filter).subscribe(
      (response: any) => {
        const result = response.data.map(val => ({...val, committeeTitle: val.committee.title}));
        this.listData.data = result.filter(val => (val.meeting_status === 'mom_updated'|| val.meeting_status === 'mom_approved'|| val.meeting_status === 'mom_finalized'));
        this.table.renderRows();
      },
      (err) => {
        setTimeout(() => {
          this.getAllCommiteeMeetings();
        }, 500);
        console.log('errrrrrrr', err);
      }
    );
  }
  createMeeting() {
    this.router.navigate(['/committee/meetings']);
  }

  getMinutes(element: any) {
    this.minutesData = element;
    if(this.minutesData.mom && 
      this.minutesData.mom.startsWith(environment.webservices+'/core/media/publicdownload?') &&
      this.minutesData.members.filter(val => val.invite_status === 'pending').length === this.minutesData.members.length) {
        this.download_file(this.minutesData.mom, 'MEETING-MINUTES.pdf');
        return;
    }
    console.log(element)
    
    this.generatePdf();
  }

  download_file(fileURL, fileName) {
    fetch(fileURL, {
    method: 'GET'
}).then(resp => resp.blob())
    .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName; // the filename you want
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    })
  }

  generatePdf() {
    let doc = new jsPDF();
    doc.setFont("", "bold");
    doc.setFontSize(11);
    let date = this.minutesData.start_date?.split('T')[0] ? this.minutesData.start_date?.split('T')[0] : '-'
    const allInvitees = [];
    this.minutesData.members.forEach((element) => {
      const index = this.minutesData.committee.members.findIndex(val => val.id === element.id);
      if(index === -1) {
        allInvitees.push(element);
      }
    });
    let invitees = allInvitees.map((o)=>o.name)
    let invitees_text = invitees.join(', ') || 'None';
	  const commitee_members = this.minutesData.members.filter(val => val.attendance).map((o)=>o.name).join(', ') || 'None';
    let tmp_invitees = this.minutesData.temporary_members.members.filter(val=> val.attendance).map((o)=>o.name)
    let text_tmp_invitees = tmp_invitees.join(', ') || 'None';
    autoTable(doc, {
      theme: 'plain',
      styles: {minCellWidth: 30,fontStyle: 'bold', font: 'times',fontSize: 10},
      body: [
        ["MEETING NAME: "+ this.minutesData.title, "DATE: "+ date, "VENUE: "+ this.minutesData.venue],
        [{ content: "ATTENDEES: ", colSpan: 3}], [{ content: commitee_members, colSpan: 3}],[{ content: "INVITEES: ", colSpan: 3}],[{ content: invitees_text, colSpan: 3}],[{ content: "TEMPORARY INVITEES: ", colSpan: 3},{ content: text_tmp_invitees, colSpan: 3}]
      ]
    })
    autoTable(doc, {
      theme: 'grid',
      styles: {minCellWidth: 30,fontStyle: 'bold', font: 'times',halign: 'center', fontSize: 14,lineWidth: 1},
      body: [
        ["MINUTES OF MEETING"]
      ]
    })
		this.minutesData.agendas.forEach((agenda,index)=> {
      let preReads = [];
      if(agenda.pre_reads) {
        preReads.push(['Pre Reads '+parseInt(index+1), agenda.pre_reads])
      }
			let assignTasks = [];
			if (agenda.tasks.length !== 0)
			{
        // assignTasks.push(['Outcomes :-'])
				agenda.tasks.forEach((task, index) => {
					if (task.description) {      
						assignTasks.push([`Task ${index + 1}`, task.description])
            assignTasks.push([`Assignee`, task?.assigned_to.map(val => val.name + ' ' + val.lastname).join(', ')])
						if (task.supporters.length > 0) {
							assignTasks.push([`Supporters`, task.supporters.map(val => val.name + ' ' + val.lastname).join(', ')])
						}
						if (task.start_date && task.end_date) {
              assignTasks.push([`From`, task.start_date.split('T')[0]]);
              assignTasks.push([`To`, task.end_date.split('T')[0]]);
						}
					}
				});
			}
			let commentTasks = [];
			if (agenda.comments.length !== 0) {
        commentTasks.push(['All Outcomes :-'])
        agenda.comments.forEach((c, index) => {
				commentTasks.push([`Outcome${index + 1}`, c.comment])
				});
			}
      autoTable(doc, {
        theme: 'grid',
        styles: {minCellWidth: 30,fontStyle: 'bold', font: 'times'},
        body: [
          ['AGENDA '+parseInt(index+1), agenda.title],
          ['Description '+parseInt(index+1), agenda.description],
          ...preReads,
          ...assignTasks,
          ...commentTasks
        ]
      })
		});
    let start_date = this.minutesData?.start_date?.split('T')[0] ? this.minutesData.start_date?.split('T')[0] : '-'
    let end_date = this.minutesData.end_date?.split('T')[0] ? this.minutesData.end_date?.split('T')[0] : '-'
    autoTable(doc, {
      theme: 'plain',
      styles: {minCellWidth: 30,fontStyle: 'bold', font: 'times'},
      body: [
        ["START DATE: "+start_date, "END DATE: "+end_date],
        ["START TIME: "+moment(this.minutesData?.start_date).utc().format('HH:mm a'), "END TIME: "+moment(this.minutesData?.end_date).utc().format('HH:mm a')]
      ]
    })
    doc.save('MEETING-MINUTES.pdf');
  }
}

<form >
    <mat-card class="">
      <mat-card-content class="contentclass">
        <hr />
        <mat-grid-list cols="1" rowHeight="100px" gutterSize="16px" >
              <mat-grid-tile [colspan]="1" [rowspan]='7' >
                  <mat-form-field appearance="outline" class="field-spacer ">
                      <mat-label>Message</mat-label>
                      <textarea matInput placeholder="type message here"  rows="45"></textarea>
                  </mat-form-field>
              </mat-grid-tile>
              
              <mat-grid-tile >
                <div class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored">
                    <mat-label>Attachment</mat-label>
                    <input name="File1"  placeholder="Choose attachment" type="file" />
                  </div>
            </mat-grid-tile> 
        </mat-grid-list>
      </mat-card-content>
      <mat-card-actions align="end">
          <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="submit" >Create</button>
         
      </mat-card-actions>
    </mat-card>
  </form>

import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BusinessInfoService } from '@services/BusinessInfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogleadershipteamComponent } from '@shared/components/dialogleadershipteam/dialogleadershipteam.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CoreService } from '@services/CoreService';
import { language } from '@shared/config/language';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormBuilder,
  FormArray,
  NgForm,
} from '@angular/forms';
import { AuthService } from '@services/Auth.service';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import * as _ from 'lodash';

export interface LeadershipElement {
  teammember: string;
  phone: string;
  email: string;
  designation: string;
}

@Component({
  selector: 'app-leadership',
  templateUrl: './leadership.component.html',
  styleUrls: ['./leadership.component.css'],
})
export class LeadershipComponent implements OnInit {
  displayedColumns: string[] = [
    'name',
    'mobile_no',
    'email',
    'designation',
    'action',
  ];
  ELEMENT_DATA: LeadershipElement[];
  listData = new MatTableDataSource<LeadershipElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<LeadershipElement>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('LeadershipTable') table: MatTable<LeadershipElement>;
  @Input() modulefor: string;
  parentId: string;
  editindex: string = '-1';
  deleteData: [];
  resource = language;
  prevData: any[] = [];
  postform: FormGroup = new FormGroup({
    leadership_team: new FormArray([]),
  });
  searchKey: string;
  PERMISSION_EDIT = AuthService.authentication('Leadership', 'READ');
  PERMISSION_ADD = AuthService.authentication('Leadership', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Leadership', 'DELETE');

  constructor(
    private businessinfoservice: BusinessInfoService,
    private router: Router,
    private dialog: MatDialog,
    private coreservice: CoreService,
    private activeRoute: ActivatedRoute,
  ) {}
  ngOnInit(): void {
    AuthService.authenticate_redir('Leadership', ['READ']);

    this.initialLoad();
  }
  initialLoad() {
    if (this.modulefor == 'parent') {
      this.getparentleadershipdata();
    } else {
      this.getbusinessunitdata();
    }
    this.listData.sort = this.sort;
    this.listData.paginator = this.paginator;
  }
  getbusinessunitdata() {
    this.businessinfoservice.businessunitget('').subscribe(
      (response: any) => {
        const params = this.activeRoute.snapshot.queryParams;
        var data = _.find(response['data'], {_id:params['id']});
        this.listData.data = Object.values(
          data['leadership_team']
        ) as LeadershipElement[];
        this.table.renderRows();
        this.parentId = data['_id'];
        this.deleteData = data['leadership_team'];
        this.prevData = data['leadership_team'];

      },
      (err) => {}
    );
  }

  getparentleadershipdata() {
    this.businessinfoservice.businessinfoget().subscribe(
      (response: any) => {


        this.listData.data = Object.values(
          response['data'][0]['info']['leadership_team']
        ) as LeadershipElement[];

        this.table.renderRows();
        this.parentId = response['data'][0]['info']['_id'];
        this.deleteData = response['data'][0]['info']['leadership_team'];
        this.prevData = response['data'][0]['info']['leadership_team'];
        console.log('prev data ===========', this.prevData);
      },
      (err) => {}
    );
  }
  openDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogleadershipteamComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        id: this.parentId,
        prevdata: this.prevData,
        index: this.editindex,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.initialLoad();
    });
  }
  edit(row) {
    console.log('row::', row);
    console.log('this.listData', this.listData);
    this.editindex = this.editindex = this.listData.data
      .findIndex((item) => {
        return item['_id'] === row['_id'];
      })
      .toString();

    if (this.editindex !== '-1') {
      this.openDialog('', '', '');
    }
  }
  onDelete(index) {
    this.coreservice
      .openConfirmDialog(this.resource.message('LEADERSHIP_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.deleteData.splice(index, 1);
          var finalData = this.postform.getRawValue();
          for (let i = 0; i < this.deleteData.length; i++) {
            finalData['leadership_team'].push(this.deleteData[i]);
          }
          this.businessinfoservice
            .updateBusinessData(finalData, this.parentId)
            .subscribe(
              (response) => {
                const result = response.body;
                this.coreservice.success(
                  this.resource.message('LEADERSHIP_DELETED')
                );
                this.initialLoad();
              },
              (err) => {}
            );
        }
      });
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    console.log('SEARCH KEY', this.searchKey);
    this.listData.filterPredicate = function (data, filter) {
      console.log(data);
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [['Team Member', 'Mobile No.', 'Email ID', 'Designation']];
    this.listData.data.forEach((obj) => {
      let name = obj['name'] ? obj['name'] : '';
      let mobile_no = obj['mobile_no'] ? obj['mobile_no'] : '';
      let email = obj['email'] ? obj['email'] : '';
      let designation = obj['designation'] ? obj['designation'] : '';

      let arr = [name, mobile_no, email, designation];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      rowPageBreak: 'avoid',
    });

    if (action === 'save') {
      doc.save('leadership.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      // window.open(doc.output('bloburl') as unknown as string, '_blank');
      doc.output('dataurlnewwindow', { filename: 'leadership.pdf' });
    }
  }
}

import { Component, OnInit,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-suggestedagenda',
  templateUrl: './suggestedagenda.component.html',
  styleUrls: ['./suggestedagenda.component.css']
})
export class SuggestedagendaComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }
  
}

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { language } from '@shared/config/language';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthService } from '@services/Auth.service';
import { CommitteeService } from '@services/Committee.service';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { CoreService } from '@services/CoreService';

export interface MeetingElement {
  title: string;
  committee: string;
  upcoming: string;
  previous: string;
}

@Component({
  selector: 'app-committeemeetings',
  templateUrl: './committeemeetings.component.html',
  styleUrls: ['./committeemeetings.component.css'],
  providers: [DatePipe],
})
export class CommitteemeetingsComponent implements OnInit {
  resource = language;
  searchKey: string;
  displayedColumns: string[] = [
    'committee',
    'title',
    'start_date',
    'onstatus',
    'Action',
  ];
  ELEMENT_DATA: MeetingElement[];
  listData = new MatTableDataSource<MeetingElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<MeetingElement>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('MeetingsTable') table: MatTable<MeetingElement>;
  PERMISSION_EDIT = AuthService.authentication('CommitteeMeeting', 'READ');
  PERMISSION_ADD = AuthService.authentication('CommitteeMeeting', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('CommitteeMeeting', 'DELETE');

  constructor(
    private commiteeService: CommitteeService,
    private router: Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private datePipe: DatePipe,
    private coreservice: CoreService
  ) {}

  ngOnInit(): void {
    AuthService.authenticate_redir('CommitteeMeeting', ['READ']);
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllCommiteeMeetings();
  }
  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    console.log('SEARCH KEY', this.searchKey);
    this.listData.filterPredicate = function (data, filter) {
      console.log(data);
      var dataStr = ['committeeTitle', 'title', 'onstatus']
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }
  // Fetch committee meetings
  getAllCommiteeMeetings() {
    var filter = '?filter[type]=committee';
    this.commiteeService.getCommitteeMeetings(filter).subscribe(
      (response: any) => {
        const result = response.data.map(val => ({...val, onstatus: this.getStatus(val.meeting_status), committeeTitle: val.committee.title}));
        this.listData.data = result;
        this.table.renderRows();
      },
      (err) => {
        this.getAllCommiteeMeetings();
      }
    );
  }
  createMeeting() {
    this.router.navigate(['/committee/meetings']);
  }
  onEdit(row) {
    const status = row.meeting_status;
    let path = "/committee/meetings"
    if(status === 'mom_updated') {
      path = '';
    }
    else if(status === 'draft') {
      path = '/committee/participants';
    }
    else if(status === 'published') {
      path = '/committee/suggested';
    }
    else if(status === 'agenda_rationalized') {
      path = "committee/attendence";
    }
    else if(status === 'invite_sent') {
      path = "committee/agenda";
    }
    else if(status === 'marked_attendance') {
      path = '/committee/resolution';
    }
    else if(status === 'added_resolution') {
      path = '/committee/mom';
    }
    this.router.navigate([path], {
      queryParams: { id: row._id },
    });
  }
  onDelete(row, index) {
    this.coreservice
      .openConfirmDialog(this.resource.message('COMMITTEE_MEETING_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.commiteeService.removeCommitteeMeetings(row._id).subscribe(
            (response) => {
              const result = response;
              console.log(index);

              this.listData.data.splice(index, 1);
              this.listData.paginator = this.paginator;
              this.coreservice.success(
                this.resource.message('COMMITTEE_MEETING_DELETED')
              );
            },
            (err) => {}
          );
        }
      });
  }

  getStatus(status: string) {
    if(status === 'mom_updated' || status === 'mom_finalized' || status === 'mom_approved') {
      return 'Minutes Published'
    }
    else if(status === 'draft') {
      return 'Draft'
    }
    else if(status === 'published') {
      return 'Agenda Published'
    }
    else if(status === 'agenda_rationalized') {
      return 'Agenda Rationalized'
    }
    else if(status === 'invite_sent') {
      return 'Invite Sent'
    }
    else if(status === 'marked_attendance') {
      return 'Marked Attendance'
    }
    else if(status === 'added_resolution') {
      return 'Added Resolution'
    }
    else {
      return status;
    }
  }
  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [
      [
        'Committee Name',
        'Meeting Name',
        'Upcoming Meeting',
        'Previous Meeting',
      ],
    ];
    this.listData.data.forEach((obj) => {
      let committee_name = obj['committee']['title']
        ? obj['committee']['title']
        : '';
      let meeting_name = obj['title'] ? obj['title'] : '';
      let upcoming_meeting = '--';
      let previous_meeting = obj['start_date']
        ? this.datePipe.transform(obj['start_date'])
        : '';

      let arr = [
        committee_name,
        meeting_name,
        upcoming_meeting,
        previous_meeting,
      ];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      rowPageBreak: 'avoid',
    });

    if (action === 'save') {
      doc.save('committee-meetings.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      doc.output('dataurlnewwindow', { filename: 'committee-meetings.pdf' });
    }
  }
}

<div class="content p30">
  <h5 class="bold-text">Message Template</h5>
  <mat-card class="">
    <mat-card-header>
      <mat-card-title> Message Template </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="form-inline">
        <div>
          <label class="formtitle"> Email </label>
        </div>
        <hr />
        <div class="form-group" style="width: 100%">
          <div style="width: 450px" class="input-block p-0">
            <input
              type="file"
              #fileInput
              id="uploadFile"
              name="uploadFile"
              multiple="multiple"
              accept="image/*"
            />
            <span class="placeholder"> Upload Image </span>
            <span class="input-preview-btn"> Preview</span>
            <span class="input-remove-btn"> Remove</span>
          </div>

          <div class="input-block p-0">
            <mat-form-field class="example-full-width">
              <mat-label>Description</mat-label>
              <textarea matInput></textarea>
            </mat-form-field>
          </div>
        </div>
        <div>
          <label class="formtitle"> SMS </label>
        </div>
        <hr />
        <div class="form-group" style="width: 100%">
          <div class="input-block p-0">
            <mat-form-field class="example-full-width">
              <mat-label>Description</mat-label>
              <textarea matInput></textarea>
            </mat-form-field>
          </div>
        </div>
        <div>
          <label class="formtitle"> Whatsapp </label>
        </div>
        <hr />
        <div class="form-group" style="width: 100%">
          <div style="width: 450px" class="input-block p-0">
            <input
              type="file"
              #fileInput
              id="uploadFile"
              name="uploadFile"
              multiple="multiple"
              accept="image/*"
            />
            <span class="placeholder"> Upload Image </span>
            <span class="input-preview-btn"> Preview</span>
            <span class="input-remove-btn"> Remove</span>
          </div>

          <div class="input-block p-0">
            <mat-form-field class="example-full-width">
              <mat-label>Description</mat-label>
              <textarea matInput></textarea>
            </mat-form-field>
          </div>
        </div>
        <div>
          <label class="formtitle"> Select Users </label>
        </div>
        <hr />
        <div class="form-group" style="width: 100%">
          <div class="input-block p-0">
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>User</mat-label>
              <mat-select name="usertype">
                <mat-option
                  *ngFor="let usertype of userstype"
                  [value]="usertype.value"
                >
                  {{ usertype.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="input-block p-0">
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Family Name</mat-label>
              <mat-select
                name="familyname"
                (selectionChange)="onSelectionChanges($event)"
              >
                <mat-option
                  *ngFor="let familyname of listData"
                  [value]="familyname._id"
                >
                  {{ familyname.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
      <mat-table
        #userTable
        userTable
        matTableExporter
        [dataSource]="tableData"
        matSort
        class="mattable"
        #exporter="matTableExporter"
        [hiddenColumns]="[5, 6]"
      >
        <ng-container matColumnDef="Check">
          <mat-header-cell *matHeaderCellDef>Check</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <mat-checkbox
              (change)="updateCheckedList($event, element)"
            ></mat-checkbox>
          </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Profile Name</mat-header-cell
          >
          <mat-cell *matCellDef="let element"
            >{{ element.name }} {{ element.lastname }}</mat-cell
          >
        </ng-container>

        <!-- Mobile Column -->
        <ng-container matColumnDef="mobile">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Mobile No</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.mobile }}</mat-cell>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Email ID</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
        </ng-container>

        <!-- Role Column -->
        <ng-container matColumnDef="role">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Role</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.role }}</mat-cell>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Status</mat-header-cell
          >
          <mat-cell *matCellDef="let element"
            ><span
              [ngClass]="
                element.status == 'active' ? 'status-active' : 'status-inactive'
              "
              >{{ element.status | titlecase }}</span
            ></mat-cell
          >
        </ng-container>

        <!-- System Column -->

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 15, 20]"
        [pageSize]="5"
        showFirstLastButtons
      ></mat-paginator>
    </mat-card-content>
    <mat-card-actions align="end">
      <button
        mat-raised-button
        color="primary"
        class="btn btn-primary login-btn button-wider"
        type="button"
        (click)="SaveData()"
      >
        Save
      </button>
      <button mat-raised-button color="clear" class="button-wider">
        Cancel
      </button>
    </mat-card-actions>
  </mat-card>
</div>

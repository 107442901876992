<div class="content p30">
  <div id="block_container">
    <a
      routerLink="/othermeeting"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >
    <div id="bloc2">
      <h5>{{ resource.message("OTHERMEETING") }} / {{ labelText }}</h5>
    </div>
  </div>
  <form [formGroup]="form" >
    <mat-card class="">
      <mat-card-header>
        <mat-card-title class="meetingtitle">
          {{ resource.message("MEETINGDETAILS") }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="contentclass">
        <hr />
        <mat-grid-list cols="2" rowHeight="100px">
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <input type="hidden" formControlName="_id" />

              <mat-label>Meeting Title</mat-label>
              <input
                matInput
                placeholder="Meeting Title"
                formControlName="title"
              />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Venue</mat-label>
              <input matInput placeholder="Venue" formControlName="venue" />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Requested By</mat-label>
              <mat-select placeholder="Advisor" formControlName="requested_by">
                <mat-option
                  *ngFor="let user of REQUESTED_BY"
                  [value]="user._id + ''"
                  >{{ user.name }} {{ user.lastname }}</mat-option
                >
              </mat-select>
              <mat-error>This field is mandatory.</mat-error>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile [rowspan]="2">
            <mat-form-field appearance="outline" class="field-spacer agenda">
              <mat-label>Agenda</mat-label>
              <textarea
                matInput
                placeholder="Agenda"
                formControlName="agenda"
              ></textarea>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Participants</mat-label>
              <mat-select
                placeholder="Participants"
                formControlName="participants"
                required
                multiple
              >
                <mat-option
                  *ngFor="let user of PARTICIPANTS"
                  [value]="user._id + ''"
                  >{{ user.name }} {{ user.lastname }}</mat-option
                >
              </mat-select>
              <mat-error>This field is mandatory.</mat-error>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>

        <span class="example-spacer"></span>
        <div>
          <div class="datetime">
            <span>Date and Time</span>
          </div>
          <div class="dayevent">
            All Day Event &nbsp;&nbsp;
            <mat-slide-toggle #voting  (change)="changeToggle($event)"></mat-slide-toggle>
          </div>
        </div>
        <span class="example-spacer"></span>
        <hr />
        <mat-grid-list cols="2" rowHeight="100px">
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <!-- <mat-label>Start date</mat-label>
              <input
                matInput
                [matDatepicker]="dp3"
                [readonly]="true"
                [min]="today"
                formControlName="start_date"
                placeholder="Start date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dp3"
              ></mat-datepicker-toggle>
              <mat-datepicker #dp3 disabled="false"></mat-datepicker> -->
              <mat-label>Start date  and Time</mat-label>
              <input matInput type="datetime-local" disabled formControlName="start_date"
                placeholder="Start Date and Time" [min]="minDate" (change)="onDateRangeChanged('start')"  [max]="form.get('end_date').value" />
            </mat-form-field> </mat-grid-tile
          ><mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>End Date and Time</mat-label>
              <input matInput type="datetime-local" disabled formControlName="end_date" (change)="onDateRangeChanged('end')" placeholder="End Date and Time"
                [min]="form.get('start_date').value" />
              <!-- <mat-label>Start time </mat-label>
              <input
                [readonly]="true"
                matInput
                formControlName="start_time"
                [ngxMatTimepicker]="time"
                disabled
                placeholder="End time"
              />

              <ngx-mat-timepicker
                class="time"
                #time
                disabled="false"
              ></ngx-mat-timepicker> -->
            </mat-form-field>
          </mat-grid-tile>
          <!-- <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>End date</mat-label>
              <input
                matInput
                [min]="form.get('start_date').value"
                [matDatepicker]="dp4"
                [readonly]="true"
                formControlName="end_date"
                placeholder="End date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dp4"
              ></mat-datepicker-toggle>
              <mat-datepicker #dp4 disabled="false"></mat-datepicker>
            </mat-form-field> </mat-grid-tile
          ><mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>End time </mat-label>
              <input
                [readonly]="true"
                matInput
                formControlName="end_time"
                [ngxMatTimepicker]="time1"
                disabled
                placeholder="End time"
              />

              <ngx-mat-timepicker
                class="time"
                #time1
                disabled="false"
              ></ngx-mat-timepicker>
            </mat-form-field>
          </mat-grid-tile> -->
        </mat-grid-list>
        <div class="datetime">
          <span>Upload Minutes</span>
          &nbsp;&nbsp;
          <mat-slide-toggle
            [(checked)]="uploadMinutes"
            (change)="uploadMinutes = $event.checked"
            #voting
          ></mat-slide-toggle>
        </div>
        <hr />
        <mat-grid-list cols="2" rowHeight="100px">
          <mat-grid-tile>
            <div
              *ngIf="uploadMinutes === true"
              class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored"
            >
              <mat-label>Upload Minutes</mat-label>
              <input
                name="File1"
                placeholder="Upload Minutes"
                type="file"
                (change)="onFileChange($event)"
              />
              <a
                *ngIf="form.get('file').value"
                style="cursor: pointer"
                class="input-preview-btn"
                (click)="previewimg()"
              >
                Preview</a
              >
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
      <mat-card-actions align="end">
        <button
          mat-raised-button
          color="primary"
          class="btn btn-primary login-btn button-wider"
          type="submit"
          [disabled]="isSubmit"
          (click)="submit()"
        >
          Create
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>

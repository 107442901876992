import { Component, OnInit } from '@angular/core';
import { language } from '@shared/config/language';
import { FamiliesService } from '@services/Families.service';
import { DatePipe } from '@angular/common'
import { constants } from '@shared/config/constants';

@Component({
  selector: 'app-mysubscription',
  templateUrl: './mysubscription.component.html',
  styleUrls: ['./mysubscription.component.css'],
  providers: [DatePipe]

})
export class MysubscriptionComponent implements OnInit {

  
  resource = language;
  localstoragedata:any;
  planType:string;
  DATE_FORMAT = constants.GLOBAL_DATE_FORMAT;
  planDate=new Date();
  constructor(private familiesServer: FamiliesService,public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.localstoragedata=JSON.parse(localStorage.getItem('LOGGED_USER'));
    //var aYearFromNow = new Date();
    //aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
    this.familiesServer.getFamily(this.localstoragedata.family._id).subscribe(response=> {
      
      const result = response.body["family"];
      console.log("&&&&&&&&&&&&&&&",result.subscription) 
      this.planType=result.subscription.subscription
//      this.planDate = new Date(this.datepipe.transform(result.created_at, this.DATE_FORMAT));
      this.planDate = new Date(result.created_at);
      this.planDate.setFullYear(this.planDate.getFullYear() + 1);
      console.log(">>>>>>>>>>>>>>>>>>>",this.planDate)

    })
  }
  
}

<div class="p30">
  <form class="form-inline">
    <div class="form-group mr-2"></div>
    <span class="example-spacer"></span>
    <div class="mr-1">
      <button
        [disabled]="!PERMISSION_ADD"
        mat-raised-button
        color="primary"
        class="addbutton"
        class="buttonfloatright"
        (click)="addrole()"
      >
        <i class="fas fa-plus"></i>&nbsp;&nbsp;{{
          resource.message("ADD_CATEGORIES") | uppercase
        }}
      </button>
      <input
        matInput
        [(ngModel)]="searchKey"
        placeholder="{{ resource.message('SEARCH_BY_KNOWLEDGE') }}"
        autocomplete="off"
        (keyup)="applyfilter()"
        name="searchKey"
        class="searchfloatright"
      />
    </div>
  </form>
  <p>
    <br />
    <mat-table
      #roleTablecategoryTable
      roleTablecategoryTable
      matTableExporter
      [dataSource]="listData"
      matSort
      class="mattable"
      #exporter="matTableExporter"
      [hiddenColumns]="[1, 3, 4, 5]"
      id="print-section"
    >
      <!-- Name Column -->
      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Title</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
      </ng-container>

      <!-- System Column -->

      <!-- Action Column -->
      <ng-container matColumnDef="Action">
        <mat-header-cell style="padding-right: 144px" *matHeaderCellDef
          >Action</mat-header-cell
        >
        <mat-cell style="padding-right: 144px" *matCellDef="let row">
          <button
            mat-icon-button
            (click)="edit(row)"
            [disabled]="row.isSystem"
            [disabled]="!PERMISSION_EDIT || row.isSystem"
          >
            <mat-icon>mode_edit</mat-icon>
          </button>
          <button
            mat-icon-button
            color="warn"
            (click)="onDelete(row._id)"
            [disabled]="row?.children > 0 || !PERMISSION_DELETE || row.isSystem"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 15, 20]"
      [pageSize]="5"
      showFirstLastButtons
    ></mat-paginator>
  </p>
</div>

import { Component, OnInit, Inject } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormArray,
} from '@angular/forms';
import { language } from '@shared/config/language';
import { FileService } from '@services/File.service';
import { BusinessInfoService } from '@services/BusinessInfo.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CoreService } from '@services/CoreService';
import { CommitteeService } from '@services/Committee.service';
import { IdpService } from '@services/Idp.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dialogcommiteeagenda',
  templateUrl: './dialogcommiteeagenda.component.html',
  styleUrls: ['./dialogcommiteeagenda.component.css'],
})
export class DialogcommiteeagendaComponent implements OnInit {
  file_type = new FormControl('consensus');
  labelText: string;
  form: FormGroup = new FormGroup({
    title: new FormControl('', Validators.required),
    _id: new FormControl(''),
    resolution: new FormControl('consensus', Validators.required),
    description: new FormControl('', Validators.required),
    pre_reads: new FormControl(''),
    protocol: new FormControl('http://'),
  });
  resource = language;
  isEdit: boolean = false;
  CATEGORY: any;
  constructor(
    public dialogRef: MatDialogRef<DialogcommiteeagendaComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private committeeService: CommitteeService,
    private coreservice: CoreService,
    private idp: IdpService,
    private router: Router
  ) {}

  ngOnInit(): void {
    
    
    if (this.data.agendaId) {
      this.committeeService
        .getAgendaById(this.data.id, this.data.agendaId, this.data.defer)
        .subscribe((res) => {
          console.log(res);
          this.file_type.setValue(res['data'].resolution);

          this.form.patchValue({
            _id: this.data.agendaId,
            title: res['data'].title,
            resolution: res['data'].resolution,
            description: res['data'].description,
            pre_reads: res['data'].pre_reads.split('://')[1],
            protocol: (res['data'].pre_reads.split('://')[0] || 'http')+'://',
          });
        });

      this.form.patchValue({
        _id: this.data.agendaId,
      });
    }
    this.labelText = this.data.message;

    this.isEdit = this.data.isEdit;
    
    if(this.data.type === 'is_voting') {
      this.form.patchValue({ resolution: 'voting' })
      this.file_type.setValue('voting');
    }else {
      console.log(this.data.type)
      this.form.patchValue({ resolution: 'consensus' })
      this.file_type.setValue('consensus');
    }
    console.log(this.form,this.file_type)
  }

  updatepreReads() {
    this.form.patchValue({pre_reads:this.form.get('pre_reads').value.replace(/(^\w+:|^)\/\//, '') })
  }

  async submitData() {
    console.log(this.form)

    if (this.form.invalid) {
      return;
    }
    var constdata = this.form.getRawValue();
    constdata = {...constdata, pre_reads: constdata.pre_reads ? constdata.protocol+constdata.pre_reads : ''}
    // // // if(this.selFileName!='')
    // // // {
    // // //   let fileurl = this.selFileName;
    // // //   var formData: any = new FormData();
    // // //   formData.append("file", this.selFile);
    // // //   fileurl = await this.fileService.uploadFile(formData, true, false );
    // // //   constdata['img']= fileurl
    // // // }

    // // constdata['type']='business'

    // delete constdata['file']
    if (this.data.isEdit == false) {
      delete constdata['_id'];
      // constdata['business']=constdata['_id']
      this.committeeService.committeeAgenda(constdata, this.data.id).subscribe(
        (response) => {
          this.coreservice.success(this.resource.message('AGENDA_PUBLISHED'));
          this.router.navigate(['/committee/agenda'], {
            queryParams: { id: this.data.id },
          });
          this.closeDialog();
        },
        (err) => {}
      );
    } else {
      this.committeeService
        .updateCommitteeAgenda(constdata, this.data.id, this.data.agendaId)
        .subscribe(
          (response) => {
            this.coreservice.success(this.resource.message('AGENDA_EDITED'));
            this.router.navigate(['/committee/agenda'], {
              queryParams: { id: this.data.id },
            });
            this.closeDialog();
          },
          (err) => {}
        );
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}

<div class="content p30">

  <div id="block_container">
    <ng-container>
      <a
      routerLink="/committee/resolution"
      [queryParams]="{ id: parentId }"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
    </ng-container>
    <div id="bloc2">
      <h5>
        Committee Meetings / {{ endpointResults?.committee?.title }} /
            {{ endpointResults?.title }}
      </h5>
    </div>
  </div>
  
  <form [formGroup]="form" (ngSubmit)="submit()">
    <fieldset>
      <mat-card class="">
        <mat-card-content>
          <div class="devarea">
            <span>Rationalize Agenda</span>
          </div>
          <hr />
          <div class="row cancel">
            <button mat-stroked-button>Cancel Meeting</button>
          </div>
          <div class="row maindiv">
            <div class="row">
              <div class="meetingcol">{{ endpointResults?.title }}</div>
            </div>

            <div class="row">
              <div class="col-md-3 firstcol">Start Date and Time</div>
              <div class="col-md-3 firstcol">End Date and Time</div>
              <div class="col-md-2 firstcol">Participants</div>
              <div class="col-md-2 firstcol">Venue</div>
              <div class="col-md-2 firstcol">Actions</div>
              <div class="col-md-3 secondcol">
                {{ start_date | customDate }}
              </div>
              <div class="col-md-3 secondcol">{{ end_date | customDate }}</div>
              <div class="col-md-2 secondcol1">
                <button
                  type="button"
                  (click)="openparticipants('', '', '')"
                  mat-button
                  color="primary"
                >
                  View Invitees
                </button>
              </div>
              <div class="col-md-2 secondcol">{{ venue }}</div>
              <div class="col-md-2 secondcol">
                <button
                  mat-icon-button
                  routerLink="/committee/meetings"
                  [queryParams]="{ id: parentId }"
                >
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </div>
            </div>
            <!-- <div class="row thirdrow">
              <label>Upload Agenda</label>
              <input name="File2" placeholder="Agenda" type="text" matInput />
              <a
                class="input-preview-btn"
                *ngIf="url != '' && url != null"
                (click)="previewImg()"
              >
                Preview</a
              >
            </div> -->
          </div>
          <div class="maindiv">
            <div *ngFor="let agenda of agendas; let i = index ">
              <div class="row" *ngIf="agenda.title"> 
                <div class="meetingcol">Agenda: {{agenda.title }}</div>
              </div>
              <div class="row" *ngIf="!agenda.title"> 
                <div class="meetingcol">Offline Agenda</div>
              </div>
  
              <div class="row">
                <div class="col-md-4">
                  <div class="firstcol" >Description: {{agenda.description || ''}}</div>
                </div>
                <div class="col-md-4">
                  <div class="firstcol" *ngIf="agenda.pre_reads">Pre-reads: 
                    <strong><a [href]="agenda?.pre_reads" target="_blank">{{ (agenda?.pre_reads?.length>90)? (agenda?.pre_reads | slice:0:90)+'...':(agenda?.pre_reads) }}</a></strong>
                   </div>
                  <div>
                    
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="firstcol">
                    <button mat-stroked-button type="button" (click)="agenda.showoutcome = !agenda.showoutcome">
                      View Outcome
                    </button>
                  </div>  
                  <div *ngIf="agenda?.showoutcome">
                    <br>
                  <h4>All OutComes</h4>
                  <mat-list role="list" *ngFor="let comment of agenda?.comments;let i=index;">
              
                    <mat-list-item role="listitem" *ngIf="comment.comment">{{i+1}}. &nbsp;{{comment.comment}}</mat-list-item>
                  </mat-list>
                  </div>
                </div>

              </div>
            </div>
            
            
          </div>
          <fieldset>
            <!--[disabled]=" (familyname!=select_user_family) ? isDisabled :''"-->

            <mat-grid-list cols="2" rowHeight="100px">
              <mat-grid-tile>
                <button
                (click)="generatePdf()"
                mat-stroked-button
                type="button"
                class="wide"
              >
                MEETING-MINUTES.pdf
              </button>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Url</mat-label>
                  <input
                    matInput
                    placeholder="Paste URL Link"
                    formControlName="title"
                  />
                </mat-form-field>
              </mat-grid-tile>
              <!-- <mat-grid-tile>
                <div
                  class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored"
                >
                  <mat-label>Upload Agenda</mat-label>
                  <input
                    name="File1"
                    placeholder="MOM url"
                    type="file"
                    (change)="onFileChange($event)"
                    formControlName="file"
                  />

                  <a
                    class="input-preview-btn"
                    *ngIf="url != '' && url != null"
                    (click)="previewImg()"
                  >
                    Preview</a
                  >
                </div>
              </mat-grid-tile> -->
            </mat-grid-list>
          </fieldset>
        </mat-card-content>
        <span class="blankline"></span>
        <div class="form-group mr-2">
          <button
            routerLink="/committee/resolution"
            [queryParams]="{ id: parentId }"
            mat-stroked-button
            type="button"
            class="wide"
          >
            PREVIOUS
          </button>
        </div>
        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="submit"
          >
            Publish MOM
          </button>
        </mat-card-actions>
      </mat-card>
    </fieldset>
  </form>

</div>

<div class="content p30"> 

    <h5>{{resource.message('KNOWLEDGE')}}</h5>    
 

    
    <mat-card class="">
      <mat-card-header >            
        <mat-card-title > {{resource.message('KNOWLEDGE')}} </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <br />
        <mat-tab-group class="tabgroup" [selectedIndex]="activePath">
          <mat-tab label="Category" class="test">
            <app-category ></app-category>
          </mat-tab>
          <mat-tab label="Docs (Family Admin)"> 
            <app-list [source]="'familyadmin'"></app-list>
          </mat-tab>
          <mat-tab  label="Docs (Super Admin)" class="test">
            <app-list [source]="'template'"></app-list>             
        </mat-tab>
          
        </mat-tab-group>



      </mat-card-content>
    </mat-card>



  </div>

<div class="content p30"> 
    <div id="block_container">
          <a  routerLink="/tickets" id="bloc1" mat-flat-button class="mat-flat-button-transperent" ><span  class="material-icons">keyboard_backspace</span></a> 
          <div id="bloc2"><h5>{{resource.message('FAMILYOFFICE')}} /{{resource.message('REQUEST')}}</h5></div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" >
      <mat-card class="">
        <mat-card-header>            
          <mat-card-title> Request No. {{ticketno}} </mat-card-title>
        </mat-card-header>
        <mat-card-content class="contentclass">
          <hr />
          <div>
            <button mat-stroked-button color="primary"  type="button" (click)="onChange($event)" style="float: right;" >Assign</button>
          <!-- <p class="assign">Assign &nbsp;&nbsp;<mat-slide-toggle style="float: right;"  (change)="onChange($event)" formControlName="allowedlogs"></mat-slide-toggle></p> -->
        </div>  
        <br/><br/>
          <mat-grid-list cols="2" rowHeight="100px" >
              <mat-grid-tile  >
                    <mat-form-field appearance="outline" class="field-spacer">
                        <mat-select  placeholder="Status"  formControlName="request_status"  class="editselect">
                            <mat-option *ngFor="let type of STATUS_LIST | keyvalue" [value]="type.key">{{type.value}}</mat-option>
                         </mat-select>
                      </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile  >
                <div class="uploadcss field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored">
                    <mat-label>Upload File  &nbsp;</mat-label>
                    <input name="File1"  placeholder="Upload File" type="file" (change)="onFileChange($event)" formControlName="file"/>
                    <a
                      class="input-preview-btn"
                      *ngIf="file"
                      [href]="file"
                      target="_blank"
                    >
                      Preview</a
                    >
                    <a
                    *ngIf="file"
                    class="remove-preview-btn"
                    (click)="remove()"
                    
                  >
                  Remove
                </a>
                </div>
              </mat-grid-tile>
              <mat-grid-tile  *ngIf="showMem==false">
                <mat-form-field appearance="outline" class="field-spacer">
                    <mat-label>Remarks</mat-label>
                    <textarea matInput placeholder="Remarks" formControlName="description1"  rows="3"> </textarea>
                </mat-form-field>
          </mat-grid-tile>
              <mat-grid-tile *ngIf="showMem==true">
                <mat-form-field appearance="outline" class="field-spacer" >       
                  <mat-label>Members Name</mat-label>
                    <mat-select  placeholder="Members Name"  formControlName="subassign_userid" >
                      <mat-option *ngFor="let member of MEMBER_LIST" [value]="member.user_id+''">{{member.name}} {{member.lastname}}</mat-option>
                    </mat-select>
                  </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile  >
                <div class="uploadcss field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored">
                    <mat-label>Upload File &nbsp;</mat-label>
                    <input name="File1"  placeholder="Upload File" type="file" (change)="onFileChange1($event)" formControlName="file2"/>
                    <a
                      class="input-preview-btn"
                      *ngIf="file2"
                      [href]="file2"
                      target="_blank"
                    >
                      Preview</a
                    >
                    <a
                    *ngIf="file2"
                    class="remove-preview-btn"
                    (click)="remove2()"
                    
                  >
                  Remove
                </a>
                </div>
              </mat-grid-tile>
              <mat-grid-tile *ngIf="showMem==true" >
                    <mat-form-field appearance="outline" class="field-spacer">
                        <mat-label>Remarks</mat-label>
                        <textarea matInput placeholder="Remarks" formControlName="description1"  rows="3"> </textarea>
                    </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile *ngIf="showMem==false" ></mat-grid-tile>
               <mat-grid-tile  >
                <div class="uploadcss field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored">
                    <mat-label>Upload File  &nbsp;</mat-label>
                    <input name="File1"  placeholder="Upload File" type="file" (change)="onFileChange2($event)" formControlName="file3"/>
                    <a
                      class="input-preview-btn"
                      *ngIf="file3"
                      [href]="file3"
                      target="_blank"
                    >
                      Preview</a
                    >
                    <a
                    *ngIf="file3"
                    class="remove-preview-btn"
                    (click)="remove3()"
                    
                  >
                  Remove
                </a>
                </div>
              </mat-grid-tile>
             
          </mat-grid-list>
        </mat-card-content>
        <div style="font-size: 12px;"><em>Note: Please submit your changes to save latest changes.</em></div>
        <hr/>
        <mat-card-actions align="left">
          <div class="form-group mr-2">  
              <button mat-stroked-button type="button" class="wide" (click)="previousPage()">Previous</button>
          </div>
       <div class="rightpanel" align="right"> 
        <button mat-stroked-button color="primary"  type="button" (click)="onSubmit()">Update</button>
         <button mat-raised-button color="primary" class="btn btn-primary login-btn wide" type="submit" [disabled]="this.form.get('request_status').value=='in_review' || this.form.get('request_status').value=='in_progress'">Submit</button>
       </div> </mat-card-actions>
      </mat-card>
    </form>
  </div>
import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { UserElement } from '@modules/family-info/family-info.component';
import { AuthService } from '@services/Auth.service';
import { CoreService } from '@services/CoreService';
import { FamilyOfficeService } from '@services/FamilyOffice.service';
import { UserService } from '@services/UserService';
import { language } from '@shared/config/language';

@Component({
  selector: 'app-family-office-card',
  templateUrl: './family-office-card.component.html',
  styleUrls: ['./family-office-card.component.css'],
})
export class FamilyOfficeCardComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  total = 0;
  showFiller = false;
  ELEMENT_DATA: UserElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = [
    'ticket_no',
    'created_at',
    'description',
    'request_status',
  ];
  listData = new MatTableDataSource<UserElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<UserElement>(true, []);
  PERMISSION_EDIT = AuthService.authentication('Family Office', 'READ');
  PERMISSION_ADD = AuthService.authentication('Family Office', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Family Office', 'DELETE');
  localstoragedata: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('roleTable') table: MatTable<UserElement>;

  resource = language;
  constructor(
    private userservice: UserService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private coreservice: CoreService,
    private familyoffice: FamilyOfficeService
  ) {
    this.listData = new MatTableDataSource<UserElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<UserElement>(true, []);
  }

  ngOnInit(): void {
    AuthService.authenticate_redir('Family Office', ['READ']);

    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    console.log('LOGGED ROLE', this.localstoragedata.role);

    if (this.localstoragedata.role == 'admin') {
      this.getAllTicket();
    } else {
      this.getTicket();
    }

    this.table.renderRows();
  }

  getTicket() {
    this.familyoffice.assignedticket().subscribe(
      (response: any) => {
        console.log('Ticket Date', response.data);
        //const result = response.body;
        this.listData.data = response.data as UserElement[];
        this.table.renderRows();
      },
      (err) => {
        console.log('ERROR', err);
      }
    );
  }

  getAllTicket() {
    this.familyoffice.allticketget().subscribe(
      (response: any) => {
        console.log('Ticket Date', response.data);
        //const result = response.body;
        this.total = response?.data?.length;
        this.listData.data = response?.data?.slice(0, 5) as UserElement[];
        this.table.renderRows();
      },
      (err) => {
        console.log('ERROR', err);
      }
    );
  }

  stoppro($event) {
    $event.stopPropagation();
  }
  viewMore() {
    this.router.navigate(['tickets']);
  }
}

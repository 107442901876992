import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { language } from '@shared/config/language';
import { DialogmeetingdataComponent } from '@shared/components/dialogmeetingdata/dialogmeetingdata.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IdpService } from '@services/Idp.service';
import { DialogstatusgoalComponent } from '@shared/components/dialogstatusgoal/dialogstatusgoal.component';
export interface GoalElement {
  development: number;
  category: string;
  status: string;
}
@Component({
  selector: 'app-schedulemeeting',
  templateUrl: './schedulemeeting.component.html',
  styleUrls: ['./schedulemeeting.component.css'],
})
export class SchedulemeetingComponent implements OnInit {
  resource = language;
  ELEMENT_DATA: GoalElement[];
  rowModelType: 'serverSide';
  rowData: any;
  searchKey: string;

  displayedColumns: string[] = ['select', 'category', 'description', 'status'];
  listData = new MatTableDataSource<GoalElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<GoalElement>(true, []);
  localstoragedata: any;
  form: FormGroup = new FormGroup({
    _id: new FormControl(''),
    topic: new FormControl(''),
    others: new FormControl([]),
    title: new FormControl(''),
    start_date: new FormControl(''),
    end_date: new FormControl(''),
    venue: new FormControl(''),
  });

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('goalTable') table: MatTable<GoalElement>;
  parentid: string;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private idp: IdpService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.listData.sort = this.sort;
    this.listData.paginator = this.paginator;
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentid = data.id;
      }
    });
    this.getGoals(this.parentid);
  }
  openDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogstatusgoalComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        id: this.parentid,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  onSubmit() {
    const sel = this.selection['_selected'];
    let agenda = [];
    for (let i = 0; i < sel.length; i++) {
      console.log('>>>>>>>>>idp,', sel[i]._id);
      agenda.push(sel[i]._id);
    }
    console.log(agenda);
    const req = this.form.getRawValue();
    req['others'] = agenda;
    this.router.navigate(['createmeeting'], {
      queryParams: {
        id: this.parentid,
        top: req['topic'],
        a: JSON.stringify(agenda),
      },
    });

    // this.form.removeControl('_id');

    // const req = this.form.getRawValue();
    // req['others'] = req['topic'];
    // this.form.removeControl('topic');
    // this.router.navigate(['createmeeting']);

    // this.idp.idptopicpost(req).subscribe(
    //   (response: any) => {
    //     this.router.navigate(['createmeeting', this.form.get('topic')]);
    //   },
    //   (err) => {
    //     console.log('errrrrrrr', err);
    //   }
    // );
  }

  getGoals(id) {
    var filter = '';

    filter = '?filter[status]=in_progress&filter[user]=' + id; //+this.localstoragedata._id
    this.idp.useridpget(filter).subscribe((response: any) => {
      const result = response;
      console.log('>>>>>>>>>>>>>>', response.data);
      this.listData.data = response.data as GoalElement[];
      this.table.renderRows();
    });
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    //console.log(this.selection.selected);

    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.listData.data);
  }
  checkboxLabel(row?: GoalElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.development + 1
    }`;
  }
  redirectTo() {
    this.router.navigate(['idp']);
  }
  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }
}

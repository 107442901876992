<div class="content p30">
  <div id="block_container">
    <a
      routerLink="/createmeeting"
      [queryParams]="{ id: parentId }"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >
    <div id="bloc2">
      <h5>{{ resource.message("IDP") }} / Create Meeting</h5>
    </div>
  </div>
  <form [formGroup]="form">
    <fieldset>
      <!--[disabled]=" (familyname!=select_user_family) ? isDisabled :''"-->
      <mat-card class="">
        <mat-card-content>
          <span class="devarea">Agenda</span>
          <br />
          <hr />
          <div class="row cancel">
            <button mat-stroked-button (click)="cancelMeeting()">Cancel Meeting</button>
          </div>
          <div class="row maindiv">
            <div class="row">
              <div class="meetingcol">{{title}}</div>
            </div>
            <div class="row">
              <div class="col-md-3 firstcol">Start Date and Time</div>
              <div class="col-md-3 firstcol">End Date and Time</div>
              <div class="col-md-2 firstcol">Participants</div>
              <div class="col-md-2 firstcol">Venue</div>
              <div class="col-md-2 firstcol">Actions</div>
              <div class="col-md-3 secondcol">
                {{ start_date | customDate }} {{ start_time }}
              </div>
              <div class="col-md-3 secondcol">
                {{ end_date | customDate }} {{ end_time }}
              </div>
              <div class="col-md-2 secondcol1">
                <button
                  mat-button
                  color="primary"
                  (click)="openDialog('', '', '')"
                >
                  View Invitees
                </button>
              </div>
              <div class="col-md-2 secondcol">{{ venue }}</div>
              <div class="col-md-2 secondcol">
                <button
                  mat-icon-button
                  routerLink="/createmeeting"
                  [queryParams]="{ id: parentId }"
                >
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </div>
            </div>
          </div>

          <mat-grid-list cols="2" rowHeight="100px">
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Category</mat-label>
                <input
                  matInput
                  placeholder="Category"
                  formControlName="category"
                  readonly
                />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Goal</mat-label>
                <input
                  matInput
                  placeholder="Goal"
                  formControlName="goal"
                  readonly
                />
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
          <br />
          <br />
          <span class="devarea">Other discussion topics</span>
          <hr />
          <div class="row">
            <div class="col-md-4" *ngFor="let topic of topics">
              <mat-card class="topic-card">{{ topic }}</mat-card>
            </div>
          </div>
        </mat-card-content>
        <span class="blankline"></span>
        <div class="form-group mr-2">
          <button
            mat-stroked-button
            type="button"
            class="wide"
            routerLink="/createmeeting"
            [queryParams]="{ id: parentId }"
          >
            PREVIOUS
          </button>
        </div>
        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="button"
            (click)="submit()"
          >
            Publish Agenda
          </button>
        </mat-card-actions>
      </mat-card>
    </fieldset>
  </form>
</div>

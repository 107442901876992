import { Component, OnInit, Input } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormBuilder,
  FormArray,
  NgForm,
} from '@angular/forms';
import { CoreService } from '@services/CoreService';
import { IdpService } from '@services/Idp.service';
import { language } from '@shared/config/language';

@Component({
  selector: 'app-outcometodo',
  templateUrl: './outcometodo.component.html',
  styleUrls: ['./outcometodo.component.css'],
})
export class OutcometodoComponent implements OnInit {
  LEVELS_LIST: any[] = [];
  resource = language;
  @Input() parentid: String;
  @Input() agendaid: String;
  @Input() viewdata: Boolean;

  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    social_links: this.fb.array([]),
  });
  constructor(private fb: FormBuilder, private idp: IdpService,  private coreservice: CoreService) {}

  ngOnInit(): void {
    console.log(this.viewdata);
    if (this.viewdata === true) {
      this.getMeetings(this.parentid);
    } else {
      this.sociallinks.push(this.createTodoItem());
    }
  }

  createTodoItem(): FormGroup {
    return this.fb.group({
      todo: '',
    });
  }
  get sociallinks() {
    return this.form.get('social_links') as FormArray;
  }
  addSocial() {
    this.sociallinks.push(this.fb.group({ todo: '' }));
  }
  addTodos() {
    var alltodo = this.form.getRawValue();
    console.log(alltodo);
    var data = [];
    for (let i = 0; i < alltodo.social_links.length; i++) {
      data.push({
        title: alltodo.social_links[i].todo,
      });
    }

    this.idp.idptodopost(this.parentid, this.agendaid, data).subscribe(
      (response: any) => {
        this.createTodoItem();
        this.coreservice.success(this.resource.message('SAVED_SUCCCESSFULLY'));
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  getMeetings(id) {
    var filter = '';
    filter = '?filter[_id]=' + id; //+this.localstoragedata._id
    this.idp.idpMeetingGet(filter).subscribe(
      (response: any) => {
        let outcomeData = response.data[0].agendas.find(
          (outcome) => outcome._id === this.agendaid
        );
        let task_init = [];
        for (let i = 0; i < outcomeData.tasks.length; i++) {
          this.sociallinks.push(
            this.fb.group({ todo: outcomeData.tasks[i].title })
          );
          task_init.push(outcomeData.tasks[i].title);

          console.log('FINAL::::', task_init);
        }

        // this.form.patchValue({
        //   social_links: task_init,
        // });
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
}

<div class="content p30">
  <h5>{{ resource.message("COMMITTEEMEETINGS") }}</h5>
  <mat-card class="">
    <mat-card-header>
      <mat-card-title>
        {{ resource.message("COMMITTEEMEETINGS") }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group class="tabgroup">
        <mat-tab label="Committee Meetings" class="test">
          <app-committeemeetings></app-committeemeetings>
        </mat-tab>
        <mat-tab label="Minutes" class="test">
          <app-minutes></app-minutes>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-sm-7">
      <div *ngIf="!message && isTokenValid" class="main-div align-middle">
        <h3 class="bold-text">Change your Password</h3>
        <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="form-group">
            <label class="login-label"> Password</label>
            <input
              type="password"
              formControlName="password1"
              class="form-control"
            />
            <!-- <div
              class="alert alert-danger"
              role="alert"
              *ngIf="errorMessage"
              align="center"
            >
              {{ errorMessage }}
            </div> -->
          </div>
          <div class="form-group">
            <label class="login-label">Confirm password</label>
            <input
              type="password"
              formControlName="password2"
              class="form-control"
            />
            <div
              class="alert alert-danger"
              role="alert"
              *ngIf="
                form.get('password2').errors &&
                form.get('password2').errors.areDifferent === true
              "
              align="center"
            >
              Both passwords must be same!
            </div>
          </div>
          <button
            [disabled]="form.invalid"
            class="btn btn-primary login-btn"
            type="submit"
          >
            Submit
          </button>
        </form>

        <!-- <div class="backtologin">
          <button type="button" class="btn btn-primary back-login-btn">
            <a href="index.html">
              <i class="fa fa-arrow-left"></i> &nbsp; Back to Login
            </a>
          </button>
        </div> -->
      </div>
      <div *ngIf="!isTokenValid" class="main-div align-middle">
        <div
          style="padding: 8px"
          class="alert alert-danger"
          role="alert"
          align="center"
        >
          Invalid token!
        </div>
      </div>
      <div
        *ngIf="isTokenValid && message === 'Password changed'"
        class="main-div align-middle"
      >
        <div
          style="padding: 8px"
          class="alert alert-success"
          role="alert"
          align="center"
        >
          Password Changed. Redirecting you to login page...
        </div>
      </div>
      <div
        *ngIf="
          isTokenValid && message === 'Something went wrong. Please try again!'
        "
        class="main-div align-middle"
      >
        <div
          style="padding: 8px"
          class="alert alert-danger"
          role="alert"
          align="center"
        >
          Something went wrong. Please try again!
        </div>
      </div>
    </div>
    <div class="col-sm-5">
      <div class="login-bg">
        <img src="../../../assets/images/fb-logo-white.png" class="fb-logo" />
      </div>
    </div>
  </div>
</div>
<!--container-fluid-->

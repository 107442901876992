<div class="content p30">
  <div id="block_container">
    <a
    routerLink="/committee/rationalized"
    [queryParams]="{ id: parentid }"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >
    <div id="bloc2">
      <h5>
        Committee Meetings / {{ endpointResults?.committee?.title }} /
            {{ endpointResults?.title }}
      </h5>
    </div>
  </div>
  <form [formGroup]="form">
    <fieldset>
      <!--[disabled]=" (familyname!=select_user_family) ? isDisabled :''"-->
      <mat-card class="">
        <mat-card-content>
          <span class="devarea">Take Attendence</span>
          <br />
          <hr />
          <div class="row cancel">
            <button mat-stroked-button (click)="cancelMeeting()">
              Cancel Meeting
            </button>
          </div>
          <div class="row maindiv">
            <div class="row">
              <div class="meetingcol">{{meetingTitle}}</div>
            </div>
            <div class="row">
              <div class="col-md-3 firstcol">Start Date and Time</div>
              <div class="col-md-3 firstcol">End Date and Time</div>
              <div class="col-md-2 firstcol">Participants</div>
              <div class="col-md-2 firstcol">Venue</div>
              <div class="col-md-2 firstcol">Actions</div>
              <div class="col-md-3 secondcol">
                {{ start_date | customDate }}
              </div>
              <div class="col-md-3 secondcol">{{ end_date | customDate }}</div>
              <div class="col-md-2 secondcol1">
                <button
                  mat-button
                  color="primary"
                  (click)="openparticipants('', '', '')"
                >
                  View Invitees
                </button>
              </div>
              <div class="col-md-2 secondcol">{{ venue }}</div>
              <div class="col-md-2 secondcol">
                <button type="button" mat-icon-button (click)="edit_meeting()">
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </div>
            </div>
          </div>

          <br />
          <span class="devarea">Participants </span>
          <hr />
          <p>
            <br />
            <mat-table
              #goalTable
              goalTable
              matTableExporter
              [dataSource]="listData"
              matSort
              class="mattable"
              #exporter="matTableExporter"
              [hiddenColumns]="[5, 6]"
            >
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Name of the member</mat-header-cell
                >
                <mat-cell *matCellDef="let element"
                  >{{ element.name }} {{ element.lastname }}</mat-cell
                >
              </ng-container>

              <!-- Mobile Column -->
              <ng-container matColumnDef="attendence">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Meeting Invite</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                  <!-- {{element.invite_status }}--></mat-cell>
              </ng-container>

              <!-- Email Column -->
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                  >Status</mat-header-cell
                >
                <mat-cell *matCellDef="let element"
                  ><button
                    (click)="markAttendence(true, element)"
                    mat-button
                    [ngClass]="
                      element.attendance == true
                        ? 'status-success'
                        : 'status-present'
                    "
                  >
                    Present
                  </button>
                  <button
                    (click)="markAttendence(false, element)"
                    mat-button
                    [ngClass]="
                      element.attendance !== true
                        ? 'status-danger'
                        : 'status-present'
                    "
                  >
                    Absent
                  </button>
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row>
            </mat-table>
            <mat-paginator
              [pageSizeOptions]="[5, 10, 15, 20]"
              [pageSize]="5"
              showFirstLastButtons
            ></mat-paginator>
          </p>
        </mat-card-content>
        <span class="blankline"></span>
        <div class="form-group mr-2">
          <button
            mat-stroked-button
            type="button"
            class="wide"
            routerLink="/committee/rationalized"
            [queryParams]="{ id: parentid }"
          >
            PREVIOUS
          </button>
        </div>
        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="button"
            (click)="next_clcik()"
          >
            Next
          </button>
        </mat-card-actions>
      </mat-card>
    </fieldset>
  </form>
</div>

import { Component, OnInit, ViewChild ,ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { AdminKnowledgeHubService } from '@services/AdminKnowledgeHub';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import {CoreService} from '@services/CoreService';
import { MatMenuTrigger } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import { AuthService } from '@services/Auth.service';

export interface KnowldgeHubElement {
  id: number;
  filename: string;
  title: string;
  created: string;
  updated: string;
}


@Component({
  selector: 'app-adminknowledgehub',
  templateUrl: './adminknowledgehub.component.html',
  styleUrls: ['./adminknowledgehub.component.css']
})
export class AdminknowledgehubComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;
  
  PERMISSION_EDIT = AuthService.authentication('Knowledge Hub','EDIT');
  PERMISSION_ADD = AuthService.authentication('Knowledge Hub','ADD');
  PERMISSION_DELETE = AuthService.authentication('Knowledge Hub','DELETE');


  ELEMENT_DATA: KnowldgeHubElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = [ 'title','download','created_at',   'Action'];
  listData = new MatTableDataSource <KnowldgeHubElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<KnowldgeHubElement>(true, []);
 



  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<KnowldgeHubElement>;

  resource = language;

  constructor(private knowledgeService: AdminKnowledgeHubService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router : Router,
    private http: HttpClient,
    private coreservice:CoreService
    ) {

     // AuthService.authenticate_redir('Knowledge Hub', ['READ']);

    this.listData = new MatTableDataSource<KnowldgeHubElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<KnowldgeHubElement>(true, []);


console.log(language.message("a"));

   }

  ngOnInit(): void {
 
    this.listData.paginator=this.paginator;
    this.listData.sort=this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllKnowledge();
   // this.table.renderRows();   

   
    
 

    

 }



  getAllKnowledge()
  {

    this.knowledgeService.getKnwoledgeFiles('superadmin').subscribe(response=> {
      
      const result = response.body;
      this.listData.data=response.body['results'] as KnowldgeHubElement[]
      this.table.renderRows();

     }, (err) => {

        if(err.error.status != null){
//          this.errmessage = err.error.message;
        }else{
 //         this.errmessage = err.error.errors[0].msg;
        }
      
   })

   


   

   }



   onSearchClear()
   {
     this.searchKey="";    
     this.applyfilter();
   }
   applyfilter()
   {
    this.listData.filterPredicate = 
    function (data, filter) {
        var dataStr = Object.keys(data).reduce(function (currentTerm, key) {
            return currentTerm + data[key] + '◬';
        }, '').toLowerCase();
        var transformedFilter = filter.trim().toLowerCase();
        return dataStr.indexOf(transformedFilter) != -1;
    };
     this.listData.filter=this.searchKey.trim().toLowerCase();
   }
   

   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.listData.data.forEach(row => this.selection.select(row));
  }

  addKnwoledge(){
    this.router.navigate(['/adminknowledgehub/add']);

  }

  edit(role){
    this.router.navigate(['adminknowledgehub/add'],{queryParams: {id: role._id}});

  }

  onDelete(id){
    this.coreservice.openConfirmDialog(this.resource.message('KNOWLEDGE_DELETE'))
    .afterClosed().subscribe(res=>{
      if(res){
        this.knowledgeService.removeKnowledgeFile(id).subscribe(response=> {
          const result = response;
          this.getAllKnowledge();
          this.coreservice.success(this.resource.message('KNOWLEDGE_DELETED'));

         }, (err) => {
    })

        
        
      }
    })
  }



  closeMyMenu() {
    this.trigger.closeMenu();
  }


  
  filterData(ev,col,val) 
  {
    if(ev.checked){
      col = String(col);
       
      let value = {[col]:val};
      this.filterValues.push(value);
    }else{
      let value = {[col]:val};
      const index = this.filterValues.indexOf(value);
      this.filterValues.splice(index-1,1);
    }

    
    this.setupFilter(col);
    this.applyFilter(val)
   
  } 

  stoppro($event){
    $event.stopPropagation();
   
  }

  setupFilter(column: string) {
    this.listData.filterPredicate = (d: any, filter: string) => {
    const val = JSON.parse(filter);
    var flag = false;

      val.forEach(function(item){  
         let obj = item;
         const textToSearch = d[column] && d[column].toLowerCase() || '';
            if(obj[column] != undefined){
              if(textToSearch.toLowerCase().indexOf(obj[column].toLowerCase()) !== -1){
                flag = true;
              }
            }
            
        }); 
        return flag;
     
    };
  }
  
  applyFilter(filterValue: string) {
    
    this.listData.filter =  JSON.stringify(this.filterValues);// filterValue.trim().toLowerCase();
  }


  filterValues = [];
}

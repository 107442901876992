<div style="background: #fff; padding: 16px; border-radius: 5px">
  <div style="display: flex; justify-content: space-between">
    <h4><i class="fa fa-briefcase"></i> &nbsp;My Tasks</h4>
    <button (click)="addTask()" mat-stroked-button class="viewmore">
      Add Task
    </button>
  </div>
  <mat-table
    #roleTable
    roleTable
    matTableExporter
    [dataSource]="listData"
    matSort
    class="mattable card"
    #exporter="matTableExporter"
    [hiddenColumns]="[5]"
    id="print-section"
  >
    <!-- Name Column -->
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Description</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="start_date">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Start Date & Time</mat-header-cell
      >
      <mat-cell *matCellDef="let element"
        >{{ element.start_date | customDate }}
        {{ element?.start_time }}</mat-cell
      >
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="end_date">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >End Date & Time</mat-header-cell
      >
      <mat-cell *matCellDef="let element"
        >{{ element.end_date | customDate }} {{ element?.end_time }}</mat-cell
      >
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <div *ngIf="total > 5" style="display: flex; justify-content: end">
    <button
      (click)="viewMore()"
      style="float: none"
      mat-stroked-button
      class="viewmore"
    >
      View More
    </button>
  </div>
</div>

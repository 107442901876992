<div align="right">
  <div class="content-container">
    <mat-card class="cardclass">
      <mat-card-header>
        <mat-card-title>
          {{ resource.message("BUSINESSIMAGE") }}
        </mat-card-title>
        <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
      </mat-card-header>
      <hr />

      <form [formGroup]="photoform" (ngSubmit)="submitPhoto()">
        <div class="formdesign">
          <div class="input-block p-0">
            <input type="hidden" formControlName="_id" />
            <input
              type="file"
              name="File1"
              formControlName="file"
              (change)="onFileChange($event)"
            />
            <span class="placeholder"> Upload Image </span>
          </div>
          <mat-form-field appearance="outline" class="matlabel">
            <mat-label>Title</mat-label>
            <input matInput formControlName="title" />
          </mat-form-field>
        </div>
        <div class="formdesign">
          <mat-form-field appearance="outline" class="matlabel">
            <mat-label>URL</mat-label>
            <input matInput formControlName="url" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="matlabel">
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description"></textarea>
          </mat-form-field>
        </div>
        <hr />
        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="submit"
            [disabled]="photoform.invalid || isSubmitingForm"
          >
            <span style="display: flex; justify-content: center; gap: 4px">
              <span>Save</span>
              <mat-progress-spinner
                style="margin-top: 6px"
                mode="indeterminate"
                diameter="24"
                *ngIf="isSubmitingForm === true"
              ></mat-progress-spinner>
            </span>
          </button>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
</div>

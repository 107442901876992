import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectorRef,
  TemplateRef,
} from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { language } from '@shared/config/language';
import { IdpService } from '@services/Idp.service';
import { CommitteeService } from '@services/Committee.service';
import { DialogcommitteeparticipantsComponent } from '@shared/components/dialogcommitteeparticipants/dialogcommitteeparticipants.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CoreService } from '@shared/services/CoreService';
import * as _ from 'lodash';
import { constants } from '@shared/config/constants';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

export interface MeetingElement {
  name: string;
}
export interface SupMeetingElement {
  supname: string;
}
@Component({
  selector: 'app-commiteetask',
  templateUrl: './commiteetask.component.html',
  styleUrls: ['./commiteetask.component.css'],
})
export class CommiteetaskComponent implements OnInit {
  @Input() parentid: String;
  @Input() agendaid: String;
  @Input() viewdata: Boolean;

  @ViewChild('firstDialog') firstDialog: TemplateRef<any>;
  @ViewChild('secondDialog') secondDialog: TemplateRef<any>;

  
  ELEMENT_DATA: MeetingElement[];
  SUPELEMENT_DATA: MeetingElement[];
  rowModelType: 'serverSide';
  rowData: any;
  resolution!: string;
  todayDate:Date = new Date();
  displayedColumns: string[] = ['name', 'Action'];
  listData = new MatTableDataSource<MeetingElement>();
  displayedSupColumns: string[] = ['supname', 'Action1'];
  supData = new MatTableDataSource<SupMeetingElement>();
  localstoragedata: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('MeetingsTable') table: MatTable<MeetingElement>;
  @ViewChild(MatSort, { static: true }) supsort: MatSort;
  @ViewChild(MatPaginator, { static: true }) suppaginator: MatPaginator;
  @ViewChild('SupMeetingsTable') suptable: MatTable<SupMeetingElement>;

  searchKey: string;
  allMembers = [];
  members = [];
  resource = language;

  data: {};

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private committeeService: CommitteeService,
    private dialog: MatDialog,
    private coreservice: CoreService,
    private datepipe: DatePipe
    
  ) {
    
  }
  form: FormGroup = new FormGroup({
    title: new FormControl('', Validators.required),
    start_date: new FormControl('', Validators.required),
    end_date: new FormControl('', Validators.required),
  }, { validators : this.dateLessThan('start_date', 'end_date')});
  ngOnInit(): void {
   
    console.log(this.parentid);
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    
    // this.supData.sort = this.supsort;
    // this.supData.paginator = this.suppaginator;
    this.changeDetectorRefs.detectChanges();
    this.getAllCommiteeMeetings(this.parentid);
  }
  dateLessThan(from: string, to: string) {
    return (group: FormGroup): {[key: string]: any} => {
     let f = group.controls[from];
     let t = group.controls[to];
     if (f.value > t.value) {
       t.setErrors({ endTimeGreaterThanStartTime: true });
       return {
         dates: "Start Date should be less than end Date"
       };
     }
     t.setErrors(null);
    }
  }
  getAllCommiteeMeetings(id) {
    this.changeDetectorRefs.detectChanges();

    var filter = '/' + id;

    this.committeeService.getCommitteeMeetings(filter).subscribe(
      (response: any) => {

        let outcomeData = response.data.agendas.find(
          (outcome) => outcome._id === this.agendaid
        );
        this.resolution = outcomeData.resolution;
        console.log(outcomeData,this.agendaid)
        this.form.patchValue({
          title: outcomeData.tasks[0]?.description || '',
          start_date: outcomeData.tasks.length ? this.datepipe.transform(
            outcomeData.tasks[0].start_date,
            constants.YYYY_MM_DD_DATE_FORMAT
          ): '',
          end_date: outcomeData.tasks.length ? this.datepipe.transform(
            outcomeData.tasks[0].end_date,
            constants.YYYY_MM_DD_DATE_FORMAT
          ): '',
        })
        
        const task =  outcomeData.tasks[0];
        if(outcomeData.tasks.length) {
          this.allMembers = task.assigned_to.map(val => ({
            ...val,
            name: val.name + ' ' + val.lastname,
            id: val._id,
            user_id: val._id
          }))
          this.listData.data = this.allMembers;
          this.table.renderRows();
          this.changeDetectorRefs.detectChanges();
          console.log(response.data.members);

          this.members = task.supporters.map(val => ({
              ...val,
              supname: val.name + ' ' + val.lastname,
              id: val._id,
              user_id: val._id
          }))
          this.supData.data = this.members;
          
          this.suptable.renderRows();
          this.changeDetectorRefs.detectChanges();
          //this.listData.data = response.data.members as MeetingElement[];
          //   this.table.renderRows();
      }
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  openDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogcommitteeparticipantsComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result && result.data){
      console.log('>><<<<>>>>', result);
      for (let i = 0; i < result.data.length; i++) {
        // //  result.data[i].committee.members[i].type = 'Permanent Member';
        // this.allMembers.push({
        //   committee_role: result.data[i].role,
        //   id: result.data[i]._id,
        //   mobile: result.data[i].mobile,
        //   name: result.data[i].name + '' + result.data[i].lastname,
        //   profile_photo: result.data[i].profile_photo,
        //   role: result.data[i].role,
        //   type: 'Temporary Invitee',
        // });
        
        let found = _.find(this.supData.data,{ 'id': result.data[i]['_id'] } )
        let findInPar = _.find(this.allMembers,{ 'id': result.data[i]['_id'] })
        if(found || findInPar){
          this.dialog.open(this.secondDialog);
        }
        else{
          this.allMembers.push({
            ...result.data[i],
            name: result.data[i].name + '' + result.data[i].lastname,
            id: result.data[i]._id,
          });
        }
      }
      this.listData.data = this.allMembers;
      this.table.renderRows();
      this.changeDetectorRefs.detectChanges();
    }
    });
  }

  openSupDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogcommitteeparticipantsComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('>><<<<>>>>', result);
      
      for (let i = 0; i < result.data.length; i++) {

        let found = _.find(this.listData.data,{ 'id': result.data[i]['_id'] } )
        let findInSup = _.find(this.members,{ 'id': result.data[i]['_id'] })

        if(found || findInSup){
          this.dialog.open(this.secondDialog);
          // alert('')
        }
        else{
          this.members.push({
            ...result.data[i],
            supname: result.data[i].name + '' + result.data[i].lastname,
            id: result.data[i]._id,
          });
        }
      }
      this.supData.data = this.members;
      console.log(this.members, this.listData.data)
      this.suptable.renderRows();
      this.changeDetectorRefs.detectChanges();
    });
  }

  //Save task
  saveTask() {
    if(this.form.invalid) {
      return;
    }
    let newdata = this.form.getRawValue();
    console.log(this.form.getRawValue());
    var finaldata = [
      {
        title: '',
        start_date: '',
        end_date: '',
        description: '',
      },
    ];
    console.log(this.members)
    var assigned_to = this.allMembers.map((mem) => mem.user_id);
    var supporters = this.members.map((mem) => mem.user_id);
    console.log('>>>>>>>', assigned_to);
    console.log('>>>>>>>', this.members);
    newdata.description = newdata.title;
    newdata.assigned_to = assigned_to;
    newdata['supporters'] = supporters;
    newdata['resolution'] = this.resolution;
    newdata.start_date = moment(newdata.start_date).format('yyyy-MM-DD');
    newdata.end_date = moment(newdata.end_date).format('yyyy-MM-DD');
    this.committeeService
      .postCommitteeTask(this.parentid, this.agendaid, newdata)
      .subscribe(
        (response: any) => {
          this.coreservice.success('Task Saved Sucessfully!');
        },
        (err) => {
          console.log('errrrrrrr', err);
        }
      );
  }
  removeParticipant(id){
    this.listData.data = _.filter(this.listData.data, (o)=>o.id != id);
    this.allMembers = _.filter(this.allMembers, (o)=>o.id != id);
  }

  removeSupporter(id){
    this.supData.data = _.filter(this.supData.data, (o)=>o.id != id);
    this.members = _.filter(this.members, (o)=>o.id != id);
  }
}
//postCommitteeTask

<div class="content p30">
  <div id="block_container">
    <button
      (click)="backToMain()"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></button
    >

    <div id="bloc2">
      <h5>{{ resource.message("ADDBUSINESSINFO") }}</h5>
    </div>
  </div>
  <mat-card class="">
    <mat-card-header>
      <mat-card-title>
        {{ resource.message("BUSINESSINFOTITLE") }}
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <mat-tab-group class="tabgroup">
        <!--Business Unit-->
        <mat-tab label="Business Unit" class="tabcolor">
          <form [formGroup]="unitform" (ngSubmit)="submit()">
            <h6>Basic Details</h6>
            <hr />
            <mat-grid-list cols="2" rowHeight="100px">
              <mat-grid-tile>
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Business Unit Name</mat-label>
                  <input
                    matInput
                    placeholder="Business Unit Name"
                    formControlName="name"
                  />
                </mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile>
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Date of Establishment</mat-label>
                  <input
                    class="custom_disabled"
                    matInput
                    [max]="maxDate"
                    [matDatepicker]="dp3"
                    disabled
                    formControlName="established"
                    placeholder="Date Of Establishment" />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dp3"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #dp3 disabled="false"></mat-datepicker
                ></mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile>
                <div
                  class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored"
                >
                  <span class="placeholder"> Upload Photo </span>
                  <input
                    name="File1"
                    placeholder="File"
                    type="file"
                    (change)="onFileChange($event)"
                    formControlName="file"
                  />

                  <span
                    class="input-preview-btn"
                    (click)="previewimg()"
                    *ngIf="this.unitform.get('logo').value !== ''"
                  >
                    Preview</span
                  >
                </div>
              </mat-grid-tile>

              <mat-grid-tile>
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Description</mat-label>
                  <textarea
                    matInput
                    placeholder="Description"
                    formControlName="about"
                  >
                  </textarea>
                </mat-form-field>
              </mat-grid-tile>
            </mat-grid-list>

            <h6>Company Details</h6>
            <hr />

            <mat-grid-list cols="2" rowHeight="100px">
              <mat-grid-tile>
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Website Link</mat-label>
                  <input
                    matInput
                    placeholder="Website Link"
                    formControlName="website"
                  />
                </mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile>
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Email ID</mat-label>
                  <input
                    matInput
                    placeholder="Email ID"
                    formControlName="email"
                  />
                </mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile>
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Contact Number 1</mat-label>
                  <ngx-mat-intl-tel-input
                    [preferredCountries]="['in']"
                    [enablePlaceholder]="true"
                    [enableSearch]="true"
                    name="mobile1"
                    formControlName="mobile1"
                    describedBy="phoneInput1"
                ></ngx-mat-intl-tel-input>
                </mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile>
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Contact Number 2</mat-label>
                  <ngx-mat-intl-tel-input
                    [preferredCountries]="['in']"
                    [enablePlaceholder]="true"
                    [enableSearch]="true"
                    name="mobile2"
                    formControlName="mobile2"
                    describedBy="phoneInput1"
                ></ngx-mat-intl-tel-input>
              </mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile>
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Key Person's name</mat-label>
                  <input
                    matInput
                    placeholder="Key Person's Name"
                    formControlName="keyperson"
                  />
                </mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile>
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Registered Address</mat-label>
                  <textarea matInput formControlName="registeredaddress">
                  </textarea>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Corporate Address</mat-label>
                  <textarea matInput formControlName="corporateaddress">
                  </textarea>
                </mat-form-field>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-card-actions align="end">
              <button
                mat-raised-button
                color="primary"
                class="btn btn-primary login-btn button-wider"
                type="submit"
                [disabled]="unitform.invalid"
              >
                Save
              </button>
              <button
                (click)="clearAll()"
                type="button"
                mat-raised-button
                color="clear"
                class="button-wider"
              >
                Clear All
              </button>
            </mat-card-actions>
          </form>
        </mat-tab>

        <!--Leadership-->
        <mat-tab label="Leadership" class="tabcolor">
          <app-leadership [modulefor]="parent"></app-leadership>
        </mat-tab>

        <!--Board Directors-->
        <mat-tab label="Board Directors" class="tabcolor">
          <app-boarddirectors [modulefor]="parent"></app-boarddirectors>
        </mat-tab>

        <!--Images-->
        <mat-tab label="Images" class="tabcolor">
          <app-businessimage [modulefor]="parent"></app-businessimage>
        </mat-tab>

        <!--Media-->
        <mat-tab label="Media" class="tabcolor">
          <app-businessmedia [modulefor]="parent"></app-businessmedia>
        </mat-tab>

        <!--Key Info-->
        <mat-tab label="Key Info" class="tabcolor">
          <app-keyinfo></app-keyinfo>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>

<div class="content p30">
  <div id="block_container">
    <ng-container>
      <a
        routerLink="/committee/meetings"
        [queryParams]="{ id: parentId }"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
    </ng-container>
    <div id="bloc2">
      <h5>
        Committee Meetings / {{ endpointResults?.committee?.title }} /
        {{ endpointResults?.title }}
      </h5>
    </div>
  </div>
  <mat-card class="">
    <span class="devarea">Add Participants</span>
    <hr />
    <mat-card-content>
      <form class="form-inline">
        <span class="example-spacer"></span>
        <div class="mr-1">
          <button
            mat-raised-button
            color="primary"
            class="addbutton"
            class="buttonfloatright"
            (click)="openDialog('Add Temporary Invitees', '', '')"
          >
            <i class="fas fa-plus"></i>&nbsp;&nbsp;ADD PARTICIPANTS
          </button>
          <!-- <button
            (click)="openViewInviteesDialog()"
            mat-button
            color="primary"
            class="addbutton"
            class="buttonfloatright"
            *ngIf="showInvitee"
          >
            <i class="fas fa-plus"></i>&nbsp;&nbsp;VIEW SPECIAL INVITEE LIST
          </button> -->
        </div>
      </form>
      <p>
        <br />
        <mat-table
          #MeetingsTable
          MeetingsTable
          matTableExporter
          [dataSource]="listData"
          matSort
          class="mattable"
          #exporter="matTableExporter"
          [hiddenColumns]="[1, 2, 3, 4, 5]"
        >
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Name</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Type</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.type }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="meeting_role">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Meeting Role</mat-header-cell
            >
            <mat-cell class="capitalize"  *matCellDef="let element">{{ element.committee_role === '' ? 'Invitee' : element.committee_role }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index">
              <button
                (click)="onDelete(row.id)"
                mat-icon-button
                color="warn"
                [disabled]="row.type === 'Permanent Member' && row.committee_role!== 'invitee' ? true : false"
              >
                <mat-icon>delete_outline</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 15, 20]"
          [pageSize]="5"
          showFirstLastButtons
        ></mat-paginator>
      </p>
    </mat-card-content>
    <mat-card-actions>
      <div class="row">
        <div class="col col-md-6" align="left">
          <button
            (click)="previous()"
            mat-stroked-button
            type="button"
            class="wide"
          >
            PREVIOUS
          </button>
        </div>
        <div class="col col-md-6" align="right">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn wide"
            type="submit"
            (click)="onSubmit()"
          >
            Next
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</div>

 

import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FileService } from '@services/File.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import { Router } from '@angular/router';
import {language} from '@shared/config/language';
import { CategoriesReportsService } from '@services/CategoriesReports.service';

@Component({
  selector: 'app-dialogaddreportcategory',
  templateUrl: './dialogaddreportcategory.component.html',
  styleUrls: ['./dialogaddreportcategory.component.css']
})
export class DialogaddreportcategoryComponent implements OnInit {
 
  newCategory = {
    "_id":"",
    "type": "category",
    "title":"",
    "role": ["facilitator", "member", "office_member"],
    "display_message": "",
    "icon": "",
    "no_of_columns": 1,
    "parent":""
  };
  resource = language;
  form:FormGroup=new FormGroup({
    _id:new FormControl(''),
    title:new FormControl('',Validators.required),
    display_message:new FormControl('',Validators.required),
    icon:new FormControl('',Validators.required),
  })
  constructor(@Inject(MAT_DIALOG_DATA) public data,
  public dialogRef : MatDialogRef<DialogaddreportcategoryComponent>,
  private categoriesReportsService:CategoriesReportsService
  ) {
      console.log(this.data)
      this.newCategory = this.data.category;
      this.form.patchValue({
        title: this.newCategory.title,
        display_message: this.newCategory.display_message,
        icon: this.newCategory.icon
      })
   }

  ngOnInit(): void {
  }
    
  closeDialog()
  {
    this.dialogRef.close(this.newCategory)
  }

  saveCategory(){

    if(this.form.valid){
      let categoryObject = {
        "_id": this.newCategory._id,
        "type": this.newCategory.type,
        "title": this.form.get("title").value,
        "role": ["facilitator", "member", "office_member"],
        "display_message": this.form.get("display_message").value,
        "icon": this.form.get("icon").value,
        "no_of_columns": 1,
        "parent":this.newCategory.parent
      }
  
      if(categoryObject._id != ""){
        this.categoriesReportsService.updateCategory(categoryObject).subscribe(response=> {    
          console.log(response);
          this.closeDialog();
        });
      }else{
        this.categoriesReportsService.createCategory(categoryObject).subscribe(response=> {    
          console.log(response);
          this.closeDialog();
  
        });
      }
  
    }


  }
 
}

import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit,Inject, ViewChild,ChangeDetectorRef } from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {FamilyInfoService} from '@services/FamilyInfo.service';
import {language} from '@shared/config/language';
import { ActivatedRoute, Router } from '@angular/router';

export interface RoleElement {
  photoname: number;
  title: string;
 
}
@Component({
  selector: 'app-dialogmeetingdata',
  templateUrl: './dialogmeetingdata.component.html',
  styleUrls: ['./dialogmeetingdata.component.css']
})
export class DialogmeetingdataComponent implements OnInit {
  displayedColumns: string[] = [ 'file','title','Action'];
  ELEMENT_DATA: RoleElement[];
  listData = new MatTableDataSource <RoleElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<RoleElement>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('photoTable') table: MatTable<RoleElement>;
  resource = language;
  constructor(
  @Inject(MAT_DIALOG_DATA) public data,
  public dialogRef : MatDialogRef<DialogmeetingdataComponent>,
  private changeDetectorRefs: ChangeDetectorRef,
  private familyinfoservice:FamilyInfoService,
  private router : Router,

  ) {
    this.listData = new MatTableDataSource<RoleElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<RoleElement>(true, []);
   }

  ngOnInit(): void {
    this.listData.paginator=this.paginator;
    this.listData.sort=this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getPhotos();
    this.table.renderRows();   

  }
  closeDialog()
  {
    this.dialogRef.close(false)
  }

  getPhotos()
  {

    this.familyinfoservice.familyinfoget().subscribe((response:any)=> {
      this.listData.data=Object.values(response['data'][2]['data']) as RoleElement[];
      this.table.renderRows();
     }, (err) => {
        if(err.error.status != null){
        }else{
        }
      })
  }

  onDelete(id)
  {

    this.familyinfoservice.removePhoto(id).subscribe((response:any)=> {
      this.getPhotos()
    }, (err) => {
        if(err.error.status != null){
        }else{
        }
      })
  }

  viewImg(img)
  {
    window.open(img.url, "_blank"); 
  }
  
  edit(row)
  {
    this.router.navigate(['/familyinfo'],{queryParams: {id: row._id,rowfor:'photo'}});
    this.closeDialog()
  }
}

import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { language } from '@shared/config/language';
import { constants } from '@shared/config/constants';
import { OtherMeetingService } from '@services/OtherMeeting.service';
import { IdpService } from '@services/Idp.service';
import { CommitteeService } from '@services/Committee.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import { CoreService } from '@services/CoreService';
import {filter, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {Location} from "@angular/common";

@Component({
  selector: 'app-addgoal',
  templateUrl: './addgoal.component.html',
  styleUrls: ['./addgoal.component.css'],
})
export class AddgoalComponent implements OnInit, OnDestroy {
  subscriptionDestroy = new Subject();

  @ViewChild('categoryFilterInput') categoryFilterInput: ElementRef;

  labelText: string = 'Add Goal';
  resource = language;
  form: FormGroup = new FormGroup({
    category: new FormControl('', Validators.required),
    subcategory: new FormControl('', Validators.required),
    goal_name: this.fb.array([this.createGoalItem()]),
    supporters: new FormControl(''),
    todolist: new FormControl(''),
    tododate: new FormControl(''),
    user: new FormControl(''),
    category_map: new FormControl(''),
    subcategory_map: new FormControl(''),
  });
  parentid: string;

  CONSTANT = constants;
  SUPPORTERS: any;
  isEdit: boolean = false;
  CATEGORY: any;
  CATEGORY_ALL: any;
  SUB_CATEGORY: any;
  showsub: boolean = false;
  constructor(
    private fb: FormBuilder,
    private othermeetinginfoservice: OtherMeetingService,
    private idp: IdpService,
    private committeeService: CommitteeService,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private router: Router,
    private location: Location
  ) {

  }
  ngOnDestroy(): void {
    this.subscriptionDestroy.next();
    this.subscriptionDestroy.complete();
  }

  ngOnInit(): void {
    this.getAllUsers();
    this.getAllCategories();
    // this.form.patchValue({
    //   category: '5d1a03288362ee002afc3a30',
    //   subcategory: '5d1a03288362ee002afc3a30',
    // });
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentid = data.id;
        //     var filter = '';

        //     filter = '?filter[_id]=' + data.id; //+this.localstoragedata._id
        //     this.idp.useridpget(filter).subscribe((response: any) => {
        //       const result = response.data[0];

        //       const categoryData = this.CATEGORY.find(
        //         (cat) => cat.title === response.data[0].category
        //       );
        //       console.log('>>>>>>>>>>>>>>', categoryData);

        //       const subcategoryData = this.SUB_CATEGORY.find(
        //         (cat) => cat._id === response.data[0].subcategory
        //       );
        //       console.log(categoryData);
        //       this.form.patchValue({
        //         category: categoryData,
        //         subcategory: subcategoryData,
        //       });
        //     });
      }
    });
  }
  createGoalItem(): FormGroup {
    return this.fb.group({ goal: ['', Validators.required], goal_date: ['', Validators.required], supporters: ['', Validators.required] });
  }
  get goal_name() {
    return this.form.get('goal_name') as FormArray;
  }
  addSocial() {
    this.goal_name.push(
      this.fb.group({ goal: ['', Validators.required], goal_date: ['', Validators.required], supporters: ['', Validators.required] })
    );
  }

  deleteSocial(index) {
    this.goal_name.removeAt(index);
  }
  getAllUsers() {
    var filter = '';
    this.othermeetinginfoservice.getFamilyUsers(filter).subscribe(
      (response) => {
        this.SUPPORTERS = response.body['data'];
      },
      (err) => {
        if (err.error.status != null) {
        } else {
        }
      }
    );
  }
  submit(): void {
    console.log(this.form)
    if (this.form.invalid) {
      return;
    }
    this.form.patchValue({
      user: this.parentid,
    });
    const constdata = this.form.getRawValue();

    // constdata.goal_name.length;
    if (!this.isEdit) {
      //    this.form.removeControl('_id');
    }
    if (constdata.goal_name.length > 0) {
      const categoryName = this.CATEGORY.find(
        (catid) => catid._id == constdata['category']
      );
      let subcategoryname;
      if (this.showsub) {
        subcategoryname = this.SUB_CATEGORY.find(
          (subcatid) => subcatid._id == constdata['subcategory']
        );
      }
      for (var i = 0; i < constdata.goal_name.length; i++) {
        let submitdata = {...constdata};
        console.log('CAT NAME:::', categoryName);
        submitdata['user'] = constdata.user;
        submitdata['category_map'] = constdata.category;
        submitdata['category'] = categoryName.title;
        if (subcategoryname != undefined) {
          submitdata['subcategory_map'] = constdata.subcategory;
          submitdata['subcategory'] = subcategoryname.title;
        } else {
          delete submitdata['subcategory'];
          delete submitdata['subcategory_map'];
        }

        submitdata['description'] = constdata.goal_name[i].goal;
        submitdata['supporters'] = constdata.goal_name[i].supporters;
        this.idp.useridppost(submitdata).subscribe(
          (response) => {
            const result = response;
            this.coreservice.success(this.resource.message('GOAL_ADD'));
            this.router.navigate(['idp']);
          },
          (err) => {
            //        this.form.addControl('_id', new FormControl(''));
          }
        );
      }
    }
    //  this.router.
  }
  getAllCategories() {
    this.committeeService.getCommitteeCategories('').subscribe(
      (response) => {
        const result = response.body;
        console.log(response.body['data']);
        this.CATEGORY = response.body['data'].filter(
          (catid) => catid.parent == undefined
        );
        this.CATEGORY_ALL = this.CATEGORY;
      },
      (err) => {}
    );
  }
  subcategoryGet(catid) {

    // to remove the filter
    this.categoryFilterInput.nativeElement.value = '';
    this.filterCategories();
    this.SUB_CATEGORY = []
    this.form.controls.subcategory.disable();
    this.showsub = false;
    this.form.patchValue({subcategory: ''})

    // to get the sub categories
    this.committeeService.subCategoryGet(catid).subscribe(
      (response: any) => {
        this.SUB_CATEGORY = response['data'];
        if (response.data && response['data'] != '' && response.data.length) {
          this.form.controls.subcategory.enable();
          console.log(this.SUB_CATEGORY);
          this.showsub = true;
        } else {
          this.showsub = false;
        }
      },
      (err) => {}
    );
  }

  onbackUrlClick(): void {
    this.location.back();
  }

  filterCategories(): void {
    if (this.categoryFilterInput.nativeElement.value == '') {
      this.CATEGORY = this.CATEGORY_ALL;
    } else {
      try {
        this.CATEGORY = this.CATEGORY_ALL.filter((tempData: any) => ((tempData.title).toLowerCase().indexOf(this.categoryFilterInput.nativeElement.value.toLowerCase()) > -1));
      } catch (e) {
        this.CATEGORY = this.CATEGORY_ALL;
      }
    }

  }
}

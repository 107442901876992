<div class="content p30"> 
    <h5>{{resource.message('OTHERMESSAGE')}}</h5>    
    <mat-card class="">
      <mat-card-header >            
            <mat-card-title > {{resource.message('MESSAGETEMPLATE')}} </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-tab-group class="tabgroup" [selectedIndex]="activePath">
          <mat-tab label="Email" class="test">
            <app-emailtemplate></app-emailtemplate>
          </mat-tab>
          <mat-tab label="SMS" class="test">
            <app-smstemplate></app-smstemplate>
          </mat-tab>
          <mat-tab label="Whatsapp" class="test">
            <app-whatsapptemplate></app-whatsapptemplate>
          </mat-tab>
          <mat-tab label="Publish" class="test">
            <app-publishtemplate></app-publishtemplate>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
</div>


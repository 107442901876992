<form  [formGroup]="form">
      <mat-card class="">
        <mat-card-content class="contentclass">
            <span>Sent To</span>
            <mat-grid-list cols="2" rowHeight="100px">
                <mat-grid-tile>
                    <mat-radio-group appearance="outline" class="smallRadio" [formControl]="sendto">
                      <mat-radio-button value="admins" [checked]="sendto.value == 'single'" class="m20" (change)="this.form.patchValue({'sent_to':'admins'})"><span>Family Admins</span> </mat-radio-button>
                      <mat-radio-button value="members" [checked]="sendto.value == 'members'" class="m20" (change)="this.form.patchValue({'sent_to':'members'})" >Family Members</mat-radio-button>
                      <mat-radio-button value="both" [checked]="sendto.value == 'both'" class="m20" (change)="this.form.patchValue({'sent_to':'both'})" >Both</mat-radio-button>
                    </mat-radio-group>
                </mat-grid-tile>
                <mat-grid-tile  >
                </mat-grid-tile>
                
                <mat-grid-tile >
                  <mat-form-field appearance="outline"class="field-spacer">
                      <mat-label>Family Admins</mat-label>
                      <mat-select  placeholder="Family Admin"  formControlName="family_admin"  required>
                       </mat-select>
                    </mat-form-field>
                 </mat-grid-tile>
             
                 <mat-grid-tile >
                    <mat-form-field appearance="outline"class="field-spacer">
                        <mat-label>Family Members</mat-label>
                        <mat-select  placeholder="Family Members"  formControlName="family_member"  required>
                         </mat-select>
                      </mat-form-field>
                   </mat-grid-tile>

                   <mat-grid-tile >
                    <mat-form-field appearance="outline"class="field-spacer">
                        <mat-label>Send By</mat-label>
                        <mat-select  placeholder="Send By"  formControlName="send_by"  required>
                         </mat-select>
                      </mat-form-field>
                   </mat-grid-tile>   
               
              </mat-grid-list>

              
        </mat-card-content>
        <mat-card-actions align="end">
            <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="submit" >Send</button>
           
        </mat-card-actions>
      </mat-card>
    </form>
<div class="content p30">
  <div id="block_container">
    <ng-container>
      <a
      routerLink="/committee/suggested"
      [queryParams]="{ id: parentId }"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
    </ng-container>
    <div id="bloc2">
      <h5>
        Committee Meetings / {{ endpointResults?.committee?.title }} /
            {{ endpointResults?.title }}
      </h5>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <fieldset>
      <mat-card class="">
        <mat-card-content>
          <div class="devarea">
            <span>Rationalize Agenda</span>
          </div>
          <hr />
          <div class="row cancel">
            <button mat-stroked-button>Cancel Meeting</button>
          </div>
          <div class="row maindiv">
            <div class="row">
              <div class="meetingcol">{{ endpointResults?.title }}</div>
            </div>

            <div class="row">
              <div class="col-md-3 firstcol">Start Date and Time</div>
              <div class="col-md-3 firstcol">End Date and Time</div>
              <div class="col-md-2 firstcol">Participants</div>
              <div class="col-md-2 firstcol">Venue</div>
              <div class="col-md-2 firstcol">Actions</div>
              <div class="col-md-3 secondcol">
                {{ start_date | customDate }} &#160; {{ start_time }}
              </div>
              <div class="col-md-3 secondcol">
                {{ end_date | customDate }} &#160; {{ end_time }}
              </div>
              <div class="col-md-2 secondcol1">
                <button
                  mat-button
                  color="primary"
                  type="button"
                  (click)="openparticipants('', '', '')"
                >
                  View Invitees
                </button>
              </div>
              <div class="col-md-2 secondcol">{{ venue }}</div>
              <div class="col-md-2 secondcol">
                <button type="button" mat-icon-button (click)="edit_meeting()">
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <fieldset>
            <div class="row">
              <div
                class="row wholerow"
                *ngFor="let topic of topics; index as i"
              >
                <div class="col-md-12 firstcol">
                  <div class="row">
                    <label>Agenda: </label>&nbsp; {{ topic.title }}
                  </div>
                </div>
                <div class="col-md-3 firstcol">
                  <strong style="color: black"
                    ><label>Description: </label
                    ><br>&nbsp;{{ topic.description }}</strong
                  >
                </div>
                <div class="col-md-9 firstcol">
                  <strong style="color: black" *ngIf="topic.pre_reads"
                    ><label>Pre-Reads: </label><br>&nbsp;{{ topic.pre_reads }}</strong
                  >
                </div>
              </div>
            </div>
            <br />
            <button
              mat-raised-button
              color="primary"
              class="btn btn-primary login-btn button-wider"
              type="button"
              (click)="showNote()"
            >
              Note
            </button>
          </fieldset>
        </mat-card-content>
        <span class="blankline"></span>
        <div class="form-group mr-2">
          <button
            (click)="previous()"
            mat-stroked-button
            type="button"
            class="wide"
          >
            PREVIOUS
          </button>
        </div>
        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="button"
            (click)="visit_attendence()"
          >
            Next
          </button>
        </mat-card-actions>
      </mat-card>
    </fieldset>
  </form>
</div>

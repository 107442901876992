import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface Notification {
  notification: string;
  updated: Date;
  status: string;
}

@Component({
  selector: 'app-dialognotification',
  templateUrl: './dialognotification.component.html',
  styleUrls: ['./dialognotification.component.css'],
})
export class DialognotificationComponent implements OnInit {
  notify: Notification[] = [
    {
      notification: 'You have received a mail from Super Admin',
      updated: new Date('1/1/16'),
      status: 'read',
    },
    {
      notification: 'New committee is created by Shekhar Kumar',
      updated: new Date('1/17/16'),
      status: 'unread',
    },
    {
      notification: 'Poll related to Vaccine drive is created by Lovely Kumari',
      updated: new Date('1/28/16'),
      status: 'unread',
    },
    {
      notification: 'You have received a personal task from Super Admin',
      updated: new Date('1/1/16'),
      status: 'unread',
    },
    {
      notification: 'You are added to committee  created by Priya Singh',
      updated: new Date('1/17/16'),
      status: 'unread',
    },
    {
      notification: 'New business unit created by Super Admin',
      updated: new Date('1/28/16'),
      status: 'unread',
    },
  ];
  constructor(public dialogRef: MatDialogRef<DialognotificationComponent>) {}

  ngOnInit(): void {}
  closeDialog() {
    this.dialogRef.close(false);
  }
}

<mat-card class="main-card">
  <mat-card-header>
    <mat-card-title>
      {{ labelText }}
    </mat-card-title>
    <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
  </mat-card-header>
  <mat-card-content>
    <textarea class="textarea" rows="6" [(ngModel)]="notes"></textarea>
    <div class="divfloat">
      <button
        mat-raised-button
        color="primary"
        class="btn btn-primary done-btn button-wider"
        type="button"
        (click)="doneClick()"
      >
        Done
      </button>
    </div>
  </mat-card-content>
</mat-card>

import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators,FormArray,FormBuilder} from '@angular/forms';
import { UACService } from '@services/UAC.service';
import { ActivatedRoute, Router } from '@angular/router';
import {CoreService} from '@services/CoreService';
import { language } from '@shared/config/language';
import { constants } from '@shared/config/constants';
import { FamilyOfficeService } from '@services/FamilyOffice.service';
import { DatePipe } from '@angular/common'

export interface Option {
  votes:string,
  total_percentage : string,
  option: string
}
@Component({
  selector: 'app-firstsection',
  templateUrl: './firstsection.component.html',
  styleUrls: ['./firstsection.component.css']
})
export class FirstsectionComponent implements OnInit {
  notice =new FormControl('single')
  isEdit:Boolean = false;
  disableText:boolean=true;
  form :FormGroup = new FormGroup({
    _id:new FormControl(0),
    member:new FormControl('',Validators.required),
    created_on:new FormControl('',Validators.required),
    description: new FormControl(''),
    
  })

 
  resource = language;
 editId:string;
 ticketno:string;
  constructor(private uacservice: UACService, private router : Router,
    private activeRoute: ActivatedRoute,
   private familyoffice : FamilyOfficeService,
   public datepipe: DatePipe
   
    ) {
 
 
    this.activeRoute.queryParams.subscribe(data => {

      if(data.id!=undefined)
      {
        this.editId=data.id;
        this.familyoffice.ticketget(data.id).subscribe((response:any)=> {
          const result = response.data[0];
          console.log("RECEIVED EDIT DATA",result[0])
          this.ticketno=result.ticket_no
          this.form.setValue({
            _id:result._id,
            member:result.user.name,
            created_on:this.datepipe.transform(result.created_at, 'dd-MM-yyyy'),
            description: result.description,
            
        })
         })

      } 
    })

   }

  ngOnInit(): void {
    }

    nextPage(){ 
      this.router.navigate(['tickets/s2'],{queryParams: {id: this.editId}});

    
  }

}

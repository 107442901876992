import { Component, OnInit } from '@angular/core';
import { language } from '@shared/config/language';
import { CommitteeService } from '@services/Committee.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { FileService } from '@services/File.service';
import { IdpService } from '@services/Idp.service';
import { CoreService } from '@services/CoreService';
import { DialogaddnoteComponent } from '@shared/components/dialogaddnote/dialogaddnote.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DialogviewattendanceComponent } from '@shared/components/dialogviewattendance/dialogviewattendance.component';
@Component({
  selector: 'app-committeemeetingrationalized',
  templateUrl: './committeemeetingrationalized.component.html',
  styleUrls: ['./committeemeetingrationalized.component.css'],
})
export class CommitteemeetingrationalizedComponent implements OnInit {
  resource = language;
  endpointResults: any;
  parentId: string;
  start_date: string;
  end_date: string;
  start_time: string;
  end_time: string;
  venue: string;
  currentfile = {};
  currentfilename = '';
  selectedfile: any;
  createdfor: string;
  isEdit: boolean = false;
  fileId: string;
  url: string = '';
  topics: any[] = [];
  members: any[] = [];

  constructor(
    private committeeService: CommitteeService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fileService: FileService,
    private idp: IdpService,
    private coreservice: CoreService,
    private dialog: MatDialog
  ) {}
  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    file: new FormControl(''),
    filename: new FormControl(''),
  });
  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentId = data.id;
        this.getAllCommiteeMeetings(this.parentId);
      }
    });
  }
  getAllCommiteeMeetings(id) {
    var filter = '/' + id;

    this.committeeService.getCommitteeMeetings(filter).subscribe(
      (response: any) => {
        const result = response.data;
        this.endpointResults = result;
        console.log('::::::::::::', response.data);
        let commitee_members = result.committee.members
        this.start_date = result.start_date;
        this.end_date = result.end_date;
        this.start_time = result.start_time;
        this.end_time = result.end_time;
        this.venue = result.venue;
        let special_invitees = response.data.temporary_members.members
        this.members = [...response.data.members,...special_invitees];
        if (result.agendas.length > 0) {
          this.topics = result.agendas;
          // temp comments for testing
          // .filter(
          //   (agen) => agen.agenda_passed === true
          // );
          console.log('this.topics ', this.topics);
        }
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  onFileChange(event) {
    const latestfile = (event.target as HTMLInputElement).files[0];
    this.currentfile = latestfile;
    this.currentfilename = latestfile.name;
    this.selectedfile = latestfile;
    var filename = latestfile.name;

    this.form.patchValue({
      filename: this.currentfilename,
    });

    this.form.patchValue({
      file: this.currentfile,
    });

    this.form.get('file').updateValueAndValidity();
  }
  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    var rawuser = this.form.getRawValue();
    console.log(this.form.get('file'));
    if (
      this.form.get('file').value !== undefined &&
      this.form.get('file').value !== ''
    ) {
      let fileurl = this.form.get('file').value;
      var formData: any = new FormData();
      formData.append('file', this.form.get('file').value);
      fileurl = await this.fileService.uploadFile(formData, true, false);
      rawuser.file_url = fileurl;
    }

    delete rawuser['file'];
    let finaldata;
    if (this.isEdit === false) {
      finaldata = {
        pre_reads: rawuser.file_url,
      };
    } else {
      finaldata = {
        pre_reads: rawuser.file_url,
      };
    }
    this.committeeService.committeeAgenda(finaldata, this.parentId).subscribe(
      (response) => {
        this.coreservice.success(this.resource.message('Agenda_PUBLISHED'));

        this.router.navigate(['/committee/mom'], {
          queryParams: { id: this.parentId },
        });
      },
      (err) => {}
    );
  }
  showNote() {
    const dialogRef = this.dialog.open(DialogaddnoteComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: 'NOTE',
        actionbutton: '',
        cancelbutton: '',
        id: this.parentId,
        type: 'shownote',
        variant: 'committee'
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  previous() {
    this.router.navigate(['committee/suggested'], {
      queryParams: { id: this.parentId },
    });
  }
  edit_meeting() {
    this.router.navigate(['/committee/meetings'], {
      queryParams: { id: this.parentId },
    });
  }
  visit_attendence() {
    this.router.navigate(['committee/attendence'], {
      queryParams: { id: this.parentId },
    });
  }
  openparticipants(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogviewattendanceComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        members: this.members,
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        parentId: this.parentId,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('>><<<<>>>>', result.data.length);
    });
  }
}

<div class="content p30">
  <div id="block_container">
    <a
      routerLink="/attendence"
      id="bloc1"
      [queryParams]="{ id: parentid }"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >
    <div id="bloc2">
      <h5>{{ resource.message("IDP") }} / Create Meeting</h5>
    </div>
  </div>
  <form>
    <fieldset>
      <!--[disabled]=" (familyname!=select_user_family) ? isDisabled :''"-->
      <mat-card class="">
        <mat-card-content>
          <span class="devarea">Add Outcome</span>
          <br />
          <hr />
          <!-- <div class="row cancel">
              <button mat-stroked-button>Cancel Meeting</button>
            </div> -->
          <div class="row maindiv">
            <div class="row">
              <div class="meetingcol">{{ title }}</div>
            </div>
            <div class="row">
              <div class="col-md-3 firstcol">Start Date and Time</div>
              <div class="col-md-3 firstcol">End Date and Time</div>
              <div class="col-md-2 firstcol">Participants</div>
              <div class="col-md-2 firstcol">Venue</div>
              <div class="col-md-2 firstcol">Actions</div>
              <div class="col-md-3 secondcol">
                {{ start_date | customDate }} {{ start_time }}
              </div>
              <div class="col-md-3 secondcol">
                {{ end_date | customDate }} {{ end_time }}
              </div>
              <div class="col-md-2 secondcol1">
                <button
                  mat-button
                  color="primary"
                  (click)="openparticipants('', '', '')"
                >
                  View Invitees
                </button>
              </div>
              <div class="col-md-2 secondcol">{{ venue }}</div>
              <div class="col-md-2 secondcol">
                <button mat-icon-button disabled="true">
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </div>
            </div>
          </div>

          <br />
          <div class="row maindiv">
            <div class="row">
              <div class="meetingcol">Agenda</div>
            </div>
            <div class="row wholerow" *ngFor="let topic of topics; index as i">
              <ng-container *ngIf="topic.idp">
              <div class="col-md-6 firstcol">
                <div class="row">
                  {{
                    topic.idp && topic.idp.category === "Topic"
                      ? topic.idp.category
                      : "Development"
                  }}
                </div>
                <div class="row">
                  <strong style="color: black" *ngIf="topic.idp">{{
                    topic.idp.description
                  }}</strong>
                </div>
              </div>
              <div class="col-md-6 firstcol">
                <button
                  mat-stroked-button
                  type="button"
                  class="wide1"
                  (click)="outcome_tab(topic, i)"
                >
                  Add Outcome
                </button>
              </div>
              <div class="col-md-12 newcomp" *ngIf="showView === i">
                <app-outcometab
                  [parentid]="parentid"
                  [agendaid]="agendaid"
                  [viewdata]="viewdata"
                ></app-outcometab>
              </div>
            </ng-container>
            </div>
          </div>
        </mat-card-content>

        <span class="blankline"></span>
        <div class="form-group mr-2">
          <button
            mat-stroked-button
            type="button"
            class="wide"
            [queryParams]="{ id: parentid }"
            routerLink="/attendence"
          >
            PREVIOUS
          </button>
        </div>
        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="button"
            (click)="onSubmit()"
          >
            Next
          </button>
        </mat-card-actions>
      </mat-card>
    </fieldset>
  </form>
</div>

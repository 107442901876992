import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { UserService } from '@services/UserService';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import { CoreService } from '@services/CoreService';
import { MatMenuTrigger } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import { UACService } from '@services/UAC.service';
import { AuthService } from '@services/Auth.service';
import { constants } from '@shared/config/constants';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export interface UserElement {
  userId: number;
  name: string;
  email: string;
  mobile: string;
  role: string;
  status: string;
  created: string;
  updated: string;
}

// REMOVE THIS COMMENTS

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
})
export class UserComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;

  ROLE_DATA: string[];
  ELEMENT_DATA: UserElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = [
    'name',
    'mobile',
    'email',
    'role',
    'status',
    'Action',
  ];
  listData = new MatTableDataSource<UserElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<UserElement>(true, []);
  ROLES_LIST: any;

  PERMISSION_EDIT = AuthService.authentication('Users', 'READ');
  PERMISSION_ADD = AuthService.authentication('Users', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Users', 'DELETE');
  localstoragedata: any;
  familyname = '';
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<UserElement>;

  resource = language;
  CORE_FAMILY = constants.CORE_FAMILY_NAME;

  constructor(
    private userservice: UserService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private http: HttpClient,
    private coreservice: CoreService,
    private uacservice: UACService
  ) {
    this.listData = new MatTableDataSource<UserElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<UserElement>(true, []);
  }

  ngOnInit(): void {
    AuthService.authenticate_redir('Users', ['READ']);

    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    if (this.localstoragedata.family != null)
      this.familyname = this.localstoragedata.family.name;
    /* if( this.familyname==this.CORE_FAMILY)
    {
      this.getAllUsers();
    }
    else
    {
      this.getAllUsers()
    }*/
    this.getAllUsers();
    this.getAllRoles();
    this.table.renderRows();
  }

  getAllUsers() {
    var filter = '';
    if (this.familyname == this.CORE_FAMILY) {
      filter = '';
    } else {
      filter = '?filter[family]=' + this.localstoragedata.family._id;
    }
    this.userservice.getUsers(filter).subscribe(
      (response) => {
        const result = response.body;
        this.listData.data = response.body['results'] as UserElement[];

        this.table.renderRows();

        this.ROLE_DATA = this.getCol(
          response.body['results'] as UserElement[],
          'role'
        );
        console.log(this.ROLE_DATA);

        this.ROLE_DATA = [...new Set(this.ROLE_DATA)];
      },
      (err) => {
        if (err.error.status != null) {
          //          this.errmessage = err.error.message;
        } else {
          //         this.errmessage = err.error.errors[0].msg;
        }
      }
    );
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listData.data.forEach((row) => this.selection.select(row));
  }

  addrole() {
    this.router.navigate(['/user/add']);
  }

  edit(role) {
    console.log('*************************', role);
    this.router.navigate(['user/add'], { queryParams: { id: role._id } });
  }

  onDelete(id) {
    this.coreservice
      .openConfirmDialog(this.resource.message('USER_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.userservice.removeRole(id).subscribe(
            (response) => {
              const result = response;
              this.getAllUsers();
              this.coreservice.success(this.resource.message('USER_DELETED'));
            },
            (err) => {}
          );
        }
      });
  }

  closeMyMenu() {
    this.trigger.closeMenu();
  }

  filterData(ev, col, val) {
    if (ev.checked) {
      col = String(col);

      // let value = { [col]: val };
      // this.filterValues.push(value);
      if (col === 'role') {
        this.filterValues = {
          role: [...this.filterValues.role, val],
          status: [...this.filterValues.status],
        };
      } else if (col === 'status') {
        this.filterValues = {
          role: [...this.filterValues.role],
          status: [...this.filterValues.status, val],
        };
      }
    } else {
      const index = this.filterValues[col].indexOf(val);
      this.filterValues[col].splice(index, 1);
    }

    console.log('filterValues', this.filterValues);

    this.setupFilter();

    this.applyFilter(val);
  }

  stoppro($event) {
    $event.stopPropagation();
  }

  setupFilter() {
    this.listData.filterPredicate = (d: any, filter: string) => {
      const filterVal = JSON.parse(filter);
      console.log('filter', filter);
      console.log(filterVal);
      var flag1 = false;
      var flag2 = false;

      console.log('role   ', d.role);
      console.log('status   ', d.status);

      if (filterVal.role.length === 0) {
        flag1 = true;
      } else if (filterVal.role.includes(d?.role)) {
        flag1 = true;
      }

      if (filterVal.status.length === 0) {
        flag2 = true;
      } else if (filterVal.status.includes(d?.status)) {
        flag2 = true;
      }
      console.log(flag1 + '  ' + flag2);

      return flag1 && flag2;
    };
  }

  applyFilter(filterValue: string) {
    console.log(JSON.stringify(this.filterValues));
    this.listData.filter = JSON.stringify(this.filterValues); // filterValue.trim().toLowerCase();
  }

  getCol(matrix, col) {
    var column = [];
    for (var i = 0; i < matrix.length; i++) {
      column.push(matrix[i][col]['role']);
    }
    return column; // return column data..
  }

  filterValues = { role: [], status: [] };
  filterColumns = ['role', 'status'];

  getAllRoles() {
    this.uacservice.getRoles().subscribe(
      (response) => {
        const result = response.body;
        this.ROLES_LIST = response.body['results'];

        this.ROLES_LIST = this.ROLES_LIST.filter(
          (thing, i, arr) => arr.findIndex((t) => t.role === thing.role) === i
        );
      },
      (err) => {}
    );
  }

  getFamilyUsers() {
    this.userservice.getFamilyUses().subscribe(
      (response) => {
        const result = response.body;
        this.listData.data = response.body['data'] as UserElement[];

        this.table.renderRows();

        this.ROLE_DATA = this.getCol(
          response.body['data'] as UserElement[],
          'role'
        );
        console.log(this.ROLE_DATA);

        this.ROLE_DATA = [...new Set(this.ROLE_DATA)];
      },
      (err) => {
        if (err.error.status != null) {
          //          this.errmessage = err.error.message;
        } else {
          //         this.errmessage = err.error.errors[0].msg;
        }
      }
    );
  }

  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [['Profile Name', 'Mobile No', 'Email ID', 'Role', 'Status']];
    this.listData.data.forEach((obj) => {
      let name = obj['name'] ? obj['name'] : '';
      let lastname = obj['lastname'] ? obj['lastname'] : '';
      let mobile = obj['mobile'] ? obj['mobile'] : '';
      let email = obj['email'] ? obj['email'] : '';
      let role = obj['role'] ? obj['role'] : '';
      let status = obj['status']
        ? obj['status'].charAt(0).toUpperCase() + obj['status'].slice(1)
        : '';

      let arr = [name + ' ' + lastname, mobile, email, role, status];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      columnStyles: { 0: { cellWidth: 50 } },
      rowPageBreak: 'avoid',
    });
    if (action === 'save') {
      doc.save('users.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      // window.open(doc.output('bloburl') as unknown as string, '_blank');
      doc.output('dataurlnewwindow', { filename: 'users.pdf' });
    }
  }
}

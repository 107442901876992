// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //  webservices: "http://69.64.34.50:8182" // "http://199.217.116.37:8182"  // "http://192.168.172.131:8182" //
  //  webservices: "http://69.64.46.62:8182" // "http://199.217.116.37:8182"  // "http://192.168.172.131:8182" //

  //webservices: 'http://69.64.46.62:8182', // FB-DEV INSTANCE
  //  webservices: "http://69.64.34.50:8182",   // FB-UAT INSTANCE
//  webservices: "https://ojasfamily.fabricadvisory.com/api",   // FB-UAT INSTANCE-NEW
  // webservices: 'https://fbuat.fiveminutes.in/api', // FB-UAT INSTANCE-NEW
  //  webservices: "http://52.66.162.137:8182",   // RECRO UAT INSTANCE
  // webservices: "https://fbafrontend.5mi.in/api",
   webservices: "https://fbafrontend.5mi.in/api"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

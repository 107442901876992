<br />
   
    <div class="form-group mr-2">  
     <h5><b>{{resource.message('SPACETYPE')}} </b></h5>  
    </div>
     <span class="example-spacer"></span>
     <div class="mr-1"> 
   
     <button mat-raised-button color="primary" class="addbutton" class="buttonfloatright" (click)="addnewitem()" ><i class="fas fa-plus"></i>&nbsp;&nbsp;ADD</button>
    
    </div>
    <br /><br /> 
    <div><hr /></div>

    <div>

        <mat-grid-list cols="1" rowHeight="70px">
            <mat-grid-tile *ngFor="let category of categories; index as i; first as isFirst">
                <mat-form-field appearance="outline" class="field-spacer" style="margin-top: 22px">
                    <input matInput placeholder="Category" [(ngModel)]="category.category" (blur)="update(category)">
                  </mat-form-field>
                  <button mat-stroked-button color="warn" class="actionubutton" (click)="remove(category)"><i class="fas fa-trash"></i></button>
            </mat-grid-tile>
        </mat-grid-list>   
        
        

 
    </div>

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FamilyInfoService } from '@services/FamilyInfo.service';
import { DialogphotoComponent } from './../../shared/components/dialogphoto/dialogphoto.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogmediaComponent } from './../../shared/components/dialogmedia/dialogmedia.component';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { UACService } from '@services/UAC.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { MatMenuTrigger } from '@angular/material/menu';
import { HttpClient } from '@angular/common/http';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  NgForm,
} from '@angular/forms';
import { CoreService } from '@services/CoreService';
import { language } from '@shared/config/language';
import { FileService } from '@services/File.service';
import { FamiliesService } from '@services/Families.service';
import { environment } from '@environments/environment';
export interface UserElement {
  name: string;
  mobile: string;
  email: string;
  role: string;
}

@Component({
  selector: 'app-family-info',
  templateUrl: './family-info.component.html',
  styleUrls: ['./family-info.component.css'],
})
export class FamilyInfoComponent implements OnInit {
  selFile = new Object();

  selFileName = '';
  family_tree_event = null;
  basic_details_event = null;
  familyid = '';
  filterform: FormGroup = new FormGroup({
    member: new FormControl(true),
    advisor: new FormControl(true),
    active: new FormControl(true),
    inactive: new FormControl(true),
  });

  form: FormGroup = new FormGroup({
    img: new FormControl(0),
    title: new FormControl('', Validators.required),
    description: new FormControl(0),
    type: new FormControl(0),
    fileurl: new FormControl(''),
    file: new FormControl(''),
    external_link: new FormControl(''),
    url: new FormControl('', Validators.required),
    _id: new FormControl(''),
  });

  photoform: FormGroup = new FormGroup({
    type: new FormControl(0),
    url: new FormControl('', Validators.required),
    title: new FormControl('', Validators.required),
    file: new FormControl(''),
    _id: new FormControl(''),
  });
  familytree: FormGroup = new FormGroup({
    file: new FormControl(''),
    filename: new FormControl(''),
  });

  displayedColumns: string[] = ['name', 'mobile', 'email', 'role'];
  ELEMENT_DATA: UserElement[];
  listData = new MatTableDataSource<UserElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<UserElement>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('userTable') table: MatTable<UserElement>;
  photoName: string = '';
  pdfName: string = '';
  familyname: string;
  membercount: string;
  searchKey: string;
  photosLength: number;
  photosUrl: string;
  photoTitle: string;
  mediaLength: number;
  mediaUrl: string;
  mediaTitle: string;
  resource = language;
  logo: string = '';
  familypdf: string = '';
  isEdit: boolean = false;
  dataid: string;
  activePath = 0;
  trigger: any;
  FamilyInfoService: any;
  ROLES_LIST: any;
  showFiller = false;

  ROLE_DATA: string[];
  userservice: any;

  isUpdatingPhoto: boolean = false;
  isUpdatingMedia: boolean = false;
  isActive: boolean = true;
  isInActive: boolean = true;
  isMember: boolean = true;
  isAdvisor: boolean = true;
  mediaRadioBtn: string = 'file1';

  constructor(
    private familyinfoservice: FamilyInfoService,
    private dialog: MatDialog,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private coreservice: CoreService,
    private fileService: FileService,
    private familyservice: FamiliesService,
    private activeRoute: ActivatedRoute,
    private uacservice: UACService
  ) {}

  getAllData() {
    this.form.patchValue({
      img: 'https://cdn1.iconfinder.com/data/icons/image-manipulations/100/13-512.png',
      description: 'Portal description 1',
      type: 'family',
      title: '',
      url: '',
      file: '',
      _id: '',
    });
    this.photoform.patchValue({
      type: 'family',
      url: '',
      title: '',
      file: '',
      _id: '',
    });
    this.familyinfoservice.familyinfoget().subscribe(
      (response: any) => {
        console.log('MEMBERS:::', Object.values(response['data'][1]['data']));
        console.log('MEDIA:::', Object.values(response['data'][2]['data']));
        this.familyname = response['data'][0].info.name;
        this.membercount = response['data'][0].info.members;
        this.photosLength = Object.values(response['data'][2]['data']).length;
        this.photosUrl =
          response['data'][2]['data'].length > 0
            ? response['data'][2]['data'][this.photosLength - 1].url
            : '';
        this.mediaLength = Object.values(response['data'][3]['data']).length;
        this.listData.data = Object.values(
          response['data'][1]['data']
        ) as UserElement[];
        this.logo = response['data'][0].info.logo;
        this.table.renderRows();
      },
      (err) => {}
    );
    this.familyservice.getFamilies().subscribe((response) => {
      this.familypdf = response.body['results'][0].family_tree;
    });
    setTimeout(() => this.isEditable(), 1000);
  }
  ngOnInit(): void {
    this.familytree.setValue({
      filename: '',
      file: null,
    });

    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;

    this.getAllData();
    this.getAllRoles();
    this.changeDetectorRefs.detectChanges();
    var localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    let familyId = localstoragedata.family._id;
    this.familyinfoservice.getFamilyInfoData(familyId).subscribe(val=> {
      console.log(val)
      this.logo = val.body['family'].logo;
    })
    this.table.renderRows();
  }

  onFilterSubmit(f: NgForm) {
    console.log(this.filterform.getRawValue()['member']); // { first: '', last: '' }
  }
  isEditable() {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined && data.id !== '' && data.id !== null) {
        console.log('DATA ROUTE', data);
        if (data.rowfor == 'photo') {
          this.isUpdatingPhoto = true;
          this.familyinfoservice
            .getPhoto(data.id)
            .subscribe((response: any) => {
              console.log('>>>', data.id);
              this.dataid = data.id;
              console.log('>>>', response.body['data'][0]['title']);
              this.photoform.setValue({
                type: 'family',
                url: response.body['data'][0]['url'],
                title: response.body['data'][0]['title'],
                file: '',
                _id: data.id,
              });
            });
          this.activePath = 0;
        } else if (data.rowfor == 'media') {
          this.isUpdatingMedia = true;
          this.dataid = data.id;
          this.familyinfoservice
            .getMedia(data.id)
            .subscribe((response: any) => {
              console.log('>>>', response.body['data']);
              console.log('>>>', response.body['data'][0]['title']);
              this.form.patchValue({
                type: 'family',
                url: response.body['data'][0]['url'],
                title: response.body['data'][0]['title'],
                file: '',
                _id: data.id,
              });
            });
          this.activePath = 1;
        }

        this.isEdit = true;
      }
    });
  }
  previewimg(eve) {
    if (eve == 'logo') {
      window.open(this.logo, '_blank');
    } else {
      window.open(this.familypdf, '_blank');
    }
  }
  openPhotoDialog(msg, actionbutton, cancelbutton) {
    return this.dialog.open(DialogphotoComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
      },
    }).afterClosed().subscribe(res=>{
      // this.getAllData()
      // this.form.controls.title.setErrors(null);
    });
  }
  cancelUpdatingPhoto() {
    this.photoform.setValue({
      type: '',
      url: '',
      title: '',
      file: '',
      _id: '',
    });

    this.isUpdatingPhoto = false;
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: { id: null, rowfor: null },
    });
  }
  cancelUpdatingMedia() {
    this.form.patchValue({
      type: '',
      url: '',
      title: '',
      file: '',
      _id: '',
    });

    this.isUpdatingMedia = false;
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: { id: null, rowfor: null },
    });
  }
  openMediaDialog(msg, actionbutton, cancelbutton) {
    const result = this.dialog.open(DialogmediaComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
      },
    }).afterClosed().subscribe(res=>{
      // this.getAllData()
      // this.form.controls.title.setErrors(null);
    });
    console.log('result', result);

    return result;
  }
  onMediaRadioBtnChange(e) {
    // console.log('file: ', this.form.get('file'));
    this.mediaRadioBtn = e.value;
  }
  addUser() {
    this.router.navigate(['/user/add']);
  }
  edit(role) {
    this.router.navigate(['/member/add'], { queryParams: { id: role._id } });
  }

  async submitMedia() {
    if (this.form.invalid) {
      return;
    }
    var req = this.form.getRawValue();
    if (this.mediaRadioBtn !== 'file1') {
      if (this.form.get('external_link').value !== '') {
        // delete previous image
        let url = this.form.get('url').value;
        if (url.startsWith('https://fbafrontend.5mi.in/api')) {
          url = url.split('?');
          this.fileService.deleteFile(url[1]).subscribe(
            (res) => {
              console.log(res);
            },
            (err) => {
              console.log(err);
            }
          );
        }
      }

      // req['url'] = this.selFile;
      console.log(" req['url']", req['url']);
    } else if (this.selFileName != '') {
      // delete previous image
      let url = this.form.get('url').value;
      if (url.startsWith('https://fbafrontend.5mi.in/api')) {
        url = url.split('?');
        this.fileService.deleteFile(url[1]).subscribe(
          (res) => {
            console.log(res);
          },
          (err) => {
            console.log(err);
          }
        );
      }
      let fileurl = this.selFileName;
      var formData: any = new FormData();
      console.log('this.selFile', this.selFile);

      formData.append('file', this.selFile);
      fileurl = await this.fileService.uploadFile(formData, true, false);
      req['url'] = fileurl;
      this.selFileName = '';
    }
    if (this.mediaRadioBtn !== 'file1') {
      if (this.form.get('external_link').value !== '') {
        req['url'] = this.form.get('external_link').value;
      }
    }
    delete req['file'];
    delete req['external_link'];
    if (this.isEdit == false) {
      delete req['_id'];
    } else {
      req['_id'] = this.dataid;
    }
    this.familyinfoservice.addMedia(req).subscribe(
      (response) => {
        const result = response.body;
        this.form.patchValue({ external_link: '' });
        this.coreservice.success('Media saved');
        this.getAllData();
        this.form.controls.title.setErrors(null);
        //this.router.navigate(['/familyinfo']);
        this.isEdit = false;
        this.isUpdatingMedia = false;
      },
      (err) => {
        this.coreservice.error(err.error.message);
        // this.form.addControl('_id', new FormControl(''));
      }
    );
  }

  async submitPhoto(event) {
    if (this.photoform.invalid) {
      return;
    }
    var req = this.photoform.getRawValue();
    if (this.selFileName != '') {
      // delete previous image
      let url = this.photoform.get('url').value;
      if (url.startsWith(environment.webservices)) {
        url = url.split('?');
        this.fileService.deleteFile(url[1]).subscribe(
          (res) => {
            console.log(res);
          },
          (err) => {
            console.log(err);
          }
        );
      }
      let fileurl = this.selFileName;
      var formData: any = new FormData();
      formData.append('file', this.selFile);
      fileurl = await this.fileService.uploadFile(formData, true, false);
      req['url'] = fileurl;
      this.selFileName = '';
    }

    delete req['file'];
    if (this.isEdit == false) {
      delete req['_id'];
    } else {
      req['_id'] = this.dataid;
    }
    this.familyinfoservice.addPhoto(req).subscribe(
      (response) => {
        
        const result = response.body;

        this.coreservice.success('Photo saved');
        // this.photoform.patchValue({
        //   type: 'family',
        //   url: '',
        //   title: '',
        //   file: '',
        //   _id: '',
        // });
        this.getAllData();
        // this.photoform.reset();
        
        this.photoform.controls.title.setErrors(null);
        
       // this.router.navigate(['/familyinfo']);
        this.isEdit = false;
        this.isUpdatingPhoto = false;
      },
      (err) => {
        this.coreservice.error(err.error.message);
        // this.form.addControl('_id', new FormControl(''));
      }
    );
  }

  setValidatorForPhoto() {
    this.photoform.controls["title"].setValidators(Validators.required);
    this.photoform.controls["title"].updateValueAndValidity();
  }

  setValidatorForMedia() {
    this.form.controls["title"].setValidators(Validators.required);
    this.form.controls["title"].updateValueAndValidity();
  }

  onChange(event, file) {
    if (file === 'pdf') {
      this.family_tree_event = event;
      this.pdfName = (event.target as HTMLInputElement).files[0].name;
    } else {
      this.basic_details_event = event;
      this.photoName = (event.target as HTMLInputElement).files[0].name;
    }
  }
  onFileChange(event, col) {
    if (col !== 'media link') {
      this.selFile = (event.target as HTMLInputElement)?.files[0];
      this.selFileName = '';
      this.selFileName = (event.target as HTMLInputElement)?.files[0]?.name;
      console.log('onFileChange this.selFile ', this.selFile);
      console.log('onFileChange  this.selFileName  ', this.selFileName);

      this.form.patchValue({ url: this.selFileName });
      this.photoform.patchValue({ url: this.selFileName });
    } else {
      console.log('url', (event.target as HTMLInputElement).value);
      if (!this.isUpdatingMedia) {
        this.form.patchValue({ url: (event.target as HTMLInputElement).value });
      }
    }
    if (col !== 'photo' && col !== 'media file' && col !== 'media link') {
      this.updateFamilyInfo(col);
    }
    /*
 
    this.familytree.get('file').updateValueAndValidity()
    this.submitTree();
    */
  }
  deletePhoto(id) {
    /*this.coreservice.openConfirmDialog(this.resource.message('ROLE_DELETE'))
    .afterClosed().subscribe(res=>{
      if(res){
        this.uacservice.removeRole(id).subscribe(response=> {
          const result = response;
          this.getAllRoles();
          this.coreservice.success(this.resource.message('ROLE_SAVE'));

         }, (err) => {
    })

        
        
      }
    })*/
  }
  async remove(col) {
    var localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    let familyId = localstoragedata.family._id;

    let fileurl = '';

    // delete previous image
    let url: any = '';
    url = col === 'family_tree' ? this.familypdf : this.logo;
    if (url.startsWith(environment.webservices)) {
      url = url.split('?');
      this.fileService.deleteFile(url[1]).subscribe(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    }

    var req = {};

    console.log('COL:' + col);

    req['family'] = {};
    req['family'][col] = fileurl;

    console.log(req);
    this.familyinfoservice
      .updateFamilyInfo(req, familyId)
      .subscribe((response) => {
        if (col === 'logo') {
          this.logo = '';
        }
        alert('In order to reflect the new family details please re-login.');
      });
  }
  async updateFamilyInfo(col) {
    var localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    let familyId = localstoragedata.family._id;

    let fileurl = this.selFileName;
    var formData: any = new FormData();
    formData.append('file', this.selFile);
    fileurl = await this.fileService.uploadFile(formData, true, false);

    if (fileurl) {
      // delete previous image
      let url: any = '';
      url = col === 'family_tree' ? this.familypdf : this.logo;
      if (url && url.startsWith(environment.webservices)) {
        url = url.split('?');
        this.fileService.deleteFile(url[1]).subscribe(
          (res) => {
            console.log(res);
          },
          (err) => {
            console.log(err);
          }
        );
      }
    }
    var req = {};

    console.log('COL:' + col);

    req['family'] = {};
    req['family'][col] = fileurl;

    console.log(req);
    this.familyinfoservice
      .updateFamilyInfo(req, familyId)
      .subscribe((response) => {
        console.log(response)
        // alert("DONE");
        this.logo = response['body']['family'].logo;
      });
  }

  async submitTree(): Promise<void> {
    if (this.familytree.invalid) {
      //  return;
    }
    let fileurl = this.selFileName; // this.familytree.get("filename").value;

    var formData: any = new FormData();
    formData.append('file', this.selFile);
    fileurl = await this.fileService.uploadFile(formData, true, true);
    this.familytree.patchValue({ filename: fileurl });
  }

  onUpdateMember() {
    var localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    let familyId = localstoragedata.family._id;
    var req = {};
    req['family'] = {};
    req['family']['name'] = this.familyname;
    this.familyinfoservice
      .updateFamilyInfo(req, familyId)
      .subscribe((response) => {
        console.log("========")
        alert('In order to reflect the new family name please re-login.');
      });
  }
  // onSearchClear() {
  //   this.searchKey = '';
  //   this.applyfilter();
  // }
  preventSubmit(event) {
    return event.key != 'Enter';
  }
  applyfilter(event) {
    this.searchKey = (event.target as HTMLInputElement).value;
    console.log('this.searchKey', this.searchKey);

    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }
  closeMyMenu() {
    this.trigger.closeMenu();
  }
  filterData(ev, col, val) {
    if (ev.checked) {
      col = String(col);

      // let value = { [col]: val };
      // this.filterValues.push(value);
      if (col === 'role') {
        this.filterValues = {
          role: [...this.filterValues.role, val],
          status: [...this.filterValues.status],
        };
      } else if (col === 'status') {
        this.filterValues = {
          role: [...this.filterValues.role],
          status: [...this.filterValues.status, val],
        };
      }
    } else {
      const index = this.filterValues[col].indexOf(val);
      this.filterValues[col].splice(index, 1);
    }

    console.log('filterValues', this.filterValues);

    this.setupFilter();

    this.applyFilter(val);
  }

  stoppro($event) {
    $event.stopPropagation();
  }

  setupFilter() {
    this.listData.filterPredicate = (d: any, filter: string) => {
      const filterVal = JSON.parse(filter);
      console.log('filter', filter);
      console.log(filterVal);
      var flag1 = false;
      var flag2 = false;

      console.log('role   ', d.role);
      console.log('status   ', d.status);

      if (filterVal.role.length === 0) {
        flag1 = true;
      } else if (filterVal.role.includes(d?.role)) {
        flag1 = true;
      }

      if (filterVal.status.length === 0) {
        flag2 = true;
      } else if (filterVal.status.includes(d?.status)) {
        flag2 = true;
      }
      console.log(flag1 + '  ' + flag2);

      return flag1 && flag2;
    };
  }

  applyFilter(filterValue: string) {
    console.log(JSON.stringify(this.filterValues));
    this.listData.filter = JSON.stringify(this.filterValues); // filterValue.trim().toLowerCase();
  }

  getCol(matrix, col) {
    var column = [];
    for (var i = 0; i < matrix.length; i++) {
      column.push(matrix[i][col]['role']);
    }
    return column; // return column data..
  }

  filterValues = { role: [], status: [] };
  filterColumns = ['role', 'status'];

  getAllRoles() {
    this.uacservice.getRoles().subscribe(
      (response) => {
        const result = response.body;
        this.ROLES_LIST = response.body['results'];

        this.ROLES_LIST = this.ROLES_LIST.filter(
          (thing, i, arr) => arr.findIndex((t) => t.role === thing.role) === i
        );
        console.log('ROLES_LIST', this.ROLES_LIST);
      },
      (err) => {}
    );
  }
  onSaveDetails() {
    if (
      this.family_tree_event !== null &&
      (this.family_tree_event.target as HTMLInputElement).files.length !== 0
    ) {
      this.onFileChange(this.family_tree_event, 'family_tree');
    }
    if (
      this.basic_details_event !== null &&
      (this.basic_details_event.target as HTMLInputElement).files.length
    ) {
      this.onFileChange(this.basic_details_event, 'logo');
    }
    this.onUpdateMember();
  }

  getFamilyUsers() {
    this.userservice.getFamilyUses().subscribe(
      (response) => {
        const result = response.body;
        this.listData.data = response.body['data'] as UserElement[];
        this.table.renderRows();

        this.ROLE_DATA = this.getCol(
          response.body['data'] as UserElement[],
          'role'
        );
        console.log(this.ROLE_DATA);

        this.ROLE_DATA = [...new Set(this.ROLE_DATA)];
      },
      (err) => {
        if (err.error.status != null) {
          //          this.errmessage = err.error.message;
        } else {
          //         this.errmessage = err.error.errors[0].msg;
        }
      }
    );
  }

  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [['Profile Name', 'Mobile No.', 'Email ID', 'Role']];
    this.listData.data.forEach((obj) => {
      console.log(obj);
      let name = obj['name'] ? obj['name'] : '';
      let middlename = obj['middlename'] ? ' ' + obj['middlename'] : '';
      let lastname = obj['lastname'] ? ' ' + obj['lastname'] : '';
      let mobile_no = obj['mobile'] ? obj['mobile'] : '';
      let email = obj['email'] ? obj['email'] : '';
      let role = obj['role'] ? obj['role'] : '';

      let arr = [name + middlename + lastname, mobile_no, email, role];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      rowPageBreak: 'avoid',
    });
    if (action === 'save') {
      doc.save('board-directors.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      // window.open(doc.output('bloburl') as unknown as string, '_blank');
      doc.output('dataurlnewwindow', { filename: 'family-info.pdf' });
    }
  }
}

<div class="content p30">
  <h5>{{ resource.message("SUBSCRIPTION") }}</h5>

  <!--
    <mat-grid-list cols="2" rowHeight="300px">
      <mat-grid-tile colspan=2 >
        <mat-card class="example-card">
          <mat-card-header>            
            <mat-card-title><i class="fa fa-file-text-o"></i> Dashboard Tile </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
            </p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>  
  -->

  <mat-card class="">
    <mat-card-header>
      <mat-card-title> {{ resource.message("SUBSCRIPTION") }} </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="form-inline">
        <div class="form-group mr-2">
          <button
            mat-stroked-button
            class="iconcss"
            (click)="convertToPDF('save')"
          >
            <i class="fa fa-file-pdf fa-lg"></i>
          </button>
          <button
            mat-stroked-button
            class="iconcss"
            (click)="exporter.exportTable('csv', { fileName: 'subscriptions' })"
          >
            <i class="fa fa-file-excel fa-lg"></i>
          </button>
          <button
            mat-stroked-button
            class="iconcss"
            (click)="convertToPDF('print')"
          >
            <i class="fa fa-print fa-lg"></i>
          </button>
        </div>
        <span class="example-spacer"></span>
        <div class="mr-1">
          <!--  <button [disabled]="!PERMISSION_ADD" mat-raised-button color="primary" class="addbutton" class="buttonfloatright" (click)="addKnwoledge()"><i class="fas fa-plus"></i>&nbsp;&nbsp;{{resource.message('SUBSCRIPTION_ACTION') | uppercase }}</button>
          -->
          <input
            matInput
            [(ngModel)]="searchKey"
            placeholder="{{ resource.message('SEARCH_BY_SUBSCRIPTION') }}"
            autocomplete="off"
            (keyup)="applyfilter()"
            (keydown.enter)="$event.preventDefault()"
            name="searchKey"
            class="searchfloatright"
          />
        </div>
      </form>
      <p>
        <br />
        <mat-table
          #roleTable
          roleTable
          matTableExporter
          [dataSource]="listData"
          matSort
          class="mattable"
          #exporter="matTableExporter"
          [hiddenColumns]="[4, 5, 6]"
        >
          <!-- Name Column -->
          <ng-container matColumnDef="subscription">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Subscription</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              element.subscription
            }}</mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="users_allowed">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Allowed Users</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              element.users_allowed
            }}</mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="cost">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Cost</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.cost }}</mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="cycle">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Cycle</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.cycle }}</mat-cell>
          </ng-container>

          <!-- System Column -->

          <!-- Action Column -->
          <ng-container matColumnDef="Action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button
                mat-icon-button
                (click)="edit(row)"
                [disabled]="!PERMISSION_EDIT"
              >
                <mat-icon>mode_edit</mat-icon>
              </button>
              <button
                mat-icon-button
                color="warn"
                (click)="onDelete(row._id)"
                [disabled]="!PERMISSION_DELETE"
              >
                <mat-icon>delete_outline</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 15, 20]"
          [pageSize]="5"
          showFirstLastButtons
        ></mat-paginator>
      </p>
    </mat-card-content>
  </mat-card>
</div>

<div class="content p30">
  <h5>{{ resource.message("FAMILIES") }}</h5>

  <!--
    <mat-grid-list cols="2" rowHeight="300px">
      <mat-grid-tile colspan=2 >
        <mat-card class="example-card">
          <mat-card-header>            
            <mat-card-title><i class="fa fa-file-text-o"></i> Dashboard Tile </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
            </p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>  
  -->

  <mat-card class="">
    <mat-card-header>
      <mat-card-title> {{ resource.message("FAMILIES") }} </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="form-inline">
        <div class="form-group mr-2">
          <button
            mat-stroked-button
            class="iconcss"
            (click)="convertToPDF('save')"
          >
            <i class="fa fa-file-pdf fa-lg"></i>
          </button>
          <button
            mat-stroked-button
            class="iconcss"
            (click)="exporter.exportTable('csv', { fileName: 'families' })"
          >
            <i class="fa fa-file-excel fa-lg"></i>
          </button>
          <button
            mat-stroked-button
            class="iconcss"
            (click)="convertToPDF('print')"
          >
            <i class="fa fa-print fa-lg"></i>
          </button>
        </div>
        <span class="example-spacer"></span>
        <div class="mr-1">
          <!-- <button mat-raised-button color="primary" class="addbutton" class="buttonfloatright" (click)="addrole()"><i class="fas fa-plus"></i>&nbsp;&nbsp;ADD {{resource.message('ROLE') | uppercase }}</button>-->
          <input
            matInput
            [(ngModel)]="searchKey"
            placeholder="{{ resource.message('SEARCH_BY_FAMILY') }}"
            autocomplete="off"
            (keyup)="applyfilter()"
            (keydown.enter)="$event.preventDefault()"
            name="searchKey"
            class="searchfloatright"
          />
        </div>
      </form>
      <p>
        <br />
        <mat-table
          #roleTable
          roleTable
          matTableExporter
          [dataSource]="listData"
          matSort
          class="mattable"
          #exporter="matTableExporter"
          [hiddenColumns]="[6]"
        >
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Family Name</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Date of Registration</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              element.created_at | customDate
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Email ID</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="mobile">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Contact No</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.mobile }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="paymentstatus">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Payment Status</mat-header-cell
            >
            <mat-cell *matCellDef="let element"
              ><span
                [ngClass]="
                  element.payment_status.toLowerCase() == 'active'
                    ? 'status-active'
                    : 'status-inactive'
                "
                >{{ element.payment_status | titlecase }}</span
              ></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="appstatus">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Family App Status</mat-header-cell
            >
            <mat-cell *matCellDef="let element"
              ><span
                [ngClass]="
                  element.app_status == true
                    ? 'status-active'
                    : 'status-inactive'
                "
                >{{ element.app_status == true ? "Active" : "In Active" }}</span
              ></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="portalstatus">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Family Portal Status</mat-header-cell
            >
            <mat-cell *matCellDef="let element"
              ><span
                [ngClass]="
                  element.admin_portal_status == true
                    ? 'status-active'
                    : 'status-inactive'
                "
                >{{
                  element.admin_portal_status == true ? "Active" : "In Active"
                }}</span
              ></mat-cell
            >
          </ng-container>

          <!-- System Column -->

          <!-- Action Column -->
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button
                mat-icon-button
                [disabled]="!PERMISSION_EDIT"
                (click)="edit(row)"
              >
                <mat-icon>mode_edit</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 15, 20]"
          [pageSize]="5"
          showFirstLastButtons
        ></mat-paginator>
      </p>
    </mat-card-content>
  </mat-card>
</div>

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { language } from '@shared/config/language';
import { ActivatedRoute, Router } from '@angular/router';
import { CommitteeService } from '@services/Committee.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DialogviewattendanceComponent } from '@shared/components/dialogviewattendance/dialogviewattendance.component';

@Component({
  selector: 'app-committeeresolution',
  templateUrl: './committeeresolution.component.html',
  styleUrls: ['./committeeresolution.component.css'],
})
export class CommitteeresolutionComponent implements OnInit {
  resource = language;
  topics: string[] = [];
  members: any[] = [];
  parentid: string;
  start_date: string;
  end_date: string;
  venue: string;
  showtab: boolean = false;
  agendaid: string;
  showView: Number;
  endpointResults: any;
  viewdata: boolean = true;
  meetingTitle:string

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private committeeService: CommitteeService,
    private dialog: MatDialog,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentid = data.id;
      }
    });
    this.getAllCommiteeMeetings(this.parentid);
  }
  outcome_tab(topic, index) {
    this.showView = index;
    this.agendaid = topic._id;
    console.log('::::::::::::', topic);
    //this.router.navigate(['/outcometab']);
  }

  getAllCommiteeMeetings(id) {
    this.changeDetectorRefs.detectChanges();

    var filter = '/' + id;

    this.committeeService.getCommitteeMeetings(filter).subscribe(
      (response: any) => {
        const result = response.data;
        console.log(response.data.agendas);
        this.meetingTitle = result.title;
        this.endpointResults = result;
        this.start_date = result.start_date;
        this.end_date = result.end_date;
        this.venue = result.venue;
        this.topics = [];
        let special_invitees = response.data.temporary_members.members
        this.members = [...response.data.members,...special_invitees];
        for (let i = 0; i < response.data.agendas.length; i++) {
          this.topics.push(response.data.agendas[i]);
          console.log(this.topics);
        }
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }

  openparticipants(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogviewattendanceComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        members: this.members,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        parentId: this.parentid,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('>><<<<>>>>', result.data.length);
    });
  }
  // defer the agenda
  defere_tab(topic, i) {
    console.log('TOPIC::', topic, 'Index:', i);
    this.committeeService.committeedefer(this.parentid, topic._id).subscribe(
      (response: any) => {
        this.getAllCommiteeMeetings(this.parentid);
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  mom() {
    this.committeeService.committeeUpdateStatus({meeting_status : 'added_resolution'}, this.parentid).subscribe(
      (response: any) => {},
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
    
    this.committeeService.getCommitteeMeetings(`/${this.parentid}`).subscribe(
      (response: any) => {
        const checkVotesAdded = response.data.agendas.some(val => (val.resolution !== 'consensus' && val.votes.length===0));
        // if(!checkVotesAdded) {
          this.router.navigate(['/committee/mom'], {
            queryParams: { id: this.parentid },
          });
        // }
   })
  }
}

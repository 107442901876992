import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MembershipService } from '@services/Membership.service';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import { CoreService } from '@services/CoreService';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { language } from '@shared/config/language';
import { AuthService } from '@services/Auth.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export interface MembershipElement {
  _id: number;
  user: any;
  start_date: string;
  valid_till: string;
  description: string;
  name: string;
  subtype: string;
  type: string;
  status: string;
}

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css'],
})
export class MembershipComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;

  ELEMENT_DATA: MembershipElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = ['userName', 'name', 'end', 'status', 'Action'];
  listData = new MatTableDataSource<MembershipElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<MembershipElement>(true, []);

  PERMISSION_EDIT = AuthService.authentication('Membership', 'READ');
  PERMISSION_ADD = AuthService.authentication('Membership', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Membership', 'DELETE');

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<MembershipElement>;

  resource = language;

  constructor(
    private membershipService: MembershipService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private http: HttpClient,
    private coreservice: CoreService,
    private datePipe: DatePipe,
    private titleCasePipe: TitleCasePipe
  ) {
    AuthService.authenticate_redir('Membership', ['READ']);

    this.listData = new MatTableDataSource<MembershipElement>(
      this.ELEMENT_DATA
    );
    this.selection = new SelectionModel<MembershipElement>(true, []);

    console.log(language.message('a'));
  }

  ngOnInit(): void {
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllTasks();
    this.table.renderRows();
  }

  getAllTasks() {
    this.membershipService.getMemberships().subscribe(
      (response) => {
        const result = response.body;
        this.listData.data = response.body['data'] as MembershipElement[];
        this.listData.data.forEach(function (value) {
          console.log(':::::', value.user.name);
          value['userName'] = value.user.name + ' ' + value.user.lastname;
        });
        this.table.renderRows();
      },
      (err) => {
        if (err.error.status != null) {
          //          this.errmessage = err.error.message;
        } else {
          //         this.errmessage = err.error.errors[0].msg;
        }
      }
    );
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listData.data.forEach((row) => this.selection.select(row));
  }

  addrole() {
    this.router.navigate(['/membership/add']);
  }

  edit(role) {
    this.router.navigate(['membership/add'], { queryParams: { id: role._id } });
  }

  onDelete(id) {
    this.coreservice
      .openConfirmDialog(this.resource.message('MEMBERSHIP_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.membershipService.removeMembership(id).subscribe(
            (response) => {
              const result = response;
              this.getAllTasks();
              this.coreservice.success(
                this.resource.message('MEMBERSHIP_DELETED')
              );
            },
            (err) => {}
          );
        }
      });
  }

  closeMyMenu() {
    this.trigger.closeMenu();
  }

  filterData(ev, col, val) {
    if (ev.checked) {
      col = String(col);

      let value = { [col]: val };
      this.filterValues.push(value);
    } else {
      let value = { [col]: val };
      const index = this.filterValues.indexOf(value);
      this.filterValues.splice(index - 1, 1);
    }

    this.setupFilter(col);
    this.applyFilter(val);
  }

  stoppro($event) {
    $event.stopPropagation();
  }

  setupFilter(column: string) {
    this.listData.filterPredicate = (d: any, filter: string) => {
      const val = JSON.parse(filter);
      var flag = false;

      val.forEach(function (item) {
        let obj = item;
        const textToSearch = (d[column] && d[column].toLowerCase()) || '';
        if (obj[column] != undefined) {
          //              if(textToSearch.toLowerCase().indexOf(obj[column].toLowerCase()) !== -1){
          if (textToSearch.toLowerCase() == obj[column].toLowerCase()) {
            flag = true;
          }
        }
      });
      return flag;
    };
  }

  applyFilter(filterValue: string) {
    this.listData.filter = JSON.stringify(this.filterValues); // filterValue.trim().toLowerCase();
  }

  filterValues = [];

  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [['Profile Name', 'Membership Name', 'Valid Till', 'Status']];
    this.listData.data.forEach((obj) => {
      let profile_name = obj['user'] != null ? obj['user']['name'] : '';
      let membership_name = obj['name'] ? obj['name'] : '';
      let valid_till = obj['valid_till']
        ? this.datePipe.transform(obj['valid_till'], 'M/dd/YYYY')
        : '';
      let status = obj['status']
        ? this.titleCasePipe.transform(obj['status'])
        : '';

      let arr = [profile_name, membership_name, valid_till, status];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      rowPageBreak: 'avoid',
    });

    if (action === 'save') {
      doc.save('membership.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      doc.output('dataurlnewwindow', { filename: 'membership.pdf' });
    }
  }
}

<div class="content p30">
  <div id="block_container">
    <a
      routerLink="/idp"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >

    <div id="bloc2">
      <h5>{{ resource.message("CREATE_MEETING") }}</h5>
    </div>
  </div>

  <mat-card class="">
    <mat-card-content>
      <form class="form-inline">
        <div class="form-group mr-2"></div>
        <span class="example-spacer"></span>
        <div class="mr-1">
          <input
            matInput
            [(ngModel)]="searchKey"
            placeholder="search"
            autocomplete="off"
            (keyup)="applyfilter()"
            name="searchKey"
            class="searchfloatright"
          />
        </div>
      </form>
      <br />

      <div class="row">
        <div class="col-sm-12">
          <span class="devarea">Choose development areas </span>
        </div>
      </div>
      <hr />
      <p>
        <br />
        <mat-table
          #goalTable
          goalTable
          matTableExporter
          [dataSource]="listData"
          matSort
          class="mattable"
          #exporter="matTableExporter"
          [hiddenColumns]="[5, 6]"
        >
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : clicked()"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
              >
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)"
              >
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="description">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="customTableHeaderStyle"
              style="justify-content: center"
              >Development Area</mat-header-cell
            >
            <mat-cell
              *matCellDef="let element"
              style="justify-content: center"
              >{{ element.description }}</mat-cell
            >
          </ng-container>

          <!-- Mobile Column -->
          <ng-container matColumnDef="category">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="customTableHeaderStyle"
              >Category</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              element.category
            }}</mat-cell>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="customTableHeaderStyle"
              style="justify-content: center"
              >Status</mat-header-cell
            >
            <mat-cell *matCellDef="let element" style="justify-content: center"
              >{{ selection.category
              }}<span
                [ngClass]="
                  element.status == 'completed'
                    ? 'status-active'
                    : '' || element.status == 'abandoned'
                    ? 'status-inactive'
                    : '' || element.status == 'in_review'
                    ? 'status-completed'
                    : 'status-progress'
                "
              ></span
            ></mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 15, 20]"
          [pageSize]="5"
          showFirstLastButtons
        ></mat-paginator>
      </p>

      <div>
        <button
          mat-stroked-button
          color="primary"
          class="addbutton"
          class="buttonfloatright"
          style="border: 1px solid #095294; font-weight: 600"
          (click)="openDialog('', '', '')"
        >
          View More
        </button>
      </div>
      <br />
      <br />
      <br />
      <span class="devarea">Other discussion topics</span>
      <hr />
      <br />
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <mat-grid-list cols="2" rowHeight="100px">
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Add Topic</mat-label>
              <input matInput placeholder="Add Topic" formControlName="topic" />
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-card
          class="optioncard"
          *ngFor="let opt of selection; let i = index"
          >{{ opt.option }}
          <mat-icon class="optionicon" (click)="remove(i)"
            >close</mat-icon
          ></mat-card
        >
        <mat-card-actions>
          <div class="form-group mr-2">
            <button
              mat-stroked-button
              type="button"
              class="wide"
              (click)="redirectTo()"
            >
              PREVIOUS
            </button>
          </div>
          <div class="rightpanel" align="right">
            <button
              mat-raised-button
              color="primary"
              class="btn btn-primary login-btn wide"
              type="submit"
              [disabled]="!form.valid || !selection.selected.length"
            >
              Next
            </button>
          </div>
        </mat-card-actions>
      </form>
    </mat-card-content>
  </mat-card>
</div>

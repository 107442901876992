<div align="right">
  <div class="content-container">
    <mat-card class="cardclass">
      <mat-card-header>
        <mat-card-title> </mat-card-title>
        <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
      </mat-card-header>
      <mat-card-content>
        <form></form>
        <br />
        <br />
        <br />
        <mat-table
          #roleTable
          roleTable
          matTableExporter
          [dataSource]="listData"
          matSort
          class="mattable"
          #exporter="matTableExporter"
          [hiddenColumns]="[5, 6]"
        >
          <ng-container matColumnDef="category">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="customTableHeaderStyle"
              >Category</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              element.category
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="customTableHeaderStyle"  style="justify-content: center;"
              >Development Areas</mat-header-cell
            >
            <mat-cell *matCellDef="let element" style="justify-content: center;">{{
              element.description
            }}</mat-cell>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="customTableHeaderStyle"  style="justify-content: center;"
              >Status</mat-header-cell
            >
            <mat-cell *matCellDef="let element" style="justify-content: center;"
              >{{ selection.category
              }}<span
                [ngClass]="
                  element.status == 'completed'
                    ? 'status-active'
                    : '' || element.status == 'abandoned'
                    ? 'status-inactive'
                    : '' || element.status == 'in_review'
                    ? 'status-completed'
                    : 'status-progress'
                "
              ></span
            ></mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 15, 20]"
          [pageSize]="5"
          showFirstLastButtons
        ></mat-paginator>
        <br />
        <mat-card-actions>
          <button
            mat-raised-button
            color="primary"
            class="buttonfloatright"
            (click)="closeDialog()"
          >
            Close
          </button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommitteeService } from '@services/Committee.service';
import { constants } from '@shared/config/constants';
import { language } from '@shared/config/language';
import { CoreService } from '@services/CoreService';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-committee-s1',
  templateUrl: './committee-s1.component.html',
  styleUrls: ['./committee-s1.component.css'],
})
export class CommitteeS1Component implements OnInit {
  resource = language;
  steps = [];
  isTemplate: boolean = false;
  
  CATEGORY_ENABLE: boolean = true;
  TYPE_LIST: any;
  CATEGORY_LIST: any;

  COMMITTEE_LIST: any;
  SUBCOMMITTEE_LIST: any;

  isEdit: Boolean = false;

  TEMPLATE = 0;

  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    type: new FormControl(0, Validators.required),
    title: new FormControl('', Validators.required),
    categories: new FormControl(
      { value: '', disabled: !this.CATEGORY_ENABLE },
      Validators.required
    ),
    purpose: new FormControl('', Validators.required),
    committee: new FormControl('', Validators.required),
    subcommittee: new FormControl('', Validators.required),
  });

  constructor(
    private committeeService: CommitteeService,
    private coreservice: CoreService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.TYPE_LIST = constants.committee_categoryies;
    this.getAllCategories();

    this.activeRoute.queryParams.subscribe((data) => {
      if (data.template != undefined) {
        this.TEMPLATE = data.template;
        if (this.TEMPLATE == 1) this.isTemplate = true;
        else this.isTemplate = false;
      }

      if (this.isTemplate) {
        this.steps = constants.committee_template_steps;
      } else {
        this.steps = constants.committee_steps;
      }

      if (data.id != undefined) {
        this.getCommittees('committee', '');
        this.isEdit = true;
        this.form.patchValue({ _id: data.id });
        this.committeeService.getCommittee(data.id).subscribe((response) => {
          const result = response.body;
          var CURRENT_COMMITTEE = result['data'];

          this.form.patchValue({
            _id: CURRENT_COMMITTEE['_id'],
            type: CURRENT_COMMITTEE['type'],
            title: CURRENT_COMMITTEE['title'],
            categories: CURRENT_COMMITTEE['categories']['_id'],
            purpose: CURRENT_COMMITTEE['purpose'],
            committee:
              CURRENT_COMMITTEE['committee'] != null
                ? CURRENT_COMMITTEE['committee']['_id']
                : '',
            subcommittee:
              CURRENT_COMMITTEE['subcommittee'] != null
                ? CURRENT_COMMITTEE['subcommittee']['_id']
                : '',
          });

          if (this.form.get('type').value == 'subsubcommittee') {
            this.getCommittees(
              'subcommittee',
              this.form.get('committee').value
            );
          }
          this.changeType(CURRENT_COMMITTEE['type'], false)
        });
      }
    });
  }

  getUniqueListByKey(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  getAllCategories() {
    this.committeeService.getCommitteeCategories('').subscribe(
      (response) => {
        const result = response.body;
        this.CATEGORY_LIST = response.body['data'];
      },
      (err) => {}
    );
  }

  submit(type): void {
    console.log(this.form);
    if (this.form.invalid) {
      return;
    }

    if (this.form.get('type').value == 'committee') {
      this.form.removeControl('committee');
      this.form.removeControl('subcommittee');
    }

    if (this.form.get('type').value == 'subcommittee') {
      this.form.removeControl('subcommittee');
    }

    var rawcommittee = this.form.getRawValue();

    if (!this.isEdit) {
      this.form.patchValue({ _id: '' });
      //      this.form.removeControl('_id');
      rawcommittee = this.form.getRawValue();
    }

    //rawcommittee['category']=''

    this.committeeService.addCommittee(rawcommittee).subscribe(
      (response) => {
        const result = response.body;

        constants.CURRENT_COMMITTEE = result['data'];
        if (this.isTemplate) {
          if (type == 'final') {
            this.router.navigate(['committee/add/s2'], {
              queryParams: {
                id: constants.CURRENT_COMMITTEE['_id'],
                template: this.TEMPLATE,
              },
            });
          } else {
            this.coreservice.success('Committee saved as draft');
            this.router.navigate(['/templatemanagement']);
          }
        } else {
          if (type == 'final') {
            this.router.navigate(['committee/add/s2'], {
              queryParams: {
                id: constants.CURRENT_COMMITTEE['_id'],
                template: this.TEMPLATE,
              },
            });
          } else {
            this.coreservice.success('Committee saved as draft');
            this.router.navigate(['/committee']);
          }
        }
      },
      (err) => {
        console.log(err);
        //      var errormessages = "Please correct the errors - \n";
        //      err.error.errors.forEach(element => errormessages += " - "+element.msg+"\n");
        this.coreservice.error('Invalid Committee - ' + err.error.message);
        //  this.form.addControl('_id', new FormControl(''));
      }
    );
  }
  clearForm() {
    this.form.patchValue({
      type: 0,
      title: '',
      categories: '',
      purpose: '',
      committee: '',
      subcommittee: '',
    });
  }

  changeType(type, alreadyFetch) {
    if(type == 'subcommittee') {
      this.form.controls["committee"].setValidators([Validators.required]);
    this.form.controls["subcommittee"].setValidators(null);
    this.form.controls["committee"].updateValueAndValidity();
    this.form.controls["subcommittee"].updateValueAndValidity();
    }
    if(type == 'subsubcommittee') {
      this.form.controls["committee"].setValidators([Validators.required]);
      this.form.controls["subcommittee"].setValidators([Validators.required]);
      this.form.controls["committee"].updateValueAndValidity();
      this.form.controls["subcommittee"].updateValueAndValidity();
    }
    if(type == 'committee') {
      this.form.controls["committee"].setValidators(null);
      this.form.controls["subcommittee"].setValidators(null);
      this.form.controls["committee"].updateValueAndValidity();
      this.form.controls["subcommittee"].updateValueAndValidity();
    }
    if(alreadyFetch){
      this.form.patchValue({
        committee: '',
        subcommittee: '',
      });
    }
    console.log(type)
    this.SUBCOMMITTEE_LIST = [];
    this.COMMITTEE_LIST = [];
    this.committeeService
      .getCommittees()
      .subscribe((response) => {
        const res = response.body['data'].filter(data => data.is_published);
        if (type == 'committee' || type == 'subcommittee') {
          this.COMMITTEE_LIST = res.filter(data => data.type == "committee");
          this.CATEGORY_ENABLE = true;
        }
        else {
          this.CATEGORY_ENABLE = false;
          console.log(res.filter(data => data.committee))
          this.COMMITTEE_LIST = res.filter(data => data.committee && data.type == "subcommittee").map(val => val.committee);
          this.COMMITTEE_LIST = this.getUniqueListByKey(this.COMMITTEE_LIST, "_id");
        }
        console.log(this.COMMITTEE_LIST)
      });
  }

  getCommittees(type, committee) {
    console.log(this.form);
    console.log(committee);
    if (type != 'committee') {
      if(type == 'subcommittee') {
        this.form.patchValue({ subcommittee: ""})
      }
      let selectedCommittee = this.COMMITTEE_LIST.find(
        (category) => category._id == committee
      );
      this.form.get('categories').setValue(selectedCommittee.categories._id);
      this.CATEGORY_ENABLE = false;
    } else {
      
      this.form.patchValue({committee: '', subcommittee: ""})
      this.CATEGORY_ENABLE = true;
    }

    this.committeeService
      .getCommitteesFilter(type, committee)
      .subscribe((response) => {
        if (type == 'committee') {
          this.COMMITTEE_LIST = response.body['data'];
        } else if (type == 'subcommittee') {
          this.SUBCOMMITTEE_LIST = response.body['data'];
          console.log(this.SUBCOMMITTEE_LIST);

          if (response.body['data'].length < 1) {
            //this.coreservice.error(this.resource.message('NO_SUB_COMMITTEE'));
          }
        }
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { language } from '@shared/config/language';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent  implements OnInit {

  
  resource = language;
  activePath = 0;
 
  constructor(route: ActivatedRoute) {

    route.url.subscribe(() => {
      console.log("CURRENT CHILD")
      console.log(route.snapshot.firstChild);
      let currentTab = (route.snapshot.firstChild != null)?route.snapshot.firstChild.url[0]["path"]:'category';

      if(currentTab == 'category') this.activePath = 0;
      if(currentTab == 'faknowledgehub') this.activePath = 1;
      if(currentTab == 'saknowledgehub') this.activePath =2 ; 


     });

   }
  ngOnInit(): void {
  }

}

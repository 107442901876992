<mat-card class="">
    <mat-card-content>
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" #group="matButtonToggleGroup" [value]="selectedVal" (change)="onValChange(group.value)">
            <mat-button-toggle value="boardreads" (change)="changeTo($event.value)"  [checked]="selectedVal === 'boardreads'">Board Reads</mat-button-toggle>
            <mat-button-toggle value="financial_reports" (change)="changeTo($event.value)" [checked]="selectedVal === 'financial_reports'">Financial Reports</mat-button-toggle>
            <mat-button-toggle value="executive_summary" (change)="changeTo($event.value)" [checked]="selectedVal === 'executive_summary'">Executive Summary</mat-button-toggle>
            <mat-button-toggle value="others" (change)="changeTo($event.value)" [checked]="selectedVal === 'others'">Others(CIP,PAN,TAN)</mat-button-toggle>
          </mat-button-toggle-group>
 </mat-card-content>
 <div *ngIf="boardreads">
    <app-boardreads [id]="parentId" [changeto]="mainRead"></app-boardreads>
 </div>
 <div *ngIf="financial_reports">
    <app-financialreports [id]="parentId"></app-financialreports>
 </div>
 <div *ngIf="executive_summary">
    <app-executivesummary [id]="parentId"></app-executivesummary>
 </div>
 <div *ngIf="others">
    <app-otherreports [id]="parentId"></app-otherreports>
 </div>
</mat-card>                 
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { DialogattendanceqorumComponent } from '../dialogattendanceqorum/dialogattendanceqorum.component';
@Component({
  selector: 'app-dialogattendence',
  templateUrl: './dialogattendence.component.html',
  styleUrls: ['./dialogattendence.component.css'],
})
export class DialogattendenceComponent implements OnInit {
  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogattendenceComponent>,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}
  schedule() {
    // this.router.navigate(['committee/resolution'], {
    //   queryParams: {
    //     id: this.data.id,
    //   },
    // });
    this.next_clcik();
    this.closeDialog();
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  next_clcik() {
    const dialogRef = this.dialog.open(DialogattendanceqorumComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: 'Attendance Quorum',
        actionbutton: '',
        cancelbutton: '',
        id: this.data.id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // this.router.navigate(['committee/rationalized'], {
      //   queryParams: { id: this.parentid },
      // });
    });
  }
}

<div align="right">
  <div class="dialogcontent-container">
    <mat-card class="cardclass">
      <mat-card-header class="add-category">
        <mat-card-title>
          {{ resource.message("CATEGORYCREATION") }}
        </mat-card-title>
        <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
      </mat-card-header>
      <hr />
      <form [formGroup]="form" (ngSubmit)="submitData()">
        <mat-card-content>
          <div class="row mt-3">
            <div class="col-sm-12 text-left">
              <div class="row">
                <input type="hidden" formControlName="_id" />
                <div class="col-sm-12 text-left ml-4 mb-2">Category Type : </div>
                <div class="col-sm-12 text-left ml-2">
                  <mat-radio-group
                    color="primary"
                    appearance="outline"
                    class="smallRadio"
                    [formControl]="file_type"
                  >
                    &nbsp;&nbsp;&nbsp;
                    <mat-radio-button
                      color="primary"
                      value="category"
                      [checked]="file_type.value == 'category'"
                      (change)="this.form.patchValue({ categroy_type: 'category' })"
                      class="m20 radio-bold"
                    ><span>Category</span>
                    </mat-radio-button>
                    &nbsp;&nbsp;&nbsp;
                    <mat-radio-button
                      value="sub-category"
                      [checked]="file_type.value == 'sub-category'"
                      (change)="this.form.patchValue({ categroy_type: 'sub-category' })"
                      class="m20 radio-bold">Sub-Category</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>

          <mat-grid-list cols="2" rowHeight="100px">
<!--            <mat-grid-tile>-->
<!--              <input type="hidden" formControlName="_id" />-->
<!--              <mat-label>Category Type : </mat-label> &nbsp;&nbsp;&nbsp;-->
<!--              <mat-radio-group-->
<!--                appearance="outline"-->
<!--                class="smallRadio"-->
<!--                [formControl]="file_type"-->
<!--              >-->
<!--                &nbsp;&nbsp;&nbsp;-->
<!--                <mat-radio-button-->
<!--                  value="category"-->
<!--                  [checked]="file_type.value == 'category'"-->
<!--                  (change)="this.form.patchValue({ categroy_type: 'category' })"-->
<!--                  class="m20"-->
<!--                  ><span>Category</span>-->
<!--                </mat-radio-button>-->
<!--                &nbsp;&nbsp;&nbsp;-->
<!--                <mat-radio-button-->
<!--                  value="sub-category"-->
<!--                  [checked]="file_type.value == 'sub-category'"-->
<!--                  (change)="-->
<!--                    this.form.patchValue({ categroy_type: 'sub-category' })-->
<!--                  "-->
<!--                  class="m20"-->
<!--                  >Sub-Category</mat-radio-button-->
<!--                >-->
<!--              </mat-radio-group>-->
<!--            </mat-grid-tile>-->
<!--            <mat-grid-tile></mat-grid-tile>-->
            <mat-grid-tile *ngIf="file_type.value == 'sub-category'">
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Category Title</mat-label>
                <mat-select
                  placeholder="Category Title"
                  formControlName="parent"
                  required
                >
                  <mat-option
                    *ngFor="let category of CATEGORY"
                    [value]="category._id"
                    >{{ category.title }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="file_type.value == 'sub-category'">
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Sub-Category Title</mat-label>
                <input
                  matInput
                  placeholder="Sub-Category Title"
                  formControlName="title"
                />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="file_type.value == 'category'">
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Category Name</mat-label>
                <input
                  matInput
                  placeholder="Category Name"
                  formControlName="title"
                />
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="submit"
            *ngIf="isEdit"
          >
            Save
          </button>
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="submit"
            *ngIf="!isEdit"
          >
            Add
          </button>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
</div>

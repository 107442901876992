import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { language } from '@shared/config/language';
import { DialogemailinviteComponent } from '@shared/components/dialogemailinvite/dialogemailinvite.component';
import { IdpService } from '@services/Idp.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

export interface MediaElement {
  name: string;
  attendence: string;
}
@Component({
  selector: 'app-dialoginvitees',
  templateUrl: './dialoginvitees.component.html',
  styleUrls: ['./dialoginvitees.component.css'],
})
export class DialoginviteesComponent implements OnInit {
  displayedColumns: string[] = ['name', 'attendence'];
  ELEMENT_DATA: MediaElement[];
  resource = language;
  searchKey: string;
  listData = new MatTableDataSource<MediaElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<MediaElement>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<MediaElement>;

  // form: FormGroup = new FormGroup({
  //   _id: new FormControl(''),
  //   participantname: new FormControl('', Validators.required),
  //   participantemail: new FormControl('', Validators.required),
  // });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialoginviteesComponent>,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog,
    private idp: IdpService
  ) {
    this.listData = new MatTableDataSource<MediaElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<MediaElement>(true, []);
  }

  ngOnInit(): void {
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getMeetings(this.data.id);
    this.table.renderRows();
  }
  closeDialog() {
    this.dialogRef.close(false);
  }

  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  getMeetings(id) {
    var filter = '';
    filter = '?filter[_id]=' + id; //+this.localstoragedata._id
    this.idp.idpMeetingGet(filter).subscribe(
      (response: any) => {
        this.listData.data = [...response.data[0].members,...response.data[0].temporary_members.members];
        console.log(response.data[0].members);
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
}

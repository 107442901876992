import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FileService } from '@services/File.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { language } from '@shared/config/language';

@Component({
  selector: 'app-dialogemailinvite',
  templateUrl: './dialogemailinvite.component.html',
  styleUrls: ['./dialogemailinvite.component.css'],
})
export class DialogemailinviteComponent implements OnInit {
  selFile = new Object();

  selFileName = '';
  resource = language;
  currentfile = {};
  currentfilename = '';
  selectedfile: any;
  form: FormGroup = new FormGroup({
    _id: new FormControl(''),
    subject: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
    file: new FormControl(''),
    file_url: new FormControl(''),
    filename: new FormControl(''),
  });
  constructor(
    private fileService: FileService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogemailinviteComponent>,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {}
  onFileChange(event) {
    const latestfile = (event.target as HTMLInputElement).files[0];
    this.currentfile = latestfile;
    this.currentfilename = latestfile.name;
    this.selectedfile = latestfile;
    var filename = latestfile.name;

    this.form.patchValue({
      filename: this.currentfilename,
    });

    this.form.patchValue({
      file: this.currentfile,
    });

    this.form.get('file').updateValueAndValidity();
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  async submitData(): Promise<void> {
    var rawuser = this.form.getRawValue();
    if (
      this.form.get('file').value !== undefined &&
      this.form.get('file').value !== ''
    ) {
      let fileurl = this.form.get('file').value;
      var formData: any = new FormData();
      formData.append('file', this.form.get('file').value);
      fileurl = await this.fileService.uploadFile(formData, true, false);
      rawuser.file_url = fileurl;
    }
    this.dialogRef.close(rawuser);
  }
}

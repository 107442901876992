import { Component, OnInit ,Input} from '@angular/core';
import {language} from '@shared/config/language';
import {BusinessInfoService} from '@services/BusinessInfo.service';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';

@Component({
  selector: 'app-keyinfo',
  templateUrl: './keyinfo.component.html',
  styleUrls: ['./keyinfo.component.css']
})
export class KeyinfoComponent implements OnInit {
  boardreads:boolean=false;
  resource=language;
  @Input() modulefor: string;
  parentId:string;
  others:boolean=false;
  financial_reports:boolean=false;
  executive_summary:boolean=false
  mainRead:string='boardRead'
  public selectedVal: string;

  constructor(  private businessinfoservice: BusinessInfoService,  private activeRoute: ActivatedRoute,
    ) { 
    this.initialLoad();
    
  }

  ngOnInit(): void {
   this.initialLoad();
   setTimeout(()=>{                           //<<<---using ()=> syntax
    this.boardreads = true;
    this.selectedVal='boardRead'
}, 3000);
  
  }
  initialLoad()
  { 
    console.log(">>>>>>>>>"+this.modulefor)
    if(this.modulefor=='parent')
    {
      console.log("IN PARENT")
      this.getparentid();
    }
    else
    {
      console.log("IN CHILD")

      this.getunitid();
    }
  
  }
  getunitid()
  {
    this.businessinfoservice.businessunitget('').subscribe((response:any)=> {
      const params = this.activeRoute.snapshot.queryParams;
      var data = _.find(response['data'], {_id:params['id']});
      //this.parentId=response['data'][0]['_id']
      if(data && data['_id']){
        this.parentId = data['_id'];
      }
      }, (err) => {
     })
 
  }

  getparentid()
  {
    this.businessinfoservice.businessinfoget().subscribe((response:any)=> {
      this.parentId=response['data'][0]['info']['_id']
     }, (err) => {
        
     })
 
  }
  changeTo(val)
  {
    console.log("*************************",val)
      if(val=='boardreads')
      {
        this.boardreads=true;
        this.others=false;
        this.financial_reports=false;
        this.executive_summary=false;
      }
      else if(val=='others')
      {
        this.boardreads=false;
        this.others=true;
        this.financial_reports=false;
        this.executive_summary=false;
      }
      else if(val=='financial_reports')
      {
        this.boardreads=false;
        this.others=false;
        this.financial_reports=true;
        this.executive_summary=false;
      }
      else{
        this.boardreads=false;
        this.others=false;
        this.financial_reports=false;
        this.executive_summary=true;
      }
  }

  public onValChange(val: string) {
    this.selectedVal = val;
  }
}

import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
} from '@angular/forms';
import { AdminKnowledgeHubService } from '@services/AdminKnowledgeHub';
import { KnowledgeHubService } from '@services/KnowledgeHub.service';

import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '@services/CoreService';
import { FileService } from '@services/File.service';
import { language } from '@shared/config/language';
import { AuthService } from '@services/Auth.service';
@Component({
  selector: 'app-addknowledgehub',
  templateUrl: './addknowledgehub.component.html',
  styleUrls: ['./addknowledgehub.component.css'],
})
export class AddknowledgehubComponent implements OnInit {
  selectedfile: any;
  isEdit: Boolean = false;
  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    filename: new FormControl('', Validators.required),
    file: new FormControl(''),
    title: new FormControl('', Validators.required),
    category: new FormControl(),
  });
  resource = language;
  knowledgePdf: string = '';
  source = 'superadmin';

  PERMISSION_EDIT = AuthService.authentication('Knowledge Hub', 'EDIT');
  PERMISSION_ADD = AuthService.authentication('Knowledge Hub', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Knowledge Hub', 'DELETE');
  CATEGORY_LIST = [];

  constructor(
    private adminKnowledgeHubService: AdminKnowledgeHubService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private knowledgeHubService: KnowledgeHubService,
    private fileService: FileService
  ) {
    this.getAllCategories();

    this.activeRoute.queryParams.subscribe((data) => {
      if (data.source != undefined) this.source = data.source;

      var modulename = 'Knowledge Hub';
      if (this.source == 'familyadmin') modulename = 'Family Knowledge Hub';
      this.PERMISSION_EDIT = AuthService.authentication(modulename, 'READ');
      this.PERMISSION_ADD = AuthService.authentication(modulename, 'ADD');
      this.PERMISSION_DELETE = AuthService.authentication(modulename, 'DELETE');
      //AuthService.authenticate_redir(modulename, ['READ']);

      if (data.id != undefined) {
        AuthService.authenticate_redir(modulename, 'EDIT');
        this.isEdit = true;

        if (this.source == 'familyadmin') {
          this.knowledgeHubService
            .getKnwoledgeFile(data.id)
            .subscribe((response) => {
              const result = response.body['knowledgebase'][0];
              this.form.patchValue({
                _id: result._id,
                filename: result.filename,
                title: result.title,
                file: null,
                category: result.category._id,
              });
              this.knowledgePdf = result.filename;
            });
        } else {
          this.adminKnowledgeHubService
            .getKnwoledgeFile(data.id)
            .subscribe((response) => {
              const result = response.body['knowledgebase'];
              this.form.patchValue({
                _id: result._id,
                filename: result.filename,
                title: result.title,
                file: null,
                category: '',
              });
              this.knowledgePdf = result.filename;
            });
        }
      } else {
        AuthService.authenticate_redir(modulename, 'ADD');
      }
    });
  }

  getAllCategories(): void {
    this.knowledgeHubService.getKnwoledgeCategories().subscribe((response) => {
      this.CATEGORY_LIST = response.body['data'];
    });
  }

  previous() {
    if (this.source == 'familyadmin') {
      this.router.navigate(['knowledgehub']);
    } else {
      this.router.navigate(['adminknowledgehub']);
    }
  }
  ngOnInit(): void {
    this.form.setValue({
      _id: 0,
      title: '',
      filename: '',
      file: null,
      category: '',
    });
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    if (!this.isEdit) this.form.removeControl('_id');

    let fileurl = this.form.get('filename').value;

    console.log(this.form.get('file').value);

    if (this.form.get('file').value != undefined) {
      var formData: any = new FormData();
      formData.append('file', this.form.get('file').value);
      fileurl = await this.fileService.uploadFile(formData, true, true);
    }

    this.form.patchValue({ filename: fileurl });

    if (this.source == 'familyadmin') {
      this.knowledgeHubService
        .addKnowledgFile(this.form.getRawValue())
        .subscribe(
          (response) => {
            const result = response.body;
            if (!this.isEdit) {
              this.coreservice.success(this.resource.message('KNOWLEDGE_SAVE'));
            } else {
              this.coreservice.success(
                this.resource.message('KNOWLEDGE_UPDATE')
              );
            }
            this.router.navigate(['knowledgehub']);
          },
          (err) => {
            this.coreservice.error(err.error.message);
            this.form.addControl('_id', new FormControl(''));
          }
        );
    } else {
      this.adminKnowledgeHubService
        .addKnowledgFile(this.form.getRawValue())
        .subscribe(
          (response) => {
            const result = response.body;
            if (!this.isEdit) {
              this.coreservice.success(this.resource.message('KNOWLEDGE_SAVE'));
            } else {
              this.coreservice.success(
                this.resource.message('KNOWLEDGE_UPDATE')
              );
            }
            this.router.navigate(['adminknowledgehub']);
          },
          (err) => {
            this.coreservice.error(err.error.message);
            this.form.addControl('_id', new FormControl(''));
          }
        );
    }
  }

  onFileChange(event) {
    const selectf = (event.target as HTMLInputElement).files[0];

    this.form.patchValue({
      filename: selectf.name,
    });

    this.form.patchValue({
      file: (event.target as HTMLInputElement).files[0],
    });
    this.form.get('file').updateValueAndValidity();
  }

  public onFileChangeori(event) {
    const latestfile = (event.target as HTMLInputElement).files[0];
    this.selectedfile = latestfile;
    var filename = latestfile.name;
    console.log(filename);
    console.log(event);
    console.log('2' + JSON.stringify(event.target.files));

    this.form.patchValue({
      filename: filename,
    });
    this.form.patchValue({
      file: latestfile,
    });

    this.form.get('file').updateValueAndValidity();
  }
  previewimg(eve) {
    window.open(this.knowledgePdf, '_blank');
  }
}

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { language } from '@shared/config/language';
import { DialogmeetingdataComponent } from '@shared/components/dialogmeetingdata/dialogmeetingdata.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { IdpService } from '@services/Idp.service';
import { DialogviewattendanceComponent } from '@shared/components/dialogviewattendance/dialogviewattendance.component';
import { DialogaddnoteComponent } from '@shared/components/dialogaddnote/dialogaddnote.component';
import { CommitteeService } from '@services/Committee.service';
import { DialogattendenceComponent } from '@shared/components/dialogattendence/dialogattendence.component';

export interface GoalElement {
  development: number;
  category: string;
  status: string;
}
@Component({
  selector: 'app-committeeattendence',//main
  templateUrl: './committeeattendence.component.html',
  styleUrls: ['./committeeattendence.component.css'],
})
export class CommitteeattendenceComponent implements OnInit {
  resource = language;

  ELEMENT_DATA: GoalElement[];
  endpointResults: any;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = ['name', 'attendence', 'status'];
  listData = new MatTableDataSource<GoalElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<GoalElement>(true, []);
  localstoragedata: any;
  form: FormGroup = new FormGroup({
    _id: new FormControl(''),
    topic: new FormControl('', Validators.required),
  });
  start_date: string;
  meetingTitle:string;
  end_date: string;
  venue: string;
  mail: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('goalTable') table: MatTable<GoalElement>;
  parentid: string;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private idp: IdpService,
    private activeRoute: ActivatedRoute,
    private changeDetectorRefs: ChangeDetectorRef,
    private committeeService: CommitteeService
  ) {}

  ngOnInit(): void {
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentid = data.id;
      }
    });
    this.getMeetings(this.parentid);
  }
  openDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogmeetingdataComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  // onSubmit() {
  //   this.router.navigate(['outcome'], {
  //     queryParams: {
  //       id: this.parentid,
  //     },
  //   });
  // }
  getMeetings(id) {
    var filter = '/' + id;

    this.committeeService.getCommitteeMeetings(filter).subscribe(
      (response: any) => {
        console.log(response.data,'dsds');
        this.endpointResults = response.data;
        this.meetingTitle = response.data.title
        let committee_members =response.data.committee.members
        this.mail = response.data.temporary_members;
        let special_invitees = response.data.temporary_members.members
        this.listData.data = [...response.data.members,...special_invitees]
        //this.listData.data = response.data.members;
        this.start_date = response.data.start_date;
        this.end_date = response.data.end_date;
        this.venue = response.data.venue;
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }

  setTemporaryMemberAttendance(member: any) {
    const req = {};
    req['temporary_members'] = {
      subject: this.mail.subject,
      mailbody: this.mail.message,
      attachment: this.mail.file_url,
      members: [...this.mail.members.filter(val => val._id !== member._id), member],
    };
    this.committeeService.committeeParticipants(req, this.parentid).subscribe(
      (response: any) => {
        this.getMeetings(this.parentid);
      })
  }

  markAttendence(status, member) {
    if(member.email) {
    this.setTemporaryMemberAttendance({...member, attendance: status})
    return;
    }
    let data = [];
    console.log(member,'c')
    data.push({
      member_id: member.id,
      attendance: status,
    });

    this.idp.idpstatuspost(data, this.parentid).subscribe(
      (response: any) => {
        console.log(data);
        this.getMeetings(this.parentid);
        this.table.renderRows();
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  openparticipants(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogviewattendanceComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        members: this.listData.data,
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        parentId: this.parentid,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('>><<<<>>>>', result.data.length);
    });
  }
  cancelMeeting() {
    const dialogRef = this.dialog.open(DialogaddnoteComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: 'MEETING CANCELLED',
        actionbutton: '',
        cancelbutton: '',
        id: this.parentid,
        type: 'cancel',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
    //   this.idp.idpcancel(this.parentid).subscribe(
    //     (response: any) => {
    //       //    this.router.navigate(['idp']);
    //     },
    //     (err) => {
    //       console.log('errrrrrrr', err);
    //     }
    //   );
  }
  next_clcik() {
    // let criteria = false;
    // const type = this.endpointResults?.committee.attendance_quorum?.attendance_quorum_type;
    // const value = (this.endpointResults?.committee.attendance_quorum?.attendance_quorum_value || 0);
    // const presentCount = this.endpointResults.members?.filter(val => val.attendance === true).length;
    // const presentCountPercent = (presentCount*100)/this.endpointResults?.members?.length;
    // if(type === 'number') {
    //   criteria = value <=  presentCount;
    // }
    // else {
    //   criteria = value <=  presentCountPercent;
    // }
    // if(criteria) {
      this.router.navigate(['committee/resolution'], {
        queryParams: {
          id: this.parentid
        },
      });
      // return;
    // }
    // const dialogRef = this.dialog.open(DialogattendenceComponent, {
    //   width: '50vw',
    //   panelClass: 'confirm-dialog-container',
    //   disableClose: true,
    //   data: {
    //     message: 'Rationalise Agenda',
    //     actionbutton: '',
    //     cancelbutton: '',
    //     id: this.parentid,
    //   },
    // });
    // dialogRef.afterClosed().subscribe((result) => {
      // this.router.navigate(['committee/rationalized'], {
      //   queryParams: { id: this.parentid },
      // });
    //});
  }
  edit_meeting() {
    this.router.navigate(['/committee/meetings'], {
      queryParams: { id: this.parentid },
    });
  }
}

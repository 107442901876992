<div class="content p30">
  <div id="block_container">
    <ng-container [ngSwitch]="TEMPLATE">
      <a
        *ngSwitchCase="'1'"
        routerLink="/templatemanagement"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
      <a
        *ngSwitchCase="'0'"
        routerLink="/committee"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
    </ng-container>
    <div id="bloc2"><h5>Committee / Add</h5></div>
  </div>

  <form [formGroup]="form" (ngSubmit)="submit('final')">
    <input type="hidden" formControlName="_id" />
    <mat-card class="">
      <mat-card-content>
        <app-stepper
          [isTemplate]="isTemplate"
          [currentStep]="isTemplate ? 4 : 5"
        ></app-stepper>

        <br />
        <h4 class="steph4">{{ isTemplate ? steps[4] : steps[5] }}</h4>
        <hr />
        <p>
          <span class="bluelabel"
            >Consensus &nbsp;
            <mat-slide-toggle
              #consensus
              (change)="
                form.patchValue({
                  is_consensus: consensus.checked,
                  is_voting: false,
                  is_hybrid: false,
                  voting_rules: '',
                  hybrid_rules: ''
                })
              "
              [checked]="form.get('is_consensus').value"
            ></mat-slide-toggle>
          </span>
          <br /><br />
          <mat-form-field appearance="outline" class="field-spacer1 txt">
            <textarea
              matInput
              rows="5"
              class=""
              placeholder="Description"
              formControlName="use_consensus"
            ></textarea>
          </mat-form-field>
        </p>

        <hr />

        <div>
          <div class="mr-2">
            <div appearance="outline" class="field-spacer1 txt bluelabel">
              Voting &nbsp;
              <mat-slide-toggle
                #voting
                (change)="
                  form.patchValue({
                    is_voting: voting.checked,
                    is_consensus: false,
                    is_hybrid: false,
                    hybrid_rules: '',
                    use_consensus: ''
                  })
                "
                [checked]="form.get('is_voting').value"
              ></mat-slide-toggle>
            </div>
            <mat-form-field appearance="outline" class="field-spacer1 txt2">
              <textarea
                matInput
                rows="5"
                class=""
                placeholder="Description"
                formControlName="voting_rules"
              ></textarea>
            </mat-form-field>
          </div>

          <span class="example-spacer"></span>

          <div class="form-group mr" *ngIf="form.get('is_voting').value">
            <span appearance="outline" class="field-spacer1 txt mr">
              <label appearance="outline" class="field-spacer1 txt bluelabel"
                >Resolution Quorum </label
              ><br />
              <mat-radio-group
                appearance="outline"
                class="field-spacer mt-5"
                [formControl]="typeControl"
              >
                <mat-radio-button
                  value="number"
                  [checked]="typeControl.value == 'number'"
                  (change)="
                    this.form.patchValue({ resolution_quorum_type: 'number' });
                  "
                  >Number</mat-radio-button
                >
                <mat-radio-button
                  value="percent"
                  [checked]="typeControl.value == 'percent'"
                  class="m20"
                  (change)="
                    this.form.patchValue({ resolution_quorum_type: 'percent' });
                  "
                  >Percentage</mat-radio-button
                >
              </mat-radio-group>
            </span>
            <br /><br /><br />
            <mat-form-field
              style="margin-left: 24px"
              appearance="outline"
              class="field-spacer3"
              *ngIf="typeControl.value == 'number'"
            >
              <input
                matInput
                placeholder="Number"
                type="number"
                min="0"
                [formControl]="valueControl"
              />
            </mat-form-field>
            <span
              style="margin-left: 24px"
              appearance="outline"
              class="field-spacer4 txt"
              *ngIf="typeControl.value == 'percent'"
            >
              <mat-slider
                [formControl]="valueControl"
                style="width: 300px"
                thumbLabel
                [displayWith]="formatLabel"
                tickInterval="100"
                step="1"
                min="1"
                max="100"
              ></mat-slider>
            </span>
            <!-- <span appearance="outline" class="field-spacer1 txt mt-5">
              <mat-slider
                *ngif="
                  this.form.get('resolution_quorum_type').value === 'percent'
                "
                [formControl]="valueControl"
                style="width: 300px"
                thumbLabel
                [displayWith]="formatLabel"
                tickInterval="100"
                step="1"
                min="1"
                max="100"
              ></mat-slider>
              <input
                matInput
                placeholder="Number"
                type="number"
                min="0"
                [formControl]="valueControl"
              />
            </span> -->
          </div>
        </div>
        <div style="clear: both"></div>
        <hr />
        <p>
          <span class="bluelabel"
            >Hybrid &nbsp;
            <mat-slide-toggle
              #hybrid
              (change)="
                form.patchValue({
                  is_hybrid: hybrid.checked,
                  is_voting: false,
                  is_consensus: false,
                  voting_rules: '',
                  use_consensus: ''
                })
              "
              [checked]="form.get('is_hybrid').value"
            ></mat-slide-toggle>
          </span>
          <br /><br />
          <mat-form-field appearance="outline" class="field-spacer1 txt">
            <textarea
              matInput
              rows="5"
              class=""
              placeholder="Description"
              formControlName="hybrid_rules"
            ></textarea>
          </mat-form-field>
        </p>

        <hr />
      </mat-card-content>

      <mat-card-actions>
        <!-- 
          <div class="form-group mr-2">  
            
               <button mat-stroked-button type="button" class="wide" (click)="previous()">PREVIOUS</button>
               <button mat-raised-button color="primary" class="btn btn-primary login-btn wide" type="button" [disabled]="form.invalid" (click)="submit('draft')">Save As Draft</button>

          </div> -->
        <div class="row">
          <div class="col col-md-6" align="left">
            <button
              mat-stroked-button
              type="button"
              class="wide"
              (click)="previous()"
            >
              PREVIOUS
            </button>
            <button
              mat-raised-button
              color="primary"
              class="btn btn-primary login-btn wide"
              type="button"
              [disabled]="form.invalid"
              (click)="submit('draft')"
            >
              Save As Draft
            </button>
          </div>
          <div class="col col-md-6" align="right">
            <button
              mat-raised-button
              color="primary"
              class="btn btn-primary login-btn wide"
              type="submit"
              [disabled]="isFormDisabled"
            >
              Next
            </button>
            <button
              mat-raised-button
              color="clear"
              class="wide"
              [routerLink]="isTemplate ? '/templatemanagement' : '/committee'"
            >
              Cancel
            </button>
          </div>
        </div>
      </mat-card-actions>
    </mat-card>
  </form>
</div>

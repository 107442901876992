import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormBuilder,
  FormArray,
  NgForm,
} from '@angular/forms';
import { UserService } from '@services/UserService';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '../../../shared/services/CoreService';
import { UACService } from '@services/UAC.service';
import { constants } from '@shared/config/constants';
import { language } from '@shared/config/language';
import { PersonalTasksService } from '@services/PesonalTask.service';
import * as moment from 'moment';

@Component({
  selector: 'app-addpersonaltask',
  templateUrl: './addpersonaltask.component.html',
  styleUrls: ['./addpersonaltask.component.css'],
})
export class AddpersonaltaskComponent implements OnInit {
  marriedControl = new FormControl('UnMarried');
  minDate = moment().format('YYYY-MM-DDTHH:mm');
  rolename = '';
  today: Date;

  isEdit: Boolean = false;
  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    user: new FormControl(0),
    title: new FormControl('', Validators.required),
    start_date: new FormControl('', Validators.required),
    end_date: new FormControl('', Validators.required),
    start_time: new FormControl(''),
    end_time: new FormControl(''),
    is_full_day: new FormControl(false),
    priority: new FormControl(''),
    status: new FormControl(''),
  });



  ADVISOR_LIST: any;
  ROLES_LIST: any;
  CONSTANT = constants;
  resource = language;
  labelText: String = 'Add User';

  PRIORITY = constants.task_priority;
  STATUS = constants.task_status;

  constructor(
    private userservice: UserService,
    private router: Router,
    private personalTask: PersonalTasksService,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private fb: FormBuilder
  ) {
    console.log(this.CONSTANT.task_status);
    this.today = new Date();
    this.getAllAdvisors();

    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.labelText = 'Edit Task';

        this.personalTask.getTask(data.id).subscribe((response) => {
          const result = response.body['data'];

          this.form.patchValue({
            _id: result._id,
            user: result.user._id,
            title: result.title,
            start_date: result.start_date ? new Date(result.start_date ).toISOString().slice(0, 16): undefined,
            end_date: result.end_date ? new Date(result.end_date).toISOString().slice(0, 16): undefined,
            is_full_day: result.is_full_day,
            status: result.status,
            priority: result.priority,
          });

          this.isEdit = true;
          console.log(this.form);
        });
      }
    });
  }

  ngOnInit(): void {
    this.form.controls.end_date.valueChanges.subscribe(data => {
      if(moment(this.form.get('start_date').value).startOf('day').format('YYYY-MM-DDTHH:mm') === moment(this.form.get('start_date').value).format('YYYY-MM-DDTHH:mm') && 
      moment(this.form.get('end_date').value).format('YYYY-MM-DDTHH:mm') === moment(this.form.get('start_date').value).add(1, 'day').subtract('1', 'minute').format('YYYY-MM-DDTHH:mm')) {
      this.form.patchValue({
        is_full_day: true
      });
    }
    else {
      this.form.patchValue({
        is_full_day: false
      });
    }
    })
    console.log(this.form)
  }

  changeToggle(event) {
    if(event.checked) {
      const startDate = moment(this.form.get('start_date').value).startOf('day').format('YYYY-MM-DDTHH:mm')
      this.form.patchValue({start_date: startDate,end_date: moment(startDate).add(1, 'day').subtract('1', 'minute').format('YYYY-MM-DDTHH:mm')});
    }
    this.onDateRangeChanged('end')
  }


  getAllAdvisors() {
    this.userservice.getFamilyUses().subscribe(
      (response) => {
        const result = response.body;
        this.ADVISOR_LIST = response.body['data'];
      },
      (err) => {}
    );
  }

  onChange(obj): void {
    this.form.patchValue({
      is_full_day: obj.checked,
    });
    this.changeToggle(obj);
  }

  onDateRangeChanged(type: 'start' | 'end') {
    if (moment(this.form.value.start_date) >= moment(this.form.value.end_date)) {
      if (type == 'start') {
        this.form.controls['end_date'].setErrors({ invalidDate: true });
      } else {
        this.form.controls['end_date'].setErrors({ invalidDate: true });
      }
    }
  }

  submit(): void {
    this.onDateRangeChanged('end')
    console.log(this.form);
    if (this.form.invalid) {
      return;
    }

    var rawuser = this.form.getRawValue();

    if (!this.isEdit) {
      this.form.removeControl('_id');
      rawuser = this.form.getRawValue();
    }
    if(!rawuser.end_date) {
      rawuser.end_date = undefined
      rawuser.end_time = undefined
    }
    else {
      rawuser.end_time = moment(rawuser.end_date).format("hh:mm A");;
    }

    if(!rawuser.start_date) {
      rawuser.start_date = undefined; 
      rawuser.start_time = undefined;
    }
    else {
      rawuser.start_time = moment(rawuser.start_date).format("hh:mm A");
    }

    this.personalTask.addTask(rawuser).subscribe(
      (response) => {
        const result = response.body;

        this.coreservice.success('Task saved');
        this.router.navigate(['personaltask']);
      },
      (err) => {
        console.log(err.error.errors);
        var errormessages = 'Please correct the errors - \n';
        err.error.errors.forEach(
          (element) => (errormessages += ' - ' + element.msg + '\n')
        );

        // err.error.errors.forEach(element =>  this.coreservice.error(element.msg));

        this.coreservice.error(errormessages);
        this.form.addControl('_id', new FormControl(''));
      }
    );
  }

  clearAll() {
    this.form.patchValue({
      user: '',
      title: '',
      start_date: '',
      end_date: '',
      is_full_day: '',
      status: '',
      priority: '',
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';

@Injectable({
  providedIn: 'root',
})
export class IdpService {
  constructor(private http: HttpClient) {}

  dashboardget(filter) {
    return this.http.get(apiendpoints.IDPUSERDASHBOARD + '?' + filter, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Token ' + localStorage.getItem('ACCESS_TOKEN')
      ),
    });
  }

  useridpget(filter) {
    console.log(apiendpoints.IDPUSERLISTING + filter);
    return this.http.get(apiendpoints.IDPUSERLISTING + filter, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Token ' + localStorage.getItem('ACCESS_TOKEN')
      ),
    });
  }
  useridp_delete(id) {
    return this.http.delete(apiendpoints.IDPUSERLISTING + '/' + id, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Token ' + localStorage.getItem('ACCESS_TOKEN')
      ),
    });
  }
  useridppost(data: any) {
    return this.http.post(
      apiendpoints.IDPUSERLISTING,
      { idp: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }

  useridpUpdate(data: any, id: string) {
    return this.http.put(
      apiendpoints.IDPUSERLISTING+"/"+id+'/update',
      { idp: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }

  updateGoalStatus(id: string, status: string) {
    return this.http.put(
      `${apiendpoints.IDPUSERLISTING}/${id}/status/${status}`,{},
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  idpMeetingGet(filter) {
    return this.http.get(apiendpoints.IDPMEETINGS + filter, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Token ' + localStorage.getItem('ACCESS_TOKEN')
      ),
    });
  }

  idptopicpost(data: any) {
    return this.http.post(
      apiendpoints.IDPMEETINGSADD,
      { meeting: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }

  idptopicput(data: any, id: string) {
    return this.http.put(
      apiendpoints.IDPMEETINGSADD + '/' + id,
      { meeting: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }

  idpCategoryCheckGet(filter) {
    return this.http.get(apiendpoints.IDPCATEGORYCHECK + filter, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Token ' + localStorage.getItem('ACCESS_TOKEN')
      ),
    });
  }

  idpagendapost(data: any, id) {
    return this.http.post(
      apiendpoints.IDPMEETINGSADD + '/' + id + '/suggested_agenda',
      data,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  idpnotepost(data: any, id) {
    return this.http.post(
      apiendpoints.IDPMEETINGSADD + '/' + id + '/rationalize',
      { rationalize_notes: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  idpstatuspost(data: any, id) {
    return this.http.post(
      apiendpoints.IDPMEETINGSADD + '/' + id + '/markAttendance',
      { attendance: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  idpcommentpost(id, agendaid, data: any) {
    return this.http.post(
      apiendpoints.IDPMEETINGSADD +
        '/' +
        id +
        '/agendas/' +
        agendaid +
        '/comments',
      { comment: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  idpcancel(id, data) {
    return this.http.put(
      apiendpoints.IDPMEETINGSADD + '/' + id + '/cancel',
      data,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }

  idptodopost(id, agendaid, data: any) {
    return this.http.post(
      apiendpoints.IDPMEETINGSADD +
        '/' +
        id +
        '/agendas/' +
        agendaid +
        '/addResolution?responseType=agenda',
      { tasks: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  idpmompost(data: any, id) {
    return this.http.post(
      apiendpoints.IDPMEETINGSADD + '/' + id + '/addMomComment',
      data,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }

  updateIDPMeetStatus(data: any, id) {
    return this.http.post(
      apiendpoints.IDPMEETINGSADD + '/' + id + '/updateMeetingStatus',
      data,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }

  idpperformaction(id, agendaId, data) {
    return this.http.post(
      apiendpoints.IDPMEETINGSADD +
        '/' +
        id +
        '/suggested_agenda/' +
        agendaId +
        '/performAction',
      data,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  removeCategory(id: string) {
    return this.http.delete(apiendpoints.COMMITTEECATEGORIES + '/' + id, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }
  idpaddremark(id, data) {
    return this.http.put(
      apiendpoints.IDPREMARK + '/' + id + '/remarks',
      { remark: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
}

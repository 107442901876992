<div class="content p30"> 
    <div id="block_container">
           <a  routerLink="/commonspace" id="bloc1" mat-flat-button class="mat-flat-button-transperent" >
            <span  class="material-icons">keyboard_backspace</span></a> 
           <div id="bloc2"><h5>{{resource.message('COMMONSPACE')}} / {{resource.message('ADDBOOKING')}}</h5></div>
    </div>
    <mat-card>
        <mat-card-content>
        <full-calendar [options]="calendarOptions" dateClick="dateClick($event)"
      ></full-calendar>
    </mat-card-content>
</mat-card>

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { language } from '@shared/config/language';
import { DialogmeetingparticipantsComponent } from '@shared/components/dialogmeetingparticipants/dialogmeetingparticipants.component';
import {
  MatDialog,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialoginternalparticipantsComponent } from '@shared/components/dialoginternalparticipants/dialoginternalparticipants.component';
import { CoreService } from '@shared/services/CoreService';
import { IdpService } from '@services/Idp.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
export interface GoalElement {
  development: number;
  category: string;
  date: string;
  status: string;
}
@Component({
  selector: 'app-createmeeting',
  templateUrl: './createmeeting.component.html',
  styleUrls: ['./createmeeting.component.css'],
})
export class CreatemeetingComponent implements OnInit {
  labelText: string = 'Add Goal';
  resource = language;
  // allowlogs:boolean=false;
  ELEMENT_DATA: GoalElement[];
  today: Date;
  minDate = moment().format('YYYY-MM-DDTHH:mm');
  rowData: any;
  displayedColumns: string[] = [
    'development',
    'category',
    'date',
    'status',
    'Action2',
  ];
  listData = new MatTableDataSource<GoalElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<GoalElement>(true, []);
  localstoragedata: any;
  form: FormGroup = new FormGroup(
    {
      _id: new FormControl(''),
      title: new FormControl('', Validators.required),
      venue: new FormControl('', Validators.required),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', Validators.required),
      start_time: new FormControl(''),
      end_time: new FormControl(''),
      allowlogs: new FormControl(false),
      other_topics: new FormControl(''),
      created_for: new FormControl(''),
      agendas: new FormControl(''),
      participant: new FormControl('', Validators.required),
      type: new FormControl(''),
      members: new FormControl('', Validators.required),
      temporary_members: new FormControl(''),
    },
    
  );
  @ViewChild('goalTable') table: MatTable<GoalElement>;
  parentid: string;
  allmember: any = [];
  isEdit: Boolean = false;
  subject: string;
  mailbody: string;
  attachment: string;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private coreservice: CoreService,
    private idp: IdpService,
    private activeRoute: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.today = new Date();
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentid = data.id;
        const othettopic = [{ idp: data.top }];
        if (data.a) {
          
          this.isEdit = false;
          console.log(JSON.parse(data.a));

          let parsedData = JSON.parse(data.a);
          this.getGoalCreator(parsedData)
          let agenda = [];
          for (let i = 0; i < parsedData.length; i++) {
            agenda.push({
              idp: parsedData[i],
              agenda_passed: null,
              votes_overridden: null,
              status: 'created',
              owner_type: 'super',
              comments: [],
              votes: [],
              tasks: [],
            });
          }
          this.form.patchValue({
            other_topics: othettopic,
            created_for: this.parentid,
            agendas: agenda,
          });
        } else {
          this.isEdit = true;
          var filter = '?filter[_id]=' + data.id; //+this.localstoragedata._id
          this.idp.idpMeetingGet(filter).subscribe(
            (response: any) => {
              console.log(':::::::', response);
              let names = response.data[0].members.map(val => val.name).join(', ');
              const result = response.data[0];
              console.log(names)
              this.form.patchValue({
                title: response.data[0].title,
                venue: response.data[0].venue,
                start_date: result.start_date ? new Date(result.start_date ).toISOString().slice(0, 16): undefined,
                end_date: result.end_date ? new Date(result.end_date).toISOString().slice(0, 16): undefined,
                members: response.data[0].members,
                participant: names,
              });
             this.allmember = response.data[0].temporary_members.members;
              console.log(response.data[0].temporary_members.members)
            },
            (err) => {
              console.log('errrrrrrr', err);
            }
          );
        }
      }
    });
  }

  getGoalCreator(id) {
    var filter = '';

    filter = '?filter[_id]=' + id; //+this.localstoragedata._id
    this.idp.useridpget(filter).subscribe(
      (response: any) => {
        let result = response.data[0].created_by;
        result.id = result._id;
        const name= result.name;
        console.log(result)
        this.form.patchValue({
          members: [result],
          participant: name,
        });
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  onChange(event): void {
    this.openDialog('', '', '');
  }
  onDateRangeChanged(type: 'start' | 'end') {
    if (moment(this.form.value.start_date) >= moment(this.form.value.end_date)) {
      if (type == 'start') {
        this.form.controls['end_date'].setErrors({ invalidDate: true });
      } else {
        this.form.controls['end_date'].setErrors({ invalidDate: true });
      }
    }
  }
  openDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogmeetingparticipantsComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('>><<<<>>>>', result);
      this.allmember = [...this.allmember, ...result.users];
      this.subject = result.mail.subject;
      this.mailbody = result.mail.message;
      this.attachment = result.mail.file_url;
    });
  }
  addparticipant() {
    this.openParticipantsDialog('', '', '');
  }
  timeValidator(controlName1: string, controlName2: string) {
    return (formGroup: FormGroup) => {
      const start_date = formGroup.controls['start_date'];
      const end_date = formGroup.controls['end_date'];

      const start_date_time = new Date(
        this.datePipe.transform(start_date.value) 
      );
      const end_date_time = new Date(
        this.datePipe.transform(end_date.value) 
      );

      if (
        start_date_time >= end_date_time &&
        formGroup.controls['is_full_day'].value
      ) {
        end_date.setErrors({ endTimeGreaterThanStartTime: true });
      }
      return null;
    };
  }
  openParticipantsDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialoginternalparticipantsComponent, {
      width: '50%',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        members: this.form.value.members,
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      let participantList = '';
      let member = [];
      for (let i = 0; i < result.data.length; i++) {
        member.push({
          invite_status: 'pending',
          id: result.data[i].user_id,
          mobile: result.data[i].mobile,
          name: result.data[i].name + ' ' + result.data[i].lastname,
          role: result.data[i].role,
          committee_role: result.data[i].role,
          profile_photo: result.data[i].profile_photo,
        });
        console.log(result.data[i]);
        participantList += result.data[i].name + ',';
      }
      member[0].committee_role = 'chairman';
      //console.log('RESULT::::', member);
      this.form.patchValue({
        participant: member.map(val => val.name).join(', '),
        members: member,
      });
    });
  }
  onSubmit() {
    console.log(this.form)
    this.onDateRangeChanged('end')
    if (this.form.invalid) {
      const req = this.form.getRawValue();
      return;
    }
    // if (this.isEdit === false) {
      this.form.removeControl('_id');

      const req = this.form.getRawValue();
      if(!req.end_date) {
        req.end_date = undefined
        req.end_time = undefined
      }
      else {
        req.end_time = req.end_date.split('T')[1];
      }

      if(!req.start_date) {
        req.start_date = undefined; 
        req.start_time = undefined;
      }
      else {
        req.start_time = req.start_date.split('T')[1];
      }
      req['type'] = 'idp';
      req['temporary_members'] = {
        subject: this.subject,
        mailbody: this.mailbody,
        attachment: this.attachment,
        members: this.allmember,
      };
      
      console.log(this.form.get('start_date').value);
    if (this.isEdit === false) {
      console.log('Invitee', req);
      this.idp.idptopicpost(req).subscribe(
        (response: any) => {
          this.coreservice.success(this.resource.message('INVITE'));
          console.log(response.data._id);

          this.router.navigate(['/agenda'], {
            queryParams: {
              id: response.data._id,
            },
          });
        },
        (err) => {
          console.log('errrrrrrr', err);
        }
      );
    } else {
      delete req.other_topics;
      delete req.agendas;
      delete req.created_for;
      delete req.allowlogs;
      this.idp.idptopicput(req, this.parentid).subscribe(
        (response: any) => {
          //this.coreservice.success(this.resource.message('INVITE'));
          console.log(response.data._id);

          this.router.navigate(['/agenda'], {
            queryParams: {
              id: this.parentid,
            },
          });
        },
        (err) => {
          console.log('errrrrrrr', err);
        }
      );
      
    }
    //this.coreservice.success(this.resource.message('INVITE'));
    //this.router.navigate(['/agenda']);
  }

  getGoals(id) {
    var filter = '';

    filter = '?filter[status]=in_progress&filter[user]=' + id; //+this.localstoragedata._id
    this.idp.useridpget(filter).subscribe(
      (response: any) => {
        const result = response;
        console.log('>>>>>>>>>>>>>>', response.data);
        this.listData.data = response.data as GoalElement[];
        this.table.renderRows();
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  delete_participant(row, index) {
    this.allmember.splice(index, 1);
    console.log(this.allmember);
  }
}

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { PersonalTasksService } from '@services/PesonalTask.service';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import { CoreService } from '@services/CoreService';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import { AuthService } from '@services/Auth.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DatePipe } from '@angular/common';

export interface TaskElement {
  taskId: number;
  user: any;
  start: string;
  end: string;
  status: string;
  title: string;
}

@Component({
  selector: 'app-personaltask',
  templateUrl: './personaltask.component.html',
  styleUrls: ['./personaltask.component.css'],
  providers: [DatePipe],
})
export class PersonaltaskComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;

  ELEMENT_DATA: TaskElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = [
    'username',
    'title',
    'start_date',
    'end_date',
    'status',
    'Action',
  ];
  listData = new MatTableDataSource<TaskElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<TaskElement>(true, []);

  PERMISSION_EDIT = AuthService.authentication('Personal Task', 'READ');
  PERMISSION_ADD = AuthService.authentication('Personal Task', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Personal Task', 'DELETE');

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<TaskElement>;

  resource = language;

  constructor(
    private taskService: PersonalTasksService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private http: HttpClient,
    private coreservice: CoreService,
    private datePipe: DatePipe
  ) {
    AuthService.authenticate_redir('Personal Task', ['READ']);

    this.listData = new MatTableDataSource<TaskElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<TaskElement>(true, []);

    console.log(language.message('a'));
    AuthService.authenticate_redir('Users', ['READ']);
  }

  ngOnInit(): void {
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllTasks();
    this.table.renderRows();
  }

  getAllTasks() {
    this.taskService.getTasks().subscribe(
      (response) => {
        const result = response.body['data'].map(element => ({...element, username: element.user.name +
          " " +
          element.user.middlename +
          " " +
          element.user.lastname}))
          console.log(result)
        this.listData.data = result as TaskElement[];

        this.table.renderRows();
      },
      (err) => {
        this.getAllTasks();
      }
    );
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listData.data.forEach((row) => this.selection.select(row));
  }

  addrole() {
    this.router.navigate(['/personaltask/add']);
  }

  edit(role) {
    this.router.navigate(['personaltask/add'], {
      queryParams: { id: role._id },
    });
  }

  onDelete(id) {
    this.coreservice
      .openConfirmDialog(this.resource.message('PERSONAL_TASKS_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.taskService.removeTask(id).subscribe(
            (response) => {
              const result = response;
              this.getAllTasks();
              this.coreservice.success(
                this.resource.message('PERSONAL_TASKS_DELETED')
              );
            },
            (err) => {}
          );
        }
      });
  }

  closeMyMenu() {
    this.trigger.closeMenu();
  }

  filterData(ev, col, val) {
    if (ev.checked) {
      col = String(col);

      let value = { [col]: val };
      this.filterValues.push(value);
    } else {
      let value = { [col]: val };
      const index = this.filterValues.indexOf(value);
      this.filterValues.splice(index - 1, 1);
    }

    this.setupFilter(col);
    this.applyFilter(val);
  }

  stoppro($event) {
    $event.stopPropagation();
  }

  setupFilter(column: string) {
    this.listData.filterPredicate = (d: any, filter: string) => {
      const val = JSON.parse(filter);
      var flag = false;

      val.forEach(function (item) {
        let obj = item;
        const textToSearch = (d[column] && d[column].toLowerCase()) || '';
        if (obj[column] != undefined) {
          if (
            textToSearch.toLowerCase().indexOf(obj[column].toLowerCase()) !== -1
          ) {
            flag = true;
          }
        }
      });
      return flag;
    };
  }

  applyFilter(filterValue: string) {
    this.listData.filter = JSON.stringify(this.filterValues); // filterValue.trim().toLowerCase();
  }

  filterValues = [];
  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [
      ['Profile Name', 'Description', 'Start Date', 'End Date', 'Status'],
    ];
    this.listData.data.forEach((obj) => {
      let name = obj['user']['name'] ? obj['user']['name'] : '';
      let title = obj['title'] ? obj['title'] : '';
      let start_date = obj['start_date']
        ? this.datePipe.transform(obj['start_date'], 'M/dd/YYYY')
        : '';
      let end_date = obj['end_date']
        ? this.datePipe.transform(obj['end_date'], 'M/dd/YYYY')
        : '';
      let status = obj['status'] ? obj['status'] : '';

      let arr = [name, title, start_date, end_date, status];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      rowPageBreak: 'avoid',
    });

    if (action === 'save') {
      doc.save('personal-tasks.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      doc.output('dataurlnewwindow', { filename: 'personal-tasks.pdf' });
    }
  }
}

import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { apiendpoints } from '../config/apiendpoints';


@Injectable({
    providedIn:'root'
})

export class FamilyOfficeService {

    constructor(private http: HttpClient){


    }

    ticketget(id){
        return this.http.get(apiendpoints.TICKET+'/'+id, {
            headers: new HttpHeaders()
            .set('Authorization', 'Token ' +localStorage.getItem('ACCESS_TOKEN')),
        });
    }
    allticketget()
    {
        return this.http.get(apiendpoints.ADMINTICKET, {
            headers: new HttpHeaders()
            .set('Authorization', 'Token ' +localStorage.getItem('ACCESS_TOKEN')),
        });
    }
    ticketput(data:any){
        return this.http.put(apiendpoints.TICKET+'/'+data._id,{request:data} ,{
            headers: new HttpHeaders()
            .set('Authorization', 'Token ' +localStorage.getItem('ACCESS_TOKEN')),
        });
    }
    assignedticket(){
        return this.http.get(apiendpoints.ASSIGNEDTICKET, {
            headers: new HttpHeaders()
            .set('Authorization', 'Token ' +localStorage.getItem('ACCESS_TOKEN')),
        });
    }
}




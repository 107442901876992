<div class="content p30">
  <div id="block_container">
    <a
      routerLink="/agenda"
      [queryParams]="{ id: parentid }"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >
    <div id="bloc2">
      <h5>{{ resource.message("IDP") }} / Create Meeting</h5>
    </div>
  </div>
  <form [formGroup]="form">
    <fieldset>
      <!--[disabled]=" (familyname!=select_user_family) ? isDisabled :''"-->
      <mat-card class="">
        <mat-card-content>
          <span class="devarea">Agenda</span>
          <br />
          <hr />
          <div class="row cancel">
            <button mat-stroked-button (click)="cancelMeeting()">
              Cancel Meeting
            </button>
          </div>
          <br />
          <mat-grid-list cols="2" rowHeight="100px">
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Category</mat-label>
                <input
                  matInput
                  placeholder="Category"
                  formControlName="category"
                  readonly
                />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile
              ><mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Goal</mat-label>
                <input
                  matInput
                  placeholder="Goal"
                  formControlName="goal"
                  readonly
                />
              </mat-form-field>
            </mat-grid-tile>
            <!-- <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Goal</mat-label>
                <input matInput placeholder="Goal" formControlName="goal" />
              </mat-form-field>
            </mat-grid-tile> -->
            <!-- <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Goal</mat-label>
                <input matInput placeholder="Goal" formControlName="goal" />
              </mat-form-field>
            </mat-grid-tile> -->
          </mat-grid-list>
          <br />
          <br />
          <span class="devarea">Other discussion topics</span>
          <hr />
          <div class="row">
            <div class="col-md-4" *ngFor="let topic of topics">
              <mat-card class="topic-card">{{ topic }}</mat-card>
            </div>
          </div>

          <br />
          <br />
          <span class="devarea">Suggested Agenda</span>
          <hr />
          <div class="row" *ngIf="addAgenda === 'true'">
            <app-newagenda
              (newItemEvent)="getagenda($event)"
              [uid]="parentid"
              class="agendacss"
            ></app-newagenda>
          </div>
          <div *ngIf="addAgenda === 'false'">
            <app-suggestedagenda> </app-suggestedagenda>
          </div>

          <div *ngIf="nextbtn">
            <button
              mat-raised-button
              color="primary"
              class="btn btn-primary login-btn button-wider"
              type="button"
              (click)="showNote()"
            >
              Note
            </button>
          </div>
        </mat-card-content>
        <span class="blankline"></span>
        <div class="form-group mr-2">
          <button
            mat-stroked-button
            type="button"
            class="wide"
            routerLink="/agenda"
            [queryParams]="{ id: parentid }"
          >
            PREVIOUS
          </button>
        </div>
        <mat-card-actions align="end">
          <button
            *ngIf="!nextbtn"
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="button"
            (click)="addNote()"
          >
            Rationalize Agenda
          </button>
          <button
            *ngIf="nextbtn"
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="button"
            (click)="take_attendence()"
          >
            Next
          </button>
        </mat-card-actions>
      </mat-card>
    </fieldset>
  </form>
</div>

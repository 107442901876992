import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { language } from '@shared/config/language';
import { FileService } from '@services/File.service';
import { UserService } from '@services/UserService';
import { CoreService } from '../../../shared/services/CoreService';
import { OtherMeetingService } from '@services/OtherMeeting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
export interface UserElement {
  name: number;
  attendence: string;
}
@Component({
  selector: 'app-addothermeetings',
  templateUrl: './addothermeetings.component.html',
  styleUrls: ['./addothermeetings.component.css'],
})
export class AddothermeetingsComponent implements OnInit {
  notice = new FormControl('single');
  REQUESTED_BY: any;
  PARTICIPANTS: any;
  isSubmit = false;
  today = moment().format('YYYY-MM-DDTHH:mm');
  minDate = moment().format('YYYY-MM-DDTHH:mm');
  labelText: String = 'Create Meeting';
  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    title: new FormControl('', Validators.required),
    venue: new FormControl('', Validators.required),
    requested_by: new FormControl('', Validators.required),
    agenda: new FormControl('', Validators.required),
    participants: new FormControl('', Validators.required),
    start_date: new FormControl('', Validators.required),
    end_date: new FormControl('', Validators.required),
    start_time: new FormControl(''),
    end_time: new FormControl(''),
    file: new FormControl(''),
    minutespdf: new FormControl(''),
  },this.timeValidator('start_time', 'end_time'));
  isEdit: Boolean = false;
  uploadMinutes: Boolean = false;
  resource = language;
  selFile = new Object();
  parentId: string;
  selFileName = '';
  constructor(
    private fileService: FileService,
    private userservice: UserService,
    private coreservice: CoreService,
    private router: Router,
    private othermeetinginfoservice: OtherMeetingService,
    private activeRoute: ActivatedRoute,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getAllUsers();

    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.labelText = 'Edit Task';
        var filter = '?filter[_id]=' + data.id;
        this.parentId = data.id;
        this.othermeetinginfoservice
          .getOtherMeetings(filter)
          .subscribe((response) => {
            const result = response.body['data'];
            const requested = result[0].requested_by;
            //  const participants = result[0].participants;
            console.log(result);
            let allparticipants = [];
            for (let i = 0; i < result[0].participants.length; i++) {
              allparticipants.push(result[0].participants[i].user._id);
              console.log(
                'praticipantarr:',
                result[0].participants[i].user._id
              );
            }
            this.uploadMinutes = result[0].file !== '';
            this.form.patchValue({
              _id: result[0]._id,
              title: result[0].title,
              venue: result[0].venue,
              requested_by: requested._id,
              agenda: result[0].agenda,
              start_time: result[0].start_time,
              end_time: result[0].end_time,
              start_date: result[0].start_date ? new Date(result[0].start_date ).toISOString().slice(0, 16): undefined,
              end_date: result[0].end_date ? new Date(result[0].end_date).toISOString().slice(0, 16): undefined,
              file: result[0].minutes_url,
              minutespdf: result[0].minutes_url,
              participants: allparticipants,
            });

            this.isEdit = true;
            console.log(this.form);
          });
      }
    });
  }

  changeToggle(event) {
    if(event.checked) {
      const startDate = moment(this.form.get('start_date').value).startOf('day').format('YYYY-MM-DDTHH:mm')
      this.form.patchValue({start_date: startDate,end_date: moment(startDate).add(1, 'day').subtract('1', 'minute').format('YYYY-MM-DDTHH:mm')});
    }
    this.onDateRangeChanged('end')
  }

  timeValidator(controlName1: string, controlName2: string) {
    return (formGroup: FormGroup) => {
      const start_date = formGroup.controls['start_date'];
      const end_date = formGroup.controls['end_date'];

      const start_date_time = new Date(
        this.datePipe.transform(start_date.value) 
      );
      const end_date_time = new Date(
        this.datePipe.transform(end_date.value) 
      );
      console.log('start_date_time ', start_date_time);
      console.log(
        'start_date_time > end_date_time',
        start_date_time > end_date_time
      );

      if (
        start_date_time > end_date_time
      ) {
        end_date.setErrors({ endTimeGreaterThanStartTime: true });
      } else {
        end_date.setErrors(null);
      }
      return null;
    };
  }

  onDateRangeChanged(type: 'start' | 'end') {
    console.log(Date.parse(this.form.value.start_date) > Date.parse(this.form.value.end_date))
    if (Date.parse(this.form.value.start_date) >= Date.parse(this.form.value.end_date)) {
      if (type == 'start') {
        this.form.controls['start_date'].setErrors({ invalidDate: true });
        this.form.controls['end_date'].setErrors(null);
      } else {
        this.form.controls['end_date'].setErrors({ invalidDate: true });
        this.form.controls['start_date'].setErrors(null);
      }
    }
  }

  async submit(): Promise<void> {
    this.onDateRangeChanged('start');
    this.onDateRangeChanged('end');
    console.log(this.form)
    if (this.form.invalid) {
      return;
    }
    this.isSubmit = true;
    var req = this.form.getRawValue();
    if(!req.end_date) {
      req.end_date = undefined
      req.end_time = undefined
    }
    else {
      req.end_time = req.end_date.split('T')[1];
    }

    if(!req.start_date) {
      req.start_date = undefined; 
      req.start_time = undefined;
    }
    else {
      req.start_time = req.start_date.split('T')[1];
    }
    if (this.selFileName != '') {
      let fileurl = this.selFileName;
      var formData: any = new FormData();
      formData.append('file', this.selFile);
      fileurl = await this.fileService.uploadFile(formData, true, false);
      req['minutes_url'] = fileurl;
    }
    let praticipantarr = [];
    for (let i = 0; i < req['participants'].length; i++) {
      praticipantarr.push({ isPresent: false, user: req['participants'][i] });
      console.log('praticipantarr:', praticipantarr);
    }
    req['participants'] = praticipantarr;
    delete req['file'];

    if (!this.isEdit) {
      delete req['_id'];
      this.form.removeControl('_id');
      this.form.removeControl('status');
    }

    if (!this.isEdit) {
      this.othermeetinginfoservice.postOtherMeetings(req).subscribe(
        (response) => {
          const result = response.body;
          if (!this.isEdit) {
            this.coreservice.success('Meeting saved successfully');
          } else {
            this.coreservice.success('Meeting updated successfully');
          }
          this.form.addControl('_id', new FormControl(''));
          this.router.navigate(['othermeeting']);
        },
        (err) => {
          var errormessages = 'Please correct the errors - \n';
          err.error.errors.forEach(
            (element) => (errormessages += ',' + element.msg + '\n')
          );
          this.coreservice.error(errormessages);
          this.isSubmit = false
        }
      );
    } else {
      this.othermeetinginfoservice
        .updateOtherMeetings(req, this.parentId)
        .subscribe(
          (response) => {
            const result = response.body;
            if (!this.isEdit) {
              this.coreservice.success('Meeting saved successfully');
            } else {
              this.coreservice.success('Meeting updated successfully');
            }
            this.form.addControl('_id', new FormControl(''));
            this.router.navigate(['othermeeting']);
          },
          (err) => {
            var errormessages = 'Please correct the errors - \n';
            err.error.errors.forEach(
              (element) => (errormessages += ',' + element.msg + '\n')
            );
            this.coreservice.error(errormessages);
            this.isSubmit = false
          }
        );
    }
  }
  onFileChange(event) {
    if (this.uploadMinutes) {
      this.selFile = (event.target as HTMLInputElement).files[0];
      this.selFileName = (event.target as HTMLInputElement).files[0].name;
    }
  }
  previewimg() {
    console.log(this.form.value);
    window.open(this.form.get('file').value, '_blank');
  }
  getAllUsers() {
    var filter = '';
    this.othermeetinginfoservice.getFamilyUsers(filter).subscribe(
      (response) => {
        this.REQUESTED_BY = response.body['data'];
        this.PARTICIPANTS = response.body['data'];
      },
      (err) => {
        if (err.error.status != null) {
        } else {
        }
      }
    );
  }
}

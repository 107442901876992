import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
} from '@angular/forms';
import { UACService } from '@services/UAC.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '@shared/services/CoreService';
import { language } from '@shared/config/language';
import { SettingsService } from '@services/Settings.service';
import { constants } from '@shared/config/constants';
import {UserService} from '@services/UserService';
import {LevelsComponent} from '@modules/achievements/levels/levels.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  SETTINGS_LIST: any;

  resource = language;
  localstoragedata: any;
  logged_role: string;

  allowlogs: boolean;
  allowmsg: boolean;
  form: FormGroup = new FormGroup({
    _id: new FormControl(''),
    logs: new FormControl(''),
    name: new FormControl(''),
    middlename: new FormControl(''),
    lastname: new FormControl(''),
    mobile: new FormControl(''),
    role: new FormControl('')

  });
  constructor(
    private uacservice: UACService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private settingsService: SettingsService,
    private userservice: UserService,
  ) {
    this.getSettings();
  }

  ngOnInit(): void {
    this.localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    this.logged_role = this.localstoragedata.role;
    this.allowlogs = this.localstoragedata.logs;
    this.allowmsg = this.localstoragedata.msgpermission;
    this.form.patchValue({
      _id: this.localstoragedata._id,
      logs: this.localstoragedata.logs,
      name: this.localstoragedata.name,
      middlename: this.localstoragedata.middlename,
      lastname: this.localstoragedata.lastname,
      mobile: this.localstoragedata.mobile,
      role: this.localstoragedata.role,
      msgpermission: this.localstoragedata.msgpermission
    });
  }

  updatevalue(id, key, value) {
    this.settingsService
      .addSettings({
        _id: id,
        key: key,
        values: value,
      })
      .subscribe(
        (response) => {
          this.coreservice.success('Setting saved');
        },
        (err) => {}
      );
  }

  public getSettings() {
    this.settingsService.getSettings().subscribe(
      (response) => {
        const result = response.body;
        this.SETTINGS_LIST = response.body['data'];
      },
      (err) => {}
    );
  }
  onChange(event): void {

    var rawuser = this.form.getRawValue();
    rawuser['logs'] = event.checked;
    this.userservice.addUser(rawuser).subscribe(response => {
      const result = response.body;
      //   this.coreservice.success(this.resource.message("USER_UPDATE"));
    }, (err) => {
      var errormessages = 'Please correct the errors - \n';
      err.error.errors.forEach(element => errormessages += ',' + element.msg + '\n');
      // this.coreservice.error(errormessages);
    });

  }

  onPermissionChange(event): void {
    var rawuser = this.form.getRawValue();
    rawuser['msgpermission'] = event.checked;
    this.userservice.addUser(rawuser).subscribe(response => {
      const result = response.body;
      //   this.coreservice.success(this.resource.message("USER_UPDATE"));
    }, (err) => {
      var errormessages = 'Please correct the errors - \n';
      err.error.errors.forEach(element => errormessages += ',' + element.msg + '\n');
      // this.coreservice.error(errormessages);
    });
  }
}

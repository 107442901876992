import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {}

  login(data: any) {
    console.log(data);
    return this.http.post(
      apiendpoints.AUTH_LOGIN,
      { user: data },
      { observe: 'response' }
    );
  }

  localsave(user: any) {
    localStorage.setItem('ACCESS_TOKEN', user.user.token);
    localStorage.setItem('LOGGED_USER', JSON.stringify(user.user));
  }

  logout() {
    localStorage.clear();
  }
  isLoggedIn(): boolean {
    return JSON.parse(localStorage.getItem('LOGGED_USER')) !== null;
  }

  public static authentication(module, permissionkey) {
    let rolesstorage = JSON.parse(localStorage.getItem('LOGGED_USER'));
    let roleId = rolesstorage.role_uac._id;
    let permissionmetrics = JSON.parse(localStorage.getItem('ALLOWED_MODULES'));
    var isAllowed = false;
    if (permissionmetrics) {
      for (var i = 0; i < permissionmetrics.length; i++) {
        if (
          permissionmetrics[i]['role']['_id'] == roleId &&
          permissionmetrics[i]['module']['module'] == module &&
          permissionmetrics[i]['permission']['key'] == permissionkey
        ) {
          isAllowed = true;
        }
      }
    }

    return isAllowed;
  }

  public static authenticate_redir(module, permissionkey) {
    var isValid = false;
    if (Array.isArray(permissionkey)) {
      for (var i = 0; i < permissionkey.length; i++) {
        if (AuthService.authentication(module, permissionkey[i]))
          isValid = true;
      }
    } else {
      isValid = AuthService.authentication(module, permissionkey);
    }
    if (!isValid) {
      //       window.location.assign("/dashboard");
    }
  }
}

<!--<mat-drawer #drawer mode="side" style="background-color:#095294; color: #ffffff;"></mat-drawer>
--><button (click)="showFiller = !showFiller" mat-raised-button>
   
  </button>
  <div>
    <img [src]="photo!=null? photo : './assets/images/admin.png'" class="img-admin" height="100px" width="100px" >
   
   </div>
   <div style="padding: 10px;">
    <h5 class="admin-name">{{firstname}} {{lastname}}</h5>
    <span><i>{{rolename}}</i></span><br>

  
    <ul > 
      <li class="fullwidth" *ngFor="let modulename of moduleList; index as i;">
        <a routerLink="/{{modulename.route}}" class="sidebarpadding" *ngIf="modulename.isMenu" tabindex="{{i}}">
          <i [ngClass]="modulename.icon"></i> 
          <span class=" spanpadding">{{modulename.module}}</span></a>
      </li>
     </ul>

   <button type="button" mat-stroked-button class="btn btn-primary logout-btn fullwidth" (click)="logout()"> Logout </button>
  
   </div>

import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
export interface Tile {
  color: string;
  cols: number;
  rows: number;
  levelname: string;
  position: string;
  points: string;
}
@Component({
  selector: 'app-levels',
  templateUrl: './levels.component.html',
  styleUrls: ['./levels.component.css'],
})
export class LevelsComponent implements OnInit {
  tiles: Tile[] = [
    {
      levelname: 'Novoice',
      cols: 1,
      rows: 1,
      color: '#EEEEEE',
      position: 'Level 1',
      points: '00-09 points',
    },
    {
      levelname:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
      cols: 1,
      rows: 4,
      color: '#EEEEEE',
      position: '',
      points: '',
    },
    {
      levelname: 'Rising Star',
      cols: 1,
      rows: 1,
      color: '#EEEEEE',
      position: 'Level 2',
      points: '10-39 points',
    },
    {
      levelname: 'All Star',
      cols: 1,
      rows: 1,
      color: '#EEEEEE',
      position: 'Level 3',
      points: '40-59 points',
    },
    {
      levelname: 'Super Star',
      cols: 1,
      rows: 1,
      color: '#EEEEEE',
      position: 'Level 4',
      points: '60-79 points',
    },
    {
      levelname: 'Mega Star',
      cols: 1,
      rows: 1,
      color: '#EEEEEE',
      position: 'Level 5',
      points: '80-100 points',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [['Rank Name', 'Level', 'Points']];

    // let arr = [name + middlename + lastname, mobile_no, email, role];
    data.push(['Novice', 'Level 1', '00 - 09    Points']);
    data.push(['Rising Star', 'Level 2', '10 - 39    Points']);
    data.push(['All Star', 'Level 3', '40 - 59    Points']);
    data.push(['Super Star', 'Level 4', '60 - 79    Points']);
    data.push(['Mega Star', 'Level 5', '80 - 100    Points']);

    autoTable(doc, {
      head: head,
      body: data,
      rowPageBreak: 'avoid',
    });
    if (action === 'save') {
      doc.save('levels.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      // window.open(doc.output('bloburl') as unknown as string, '_blank');
      doc.output('dataurlnewwindow', { filename: 'family-info.pdf' });
    }
  }
}

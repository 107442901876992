  <mat-toolbar class="custom-toolbar">
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon (click)="this.parent.toggle()">menu</mat-icon>
    </button>
    <a routerLink='/dashboard' class="img-a"> <img [src]="logo!=''? logo : './assets/images/familybook_header_logo.png'" class="img-fluid" ></a>
      <span *ngIf="familyname!=CORE_FAMILY">{{familyname}}</span>
      <span class="example-spacer"></span>
    <!--  <a mat-stroked-button [matMenuTriggerFor]="menu" class="custom-toolbar-item" *ngIf="logged_role=='admin'">
        <mat-icon  class="custom-toolbar-item-icon">vpn_key</mat-icon> Grant Permission
      </a>
      <mat-menu #menu="matMenu" class="menustyle">
        <div class="menuitemstyle">
          <div>
            <p style="font-size: 20px;">Log In Info   <mat-slide-toggle style="float: right;" (change)="onChange($event)" [(ngModel)]="allowlogs"></mat-slide-toggle> </p>
            <p>
              Allow access to Super admin to view the log in details.
            </p>
            <p>
              The access can be denied if you decide not to provide access to the Super admin to view the details.
            </p>
          </div>
          <hr/>
          <div>
            <p style="font-size: 20px;">Send Messages<mat-slide-toggle style="float: right;" (change)="onPermissionChange($event)" [(ngModel)]="allowmsg"></mat-slide-toggle> </p>
            <p>
                Allow access to Super admin to view to send messages directly to the Family office team / Family members directly.
             </p>
          </div>
        </div>
      </mat-menu>-->
<!--      <a mat-button [routerLink]="'/create-account'" class="custom-toolbar-item"> <mat-icon  class="custom-toolbar-item-icon">supervised_user_circle</mat-icon> Family Office Team </a>-->
  <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon" (click)="openDialog('','','')">
    <mat-icon class="custom-toolbar-item-icon">notifications</mat-icon>
  </button>
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
   <mat-icon [matMenuTriggerFor]="settingmenu" class="custom-toolbar-item-icon">settings</mat-icon>
 </button>
 <mat-menu #settingmenu="matMenu" class="menustyle">
    <div class="menuitemstyle">
      <div>
        <button mat-menu-item (click)="redirectTo('changepassword')">
          <span>Change Password</span>
        </button>
      </div>
<!--      <hr/>-->
     <!-- <div>
        <button mat-menu-item>
          <span>My Activity Logs</span>
        </button>
      </div>-->
    </div>
    </mat-menu>
</mat-toolbar>






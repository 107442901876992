import { Component, OnInit } from '@angular/core';
import { ActivatedRoute ,Router} from '@angular/router';
import { language } from '@shared/config/language';

@Component({
  selector: 'app-commonspacetabs',
  templateUrl: './commonspacetabs.component.html',
  styleUrls: ['./commonspacetabs.component.css']
})
export class CommonspacetabsComponent implements OnInit {

  
  resource = language;
  activePath = 0;
 
  constructor(private route: ActivatedRoute,    private router : Router,
    ) {

    route.url.subscribe(() => {
      console.log("CURRENT CHILD")
      console.log(route.snapshot.firstChild);
      if(route.snapshot.firstChild!=null)
        {
          let currentTab = (route.snapshot.firstChild.url[0]["path"]);
          if(currentTab == 'category') this.activePath = 0;
          if(currentTab == 'commonspaces') this.activePath =1 ;
          if(currentTab == 'booking') this.activePath =2 ; 
        }
      });
   }

  ngOnInit(): void {
  }
  changePath(val){
    if(val.index=='2')
    {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['booking'], {relativeTo:this.route});
  
    }
    else if(val.index=='1')
    {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['commonspaces'], {relativeTo:this.route});
  
    }
    else
    {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['category'], {relativeTo:this.route});
  
    }
    console.log("IN the click event",val.index)
  }
}

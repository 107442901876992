<div class="content p30">

    <h5>{{resource.message('ACHIEVEMENTS')}}</h5>
    <mat-card class="">
      <mat-card-header >
        <mat-card-title > {{resource.message('ACHIEVEMENTS')}} </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-tab-group class="tabgroup">


            <!--Activity Log-->
            <mat-tab label="Activity Log" class="tabcolor">
               <app-activitylogs></app-activitylogs>
            </mat-tab>

          <!--Levels-->
         <!-- <mat-tab label="Levels" class="tabcolor">
            <app-levels></app-levels>
          </mat-tab>-->
         </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>

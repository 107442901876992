 <div class="content p30"> 

       
 
    <div id="block_container">
        <a  routerLink="/user" id="bloc1" mat-flat-button class="mat-flat-button-transperent" ><span  class="material-icons">keyboard_backspace</span></a> 
          
        <div id="bloc2"><h5>Users / Add User</h5></div>
    </div>
     

    <form [formGroup]="form" (ngSubmit)="submit()" >
    <mat-card class="">
      <mat-card-content>
        <br />
        <h5>Personal Details</h5>
        <hr />
      
        <mat-grid-list cols="3" rowHeight="100px">
            <mat-grid-tile >
                <mat-form-field appearance="outline" class="field-spacer">
                    <input type="hidden" formControlName="_id" >
                    <input type="hidden" formControlName="isDel" >
                    <input type="hidden" formControlName="updated_by" >
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="First Name" formControlName="name">
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
                <mat-form-field appearance="outline" class="field-spacer">
                    <mat-label>Middle Name</mat-label>
                    <input matInput placeholder="Middle Name" formControlName="middlename">
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile  >
                <mat-form-field appearance="outline" class="field-spacer">
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="Last Name" formControlName="lastname">
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
                <mat-form-field appearance="outline"class="field-spacer">
                    <mat-label>Role Name</mat-label>
                    <mat-select  placeholder="Role"  formControlName="role"  >
                        <mat-option *ngFor="let role of ROLES_LIST" [value]="role._id+''">{{ role.role}}</mat-option>
                     </mat-select>
                     <mat-error>This field is mandatory.</mat-error>
                  </mat-form-field>

                  
                   
            </mat-grid-tile>

            <mat-grid-tile >
                <mat-form-field appearance="outline"class="field-spacer">
                    <mat-label>Profile Photo</mat-label>
                    <input matInput placeholder="Profile Photo" formControlName="profile_photo">
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
                <mat-form-field appearance="outline"class="field-spacer">
                    <mat-label>Address</mat-label>
                    <input matInput placeholder="Address" formControlName="address">
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
                <mat-form-field appearance="outline"class="field-spacer">
                    <mat-label>Mobile</mat-label>
                    <input matInput placeholder="Mobile" formControlName="mobile"  inputmode="numeric"
pattern="[0-9]*" digitOnly minlength="10" >
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
                <mat-form-field appearance="outline"class="field-spacer">
                    <mat-label>Email ID</mat-label>
                    <input matInput placeholder="Email ID" formControlName="email">
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
                  <mat-form-field appearance="outline" class="field-spacer">
                    <mat-label>Date of Birth</mat-label>
                    <input matInput [matDatepicker]="dp3" disabled formControlName="dob" placeholder="Date of Birth">
                    <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                    <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                  </mat-form-field>

            </mat-grid-tile>

            <mat-grid-tile >
                
                    <div class="div1">
                        <label>Married Status : {{marriedControl.value}} </label><br />
                        <mat-radio-group appearance="outline" class="field-spacer" [formControl]="marriedControl">
                            <mat-radio-button value="UnMarried" [checked]="marriedControl.value == 'UnMarried'" (change)="this.form.patchValue({'married':'UnMarried'})" >UnMarried</mat-radio-button>
                            <mat-radio-button value="Married" [checked]="marriedControl.value == 'Married'" class="m20" (change)="this.form.patchValue({'married':'Married'})">Married</mat-radio-button>
                        </mat-radio-group>
                      </div>

            </mat-grid-tile>
 
            <mat-grid-tile >
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Date of Marriage</mat-label>
                  <input matInput [matDatepicker]="dp4" disabled formControlName="dateofmarriage" placeholder="Date of Marriage">
                  <mat-datepicker-toggle matSuffix [for]="dp4"></mat-datepicker-toggle>
                  <mat-datepicker #dp4 disabled="false"></mat-datepicker>
                </mat-form-field>

          </mat-grid-tile>



            <mat-grid-tile >
                <mat-form-field appearance="outline"class="field-spacer">
                    <mat-label>Advisor</mat-label>
                     <mat-select  placeholder="Advisor"  formControlName="advisor"  >
                      <mat-option *ngFor="let role of ADVISOR_LIST" [value]="role._id+''">{{ role.name}} {{ role.lastname}}</mat-option>
                   </mat-select>
                  </mat-form-field>
            </mat-grid-tile>

          </mat-grid-list>
          

          <br />
          <h5>Social Media Links</h5>
          <hr />
        
          <mat-grid-list cols="10" rowHeight="80px" formArrayName="social_links">
            <div *ngFor="let item of sociallinks.controls; let pointIndex=index" [formGroupName]="pointIndex">
                <mat-grid-tile  colspan="3">
                    <mat-form-field appearance="outline"class="field-spacer">
                        <mat-label>Social Network Site</mat-label>
                        <mat-select  placeholder="Social"  formControlName="network"  >
                            <mat-option *ngFor="let social of CONSTANT.getConstant('SOCIAL_SITES')" [value]="social">{{ social}}</mat-option>
                         </mat-select>
                         <mat-error>This field is mandatory.</mat-error>
                      </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="6">
                    <mat-form-field appearance="outline" class="field-spacer">
                      <mat-label>URL Link</mat-label>
                      <input matInput placeholder="URL Link" formControlName="url" >
                    </mat-form-field>
                </mat-grid-tile > 
                <mat-grid-tile  style="padding: 0px;">  
                    <button style="height: 80%; width: 50%;" mat-button type="button" color="warn" appearance="outline" class="field-spacer" (click)="deleteSocial(pointIndex)" *ngIf="pointIndex != sociallinks.controls.length-1"><span class="material-icons" inline="true" style="transform: scale(1.5)">delete_outline</span></button>
                    <button style="height: 80%; width: 50%;" mat-button type="button" color="primary" appearance="outline" class="field-spacer" (click)="addSocial()" *ngIf="pointIndex == sociallinks.controls.length-1"><span class="material-icons" inline="true" style="transform: scale(1.5)">note_add</span></button>
                </mat-grid-tile>
            </div> 
            </mat-grid-list>
         
            <br />
            <h5>Designation</h5>
            <hr />
          
            <mat-grid-list cols="10" rowHeight="80px" formArrayName="designation">
              <div *ngFor="let item of designation.controls; let pointIndex=index" [formGroupName]="pointIndex">
                  <mat-grid-tile  colspan="3">
                      <mat-form-field appearance="outline"class="field-spacer">
                          <mat-label>Parent Business / Business Unit</mat-label>
                          <mat-select  placeholder="Parent Business / Business Unit"  formControlName="business"  >
                              <mat-option *ngFor="let social of CONSTANT.getConstant('SOCIAL_SITES')" [value]="social">{{ social}}</mat-option>
                           </mat-select>
                           <mat-error>This field is mandatory.</mat-error>
                        </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="6">
                      <mat-form-field appearance="outline" class="field-spacer">
                        <mat-label>Position</mat-label>
                        <input matInput placeholder="Position" formControlName="designation" >
                      </mat-form-field>
                  </mat-grid-tile > 
                  <mat-grid-tile  style="padding: 0px;">  
                      <button style="height: 80%; width: 50%;" mat-button type="button" color="warn" appearance="outline" class="field-spacer" (click)="deleteDesignation(pointIndex)" *ngIf="pointIndex != designation.controls.length-1"><span class="material-icons" inline="true" style="transform: scale(1.5)">delete_outline</span></button>
                      <button style="height: 80%; width: 50%;" mat-button type="button" color="primary" appearance="outline" class="field-spacer" (click)="addDesignation()" *ngIf="pointIndex == designation.controls.length-1"><span class="material-icons" inline="true" style="transform: scale(1.5)">note_add</span></button>
                  </mat-grid-tile>
              </div> 
              </mat-grid-list>
           
  
          
              
              <br />
              <h5>ID Details</h5>
              <hr />
            
              <mat-grid-list cols="10" rowHeight="80px" formArrayName="id_details">
                <div *ngFor="let item of ids.controls; let pointIndex=index" [formGroupName]="pointIndex">
                    <mat-grid-tile  colspan="3">
                        <mat-form-field appearance="outline"class="field-spacer">
                            <mat-label>ID Type</mat-label>
                            <mat-select  placeholder="ID Type"  formControlName="id_type"  >
                                <mat-option *ngFor="let social of CONSTANT.getConstant('ID_TYPES')" [value]="social">{{ social}}</mat-option>
                             </mat-select>
                             <mat-error>This field is mandatory.</mat-error>
                          </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="6">
                        <mat-form-field appearance="outline" class="field-spacer">
                          <mat-label>ID Number</mat-label>
                          <input matInput placeholder="ID Number" formControlName="id_no" >
                        </mat-form-field>
                    </mat-grid-tile > 
                    <mat-grid-tile  style="padding: 0px;">  
                        <button style="height: 80%; width: 50%;" mat-button type="button" color="warn" appearance="outline" class="field-spacer" (click)="deleteId(pointIndex)" *ngIf="pointIndex != ids.controls.length-1"><span class="material-icons" inline="true" style="transform: scale(1.5)">delete_outline</span></button>
                        <button style="height: 80%; width: 50%;" mat-button type="button" color="primary" appearance="outline" class="field-spacer" (click)="addId()" *ngIf="pointIndex == ids.controls.length-1"><span class="material-icons" inline="true" style="transform: scale(1.5)">note_add</span></button>
                    </mat-grid-tile>
                </div> 
                </mat-grid-list>
             
    
            
                
   
       


      </mat-card-content>

      <mat-card-actions align="end">
        <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="submit" [disabled]="form.invalid">Save</button>
          <button mat-raised-button color="clear" class="button-wider"  >Cancel</button>
      </mat-card-actions>
      

    </mat-card>
  </form>


  </div>

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { language } from '@shared/config/language';
import { AuthService } from '@services/Auth.service';
import { constants } from '@shared/config/constants';
import { IdpService } from '@services/Idp.service';
import { OtherMeetingService } from '@services/OtherMeeting.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export interface IdpElement {
  member: number;
  advisor: string;
  details: string;
}
@Component({
  selector: 'app-idplisting',
  templateUrl: './idplisting.component.html',
  styleUrls: ['./idplisting.component.css'],
})
export class IdplistingComponent implements OnInit {
  ELEMENT_DATA: IdpElement[];
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = ['name', 'advisor', 'Action1', 'details'];
  listData = new MatTableDataSource<IdpElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<IdpElement>(true, []);

  PERMISSION_EDIT = AuthService.authentication('Users', 'READ');
  PERMISSION_ADD = AuthService.authentication('Users', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Users', 'DELETE');
  localstoragedata: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('idpTable') table: MatTable<IdpElement>;
  searchKey: string;

  resource = language;
  CORE_FAMILY = constants.CORE_FAMILY_NAME;

  constructor(
    private router: Router,
    private idp: IdpService,
    private changeDetectorRefs: ChangeDetectorRef,
    private othermeetinginfoservice: OtherMeetingService
  ) {}

  ngOnInit(): void {
    AuthService.authenticate_redir('Idp', ['READ']);

    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    this.getAllUsers();
  }

  getDashboard() {
    var filter = '';

    //  filter = '?filter[user]=5ec7caaabb74fa001d368705'; //+this.localstoragedata._id
    console.log('****************', this.localstoragedata._id);
    this.idp.useridpget(filter).subscribe(
      (response: any) => {
        const result = response.data.filter(val => val.role === 'member');
        console.log('>>>>>>>>>>>>>>', result);
        this.listData.data = result as IdpElement[];
        this.table.renderRows();
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  redirectTo(redirto, row) {
    if (redirto == 'meeting') {
      this.router.navigate(['schedulemeeting'], {
        queryParams: { id: row._id },
      });
    } else {
      this.router.navigate(['goal/add'], {
        queryParams: { id: row._id },
      });
    }
  }
  getAllUsers() {
    var filter = '';
    this.othermeetinginfoservice.getFamilyUsers(filter).subscribe(
      (response) => {
        let result = response.body['data'].map(val => ({...val, advisor: val.coach
          ? val.coach.name + " " + val.coach.lastname
          : ""}))
         result = result.filter(val => val.role === 'member');
        console.log('>>>>>>>>>>>>>>', result);
        this.listData.data = result as IdpElement[];
        this.table.renderRows();
      },
      (err) => {
        if (err.error.status != null) {
        } else {
        }
      }
    );
  }
  detailsPage(data) {
    console.log('>>>>>>>', data);
    this.router.navigate(['idpdetails'], { queryParams: { id: data._id } });
  }
  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }
  filterData(ev, col, val) {
    if (ev.checked) {
      col = String(col);

      let value = { [col]: val };
      this.filterValues.push(value);
    } else {
      let value = { [col]: val };
      const index = this.filterValues.indexOf(value);
      this.filterValues.splice(index - 1, 1);
    }

    this.setupFilter(col);
    this.applyFilter(val);
  }

  stoppro($event) {
    $event.stopPropagation();
  }

  setupFilter(column: string) {
    this.listData.filterPredicate = (d: any, filter: string) => {
      const val = JSON.parse(filter);
      console.log('**');
      console.log(val);
      var flag = false;

      val.forEach(function (item) {
        let obj = item;
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>', obj[column]);
        //const textToSearch = d[column][column] && d[column][column].toLowerCase() || '';
        const textToSearch = d[column].toLowerCase() || '';
        if (obj[column] != undefined) {
          if (column == 'role') {
            if (
              textToSearch
                .toLowerCase()
                .indexOf(obj[column][column].toLowerCase()) !== -1
            ) {
              flag = true;
            }
          } else if (column == 'status') {
            if (textToSearch.toLowerCase() == obj[column].toLowerCase()) {
              flag = true;
            }
          }
        }
      });

      if (val.length == 0) flag = true;

      return flag;
    };
  }

  applyFilter(filterValue: string) {
    console.log(JSON.stringify(this.filterValues));
    this.listData.filter = JSON.stringify(this.filterValues); // filterValue.trim().toLowerCase();
  }

  getCol(matrix, col) {
    var column = [];
    for (var i = 0; i < matrix.length; i++) {
      column.push(matrix[i][col]['role']);
    }
    return column; // return column data..
  }

  filterValues = [];

  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [['Member Name', 'Advisor Name']];
    this.listData.data.forEach((obj) => {
      let member_name1 = obj['name'] ? obj['name'] : '';
      let member_name2 = obj['lastname'] ? obj['lastname'] : '';
      let member_name = member_name1 + ' ' + member_name2;

      let advisor_name = obj['coach'] ? obj['coach']['name'] : '';

      let arr = [member_name, advisor_name];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      rowPageBreak: 'avoid',
    });

    if (action === 'save') {
      doc.save('IDP.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      doc.output('dataurlnewwindow', { filename: 'IDP.pdf' });
    }
  }
}

<div class="container-fluid">
    <div class="row">
       <div class="col-sm-7">
            <div class="main-div align-middle">
                <span class="arrowlft" (click)="backClicked()"><i class="fa fa-arrow-left"></i></span>
                <h2>Change Password</h2>
                <form [formGroup]="form" (ngSubmit)="checkPassword()">

                    <div class="form-group">
                        <label> New Password</label>
                        <input type="password" class="form-control" id="newpassword"  name="newpassword" required formControlName="new_password">
                    </div>
                    <div class="instCard">
                        <label class='passwordcontain'>Password must contain</label>
                        <label class="lblcolor"><i class="fa fa-circle-o"></i><span class="passwordrules">  At least 6 characters</span></label>
                        <label  class="lblcolor"><i class="fa fa-circle-o"></i><span class="passwordrules">  Alphanumeric</span></label>
                        <label  class="lblcolor"><i class="fa fa-circle-o"></i><span class="passwordrules">  At least one upper case and one special character </span></label>
                    </div>
                    <div class="form-group">
                        <label> Confirm Password </label>
                        <input type="password" class="form-control" id="confirmpassword"  name="confirmpassword" required formControlName="confirmPassword">
                    </div>
                    <button class="btn btn-primary login-btn" type="submit"  [disabled]="form.invalid">CHANGE</button>
                 </form>
            </div>
        </div>
        <div class="col-sm-5">
            <div class="login-bg">  </div>
        </div>
    </div>
</div>


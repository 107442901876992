import { Component, ViewChild } from '@angular/core';
import $ from 'jquery';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SettingsService } from '@services/Settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'FBA';
  localstoragedata: any;
  hidesidebar: boolean = true;
  @ViewChild('any') childdrawer: any;
  corechild: any;
  parent: any;
  constructor(private router: Router, private settingService: SettingsService) {
    this.localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));

    
    settingService.getDateFormat();

    router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        //   this.currentUrl = event.url;
        // console.log("APP file",event.url)
        console.log('this.localstoragedata  ', this.localstoragedata);
        if (
          event.url === '/' ||
          event.url === '/password-recovery-by-email' ||
          event.url.startsWith('/password-reset-token') ||
          event.url === '/forget' ||
          event.url === '/forgotId' ||
          event.url === '/login' ||
          event.url === '/changepassword' ||
          event.url === '/logout'
        ) {
          this.hidesidebar = true;
        } else {
          this.corechild = this.childdrawer;
          this.parent = this.childdrawer;
          this.hidesidebar = false;
        }
      }
    });

    $(document).ready(function () {
      //   alert( "ready!" );
    });
  }

  onActivate(event) {
    // window.scroll(0,0);
    document.querySelector('.mat-drawer-content').scrollTo(0,0)
  }
}

 



import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators} from '@angular/forms';
import { UACService } from '@services/UAC.service';
import { ActivatedRoute, Router } from '@angular/router';
import {CoreService} from '../../../../shared/services/CoreService';
import { language } from '@shared/config/language';
import { FamiliesService } from '@services/Families.service';
import { constants } from '@shared/config/constants';
import { CategoriesReportsService } from '@services/CategoriesReports.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogaddreportcategoryComponent } from '@shared/components/dialogaddreportcategory/dialogaddreportcategory.component';
import { ThisReceiver } from '@angular/compiler';

@Component({
  selector: 'app-addcategory',
  templateUrl: './addcategory.component.html',
  styleUrls: ['./addcategory.component.css']
})
export class AddcategoryComponent implements OnInit {
 

  LEVELS_LIST:any[] = [];

  resource = language;
  labelText:String='Add Category';

  currentSelected = "";

  newCategory = {
    "_id":"",
    "type": "category",
    "title":"",
    "role": ["facilitator", "member", "office_member"],
    "display_message": "",
    "icon": "",
    "no_of_columns": 1,
    "parent":""
  }

  edit_id = "";

  constructor(private categoriesReportsService:CategoriesReportsService, private dialog:MatDialog,    private activeRoute: ActivatedRoute,
    ) {
 
      this.initiateLevels();
      this.fetchCategories(0);


      this.activeRoute.queryParams.subscribe(data => {

        if(data.id!=undefined)
        {
            this.edit_id = data.id;
            this.currentSelected = data.id;
            this.fetchCategories(0, true);

            console.log("*************"+this.currentSelected)
        }
      });
  }

  initiateLevels(){
    this.LEVELS_LIST.push({
        level:0,
        categories:[],
        selected:""
    })
  }

  fetchCategories(level:number, fetchRecurrsive=false){
    this.categoriesReportsService.getParentCategories().subscribe(response=> {    
        this.LEVELS_LIST[level].categories = [{
          _id:"",
          title:"Select Category"
        }];   
        this.LEVELS_LIST[level].categories.push(...response.body['data'] as any[]);
        this.LEVELS_LIST[level].selected = "";
        let rootIndex = 0;
        if(this.edit_id != "") {
            rootIndex = this.LEVELS_LIST[level].categories.find((category)=> category._id == this.edit_id);
            this.LEVELS_LIST[level].selected = this.edit_id;
        }
        if(fetchRecurrsive){
        
        
          if(level != 0) this.edit_id =  this.LEVELS_LIST[level].categories[1]._id;
        
          console.log(this.LEVELS_LIST);
          console.log("IN:"+level+":"+this.edit_id)

          this.fetchChildCategories(this.edit_id, level+1, true);
        }
     });

  }

  fetchChildCategories(parentId:string, level:number, fetchRecurrsive=false){
    this.LEVELS_LIST[level-1].selected = parentId;
    console.log(parentId)
    this.categoriesReportsService.getChildCategories(parentId).subscribe(response=> {  

      response.body['data']  = (response.body['data'] as any[]).filter((child)=> child.type != "doc");
      
        if((response.body['data'] as any[]).length > 0 && this.LEVELS_LIST[level] == undefined){
          if( level < 5)
            this.addSubLevel();
        }

        this.LEVELS_LIST.splice(level+1,this.LEVELS_LIST.length)
        
        
        if(this.LEVELS_LIST[level] != undefined){
          this.LEVELS_LIST[level].categories = [{
            _id:"",
            title:"Select Category"
          }];
          this.LEVELS_LIST[level].categories.push(...response.body['data'] as any[]);  
          this.LEVELS_LIST[level].selected = (this.LEVELS_LIST[level].categories[1] == undefined)?this.LEVELS_LIST[level].categories[1]._id:"";
          if(fetchRecurrsive){
            
            this.fetchChildCategories(this.LEVELS_LIST[level].categories[1]._id, level+1, true);
          }
        }


      });

  }

  createNewSubLevel(index, action="add"){

    console.log(index)
    this.newCategory.type = (index != 0)?"sub":"category";
    this.newCategory.parent = (index != 0)?this.LEVELS_LIST[index-1].selected:null;
    
    if(action == "edit"){
      let selectedCategory = this.LEVELS_LIST[index].categories.find((category)=> category._id == this.LEVELS_LIST[index].selected);
      console.log(selectedCategory)
      this.newCategory._id = selectedCategory._id;
      this.newCategory.title = selectedCategory.title;
      this.newCategory.display_message = selectedCategory.display_message;
      this.newCategory.icon= selectedCategory.icon;
    }

    return  this.dialog.open(DialogaddreportcategoryComponent,{
        width:'50vw',
        panelClass:'confirm-dialog-container',
        disableClose:true,
        data:{
            category:this.newCategory,
            actionbutton: "Save",
            cancelbutton: "Cancel"
        }
    }).afterClosed().subscribe((newCategory)=>{
          console.log("Closed:"+JSON.stringify(newCategory))
          if(index == 0){
            this.fetchCategories(index);

          }else{
            this.fetchChildCategories(this.newCategory.parent, index, true);

          }
    })

  }

  addSubLevel(){
    if(this.LEVELS_LIST.length < 5){
    this.LEVELS_LIST.push({
      level:this.LEVELS_LIST.length,
      categories:[],
      selected:""
    })
  }
  }

  removeSubLevel(level, selectedindex){

      console.log(level);
      let category = level.categories.find( (category)=> category._id == level.selected);
      console.log(category);
      this.categoriesReportsService.deleteCategory(level.selected).subscribe(response=> {    
       
        console.log(level);
        console.log(">>:"+category.parent);
        if(level.level == 0){
          this.fetchCategories(0);
        }else{
          this.fetchChildCategories(category.parent, selectedindex);

        }

      }); 

  }

  ngOnInit(): void {  }
  
 
}

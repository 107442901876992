import { Component, OnInit } from '@angular/core';
import { language } from '@shared/config/language';

@Component({
  selector: 'app-othermessagetemplate',
  templateUrl: './othermessagetemplate.component.html',
  styleUrls: ['./othermessagetemplate.component.css']
})
export class OthermessagetemplateComponent implements OnInit {
  resource = language;
  activePath = 0;
  constructor() { }

  ngOnInit(): void {
  }

}

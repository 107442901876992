import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { apiendpoints } from '../config/apiendpoints';

@Injectable({
  providedIn: 'root',
})
export class BusinessInfoService {
  constructor(private http: HttpClient) {}

  businessunitget(id) {
    return this.http.get(apiendpoints.BUSINESS_UNIT + '/' + id, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Token ' + localStorage.getItem('ACCESS_TOKEN')
      ),
    });
  }

  businessinfoget() {
    return this.http.get(apiendpoints.BUSINESS_INFO, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Token ' + localStorage.getItem('ACCESS_TOKEN')
      ),
    });
  }
  updateBusinessData(data: any, id: string) {
    return this.http.put(
      apiendpoints.BUSINESS_UPDATE + '/' + id,
      { business: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }

  postBusinessData(data: any) {
    return this.http.post(
      apiendpoints.BUSINESS_UPDATE,
      { business: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }

  addBusinessData(data: any) {
    return this.http.post(
      apiendpoints.BUSINESS_UPDATE,
      { business: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }
  removeUnit(id: string) {
    return this.http.delete(apiendpoints.BUSINESS_UPDATE + '/' + id, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }

  getKeyInfo(id: string) {
    return this.http.get(apiendpoints.BUSINESS_INFORMATION + '/' + id, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }
  getImageMedia(id: string) {
    return this.http.get(apiendpoints.BUSINESS_UNIT_IMAGE + '/' + id, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }
  postKeyInfo(data: any) {
    return this.http.post(
      apiendpoints.BUSINESS_INFORMATION,
      { information: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }
  updateKeyInfo(data: any) {
    return this.http.put(
      apiendpoints.BUSINESS_INFORMATION,
      { information: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }

  deleteKeyInfo(id) {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Token ` + localStorage.getItem('ACCESS_TOKEN'),
      }),
      body: {
        informationId: id,
      },
    };

    return this.http.delete(apiendpoints.BUSINESS_INFORMATION, options);
  }

  deleteBuisnessReadInfo(id) {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Token ` + localStorage.getItem('ACCESS_TOKEN'),
      }),
      body: {
        readId: id,
      },
    };

    return this.http.delete(apiendpoints.BUSINESS_BOARD_READS, options);
  }

  getBoardReads(id: string) {
    return this.http.get(apiendpoints.BUSINESS_BOARD_READS + '/' + id, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }
  postBoardReadsInfo(data: any) {
    return this.http.post(
      apiendpoints.BUSINESS_BOARD_READS,
      { board_read: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }

  deleteBoardReads(id) {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Token ` + localStorage.getItem('ACCESS_TOKEN'),
      }),
      body: {
        readId: id,
      },
    };

    return this.http.delete(apiendpoints.BUSINESS_BOARD_READS, options);
  }

  getRoles(filter) {
    return this.http.get(apiendpoints.ROLE + filter, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }
}

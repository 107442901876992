import { Component, OnInit } from '@angular/core';
import { language } from '@shared/config/language';

@Component({
  selector: 'app-templatemanagement',
  templateUrl: './templatemanagement.component.html',
  styleUrls: ['./templatemanagement.component.css']
})
export class TemplatemanagementComponent implements OnInit {

  resource = language;

  
  constructor() { }

  ngOnInit(): void {
  }

}
 
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { language } from '@shared/config/language';
import { AuthService } from '@services/Auth.service';
import { constants } from '@shared/config/constants';
import { IdpService } from '@services/Idp.service';
import { DialoggoalcategoryComponent } from '@shared/components/dialoggoalcategory/dialoggoalcategory.component';
import { MatDialog } from '@angular/material/dialog';
import { CommitteeService } from '@services/Committee.service';
import { CoreService } from '@shared/services/CoreService';

export interface IdpElement {
  category_type: number;
  title: string;
}
@Component({
  selector: 'app-idpcategory',
  templateUrl: './idpcategory.component.html',
  styleUrls: ['./idpcategory.component.css'],
})
export class IdpcategoryComponent implements OnInit {
  ELEMENT_DATA: IdpElement[];
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = ['category_type', 'title', 'Action'];
  listData = new MatTableDataSource<IdpElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<IdpElement>(true, []);

  PERMISSION_EDIT = AuthService.authentication('Users', 'READ');
  PERMISSION_ADD = AuthService.authentication('Users', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Users', 'DELETE');
  localstoragedata: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('idpTable1') table: MatTable<IdpElement>;

  resource = language;
  CORE_FAMILY = constants.CORE_FAMILY_NAME;
  searchKey: string;
  id: string = '';
  isEdit: boolean = false;
  labelText: string = 'Add Goal Type (Categories)';
  showback: boolean = false;
  constructor(
    private router: Router,
    private idp: IdpService,
    private changeDetectorRefs: ChangeDetectorRef,
    private dialog: MatDialog,
    private committeeService: CommitteeService,
    private coreservice: CoreService
  ) {}

  ngOnInit(): void {
    AuthService.authenticate_redir('Users', ['READ']);

    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    this.getAllCategories();
  }

  redirectTo(row) {
    console.log(row);
    this.labelText = 'Category: ' + row.title;
    this.listData.data = this.listData.data.filter((category) =>
      category['parent'] !== undefined ? category['parent']._id === row._id : ''
    );
    this.showback = true;
    console.log(this.listData.data);
    this.table.renderRows();
  }

  openDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialoggoalcategoryComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        id: this.id,
        isEdit: this.isEdit,
      },
    });
    dialogRef.afterClosed().subscribe((item) => {
      this.isEdit = false;
      this.id = '';
      this.getAllCategories();
    });
  }

  getAllCategories() {
    this.showback = false;
    this.labelText = 'Add Goal Type (Categories)';

    this.committeeService.getCommitteeCategories('').subscribe(
      (response) => {
        const result = response.body;
        console.log('cat ', result);
        const subCat = [...response.body['data']];
        let data = response.body['data'].map((cat) => {
          const hasSubCat = subCat.find((c) => {
            return c?.parent && c?.parent?._id === cat._id;
          });
          if (hasSubCat) {
            cat['hasSubCat'] = true;
          } else {
            cat['hasSubCat'] = false;
          }
          cat['category_type'] = cat.parent == undefined ? "Category" : "Sub-Category"
          return cat;
        });
        this.listData.data = data as IdpElement[];
      },
      (err) => {}
    );
  }
  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }
  filterData(ev, col, val) {
    if (ev.checked) {
      col = String(col);

      let value = { [col]: val };
      this.filterValues.push(value);
    } else {
      let value = { [col]: val };
      const index = this.filterValues.indexOf(value);
      this.filterValues.splice(index - 1, 1);
    }

    this.setupFilter(col);
    this.applyFilter(val);
  }

  stoppro($event) {
    $event.stopPropagation();
  }

  setupFilter(column: string) {
    this.listData.filterPredicate = (d: any, filter: string) => {
      const val = JSON.parse(filter);
      console.log('**');
      console.log(val);
      var flag = false;

      val.forEach(function (item) {
        let obj = item;
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>', obj[column]);
        //const textToSearch = d[column][column] && d[column][column].toLowerCase() || '';
        const textToSearch = d[column].toLowerCase() || '';
        if (obj[column] != undefined) {
          if (column == 'role') {
            if (
              textToSearch
                .toLowerCase()
                .indexOf(obj[column][column].toLowerCase()) !== -1
            ) {
              flag = true;
            }
          } else if (column == 'status') {
            if (textToSearch.toLowerCase() == obj[column].toLowerCase()) {
              flag = true;
            }
          }
        }
      });

      if (val.length == 0) flag = true;

      return flag;
    };
  }

  applyFilter(filterValue: string) {
    console.log(JSON.stringify(this.filterValues));
    this.listData.filter = JSON.stringify(this.filterValues); // filterValue.trim().toLowerCase();
  }

  getCol(matrix, col) {
    var column = [];
    for (var i = 0; i < matrix.length; i++) {
      column.push(matrix[i][col]['role']);
    }
    return column; // return column data..
  }

  filterValues = [];

  editRow(row) {
    console.log('CAT Edit::::', row);
    this.isEdit = true;
    this.id = row._id;
    this.openDialog('', '', '');
  }

  deleteCategory(row) {
    this.idp.removeCategory(row._id).subscribe(
      (response) => {
        const result = response.body;
        this.coreservice.success(this.resource.message('CATEGORY_DELETE'));
        this.getAllCategories();
        this.table.renderRows();
      },
      (err) => {}
    );
  }
}

import { Component, OnInit, Input  } from '@angular/core';
import { PlaygroundComponent } from '@modules/playground/playground.component';
import { ViewChild } from '@angular/core'
import { constants } from '@shared/config/constants';
import { UserService } from '@services/UserService';
import { FormControl,FormControlName,FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialognotificationComponent } from '@shared/components/dialognotification/dialognotification.component';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
 
  @Input() parent: any;
  
  familyname:string = "";
  localstoragedata:any;

  playground: any;
  showFiller = false;
  logo:string='';
  CONSTANT = constants;
  CORE_FAMILY = constants.CORE_FAMILY_NAME;
  logged_role:string;
  user_id:string;
  form :FormGroup = new FormGroup({
    _id:new FormControl(''),
    logs:new FormControl(''),
    name:new FormControl(''),
    middlename:new FormControl(''),
    lastname:new FormControl(''),
    mobile: new FormControl(''),
    role:new FormControl('')
    
  })
  allowlogs:boolean;
  allowmsg:boolean;
  constructor(private userservice: UserService,
    private router : Router,    
    private dialog :MatDialog) { 
 }

  ngOnInit(): void {
    this.playground = this.parent;
    console.log("PARENT :::::",this.parent);
    this.playground?.toggle();

    this.localstoragedata=JSON.parse(localStorage.getItem('LOGGED_USER'));
    if(this.localstoragedata.family != null)    this.familyname=this.localstoragedata.family.name;
    this.logo=this.localstoragedata.family.logo
    this.logged_role=this.localstoragedata.role
    this.user_id=this.localstoragedata._id
    this.allowlogs=this.localstoragedata.logs
    this.allowmsg=this.localstoragedata.msgpermission
    this.form.patchValue({
      _id :this.localstoragedata._id,
      logs:this.localstoragedata.logs,
      name:this.localstoragedata.name,
      middlename:this.localstoragedata.middlename,
      lastname:this.localstoragedata.lastname,
      mobile:this.localstoragedata.mobile,
      role:this.localstoragedata.role,
      msgpermission:this.localstoragedata.msgpermission
    })
  }
  onChange(event):void{
  
    var rawuser=this.form.getRawValue()
    rawuser['logs']= event.checked
    this.userservice.addUser(rawuser).subscribe(response=> {
      const result = response.body;
     //   this.coreservice.success(this.resource.message("USER_UPDATE"));
      }, (err) => {
        var errormessages = "Please correct the errors - \n";
        err.error.errors.forEach(element => errormessages += ","+element.msg+'\n');
       // this.coreservice.error(errormessages);
      })
   
  }
  onPermissionChange(event):void{
    var rawuser=this.form.getRawValue()
    rawuser['msgpermission']= event.checked
    this.userservice.addUser(rawuser).subscribe(response=> {
      const result = response.body;
     //   this.coreservice.success(this.resource.message("USER_UPDATE"));
      }, (err) => {
        var errormessages = "Please correct the errors - \n";
        err.error.errors.forEach(element => errormessages += ","+element.msg+'\n');
       // this.coreservice.error(errormessages);
      })
  }
  redirectTo(redirectTo){
    this.router.navigate(['changePassword']);

  }

  openDialog(msg,actionbutton, cancelbutton)
  {
   const dialogRef = this.dialog.open(DialognotificationComponent,{
           width:'50vw',
           panelClass:'notification-dialog-container',
           disableClose:true,
           data:{
               message:msg,
               actionbutton: actionbutton,
               cancelbutton: cancelbutton,
           }
       })
     
   }
}

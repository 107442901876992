<div class="content p30"> 
    <h5>{{resource.message('FAMILYOFFICE')}}</h5>    
    <mat-card class="">
      <mat-card-header >            
            <mat-card-title > {{resource.message('FAMILYOFFICE')}} </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-tab-group class="tabgroup" [selectedIndex]="activePath">
          <mat-tab label="Tickets" class="test">
            <app-ticket></app-ticket>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
</div>


import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@services/UserService';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-password-reset-token',
  templateUrl: './password-reset-token.component.html',
  styleUrls: ['./password-reset-token.component.css'],
})
export class PasswordResetTokenComponent implements OnInit {
  isTokenValid = false;
  reset_token = null;
  message = '';
  form: FormGroup = new FormGroup(
    {
      password1: new FormControl('', [Validators.required]),
      password2: new FormControl('', [Validators.required]),
    },
    this.mustBeSame('password1', 'password2')
  );
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router : Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((prams) => {
      console.log(prams);

      if (prams.reset_token) {
        this.reset_token = prams.reset_token;
      }
    });
    this.validateToken();
  }
  mustBeSame(controlName1: string, controlName2: string) {
    return (formGroup: FormGroup) => {
      const control1 = formGroup.controls[controlName1];
      const control2 = formGroup.controls[controlName2];

      if (control2.errors && !control2.errors.areDifferent) {
        return;
      }

      if (control1.value !== control2.value) {
        control2.setErrors({ areDifferent: true });
      } else {
        control2.setErrors(null);
      }
      return null;
    };
  }

  validateToken() {
    this.userService
      .ValidatePasswordToken({ resettoken: this.reset_token })
      .subscribe(
        (response) => {
          if (response.status === 200) {
            this.isTokenValid = true;
          }
        },
        (error) => {
          if (error.status !== 200) {
            this.isTokenValid = false;
          }
        }
      );
  }

  submit() {
    if (this.form.invalid) {
      return;
    }
    this.userService
      .SetNewPasswordUsingToken({
        resettoken: this.reset_token,
        password: this.form.get('password1').value,
      })
      .subscribe(
        (response) => {
          this.message = 'Password changed';
          setTimeout(()=>{
            this.router.navigate(['login']);

          },3000)

        },
        (error) => {
          this.message = 'Something went wrong. Please try again!';
        }
      );
  }
}

import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { apiendpoints } from '../config/apiendpoints';


@Injectable({
    providedIn:'root'
})

export class TemplateService{

    constructor(private http: HttpClient){


    }

    familyuserget(filterData){
        return this.http.get(apiendpoints.USERS+"?filter[family]="+filterData, {
            headers: new HttpHeaders()
            .set('Authorization', 'Token ' +localStorage.getItem('ACCESS_TOKEN')),
        });
    }
    addMessage(data:any){
        return this.http.post(apiendpoints.MESSAGE_ADD, {messagequeue:data},  {
          headers: new HttpHeaders()
            .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
          observe: 'response'
        });      
      }

}
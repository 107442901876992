import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators,FormArray,FormBuilder} from '@angular/forms';
import { UACService } from '@services/UAC.service';
import { ActivatedRoute, Router } from '@angular/router';
import {CoreService} from '@services/CoreService';
import { language } from '@shared/config/language';
import { constants } from '@shared/config/constants';
import { FamilyOfficeService } from '@services/FamilyOffice.service';
import { DatePipe } from '@angular/common'
import { FileService } from '@services/File.service';
import { UserService } from '@services/UserService';
import { DomSanitizer } from '@angular/platform-browser';

export interface Option {
  votes:string,
  total_percentage : string,
  option: string
}
@Component({
  selector: 'app-secondsection',
  templateUrl: './secondsection.component.html',
  styleUrls: ['./secondsection.component.css']
})
export class SecondsectionComponent implements OnInit {
  notice =new FormControl('single')
  isEdit:Boolean = false;
  disableText:boolean=true;
  form :FormGroup = new FormGroup({
    _id:new FormControl(0),
    status:new FormControl('',Validators.required),
    file:new FormControl(''),
    file2:new FormControl(''),
    file3:new FormControl(''),
    allowedlogs:new FormControl(''),
    request_status:new FormControl(''),
    description1:new FormControl(''),
    response:new FormControl(''),
    attachments: new FormArray([]),
    subassign_userid:new FormControl('')

    
  })

  postform:FormGroup= new FormGroup({
   
  })
  resource = language;
 editId:string;
 ticketno:string;
 file: any = "";
 file2: any = "";
 file3: any = "";
 STATUS_LIST:any;
 allowlogs:boolean;
  selFile = new Object();
  selFileName = "";
  
  selFile2 = new Object();
  selFileName2 = "";
  
  selFile3 = new Object();
  selFileName3 = "";
  allAttachments:any;
  localstoragedata:any;
  MEMBER_LIST:any;
  showMem:boolean=false;
  isAssigned:string;
  btnvisible:boolean=false;
  constructor(private uacservice: UACService, private router : Router,
    private activeRoute: ActivatedRoute,
   private familyoffice : FamilyOfficeService,
   public datepipe: DatePipe,
   private fileService: FileService,
   private userservice: UserService,
   private coreservice:CoreService,
   private sanitizer:DomSanitizer
   
    ) {
 
      this.STATUS_LIST = constants.ticket_status;

    this.activeRoute.queryParams.subscribe(data => {

      if(data.id!=undefined)
      {
        this.editId=data.id;
        this.familyoffice.ticketget(data.id).subscribe((response:any)=> {
          const result = response.data[0];
          console.log("RECEIVED EDIT DATA",result)
          this.ticketno=result.ticket_no
          this.form.patchValue({
            _id:result._id,
            request_status:result.request_status,
            description1:(result.response.description!= null)?result.response.description:'',
            subassign_userid:(result.subassign_userid!= null)?result.subassign_userid:'',
            
        }) 
        this.file= result.response.attachments && result.response.attachments[0] ? result.response.attachments[0].url : '',
        this.file2= result.response.attachments && result.response.attachments[1] ? result.response.attachments[1].url : '',
         this.file3= result.response.attachments && result.response.attachments[2] ? result.response.attachments[2].url : ''
        this.allAttachments=result.response.attachments;
        this.localstoragedata=JSON.parse(localStorage.getItem('LOGGED_USER'));
            if(this.localstoragedata.role=='admin')
            {
              this.showMem=false;
              this.btnvisible=true;
            
            }
            else
            {
              this.btnvisible=false;
              if(this.form.get('subassign_userid').value=='')
              {
                this.showMem=true;
              }
              else
              {
                this.showMem=false;
              }
           }
         })
        } 
    })

   }

  ngOnInit(): void {
    this.getAllUsers()
    
  }

    nextPage(){ 
      this.router.navigate(['tickets/s3'],{queryParams: {id: this.editId}});

    
  }
  previousPage()
  {
    this.router.navigate(['tickets/s1'],{queryParams: {id: this.editId}});

  }
  onChange(event):void{
     this.router.navigate(['tickets/s3'],{queryParams: {id: this.editId}});


  console.log("EVENT",event)
   
  }

  
  
  onFileChange(event) {

    
    this.selFile = (event.target as HTMLInputElement).files[0];
    this.selFileName = (event.target as HTMLInputElement).files[0].name;
     this.file = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.target.files[0]))
    }
 onFileChange1(event) {

    
  this.selFile2 = (event.target as HTMLInputElement).files[0];
  this.selFileName2 = (event.target as HTMLInputElement).files[0].name;
  this.file2 = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.target.files[0]))
 }
onFileChange2(event) {

    
  this.selFile3 = (event.target as HTMLInputElement).files[0];
  this.selFileName3 = (event.target as HTMLInputElement).files[0].name;
  this.file3 = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.target.files[0]))
 }

 remove() {
  this.file = "";
  this.selFileName = "";
  this.allAttachments[0] = "";
 }
 remove2() {
  this.file2 = "";
  this.selFileName2 = "";
  this.allAttachments[1] = "";
 }
 remove3() {
  this.file3 = "";
  this.selFileName3 = "";
  this.allAttachments[2] = "";
 }

 async onSubmit()  
 {
   console.log("In submit")
   var req = this.form.getRawValue()

    let fileurl = this.selFileName;
    var attch1;
    var attch2
    var attch3
    if(this.selFileName!=='')
    {
      var formData: any = new FormData();
      formData.append("file", this.selFile);
      fileurl = await this.fileService.uploadFile(  formData, true, false );
      attch1={
        'type':'Image',
        'url':fileurl
      }
      req['attachments'].push({
        'type':'Image',
        'url':fileurl
      })
    }
    else {
      if(this.allAttachments && this.allAttachments[0]) {
        req['attachments'].push({
          'type':'Image',
          'url':this.allAttachments[0].url
        })
      }
    }

    let fileurl2 = this.selFileName2;
   
    if(this.selFileName2!=='')
    {
      var formData: any = new FormData();
      formData.append("file", this.selFile2);
      fileurl2 = await this.fileService.uploadFile(  formData, true, false );
      attch2={
        'type':'Image',
        'url':fileurl2
      }
      req['attachments'].push({
        'type':'Image',
        'url':fileurl2
      })
    }
    else {
      if(this.allAttachments && this.allAttachments[1]) {
        req['attachments'].push({
          'type':'Image',
          'url':this.allAttachments[1].url
        })
      }
    }
    let fileurl3 = this.selFileName3;
    if(this.selFileName3!=='')
    {
      var formData: any = new FormData();
      formData.append("file", this.selFile3);
      fileurl3 = await this.fileService.uploadFile(  formData, true, false );
      attch3={
        'type':'Image',
        'url':fileurl3
      }
      req['attachments'].push({
        'type':'Image',
        'url':fileurl3
      })
    }
    else {
      if(this.allAttachments && this.allAttachments[2]) {
        req['attachments'].push({
          'type':'Image',
          'url':this.allAttachments[2].url
        })
      }
    }
    if(req['attachments']=='')
    {
      req['response']={'description':req['description1'],'attachments':this.allAttachments}

    }
    else
    {
      req['response']={'description':req['description1'],'attachments':req['attachments']}

    }
    req["url"]= fileurl; 
    if(req['subassign_userid']=='')
    {
      delete req['subassign_userid']
    }
    delete req['file']
    delete req['file2']
    delete req['file3']
    delete req['attachments'];
    if(this.isEdit==false)
    {
   //   delete req['_id']
    }
    else
    {
     // req['_id']=this.dataid
    }
    
    if(req.request_status === 'completed' || req.request_status === 'declined') {
      req = {request_progress:{request_closed : {status : true},internal_assigned : {status : false},external_assigned : {status : false}, request_created : {status : false}}, ...req}
    }
 
    
    this.familyoffice.ticketput(req).subscribe(response=> {
      this.coreservice.success(this.resource.message('TICKET_UPDATED'));

      this.router.navigate(['/tickets']);
    }, (err) => {
  })
}
getAllUsers()
{
  this.localstoragedata=JSON.parse(localStorage.getItem('LOGGED_USER'));

  var filter="?filter[family]="+this.localstoragedata.family._id
  this.userservice.getUsers(filter).subscribe(response=> {
    
    const result = response.body;
    this.MEMBER_LIST=response.body['results']
   }, (err) => {

      if(err.error.status != null){
      }else{
      }
 })
}
}

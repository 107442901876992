<div class="content p30">
  <div id="block_container">
    <a
      routerLink="/commonspace/commonspaces"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >
    <div id="bloc2">
      <h5>{{ resource.message("COMMONSPACE") }} / {{ labelText }}</h5>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <mat-card class="">
      <mat-card-content>
        <br />
        <h5>{{ resource.message("ADDSPACE") }}</h5>
        <hr />

        <mat-grid-list cols="2" rowHeight="100px">
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Space Type</mat-label>
              <mat-select placeholder="Space Type" formControlName="category">
                <mat-option
                  *ngFor="let category of CATEGORY_LIST"
                  [value]="category._id"
                  >{{ category.category }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <input type="hidden" formControlName="_id" />
              <mat-label>Space Name</mat-label>
              <input
                matInput
                placeholder="Space Name"
                formControlName="spacename"
              />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Address 1</mat-label>
              <input matInput placeholder="Address 1" formControlName="floor" />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Capacity</mat-label>
              <input
                matInput
                placeholder="Capacity"
                formControlName="capacity"
                type="number"
              />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Address 2</mat-label>
              <input
                matInput
                placeholder="Address 2"
                formControlName="address"
              />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>City</mat-label>
              <input matInput placeholder="City" formControlName="city" />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Contact Person</mat-label>
              <input
                matInput
                placeholder="Contact Person"
                formControlName="caretaker"
              />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Contact Number</mat-label>
              <ngx-mat-intl-tel-input
                  [preferredCountries]="['in']"
                  [enablePlaceholder]="true"
                  [enableSearch]="true"
                  name="mobile1"
                  formControlName="contact"
                  describedBy="phoneInput1"
                ></ngx-mat-intl-tel-input>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Email ID</mat-label>
              <input
                matInput
                placeholder="Email ID"
                formControlName="email"
                type="email"
              />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Remarks</mat-label>
              <input matInput placeholder="Remarks" formControlName="remarks" />
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>

      <mat-card-actions align="end">
        <button
          mat-raised-button
          color="primary"
          class="btn btn-primary login-btn button-wider"
          type="submit"
          [disabled]="form.invalid"
        >
          Save
        </button>
        <button
          mat-raised-button
          color="clear"
          class="button-wider"
          routerLink="/commonspace/commonspaces"
        >
          Cancel
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>

import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { language } from '@shared/config/language';
import { UserService } from '@services/UserService';
import { MatPaginator } from '@angular/material/paginator';
import { IdpService } from '@services/Idp.service';

export interface GoalElement {
  development: number;
  category: string;
  status: string;
}
@Component({
  selector: 'app-dialogstatusgoal',
  templateUrl: './dialogstatusgoal.component.html',
  styleUrls: ['./dialogstatusgoal.component.css'],
})
export class DialogstatusgoalComponent implements OnInit {
  resource = language;
  ELEMENT_DATA: GoalElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = ['category', 'description', 'status'];
  listData = new MatTableDataSource<GoalElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<GoalElement>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('roleTable') table: MatTable<GoalElement>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  localstoragedata: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogstatusgoalComponent>,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog,
    private userservice: UserService,
    private idp: IdpService
  ) {
    this.listData = new MatTableDataSource<GoalElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<GoalElement>(true, []);
  }

  ngOnInit(): void {
    this.localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    this.listData.paginator = this.paginator;

    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getGoals(this.data.id);
  }
  closeDialog() {
    this.dialogRef.close();
  }

  getGoals(id) {
    var filter = '';

    filter = '?filter[user]=' + id; //+this.localstoragedata._id
    this.idp.useridpget(filter).subscribe((response: any) => {
      const result = response;
      this.listData.data = response.data as GoalElement[];
      this.table.renderRows();
    });
  }
}

<mat-card class="">
  <mat-card-content>
    <mat-tab-group class="tabgroup">
      <mat-tab label="Votes" class="tabcolor" *ngIf="isVoting">
        <app-commiteevotes
          [parentid]="parentid"
          [agendaid]="agendaid"
          [viewdata]="viewdata"
        ></app-commiteevotes>
      </mat-tab>
      <mat-tab label="Outcome" class="tabcolor">
        <app-commiteemeetingoutcome
          [parentid]="parentid"
          [agendaid]="agendaid"
          [viewdata]="viewdata"
        ></app-commiteemeetingoutcome>
      </mat-tab>
      <mat-tab label="Task" class="tabcolor">
        <app-commiteetask
          style="width: 100%; margin-left: 50px"
          [parentid]="parentid"
          [agendaid]="agendaid"
          [viewdata]="viewdata"
        ></app-commiteetask>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>

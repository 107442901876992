import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';


@Injectable({
    providedIn:'root'
})

export class SecurityLogsService {

    constructor(private http: HttpClient){


    }


    getSecurityLogs(userId){


      return this.http.get(apiendpoints.SECURITY+"/"+userId,
          {
              headers: new HttpHeaders()
                .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
              observe: 'response'
            }
          );      
    }

    
    


    
}

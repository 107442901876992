<mat-card class="main-card">
  <mat-card-content>
    <textarea class="textarea" rows="6" [(ngModel)]="outCome"></textarea>
    <div class="divfloat">
      <button
        mat-raised-button
        color="primary"
        class="btn btn-primary done-btn button-wider"
        type="button"
        (click)="doneClick()"
        *ngIf="viewdata !== true"
      >
        Save
      </button>
    </div>
  </mat-card-content>
</mat-card>

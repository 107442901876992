import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators} from '@angular/forms';
import { language } from '@shared/config/language';
import {OnboardingService} from '@services/Onboarding.service';
import { ActivatedRoute, Router } from '@angular/router';

import {CoreService} from '../../../shared/services/CoreService';


@Component({
  selector: 'app-onboardingtemplate',
  templateUrl: './onboardingtemplate.component.html',
  styleUrls: ['./onboardingtemplate.component.css']
})
export class OnboardingtemplateComponent implements OnInit {


  resource = language;

  form :FormGroup = new FormGroup({
    _id:new FormControl(0),
    message: new FormControl('',Validators.required)
    
  })



  constructor(private onboardingService:OnboardingService, private router : Router,
    private coreservice: CoreService) {

    this.onboardingService.getMessage().subscribe(response=> {
      
      const result = response.body["results"][0];
        this.form.setValue({_id:result._id,message:result.message})
    });

   }

  ngOnInit(): void {

    
  }


  submit(){
    
    if (this.form.invalid) {
        return;
    }


    this.onboardingService.updateMessage(this.form.getRawValue()).subscribe(response=> {
      
      const result = response.body;
   
      this.coreservice.success("Template saved");
   
     }, (err) => {

      this.coreservice.error(err.error.message);
   
      
   })
  }

}

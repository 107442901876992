import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

import { UACService } from './UAC.service';
@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(private uacservice: UACService, public router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    console.log('state.url', state.url.split('/'));
    return true;
    // let ALLOWED_MODULES = JSON.parse(localStorage.getItem('ALLOWED_MODULES'));
    // if (ALLOWED_MODULES === null) {
    //   this.uacservice.getAllowedModule().subscribe(
    //     (response) => {
    //       const result = response.body;
    //       localStorage.setItem(
    //         'ALLOWED_MODULES',
    //         JSON.stringify(response.body['results'])
    //       );
    //       ALLOWED_MODULES = response.body['results'].filter((module) => {
    //         if (module.module.isMenu === true && module.module.route !== '') {
    //           return true;
    //         } else {
    //           return false;
    //         }
    //       });

    //       if (
    //         ALLOWED_MODULES.find(
    //           (i) =>
    //             i.module.isMenu && i.module.route === state.url.split('/')[1]
    //         )
    //       ) {
    //         return true;
    //       } else {
    //         this.router.navigateByUrl('/');
    //         return false;
    //       }
    //     },
    //     (err) => {
    //       if (err.error.status != null) {
    //       } else {
    //       }
    //     }
    //   );
    // } else {
    //   ALLOWED_MODULES = ALLOWED_MODULES.filter((module) => {
    //     if (module.module.isMenu === true) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   });

    //   if (
    //     ALLOWED_MODULES.find((i) => {
    //       console.log(i.module.route);
    //       let route = i.module.route.replace('/', '');
    //       return i.module.isMenu && route === state.url.split('/')[1];
    //     })
    //   ) {
    //     return true;
    //   } else {
    //     this.router.navigateByUrl('/');
    //     return false;
    //   }
    // }
  }
}

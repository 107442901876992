<div class="content p30">



    <div id="block_container">
        <a  routerLink="/role" id="bloc1" mat-flat-button class="mat-flat-button-transperent" ><span  class="material-icons">keyboard_backspace</span></a>

        <div id="bloc2"><h5>{{resource.message('SETTINGS')}}</h5></div>
    </div>

    <mat-card class="">
      <mat-card-header>
        <mat-card-title> {{resource.message('SETTINGS')}} </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <hr />

        <mat-grid-list cols="3" rowHeight="90px" >
            <span  *ngFor="let setting of SETTINGS_LIST">
            <mat-grid-tile >

                    <div class="text-inside-grid">{{setting.key}}</div>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" >
                <mat-form-field appearance="outline" class="field-spacer">
                    <mat-label>{{setting.key}}</mat-label>
                    <input matInput placeholder="setting.key" [type]="setting.key == 'PASS'? 'password': 'text'" value="{{setting.values}}"  required (blur)="updatevalue(setting._id,setting.key,$event.target.value)">
                  </mat-form-field>
            </mat-grid-tile>
        </span>
        </mat-grid-list>

      </mat-card-content>

    </mat-card>

  <mat-card class="" style="margin-top: 20px;" *ngIf="logged_role=='admin'">
    <mat-card-header>
      <mat-card-title>Grant Permissions</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <hr />

      <div class="menuitemstyle">
        <div>
          <p style="font-size: 20px;">Log In Info   <mat-slide-toggle style="float: right;" (change)="onChange($event)" [(ngModel)]="allowlogs"></mat-slide-toggle> </p>
          <p>
            Allow access to Super admin to view the log in details.
          </p>
          <p>
            The access can be denied if you decide not to provide access to the Super admin to view the details.
          </p>
        </div>
        <hr/>
        <div>
          <p style="font-size: 20px;">Send Messages<mat-slide-toggle style="float: right;" (change)="onPermissionChange($event)" [(ngModel)]="allowmsg"></mat-slide-toggle> </p>
          <p>
            Allow access to Super admin to view to send messages directly to the Family office team / Family members directly.
          </p>
        </div>
      </div>

    </mat-card-content>

  </mat-card>

  </div>

 <div class="content p30"> 

       
 
    <div id="block_container">
        <a  routerLink="/subscriptions" id="bloc1" mat-flat-button class="mat-flat-button-transperent" ><span  class="material-icons">keyboard_backspace</span></a> 
          
        <div id="bloc2"><h5>{{resource.message('SUBSCRIPTION')}} / {{labelText}}</h5></div>
    </div>
    
    <form [formGroup]="form" (ngSubmit)="submit()" >
    <mat-card class="">
      <mat-card-header>            
        <mat-card-title> {{resource.message('SUBSCRIPTION')}} </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <hr />
      
         
          
          
 
          <mat-grid-list cols="1" rowHeight="100px">
            <fieldset disabled="true">
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                  <input type="hidden" formControlName="_id" >
                  <mat-label>Subscription Name</mat-label>
                  <input matInput placeholder="Subscription Name" formControlName="subscription">
                </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile >
            <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Details</mat-label>
                <textarea matInput placeholder="Subscription Details"  formControlName="details"></textarea>
              </mat-form-field>
          </mat-grid-tile>
         <mat-grid-tile >
            <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Cost of the Package</mat-label>
                <input matInput placeholder="Cost of the Package"  formControlName="cost" />
              </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile >
            <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Cycle</mat-label>
                <input matInput placeholder="Cycle of package renewal like Monthly, Yearly.."  formControlName="cycle" />
              </mat-form-field>
          </mat-grid-tile>
        </fieldset>
          <mat-grid-tile >
            <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>No. of allowed Users</mat-label>
                <input matInput placeholder="No. of allowed users"  formControlName="users_allowed" />
              </mat-form-field>
          </mat-grid-tile>
            
          </mat-grid-list>


      </mat-card-content>

      <mat-card-actions align="end">
        <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="submit" [disabled]="form.invalid">Save</button>
          <button mat-raised-button color="clear" class="button-wider"   routerLink="/subscriptions">Cancel</button>
      </mat-card-actions>
      

    </mat-card>
  </form>


  </div>

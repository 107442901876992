import { Component, OnInit } from '@angular/core';
import { language } from '@shared/config/language';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormBuilder,
  FormArray,
  NgForm,
} from '@angular/forms';
import { BusinessInfoService } from '@services/BusinessInfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '@services/CoreService';
import { FileService } from '@services/File.service';
import { BuisnessinfoService } from '@modules/businessinfo/buisnessinfo.service';

@Component({
  selector: 'app-addbusinessunit',
  templateUrl: './addbusinessunit.component.html',
  styleUrls: ['./addbusinessunit.component.css'],
})
export class AddbusinessunitComponent implements OnInit {
  resource = language;
  parent: string = 'child';
  listData = [];
  unitform: FormGroup = new FormGroup(
    {
      _id: new FormControl(0),
      name: new FormControl('', Validators.required),
      established: new FormControl('', Validators.required),
      logo: new FormControl(''),
      about: new FormControl('', Validators.required),
      website: new FormControl('', [
        Validators.required,
        Validators.pattern(
          '((http|https)://)(www.)?' +
            '[a-zA-Z0-9@:%._\\+~#?&//=]' +
            '{2,256}\\.[a-z]' +
            '{2,6}\\b([-a-zA-Z0-9@:%' +
            '._\\+~#?&//=]*)'
        ),
      ]),
      mobile1: new FormControl('', [
        Validators.required,
      ]),
      mobile2: new FormControl('', [
        Validators.required,
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      keyperson: new FormControl('', Validators.required),
      registeredaddress: new FormControl('', Validators.required),
      corporateaddress: new FormControl('', Validators.required),
      address: new FormArray([]),
      type: new FormControl(''),
      leadership_team: new FormArray([]),
      board_of_directors: new FormArray([]),
      file: new FormControl(''),
    },
    this.mustBeDifferent('mobile1', 'mobile2')
  );
  isEdit: Boolean = false;
  isSubmited: Boolean = false;
  selFile = new Object();
  selFileName = '';
  maxDate: Date;
  constructor(
    private businessinfoservice: BusinessInfoService,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private fileService: FileService,
    private router: Router,
    private buisnessInfo: BuisnessinfoService
  ) {
    this.maxDate = new Date();
  }

  ngOnInit(): void {
    console.log('init');
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.isEdit = true;

        this.businessinfoservice
          .businessunitget(data.id)
          .subscribe((response) => {
            const result = response['data'];
            this.unitform.patchValue({
              _id: result['_id'],
              name: result['name'],
              established: result['established'],
              logo: result['logo'],
              about: result['about'],
              website: result['website'],
              mobile1: result['mobile1'],
              mobile2: result['mobile2'],
              email: result['email'],
              keyperson: result['keyperson'] != null ? result['keyperson'] : '',
              registeredaddress: result['address'][1]['address'],
              corporateaddress: result['address'][0]['address'],
              address: [],
              type: result['type'],
              file: '',
            });
          });
      }
    });
  }
  mustBeDifferent(controlName1: string, controlName2: string) {
    return (formGroup: FormGroup) => {
      const control1 = formGroup.controls[controlName1];
      const control2 = formGroup.controls[controlName2];

      if (control2.errors && !control2.errors.areSame) {
        return;
      }

      if (control1.value === control2.value) {
        control2.setErrors({ areSame: true });
      } else {
        control2.setErrors(null);
      }
      return null;
    };
  }
  async submit(): Promise<void> {
    if (this.isSubmited) {
      return;
    }
    if (this.unitform.invalid) {
      return;
    }
    this.isSubmited = true;

    var constdata = this.unitform.getRawValue();
    constdata['address'].push(
      {
        type: 'Corporate Office',
        address: this.unitform.getRawValue().corporateaddress,
      },
      {
        type: 'Registered Office',
        address: this.unitform.getRawValue().registeredaddress,
      }
    );
    if (this.selFileName != '') {
      let fileurl = this.selFileName;
      var formData: any = new FormData();
      formData.append('file', this.selFile);
      fileurl = await this.fileService.uploadFile(formData, true, false);
      constdata['logo'] = fileurl;
    }
    delete constdata['file'];
    if (!this.isEdit) {
      constdata['type'] = 'business_unit';
      delete constdata['_id'];
      this.businessinfoservice.addBusinessData(constdata).subscribe(
        (response) => {
          this.buisnessInfo.selectedTab =  1;
          const result = response.body;
          this.coreservice.success(this.resource.message('BUSINESS_UNIT_SAVE'));
          this.router.navigate(['/businessinfo']);
        },
        (err) => {
          this.isSubmited = false;
        }
      );
    } else {
      delete constdata['leadership_team'];
      delete constdata['board_of_directors'];
      this.businessinfoservice
        .updateBusinessData(constdata, constdata['_id'])
        .subscribe(
          (response) => {
            this.buisnessInfo.selectedTab =  1;
            const result = response.body;
            this.coreservice.success(
              this.resource.message('BUSINESS_UNIT_UPDATED')
            );
            this.router.navigate(['/businessinfo']);
          },
          (err) => {}
        );
    }
  }

  backToMain() {
    this.buisnessInfo.selectedTab = 1;
    this.router.navigate(['/businessinfo']);
  }
  onFileChange(event) {
    this.selFile = (event.target as HTMLInputElement).files[0];
    this.selFileName = (event.target as HTMLInputElement).files[0].name;
  }
  previewimg(eve) {
    window.open(this.unitform.get('logo').value, '_blank');
  }
  clearAll() {
    this.unitform.patchValue({
      _id: 0,
      name: '',
      established: '',
      logo: '',
      about: '',
      website: '',
      mobile1: '',
      mobile2: '',
      email: '',
      keyperson: '',
      registeredaddress: [],
      corporateaddress: [],
      address: [],
      type: '',
      file: '',
    });
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BuisnessinfoService {

  constructor() { }

  selectedTab = 0;
}

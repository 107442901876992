<div class="content p30">
  <h5>
    Committee Meetings / {{ endpointResults?.committee?.title }} /
        {{ endpointResults?.title }}
  </h5>
  <mat-card class="">
    <mat-card-content>
      <span class="devarea">Add Agenda</span>
      <hr />
      <mat-tab-group class="tabgroup">
        <mat-tab label="Agenda" class="test">
          <app-committesuggested    [_s_topics]="s_topics"   [_topics]="topics" (reload)="onReload()" ></app-committesuggested>
        </mat-tab>
        <mat-tab label="Deferred Agenda" class="test">
          <app-agendadeferred   [defered_topics]="defered_topics" (reload)="onReload()" ></app-agendadeferred>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>

import { HttpResponseBase } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@services/UserService';

@Component({
  selector: 'app-password-recovery-by-email',
  templateUrl: './password-recovery-by-email.component.html',
  styleUrls: ['./password-recovery-by-email.component.css'],
})
export class PasswordRecoveryByEmailComponent implements OnInit {
  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  errorMessage = '';
  emailExists = false;
  constructor(private userService: UserService) {}

  ngOnInit(): void {}
  submit() {
    if (this.form.invalid) {
      return;
    }
    this.userService.PasswordRecoveryByEmail(this.form.getRawValue()).subscribe(
      (response) => {
        if (response.status === 200) {
          this.emailExists = true;
        }
      },
      (error) => {
        console.log(error);

        if (error['error']) {
          console.log('in condition  ', error);
          this.errorMessage = error['error'].message;
        }
      }
    );
  }
}

import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { TaskElement } from '@modules/personaltask/personaltask.component';
import { CoreService } from '@services/CoreService';
import { PersonalTasksService } from '@services/PesonalTask.service';

@Component({
  selector: 'app-my-tasks-card',
  templateUrl: './my-tasks-card.component.html',
  styleUrls: ['./my-tasks-card.component.css'],
})
export class MyTasksCardComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;

  ELEMENT_DATA: TaskElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = ['start_date', 'end_date', 'title'];
  listData = new MatTableDataSource<TaskElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<TaskElement>(true, []);

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<TaskElement>;
  total = 0;

  constructor(
    private taskService: PersonalTasksService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private http: HttpClient,
    private coreservice: CoreService,
    private datePipe: DatePipe
  ) {
    this.listData = new MatTableDataSource<TaskElement>(this.ELEMENT_DATA);
  }

  ngOnInit(): void {
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllTasks();
    this.table.renderRows();
  }

  getAllTasks() {
    this.taskService.getTasks().subscribe(
      (response) => {
        const result = response.body;
        this.total = response.body['data']?.length;
        this.listData.data = response.body['data'].slice(0, 5) as TaskElement[];

        this.table.renderRows();
      },
      (err) => {
        this.getAllTasks();
      }
    );
  }

  stoppro($event) {
    $event.stopPropagation();
  }
  viewMore() {
    this.router.navigate(['personaltask']);
  }
  addTask() {
    this.router.navigate(['personaltask/add']);
  }
}

<!-- <app-leftmenu #drawer></app-leftmenu>  -->

<div class="content p30">
  <h5>{{ resource.message("USERS") }}</h5>

  <!--
    <mat-grid-list cols="2" rowHeight="300px">
      <mat-grid-tile colspan=2 >
        <mat-card class="example-card">
          <mat-card-header>            
            <mat-card-title><i class="fa fa-file-text-o"></i> Dashboard Tile </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
            </p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>  
  -->

  <mat-card class="">
    <mat-card-header>
      <mat-card-title> {{ resource.message("USERS") }} </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="form-inline">
        <div class="form-group mr-2">
          <button
            mat-stroked-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
            class="filtersortborder"
          >
            <i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;Filter
          </button>
          <mat-menu #menu="matMenu" class="menustyle">
            <span style="color: #b4b1b1">Filter By</span>
            <div class="menuitemstyle">
              <p>Role</p>
              <!--            <mat-checkbox class="mat-menu-item" (change)="filterData($event,'role',item.role)" (click)="stoppro($event)" *ngFor="let item of this.listData.data; index as i; first as isFirst"> {{item.role}} </mat-checkbox> -->
              <mat-checkbox
                class="mat-menu-item"
                (change)="filterData($event, 'role', item.role)"
                (click)="stoppro($event)"
                *ngFor="let item of ROLES_LIST; index as i; first as isFirst"
              >
                {{ item.role }}
              </mat-checkbox>
              <!--            <mat-checkbox class="mat-menu-item" (click)="filterData($event,'Role','Family member')"> Advisor</mat-checkbox>  -->

              <hr class="hrclass" />
              <p>Status</p>
              <mat-checkbox
                class="mat-menu-item"
                (change)="filterData($event, 'status', 'active')"
                (click)="stoppro($event)"
                >Active</mat-checkbox
              >
              <mat-checkbox
                class="mat-menu-item"
                (change)="filterData($event, 'status', 'inactive')"
                (click)="stoppro($event)"
                >Inactive</mat-checkbox
              >
              <mat-checkbox
                class="mat-menu-item"
                (change)="filterData($event, 'status', 'suspended')"
                (click)="stoppro($event)"
                >Suspended</mat-checkbox
              >

              <hr />

              <button mat-raised-button color="primary" (click)="closeMyMenu()">
                Apply
              </button>
            </div>
          </mat-menu>
          <!-- <button mat-stroked-button class="filtersortborder"><i class="material-icons">sort</i>&nbsp;&nbsp;Sort By</button>
         -->

          <button
            mat-stroked-button
            class="iconcss"
            (click)="convertToPDF('save')"
          >
            <i class="fa fa-file-pdf fa-lg"></i>
          </button>
          <button
            mat-stroked-button
            class="iconcss"
            (click)="exporter.exportTable('csv', { fileName: 'users' })"
          >
            <i class="fa fa-file-excel fa-lg"></i>
          </button>
          <button
            (click)="convertToPDF('print')"
            mat-stroked-button
            class="iconcss"
          >
            <i class="fa fa-print fa-lg"></i>
          </button>
        </div>
        <span class="example-spacer"></span>
        <div class="mr-1">
          <button
            [disabled]="!PERMISSION_ADD"
            mat-raised-button
            color="primary"
            class="addbutton"
            class="buttonfloatright"
            (click)="addrole()"
          >
            <i class="fas fa-plus"></i>&nbsp;&nbsp;ADD
            {{ resource.message("USER") | uppercase }}
          </button>
          <input
            matInput
            [(ngModel)]="searchKey"
            placeholder="{{ resource.message('SEARCH_BY_USER') }}"
            autocomplete="off"
            (keyup)="applyfilter()"
            (keydown.enter)="$event.preventDefault()"
            name="searchKey"
            class="searchfloatright"
          />
        </div>
      </form>
      <p>
        <br />
        <mat-table
          #roleTable
          roleTable
          matTableExporter
          [dataSource]="listData"
          matSort
          class="mattable"
          #exporter="matTableExporter"
          [hiddenColumns]="[5, 6]"
        >
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Profile Name</mat-header-cell
            >
            <mat-cell *matCellDef="let element"
              >{{ element.name }} {{ element.lastname }}</mat-cell
            >
          </ng-container>

          <!-- Mobile Column -->
          <ng-container matColumnDef="mobile">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Mobile No</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.mobile }}</mat-cell>
          </ng-container>

          <!-- Email Column -->
          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Email ID</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
          </ng-container>

          <!-- Role Column -->
          <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Role</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.role }}</mat-cell>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <mat-header-cell
              style="flex-grow: 0; flex-basis: 260px"
              *matHeaderCellDef
              mat-sort-header
              >Status</mat-header-cell
            >
            <mat-cell
              style="flex-grow: 0; flex-basis: 260px"
              *matCellDef="let element"
              ><span
                [ngClass]="
                  element.status == 'active'
                    ? 'status-active'
                    : 'status-inactive' || element.status == 'Suspended'
                    ? 'status-inactive'
                    : ''
                "
                >{{ element.status | titlecase }}</span
              ></mat-cell
            >
          </ng-container>

          <!-- System Column -->

          <!-- Action Column -->
          <ng-container matColumnDef="Action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button
                mat-icon-button
                (click)="edit(row)"
                [disabled]="!PERMISSION_EDIT"
              >
                <mat-icon>mode_edit</mat-icon>
              </button>
              <!--   <button mat-icon-button color="warn"  (click)="onDelete(row._id)"   ><mat-icon>delete_outline</mat-icon></button>-->
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 15, 20]"
          [pageSize]="5"
          showFirstLastButtons
        ></mat-paginator>
      </p>
    </mat-card-content>
  </mat-card>
</div>

<div align="right">
    <div  >
      <mat-card class="cardclass">
        <mat-card-header>            
           <mat-card-title> {{resource.message('CATEGORY')}} </mat-card-title>
           <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
        </mat-card-header>
        <mat-card-content><br />
            <form [formGroup]="form" (ngSubmit)="saveCategory()">
                    <mat-grid-list cols="1" rowHeight="80px" gutterSize="16px" >
                          <mat-grid-tile >
                              <mat-form-field appearance="outline" class="field-spacer">
                                  <mat-label>Category</mat-label>
                                  <input matInput placeholder="Category" formControlName="title">
                              </mat-form-field>
                          </mat-grid-tile>

                          <mat-grid-tile >
                            <mat-form-field appearance="outline" class="field-spacer">
                                <mat-label>Question (Display Message)</mat-label>
                                <input matInput placeholder="Question" formControlName="display_message">
                            </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile >
                            <mat-form-field appearance="outline" class="field-spacer">
                                <mat-label>Icon</mat-label>
                                <input matInput placeholder="Icon Url" formControlName="icon">
                            </mat-form-field>
                        </mat-grid-tile>
                          
                          
                    </mat-grid-list>
                  <mat-card-actions align="end">
                      <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="submit" [disabled]="!this.form.valid" >Save</button>
                     
                  </mat-card-actions>
              </form>
            
        </mat-card-content>
        </mat-card>  
       
    </div>
    
</div>

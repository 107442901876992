<div class="content p30">
  <div id="block_container">
    <a (click)="onbackUrlClick()"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >

    <div id="bloc2">
      <h5>IDP Meeting / Details / Add Goal</h5>
    </div>
  </div>

  <form [formGroup]="form" (ngSubmit)="submit()">
    <fieldset>
      <!--[disabled]=" (familyname!=select_user_family) ? isDisabled :''"-->
      <mat-card class="">
        <mat-card-content>
          <div class="row">
            <div class="col-sm-12">
              <div class="col-sm-12">
                <span class="devarea">Add Development Area </span>
                <hr />
              </div>
            </div>
          </div>

          <mat-grid-list cols="2" rowHeight="100px">
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Category</mat-label>
                <mat-select
                  placeholder="Category"
                  formControlName="category"
                  (selectionChange)="subcategoryGet($event.value)"
                >
                  <mat-option disabled="true" class="category-select-filter">
                    <input type="text" class="form-control" placeholder="Search By Category" #categoryFilterInput (keyup)="filterCategories()">
                  </mat-option>

                  <mat-option *ngFor="let category of CATEGORY"  [value]="category._id" >
                    <mat-option-text *ngIf="form?.controls['category']?.value === category._id">
                      {{ category.title }} <i class="fa fa-check text-success selected-check-mark"></i>
                    </mat-option-text>
                    <mat-option-text *ngIf="form?.controls['category']?.value != category._id">
                      {{ category.title }}
                    </mat-option-text>
                  </mat-option>
                </mat-select>
                <mat-error>This field is mandatory.</mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field
                appearance="outline"
                class="field-spacer"
                *ngIf="showsub"
              >
                <mat-label>Sub-category</mat-label>
                <mat-select
                  placeholder="Sub-Category"
                  formControlName="subcategory"
                >
                  <mat-option
                    *ngFor="let subcategory of SUB_CATEGORY"
                    [value]="subcategory._id"
                    >{{ subcategory.title }}</mat-option
                  >
                </mat-select>
                <mat-error>This field is mandatory.</mat-error>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

          <br />
          <div class="row">
            <div class="col-sm-12">
              <div class="col-sm-12">
                <span class="devarea">Add Goals </span>
                <hr />
              </div>
            </div>
          </div>
          <ng-container formArrayName="goal_name">
          <mat-grid-list cols="10" rowHeight="80px" 
          *ngFor="let item of goal_name.controls; let pointIndex = index"
          [formGroupName]="pointIndex">
            <mat-grid-tile colspan="6">
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Goal</mat-label>
                  <input matInput placeholder="Goal" formControlName="goal" />
                </mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile colspan="6">
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Goal Date</mat-label>
                  <input
                    matInput
                    [matDatepicker]="dp3"
                    disabled
                    formControlName="goal_date"
                    placeholder="Goal Date"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dp3"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                </mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile colspan="6">
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Supporters</mat-label>
                  <mat-select
                    placeholder="Social"
                    formControlName="supporters"
                    multiple
                  >
                    <mat-option
                      *ngFor="let social of SUPPORTERS"
                      [value]="social._id"
                      >{{ social.name }}</mat-option
                    >
                  </mat-select>
                  <mat-error>This field is mandatory.</mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile style="padding: 0px">
                <button
                  style="height: 80%; width: 50%"
                  mat-button
                  type="button"
                  color="warn"
                  appearance="outline"
                  class="field-spacer"
                  (click)="deleteSocial(pointIndex)"
                  *ngIf="pointIndex != goal_name.controls.length - 1"
                >
                  <span
                    class="material-icons"
                    inline="true"
                    style="transform: scale(1.5)"
                    >delete_outline</span
                  >
                </button>
                <button
                  style="height: 80%; width: 50%"
                  mat-button
                  type="button"
                  color="primary"
                  appearance="outline"
                  class="field-spacer"
                  (click)="addSocial()"
                  *ngIf="pointIndex == goal_name.controls.length - 1"
                >
                  <span
                    class="material-icons"
                    inline="true"
                    style="transform: scale(1.5)"
                    >note_add</span
                  >
                </button>
              </mat-grid-tile>
         
          </mat-grid-list>
        </ng-container>
          <br />
        </mat-card-content>

        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="submit"
            [disabled]="form.invalid"
          >
            Save
          </button>
          <button
            mat-raised-button
            color="clear"
            class="button-wider"
            (click)="onbackUrlClick()"
          >
            Cancel
          </button>
        </mat-card-actions>
      </mat-card>
    </fieldset>
  </form>
</div>

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpClient,
  HttpClientModule,
  HttpHandler,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { LoginComponent } from './modules/login/login.component';
import { PersonaltaskComponent } from './modules/personaltask/personaltask.component';
import { HeaderComponent } from './shared/header/header.component';
import { ChangePasswordComponent } from './modules/change-password/change-password.component';
import { FamilyInfoComponent } from './modules/family-info/family-info.component';
import { PlaygroundComponent } from './modules/playground/playground.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormControl, Validators } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  DateAdapter,
  MatNativeDateModule,
  MatRippleModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { ForgetComponent } from './modules/forget/forget.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatGridListModule } from '@angular/material/grid-list';
import { LeftmenuComponent } from './shared/components/leftmenu/leftmenu.component';
import { RoleComponent } from './modules/uac/role/role.component';
import { AddroleComponent } from './modules/uac/role/addrole/addrole.component';
import { DialogdeleteComponent } from './shared/components/dialogdelete/dialogdelete.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ModuleComponent } from './modules/uac/module/module.component';
import { AddmoduleComponent } from './modules/uac/module/addmodule/addmodule.component';
import { RolepermissionComponent } from './modules/uac/rolepermission/rolepermission.component';
import { UserComponent } from './modules/user/user.component';
import { AdduserComponent } from './modules/user/adduser/adduser.component';
import { DialogphotoComponent } from './shared/components/dialogphoto/dialogphoto.component';
import { FamiliesComponent } from './modules/families/families.component';
import { AddfamilyComponent } from './modules/families/addfamily/addfamily/addfamily.component';
import { AdminknowledgehubComponent } from './modules/adminknowledgehub/adminknowledgehub.component';
import { AddknowledgehubComponent } from './modules/adminknowledgehub/addknowledgehub/addknowledgehub.component';
import { SubscriptionComponent } from './modules/subscription/subscription.component';
import { AddsubscriptionComponent } from './modules/subscription/addsubscription/addsubscription.component';
import { SecuritylogsComponent } from './modules/securitylogs/securitylogs.component';
import { DetailsComponent } from './modules/securitylogs/details/details.component';
import { AuthGuardService as AuthGuard } from './shared/services/Auth-guard.service';
import { DialogmediaComponent } from './shared/components/dialogmedia/dialogmedia.component';
import { AddmemberComponent } from './modules/family-info/addmember/addmember.component';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { ForgotidComponent } from './modules/forget/forgotid/forgotid.component';
import { TemplatemanagementComponent } from './modules/templatemanagement/templatemanagement.component';
import { CommitteetemplateComponent } from './modules/templatemanagement/committeetemplate/committeetemplate.component';
import { OnboardingtemplateComponent } from './modules/templatemanagement/onboardingtemplate/onboardingtemplate.component';
import { OthertemplateComponent } from './modules/templatemanagement/othertemplate/othertemplate.component';
import { KnowledgehubComponent } from './modules/knowledgehub/knowledgehub.component';
import { CategoryComponent } from './modules/knowledgehub/category/category.component';
import { MysubscriptionComponent } from './modules/mysubscription/mysubscription.component';
import { NgxPrintModule } from 'ngx-print';
import { AddpersonaltaskComponent } from './modules/personaltask/addpersonaltask/addpersonaltask.component';
import { MembershipComponent } from './modules/membership/membership.component';
import { AddmembershipComponent } from './modules/membership/addmembership/addmembership.component';
import { DeclarationComponent } from './modules/declaration/declaration.component';
import { AdddeclarationComponent } from './modules/declaration/adddeclaration/adddeclaration.component';
import { CommitteetabsComponent } from './modules/committeetabs/committeetabs.component';
import { CommitteeComponent } from './modules/committeetabs/committee/committee.component';
import { SendotpComponent } from './modules/forget/sendotp/sendotp.component';
import { BusinessinfoComponent } from './modules/businessinfo/businessinfo.component';
import { ParentcompanyComponent } from './modules/businessinfo/parentcompany/parentcompany.component';
import { BusinessunitComponent } from './modules/businessinfo/businessunit/businessunit.component';
import { AddbusinessunitComponent } from './modules/businessinfo/businessunit/addbusinessunit/addbusinessunit.component';
import { LeadershipComponent } from './modules/businessinfo/leadership/leadership.component';
import { DialogleadershipteamComponent } from './shared/components/dialogleadershipteam/dialogleadershipteam.component';
import { BoarddirectorsComponent } from './modules/businessinfo/boarddirectors/boarddirectors.component';
import { DialogboarddirectorsteamComponent } from './shared/components/dialogboarddirectorsteam/dialogboarddirectorsteam.component';
import { KeyinfoComponent } from './modules/businessinfo/keyinfo/keyinfo.component';
import { CommitteeS1Component } from './modules/committeetabs/addcommittee/committee-s1/committee-s1.component';
import { CommitteeS2Component } from './modules/committeetabs/addcommittee/committee-s2/committee-s2.component';
import { CommitteeS3Component } from './modules/committeetabs/addcommittee/committee-s3/committee-s3.component';
import { CommitteeS4Component } from './modules/committeetabs/addcommittee/committee-s4/committee-s4.component';
import { CommitteeS5Component } from './modules/committeetabs/addcommittee/committee-s5/committee-s5.component';
import { CommitteeS6Component } from './modules/committeetabs/addcommittee/committee-s6/committee-s6.component';
import { CommitteeS7Component } from './modules/committeetabs/addcommittee/committee-s7/committee-s7.component';
import { StepperComponent } from './modules/committeetabs/addcommittee/stepper/stepper.component';
import { AddcommitteemembersComponent } from './modules/committeetabs/addcommittee/committee-s3/addcommitteemembers/addcommitteemembers.component';
import { CommonspacetabsComponent } from './modules/commonspacetabs/commonspacetabs.component';
import { CommonspacecategoryComponent } from './modules/commonspacetabs/commonspacecategory/commonspacecategory.component';
import { CommonspacesComponent } from './modules/commonspacetabs/commonspaces/commonspaces.component';
import { AddcommonspaceComponent } from './modules/commonspacetabs/commonspaces/addcommonspace/addcommonspace.component';
import { AchievementsComponent } from './modules/achievements/achievements.component';
import { LevelsComponent } from './modules/achievements/levels/levels.component';
import { TriggerpointsComponent } from './modules/achievements/triggerpoints/triggerpoints.component';
import { ActivitylogsComponent } from './modules/achievements/activitylogs/activitylogs.component';
import { ListComponent } from './modules/adminknowledgehub/list/list.component';
import { FafilesComponent } from './modules/knowledgehub/fafiles/fafiles.component';
import { SafilesComponent } from './modules/knowledgehub/safiles/safiles.component';
import { SettingsComponent } from './modules/settings/settings.component';
import { PollsComponent } from './modules/polls/polls.component';
import { AddpollsComponent } from './modules/polls/addpolls/addpolls.component';
import { BusinessmediaComponent } from './modules/businessinfo/businessmedia/businessmedia.component';
import { BusinessimageComponent } from './modules/businessinfo/businessimage/businessimage.component';
import { DialogbusinessimageComponent } from './shared/components/dialogbusinessimage/dialogbusinessimage.component';
import { DialogbusinessmediaComponent } from './shared/components/dialogbusinessmedia/dialogbusinessmedia.component';
import { BoardreadsComponent } from './modules/businessinfo/keyinfo/boardreads/boardreads.component';
import { FinancialreportsComponent } from './modules/businessinfo/keyinfo/financialreports/financialreports.component';
import { ExecutivesummaryComponent } from './modules/businessinfo/keyinfo/executivesummary/executivesummary.component';
import { OtherreportsComponent } from './modules/businessinfo/keyinfo/otherreports/otherreports.component';
import { DialogfinancialreportsComponent } from './shared/components/dialogfinancialreports/dialogfinancialreports.component';
import { DialogexecutivesummaryComponent } from './shared/components/dialogexecutivesummary/dialogexecutivesummary.component';
import { DialogothersreportComponent } from './shared/components/dialogothersreport/dialogothersreport.component';
import { DialogboardreadsComponent } from './shared/components/dialogboardreads/dialogboardreads.component';
import { NotificationComponent } from './modules/notification/notification.component';
import { DialognotificationComponent } from './shared/components/dialognotification/dialognotification.component';
import { CommonspacebookingComponent } from './modules/commonspacetabs/commonspacebooking/commonspacebooking.component';
import { AddbookingComponent } from './modules/commonspacetabs/commonspacebooking/addbooking/addbooking.component';
import { ViewcalendarComponent } from './modules/commonspacetabs/commonspacebooking/viewcalendar/viewcalendar.component';
import { FamilyofficeComponent } from './modules/familyoffice/familyoffice.component';
import { TicketComponent } from './modules/familyoffice/ticket/ticket.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import { FirstsectionComponent } from './modules/familyoffice/ticket/addticket/firstsection/firstsection.component';
import { SecondsectionComponent } from './modules/familyoffice/ticket/addticket/secondsection/secondsection.component';
import { ThirdsectionComponent } from './modules/familyoffice/ticket/addticket/thirdsection/thirdsection.component';
import { DialogcalendarComponent } from './shared/components/dialogcalendar/dialogcalendar.component';
import { IdpComponent } from './modules/idp/idp.component';
import { MeetingComponent } from './modules/idp/meeting/meeting.component';
import { GoalComponent } from './modules/idp/goal/goal.component';
import { AddgoalComponent } from './modules/idp/goal/addgoal/addgoal.component';
import { IdplistingComponent } from './modules/idp/idplisting/idplisting.component';
import { IdpcategoryComponent } from './modules/idp/idpcategory/idpcategory.component';
import { OthermessagetemplateComponent } from './modules/othermessagetemplate/othermessagetemplate.component';
import { EmailtemplateComponent } from './modules/othermessagetemplate/emailtemplate/emailtemplate.component';
import { SmstemplateComponent } from './modules/othermessagetemplate/smstemplate/smstemplate.component';
import { WhatsapptemplateComponent } from './modules/othermessagetemplate/whatsapptemplate/whatsapptemplate.component';
import { PublishtemplateComponent } from './modules/othermessagetemplate/publishtemplate/publishtemplate.component';
import { OthermeetingsComponent } from './modules/othermeetings/othermeetings.component';
import { AddothermeetingsComponent } from './modules/othermeetings/addothermeetings/addothermeetings.component';
import { DialogparticipantsComponent } from './shared/components/dialogparticipants/dialogparticipants.component';
import { DialoggoalcategoryComponent } from './shared/components/dialoggoalcategory/dialoggoalcategory.component';
import { IdpdetailsComponent } from './modules/idp/idpdetails/idpdetails.component';
import { SchedulemeetingComponent } from './modules/idp/meeting/schedulemeeting/schedulemeeting.component';
import { DialogmeetingdataComponent } from './shared/components/dialogmeetingdata/dialogmeetingdata.component';
import { CreatemeetingComponent } from './modules/idp/meeting/createmeeting/createmeeting.component';
import { DialogmeetingparticipantsComponent } from './shared/components/dialogmeetingparticipants/dialogmeetingparticipants.component';
import { DialogemailinviteComponent } from './shared/components/dialogemailinvite/dialogemailinvite.component';
import { DialoginternalparticipantsComponent } from './shared/components/dialoginternalparticipants/dialoginternalparticipants.component';
import { MeetingagendaComponent } from './modules/idp/meeting/meetingagenda/meetingagenda.component';
import { DialoginviteesComponent } from './shared/components/dialoginvitees/dialoginvitees.component';
import { RationaliseagendaComponent } from './modules/idp/meeting/rationaliseagenda/rationaliseagenda.component';
import { NewagendaComponent } from './modules/idp/meeting/newagenda/newagenda.component';
import { SuggestedagendaComponent } from './modules/idp/meeting/suggestedagenda/suggestedagenda.component';
import { DialogaddnoteComponent } from './shared/components/dialogaddnote/dialogaddnote.component';
import { MeetingnoteComponent } from './modules/idp/meeting/meetingnote/meetingnote.component';
import { TakeattendenceComponent } from './modules/idp/meeting/takeattendence/takeattendence.component';
import { AddoutcomeComponent } from './modules/idp/meeting/addoutcome/addoutcome.component';
import { NewoutcomeComponent } from './modules/idp/meeting/newoutcome/newoutcome/newoutcome.component';
import { OutcometodoComponent } from './modules/idp/meeting/newoutcome/outcometodo/outcometodo.component';
import { OutcometabComponent } from './modules/idp/meeting/newoutcome/outcometab/outcometab.component';
import { ReportsComponent } from './modules/reports/reports.component';
import { ReportlistComponent } from './modules/reports/reportlist/reportlist.component'; // a plugin!
import { ReportCategoryComponent } from './modules/reports/category/reportcategory.component';
import { AddcategoryComponent } from './modules/reports/category/addcategory/addcategory.component';
import { DialogaddreportcategoryComponent } from './shared/components/dialogaddreportcategory/dialogaddreportcategory.component';
import { AddreportComponent } from './modules/reports/reportlist/addreport/addreport.component';
import { MinuteofmeetingComponent } from './modules/idp/meeting/minuteofmeeting/minuteofmeeting.component';
import { DialogviewattendanceComponent } from './shared/components/dialogviewattendance/dialogviewattendance.component';
import { DialogcancelnoteComponent } from './shared/components/dialogcancelnote/dialogcancelnote.component';
import { EditgoalComponent } from './modules/idp/goal/editgoal/editgoal.component';
import { DialogstatusgoalComponent } from './shared/components/dialogstatusgoal/dialogstatusgoal.component';
import { GoalprogressComponent } from './modules/idp/goal/goalprogress/goalprogress.component';
import { DialogremarkComponent } from './shared/components/dialogremark/dialogremark.component';
import { CommitteemeetingtabComponent } from './modules/CommitteeMeeting/committeemeetingtab/committeemeetingtab.component';
import { CommitteemeetingsComponent } from './modules/CommitteeMeeting/committeemeetings/committeemeetings.component';
import { MinutesComponent } from './modules/CommitteeMeeting/minutes/minutes.component';
import { CreatecommitteemeetingComponent } from './modules/CommitteeMeeting/createcommitteemeeting/createcommitteemeeting.component';
import { MeetingParticipantsComponent } from './modules/CommitteeMeeting/meeting-participants/meeting-participants.component';
import { DialogtemporaryinviteesComponent } from './shared/components/dialogtemporaryinvitees/dialogtemporaryinvitees.component';
import { CommitteeagendaComponent } from './modules/CommitteeMeeting/committeeagenda/committeeagenda.component';
import { AgendatabComponent } from './modules/CommitteeMeeting/agendatab/agendatab.component';
import { AgendadeferredComponent } from './modules/CommitteeMeeting/agendadeferred/agendadeferred.component';
import { DialogcommiteeagendaComponent } from './shared/components/dialogcommiteeagenda/dialogcommiteeagenda.component';
import { CommitteratonalizeComponent } from './modules/CommitteeMeeting/committeratonalize/committeratonalize.component';
import { CommitteemomComponent } from './modules/CommitteeMeeting/committeemom/committeemom.component';
import { CommittesuggestedComponent } from './modules/CommitteeMeeting/committesuggested/committesuggested.component';
import { CommittesuggestedtabComponent } from './modules/CommitteeMeeting/committesuggestedtab/committesuggestedtab.component';
import { CommitteemeetingrationalizedComponent } from './modules/CommitteeMeeting/committeemeetingrationalized/committeemeetingrationalized.component';
import { CommitteeattendenceComponent } from './modules/CommitteeMeeting/committeeattendence/committeeattendence.component';
import { DialogattendenceComponent } from './shared/components/dialogattendence/dialogattendence.component';
import { CommitteeresolutionComponent } from './modules/CommitteeMeeting/committeeresolution/committeeresolution.component';
import { DialogattendanceqorumComponent } from './shared/components/dialogattendanceqorum/dialogattendanceqorum.component';
import { CommiteeoutcometabComponent } from './modules/CommitteeMeeting/commiteeoutcome/commiteeoutcometab/commiteeoutcometab.component';
import { CommiteevotesComponent } from './modules/CommitteeMeeting/commiteeoutcome/commiteevotes/commiteevotes.component';
import { CommiteemeetingoutcomeComponent } from './modules/CommitteeMeeting/commiteeoutcome/commiteemeetingoutcome/commiteemeetingoutcome.component';
import { CommiteetaskComponent } from './modules/CommitteeMeeting/commiteeoutcome/commiteetask/commiteetask.component';
import { DialogoverrideComponent } from './shared/components/dialogoverride/dialogoverride.component';
import { DialogcommitteeparticipantsComponent } from './shared/components/dialogcommitteeparticipants/dialogcommitteeparticipants.component';
import { PasswordRecoveryByEmailComponent } from './modules/forget/password-recovery-by-email/password-recovery-by-email.component';
import { PasswordResetTokenComponent } from './modules/forget/password-reset-token/password-reset-token.component';
import { FamilyOfficeCardComponent } from './modules/dashboard/family-office-card/family-office-card.component';
import { MeetingsCardComponent } from './modules/dashboard/meetings-card/meetings-card.component';
import { PollsCardComponent } from './modules/dashboard/polls-card/polls-card.component';
import { MyTasksCardComponent } from './modules/dashboard/my-tasks-card/my-tasks-card.component'; // a plugin!
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CustomDatePipe } from '@core/custom.datepipe';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { RoleGuardService } from '@services/Role-guard.service';
import { LoginGuardService } from '@services/Login-guard.service';
import { DialogViewInviteeListComponent } from './shared/components/dialog-view-invitee-list/dialog-view-invitee-list.component';

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
]);

@NgModule({
  declarations: [
    PasswordRecoveryByEmailComponent,
    AppComponent,
    DashboardComponent,
    LoginComponent,
    HeaderComponent,
    PersonaltaskComponent,
    FamilyInfoComponent,
    ChangePasswordComponent,
    PlaygroundComponent,
    ForgetComponent,
    LeftmenuComponent,
    RoleComponent,
    AddroleComponent,
    DialogdeleteComponent,
    ModuleComponent,
    AddmoduleComponent,
    RolepermissionComponent,
    UserComponent,
    AdduserComponent,
    DialogphotoComponent,
    FamiliesComponent,
    AddfamilyComponent,
    AdminknowledgehubComponent,
    AddknowledgehubComponent,
    SubscriptionComponent,
    AddsubscriptionComponent,
    SecuritylogsComponent,
    DetailsComponent,
    DialogmediaComponent,
    AddmemberComponent,

    ForgotidComponent,
    TemplatemanagementComponent,
    CommitteetemplateComponent,
    OnboardingtemplateComponent,
    OthertemplateComponent,
    KnowledgehubComponent,
    CategoryComponent,
    MysubscriptionComponent,
    AddpersonaltaskComponent,
    MembershipComponent,
    AddmembershipComponent,
    DeclarationComponent,
    AdddeclarationComponent,
    CommitteetabsComponent,
    CommitteeComponent,
    SendotpComponent,
    BusinessinfoComponent,
    ParentcompanyComponent,
    BusinessunitComponent,
    AddbusinessunitComponent,
    LeadershipComponent,
    DialogleadershipteamComponent,
    BoarddirectorsComponent,
    DialogboarddirectorsteamComponent,
    KeyinfoComponent,
    CommitteeS1Component,
    CommitteeS2Component,
    CommitteeS3Component,
    CommitteeS4Component,
    CommitteeS5Component,
    CommitteeS6Component,
    CommitteeS7Component,
    StepperComponent,
    AddcommitteemembersComponent,
    CommonspacetabsComponent,
    CommonspacecategoryComponent,
    CommonspacesComponent,
    AddcommonspaceComponent,
    AchievementsComponent,
    LevelsComponent,
    TriggerpointsComponent,
    ActivitylogsComponent,
    ListComponent,
    FafilesComponent,
    SafilesComponent,
    SettingsComponent,
    PollsComponent,
    AddpollsComponent,
    BusinessmediaComponent,
    BusinessimageComponent,
    DialogbusinessimageComponent,
    DialogbusinessmediaComponent,
    BoardreadsComponent,
    FinancialreportsComponent,
    ExecutivesummaryComponent,
    OtherreportsComponent,
    DialogfinancialreportsComponent,
    DialogexecutivesummaryComponent,
    DialogothersreportComponent,
    DialogboardreadsComponent,
    NotificationComponent,
    DialognotificationComponent,
    CommonspacebookingComponent,
    AddbookingComponent,
    ViewcalendarComponent,
    FamilyofficeComponent,
    TicketComponent,
    FirstsectionComponent,
    SecondsectionComponent,
    ThirdsectionComponent,
    DialogcalendarComponent,
    IdpComponent,
    MeetingComponent,
    GoalComponent,
    AddgoalComponent,
    IdplistingComponent,
    IdpcategoryComponent,
    OthermessagetemplateComponent,
    EmailtemplateComponent,
    SmstemplateComponent,
    WhatsapptemplateComponent,
    PublishtemplateComponent,
    OthermeetingsComponent,
    AddothermeetingsComponent,
    DialogparticipantsComponent,
    DialoggoalcategoryComponent,
    IdpdetailsComponent,
    SchedulemeetingComponent,
    DialogmeetingdataComponent,
    CreatemeetingComponent,
    DialogmeetingparticipantsComponent,
    DialogemailinviteComponent,
    DialoginternalparticipantsComponent,
    MeetingagendaComponent,
    DialoginviteesComponent,
    RationaliseagendaComponent,
    NewagendaComponent,
    SuggestedagendaComponent,
    DialogaddnoteComponent,
    MeetingnoteComponent,
    TakeattendenceComponent,
    AddoutcomeComponent,
    NewoutcomeComponent,
    OutcometodoComponent,
    OutcometabComponent,
    ReportsComponent,
    ReportlistComponent,
    ReportCategoryComponent,
    AddcategoryComponent,
    DialogaddreportcategoryComponent,
    AddreportComponent,
    MinuteofmeetingComponent,
    DialogviewattendanceComponent,
    DialogcancelnoteComponent,
    EditgoalComponent,
    DialogstatusgoalComponent,
    GoalprogressComponent,
    DialogremarkComponent,
    CommitteemeetingtabComponent,
    CommitteemeetingsComponent,
    MinutesComponent,
    CreatecommitteemeetingComponent,
    MeetingParticipantsComponent,
    DialogtemporaryinviteesComponent,
    CommitteeagendaComponent,
    AgendatabComponent,
    AgendadeferredComponent,
    DialogcommiteeagendaComponent,
    CommitteratonalizeComponent,
    CommitteemomComponent,
    CommittesuggestedComponent,
    CommittesuggestedtabComponent,
    CommitteemeetingrationalizedComponent,
    CommitteeattendenceComponent,
    DialogattendenceComponent,
    CommitteeresolutionComponent,
    DialogattendanceqorumComponent,
    CommiteeoutcometabComponent,
    CommiteevotesComponent,
    CommiteemeetingoutcomeComponent,
    CommiteetaskComponent,
    DialogoverrideComponent,
    DialogcommitteeparticipantsComponent,
    PasswordRecoveryByEmailComponent,
    PasswordResetTokenComponent,
    FamilyOfficeCardComponent,
    MeetingsCardComponent,
    PollsCardComponent,
    MyTasksCardComponent,
    CustomDatePipe,
    DialogViewInviteeListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTableExporterModule,
    MatButtonModule,
    MatTableModule,
    MatTabsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatRadioModule,
    MatCardModule,
    MatGridListModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatSliderModule,
    MatSnackBarModule,
    MatDialogModule,
    MatDatepickerModule,

    CdkTableModule,
    MatGridListModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatFormFieldModule,
    MatTabsModule,
    MatTooltipModule,
    PortalModule,
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    NgxPrintModule,
    MatButtonToggleModule,
    MatChipsModule,
    FullCalendarModule,
    NgxMatTimepickerModule,

    NgxMatIntlTelInputModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    AuthGuard,
    LoginGuardService,
    RoleGuardService,
    DatePipe,
    CustomDatePipe,
    TitleCasePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'LL',
        },
        display: {
          dateInput:
            localStorage.getItem('DATE_FORMAT')?.toUpperCase() || 'DD-MMM-YYYY',
          monthYearLabel: 'YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'YYYY',
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

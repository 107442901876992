<div class="content p30">
  <div id="block_container">
    <a
      routerLink="/schedulemeeting"
      [queryParams]="{ id: parentid }"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >

    <div id="bloc2">
      <h5>{{ resource.message("IDPMEETING") }}</h5>
    </div>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <fieldset>
      <!--[disabled]=" (familyname!=select_user_family) ? isDisabled :''"-->
      <mat-card class="">
        <mat-card-content>
          <span class="devarea">Meeting Details</span>

          <hr />

          <mat-grid-list cols="2" rowHeight="100px">
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Meeting Title</mat-label>
                <input
                  matInput
                  placeholder="Meeting Title"
                  formControlName="title"
                />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Venue</mat-label>
                <input matInput placeholder="Venue" formControlName="venue" />
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
          <br />
          <br />
          <span class="devarea">Date and time</span>
          <hr />
          <mat-grid-list cols="2" rowHeight="100px">
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Start Date and Time</mat-label>
                <input [min]="minDate" matInput type="datetime-local" disabled formControlName="start_date"
                placeholder="Start Date and Time" (change)="onDateRangeChanged('start')"  />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>End Date and Time</mat-label>
              <input matInput type="datetime-local" disabled formControlName="end_date" (change)="onDateRangeChanged('end')" placeholder="End Date and Time"
                [min]="form.get('start_date').value" />
              </mat-form-field>
            </mat-grid-tile>
            <!-- <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>End time </mat-label>
                <input
                  [readonly]="true"
                  matInput
                  formControlName="end_time"
                  [ngxMatTimepicker]="time1"
                  disabled
                  placeholder="End time"
                />

                <ngx-mat-timepicker
                  class="time"
                  #time1
                  disabled="false"
                ></ngx-mat-timepicker>
                <mat-error
                  *ngIf="
                    this.form.get('end_time')?.errors
                      ?.endTimeGreaterThanStartTime
                  "
                  >End time can not be greater than start time .</mat-error
                >
              </mat-form-field>
            </mat-grid-tile> -->
          </mat-grid-list>
          <br />
          <br />
          <span class="devarea">Add Participants</span>
          <hr />
          <mat-form-field appearance="outline" class="field-spacer participant">
            <mat-label>Participants</mat-label>
            <input
              matInput
              placeholder="Participants"
              readonly
              (click)="addparticipant()"
              formControlName="participant"
            />
          </mat-form-field>
          <br />
          <br />
          <span class="devarea"
            >Add Temporary Participants
            <mat-slide-toggle
              (change)="onChange($event)"
              formControlName="allowlogs"
            ></mat-slide-toggle
          ></span>
          <hr />
          <!-- Temporary members -->

          <div class="col-md-12" *ngFor="let topic of allmember; index as i">
            <mat-card>
              <mat-form-field
                appearance="outline"
                class="field-spacer participant"
              >
                <mat-label>Name</mat-label>
                <input
                  matInput
                  placeholder="Participants"
                  readonly
                  [value]="topic.name"
                />
              </mat-form-field>
              <mat-form-field
                appearance="outline"
                class="field-spacer participant"
              >
                <mat-label>Email</mat-label>
                <input
                  matInput
                  placeholder="Participants"
                  readonly
                  [value]="topic.email"
                />
              </mat-form-field>
              <button mat-icon-button (click)="delete_participant(topic, i)">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-card>
          </div>
        </mat-card-content>
        <div class="form-group mr-2">
          <button mat-stroked-button type="button" class="wide" routerLink="/schedulemeeting"
          [queryParams]="{ id: parentid }">
            PREVIOUS
          </button>
        </div>
        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="submit"
          >
            Next
          </button>
        </mat-card-actions>
      </mat-card>
    </fieldset>
  </form>
</div>

import { Component, OnInit, ViewChild ,ChangeDetectorRef,Inject } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { CommonSpaceService } from '@services/CommonSpace.service';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import {CoreService} from '@services/CoreService';
import { MatMenuTrigger,MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import * as jsPDF from 'jspdf';
import { AuthService } from '@services/Auth.service';
//import autoTable from 'jspdf-autotable';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';

export interface CommonSpaceElement {
  Id: number;
  start_date: string;
  purpose: string;
  end_date: string;
 }

@Component({
  selector: 'app-dialogcalendar',
  templateUrl: './dialogcalendar.component.html',
  styleUrls: ['./dialogcalendar.component.css']
})
export class DialogcalendarComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;
  
  ELEMENT_DATA: CommonSpaceElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = [ 'purpose','start_date','end_date'];
  listData = new MatTableDataSource <CommonSpaceElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<CommonSpaceElement>(true, []);
 

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<CommonSpaceElement>;

  resource = language;

  constructor(private commonSpaceService: CommonSpaceService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router : Router,
    private http: HttpClient,
    private coreservice:CoreService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef : MatDialogRef<DialogcalendarComponent>,


    ) {

    AuthService.authenticate_redir('Common Space', ['READ']);

    this.listData = new MatTableDataSource<CommonSpaceElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<CommonSpaceElement>(true, []);


console.log(language.message("a"));

   }

  ngOnInit(): void {
 
    this.listData.paginator=this.paginator;
    this.listData.sort=this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getBooking(this.data.message);
    this.data.dayTitle = moment(this.data.message).format("DD-MMM-YYYY")
    this.table.renderRows();   
  }



  getBooking(date)
  {

    this.commonSpaceService.getBookingByDate(date).subscribe(response=> {
      console.log("COMMONSPACE DATA",response.body['data'])
      const result = response.body;
      this.listData.data=response.body['data'] as CommonSpaceElement[]
      this.table.renderRows();

     }, (err) => {

        if(err.error.status != null){
//          this.errmessage = err.error.message;
        }else{
 //         this.errmessage = err.error.errors[0].msg;
        }
   })
}
closeDialog()
{
  this.dialogRef.close(false)
}

 
}

import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarModule, MatSnackBarConfig} from '@angular/material/snack-bar'; 
import {MatDialog} from '@angular/material/dialog';
import { DialogdeleteComponent } from '../components/dialogdelete/dialogdelete.component';

@Injectable({
    providedIn:'root'
})

export class CoreService {


    
    snackconfig: MatSnackBarConfig = {
        duration: 2500,
        horizontalPosition: 'center',
        verticalPosition: 'top'
    }
    
    constructor( 
        private snackBar: MatSnackBar,
        private dialog :MatDialog
        ){


    }
 

     
    success(msg:string) {
        this.snackconfig['panelClass'] = ['success', 'notification']
        this.snackBar.open(msg,'Dismiss', this.snackconfig)
    }

    error(msg:string) {
        this.snackconfig['panelClass'] = ['error', 'notification']
        this.snackBar.open(msg,'Dismiss', this.snackconfig)
    }



    openAcceptDialog(msg,actionbutton, cancelbutton)
    {
      return  this.dialog.open(DialogdeleteComponent,{
            width:'25vw',
            panelClass:'confirm-dialog-container',
            disableClose:true,
            data:{
                message:msg,
                actionbutton: actionbutton,
                cancelbutton: cancelbutton
            }
        })
    }


    openConfirmDialog(msg)
    {
      return  this.dialog.open(DialogdeleteComponent,{
            width:'25vw',
            panelClass:'confirm-dialog-container',
            disableClose:true,
            data:{
                message:msg,
                actionbutton: "Yes",
                cancelbutton: "No"
            }
        })
    }
}

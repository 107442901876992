import { Component, OnInit, Inject } from '@angular/core';
import { language } from '@shared/config/language';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormBuilder,
  FormArray,
  NgForm,
} from '@angular/forms';
import * as moment from 'moment';
import { BusinessInfoService } from '@services/BusinessInfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '@services/CoreService';
import { FileService } from '@services/File.service';
import { UserService } from '@services/UserService';
import { UACService } from '@services/UAC.service';
import { OtherMeetingService } from '@services/OtherMeeting.service';

@Component({
  selector: 'app-dialogleadershipteam',
  templateUrl: './dialogleadershipteam.component.html',
  styleUrls: ['./dialogleadershipteam.component.css'],
})
export class DialogleadershipteamComponent implements OnInit {
  resource = language;
  todayDate:Date = new Date();
  listData = [];
  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    user: new FormControl(''),
    profile_photo: new FormControl(''),
    name: new FormControl('', Validators.required),
    designation: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    mobile: new FormControl('', Validators.required),
    department: new FormControl('', Validators.required),
    start_date: new FormControl('', Validators.required),
    end_date: new FormControl(''),
    file: new FormControl(''),
  }, { validators : this.dateLessThan('start_date', 'end_date')});
  postform: FormGroup = new FormGroup({
    leadership_team: new FormArray([]),
  });
  isEdit: boolean = false;
  selFile = new Object();
  selFileName = '';
  localstoragedata: any;
  select_user_family: string;
  TEAM_MEMBER: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogleadershipteamComponent>,
    private businessinfoservice: BusinessInfoService,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private fileService: FileService,
    private userservice: UserService,
    private uacservice: UACService,
    private othermeetinginfoservice: OtherMeetingService
  ) {}

  ngOnInit(): void {
    this.getAllUsers();
    console.log('this.data', this.data);
    if (this.data.index != -1) {
      const ind = this.data.index;

      this.form.patchValue({
        _id: this.data.id,
        user: this.data.prevdata[ind]['user'],
        profile_photo: this.data.prevdata[ind]['profile_photo']
          ? this.data.prevdata[ind]['profile_photo']
          : '',
        name: this.data.prevdata[ind]['name'],
        designation: this.data.prevdata[ind]['designation'],
        email: this.data.prevdata[ind]['email'],
        mobile:
          this.data.prevdata[ind]['mobile_no'] != null
            ? this.data.prevdata[ind]['mobile_no']
            : '',
        department: this.data.prevdata[ind]['department'],
        start_date: this.data.prevdata[ind]['start_date'],
        end_date: this.data.prevdata[ind]['end_date'],
      });

      this.data.prevdata.splice(ind, 1);
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): {[key: string]: any} => {
     let f = group.controls[from];
     let t = group.controls[to];
     console.log(new Date(f.value).getTime(),new Date(t.value).getTime())
     if (new Date(f.value).getTime() > new Date(t.value).getTime()) {
       t.setErrors({ endTimeGreaterThanStartTime: true });
       return {
         dates: "Start Date should be less than end Date"
       };
     }
     t.setErrors(null);
    }
  }

  async submituser(): Promise<void> {
    var rawuser = this.form.getRawValue();
    //Filter
    var filter = '';
    //Localstorage data
    this.localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    filter =
      '?filter[family]=' +
      JSON.parse(localStorage.getItem('LOGGED_USER')).family._id +
      '&filter[role]=member';

    //Role data for user
    this.businessinfoservice.getRoles(filter).subscribe(
      (response) => {
        let result = response.body;
        rawuser.role = result['results'][0].role;
        rawuser.role_uac = result['results'][0]._id;
      },
      (err) => {
        if (err.error.status != null) {
        } else {
        }
      }
    );
    console.log(rawuser);
    if (this.localstoragedata.family != null)
      this.select_user_family = this.localstoragedata.family.name;

    rawuser['mobile'] = Number(rawuser.mobile);
    rawuser.familyname = this.select_user_family;
    rawuser.password = 'FamilyBook123#';

    let fileurl = this.selFileName;
    var formData: any = new FormData();
    formData.append('file', this.selFile);
    if (this.selFileName != '') {
      fileurl = await this.fileService.uploadFile(formData, true, false);
    }
    if (fileurl !== '') {
      rawuser['profile_photo'] = fileurl;
    }
    delete rawuser['file'];
    delete rawuser['_id'];
    this.userservice.addUser(rawuser).subscribe(
      (response) => {
        const result = response.body;
        console.log('DATA Retrived', result);
      },
      (err) => {
        var errormessages = 'Please correct the errors - \n';
        console.log(err);
        if (err.error.errors != undefined) {
          err.error.errors.forEach(
            (element) => (errormessages += ',' + element.msg + '\n')
          );
          this.coreservice.error(errormessages);
        } else {
          this.coreservice.error(err.error.message);
        }
      }
    );
  }

  setrole($event) {
    this.TEAM_MEMBER.forEach((element) => {
      if (element._id == $event.value) {
        console.log(element);
        this.form.patchValue({
          user: element._id,
          profile_photo:
            element.profile_photo !== undefined ? element.profil_photo : '',
          mobile: element.mobile,
          role: element.role_uac,
          email: element.email,
          name: element.name + ' ' + element.lastname,
        });
      }
    });
  }
  async submit() {
    console.log(this.form)
    var constdata = this.form.getRawValue();
    constdata['_id'] = this.data.id;
    if (this.form.invalid) {
      console.log('KKLLLLLLLL');
      return;
    }
    let fileurl = this.selFileName;
    var formData: any = new FormData();
    formData.append('file', this.selFile);
    if (this.selFileName != '') {
      fileurl = await this.fileService.uploadFile(formData, true, false);
    }
    if (fileurl !== '') {
      constdata['profile_photo'] = fileurl;
    }
    delete constdata['file'];
    var finalData = this.postform.getRawValue();
    for (let i = 0; i < this.data.prevdata.length; i++) {
      finalData['leadership_team'].push(this.data.prevdata[i]);
    }
    const len = this.data.prevdata.length + 1;
    finalData['leadership_team'].push({
      user: constdata['user'],
      name: constdata['name'],
      designation: constdata['designation'],
      profile_photo: constdata['profile_photo'],
      start_date: constdata['start_date'],
      end_date: constdata['end_date'],
      mobile_no: constdata['mobile'],
      email: constdata['email'],
      department: constdata['department'],
    });

    this.businessinfoservice
      .updateBusinessData(finalData, constdata['_id'])
      .subscribe(
        (response) => {
          const result = response.body;
          this.coreservice.success(
            this.resource.message('LEADERSHIPT_UPDATED')
          );
          this.closeDialog();
        },
        (err) => {}
      );
  }

  onFileChange(event) {
    this.selFile = (event.target as HTMLInputElement).files[0];
    this.selFileName = (event.target as HTMLInputElement).files[0].name;
  }
  previewimg(eve) {
    window.open(this.form.get('profil_photo').value, '_blank');
  }
  getAllUsers() {
    console.log(' this.TEAM_MEMBER', this.TEAM_MEMBER);
    var filter = '';
    this.othermeetinginfoservice.getFamilyUsers(filter).subscribe(
      (response) => {
        this.TEAM_MEMBER = response.body['data'].filter((user) => {
          if (
            this.data.prevdata.find((u) => {
              return u.user === user._id;
            })
          ) {
            return false;
          } else {
            return true;
          }
        });
        console.log(' this.TEAM_MEMBER2', this.TEAM_MEMBER);
      },
      (err) => {
        if (err.error.status != null) {
        } else {
        }
      }
    );
  }
}

<br />
<p>Default Categories - <span class="categoryrounded ml">Family Constitution | Learning</span></p>
  
    <div class="form-group mr-2">  
     <h5><b>Categories</b></h5>  
    </div>
     <span class="example-spacer"></span>
     <div class="mr-1"> 
   
     <button mat-raised-button color="primary" class="addbutton" class="buttonfloatright" (click)="addnewitem()" ><i class="fas fa-plus"></i>&nbsp;&nbsp;ADD</button>
    
    </div>
    <br /><br /> 
    <div><hr /></div>

    <div>

        <mat-grid-list cols="1" rowHeight="70px">
            <ng-container *ngFor="let category of categories; index as i; first as isFirst" >
                <mat-grid-tile *ngIf="!(category.category==='Family Constitution' || category.category==='Learning')">
                <mat-form-field appearance="outline" class="field-spacer">
                    <input matInput placeholder="Category" [(ngModel)]="category.category" (blur)="update(category)">
                  </mat-form-field>
                   
                  <button mat-stroked-button color="warn" [disabled]="category.child == 1" class="actionubutton" (click)="remove(category)"><i class="fas fa-trash"></i></button>
                </mat-grid-tile>
            </ng-container>
        </mat-grid-list>   
        
        

 
    </div>

import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';
import { constants } from '@shared/config/constants';


@Injectable({
    providedIn:'root'
})

export class CategoriesReportsService {

    constructor(private http: HttpClient){


    }


    getParentCategories(){
      return this.http.get(apiendpoints.REPORT_PARENT_CATEGORIES,
        {
            headers: new HttpHeaders()
              .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
            observe: 'response'
          }
        );      
  }

  getChildCategories(parentId){
    return this.http.get(apiendpoints.REPORT_CHILD_CATEGORIES+"/"+parentId,
      {
          headers: new HttpHeaders()
            .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
          observe: 'response'
        }
      );      
  }


  createCategory(category){
    delete category["_id"];
    return this.http.post(apiendpoints.REPORT_CHILD_CATEGORIES,{vo:category},
      {
          headers: new HttpHeaders()
            .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
          observe: 'response'
        }
      );      
  
  }

  updateCategory(category){
    return this.http.put(apiendpoints.REPORT_CHILD_CATEGORIES+"/"+category._id,{vo:category},
      {
          headers: new HttpHeaders()
            .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
          observe: 'response'
        }
      );      
  
  }


  deleteCategory(id){
    return this.http.delete(apiendpoints.REPORT_CHILD_CATEGORIES+"/"+id,
      {
          headers: new HttpHeaders()
            .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
          observe: 'response'
        }
      );      
  }






  getDocuments(id=0){
    let url = apiendpoints.REPORT_PARENT_DOCUMENTS;
    if(id != 0) url+="/"+id;
    return this.http.get(url,
      {
          headers: new HttpHeaders()
            .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
          observe: 'response'
        }
      );      
  }

}

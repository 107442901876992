import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DialogcommiteeagendaComponent } from '@shared/components/dialogcommiteeagenda/dialogcommiteeagenda.component';
import { CommitteeService } from '@services/Committee.service';
import { DialogaddnoteComponent } from '@shared/components/dialogaddnote/dialogaddnote.component';
import { CoreService } from '@services/CoreService';

@Component({
  selector: 'app-committeeagenda',
  templateUrl: './committeeagenda.component.html',
  styleUrls: ['./committeeagenda.component.css'],
})
export class CommitteeagendaComponent implements OnInit {
  parentId: string = '';
  isEdit: boolean = false;
  showSlab: boolean = true;
  topics: string[] = [];
  allData = [];
  @Input() type = 'is_hybrid';
  @Output() reload = new EventEmitter();

  @Input() isRationalized = false;
  @Input() set _topics(value) {
    console.log('value', value);
    if (value.length > 0) {
      this.showSlab = false;
      this.topics = value;
    } else {
      this.showSlab = true;
    }
  }
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private dialog: MatDialog,
    private committeeService: CommitteeService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentId = data.id;
        // this.getMeetingData(this.parentId);
      }
    });
    console.log('this.topics', this.topics);
  }
  publishClick() {
    this.router.navigate(['/committee/rationalize'], {
      queryParams: {
        id: this.parentId,
      },
    });
  }
  openAgendaDialog(mode = 'add', id: '') {
    const dialogRef = this.dialog.open(DialogcommiteeagendaComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: mode === 'add' ? 'Add Agenda' : 'Edit Agenda',
        actionbutton: '',
        cancelbutton: '',
        id: this.parentId,
        isEdit: mode === 'add' ? false : true,
        agendaId: id,
        defer: false,
        type: this.type
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.activeRoute.queryParams.subscribe((data) => {
        if (data.id != undefined) {
          this.parentId = data.id;
          // this.getMeetingData(this.parentId);
          this.reload.emit('');
        }
      });
    });
  }
  onEdit(row) {
    this.openAgendaDialog('edit', row._id);
  }
  getMeetingData(id) {
    var filter = '/' + id;

    this.committeeService.getCommitteeMeetings(filter).subscribe(
      (response: any) => {
        const result = response.data;
        this.topics = [];
        console.log(response.data.agendas);
        if (result.agendas.length > 0) {
          this.showSlab = false;
          
          for (let i = 0; i < result.agendas.length; i++) {
            this.topics.push(result.agendas[i]);
            console.log(this.topics);
          }
        } else {
          this.showSlab = true;
        }
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  public trackItem(index: number, item: any) {
    return item._id;
  }
  show_suggested() {
    this.committeeService.committeeUpdateStatus({meeting_status : 'published'}, this.parentId).subscribe(
      (response: any) => {
        console.log(response.data._id);

        this.router.navigate(['committee/suggested'], {
          queryParams: { id: this.parentId },
        });
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
    
  }

  previous() {
    this.router.navigate(['committee/participants'], {
      queryParams: { id: this.parentId },
    });
  }
  onDefer(row) {
    this.committeeService
      .deferOrAddAgenda(this.parentId, row._id, 'true')
      .subscribe((res) => {
        this.coreService.success('The selected agenda defered sucessfully!');
        this.getMeetingData(this.parentId);
        this.reload.emit('');
      });
  }

  onDelete(topic) {
    console.log(topic);
    this.committeeService
      .deleteCommitteeAgendaById(this.parentId, topic._id)
      .subscribe(
        (res) => {
          this.coreService.success('The selected agenda deleted sucessfully!');
          this.getMeetingData(this.parentId);
          this.reload.emit('');
        },
        (err) => {
          console.log(err);
        }
      );
  }
  cancelMeeting() {
    const dialogRef = this.dialog.open(DialogaddnoteComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: 'MEETING CANCELLED',
        actionbutton: '',
        cancelbutton: '',
        id: this.parentId,
        type: 'cancel',
        meeting: 'committee-meetings',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
    //   this.idp.idpcancel(this.parentid).subscribe(
    //     (response: any) => {
    //       //    this.router.navigate(['idp']);
    //     },
    //     (err) => {
    //       console.log('errrrrrrr', err);
    //     }
    //   );
  }
}

<div align="right">
    <div class="dialogcontent-container">
    <mat-card  class="cardclass">
        <mat-card-header>            
           <mat-card-title> {{resource.message('KNOWLEDGE_ACTION')}} </mat-card-title>
           <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
        </mat-card-header>
        <hr/> <form [formGroup]="form" (ngSubmit)="submitData()">
                <mat-card-content>
                   
                        <mat-grid-list cols="2" rowHeight="100px">
                            <mat-grid-tile >
                                <mat-form-field appearance="outline" class="field-spacer">
                                    <mat-label>Name of the file</mat-label>
                                    <input matInput placeholder="Name of the File *" formControlName="title" >
                                  </mat-form-field>
                            </mat-grid-tile>
                
                            <mat-grid-tile >
                              <mat-label>File Type :  </mat-label>
                              <mat-radio-group appearance="outline" class="smallRadio"  [formControl]="file_type">
                                  <mat-radio-button value="PDF" [checked]="file_type.value == 'PDF'" (change)="this.form.patchValue({'uploadtype':'PDF'})" class="m20" ><span>PDF</span> </mat-radio-button>
                                  <mat-radio-button value="URL"  [checked]="file_type.value == 'URL'" (change)="this.form.patchValue({'uploadtype':'URL'})" class="m20" >URL</mat-radio-button>
                              </mat-radio-group>
                            </mat-grid-tile>
                            <mat-grid-tile *ngIf="file_type.value=='URL'">
                              <mat-form-field appearance="outline" class="field-spacer">
                                  <mat-label>Paste the URL Link</mat-label>
                                  <input matInput placeholder="Paste the URL Link *" formControlName="url" >
                                </mat-form-field>

                            </mat-grid-tile>
                          <mat-grid-tile *ngIf="file_type.value=='PDF'">
                            <div class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored">
                              <mat-label>Upload File</mat-label>
                              <input name="File1"  placeholder="File" type="file"  (change)="onFileChange($event)" formControlName="file"/>
                          </div>
                          </mat-grid-tile>
                           <!-- <mat-grid-tile >
                              <div class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored">
                                <input type="hidden" formControlName="_id" >
                                <mat-label>Upload File</mat-label>
                                <input name="File1"  placeholder="File" type="file"  (change)="onFileChange($event)" formControlName="file"/>
                            </div>
                            </mat-grid-tile>-->
    
                        </mat-grid-list>    
                        </mat-card-content>
                        <mat-card-actions align="end">
                          <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="submit" [disabled]="form.invalid" *ngIf="isEdit">Save</button>

                          <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="submit" *ngIf="!isEdit">Add</button>
                                       </mat-card-actions>
            </form>
            </mat-card>
          </div>
       </div>     
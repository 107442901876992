<div class="content p30">
  <div id="block_container">
    <a
      routerLink="/polls"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >
    <div id="bloc2">
      <h5>{{ resource.message("POLLS") }} / {{ labelText }}</h5>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <mat-card class="">
      <mat-card-header>
        <mat-card-title> {{ resource.message("POLLS") }} </mat-card-title>
      </mat-card-header>
      <mat-card-content class="contentclass">
        <hr />
        <mat-grid-list cols="2" rowHeight="100px">
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
             <input type="hidden" formControlName="_id" />
              <mat-label>Select Committee *</mat-label>
              <mat-select
                placeholder="Committee Type"
                formControlName="committee"
              >
                <mat-option
                  *ngFor="let type of COMMITTEE_LIST"
                  [value]="type._id"
                  >{{ type.title }}</mat-option
                >
              </mat-select>
              <mat-error>This field is mandatory.</mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Title *</mat-label>
              <input matInput placeholder="Title" formControlName="title" />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="div1" align="left">
              <label>Question Type * :  </label><br />
              <mat-radio-group appearance="outline" class="field-spacer" [formControl]="notice">
                  <mat-radio-button   value="single"
                  [checked]="notice.value == 'single'"
                  class="m20"
                  (change)="this.form.patchValue({ question_type: 'single' })">Single Choice</mat-radio-button>

                  <mat-radio-button
                  value="multiple"
                  [checked]="notice.value == 'multiple'"
                  class="m20"
                  (change)="this.form.patchValue({ question_type: 'multiple' })"
                  >Multi Choice</mat-radio-button>
                </mat-radio-group>
            </div>

 
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Question *</mat-label>
              <input
                matInput
                placeholder="Question"
                formControlName="question"
              />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Option *</mat-label>
              <input
                matInput
                placeholder="Option"
                formControlName="question_options"
              />
            </mat-form-field>
            <button
              mat-stroked-button
              color="primary"
              type="button"
              (click)="add_option()"
              [disabled]="this.form.get('question_options').value == ''"
            >
              Add
            </button>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Active Till *</mat-label>
              <input
                matInput
                [min]="today" 
                type="datetime-local"
                disabled
                formControlName="active_till"
                placeholder="Active Till"
              />
              
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile class="option-tile">
            <mat-grid-list cols="1" class="option-grid">
              <mat-card
                class="optioncard"
                *ngFor="let opt of option_array; let i = index"
                >{{ opt.option }}
                <mat-icon class="optionicon" (click)="remove(i)"
                  >close</mat-icon
                ></mat-card
              >
            </mat-grid-list>
          </mat-grid-tile>
          <mat-grid-tile *ngIf="isEdit == true">
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Total No of Members</mat-label>
              <input
                matInput
                placeholder="Total no of Members"
                formControlName="total_no_people"
                readonly
              />
            </mat-form-field>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Poll taken</mat-label>
              <input
                matInput
                placeholder="Poll taken"
                formControlName="total_votes"
                readonly
              />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile *ngIf="isEdit == true"> </mat-grid-tile>
          <mat-grid-tile *ngIf="isEdit == true">
            <mat-form-field appearance="outline" class="statusfield">
              <mat-label> Status</mat-label>
              <mat-select placeholder="Status" formControlName="status">
                <mat-option
                  *ngFor="let type of STATUS_LIST | keyvalue"
                  [value]="type.key"
                  >{{ type.value }}</mat-option
                >
              </mat-select>
              <mat-error>This field is mandatory.</mat-error>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
      <mat-card-actions align="end">
        <button
          mat-raised-button
          color="primary"
          class="btn btn-primary login-btn button-wider"
          type="submit"
          [disabled]="form.invalid || option_array.length<2"
        >
          Save
        </button>
        <button
          mat-raised-button
          color="clear"
          class="button-wider"
          routerLink="/polls"
        >
          Cancel
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>

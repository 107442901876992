import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { CommonSpaceService } from '@services/CommonSpace.service';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import { CoreService } from '@services/CoreService';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import { AuthService } from '@services/Auth.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DatePipe } from '@angular/common';

export interface CommonSpaceElement {
  Id: number;
  spacename: string;
  floor: string;
  capacity: string;
  city: string;
  address: string;
  caretaker: string;
  contact: string;
  email: string;
  category: object;
  createdon: string;
  user: any;
}

@Component({
  selector: 'app-commonspacebooking',
  templateUrl: './commonspacebooking.component.html',
  styleUrls: ['./commonspacebooking.component.css'],
  providers: [DatePipe],
})
export class CommonspacebookingComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;

  ELEMENT_DATA: CommonSpaceElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = [
    'spacename',
    'user',
    'ticket',
    'start_date',
    'end_date',
    'Action',
  ];
  listData = new MatTableDataSource<CommonSpaceElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<CommonSpaceElement>(true, []);

  PERMISSION_EDIT = AuthService.authentication('Common Space', 'READ');
  PERMISSION_ADD = AuthService.authentication('Common Space', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Common Space', 'DELETE');

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<CommonSpaceElement>;

  resource = language;

  constructor(
    private commonSpaceService: CommonSpaceService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private http: HttpClient,
    private coreservice: CoreService,
    private datePipe: DatePipe
  ) {
    AuthService.authenticate_redir('Common Space', ['READ']);

    this.listData = new MatTableDataSource<CommonSpaceElement>(
      this.ELEMENT_DATA
    );
    this.selection = new SelectionModel<CommonSpaceElement>(true, []);

    console.log(language.message('a'));
  }

  ngOnInit(): void {
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    console.log(this.listData.sort);
    this.changeDetectorRefs.detectChanges();
    this.getBooking();
    this.table.renderRows();
  }

  getBooking() {
    this.commonSpaceService.getBooking('').subscribe(
      (response) => {
        console.log('COMMONSPACE DATA', response.body['data']);
        const result = response.body;
        this.listData.data = response.body['data'] as CommonSpaceElement[];
        this.listData.data.forEach((item) => {
          item.spacename = item.spacename['spacename'];
          item.user = item.user['name'] + ' ' + item.user['lastname'];
        });
        this.table.renderRows();
      },
      (err) => {
        if (err.error.status != null) {
          //          this.errmessage = err.error.message;
        } else {
          //         this.errmessage = err.error.errors[0].msg;
        }
      }
    );
  }
  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key]; // + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listData.data.forEach((row) => this.selection.select(row));
  }

  addbooking() {
    this.router.navigate(['/booking/add']);
  }

  edit(role) {
    this.router.navigate(['booking/add'], { queryParams: { id: role._id } });
  }

  onDelete(id) {
    this.coreservice
      .openConfirmDialog(this.resource.message('ROLE_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.commonSpaceService.removeCommonSpace(id).subscribe(
            (response) => {
              const result = response;
              this.getBooking();
              this.coreservice.success(this.resource.message('ROLE_DELETED'));
            },
            (err) => {}
          );
        }
      });
  }

  closeMyMenu() {
    this.trigger.closeMenu();
  }

  filterData(ev, col, val) {
    if (ev.checked) {
      col = String(col);

      let value = { [col]: val };
      this.filterValues.push(value);
    } else {
      let value = { [col]: val };
      const index = this.filterValues.indexOf(value);
      this.filterValues.splice(index - 1, 1);
    }

    this.setupFilter(col);
    this.applyFilter(val);
  }

  stoppro($event) {
    $event.stopPropagation();
  }

  setupFilter(column: string) {
    this.listData.filterPredicate = (d: any, filter: string) => {
      const val = JSON.parse(filter);
      var flag = false;

      val.forEach(function (item) {
        let obj = item;
        const textToSearch = (d[column] && d[column].toLowerCase()) || '';
        if (obj[column] != undefined) {
          if (
            textToSearch.toLowerCase().indexOf(obj[column].toLowerCase()) !== -1
          ) {
            flag = true;
          }
        }
      });
      return flag;
    };
  }

  applyFilter(filterValue: string) {
    this.listData.filter = JSON.stringify(this.filterValues); // filterValue.trim().toLowerCase();
  }

  filterValues = [];

  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [
      [
        'Space Name',
        'Booked By',
        'Ticket Number',
        'Start Date and Time',
        'End Date and Time',
      ],
    ];
    this.listData.data.forEach((obj) => {
      let spacename = obj['spacename'] ? obj['spacename'] : '';
      let user = obj['user'] ? obj['user'] : '';
      let ticket = obj['ticket'] ? obj['ticket'] : '';
      let start_date = obj['start_date']
        ? this.datePipe.transform(obj['start_date'])
        : '';
      let end_date = obj['end_date']
        ? this.datePipe.transform(obj['end_date'])
        : '';

      let arr = [spacename, user, ticket, start_date, end_date];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      rowPageBreak: 'avoid',
    });
    if (action === 'save') {
      doc.save('common-space-bookings.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      // window.open(doc.output('bloburl') as unknown as string, '_blank');
      doc.output('dataurlnewwindow', { filename: 'common-space-bookings.pdf' });
    }
  }
  preventSubmit(event) {
    return event.key != 'Enter';
  }
  /*print(){
    let doc = new jsPDF(); 
    doc.autoTable({
      head: [['NAme','approved','utilised', 'available','asd','sadadasada','asdas']],
      body: this.dataSource.filteredData //returning [["log1", "$100"], ["log2", "$200"]]
    });
    doc.save('table.pdf')
  }
*/
}

<div class="row pd container-fluid">
    <!-- *ngFor="let item of steps; index as i;" -->
    
      <span class="col-sm-1" *ngFor="let item of steps; index as i;" style="width: 14.28%; flex: 0 0 14.28%;max-width: 14.28%;">
         <span class="row">
               <div class="col-sm-6 progress-first-div" >
                  <span [ngClass]="(i < currentStep)?'check-blue-bg':(i > currentStep)?'check-grey-bg':'check-bg'"> <i class="fa fa-check"></i></span>
                  <p class="progressbar-title"> {{item}}</p>
               </div>
               <div class="col-sm-6" *ngIf="i != steps.length-1">
                  <hr class="progress-bar-hr">
               </div>
         </span>
      </span>
    <!-- 
    <div class="col-sm-1 progress-first-div">
       <span class="check-blue-bg"> 4 </span>
       <p class="progressbar-title"> Member Rules</p>
    </div>
    <div class="col-sm-1">
       <hr class="progress-bar-hr">
    </div>
    <div class="col-sm-1 progress-first-div">
       <span class="check-grey-bg"> 5 </span>
       <p class="progressbar-title"> Meeting Rules</p>
    </div>
    <div class="col-sm-1">
       <hr class="progress-bar-hr">
    </div>

   -->
    
    <div class="col-sm-1"> </div>
 </div>
<div>
  <form class="form-inline">
    <div class="form-group mr-2"></div>
    <span class="example-spacer"></span>
    <div class="mr-1">
      <button
        mat-raised-button
        color="primary"
        class="addbutton"
        class="buttonfloatright"
        (click)="addgoal()"
      >
        <i class="fas fa-plus"></i>&nbsp;&nbsp;{{
          resource.message("ADDGOAL") | uppercase
        }}
      </button>
    </div>
    <br />
    <br />
    <div class="pclass table-responsive">
      <mat-table
        #goalTable
        goalTable
        matTableExporter
        [dataSource]="listData"
        matSort
        class="mattable goalTable"
        #exporter="matTableExporter"
      >
        <ng-container matColumnDef="development">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="customTableHeaderStyle"
            >Development</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.description
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="category">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="customTableHeaderStyle"
            >Category</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.category }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="date">
          <mat-header-cell style="justify-content: center;"  *matHeaderCellDef mat-sort-header class="customTableHeaderStyle"
            >Date</mat-header-cell
          >
          <mat-cell *matCellDef="let element" style="justify-content: center;"  >{{
            element.created_at | customDate
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Action2">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="customTableHeaderStyle"  style="justify-content: center;"
          >Action</mat-header-cell
          >
          <mat-cell *matCellDef="let element" style="justify-content: center;" >
            <button mat-icon-button (click)="edit(element)">
              <!-- [disabled]="!PERMISSION_EDIT" -->
              <mat-icon>mode_edit</mat-icon></button
            ><button mat-icon-button color="warn" (click)="onDelete(element)">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="customTableHeaderStyle" style="justify-content: center;"
          >Status</mat-header-cell
          >
          <mat-cell *matCellDef="let element" style="justify-content: center;" >
            <span [ngClass]="element.status == 'completed'
                  ? 'status-active'
                  : '' || element.status == 'abandoned'
                  ? 'status-inactive'
                  : '' || element.status == 'in_review'
                  ? 'status-completed'
                  : 'status-progress'
              "></span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 15, 20]"
        [pageSize]="5"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </form>
</div>

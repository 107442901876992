import { Component, OnInit, ViewChild ,ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { UserService } from '@services/UserService';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import {CoreService} from '@services/CoreService';
import { MatMenuTrigger } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';

export interface UserElement {
  userId: number;
  name: string;
  email: string;
  mobile: string;
  role: string;
  status: string;
  created: string;
  updated: string;
}


// REMOVE THIS COMMENTS


@Component({
  selector: 'app-securitylogs',
  templateUrl: './securitylogs.component.html',
  styleUrls: ['./securitylogs.component.css']
})
export class SecuritylogsComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;
  
  ROLE_DATA:string[];
  ELEMENT_DATA: UserElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = [ 'name', 'mobile',   'Action'];
  listData = new MatTableDataSource <UserElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<UserElement>(true, []);
 



  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<UserElement>;

  resource = language;

  constructor(private userservice: UserService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router : Router,
    private http: HttpClient,
    private coreservice:CoreService
    ) {

    this.listData = new MatTableDataSource<UserElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<UserElement>(true, []);


console.log(language.message("a"));

   }

  ngOnInit(): void {
 
    this.listData.paginator=this.paginator;
    this.listData.sort=this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllUsers();
    this.table.renderRows();   

   
    
 

    

 }



 getAllUsers()
  {

    this.userservice.getUsers('?filter[role]=admin').subscribe(response=> {
      
      var result = response.body;
      (result['results'] as UserElement[]).forEach(function (value) {
        value["familyname"] = value["family"]["name"];        
      });


      this.listData.data=response.body['results'] as UserElement[]


      this.table.renderRows();

      this.ROLE_DATA = this.getCol(response.body['results'] as UserElement[], 'role');
      console.log(this.ROLE_DATA);
      
      this.ROLE_DATA =  [...new Set(this.ROLE_DATA)];

     }, (err) => {

        if(err.error.status != null){
//          this.errmessage = err.error.message;
        }else{
 //         this.errmessage = err.error.errors[0].msg;
        }
      
   })

   


   

   }



   onSearchClear()
   {
     this.searchKey="";    
     this.applyfilter();
   }
   applyfilter()
   {
    this.listData.filterPredicate = 
    function (data, filter) {
        var dataStr = Object.keys(data).reduce(function (currentTerm, key) {
            return currentTerm + data[key] + '◬';
        }, '').toLowerCase();
        var transformedFilter = filter.trim().toLowerCase();
        return dataStr.indexOf(transformedFilter) != -1;
    };
     this.listData.filter=this.searchKey.trim().toLowerCase();
   }
   

   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.listData.data.forEach(row => this.selection.select(row));
  }

  addrole(){
    this.router.navigate(['/user/add']);

  }

  edit(role){
    console.log(role);
    console.log(role.family._id)
    this.router.navigate(['security/details'], { queryParams: { id: role._id,family:role.family._id } });
  //  this.router.navigate(['security/details'],{queryParams: [{id: role._id},{family: role.family._id}]});

  }

 


  
  filterData(ev,col,val) 
  {
    if(ev.checked){
      col = String(col);
       
      let value = {[col]:val};
      this.filterValues.push(value);
    }else{
      let value = {[col]:val};
      const index = this.filterValues.indexOf(value);
      this.filterValues.splice(index-1,1);
    }

    
    this.setupFilter(col);
    this.applyFilter(val)
   
  } 

  stoppro($event){
    $event.stopPropagation();
   
  }

  setupFilter(column: string) {
    this.listData.filterPredicate = (d: any, filter: string) => {
    const val = JSON.parse(filter);
    var flag = false;

      val.forEach(function(item){  
         let obj = item;
         const textToSearch = d[column]["role"] && d[column]["role"].toLowerCase() || '';
            if(obj[column] != undefined){
              if(textToSearch.toLowerCase().indexOf(obj[column].toLowerCase()) !== -1){
                flag = true;
              }
            }
            
        }); 
        return flag;
     
    };
  }
  
  applyFilter(filterValue: string) {
    
    this.listData.filter =  JSON.stringify(this.filterValues);// filterValue.trim().toLowerCase();
  }


  getCol(matrix, col){
    
    var column = [];
    for(var i=0; i<matrix.length; i++){
       column.push(matrix[i][col]["role"]);
    }
    return column; // return column data..
 }

  filterValues = [];
}

<mat-card class="">
  <mat-card-header>
    <mat-card-title> {{ resource.message("PARTICIPANTS") }} </mat-card-title>
    <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
  </mat-card-header>
  <mat-card-content>
    <form class="form-inline">
      <input
        matInput
        placeholder="Search"
        autocomplete="off"
        class="searchfloatright"
        autocomplete="off"
        (keyup)="applyfilter()"
        [(ngModel)]="searchKey"
        name="searchKey"
      />
    </form>
    <p>
      <br />
      <mat-table
        #roleTable
        roleTable
        matTableExporter
        [dataSource]="listData"
        matSort
        class="mattable"
        #exporter="matTableExporter"
        [hiddenColumns]="[5, 6]"
      >
        <ng-container matColumnDef="fullname">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Participants</mat-header-cell
          >
          <mat-cell *matCellDef="let element"
            >{{ element.fullname }} </mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="attendence">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Attending</mat-header-cell
          >
          <mat-cell *matCellDef="let element" class="toggle"
            >{{ element.isPresent ? "Attending" : "Rejected" }} 
            <span
              ><mat-slide-toggle
                (change)="onChange($event, element)"
                [checked]="element.isPresent"
              ></mat-slide-toggle
            ></span>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 15, 20]"
        [pageSize]="5"
        showFirstLastButtons
      ></mat-paginator>
    </p>
  </mat-card-content>
  <mat-card-actions align="end">
    <button
      mat-raised-button
      color="primary"
      class="btn btn-primary login-btn button-wider"
      type="button"
      (click)="saveAttendence()"
    >
      Save
    </button>
  </mat-card-actions>
</mat-card>

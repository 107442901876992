<div class="content p30"> 

    <h5>{{resource.message('ROLEPERMISSION')}}</h5>    
 

    
    <mat-card class="">
      <mat-card-header >            
        <mat-card-title > {{resource.message('ROLEPERMISSION')}} </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" class="form-inline" style="padding: 0px 10px 0px 10px;" >
          
          <div class="form-group mr-2"> 
          
          <mat-form-field class="example-full-width22" >
            <mat-select  placeholder="Role"  formControlName="role"  (selectionChange)="getRoleModulePermissions()" >
               <mat-option *ngFor="let role of ROLES_LIST" [value]="role._id+''">{{ role.role}} [ {{role.family.name}} ]</mat-option>
            </mat-select>
            <mat-error>This field is mandatory.</mat-error>
           </mat-form-field> 

         
       
          
         </div>
         <span class="example-spacer"></span>
         <div class="form-group mr-2"> 
          <mat-form-field class="example-full-width22" >
            <mat-select  placeholder="Module"  formControlName="module"   (selectionChange)="getRoleModulePermissions()" >
               <mat-option *ngFor="let module of MODULES_LIST" [value]="module._id+''">{{ module.module}}</mat-option>
            </mat-select>
            <mat-error>This field is mandatory.</mat-error>
           </mat-form-field> 
         </div>

 
      </form>
         

<br />

<fieldset >

<mat-grid-list cols=3 rowHeight="50px">
<mat-grid-tile class="mat-figure-custom"  *ngFor="let permission of PERMISSION_LIST; let i = index">
    <div class="text-inside-grid"><mat-checkbox [disabled]="!PERMISSION_EDIT"  (change)="updatepermission($event, permission._id)"  [checked]="permission.checked">{{permission.permission}}</mat-checkbox></div>
</mat-grid-tile>
</mat-grid-list>
</fieldset>

       
      </mat-card-content>
    </mat-card>



  </div>




import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BusinessInfoService } from '@services/BusinessInfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogboarddirectorsteamComponent } from '@shared/components/dialogboarddirectorsteam/dialogboarddirectorsteam.component';
import { MatDialog } from '@angular/material/dialog';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormBuilder,
  FormArray,
  NgForm,
} from '@angular/forms';
import { CoreService } from '@services/CoreService';
import { language } from '@shared/config/language';
import { AuthService } from '@services/Auth.service';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import * as _ from 'lodash';
import { OtherMeetingService } from '@services/OtherMeeting.service';

export interface BoardElement {
  teammember: string;
  phone: string;
  email: string;
  designation: string;
}

@Component({
  selector: 'app-boarddirectors',
  templateUrl: './boarddirectors.component.html',
  styleUrls: ['./boarddirectors.component.css'],
})
export class BoarddirectorsComponent implements OnInit {
  displayedColumns: string[] = [
    'name',
    'mobile_no',
    'email',
    'designation',
    'Action',
  ];

  ELEMENT_DATA: BoardElement[];
  listData = new MatTableDataSource<BoardElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<BoardElement>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('BoardTable') table: MatTable<BoardElement>;
  @Input() modulefor: string;
  parentId: string;
  deleteData: [];
  editindex: string = '-1';
  postform: FormGroup = new FormGroup({
    board_of_directors: new FormArray([]),
  });
  TEAM_MEMBER = [];
  resource = language;
  searchKey: string;

  PERMISSION_EDIT = AuthService.authentication('BoardDirectors', 'READ');
  PERMISSION_ADD = AuthService.authentication('BoardDirectors', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('BoardDirectors', 'DELETE');

  constructor(
    private businessinfoservice: BusinessInfoService,
    private router: Router,
    private dialog: MatDialog,
    private coreservice: CoreService,
    private activeRoute: ActivatedRoute,
    private othermeetinginfoservice: OtherMeetingService
  ) {}
  ngOnInit(): void {
   
    AuthService.authenticate_redir('BoardDirectors', ['READ']);
    this.getAllUsers();
    
  }

  getAllUsers() {
    var filter = '';
    this.othermeetinginfoservice.getFamilyUsers(filter).subscribe(
      (response) => {
        this.initialLoad();
        this.TEAM_MEMBER = response.body['data'];
      },
      (err) => {
        if (err.error.status != null) {
        } else {
        }
      }
    );
  }

  initialLoad() {
    if (this.modulefor == 'parent') {
      this.getparentboarddata();
    } else {
      this.getbusinessunitdata();
    }
    this.listData.sort = this.sort;
    this.listData.paginator = this.paginator;
  }

  getbusinessunitdata() {
    this.businessinfoservice.businessunitget('').subscribe(
      (response: any) => {
        const params = this.activeRoute.snapshot.queryParams;
        var data = _.find(response['data'], {_id:params['id']});
        this.listData.data = Object.values(
          data['board_of_directors']
        ) as BoardElement[];
        this.listData.data.forEach(val => {
          const userInfo = this.TEAM_MEMBER.find(data => data._id === val['user'])
          val['name'] = userInfo.name + ' ' + userInfo.lastname;
        })

        this.table.renderRows();
        this.parentId = data['_id'];
        this.deleteData = data['board_of_directors'];
      },
      (err) => {}
    );
  }

  getparentboarddata() {
    this.businessinfoservice.businessinfoget().subscribe(
      (response: any) => {
        this.listData.data = Object.values(
          response['data'][0]['info']['board_of_directors']
        ) as BoardElement[];
        console.log('DATA PASSED::::', this.listData.data);
        this.listData.data.forEach(val => {
          const userInfo = this.TEAM_MEMBER.find(data => data._id === val['user'])
          val['name'] = userInfo.name + ' ' + userInfo.lastname;
        })
        // const userInfo = this.TEAM_MEMBER.find(val => val._id === constdata.user)
        this.table.renderRows();
        this.parentId = response['data'][0]['info']['_id'];
        this.deleteData = response['data'][0]['info']['board_of_directors'];
      },
      (err) => {}
    );
  }

  openDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogboarddirectorsteamComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        id: this.parentId,
        prevdata: this.listData.data,
        index: this.editindex,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.initialLoad();
    });
  }

  edit(row) {
    console.log('row::', row);
    console.log('this.listData', this.listData);
    this.editindex = this.editindex = this.listData.data
      .findIndex((item) => {
        return item['_id'] === row['_id'];
      })
      .toString();

    if (this.editindex !== '-1') {
      this.openDialog('', '', '');
    }
  }
  onDelete(index) {
    this.coreservice
      .openConfirmDialog(this.resource.message('BOARDDIRECTORS_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.deleteData.splice(index, 1);
          var finalData = this.postform.getRawValue();
          for (let i = 0; i < this.deleteData.length; i++) {
            finalData['board_of_directors'].push(this.deleteData[i]);
          }
          this.businessinfoservice
            .updateBusinessData(finalData, this.parentId)
            .subscribe(
              (response) => {
                const result = response.body;
                this.coreservice.success(
                  this.resource.message('BOARDDIRECTORS_DELETED')
                );
                this.initialLoad();
              },
              (err) => {}
            );
        }
      });
  }
  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      console.log(data);
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [['Team Member', 'Mobile No.', 'Email ID', 'Designation']];
    this.listData.data.forEach((obj) => {
      let name = obj['name'] ? obj['name'] : '';
      let mobile_no = obj['mobile_no'] ? obj['mobile_no'] : '';
      let email = obj['email'] ? obj['email'] : '';
      let designation = obj['designation'] ? obj['designation'] : '';

      let arr = [name, mobile_no, email, designation];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      rowPageBreak: 'avoid',
    });
    if (action === 'save') {
      doc.save('board-directors.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      // window.open(doc.output('bloburl') as unknown as string, '_blank');
      doc.output('dataurlnewwindow', { filename: 'board-directors.pdf' });
    }
  }
}

<mat-card class="">
  <mat-card-content>
    <mat-tab-group class="tabgroup">
      <mat-tab label="Outcome" class="tabcolor">
        <app-newoutcome
          [parentid]="parentid"
          [agendaid]="agendaid"
          [viewdata]="viewdata"
        ></app-newoutcome>
      </mat-tab>
      <mat-tab label="To-Do" class="tabcolor">
        <app-outcometodo
          style="width: 100%; margin-left: 50px"
          [parentid]="parentid"
          [agendaid]="agendaid"
          [viewdata]="viewdata"
        ></app-outcometodo>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DialogcommiteeagendaComponent } from '@shared/components/dialogcommiteeagenda/dialogcommiteeagenda.component';
import { CommitteeService } from '@services/Committee.service';
import { CoreService } from '@services/CoreService';
@Component({
  selector: 'app-agendadeferred',
  templateUrl: './agendadeferred.component.html',
  styleUrls: ['./agendadeferred.component.css'],
})
export class AgendadeferredComponent implements OnInit {
  parentId: string = '';
  isEdit: boolean = false;
  showSlab: boolean = true;
  @Input() type = 'is_hybrid';
  topics: string[] = [];
  @Output() reload = new EventEmitter();

  @Input() set defered_topics(value) {
    console.log('value2', value);
    if (value.length >= 0) {
      this.topics = value;
    }
  }
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private dialog: MatDialog,
    private committeeService: CommitteeService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentId = data.id;
        // this.getMeetingData(this.parentId);
      }
    });
  }
  publishClick() {
    this.router.navigate(['/committee/rationalize'], {
      queryParams: {
        id: this.parentId,
      },
    });
  }

  getMeetingData(id) {
    var filter = '/' + id;

    this.committeeService.getCommitteeMeetings(filter).subscribe(
      (response: any) => {
        const result = response.data;
        console.log(response.data.deferred_agendas);
        this.topics = [];
        if (result.deferred_agendas.length > 0) {
          for (let i = 0; i < result.deferred_agendas.length; i++) {
            this.topics.push(result.deferred_agendas[i]);
            console.log('this.topics', this.topics);
          }
        }
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  openAgendaDialog(mode = 'add', id: '') {
    const dialogRef = this.dialog.open(DialogcommiteeagendaComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: mode === 'add' ? 'Add Agenda' : 'Edit Agenda',
        actionbutton: '',
        cancelbutton: '',
        id: this.parentId,
        isEdit: mode === 'add' ? false : true,
        agendaId: id,
        defer: mode === 'add' ? false : true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.activeRoute.queryParams.subscribe((data) => {
        if (data.id != undefined) {
          this.parentId = data.id;
          // this.getMeetingData(this.parentId);
          this.reload.emit('');
        }
      });
    });
  }
  onUndefer(row) {
    this.committeeService
      .deferOrAddAgenda(this.parentId, row._id, 'false')
      .subscribe((res) => {
        this.coreService.success('The selected agenda restored sucessfully!');
        // this.getMeetingData(this.parentId);
        this.reload.emit('');
      });
  }
  onEdit(row) {
    console.log(row)
    this.openAgendaDialog('edit', row._id);
  }
  previous() {
    this.router.navigate(['committee/participants'], {
      queryParams: { id: this.parentId },
    });
  }
  onDelete(topic) {
    console.log(topic);
    this.committeeService
      .deleteCommitteeAgendaById(this.parentId, topic._id)
      .subscribe(
        (res) => {
          this.coreService.success('The selected agenda deleted sucessfully!');
          // this.getMeetingData(this.parentId);
          this.reload.emit('');
        },
        (err) => {
          console.log(err);
        }
      );
  }
}

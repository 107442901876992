import { Component, OnInit,ViewChild } from '@angular/core';
import { FamiliesService } from '@services/Families.service';
import { TemplateService } from '@services/Template.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

interface userType {
  value: string;
  viewValue: string;
}
export interface UserElement {
  userId: number;
  name: string;
  email: string;
  mobile: string;
  role: string;
  status: string;
  created: string;
  updated: string;
}

@Component({
  selector: 'app-othertemplate',
  templateUrl: './othertemplate.component.html',
  styleUrls: ['./othertemplate.component.css']
})
export class OthertemplateComponent implements OnInit {
  userstype: userType[] = [
    {value: 'all-family-admin', viewValue: 'All Family Admins'},
    {value: 'selected-family-admin', viewValue: 'Selected Family Admins'},
    {value: 'all-family-members', viewValue: 'All Family Members'},
    {value: 'selected-family-members', viewValue: 'Selected Family Admins'},
    {value: 'all-family-office-team', viewValue: 'All Family Office Team'},
    {value: 'selected-family-office-team', viewValue: 'Selected Family Office Team'}
  ];
  listData=[];
  ELEMENT_DATA: UserElement[];
  displayedColumns: string[] = [ 'Check','name', 'mobile', 'email',  'role', 'status'];
  tableData = new MatTableDataSource <UserElement>();
  dataSource: MatTableDataSource<any>;
  selection = [];
  finalData:any;
  localstoragedata:any;

  @ViewChild('userTable') table: MatTable<UserElement>;

  constructor(
    private familiesService: FamiliesService,
    private templateService :TemplateService) { }
  
  ngOnInit(): void {
    this.familiesService.getFamilies().subscribe(response=> {
      
      const result = response.body;
      this.listData=response.body['results'];
      console.log('>>>>>>>>>>>',this.listData)
 

     }, (err) => {

        if(err.error.status != null){
        }else{
        }
      
   })
  }
  onSelectionChanges(familyid)
  {
    var id=1;
    console.log(">>>>>>>",familyid.value)
    this.templateService.familyuserget(familyid.value).subscribe(response=> {
     // const result = response.body;
      this.tableData.data=response['results'] as UserElement[]
      this.table.renderRows();
     

     }, (err) => {

        if(err.error.status != null){
        }else{
        }
      
   })
  }
  updateCheckedList(eve,element)
  {
    
    if(eve.checked==true)
    {
      this.selection.push(element._id);
     
    }
    else{
      this.selection = this.selection.filter(item => item !== element._id);

    }
    console.log(this.selection);
  }
  SaveData(){
    this.localstoragedata=JSON.parse(localStorage.getItem('LOGGED_USER'));
    this.finalData={details: "SMS Message 123",
     "type": "sms",
     "isDel": false,
     "updated_by": this.localstoragedata._id,
     "invites":this.selection 
   }

   this.templateService.addMessage(this.finalData).subscribe(response=> {
      console.log(">>>>>>>>",this.finalData)
    

   }, (err) => {


    
 })
  }
}

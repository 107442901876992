import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpParams,
} from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';

@Injectable({
  providedIn: 'root',
})
export class CommitteeService {
  constructor(private http: HttpClient) {}

  getCommitteeTemplates() {
    return this.http.get(apiendpoints.COMMITTEE + '?user=000000000000&', {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }

  getCommittees() {
    return this.http.get(
      apiendpoints.COMMITTEE + '?showunpublished=true&showadmin=true',
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }

  getCommittee(id) {
    return this.http.get(apiendpoints.CREATECOMMITTEE + '/' + id, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }

  addCommittee(data: any) {
    console.log(data);
    if (data._id == '') {
      delete data._id;
      return this.http.post(
        apiendpoints.CREATECOMMITTEE,
        { committee: data },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            `Token ` + localStorage.getItem('ACCESS_TOKEN')
          ),
          observe: 'response',
        }
      );
    } else {
      return this.http.put(
        apiendpoints.CREATECOMMITTEE + '/' + data._id,
        { committee: data },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            `Token ` + localStorage.getItem('ACCESS_TOKEN')
          ),
          observe: 'response',
        }
      );
    }
  }

  removeCommittee_old(id) {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Token ` + localStorage.getItem('ACCESS_TOKEN'),
      }),
      body: {
        roleId: id,
      },
    };

    return this.http.delete(apiendpoints.COMMITTEE, options);
  }

  removeCommittee(data) {
    data.isDel = true;
    return this.http.put(
      apiendpoints.CREATECOMMITTEE + '/' + data._id,
      { committee: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }

  getCommitteeCategories(filter) {
    return this.http.get(apiendpoints.COMMITTEECATEGORIES + filter, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }

  getCommitteesFilter(type, committee) {
    var whr = 'filter[type]=' + type;

    if (committee != '') {
      whr += '&filter[committee]=' + committee;
    }

    return this.http.get(apiendpoints.CREATECOMMITTEE + '?' + whr, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }

  postCommitteeCategories(data: any) {
    return this.http.post(
      apiendpoints.COMMITTEECATEGORIES,
      { category: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }
  subCategoryGet(id) {
    return this.http.get(apiendpoints.SUBCATEGORIES + '/' + id, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Token ' + localStorage.getItem('ACCESS_TOKEN')
      ),
    });
  }
  getCommitteeMeetings(filter: any) {
    return this.http.get(apiendpoints.IDPMEETINGSADD + filter, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Token ' + localStorage.getItem('ACCESS_TOKEN')
      ),
    });
  }

  deleteMeetingInviteeById(meetingId: string, InviteeId: string) {
    return this.http.delete(
      apiendpoints.IDPMEETINGSADD + `/${meetingId}/${InviteeId}`,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }

  getCountOfCommitteeMeetingsByCommitteeId(id: string) {
    return this.http.get(apiendpoints.IDPMEETINGSADD + '/committee/' + id, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Token ' + localStorage.getItem('ACCESS_TOKEN')
      ),
    });
  }

  removeCommitteeMeetings(id: string) {
    return this.http.delete(apiendpoints.IDPMEETINGSADD + '/' + id, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Token ' + localStorage.getItem('ACCESS_TOKEN')
      ),
    });
  }
  postCommitteeMeetings(data: any) {
    return this.http.post(
      apiendpoints.IDPMEETINGSADD,
      { meeting: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  updateCommitteeMeetings(id: any, data: any) {
    return this.http.put(
      apiendpoints.IDPMEETINGSADD + '/' + id,
      { meeting: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  committeeParticipants(data: any, id: string) {
    return this.http.put(
      apiendpoints.IDPMEETINGSADD + '/' + id,
      {
        meeting: {
          temporary_members: data.temporary_members,
          members: data.members,
        },
      },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  committeeParticipantsAdd(data: any, id: string) {
    return this.http.put(
      apiendpoints.IDPMEETINGSADD + '/' + id,
      {
        meeting: {
          temporary_members: data.temporary_members,
          members: data.members,
          meeting_status: data.meeting_status
        },
      },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  committeeUpdateStatus(data: any, id: string) {
    return this.http.put(
      apiendpoints.IDPMEETINGSADD + '/' + id,
      {
        meeting: {
          meeting_status: data.meeting_status
        },
      },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  // defer or add agenda
  deferOrAddAgenda(meetingId, agendaId, defer) {
    return this.http.patch(
      apiendpoints.IDPMEETINGSADD +
        '/' +
        meetingId +
        '/agenda/' +
        agendaId +
        '?defer=' +
        defer,
      {},
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  // get agenda by id
  getAgendaById(meetingId, agendaId, defer) {
    return this.http.get(
      apiendpoints.IDPMEETINGSADD +
        '/' +
        meetingId +
        '/agenda/' +
        agendaId +
        '?defer=' +
        defer,

      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  // add comittee agenda
  committeeAgenda(data: any, id: string) {
    return this.http.put(
      apiendpoints.IDPMEETINGSADD + '/' + id + '/agenda',
      {
        agenda: data,
      },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  // update/edit committee agenda
  updateCommitteeAgenda(data: any, meetingId: string, agendaId: string) {
    return this.http.put(
      apiendpoints.IDPMEETINGSADD + '/' + meetingId + '/agenda/' + agendaId,
      {
        agenda: data,
      },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  deleteCommitteeAgendaById(meetingId: string, agendaId: string) {
    return this.http.delete(
      apiendpoints.IDPMEETINGSADD + '/' + meetingId + '/agenda/' + agendaId,

      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
  committeemompost(data: any, id) {
    return this.http.post(
      apiendpoints.IDPMEETINGSADD + '/' + id + '/updateMom',
      data,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }

  committeedefer(meeting, agendaid) {
    return this.http.patch(
      apiendpoints.IDPMEETINGSADD +
        '/' +
        meeting +
        '/agenda/' +
        agendaid +
        '?defer=true',
      {},
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }

  committeevotes(meeting, agendaid, data: any) {
    return this.http.post(
      apiendpoints.IDPMEETINGSADD +
        '/' +
        meeting +
        '/agendas/' +
        agendaid +
        '/takeVotes',
      { votes: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }

  getCommitteevotes(meeting, agendaid) {
    return this.http.get(
      apiendpoints.IDPMEETINGSADD +
        '/' +
        meeting +
        '/agendas/' +
        agendaid +
        '/showVotingResult',
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }

  getCommitteeoverride(meeting, agendaid) {
    return this.http.post(
      apiendpoints.IDPMEETINGSADD +
        '/' +
        meeting +
        '/agendas/' +
        agendaid +
        '/overrideVotingResult?responseType=agenda',
      {},
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }

  postCommitteeTask(meeting, agendaid, data: any) {
    return this.http.post(
      apiendpoints.IDPMEETINGSADD +
        '/' +
        meeting +
        '/agendas/' +
        agendaid +
        '/addResolution?responseType=agenda',
      { tasks: data, resolution: data.resolution },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Token ' + localStorage.getItem('ACCESS_TOKEN')
        ),
      }
    );
  }
}

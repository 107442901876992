import { environment } from '../../../environments/environment';

export class language {
  public static english: { [key: string]: string } = {
    ACCOUNT_DISABLE:
      'You account is disabled. Please contact Administrator to actiavate your account.',

    AGENDA_PUBLISHED: 'Agenda published sucessfully',
    AGENDA_EDITED: 'Agenda edited sucessfully',
    BOOKINGS: 'Bookings',
    SEARCH_BY_ROLE: 'Search by Role',
    ROLE: 'Role',
    ROLES: 'Roles',
    ACTION: 'Action',
    ROLE_DELETE: 'Are you sure you want to delete the Role?',
    ROLE_DELETED: 'Selected role has been deleted successfully!',
    CATEGORY_DELETE_MSG: 'Are you sure you want to delete the Category?',
    CATEGORY_DELETED_MSG: 'Selected category has been deleted successfully!',
    REPORT_DELETE_MSG: 'Are you sure you want to delete the Report?',
    REPORT_DELETED_MSG: 'Selected report has been deleted successfully!',
    ROLE_UPDATE: 'Role updated successfully',
    ROLE_SAVE: 'Role saved successfully',
    ROLE_NOT_EDIT: 'You are not allowed to edit this role',
    ROLE_NOT_DELETE: 'You are not allowed to delete this role',

    SEARCH_BY_MODULE: 'Search by Module',
    MODULE: 'Module',
    MODULES: 'Modules',
    MODULE_DELETE: 'Are you sure you want to delete the Module?',
    MODULE_DELETED: 'Selected Module has been deleted successfully!',
    MODULE_SAVE: 'Module saved successfully!',
    MODULE_UPDATE: 'Module updated successfully!',

    SEARCH_BY_USER: 'Search User',
    USER: 'User',
    USERS: 'Users',
    USER_DELETE: 'Are you sure you want to delete the User?',
    USER_DELETED: 'Selected User has been deleted successfully!',
    USER_SAVE: 'User saved successfully',
    USER_UPDATE: 'User update successfully',

    SEARCH_BY_FAMILY: 'Search Family',
    FAMILY: 'Family Management',
    FAMILIES: 'Family Management',
    FAMILY_DELETE: 'Are you sure you want to delete the Family?',
    FAMILY_SAVE: 'Selected Family has been deleted successfully!',

    SEARCH_BY_KNOWLEDGE: 'Search',
    KNOWLEDGE: 'Knowledge Hub',
    KNOWLEDGES: 'Files',
    KNOWLEDGE_DELETE: 'Are you sure you want to delete the File?',
    KNOWLEDGE_DELETED: 'Selected File has been deleted successfully!',
    KNOWLEDGE_ACTION: 'Add New',
    KNOWLEDGE_UPDATE: 'Document updated successfully',
    KNOWLEDGE_SAVE: 'Document saved successfully',

    SEARCH_BY_SUBSCRIPTION: 'Search Subscription Packages',
    SUBSCRIPTION: 'Subscription Package',
    SUBSCRIPTIONS: 'Subscription Packages',
    SUBSCRIPTION_DELETE:
      'Are you sure you want to delete the Subscription Packages?',
    SUBSCRIPTION_SAVE:
      'Selected Subscription Packages has been deleted successfully!',
    SUBSCRIPTION_ACTION: 'Add New',

    SECURITY: 'Security Management',
    SEARCH_BY_SECURITY: 'Search User',
    ACTIVITY_LOG: 'Activity Log',
    ACTIVITY_LOG_NOLOGS: 'No Activity Logs exists',

    TEMPLATE_MANAGEMENT: 'Template Management',

    KNOWLEDGE_CATEGORY: 'Knowledge Hub Category',
    KNOWLEDGE_CATEGORIES: 'Knowledge Hub Categories',
    KNOWLEDGE_CATEGORIES_DELETE:
      'Are you sure you want to delete the Category?',
    KNOWLEDGE_CATEGORIES_DELETED:
      'Selected Category has been deleted successfully!',
    SPACE_TYPE_DELETE:
      'Are you sure you want to delete the Space Type?',
    SPACE_TYPE_DELETED:
      'Space Type has been deleted successfully!',
    KNOWLEDGE_CATEGORIES_SAVE: 'Category saved successfully!',
    KNOWLEDGE_CATEGORIES_ACTION: 'Add New',

    PERSONAL_TASK: 'Personal Task',
    PERSONAL_TASKS: 'Personal Tasks',
    SEARCH_BY_PERSONALTASKS: 'Search Task',
    PERSONAL_TASKS_DELETE: 'Are you sure you want to delete the Task?',
    PERSONAL_TASKS_DELETED: 'Selected task has been deleted successfully!',
    PERSONAL_TASKS_SAVE: 'Task saved successfully!',
    PERSONAL_TASKS_ACTION: 'Add New',

    MEMBERSHIP_TASK: 'Membership',
    MEMBERSHIP_TASKS: 'Membership',
    SEARCH_BY_MEMBERSHIP: 'Search Membership',
    MEMBERSHIP_DELETE: 'Are you sure you want to delete the Membership?',
    MEMBERSHIP_DELETED: 'Selected Membership has been deleted successfully!',
    MEMBERSHIP_SAVE: 'Membership saved successfully!',
    MEMBERSHIP_ACTION: 'Add New',

    MEMBER: 'Member',
    MEMBERS: 'MemberS',
    SEARCH_BY_MEMBER: 'Search Member',
    MEMBER_DELETE: 'Are you sure you want to delete the Member?',
    MEMBER_DELETED: 'Selected Member has been deleted successfully!',
    MEMBER_SAVE: 'Member saved successfully!',
    MEMBER_ACTION: 'Add New',

    DECLARATION: 'Declaration',
    DECLARATIONS: 'Declarations',
    SEARCH_BY_DECLARATION: 'Search Declaration',
    DECLARATION_DELETE: 'Are you sure you want to delete the Declaration?',
    DECLARATION_DELETED: 'Selected Declaration has been deleted successfully!',
    DECLARATION_SAVE: 'Declaration saved successfully!',
    DECLARATION_ACTION: 'Add New',

    MYSUBSCRIPTION: 'My Subscription',

    ROLEPERMISSION: 'Role Permissions',
    ROLEPERMISSION_UPDATE: 'Permissions updated successfully',

    FAMILYINFO: 'Family Info',
    PHOTOS: 'Photo',
    MEDIA: 'Media',

    COMMITTEE: 'Committee',
    COMMITTEE_TEMPLATE: 'COMMITTEE TEMPLATE',
    COMMITTEES: 'Committees',
    SEARCH_BY_COMMITTEE: 'Search Committee',
    COMMITTEE_DELETE: 'Are you sure you want to delete the Committee?',
    COMMITTEE_DELETED: 'Selected Committee has been deleted successfully!',
    COMMITTEE_SAVE: 'Committee saved successfully!',
    COMMITTEE_PUBLISH: 'Committee Published successfully!',
    COMMITTEE_ACTION: 'Add New',
    NO_SUB_COMMITTEE:
      'Error - No Sub-Committee exists for the selected Committee.',
    COMMITTEE_MEETING_DELETE:
      'Are you sure you want to delete the Committee Meeting?',
    GOAL_DELETE:
      'Are you sure you want to delete the goal?',
    OTHER_MEET_DELETE:
      'Are you sure you want to delete the meeting?',
    COMMITTEE_MEETING_DELETED:
      'Selected Committee Meeting has been deleted successfully!',
    MEDIA_DELETE:
      'Are you sure you want to delete the Media?',
    MEDIA_DELETED:
      'Selected Media has been deleted successfully!',
    IMAGE_DELETE:
      'Are you sure you want to delete the Image?',
    BUSINESSINFO: 'Business Info / Parent Company',
    BUSINESSINFOTITLE: 'Business Info',
    ADDBUSINESSINFO: 'Business Info / Business Unit /Add New Business Unit',

    LEARDERSHIPTEAM: 'Add Leadership Team',
    LEADERSHIP_DELETE: 'Are you sure you want to delete the Leadership team ?',
    LEADERSHIP_DELETED:
      'Selected Leadership team, has been deleted successfully!',
    LEADERSHIP_SAVE: 'Leadership team saved successfully',
    LEADERSHIP_UPDATE: 'Leadership team updated successfully',

    BOARDDIRECTORSTEAM: 'Add Board Team',
    BOARDDIRECTORS_DELETE:
      'Are you sure you want to delete the Board of director ?',
    BOARDDIRECTORS_DELETED:
      'Selected board of director, has been deleted successfully!',
    BOARDDIRECTORS_SAVE: 'Board of director saved successfully',
    BOARDDIRECTORS_UPDATE: 'Board of director updated successfully',

    BUSINESS_UNIT_DELETE:
      'Are you sure you want to delete the Business Unit? Note: All the leadership team and board of directors related to this will also be deleted.',
    BUSINESS_UNIT_DELETED:
      'Selected Business Unit has been deleted successfully!',
    BUSINESS_UNIT_SAVE: 'Business Unit saved successfully',
    BUSINESS_UNIT_UPDATE: 'Business Unit updated successfully',

    SEARCH_BY_COMMONSPACE: 'Search',
    COMMONSPACE: 'Common Space',
    COMMONSPACES: 'Common Spaces',
    COMMONSPACE_DELETE: 'Are you sure you want to delete the Common Spaces?',
    COMMONSPACE_DELETED:
      'Selected Common Spaces has been deleted successfully!',
    COMMONSPACE_ACTION: 'Add New',
    COMMONSPACE_UPDATE: 'Common Spaces updated successfully',
    COMMONSPACE_SAVE: 'Common Spaces saved successfully',

    DASHBOARD: 'Dashboard',
    SEARCH_BY_POLL: 'Search by Poll',
    POLL: 'Poll',
    POLLS: 'Polls',
    POLL_DELETE: 'Are you sure you want to delete the Poll?',
    POLL_DELETED: 'Selected Poll has been deleted successfully!',
    POLL_UPDATE: 'Poll updated successfully',
    POLL_SAVE: 'Poll saved successfully',

    SETTINGS: 'Settings',

    BUSINESS_IMAGE_DELETE: 'Are you sure you want to delete the image?',
    BUSINESS_IMAGE_DELETED: 'Selected image has been deleted successfully!',
    BUSINESS_IMAGE_SAVE: 'Image saved successfully',
    BUSINESS_IMAGE_UPDATE: 'Image updated successfully',
    BUSINESSIMAGE: 'Add Image',

    FINANCIAL_REPORTS_DELETE: 'Are you sure you want to delete the Report?',
    FINANCIAL_REPORTS_DELETED: 'Selected Report has been deleted successfully!',

    BOARD_READS_DELETE:  'Are you sure you want to delete the Board Reads Report?',
    BOARD_READS_DELETED:  'Selected Board Reads Report has been deleted successfully!',
    ACHIEVEMENTS: 'Achievements',
    ACTIVITYLOGS: 'Acivity Log',
    POLLS_SAVE: 'Poll saved successfully',
    POLLS_UPDATE: 'Poll update successfully',
    SPACETYPE: 'Add Space Type',
    ADDSPACE: 'Add Space',
    ADDBOOKING: 'Book Space',
    FAMILYOFFICE: 'Family Office',
    REQUEST: 'Requests',
    INFORMATION_UPDATED: 'Information saved successfully',

    TICKET_UPDATED: 'Ticket saved successfully',
    IDP: 'IDP Meeting',
    BUSINESS_UNIT_UPDATED: 'Business Unit updated successfully',
    LEADERSHIPT_UPDATED: 'Leadership Modified successfully',
    LEADERSHIP_TEAM: 'Add Leadership Team',
    BOOKING_SAVE: 'Space booked successfully',
    BOOKING_UPDATE: 'Booked space updated successfully',
    GOAL: 'Goal',
    ADDGOAL: 'Add Goal',
    ADDDEVGOAL: 'Add Development Goals',
    OTHERMEETING: 'Other Meetings',
    PARTICIPANTS: 'Participants',
    MEETINGDETAILS: 'Meeting Details',
    OTHERMESSAGE: 'Other Message Template',
    MESSAGETEMPLATE: 'Message Template',
    GOALCATEGORY: 'Goal Category',
    SCHEDULE: 'Schedule Meeting',
    IDPMEETING: 'IDP Meeting / Create meeting',
    MEETINGPARTICIPANTS: 'Share Invites via email',
    ADDPARTICIPANTS: 'Add Participants',
    INVITE: 'Meeting Invite Sent',

    CATEGORIES_REPORTS: 'Categories and Reports',
    CATEGORIES: 'Categories',
    CATEGORY: 'Category',
    ADD_CATEGORIES: 'Add Categories',
    REPORTS: 'Reports',
    REPORT_DELETE: 'Are you sure you want to delete the Report?',
    REPORT_DELETED: 'Selected Report has been deleted successfully!',
    REPORT_UPDATE: 'Report updated successfully',
    REPORT_SAVE: 'Report saved successfully',
    ADD_REPORTS: 'Add Reports',
    ADD_REMARKS: 'Add Remarks',

    COMMITTEE_MEMBER_MESSAGE:
      'Committe should have atleast 1 secretary',
    COMMITTEE_MEMBER_MESSAGE_CHMN:
      'Committe should have atleast 1 chairman',
    GOAL_ADD: 'Goal added successfully',
    IDPTITLE: 'Individual Development Plan',
    CATEGORYCREATION: 'Category Creation',
    CATEGORY_SAVE: 'Category saved successfully',
    CREATE_MEETING: 'IDP Meeting/Create Meeting',
    MOM_PUBLISHED: 'Minutes Published Successfully',
    CATEGORY_DELETE: 'Category Deleted Successfully',
    COMMITTEEMEETINGS: 'Committee Meetings',
    GOAL_STATUS_UPDATE: 'Goal Status Updated Successfully',

    SAVED_SUCCCESSFULLY: "Saved Successfully"
  };

  public static message(key) {
    return language.english[key];
  }
}

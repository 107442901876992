<div class="content p30"> 

    <h5>{{resource.message('COMMITTEE')}}</h5>    
 

    
    <mat-card class="">
      <mat-card-header >            
        <mat-card-title > {{resource.message('COMMITTEE')}} </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <br />
        <mat-tab-group class="tabgroup">
          <mat-tab label="Committees"> 
            <app-committeetemplate [modulefor]="'committee'" [allowAdd]="true" [allowsearch]="true"></app-committeetemplate> 
          </mat-tab>
          <mat-tab label="Committees Template" class="test">
            <app-committeetemplate [modulefor]="'template'" [allowAdd]="false" [showEdit]="false" [showDelete]="false" [showTemplate]="true"></app-committeetemplate>          
          </mat-tab>
          
        </mat-tab-group>



      </mat-card-content>
    </mat-card>



  </div>

import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators, FormBuilder, FormArray, NgForm } from '@angular/forms';
import { FamiliesService } from '@services/Families.service';
import { ActivatedRoute, Router } from '@angular/router';
import {CoreService} from '../../../../shared/services/CoreService';
import { UACService } from '@services/UAC.service';
import { constants } from '@shared/config/constants';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-addfamily',
  templateUrl: './addfamily.component.html',
  styleUrls: ['./addfamily.component.css'],
  providers: [DatePipe]
})
export class AddfamilyComponent implements OnInit {

  marriedControl = new FormControl('UnMarried');

  isEdit:Boolean = false;
  form :FormGroup = new FormGroup({
    _id:new FormControl(0),
    name:new FormControl('',Validators.required),
    business_domain:new FormControl(''),
    adminname:new FormControl(''),
    email:new FormControl('',Validators.required),
    mobile:new FormControl(''),
    address: new FormControl(''),
    created_at: new FormControl(undefined,Validators.required),
    web: new FormControl('',Validators.required),
    admin_portal_status: new FormControl(''),
    app_status: new FormControl(''),
    subscription: new FormControl(''),
    noofuser: new FormControl(''),
    frequency: new FormControl(''),
    
    hosting_type: new FormControl(''),
    payment_status: new FormControl('')
    
  })

  DATE_FORMAT = constants.GLOBAL_DATE_FORMAT
  ADVISOR_LIST:any;
  ROLES_LIST:any;
  CONSTANT = constants;

  portal_status: false;
  app_status: false;

  constructor(private familiesServer: FamiliesService, private router : Router, private uacservice: UACService,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private fb: FormBuilder,
    public datepipe: DatePipe
   
    ) {

     

    this.activeRoute.queryParams.subscribe(data => {

      if(data.id!=undefined)
      {

        this.familiesServer.getFamily(data.id).subscribe(response=> {
      
          const result = response.body["family"];
        
          this.marriedControl.setValue(result.married);

          this.form.patchValue({
            _id:result._id,
              name:result.name ,
              business_domain:result.middlbusiness_domainename,
              adminname:result.user.name + ' '+ result.user.lastname,
              email:result.email,
              address:result.address["corporate_office"],
              mobile:result.mobile,
              created_at:this.datepipe.transform(result.created_at, this.DATE_FORMAT),
              web:result.website,
              admin_portal_status:result.admin_portal_status,              
              app_status:result.app_status,
              subscription: (result.subscription!=null)?result.subscription.subscription:'',
              noofuser: (result.subscription != null)?result.subscription.users_allowed:"",
              frequency: (result.subscription != null)?result.subscription.cycle:"",
              hosting_type: result.hosting_type,
              payment_status: result.payment_status
              
            
          })
 
            this.portal_status = result.admin_portal_status;
            this.app_status = result.app_status;
          
          Object.keys(this.form.controls).forEach(key => {
            this.form.controls[key].disable(); 
          });

          
        
 

 
        });
      }else{
        this.router.navigate(['families']);

      } 
    })

   }

 

  ngOnInit(): void {

    
    
      

  }
 
  onsubmit(){

    let raw = {
      _id: this.form.get('_id').value,
      admin_portal_status: this.form.get('admin_portal_status').value,
      app_status: this.form.get('app_status').value,
    }

    console.log(raw);

    this.familiesServer.addFamily(raw).subscribe(response=> {
      
            this.coreservice.success("Family saved");
             this.router.navigate(['families']);
    });

  }
}



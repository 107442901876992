import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IdpService } from '@services/Idp.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommitteeService } from '@services/Committee.service';

@Component({
  selector: 'app-dialogaddnote',
  templateUrl: './dialogaddnote.component.html',
  styleUrls: ['./dialogaddnote.component.css'],
})
export class DialogaddnoteComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  notes: string;
  labelText: string;
  hidebtn: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogaddnoteComponent>,
    private idp: IdpService,
    private router: Router,
    private committeeService: CommitteeService,
  ) {}

  ngOnInit(): void {
    if (this.data.type === 'cancel') {
      this.labelText = this.data.message;
    } else {
      this.labelText = this.data.message;
    }
    if (this.data.type === 'shownote') {
      if(this.data.variant === 'committee') {
        this.getCommitteeMeetings(this.data.id);
      }
      else {
        this.getMeetings(this.data.id);
      }
      this.labelText = this.data.message;
    }
  }

  getCommitteeMeetings(id) {
    var filter = '';
    filter = '/'+id;
    this.committeeService.getCommitteeMeetings(filter).subscribe(
      (response: any) => {
        console.log('rationalize_notes', response.data?.rationalize_notes);
        this.notes = response.data.rationalize_notes;
        this.hidebtn = true;
      },
      (err) => {
        console.log('errrrrrrr', err);
      })
  }

  doneClick() {
    if (this.data.type === 'cancel') {
      this.idp
        .idpcancel(this.data.id, {
          cancel_note: this.notes,
        })
        .subscribe(
          (response: any) => {
            let route =
              this.data.meeting !== undefined ? 'committee-meetings' : 'idp';
            this.router.navigate([route]);
            this.dialogRef.close(false);
          },
          (err) => {
            console.log('errrrrrrr', err);
          }
        );
    } else {
      var addAgenda = 'true';

      this.idp.idpnotepost(this.notes, this.data.id).subscribe(
        (response: any) => {
          this.newItemEvent.emit(addAgenda);
        },
        (err) => {
          console.log('errrrrrrr', err);
        }
      );
      this.dialogRef.close(false);
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  getMeetings(id) {
    //alert('IN');
    var filter = '';
    filter = '?filter[_id]=' + id; //+this.localstoragedata._id
    this.idp.idpMeetingGet(filter).subscribe(
      (response: any) => {
        console.log('rationalize_notes', response.data[0]?.rationalize_notes);
        this.notes = response.data[0].rationalize_notes;
        this.hidebtn = true;
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
}

<div class="content p30">
  <div id="block_container">
    <a
      routerLink="/idp"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >

    <div id="bloc2">
      <h5>{{ resource.message("IDP") }} / Development areas</h5>
    </div>
  </div>

  <form [formGroup]="form">
    <fieldset>
      <mat-card class="">
        <mat-card-content>
          <span class="devarea">Goals</span>

          <hr />
          <mat-grid-list cols="2" rowHeight="100px">
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Category</mat-label>
                <input
                  matInput
                  placeholder="Category"
                  formControlName="category"
                  readonly
                />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="subcategory">
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Sub-Category</mat-label>
                <input
                  matInput
                  placeholder="Sub-Category"
                  formControlName="subcategory"
                  readonly
                />
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
          <br />
          <span class="devarea">Goal Progress</span>

          <hr />

          <div style="float: right">
            <button mat-icon-button (click)="edit()">
              <mat-icon>mode_edit</mat-icon>
            </button>
            <!-- <button mat-icon-button color="warn">
              <mat-icon>delete_outline</mat-icon>
            </button> -->
          </div>
          <br />
          <br /><br />
          <mat-grid-list cols="2" rowHeight="100px">
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Goal</mat-label>
                <input
                  matInput
                  placeholder="Goal"
                  formControlName="goal"
                  readonly
                />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-select
                (selectionChange)="onStatusUpdate($event.value)"
                  placeholder="Status"
                  formControlName="status"
                  class="editselect"
                >
                  <mat-option
                    *ngFor="let type of STATUS_LIST | keyvalue"
                    [value]="type.key"
                    >{{ type.value }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
          <br />
          <!-- <span class="devarea">Minutes</span>

          <hr /> -->
          <!-- <mat-grid-list cols="3" rowHeight="100px">
            <mat-grid-tile>
              <mat-label>Name of Meeting</mat-label>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-label>Date of Meeting</mat-label>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-label>Minutes of Meeting</mat-label>
            </mat-grid-tile>
          </mat-grid-list> -->
          <span class="devarea">To do List</span>
          <hr />
          <div class="col-md-12" *ngFor="let topic of allTodo; index as i">
            <mat-card>
              <label>{{ topic.todo }}</label>
            </mat-card>
          </div>
          <br />
          <br />
          <!-- <button
            mat-flat-button
            color="primary"
            (click)="viewCompleted()"
            style="float: right"
          >
            View Completed
          </button> -->

          <span class="devarea">Remarks</span>
          <hr />
          <br />
          <button
            mat-flat-button
            color="primary"
            (click)="openRemark('', '', '')"
            style="float: right"
          >
            Add Remark
          </button>
          <br />
          <div class="col-md-12" *ngFor="let topic of allRemarks; index as i">
            <div class="log-1">
              <div class="logs-line">
                <span class="logs-circle">
                  <div class="dotted-line">
                    <span class="small-circle-line"></span>
                    <span class="small-circle-line"></span>
                    <span class="small-circle-line"></span>
                    <span class="small-circle-line"></span>
                    <span class="small-circle-line"></span>
                    <span class="small-circle-line"></span>
                    <span class="small-circle-line"></span>
                  </div>
                </span>
              </div>

              <div class="logs-content">
                <p>{{ topic.remark }}</p>
              </div>
            </div>
          </div>
          <br />
          <br />
        </mat-card-content>
      </mat-card>
    </fieldset>
  </form>
</div>

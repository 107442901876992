import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators} from '@angular/forms';
import { UACService } from '@services/UAC.service';
import { ActivatedRoute, Router } from '@angular/router';
import {CoreService} from '../../../../shared/services/CoreService';
import { language } from '@shared/config/language';
import { FamiliesService } from '@services/Families.service';
import { constants } from '@shared/config/constants';

@Component({
  selector: 'app-addrole',
  templateUrl: './addrole.component.html',
  styleUrls: ['./addrole.component.css']
})
export class AddroleComponent implements OnInit {

  isEdit:Boolean = false;
  form :FormGroup = new FormGroup({
    _id:new FormControl(0),
    role:new FormControl('',Validators.required),
    family:new FormControl('',Validators.required),
    isDel: new FormControl('',Validators.required),
    isSystem: new FormControl('',Validators.required),
    updated_by: new FormControl('',Validators.required)
    
  })

  FAMILY_LIST:any;

  resource = language;
  localstoragedata:any;
  labelText:String='Add Role';

  constructor(private uacservice: UACService, private router : Router,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private familiesService: FamiliesService,

   
    ) {
 
       this.getAllFamilies();
    this.activeRoute.queryParams.subscribe(data => {

      if(data.id!=undefined)
      {
        this.labelText='Edit Role';
        this.uacservice.getRole(data.id).subscribe(response=> {
      
          const result = response.body["role"];
          console.log(result.role);
            this.form.setValue({_id:result._id,role:result.role, isDel:result.isDel, isSystem:result.isSystem,updated_by:result.updated_by, family:result.family._id})
            this.isEdit = true;

            console.log(this.form);
        });
      } 
    })

   }

  ngOnInit(): void {
    this.localstoragedata=JSON.parse(localStorage.getItem('LOGGED_USER'));
    if(this.localstoragedata.role=='admin')
    {
      this.form.patchValue({family:this.localstoragedata.family._id})
    }
  
    this.form.patchValue({
      _id:0,
      role:'',
      isDel:false,
      isSystem: false,
      updated_by: "5e3a77df4a0460002a8083ae"
  })


  }

  public getAllFamilies()
  {

  
    this.familiesService.getFamilies().subscribe(response=> {
      const result = response.body;
      this.FAMILY_LIST=response.body['results']
      
     }, (err) => {
        
   })



   
  }
  

  submit():void{ 

    console.log("IN SUB")
    if (this.form.invalid) {
        return;
    }

    if(!this.isEdit)         this.form.removeControl('_id');



    this.uacservice.addRole(this.form.getRawValue()).subscribe(response=> {
      
      const result = response.body;
      if(!this.isEdit)
      {
        
        this.coreservice.success(this.resource.message('ROLE_SAVE'));
      }
      else
      {
        this.coreservice.success(this.resource.message('ROLE_UPDATE'));
      }
     
     this.router.navigate(['role']);

     }, (err) => {

      this.coreservice.error(err.error.message);
      this.form.addControl('_id', new FormControl(''));

      
   })
   
  }
 
}

<div class="content p30">
  <div id="block_container">
    <a routerLink="/commonspace/booking" id="bloc1" mat-flat-button class="mat-flat-button-transperent"><span
        class="material-icons">keyboard_backspace</span></a>
    <div id="bloc2">
      <h5>
        {{ resource.message("COMMONSPACE") }} /
        {{ resource.message("ADDBOOKING") }} {{ labelText }}
      </h5>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <mat-card class="">
      <mat-card-content>
        <br />
        <h5>{{ resource.message("ADDBOOKING") }}</h5>
        <hr />

        <mat-grid-list cols="2" rowHeight="100px">
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <input type="hidden" formControlName="_id" />
              <mat-label>Space Type</mat-label>
              <mat-select placeholder="Space Type" formControlName="category"
                (selectionChange)="getSpaces($event.value)">
                <mat-option *ngFor="let category of CATEGORY_LIST" [value]="category._id + ''">{{ category.category }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Space Name</mat-label>
              <mat-select placeholder="Space Name" formControlName="spacename"
                (selectionChange)="setMaxCapacity($event.value)">
                <mat-option *ngFor="let space of FILTERED_SPACENAME_LIST" [value]="space._id + ''">{{ space.spacename }}
                  </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Booked by</mat-label>
              <mat-select placeholder="Members Name" formControlName="user">
                <mat-option *ngFor="let member of MEMBER_LIST" [value]="member.user_id + ''">{{ member.name }} {{
                  member.lastname }}</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Ticket Number</mat-label>
              <input matInput placeholder="Ticket Number" formControlName="ticket" />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Purpose</mat-label>
              <input matInput placeholder="Purpose" formControlName="purpose" />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Capacity</mat-label>
              <input type="number" matInput placeholder="Capacity" formControlName="capacity" />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Start Date and Time</mat-label>
              <input matInput type="datetime-local" disabled formControlName="start_date"
                placeholder="Start Date and Time" [min]="minDate" (change)="onDateRangeChanged('start')"  [max]="form.get('end_date').value" />
              <!-- <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
              <mat-datepicker #dp3 disabled="false"></mat-datepicker> -->
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>End Date and Time</mat-label>
              <input matInput type="datetime-local" disabled formControlName="end_date" (change)="onDateRangeChanged('end')" placeholder="End Date and Time"
                [min]="form.get('start_date').value" />
              <!-- <mat-datepicker-toggle matSuffix [for]="dp4"></mat-datepicker-toggle>
              <mat-datepicker #dp4 disabled="false"></mat-datepicker> -->
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>

      <mat-card-actions align="end">
        <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="button"
          *ngIf="isEdit" (click)="viewcalendar()">
          View Calendar
        </button>
        <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="submit"
          [disabled]="form.invalid" *ngIf="!isEdit">
          Save
        </button>
        <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="submit"
          [disabled]="form.invalid" *ngIf="isEdit">
          Update
        </button>

        <button mat-raised-button color="clear" class="button-wider" routerLink="/commonspace/booking">
          Cancel
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
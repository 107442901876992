<div class="content p30"> 

       
 
    <div id="block_container">
        <a  routerLink="/families" id="bloc1" mat-flat-button class="mat-flat-button-transperent" ><span  class="material-icons">keyboard_backspace</span></a> 
          
        <div id="bloc2"><h5>Family Management / Edit</h5></div>
    </div>
     

    <form [formGroup]="form" (submit)="onsubmit()"  >
    <mat-card class="">
      <mat-card-content>
       
        <mat-grid-list cols="2" rowHeight="100px">
            <mat-grid-tile >
                <mat-form-field appearance="outline" class="field-spacer">
                    <input type="hidden" formControlName="_id" >
                    <mat-label>Family Name</mat-label>
                    <input matInput placeholder="First Name" formControlName="name" disabled=true>
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
                <mat-form-field appearance="outline" class="field-spacer">
                    <mat-label>Family Business Domain</mat-label>
                    <input matInput placeholder="" formControlName="business_domain" disabled=true>
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile  >
                <mat-form-field appearance="outline" class="field-spacer">
                    <mat-label>Family Admin Name</mat-label>
                    <input matInput placeholder="" formControlName="adminname" >
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Email ID</mat-label>
                <input matInput placeholder="" formControlName="email">
              </mat-form-field>               
                   
            </mat-grid-tile>

            <mat-grid-tile >
                <mat-form-field appearance="outline"class="field-spacer">
                    <mat-label>Contact No</mat-label>
                    <input matInput placeholder="" formControlName="mobile">
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
                <mat-form-field appearance="outline"class="field-spacer">
                    <mat-label>Company Address</mat-label>
                    <input matInput placeholder="Address" formControlName="address">
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
                <mat-form-field appearance="outline"class="field-spacer">
                    <mat-label>Date of Registration</mat-label>
                    <input matInput placeholder="" formControlName="created_at">
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
                <mat-form-field appearance="outline"class="field-spacer">
                    <mat-label>URL Link</mat-label>
                    <input matInput placeholder="" formControlName="web">
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
                  <mat-form-field appearance="outline" class="field-spacer">
                    <mat-label>No. of Users</mat-label>
                    <input matInput placeholder="" [disabled]=true formControlName="noofuser">
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Subscription Plan</mat-label>
                <input matInput placeholder=""  [disabled]=true formControlName="subscription">
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Frequency of Subsciption Plan</mat-label>
                <input matInput placeholder="" [disabled]=true formControlName="frequency" >
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Payment Status</mat-label>
                <input matInput placeholder="" [disabled]=true formControlName="payment_status" >
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Hosting Type</mat-label>
                <input matInput placeholder=""  [disabled]=true formControlName="hosting_type">
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Family Portal Status</mat-label>
                <input matInput placeholder="" [disabled]=true [value]="(form.get('admin_portal_status').value==true)?'Active':'In Active'">
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Family Mobile App Status</mat-label>
                <input matInput placeholder="" [disabled]=true [value]="(form.get('app_status').value==true)?'Active':'In Active'">
              </mat-form-field>
            </mat-grid-tile>

            
  
            
                
   
       </mat-grid-list>



          
              
       <br />
       <h5>Exceptions</h5>
       <hr />
       
       <mat-grid-list cols="2" rowHeight="50px">
          
            <mat-grid-tile class="video-title">
              <mat-checkbox class="example-margin" [checked]="portal_status" (change)="form.patchValue({admin_portal_status:$event.checked})" >Activate Family Admin Portal</mat-checkbox>
            </mat-grid-tile>
            <mat-grid-tile class="video-title">
              <mat-checkbox class="example-margin" [checked]="app_status" (change)="form.patchValue({app_status:$event.checked})" >Activate Mobile App</mat-checkbox>
            </mat-grid-tile>     
         
         </mat-grid-list>
      



      </mat-card-content>

      <mat-card-actions align="end">
        <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="submit">Save</button>
          <a  routerLink="/families" id="bloc1" mat-raised-button color="clear" class=" button-wider" >Cancel</a>
      </mat-card-actions>
      

    </mat-card>
  </form>


  </div>


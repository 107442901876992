<div class="col-md-12" *ngFor="let topic of topics">
  <mat-card class="suggestion-card">
    <div class="row suggest">
      <label for="suggested_by" class="suggestarea"
        >Suggested By :
        <span class="namearea"
          >{{ topic.idp.created_by.name }}
          {{ topic.idp.created_by.lastname }}</span
        ></label
      >
    </div>
    <div class="row">
      <div class="col-md-8">
        <label>Agenda title : {{ topic.idp.title }}</label>
      </div>
      <div class="col-md-4">
        <label>Actions</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <label>{{ topic.idp.description }}</label>
      </div>
      <div class="col-md-4">
        <button
          mat-raised-button
          color="primary"
          class="addbutton"
          class="buttonfloatright"
          (click)="add_agenda(topic)"
        >
          <i class="fas fa-plus-circle"></i>
        </button>

        <button mat-icon-button (click)="delete_agenda(topic)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </mat-card>
</div>

import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormBuilder,
  FormArray,
  NgForm,
} from '@angular/forms';
import { UserService } from '@services/UserService';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '../../../shared/services/CoreService';
import { UACService } from '@services/UAC.service';
import { constants } from '@shared/config/constants';
import { language } from '@shared/config/language';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { BusinessInfoService } from '@services/BusinessInfo.service';
import { FamiliesService } from '@services/Families.service';
import { AuthService } from '@services/Auth.service';
import { FileService } from '@services/File.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css'],
})
export class AdduserComponent implements OnInit {
  marriedControl = new FormControl('UnMarried');

  rolename = '';
  designations = [];
  otherInfo = {};
  SOCIAL_SITES= ['Linked In', 'Instagram', 'Facebook'];
  isEdit: Boolean = false;
  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    name: new FormControl('', Validators.required),
    middlename: new FormControl(''),
    lastname: new FormControl(''),
    role_uac: new FormControl('', Validators.required),
    profile_photo: new FormControl(''),
    address: new FormControl(''),
    mobile: new FormControl(undefined, Validators.required),
    url: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    dob: new FormControl('',  Validators.required),
    married: new FormControl(this.marriedControl.value),
    anniversary_date: new FormControl(''),
    linked_in: new FormControl(''),
    pan: new FormControl('', Validators.required),
    passport: new FormControl('',  Validators.required),
    coach: new FormControl(''),
    isDel: new FormControl('', Validators.required),
    family: new FormControl('', Validators.required),
    updated_by: new FormControl('', Validators.required),
    social_links: this.fb.array([this.createSocialItem()]),
    designations: this.fb.array([this.createDesignationItem()]),
    id_details: this.fb.array([this.createIdItem()]),
    status: new FormControl('active', Validators.required),
    file: new FormControl(''),
    filename: new FormControl(''),
  });

  selectedfile: any;
  ADVISOR_LIST: any;
  ROLES_LIST: any;
  CONSTANT = constants;
  resource = language;
  labelText: String = 'Add User';
  BUSINESS_UNIT_LIST: any;
  STATUS_LIST: any;
  FAMILIES: any;
  isDisabled: boolean = true;
  familyname = '';
  localstoragedata: any;
  INVALID_STATE: boolean = true;
  select_user_family: string = '';
  prevlinkedin: string = '';
  prevdesignation: string = '';
  CORE_FAMILY = constants.CORE_FAMILY_NAME;
  currentfile = {};
  currentfilename = '';
  photo: string = '';
  constructor(
    private userservice: UserService,
    private router: Router,
    private uacservice: UACService,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private fb: FormBuilder,
    private businessinfoservice: BusinessInfoService,
    private familiesService: FamiliesService,
    private fileService: FileService
  ) {
    /* FAMILY NAME OF LOGGED IN USER*/
    this.localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    if (this.localstoragedata.family != null)
      this.familyname = this.localstoragedata.family.name;
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>', this.localstoragedata.family._id);
    /*AFF DEFAULT FUNCTION AND CONSTANTS*/
    this.getAllRoles();
    this.getbusinessunitdata(this.localstoragedata.family._id);
    this.getAllFamilies();
    this.STATUS_LIST = constants.user_status;

    /**ROLE PERMISSION AUTHENTICATION */
    AuthService.authenticate_redir('Users', ['EDIT', 'READ']);
    this.INVALID_STATE = !AuthService.authentication('Users', 'EDIT');
    console.log('INVALID_STATE  ', this.INVALID_STATE);
    /**CHECK IF PAGE ROUTING FOR ADD OR EDIT */
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        /**ADD,EDIT LABEL TEXT */
        this.labelText = 'Edit User';
        this.userservice.getUser(data.id).subscribe((response) => {
          const result = response.body['user'];
          console.log('result', result);

          this.marriedControl.setValue(result.married);
          this.rolename = result.role;

          /**SELECTED USER DEPRECATED FIELDS,FAMILY NAME */
          if (result.family != null)
            this.select_user_family = result.family.name;
          if (result.linked_in != null) this.prevlinkedin = result.linked_in;
          if (result.designation != null)
            this.prevdesignation = result.designation;
          this.sociallinks.clear();
          result.social_links.forEach((item) => {
            this.sociallinks.push(
              this.fb.group({
                url: [item.url, Validators.required],
                network: [item.network.enum[0], Validators.required],
              })
            );
          });
          (this.form.get('designations') as FormArray).clear();
          result.designations.forEach((item) => {
            (this.form.get('designations') as FormArray).push(
              this.fb.group({
                business: item.business,
                designation: item.designation,
              })
            );
          });
          this.form.patchValue({
            _id: result._id,
            name: result.name,
            middlename: result.middlename,
            lastname: result.lastname,
            role_uac: result.role_uac._id,
            profile_photo: result.profile_photo,
            address: result.address,
            mobile: result.mobile,
            email: result.email,
            dob: result.dob,
            anniversary_date: result.anniversary_date,
            coach: result.coach,
            family: result.family._id,
            linked_in: result.linked_in,
            pan: result.pan,
            passport: result.passport,
            // social_links: result.social_links.map((item) => {
            //   item.network = item.network.enum[0];
            //   return item;
            // }),
            // designations: result.designations,
            married: this.marriedControl.value,
            id_details: result.id_details,
            isDel: false,
            updated_by: '5e3a77df4a0460002a8083ae',
            password: 'admin123#',
            status: result.status,
            file: null,
          });
          this.changeMarriageStatus(this.marriedControl.value)
          this.isEdit = true;
          this.photo = result.profile_photo;
          this.getAllAdvisors();
        });
      } else {
        if (this.localstoragedata.family != null)
          this.select_user_family = this.localstoragedata.family.name;
        AuthService.authenticate_redir('Users', 'ADD');
        this.getAllAdvisors();
      }
    });
  }

  get sociallinks() {
    return this.form.get('social_links') as FormArray;
  }

  get designation() {
    return this.form.get('designations') as FormArray;
  }

  get ids() {
    return this.form.get('id_details') as FormArray;
  }

  ngOnInit(): void {
    this.form.patchValue({
      isDel: false,
      updated_by: '5e3a77df4a0460002a8083ae',
      password: 'admin123#',
      married: this.marriedControl.value,
      family: this.localstoragedata.family._id,
      filename: '',
      file: null,
      //  dob:new Date(1, 0, 2000)
    });
  }

  changeMarriageStatus(event) {
    if(event.value === "Married") {
      console.log(event)
      this.form.controls["anniversary_date"].setValidators([Validators.required]);
      this.form.controls["anniversary_date"].updateValueAndValidity();
    }
    else  {
      this.form.controls["anniversary_date"].setValidators(null);
      this.form.controls["anniversary_date"].updateValueAndValidity();
    }
    console.log(this.form)
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  getAllRoles() {
    this.uacservice.getRoles().subscribe(
      (response) => {
        const result = response.body;
        this.ROLES_LIST = response.body['results'];
      },
      (err) => {}
    );
  }

  getAllAdvisors() {
    var filter = '';
    if (this.select_user_family == this.CORE_FAMILY) {
      filter = '';
    } else {
      filter =
        '?filter[family]=' +
        this.localstoragedata.family._id +
        '&filter[role]=facilitator';
    }
    this.userservice.getUsers(filter).subscribe(
      (response) => {
        const result = response.body;
        this.ADVISOR_LIST = response.body['results'];
      },
      (err) => {}
    );
  }

  async submit(): Promise<void> {
    
    if (this.form.invalid) {
      // Object.keys(this.form.controls).forEach((key) => {
      //   console.log(key);

      //   // Get errors of every form control
      //   console.log(this.form.get(key), this.form.get(key).errors);
      // });
      return;
    }
    var rawuser = this.form.getRawValue();
    this.designations = [...rawuser.designations]
    rawuser.social_links = rawuser.social_links.map((item) => {
      let temp = item.network;
      delete item.network;
      item.network = {};
      item.network.enum = [(temp as string).toLowerCase().replace(/\s+/g, '')];
      return item;
    });
    console.log('rawuser', rawuser);

    if (!this.isEdit) {
      //      this.form.removeControl('_id');
      //rawuser = this.form.getRawValue();
      delete rawuser._id;
      rawuser.password = 'FamilyBook123#';
    }

    if (this.select_user_family == this.CORE_FAMILY) {
      //delete rawuser['coach'];
      delete rawuser['designations'];
    }
    if (rawuser.coach == '') {
      delete rawuser['coach'];
    }

    rawuser.mobile = Number(rawuser.mobile);
    rawuser.role_uac = rawuser.role_uac;
    rawuser.role = this.rolename;
    rawuser.familyname = this.select_user_family;

    if (this.form.get('file').value != undefined) {
      let fileurl = this.form.get('file').value;
      var formData: any = new FormData();
      formData.append('file', this.form.get('file').value);
      fileurl = await this.fileService.uploadFile(formData, true, false);
      rawuser['profile_photo'] = fileurl;
    }

    delete rawuser['file'];
    console.log(rawuser)
    this.userservice.addUser(rawuser).subscribe(
      (response) => {
        const result = response.body;
        
        if (!this.isEdit) {
          this.userservice.addUser({...result['user'], designations: this.designations, pan: rawuser.pan,
        passport: rawuser.passport, linked_in: rawuser.linked_in, coach: rawuser.coach, anniversary_date: rawuser.anniversary_date}).subscribe();
          this.coreservice.success(this.resource.message('USER_SAVE'));
        } else {
          this.coreservice.success(this.resource.message('USER_UPDATE'));
        }
        this.form.addControl('_id', new FormControl(''));
        this.router.navigate(['user']);
      },
      (err) => {
        var errormessages = 'Please correct the following errors: ';
        console.log(err);
        if (err.error.errors != undefined) {
          err.error.errors.forEach(
            (element) => (errormessages += '' + element.msg)
          );
          this.coreservice.error(errormessages);
        } else {
          this.coreservice.error(err.error.message);
        }
      }
    );
  }

  setrole($event) {
    console.log($event);
    this.ROLES_LIST.forEach((element) => {
      if (element._id == $event.value) {
        this.rolename = element.role;
        console.log(this.rolename);
      }
    });
  }

  createIdItem(): FormGroup {
    return this.fb.group({
      id_no: '',
      id_type: '',
    });
  }

  createDesignationItem(): FormGroup {
    return this.fb.group({
      business: '',
      designation: '',
    });
  }
  createSocialItem(): FormGroup {
    return this.fb.group({
      url: ['', [Validators.required, Validators.pattern(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/)]],
      network: ['', Validators.required],
    });
  }

  addSocial(item: FormGroup) {
    item.markAllAsTouched();
    if(item.invalid) {
      return;
    }
    this.sociallinks.push(
      this.fb.group({
        url: ['', [Validators.required, Validators.pattern(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/)]],
        network: ['', Validators.required],
      })
    );
    console.log(this.sociallinks.controls);
  }

  deleteSocial(index) {
    this.sociallinks.removeAt(index);
  }

  addDesignation() {
    this.designation.push(this.fb.group({ business: '', designation: '' }));
  }

  deleteDesignation(index) {
    this.designation.removeAt(index);
  }

  addId() {
    this.ids.push(this.fb.group({ id_no: '', id_type: '' }));
  }

  deleteId(index) {
    this.ids.removeAt(index);
  }

  handleSoc(data, idx) {
    const remITem = this.sociallinks.controls.map(val => val.value.network).filter((val, index) => val!=this.sociallinks.controls[idx].value.network)
    return data.filter(n => !remITem.includes(n.toLowerCase().split(' ').join('')))
  }

  getFormErrors(form: NgForm) {
    if (form instanceof FormControl) {
      // Return FormControl errors or null
      return form.errors ?? null;
    }
    if (form instanceof FormGroup) {
      const groupErrors = form.errors;
      // Form group can contain errors itself, in that case add'em
      const formErrors = groupErrors ? { groupErrors } : {};
      Object.keys(form.controls).forEach((key) => {
        // Recursive call of the FormGroup fields
        const error = this.getFormErrors(form.get[key]);
        if (error !== null) {
          // Only add error if not null
          formErrors[key] = error;
        }
      });
      // Return FormGroup errors or null
      return Object.keys(formErrors).length > 0 ? formErrors : null;
    }
  }
  getbusinessunitdata(data) {
    // if(this.localstoragedata.family.name != this.CORE_FAMILY)  this.select_user_family=this.localstoragedata.family.name;
    console.log('id::::  ', data);

    this.businessinfoservice.businessunitget('').subscribe(
      (response: any) => {
        this.BUSINESS_UNIT_LIST = response['data'];
        console.log('BUSINESS_UNIT_LIST', response);
      },
      (err) => {}
    );
  }
  getAllFamilies() {
    this.familiesService.getFamilies().subscribe(
      (response) => {
        const result = response.body;
        this.FAMILIES = response.body['results'];
      },
      (err) => {
        if (err.error.status != null) {
        } else {
        }
      }
    );
  }
  onFileChange(event) {
    const latestfile = (event.target as HTMLInputElement)?.files[0];
    this.currentfile = latestfile;
    this.currentfilename = latestfile ? latestfile.name : '';
    this.selectedfile = latestfile;
    var filename = latestfile?.name;

    this.form.patchValue({
      filename: this.currentfilename,
      file: this.currentfile,
    });

    this.form.patchValue({
      file: this.currentfile,
    });

    this.form.get('file').updateValueAndValidity();
  }
  previewimg() {
    window.open(this.form.get('profile_photo').value, '_blank');
  }
  onFamilyChange(event) {
    this.select_user_family = event.source.triggerValue;
    this.getbusinessunitdata(event.value);
    this.getAllAdvisors();
  }
  maxDate = new Date();
}

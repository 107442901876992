<div class="container-fluid">
    <div class="row">
        
        <div class="col-sm-7">
            <div class="main-div align-middle">
                <mat-card class="">
                    <mat-card-content class="forgotcontent">
                        <h3 class="bold-text">Forgot Customer ID</h3>
                            <form  class="idform">
                                <div class="form-group">
                                    <label class="login-label"> 
                                        <mat-radio-group aria-label="Select an option" class="forgetid">
                                            <mat-radio-button value="1" class="radioclass" checked >Mobile Number</mat-radio-button>
                                            <mat-radio-button value="2" class="radioclass">Email</mat-radio-button>
                                        </mat-radio-group>
                                    </label>
                                   </div>
                                <br />   
                                 <div class="submitbtn">
                                    <button mat-raised-button  type="submit" class="btnsubmit">Submit</button>
                                </div>
                            </form>
                           
                    </mat-card-content>
                <mat-card-footer>
                    
                </mat-card-footer>
                </mat-card>
                <div class="backtologin">
                <button type="submit" class="btn btn-primary back-login-btn">
                    <a href="index.html"> 
                        <i class="fa fa-arrow-left"></i> &nbsp; Back to Login
                    </a>
                </button>
            </div>
            </div>
        </div>
        <div class="col-sm-5">
            <div class="login-bg"> 
                <img src="../../../assets/images/fb-logo-white.png" class="fb-logo">        
            </div>
        </div>
    </div>
</div><!--container-fluid-->



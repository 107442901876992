<mat-card class="">
  <mat-card-content>
    <form class="form-inline">
      <div class="form-group mr-2 text-right">



      </div>
      <span class="example-spacer"></span>
      <div class="mr-1 col-sm-12">

        <input
          matInput
          [(ngModel)]="searchKey"
          placeholder="search"
          autocomplete="off"
          (keyup)="applyfilter()"
          name="searchKey"
          class="searchfloatright"
        />

        <button
          mat-stroked-button
          class="iconcss float-right"
          (click)="convertToPDF('print')"
        >
          <img src="./assets/images/printing.png" width="28" height="28">
        </button>

        <button
          mat-stroked-button
          class="iconcss float-right"
          (click)="exporter.exportTable('csv', { fileName: 'IDP' })"
        >
          <img src="./assets/images/xlsx-file-format-extension.png" width="28" height="28">
        </button>

        <button
          mat-stroked-button
          class="iconcss float-right"
          (click)="convertToPDF('save')"
        >
          <img src="./assets/images/pdf-file.png" width="28" height="28">
        </button>
      </div>
    </form>
    <div class="table-responsive">
      <br />
      <mat-table
        #idpTable
        idpTable
        matTableExporter
        [dataSource]="listData"
        matSort
        class="mattable idplisting"
        #exporter="matTableExporter"
        [hiddenColumns]="[2, 3]"
      >
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="customTableHeaderStyle"
            >Member Name</mat-header-cell
          >
          <mat-cell *matCellDef="let element"
            >{{ element.name ? element.name : "" }} &nbsp;
            {{ element.lastname ? element.lastname : "" }}
          </mat-cell>
        </ng-container>

        <!-- Mobile Column -->
        <ng-container matColumnDef="advisor">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="customTableHeaderStyle"
            >Advisor Name</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.advisor
          }}</mat-cell>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="details">
          <mat-header-cell
            style="text-align: center"
            *matHeaderCellDef
            mat-sort-header class="customTableHeaderStyle"
            >Details</mat-header-cell
          >
          <mat-cell
            style="text-align: center"
            *matCellDef="let element"
            ><button
              (click)="detailsPage(element)"
              mat-button
              color="primary"
              class="detailCss"
            >
              View
            </button>
          </mat-cell>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="Action1">
          <mat-header-cell *matHeaderCellDef style="text-align: center;" class="customTableHeaderStyle"
            >Actions</mat-header-cell
          >
          <mat-cell style="text-align: center; display: flow-root !important;" *matCellDef="let row">
            <button
              mat-stroked-button
              color="primary"
              (click)="redirectTo('goal', row)"
              style="border: 1px solid #095294"
              class="mt-2"
            >
              Add Goal
            </button>
            <button
              mat-flat-button
              color="primary"
              (click)="redirectTo('meeting', row)"
              class="mt-2"
            >
              Schedule meetings
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 15, 20]"
        [pageSize]="5"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>

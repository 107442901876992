<div class="content p30"> 

    <h5>{{resource.message('SECURITY')}}</h5>    

<!-- 
    <mat-grid-list cols="2" rowHeight="300px">
      <mat-grid-tile colspan=2 >
        <mat-card class="example-card">
          <mat-card-header>            
            <mat-card-title><i class="fa fa-file-text-o"></i> Dashboard Tile </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
            </p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>  
  -->    

    
    <mat-card class="">
      <mat-card-header >            
        <mat-card-title > </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form class="form-inline">
         <div class="form-group mr-2"> 
            <h3>     {{resource.message('SECURITY')}}</h3>
         </div>
          <span class="example-spacer"></span>
          <div class="mr-1"> 
        
           <input matInput [(ngModel)]="searchKey" placeholder="{{resource.message('SEARCH_BY_SECURITY')}}" autocomplete="off" (keyup)="applyfilter()" name="searchKey" class="searchfloatright" />
        
        </div>
      </form>
        <p>

<br />
          <mat-table   #roleTable roleTable matTableExporter [dataSource]="listData" matSort class="mattable"  #exporter="matTableExporter" [hiddenColumns]="[5,6]">

       
      
      
           
           <!-- Name Column -->
           <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Family Name</mat-header-cell>
            <mat-cell *matCellDef="let element">{{(element.family != null)?element.family.name:''}}</mat-cell>
          </ng-container>
    
         <!-- Mobile Column -->
         <ng-container matColumnDef="mobile">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Family Admin Name</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.name}} {{element.lastname}}</mat-cell>
          </ng-container>

          
     
           <!-- System Column -->
     
           <!-- Action Column -->
           <ng-container matColumnDef="Action">
             <mat-header-cell *matHeaderCellDef>Activity Log</mat-header-cell>
             <mat-cell *matCellDef="let row">
               <button mat-icon-button  (click)="edit(row)"  >View</button>
            <!--   <button mat-icon-button color="warn"  (click)="onDelete(row._id)"   ><mat-icon>delete_outline</mat-icon></button>-->
             </mat-cell>
           </ng-container>
          


           <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
           <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
 
         </mat-table>
         <mat-paginator [pageSizeOptions]="[5,10,15,20]" [pageSize]="5" showFirstLastButtons></mat-paginator> 


        </p>
      </mat-card-content>
    </mat-card>



  </div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { language } from '@shared/config/language';

@Component({
  selector: 'app-familyoffice',
  templateUrl: './familyoffice.component.html',
  styleUrls: ['./familyoffice.component.css']
})
export class FamilyofficeComponent implements OnInit {
  resource = language;
  activePath = 0;

  constructor() { }

  ngOnInit(): void {
  }

}

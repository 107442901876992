import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IdpService } from '@services/Idp.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dialogattendanceqorum',
  templateUrl: './dialogattendanceqorum.component.html',
  styleUrls: ['./dialogattendanceqorum.component.css'],
})
export class DialogattendanceqorumComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  notes: string;
  labelText: string;
  hidebtn: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogattendanceqorumComponent>,
    private idp: IdpService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.labelText = this.data.message;
  }

  doneClick() {
    this.router.navigate(['committee/resolution'], {
      queryParams: {
        id: this.data.id,
      },
    });
    this.closeDialog();
  }
  doneClick1() {
    if (this.data.type === 'cancel') {
      this.idp
        .idpcancel(this.data.id, {
          cancel_note: this.notes,
        })
        .subscribe(
          (response: any) => {
            this.router.navigate(['idp']);
            this.dialogRef.close(false);
          },
          (err) => {
            console.log('errrrrrrr', err);
          }
        );
    } else {
      var addAgenda = 'true';

      this.idp.idpnotepost(this.notes, this.data.id).subscribe(
        (response: any) => {
          this.newItemEvent.emit(addAgenda);
        },
        (err) => {
          console.log('errrrrrrr', err);
        }
      );
      this.dialogRef.close(false);
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  getMeetings(id) {
    //alert('IN');
    var filter = '';
    filter = '?filter[_id]=' + id; //+this.localstoragedata._id
    this.idp.idpMeetingGet(filter).subscribe(
      (response: any) => {
        console.log(response.data[0].rationalize_notes);
        this.notes = response.data[0].rationalize_notes;
        this.hidebtn = true;
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
}

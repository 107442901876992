<div align="right">
  <div class="content-container">
    <mat-card class="cardclass">
      <mat-card-header>
        <mat-card-title>
          {{ resource.message("LEADERSHIP_TEAM") }}
        </mat-card-title>
        <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
      </mat-card-header>
      <hr />
      <form [formGroup]="form" (ngSubmit)="submit()">
        <mat-card-content>
          <mat-grid-list cols="2" rowHeight="100px">
            <!-- <mat-grid-tile >
                            <mat-form-field appearance="outline" class="field-spacer">
                                <input type="hidden" formControlName="_id" >
                                <mat-label>Team Member</mat-label>
                                <input matInput placeholder="Team Member" formControlName="name">
                              </mat-form-field>
                        </mat-grid-tile> -->
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <input type="hidden" formControlName="_id" />

                <mat-label>Team Member</mat-label>
                <mat-select
                  placeholder="Team Member"
                  formControlName="user"
                  (selectionChange)="setrole($event)"
                >
                  <mat-option
                    *ngFor="let user of TEAM_MEMBER"
                    [value]="user._id + ''"
                    >{{ user.name }} {{ user.lastname }}</mat-option
                  >
                </mat-select>
                <mat-error>This field is mandatory.</mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <div
                style="padding-inline: 16px; padding-bottom: 32px"
                class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored"
              >
                <div
                  style="margin-top: 4px; text-align: start"
                  class="placeholder"
                >
                  Profile Photo
                </div>
                <div style="display: flex; gap: 8px">
                  <input
                    name="File1"
                    placeholder="File"
                    type="file"
                    (change)="onFileChange($event)"
                    formControlName="file"
                  />
                  <a
                    style="margin-top: 4px"
                    href="{{ this.form.get('profile_photo').value }}"
                    target="_blank"
                    class="input-preview-btn"
                    *ngIf="this.form.get('profile_photo').value !== ''"
                  >
                    Preview
                  </a>
                </div>
              </div>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Email ID</mat-label>
                <input
                  matInput
                  placeholder="Email ID"
                  formControlName="email"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Mobile Number</mat-label>
                <input
                  matInput
                  placeholder
                  placeholder="Mobile Number"
                  formControlName="mobile"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Designation</mat-label>
                <input
                  matInput
                  placeholder="Designation"
                  formControlName="designation"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Department</mat-label>
                <input
                  matInput
                  placeholder="Department"
                  formControlName="department"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Start Date</mat-label>
                <input
                  [min]="todayDate"
                  class="custom_disabled"
                  matInput
                  [matDatepicker]="dp3"
                  disabled
                  formControlName="start_date"
                  placeholder="Start Date"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dp3"
                ></mat-datepicker-toggle>
                <mat-datepicker #dp3 disabled="false"></mat-datepicker>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>End Date</mat-label>
                <input
                  [min]="todayDate"
                  class="custom_disabled"
                  matInput
                  [matDatepicker]="end3"
                  disabled
                  formControlName="end_date"
                  placeholder="End Date"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="end3"
                ></mat-datepicker-toggle>
                <mat-datepicker #end3 disabled="false"></mat-datepicker>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="submit"
            [disabled]="form.invalid"
          >
            Save
          </button>
          <button
            mat-raised-button
            color="clear"
            type="reset"
            class="button-wider"
          >
            Clear All
          </button>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
</div>

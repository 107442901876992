import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { PollsService } from '@services/Polls.service';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import { CoreService } from '@services/CoreService';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import { AuthService } from '@services/Auth.service';
import { constants } from '@shared/config/constants';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DatePipe, TitleCasePipe } from '@angular/common';

export interface PollElement {
  pollId: number;
  created_at: string;
  title: string;
  committee: Object;
  committeename: string;
  active_till: string;
  is_expired: string;
}

@Component({
  selector: 'app-polls',
  templateUrl: './polls.component.html',
  styleUrls: ['./polls.component.css'],
  providers: [DatePipe, TitleCasePipe],
})
export class PollsComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;

  ELEMENT_DATA: PollElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = [
    'created_at',
    'title',
    'committeename',
    'active_till',
    'status',
    'Action',
  ];
  listData = new MatTableDataSource<PollElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<PollElement>(true, []);

  PERMISSION_EDIT = AuthService.authentication('Roles', 'READ');
  PERMISSION_ADD = AuthService.authentication('Roles', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Roles', 'DELETE');

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<PollElement>;

  @Input() source: string;
  @Input() allowsearch: boolean = true;
  resource = language;

  DATE_FORMAT = constants.GLOBAL_DATE_FORMAT;

  constructor(
    private pollsService: PollsService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private http: HttpClient,
    private coreservice: CoreService,
    private datePipe: DatePipe,
    private titlecasePipe: TitleCasePipe
  ) {
    AuthService.authenticate_redir('Users', ['READ']);

    this.listData = new MatTableDataSource<PollElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<PollElement>(true, []);

    console.log(language.message('a'));
  }

  ngOnInit(): void {
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllPolls();
    this.table.renderRows();
  }

  getAllPolls() {
    this.pollsService.getPolls('').subscribe(
      (response) => {
        const result = response.body;

        //      (result['data'] as PollElement[]).forEach(function (value) {
        //            value["familyname"] = value["family"]["name"];
        //      });

        this.listData.data = response.body['data'] as PollElement[];
        this.listData.data = this.listData.data.reverse().map((item) => {
          item['committeename'] = item.committee['title'];
          return item;
        });
        this.table.renderRows();
      },
      (err) => {
        if (err.error.status != null) {
          //          this.errmessage = err.error.message;
        } else {
          //         this.errmessage = err.error.errors[0].msg;
        }
      }
    );
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listData.data.forEach((row) => this.selection.select(row));
  }

  addrole() {
    this.router.navigate(['/polls/add']);
  }

  edit(polls) {
    this.router.navigate(['/polls/add'], { queryParams: { id: polls._id } });
  }

  onDelete(id) {
    this.coreservice
      .openConfirmDialog(this.resource.message('ROLE_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.pollsService.removeRole(id).subscribe(
            (response) => {
              const result = response;
              this.getAllPolls();
              this.coreservice.success(this.resource.message('ROLE_DELETED'));
            },
            (err) => {}
          );
        }
      });
  }

  closeMyMenu() {
    this.trigger.closeMenu();
  }

  filterData(ev, col, val) {
    if (ev.checked) {
      col = String(col);

      let value = { [col]: val };
      this.filterValues.push(value);
    } else {
      let value = { [col]: val };
      const index = this.filterValues.indexOf(value);
      this.filterValues.splice(index - 1, 1);
    }

    this.setupFilter(col);
    this.applyFilter(val);
  }

  stoppro($event) {
    $event.stopPropagation();
  }

  setupFilter(column: string) {
    this.listData.filterPredicate = (d: any, filter: string) => {
      const val = JSON.parse(filter);
      var flag = false;

      val.forEach(function (item) {
        let obj = item;
        const textToSearch = (d[column] && d[column].toLowerCase()) || '';
        if (obj[column] != undefined) {
          if (
            textToSearch.toLowerCase().indexOf(obj[column].toLowerCase()) !== -1
          ) {
            flag = true;
          }
        }
      });
      return flag;
    };
  }

  applyFilter(filterValue: string) {
    this.listData.filter = JSON.stringify(this.filterValues); // filterValue.trim().toLowerCase();
  }

  filterValues = [];

  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [
      ['Created On', 'Poll Title', 'Committee', 'Active Till', 'Status'],
    ];
    this.listData.data.forEach((obj) => {
      let created_on = obj['created_at']
        ? this.datePipe.transform(obj['created_at'], this.DATE_FORMAT)
        : '';
      let poll_title = obj['title'] ? obj['title'] : '';
      let committee =
        obj['committee'] != undefined ? obj['committee']['title'] : '';
      let active_till = obj['active_till']
        ? this.datePipe.transform(obj['active_till'], this.DATE_FORMAT)
        : '';
      let status = obj['status']
        ? this.titlecasePipe.transform(obj['status'])
        : '';

      let arr = [created_on, poll_title, committee, active_till, status];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      rowPageBreak: 'avoid',
    });

    if (action === 'save') {
      doc.save('polls.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      doc.output('dataurlnewwindow', { filename: 'polls.pdf' });
    }
  }

  /*print(){
    let doc = new jsPDF(); 
    doc.autoTable({
      head: [['NAme','approved','utilised', 'available','asd','sadadasada','asdas']],
      body: this.dataSource.filteredData //returning [["log1", "$100"], ["log2", "$200"]]
    });
    doc.save('table.pdf')
  }
*/
}

import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { apiendpoints } from '../config/apiendpoints';


@Injectable({
    providedIn:'root'
})

export class FamilyInfoService {

    constructor(private http: HttpClient){


    }

    familyinfoget(){
        return this.http.get(apiendpoints.AUTH_FAMILY_INFO, {
            headers: new HttpHeaders()
            .set('Authorization', 'Token ' +localStorage.getItem('ACCESS_TOKEN')),
        });
    }
    singleuserget(id){
        return this.http.get(apiendpoints.FAMILY_INFO_USER+"/"+id, {
            headers: new HttpHeaders()
            .set('Authorization', 'Token ' +localStorage.getItem('ACCESS_TOKEN')),
        });
    }
    addMedia(data:any){
        return this.http.post(apiendpoints.FAMILY_INFO_MEDIA, {media:data},  {
          headers: new HttpHeaders()
            .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
          observe: 'response'
        });      
      }
      addPhoto(data:any){
        return this.http.post(apiendpoints.FAMILY_INFO_PHOTO, {photo:data},  {
          headers: new HttpHeaders()
            .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
          observe: 'response'
        });      
      }
      removePhoto(id){
    
        const options = {
          headers: new HttpHeaders({
            'Authorization': `Token `+localStorage.getItem('ACCESS_TOKEN')
          }),
          body: {
            photoId: id,
          },        
        };
        
        return this.http.delete(apiendpoints.FAMILY_INFO_PHOTO, options);
      }

      removeMedia(id){
    
        const options = {
          headers: new HttpHeaders({
            'Authorization': `Token `+localStorage.getItem('ACCESS_TOKEN')
          }),
          body: {
            mediaId: id,
          },        
        };
        
        return this.http.delete(apiendpoints.FAMILY_INFO_MEDIA, options);
      }


      updateFamilyInfo(data,familyid){
        return this.http.put(apiendpoints.FAMILIES+"/"+familyid, data,  {
          headers: new HttpHeaders()
            .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
          observe: 'response'
        });      
      }

      getFamilyInfoData(familyid){
        return this.http.get(apiendpoints.FAMILIES+"/"+familyid,  {
          headers: new HttpHeaders()
            .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
          observe: 'response'
        });      
      }

      familyTree()
      {
        return this.http.get(apiendpoints.FAMILYTREE,  {
          headers: new HttpHeaders()
            .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
          observe: 'response'
        });  
        
      }

      getPhoto(filterData){
        return this.http.get(apiendpoints.FAMILY_INFO_PHOTO+"?filter[_id]="+filterData,   {
          headers: new HttpHeaders()
            .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
          observe: 'response'
        });      
      }

      updatePhoto(data:any){
        return this.http.put(apiendpoints.FAMILY_INFO_PHOTO, {photo:data},  {
          headers: new HttpHeaders()
            .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
          observe: 'response'
        });      
      }
      getMedia(filterData){
        return this.http.get(apiendpoints.FAMILY_INFO_MEDIA+"?filter[_id]="+filterData,   {
          headers: new HttpHeaders()
            .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
          observe: 'response'
        });      
      } 
}

<div align="right">
  <div class="content-container">
    <mat-card class="cardclass">
      <mat-card-header>
        <mat-card-title> PARTICIPANTS </mat-card-title>
        <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
      </mat-card-header>
      <mat-card-content>
        <form>
          <div class="mr-1">
            <input
            matInput
            [(ngModel)]="searchKey"
            placeholder="{{ resource.message('SEARCH_BY_USER') }}"
            autocomplete="off"
            (keyup)="applyfilter()"
            name="searchKey"
              class="searchfloatright"
            />
          </div>
        </form>
        <br />
        <br />
        <br />
        <mat-table
          #roleTable
          roleTable
          matTableExporter
          [dataSource]="listData"
          matSort
          class="mattable"
          #exporter="matTableExporter"
          [hiddenColumns]="[5, 6]"
        >
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Profile Name</mat-header-cell
            >
            <mat-cell *matCellDef="let element"
              >{{ element.name }} {{ element.lastname }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="attendence">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Attending</mat-header-cell
            >
            <mat-cell *matCellDef="let element">
              <span
                [ngClass]="
                  element.attendance == true
                    ? 'status-attend-active'
                    : '' || element.attendance === false
                    ? 'status-attend-inactive'
                    : 'status-attend-inactive'
                "
                >{{ element.attendance == true ? "Present " : "Absent" }}</span
              >
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 15, 20]"
          [pageSize]="5"
          showFirstLastButtons
        ></mat-paginator>
        <br />
        <mat-card-actions>
          <button
            mat-raised-button
            color="primary"
            class="buttonfloatright"
            (click)="closeDialog()"
          >
            Done
          </button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="content p30">
  <h5>{{ resource.message("SUBSCRIPTION") }}</h5>

  <mat-card>
    <mat-card-header>
      <mat-card-title>
        {{ resource.message("MYSUBSCRIPTION") }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="parentcontent">
      <div class="box" align="center">
        <h4>
          You have
          <span style="color: rgb(9, 82, 148)">{{ planType }} PLAN</span> and
          it's valid till
          <span style="color: rgb(9, 82, 148)">{{
            planDate | customDate
          }}</span>
        </h4>
        <br />
        <a href="http://fb.fiveminutes.in/" target="_blank"
          ><button mat-raised-button color="primary" class="btn">
            View Payment History
          </button></a
        >
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <mat-card class="">
      <mat-card-content>
        <h6>Basic Details</h6>
        <hr />
        <mat-grid-list cols="2" rowHeight="120px">
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <input type="hidden" formControlName="_id" />
              <mat-label>Business Name</mat-label>
              <input
                matInput
                placeholder="Business Name"
                formControlName="name"
              />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Date of Establishment</mat-label>
              <input
                class="custom_disabled"
                matInput
                [max]="maxDate"
                [matDatepicker]="dp3"
                disabled
                formControlName="established"
                placeholder="Date Of Establishment" />
              <mat-datepicker-toggle
                matSuffix
                [for]="dp3"
              ></mat-datepicker-toggle>
              <mat-datepicker #dp3 disabled="false"></mat-datepicker
            ></mat-form-field>
          </mat-grid-tile>

          <!-- <mat-grid-tile>
            <div
              class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored"
            >
              <span class="placeholder"> Upload Logo </span>
              <input
                name="File1"
                placeholder="File"
                type="file"
                (change)="onFileChange($event)"
                formControlName="file"
              />

              <span
                class="input-preview-btn preview"
                (click)="previewimg()"
                *ngIf="this.form.get('logo').value !== ''"
              >
                Preview</span
              >
            </div>
          </mat-grid-tile> -->
          <mat-grid-tile>
            <div
              class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored"
            >
              <mat-label>Upload Logo</mat-label><br />
              <label style="margin-top: 8px; margin-bottom: 56px">
                <span class="upload-btn"> Choose File </span>

                <input
                  style="display: none"
                  name="File1"
                  placeholder="Profile Photo"
                  type="file"
                  (change)="onFileChange($event)"
              /></label>
              <div style="display: inline-block">
                <div style="display: flex; align-items: center">
                  <div
                    style="
                      display: inline-block;
                      margin: 0;
                      width: 170px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    *ngIf="this.selFileName !== ''"
                  >
                    {{ this.selFileName }}
                  </div>
                  <div
                    class="input-preview-btn preview"
                    (click)="previewimg()"
                    *ngIf="this.form.get('logo').value !== ''"
                  >
                    Preview
                  </div>
                </div>
              </div>
              <!-- <a class="input-preview-btn" *ngIf="photo != '' && photo != null" (click)="previewimg()">
                Preview</a> -->
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Description</mat-label>
              <textarea
                matInput
                placeholder="Description"
                formControlName="about"
              >
              </textarea>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>

        <h6>Company Details</h6>
        <hr />

        <mat-grid-list cols="2" rowHeight="100px">
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Website Link</mat-label>
              <input
                matInput
                placeholder="Website Link"
                formControlName="website"
              />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Email ID</mat-label>
              <input
                type="email"
                matInput
                placeholder="Email ID"
                formControlName="email"
              />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Mobile number 1</mat-label>
              <ngx-mat-intl-tel-input
                [preferredCountries]="['in']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="mobile1"
                formControlName="mobile1"
                describedBy="phoneInput1"
              ></ngx-mat-intl-tel-input>

              <mat-error
                *ngIf="form.controls['mobile1']?.errors?.validatePhoneNumber"
                >Invalid Number</mat-error
              >
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Mobile number 2</mat-label>
              <ngx-mat-intl-tel-input
                [preferredCountries]="['in']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="mobile2"
                formControlName="mobile2"
                describedBy="phoneInput2"
              ></ngx-mat-intl-tel-input>
              <mat-error *ngIf="form.controls['mobile2']?.errors?.areSame"
                >Both numbers can not be same</mat-error
              >
              <mat-error
                *ngIf="form.controls['mobile2']?.errors?.validatePhoneNumber"
                >Invalid Number</mat-error
              >
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Key Person's name</mat-label>
              <input
                matInput
                placeholder="Key Person's Name"
                formControlName="keyperson"
              />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile> &nbsp; </mat-grid-tile>

          <mat-grid-tile rowspan="2">
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Registered Address</mat-label>
              <textarea matInput formControlName="registeredaddress">
              </textarea>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile rowspan="2">
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Corporate Address</mat-label>
              <textarea matInput formControlName="corporateaddress"> </textarea>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>

      <mat-card-actions align="end">
        <button
          mat-raised-button
          color="primary"
          class="btn btn-primary login-btn button-wider"
          [disabled]="form.invalid"
          type="submit"
        >
          Save
        </button>
        <button
          (click)="onClear()"
          type="reset"
          mat-raised-button
          color="clear"
          class="button-wider"
        >
          Clear All
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>

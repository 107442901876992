import { Component, OnInit, ViewChild ,ChangeDetectorRef, Input } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { AdminKnowledgeHubService } from '@services/AdminKnowledgeHub';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import {CoreService} from '@services/CoreService';
import { MatMenuTrigger } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import { AuthService } from '@services/Auth.service';
import { constants } from '@shared/config/constants';
import { FileService } from '@services/File.service';
import { KnowledgeHubService } from '@services/KnowledgeHub.service';

export interface KnowldgeHubElement {
  id: number;
  filename: string;
  title: string;
  created: string;
  updated: string;
}

export interface CategoryElement {
  _id: string;
  category: string;
  created_at: string;
  updated_at: string;
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;
  
  
  categories:CategoryElement[];

  PERMISSION_EDIT = AuthService.authentication('Knowledge Hub','EDIT');
  PERMISSION_ADD = AuthService.authentication('Knowledge Hub','ADD');
  PERMISSION_DELETE = AuthService.authentication('Knowledge Hub','DELETE');


  ELEMENT_DATA: KnowldgeHubElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = [ 'title','download','categoryname','created_at',   'Action'];
  listData = new MatTableDataSource <KnowldgeHubElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<KnowldgeHubElement>(true, []);
 
  
  @Input() source: string;
  @Input() allowsearch:boolean=true;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<KnowldgeHubElement>;

  resource = language;
  DATE_FORMAT = constants.GLOBAL_DATE_FORMAT

  constructor(private knowledgeService: KnowledgeHubService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router : Router,
    private http: HttpClient,
    private coreservice:CoreService,
    private fileService: FileService,
    private adminKnowledgeHubService: AdminKnowledgeHubService,
    ) {



    this.listData = new MatTableDataSource<KnowldgeHubElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<KnowldgeHubElement>(true, []);


 
   }

  ngOnInit(): void {

     
    
    var modulename = "Knowledge Hub";

    if(this.source == "familyadmin") modulename = "Family Knowledge Hub";

    if(this.source == "template" || this.source == 'superadmin')   this.displayedColumns = [ 'title','download','created_at',   'Action'];


    if(this.source != 'template'){
      this.PERMISSION_EDIT = AuthService.authentication(modulename,'READ');
      this.PERMISSION_ADD = AuthService.authentication(modulename,'ADD');
      this.PERMISSION_DELETE = AuthService.authentication(modulename,'DELETE');      
      AuthService.authenticate_redir(modulename, ['READ']);  
    }


    this.listData.paginator=this.paginator;
    this.listData.sort=this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllKnowledge();
    this.getAllCategories();
    this.table.renderRows();   

   
     
 

    

 }

 getAllCategories():void {
  this.knowledgeService.getKnwoledgeCategories().subscribe(response=> {
    this.categories=response.body['data'] as CategoryElement[]
          this.getAllKnowledge();
  });
}

 async onPublic(row){

  //alert("Downloading file from remote server feature is in progress!")

   let formData = {
    "filename":"myupload2.jpg",
    "sourceurl": row.filename
  };
  let fileurl = await this.fileService.fetchRemoteFile(  formData, true, false );
  const learningId = this.categories.filter(val => val.category === 'Learning');
   
  let rawdata = {
    "filename": fileurl,
    "title": row.title,
    "file":{},
    "category": learningId[0]._id    
  }

  this.knowledgeService.addKnowledgFile(rawdata).subscribe(response => {
    this.coreservice.success(this.resource.message('KNOWLEDGE_SAVE'));
//    this.router.navigate(['/knowledgehub/faknowledgehub'])
    this.router.navigateByUrl('/', {skipLocationChange: false}).then(()=>
    this.router.navigate(['/knowledgehub/faknowledgehub']));
  });

 }

  
 getAllKnowledge()
  {
    let tmpsource = this.source;
    if(this.source == 'template') tmpsource = 'superadmin';

    if(this.source == "familyadmin"){
      this.knowledgeService.getKnwoledgeFiles().subscribe(response=> {
        const result = response.body;

        var files = response.body['data'];
        files.forEach(element => {
          element["categoryname"] = (element["category"]!=null)?element["category"]["category"]:"";
          console.log("PUSHEDDD:"+element["categoryname"])
        });
        
        this.listData.data=files; 

     //   this.listData.data=response.body['data'] as KnowldgeHubElement[]
        this.table.renderRows();
      })
    }else{
      this.adminKnowledgeHubService.getKnwoledgeFiles(tmpsource).subscribe(response=> {
        
        this.listData.data= response.body['results'] ; //as KnowldgeHubElement[]
        this.table.renderRows();
      })

    }

   


   

   }



   onSearchClear()
   {
     this.searchKey="";    
     this.applyfilter();
   }
   applyfilter()
   {
    this.listData.filterPredicate = 
    function (data, filter) {
        var dataStr = Object.keys(data).reduce(function (currentTerm, key) {
            return currentTerm + data[key] + '◬';
        }, '').toLowerCase();
        var transformedFilter = filter.trim().toLowerCase();
        return dataStr.indexOf(transformedFilter) != -1;
    };
     this.listData.filter=this.searchKey.trim().toLowerCase();
   }
   

   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.listData.data.forEach(row => this.selection.select(row));
  }

  addKnwoledge(){
    this.router.navigate(['/adminknowledgehub/add'],{queryParams:{source: this.source}});

  }

  edit(role){
    this.router.navigate(['adminknowledgehub/add'],{queryParams: {id: role._id,source: this.source}});

  }

  onDelete(id){
    this.coreservice.openConfirmDialog(this.resource.message('KNOWLEDGE_DELETE'))
    .afterClosed().subscribe(res=>{
      if(res){

        if(this.source == "familyadmin"){
          this.knowledgeService.removeKnowledgeFile(id).subscribe(response=> {
            const result = response;
            this.getAllKnowledge();
            this.coreservice.success(this.resource.message('KNOWLEDGE_DELETED'));
  
           }, (err) => {
           })
        }else{
          this.adminKnowledgeHubService.removeKnowledgeFile(id).subscribe(response=> {
            const result = response;
            this.getAllKnowledge();
            this.coreservice.success(this.resource.message('KNOWLEDGE_DELETED'));
  
           }, (err) => {
           })

        }

       

        
        
      }
    })
  }



  closeMyMenu() {
    this.trigger.closeMenu();
  }


  
  filterData(ev,col,val) 
  {
    if(ev.checked){
      col = String(col);
       
      let value = {[col]:val};
      this.filterValues.push(value);
    }else{
      let value = {[col]:val};
      const index = this.filterValues.indexOf(value);
      this.filterValues.splice(index-1,1);
    }

    
    this.setupFilter(col);
    this.applyFilter(val)
   
  } 

  stoppro($event){
    $event.stopPropagation();
   
  }

  setupFilter(column: string) {
    this.listData.filterPredicate = (d: any, filter: string) => {
    const val = JSON.parse(filter);
    var flag = false;

      val.forEach(function(item){  
         let obj = item;
         const textToSearch = d[column] && d[column].toLowerCase() || '';
            if(obj[column] != undefined){
              if(textToSearch.toLowerCase().indexOf(obj[column].toLowerCase()) !== -1){
                flag = true;
              }
            }
            
        }); 
        return flag;
     
    };
  }
  
  applyFilter(filterValue: string) {
    
    this.listData.filter =  JSON.stringify(this.filterValues);// filterValue.trim().toLowerCase();
  }


  filterValues = [];
}

<mat-list role="list">
  <div mat-subheader>
    Notifications
    <button (click)="notify = []" mat-button class="clrbutton">
      Clear All
    </button>
    <mat-icon
      style="cursor: pointer"
      id="close-icon"
      class="close-icon"
      (click)="closeDialog()"
    >
      close</mat-icon
    >
  </div>
  <mat-list-item
    *ngFor="let data of notify"
    [ngClass]="data.status == 'read' ? 'status-read' : 'status-unread'"
  >
    <div mat-line>{{ data.notification }}</div>
    <div mat-line>{{ data.updated | customDate }}</div>
    <div *ngIf="data.status == 'unread'" class="unreadcard"></div>
  </mat-list-item>
</mat-list>

<div class="content p30">
  <div id="block_container">
    <a
      routerLink="/user"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >

    <div id="bloc2">
      <h5>{{ resource.message("USERS") }} / {{ labelText }}</h5>
    </div>
  </div>

  <form [formGroup]="form" (ngSubmit)="submit()">
    <fieldset>
      <!--[disabled]=" (familyname!=select_user_family) ? isDisabled :''"-->
      <mat-card class="">
        <mat-card-content>
          <br />
          <div>
            <label class="formtitle"> Personal Details </label>
            <!-- <i *ngIf="familyname!=select_user_family" class='viewmsg'>User in view only mode.</i>-->
            <mat-select
              placeholder="Status"
              formControlName="status"
              class="editselect"
            >
              <mat-option
                *ngFor="let type of STATUS_LIST | keyvalue"
                [value]="type.key"
                >{{ type.value }}</mat-option
              >
            </mat-select>
          </div>

          <hr />

          <mat-grid-list cols="3" rowHeight="100px">
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <input type="hidden" formControlName="_id" />
                <input type="hidden" formControlName="isDel" />
                <input type="hidden" formControlName="updated_by" />
                <mat-label>First Name</mat-label>
                <input
                  matInput
                  placeholder="First Name"
                  formControlName="name"
                  required
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Middle Name</mat-label>
                <input
                  matInput
                  placeholder="Middle Name"
                  formControlName="middlename"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Last Name</mat-label>
                <input
                  matInput
                  placeholder="Last Name"
                  formControlName="lastname"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Role Name :</mat-label>
                <mat-select
                  placeholder="Role"
                  formControlName="role_uac"
                  (selectionChange)="setrole($event)"
                  required
                >
                  <mat-option
                    *ngFor="let role of ROLES_LIST"
                    [value]="role._id + ''"
                    >{{ role.role }}</mat-option
                  >
                </mat-select>
                <mat-error>This field is mandatory.</mat-error>
              </mat-form-field>
            </mat-grid-tile>

            <!--<mat-grid-tile >
                <mat-form-field appearance="outline"class="field-spacer">
                    <mat-label>Profile Photo</mat-label>
                    <input matInput placeholder="Profile Photo" formControlName="profile_photo">
                  </mat-form-field>
            </mat-grid-tile>-->

            <mat-grid-tile>
              <div
                class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored"
              >
                <mat-label>Profile Photo</mat-label><br />
                <label style="margin-top: 8px; margin-bottom: 56px">
                  <span class="upload-btn"> Choose File </span>

                  <input
                    style="display: none"
                    name="File1"
                    placeholder="Profile Photo"
                    type="file"
                    (change)="onFileChange($event)"
                /></label>
                <div style="display: inline-block">
                  <div style="display: flex; align-items: center">
                    <div
                      style="
                        display: inline-block;
                        margin: 0;
                        width: 70px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                      *ngIf="this.currentfilename !== ''"
                    >
                      {{ this.currentfilename }}
                    </div>
                    <div
                      class="input-preview-btn"
                      (click)="previewimg()"
                      *ngIf="this.form.get('profile_photo').value !== ''"
                    >
                      Preview
                    </div>
                  </div>
                </div>
                <!-- <a class="input-preview-btn" *ngIf="photo != '' && photo != null" (click)="previewimg()">
                  Preview</a> -->
              </div>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Address</mat-label>
                <input
                  matInput
                  placeholder="Address"
                  formControlName="address"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Mobile</mat-label>
                <input
                  matInput
                  placeholder="Mobile"
                  formControlName="mobile"
                  formControlName="mobile"
                  inputmode="numeric"
                  pattern="^(\+\d{1,3}[- ]?)?\d{10}$"
                  digitOnly
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Email ID</mat-label>
                <input
                  matInput
                  placeholder="Email ID"
                  formControlName="email"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Date of Birth</mat-label>
                <mat-hint>MM/DD/YYYY</mat-hint>
                <input
                  formControlName="dob"
                  matInput
                  [matDatepicker]="picker"
                  [max]="maxDate"
                  placeholder="Choose a date"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <!-- <input matInput [matDatepicker]="dp" formControlName="dob" placeholder="Date of Birth" required /> -->
                <!-- <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp disabled="false"></mat-datepicker> -->
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <div class="div1">
                <label>Marital Status : </label><br />
                <mat-radio-group
                  appearance="outline"
                  class="field-spacer"
                  [formControl]="marriedControl"
                  (change)="changeMarriageStatus($event)"
                >
                  <mat-radio-button
                    value="UnMarried"
                    [checked]="marriedControl.value == 'UnMarried'"
                    (change)="this.form.patchValue({ married: 'UnMarried' })"
                    >UnMarried</mat-radio-button
                  >
                  <mat-radio-button
                    value="Married"
                    [checked]="marriedControl.value == 'Married'"
                    class="m20"
                    (change)="this.form.patchValue({ married: 'Married' })"
                    >Married</mat-radio-button
                  >
                </mat-radio-group>
              </div>
            </mat-grid-tile>

            <mat-grid-tile *ngIf="marriedControl.value == 'Married'">
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Date of Marriage</mat-label>
                <input
                  [max]="maxDate"
                  matInput
                  [matDatepicker]="dp4"
                  formControlName="anniversary_date"
                  placeholder="Date of Marriage"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dp4"
                ></mat-datepicker-toggle>
                <mat-datepicker #dp4 disabled="false"></mat-datepicker>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Family</mat-label>
                <mat-select
                  placeholder="Family"
                  formControlName="family"
                  (selectionChange)="onFamilyChange($event)"
                  required
                >
                  <mat-option
                    *ngFor="let families of FAMILIES"
                    [value]="families._id + ''"
                    >{{ families.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile
              *ngIf="
                select_user_family != CORE_FAMILY &&
                rolename !== 'admin' &&
                rolename !== 'office_member'
              "
            >
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Facilitator</mat-label>
                <mat-select placeholder="Facilitator" formControlName="coach">
                  <mat-option
                    *ngFor="let role of ADVISOR_LIST"
                    [value]="role.user_id + ''"
                    >{{ role.name }} {{ role.lastname }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile
              *ngIf="select_user_family != ''"
            >
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Linked In</mat-label>
                <input
                  matInput
                  placeholder="Linked In"
                  formControlName="linked_in"
                />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile
              *ngIf="select_user_family != ''"
            >
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>PAN</mat-label>
                <input
                  matInput
                  placeholder="PAN"
                  formControlName="pan"
                />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile
              *ngIf="select_user_family != ''"
            >
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Passport</mat-label>
                <input
                  matInput
                  placeholder="Passport"
                  formControlName="passport"
                />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile
              *ngIf="select_user_family != '' && prevdesignation != ''"
            >
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Designation</mat-label>
                <input
                  matInput
                  placeholder="Family"
                  value="{{ prevdesignation }}"
                />
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

          <br />
          <h5>Social Media Links</h5>
          <hr />

          <mat-grid-list
            cols="10"
            rowHeight="80px"
            formArrayName="social_links"
          >
            <div
              *ngFor="let item of sociallinks.controls; let pointIndex = index"
              [formGroupName]="pointIndex"
            >
              <mat-grid-tile colspan="3">
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Social Network Site</mat-label>
                  <mat-select
                    placeholder="Social"
                    [value]="network"
                    formControlName="network"
                  >
                    <mat-option
                      *ngFor="
                        let social of handleSoc(SOCIAL_SITES, pointIndex)
                      "
                      [value]="social.toLowerCase().split(' ').join('')"
                      >{{ social }}</mat-option
                    >
                  </mat-select>
                  <mat-error>This field is mandatory.</mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile colspan="6">
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>URL Link</mat-label>
                  <input
                    matInput
                    placeholder="URL Link"
                    formControlName="url"
                  />
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile style="padding: 0px">
                <button
                  style="height: 80%; width: 50%"
                  mat-button
                  type="button"
                  color="warn"
                  appearance="outline"
                  class="field-spacer"
                  (click)="deleteSocial(pointIndex)"
                  *ngIf="pointIndex != sociallinks.controls.length - 1"
                >
                  <span
                    class="material-icons"
                    inline="true"
                    style="transform: scale(1.5)"
                    >delete_outline</span
                  >
                </button>
                <button
                  style="height: 80%; width: 50%"
                  mat-button
                  type="button"
                  color="primary"
                  appearance="outline"
                  class="field-spacer"
                  (click)="addSocial(item)"
                  [disabled]="sociallinks.controls.length >= SOCIAL_SITES.length"
                  *ngIf="pointIndex == sociallinks.controls.length - 1"
                >
                  <span
                    class="material-icons"
                    inline="true"
                    style="transform: scale(1.5)"
                    >note_add</span
                  >
                </button>
              </mat-grid-tile>
            </div>
          </mat-grid-list>

          <br />
          <ng-container *ngIf="designation.controls && designation.controls.length">
          <h5 *ngIf="select_user_family != CONSTANT.CORE_FAMILY_NAME">
            Designation
          </h5>

          <hr />
          
          <mat-grid-list
            cols="10"
            rowHeight="80px"
            formArrayName="designations"
            *ngIf="select_user_family != '' && CONSTANT.CORE_FAMILY_NAME != ''"
          >
            <div
              *ngFor="let item of designation.controls; let pointIndex = index"
              [formGroupName]="pointIndex"
            >
              <mat-grid-tile colspan="3">
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Parent Business / Business Unit</mat-label>
                  <mat-select
                    placeholder="Parent Business / Business Unit"
                    [value]="business"
                    formControlName="business"
                  >
                    <mat-option
                      *ngFor="let businessunit of BUSINESS_UNIT_LIST"
                      [value]="businessunit._id + ''"
                      >{{ businessunit.name }}</mat-option
                    >
                  </mat-select>
                  <mat-error>This field is mandatory.</mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile colspan="6">
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Position</mat-label>
                  <input
                    matInput
                    placeholder="Position"
                    formControlName="designation"
                  />
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile style="padding: 0px">
                <button
                  style="height: 80%; width: 50%"
                  mat-button
                  type="button"
                  color="warn"
                  appearance="outline"
                  class="field-spacer"
                  (click)="deleteDesignation(pointIndex)"
                  *ngIf="pointIndex != designation.controls.length - 1"
                >
                  <span
                    class="material-icons"
                    inline="true"
                    style="transform: scale(1.5)"
                    >delete_outline</span
                  >
                </button>
                <button
                  style="height: 80%; width: 50%"
                  mat-button
                  type="button"
                  color="primary"
                  appearance="outline"
                  class="field-spacer"
                  (click)="addDesignation()"
                  *ngIf="pointIndex == designation.controls.length - 1"
                >
                  <span
                    class="material-icons"
                    inline="true"
                    style="transform: scale(1.5)"
                    >note_add</span
                  >
                </button>
              </mat-grid-tile>
            </div>
          </mat-grid-list>
        </ng-container>
          <br />
          <h5>ID Details</h5>
          <hr />

          <mat-grid-list cols="10" rowHeight="80px" formArrayName="id_details">
            <div
              *ngFor="let item of ids.controls; let pointIndex = index"
              [formGroupName]="pointIndex"
            >
              <mat-grid-tile colspan="3">
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>ID Type</mat-label>
                  <mat-select placeholder="ID Type" formControlName="id_type">
                    <mat-option
                      *ngFor="let social of CONSTANT.getConstant('ID_TYPES')"
                      [value]="social"
                      >{{ social }}
                    </mat-option>
                  </mat-select>
                  <mat-error>This field is mandatory.</mat-error>
                </mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile colspan="6">
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>ID Number</mat-label>
                  <input
                    matInput
                    placeholder="ID Number"
                    formControlName="id_no"
                  />
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile style="padding: 0px">
                <button
                  style="height: 80%; width: 50%"
                  mat-button
                  type="button"
                  color="warn"
                  appearance="outline"
                  class="field-spacer"
                  (click)="deleteId(pointIndex)"
                  *ngIf="pointIndex != ids.controls.length - 1"
                >
                  <span
                    class="material-icons"
                    inline="true"
                    style="transform: scale(1.5)"
                    >delete_outline</span
                  >
                </button>
                <button
                  style="height: 80%; width: 50%"
                  mat-button
                  type="button"
                  color="primary"
                  appearance="outline"
                  class="field-spacer"
                  (click)="addId()"
                  *ngIf="pointIndex == ids.controls.length - 1"
                >
                  <span
                    class="material-icons"
                    inline="true"
                    style="transform: scale(1.5)"
                    >note_add</span
                  >
                </button>
              </mat-grid-tile>
            </div>
          </mat-grid-list>
        </mat-card-content>

        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="submit"
            [disabled]="INVALID_STATE"
          >
            Save
          </button>
          <button
            mat-raised-button
            color="clear"
            class="button-wider"
            routerLink="/user"
          >
            Cancel
          </button>
        </mat-card-actions>
      </mat-card>
    </fieldset>
  </form>
</div>

 


 
import { Component, OnInit, ViewChild ,ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { CategoriesReportsService } from '@services/CategoriesReports.service';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import {CoreService} from '@services/CoreService';
import { MatMenuTrigger,MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import * as jsPDF from 'jspdf';
import { AuthService } from '@services/Auth.service';
//import autoTable from 'jspdf-autotable';

export interface CategoryElement {
  _id: number;
  display_message: string;
  type:string;
  title: string;
  created_at: string;
  updated_at: string;
  parent: any;
  catstring: string[];
}



@Component({
  selector: 'app-reportlist',
  templateUrl: './reportlist.component.html',
  styleUrls: ['./reportlist.component.css']
})
export class ReportlistComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;
  
  ELEMENT_DATA: CategoryElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = [ 'created_at','category','title', 'Action'];
  listData = new MatTableDataSource <CategoryElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<CategoryElement>(true, []);
 

  PERMISSION_EDIT = AuthService.authentication('Roles','READ');
  PERMISSION_ADD = AuthService.authentication('Roles','ADD');
  PERMISSION_DELETE = AuthService.authentication('Roles','DELETE');


  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTablecategoryTable') table: MatTable<CategoryElement>;

  resource = language;

  constructor(private categoriesReportsService: CategoriesReportsService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router : Router,
    private http: HttpClient,
    private coreservice:CoreService
    ) {

      AuthService.authenticate_redir('Users', ['READ']);


    this.listData = new MatTableDataSource<CategoryElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<CategoryElement>(true, []);


console.log(language.message("a"));

   }

  ngOnInit(): void {
 
    this.listData.paginator=this.paginator;
    this.listData.sort=this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllParentCategories();
    this.table.renderRows();   

   
    
 

    

 }



  getAllParentCategories()
  {

    this.categoriesReportsService.getDocuments().subscribe(response=> {
      
      const result =response.body['data'] as CategoryElement[];
      this.listData.data=response.body['data'] as CategoryElement[]
     
      for(let i=0;i<result.length;i++ ){
          this.listData.data[i].catstring=[];
          this.fetchChild(result[i], i);
      }

      this.table.renderRows();

     }, (err) => {

        if(err.error.status != null){
//          this.errmessage = err.error.message;
        }else{
 //         this.errmessage = err.error.errors[0].msg;
        }       
    })

   }

   fetchChild(item,index){
      if(item.parent != null){
          this.listData.data[index].catstring.unshift(item.parent.title);        
          this.fetchChild(item.parent, index);
      }
   }

   onSearchClear()
   {
     this.searchKey="";    
     this.applyfilter();
   }
   applyfilter()
   {
    this.listData.filterPredicate = 
    function (data, filter) {
        var dataStr = Object.keys(data).reduce(function (currentTerm, key) {
            return currentTerm + data[key] + '◬';
        }, '').toLowerCase();
        var transformedFilter = filter.trim().toLowerCase();
        return dataStr.indexOf(transformedFilter) != -1;
    };
     this.listData.filter=this.searchKey.trim().toLowerCase();
   }
   

   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.listData.data.forEach(row => this.selection.select(row));
  }

  addrole(){
    this.router.navigate(['/reports/report/add']);

  }

  edit(role){
    this.router.navigate(['/reports/report/add'],{queryParams: {id: role._id}});

  }

  onDelete(id){
    this.coreservice.openConfirmDialog(this.resource.message('REPORT_DELETE'))
    .afterClosed().subscribe(res=>{
      if(res){
       
       
    
        this.categoriesReportsService.deleteCategory(id).subscribe(response=> {    
         
          this.getAllParentCategories();
  
        }); 
        
      }
    })
  }



  closeMyMenu() {
    this.trigger.closeMenu();
  }


  
  filterData(ev,col,val) 
  {
    if(ev.checked){
      col = String(col);
       
      let value = {[col]:val};
      this.filterValues.push(value);
    }else{
      let value = {[col]:val};
      const index = this.filterValues.indexOf(value);
      this.filterValues.splice(index-1,1);
    }

    
    this.setupFilter(col);
    this.applyFilter(val)
   
  } 

  stoppro($event){
    $event.stopPropagation();
   
  }

  setupFilter(column: string) {
    this.listData.filterPredicate = (d: any, filter: string) => {
    const val = JSON.parse(filter);
    var flag = false;

      val.forEach(function(item){  
         let obj = item;
         const textToSearch = d[column] && d[column].toLowerCase() || '';
            if(obj[column] != undefined){
              if(textToSearch.toLowerCase().indexOf(obj[column].toLowerCase()) !== -1){
                flag = true;
              }
            }
            
        }); 
        return flag;
     
    };
  }
  
  applyFilter(filterValue: string) {
    
    this.listData.filter =  JSON.stringify(this.filterValues);// filterValue.trim().toLowerCase();
  }


  filterValues = [];

  /*print(){
    let doc = new jsPDF(); 
    doc.autoTable({
      head: [['NAme','approved','utilised', 'available','asd','sadadasada','asdas']],
      body: this.dataSource.filteredData //returning [["log1", "$100"], ["log2", "$200"]]
    });
    doc.save('table.pdf')
  }
*/
}

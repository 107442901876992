import { Component, OnInit } from '@angular/core';
import {language} from '@shared/config/language';
import { BuisnessinfoService } from './buisnessinfo.service';

@Component({
  selector: 'app-businessinfo',
  templateUrl: './businessinfo.component.html',
  styleUrls: ['./businessinfo.component.css']
})
export class BusinessinfoComponent implements OnInit {

  resource=language;
  parent:string='parent';
  selectedTab=0;
  constructor(private buisnessInfo: BuisnessinfoService) { 
    this.selectedTab = this.buisnessInfo.selectedTab;
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.buisnessInfo.selectedTab = 0;
  }

}

 <form >
      <mat-card class="">
        <mat-card-content class="contentclass">
          <hr />
          <mat-grid-list cols="1" rowHeight="100px" gutterSize="16px" >
                <mat-grid-tile >
                    <mat-form-field appearance="outline" class="field-spacer">
                        <mat-label>Subject</mat-label>
                        <input matInput placeholder="Subject">
                    </mat-form-field>
                </mat-grid-tile>
                
                <mat-grid-tile [colspan]="1" [rowspan]='7' >
                    <mat-form-field appearance="outline" class="field-spacer messagebox">
                        <mat-label>Message</mat-label>
                        <textarea matInput placeholder="Message" class="messagebox" rows="45"></textarea>
                    </mat-form-field>
                </mat-grid-tile>
                
                <mat-grid-tile >
                  <div class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored">
                      <mat-label>Attachment</mat-label>
                      <input name="File1"  placeholder="Upload Minutes" type="file" />
                    </div>
              </mat-grid-tile> 
          </mat-grid-list>
        </mat-card-content>
        <mat-card-actions align="end">
            <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="submit" >Create</button>
           
        </mat-card-actions>
      </mat-card>
    </form>
  
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { MeetingElement } from '@modules/othermeetings/othermeetings.component';
import { AuthService } from '@services/Auth.service';
import { CommitteeService } from '@services/Committee.service';
import { CoreService } from '@services/CoreService';
import { OtherMeetingService } from '@services/OtherMeeting.service';

@Component({
  selector: 'app-meetings-card',
  templateUrl: './meetings-card.component.html',
  styleUrls: ['./meetings-card.component.css'],
})
export class MeetingsCardComponent implements OnInit {
  displayedColumns: string[] = [
    'start_date',
    'start',
    'end',
    'members',
    'type',
  ];
  listData = new MatTableDataSource<MeetingElement>();
  meetings = [];
  total = 0;
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<MeetingElement>(true, []);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('MeetingsTable') table: MatTable<MeetingElement>;

  constructor(
    private commiteeService: CommitteeService,
    private router: Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private othermeetinginfoservice: OtherMeetingService
  ) {}

  ngOnInit(): void {
    AuthService.authenticate_redir('CommitteeMeeting', ['READ']);

    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();

    this.renderTable();
  }

  renderTable() {
    this.getAllCommiteeMeetings();
    this.getothermeetingdata();
    // this.listData.data = this.meetings;
    // this.listData.data = this.listData.data.sort((a, b) => {
    //   console.log('00000000000');
    //   const r = a['start_date'] > b['start_date'] ? 1 : -1;
    //   console.log(r);
    //   return r;
    // }) as MeetingElement[];
    console.log('this.listData.data--->', this.listData.data);
    this.table.renderRows();
  }

  getothermeetingdata() {
    var filter = '';
    this.othermeetinginfoservice.getOtherMeetings(filter).subscribe(
      (response: any) => {
        response.body['data'].forEach((item) => {
          let newItem = {
            start_date: item.start_date,
            start_time: item.start_time,
            end_time: item.end_time,
            type: 'Other meeting',
            members: item.participants.map((member) => {
              return { name: member.user.name + ' ' + member.user.lastname };
            }),
          };
          if (
            new Date(new Date(item.start_date).setHours(0, 0, 0, 0)) >=
            new Date(new Date().setHours(0, 0, 0, 0))
          ) {
            this.meetings.push(newItem);
          }
        });
        this.total = this.meetings?.length;
        this.listData.data = this.meetings
          .sort((a, b) => {
            const r = a['start_date'] > b['start_date'] ? 1 : -1;
            console.log(r);
            return r;
          })
          .slice(0, 5);

        this.table.renderRows();
      },
      (err) => {}
    );
  }

  // Fetch committee meetings
  getAllCommiteeMeetings() {
    var filter = '?filter[type]=committee';
    this.commiteeService.getCommitteeMeetings(filter).subscribe(
      (response: any) => {
        const result = response;
        console.log('result', result);
        response.data.forEach((item) => {
          let newItem = {
            start_date: item.start_date,
            start_time: item.start_time,
            end_time: item.end_time,
            type: 'Committee meeting',
            members: item.members,
          };
          if (
            new Date(new Date(item.start_date).setHours(0, 0, 0, 0)) >=
            new Date(new Date().setHours(0, 0, 0, 0))
          ) {
            this.meetings.push(newItem);
          }
        });
        this.listData.data = this.meetings;
        this.table.renderRows();
        // this.table.renderRows();
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  viewMore() {
    this.router.navigate(['committee-meetings']);
  }
}

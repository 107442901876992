import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators} from '@angular/forms';
import { UACService } from '@services/UAC.service';
import { ActivatedRoute, Router } from '@angular/router';
import {CoreService} from '../../../../shared/services/CoreService';
import {language} from '@shared/config/language';
@Component({
  selector: 'app-addmodule',
  templateUrl: './addmodule.component.html',
  styleUrls: ['./addmodule.component.css']
})

export class AddmoduleComponent implements OnInit {

  GROUP_DATA:any;
  IS_MENU:any;
  isEdit:Boolean = false;
  form :FormGroup = new FormGroup({
    _id:new FormControl(0),
    module:new FormControl('',Validators.required),
    group:new FormControl('',Validators.required),
    icon: new FormControl(''),
    route: new FormControl(''),
    isMenu: new FormControl(false),
    priority: new FormControl(''),
    isDel: new FormControl('',Validators.required),
    updated_by: new FormControl('',Validators.required) 
    
  })
 resource = language;
 labelText:String='Add Module';

  constructor(private uacservice: UACService, private router : Router,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService
   
    ) {

      this.GROUP_DATA = ["Super Admin","Family Admin"];
      this.IS_MENU = [
        {
          key:true,val:"Yes"
        },
        {
          key:false,val:"No"
        },
    ];


    this.activeRoute.queryParams.subscribe(data => {

      if(data.id!=undefined)
      {
        this.labelText='Edit Module';
        this.uacservice.getModule(data.id).subscribe(response=> {
      
          const result = response.body["module"];
          console.log(">>>>>>>>>>>>>>>>>>",result.module);
            this.form.setValue({_id:result._id,module:result.module,icon:result.icon,route:result.route, group: result.group, isDel:result.isDel, updated_by:result.updated_by,isMenu:result.isMenu, priority:result.priority
            })
            this.isEdit = true;
            console.log(this.form);
        });
      } 
    })

   }

  ngOnInit(): void {

    this.form.setValue({
      _id:0,
      module: '',
      group:'',
      icon: '',
      route: '',
      isDel:false,
      isMenu:false,
      priority: '',
      updated_by: "5e3a77df4a0460002a8083ae"
  })

  }

   

  submit():void{ 

    console.log("IN SUB")
    if (this.form.invalid) {
        return;
    }

    if(!this.isEdit)         this.form.removeControl('_id');



    this.uacservice.addModule(this.form.getRawValue()).subscribe(response=> {
      
      const result = response.body;
   
      if(!this.isEdit)
      {
        this.coreservice.success(this.resource.message("MODULE_SAVE"));
      }
      else
      {
        this.coreservice.success(this.resource.message("MODULE_UPDATE"));
      }
      
     this.router.navigate(['module']);

     }, (err) => {

      this.coreservice.error(err.error.message);
      this.form.addControl('_id', new FormControl(''));

      
   })
   
  }
 
}

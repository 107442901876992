import { Component, OnInit, Inject } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormArray,
} from '@angular/forms';
import { language } from '@shared/config/language';
import { FileService } from '@services/File.service';
import { BusinessInfoService } from '@services/BusinessInfo.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CoreService } from '@services/CoreService';
import { FamilyInfoService } from '@services/FamilyInfo.service';

@Component({
  selector: 'app-dialogbusinessimage',
  templateUrl: './dialogbusinessimage.component.html',
  styleUrls: ['./dialogbusinessimage.component.css'],
})
export class DialogbusinessimageComponent implements OnInit {
  photoform: FormGroup = new FormGroup({
    title: new FormControl('', Validators.required),
    file: new FormControl('', Validators.required),
    _id: new FormControl(''),
    business: new FormControl(''),
    type: new FormControl(''),
    url: new FormControl(''),
  });
  isSubmitingForm = false;
  resource = language;
  selFile = new Object();
  selFileName = '';
  postform: FormGroup = new FormGroup({
    Photos: new FormArray([]),
  });
  constructor(
    private fileService: FileService,
    private businessinfoservice: BusinessInfoService,
    public dialogRef: MatDialogRef<DialogbusinessimageComponent>,
    private coreservice: CoreService,
    @Inject(MAT_DIALOG_DATA) public data,
    private familyinfoservice: FamilyInfoService
  ) {}

  ngOnInit(): void {
    console.log('>>>>>>>>>>>>>>>>>*********IN HERE', this.data);
    if (this.data.index != -1) {
      console.log('>>>>>>>>>>>>>>>>>*********IN HERE', this.data);

      const ind = this.data.index;
      this.photoform.setValue({
        _id: this.data.id,
        title: ind['title'],
        file: ind['url'] != null ? ind['url'] : '',
        business: ind['business'],
        type: ind['type'],
        url: ind['url'] != null ? ind['url'] : '',
      });
      this.data.prevdata.splice(ind, 1);
    } else {
      console.log(this.data)
      this.photoform.patchValue({
        business: this.data.id,
      });
    }
  }
  onFileChange(event) {
    this.selFile = (event.target as HTMLInputElement).files[0];
    this.selFileName = (event.target as HTMLInputElement).files[0].name;
  }

  async submitPhoto() {
    if (this.photoform.invalid) {
      return;
    }
    this.isSubmitingForm = true;
    var constdata = this.photoform.getRawValue();
  
    if (this.selFileName != '') {
      // delete previous image
      let temp = this.photoform.get('url').value;
      let url = temp.split('?');

      this.fileService.deleteFile(url[1]).subscribe(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
      let fileurl = this.selFileName;
      var formData: any = new FormData();
      formData.append('file', this.selFile);
      fileurl = await this.fileService.uploadFile(formData, true, false);
      constdata['url'] = fileurl;
    }

    constdata['type'] = 'business';

    delete constdata['file'];
    if (this.data.index == -1) {
      delete constdata['_id'];
      // constdata['business']=constdata['_id']
    }
    console.log('FORM DATA TO BE POSTED', constdata);
    this.familyinfoservice.addPhoto(constdata).subscribe(
      (response) => {
        this.isSubmitingForm = false;
        const result = response.body;
        this.coreservice.success(this.resource.message('BUSINESS_IMAGE_SAVE'));
        this.closeDialog();
      },
      (err) => {
        console.log(err);
        this.isSubmitingForm = false;
        this.coreservice.error('Something went wrong, please try again!');
        // this.closeDialog();
      }
    );
  }
  closeDialog() {
    this.dialogRef.close(false);
  }

  previewimg() {
    window.open(this.photoform.get('url').value, '_blank');
  }
}

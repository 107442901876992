<div align="right">
  <div class="content-container">
    <mat-card class="cardclass">
      <mat-card-header>
        <mat-card-title>
          {{ resource.message("MEETINGPARTICIPANTS") }}
        </mat-card-title>
        <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form">
          <mat-grid-list cols="1" rowHeight="100px" gutterSize="16px">
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Subject</mat-label>
                <input
                  matInput
                  placeholder="Subject"
                  formControlName="subject"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Message</mat-label>
                <textarea
                  matInput
                  placeholder="Message"
                  class="messagebox"
                  formControlName="message"
                ></textarea>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <div
                class="mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored"
              >
                <div class="attach">
                  <mat-label>Attachment</mat-label>
                  <input
                    name="File1"
                    placeholder="Upload Attachment"
                    type="file"
                    formControlName="file"
                    (change)="onFileChange($event)"
                  />
                </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-card-actions align="end">
            <button
              mat-raised-button
              color="primary"
              class="btn btn-primary login-btn button-wider"
              type="submit"
              (click)="submitData()"
            >
              Save
            </button>
          </mat-card-actions>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>

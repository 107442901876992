<form [formGroup]="form">
  <fieldset>
    <mat-card class="main-card">
      <mat-card-content>
        <mat-form-field appearance="outline" class="field-spacer">
          <textarea
            matInput
            class="textarea"
            rows="6"
            formControlName="title"
          ></textarea>
        </mat-form-field>
        <mat-grid-list cols="2" rowHeight="100px">
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Start date</mat-label>
              <input
                matInput
                [min]="todayDate"
                [matDatepicker]="dp3"
                disabled
                placeholder="Start date"
                formControlName="start_date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dp3"
              ></mat-datepicker-toggle>
              <mat-datepicker #dp3 disabled="false"></mat-datepicker>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>End date</mat-label>
              <input
                matInput
                [min]="todayDate"
                [matDatepicker]="dp4"
                disabled
                placeholder="End date"
                formControlName="end_date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dp4"
              ></mat-datepicker-toggle>
              <mat-datepicker #dp4 disabled="false"></mat-datepicker>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
        <div class="mr-1">
          <button
            mat-raised-button
            color="primary"
            class="addbutton"
            class="buttonfloatright"
            (click)="openDialog('Add Participants', '', '')"
          >
            <i class="fas fa-plus"></i>&nbsp;&nbsp;ADD PARTICIPANTS
          </button>
        </div>
        <br />
        <p>
          <br />
          <mat-table
            #MeetingsTable
            MeetingsTable
            matTableExporter
            [dataSource]="listData"
            matSort
            class="mattable"
            #exporter="matTableExporter"
            [hiddenColumns]="[1, 2, 3, 4, 5]"
          >
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                >Name</mat-header-cell
              >
              <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="Action">
              <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
              <mat-cell *matCellDef="let row; let i = index">
                <button mat-icon-button color="warn" (click)="removeParticipant(row.id)">
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
          <mat-paginator
            #paginator
            [pageSizeOptions]="[5, 10, 15, 20]"
            [pageSize]="5"
            showFirstLastButtons
          ></mat-paginator>
        </p>
        <div class="mr-1">
          <button
            mat-raised-button
            color="primary"
            class="addbutton"
            class="buttonfloatright"
            (click)="openSupDialog('Add Supporters', '', '')"
          >
            <i class="fas fa-plus"></i>&nbsp;&nbsp;ADD SUPPORTERS
          </button>
        </div>
        <br />
        <p>
          <br />
          <mat-table
            #SupMeetingsTable
            SupMeetingsTable
            matTableExporter
            [dataSource]="supData"
            matSort
            class="mattable"
            #exporter="matTableExporter"
            [hiddenColumns]="[1, 2, 3, 4, 5]"
          >
            <ng-container matColumnDef="supname">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                >Name</mat-header-cell
              >
              <mat-cell *matCellDef="let element">{{
                element.supname
              }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="Action1">
              <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
              <mat-cell *matCellDef="let row; let i = index">
                <button mat-icon-button color="warn" (click)="removeSupporter(row.id)">
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedSupColumns"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedSupColumns"
            ></mat-row>
          </mat-table>
          <mat-paginator
            #suppaginator
            [pageSizeOptions]="[5, 10, 15, 20]"
            [pageSize]="5"
            showFirstLastButtons
          ></mat-paginator>
        </p>
      </mat-card-content>
      <mat-card-footer>
        <div class="divfloat">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary done-btn button-wider"
            (click)="saveTask()"
          >
            Save
          </button>
        </div>
      </mat-card-footer>
    </mat-card>
  </fieldset>
</form>
<ng-template #firstDialog>
  <h2 matDialogTitle>Alart !</h2>
  <p matDialogContent><em>Member already in Supporters List</em></p>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose>Dismiss</button>
  </mat-dialog-actions>
</ng-template>
<ng-template #secondDialog>
  <h2 matDialogTitle>Alart !</h2>
  <p matDialogContent><em>Member already in Participants List</em></p>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose>Dismiss</button>
  </mat-dialog-actions>
</ng-template>
<div align="right">
  <div class="content-container">
    <mat-card class="cardclass">
      <mat-card-header>
        <mat-card-title>
          {{ labelText }}
        </mat-card-title>
        <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
      </mat-card-header>
      <mat-card-content>
        <form>
          <div class="mr-1 searchbox">
            <input
              matInput
              [(ngModel)]="searchKey"
              placeholder="{{ resource.message('SEARCH_BY_USER') }}"
              autocomplete="off"
              (keyup)="applyfilter()"
              name="searchKey"
              class="searchfloatright"
            />
          </div>
        </form>
        <br />
        <br />
        <br />
        <div class="invitee-list">
        <mat-table
          #roleTable
          roleTable
          matTableExporter
          [dataSource]="listData"
          matSort
          class="mattable"
          #exporter="matTableExporter"
          [hiddenColumns]="[5, 6]"
        >
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Name</mat-header-cell
            >
            <mat-cell *matCellDef="let element"
              >{{ element.name }} {{ element.lastname }}</mat-cell
            >
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 15, 20]"
          [pageSize]="5"
          showFirstLastButtons
        ></mat-paginator>
        <br />
        <mat-card-actions>
          <button
            mat-raised-button
            color="primary"
            class="buttonfloatleft"
            (click)="openDialog('', '', '')"
          >
            Add Special Invitees
          </button>
          <button
            mat-raised-button
            color="primary"
            class="buttonfloatright"
            (click)="closeDialog()"
          >
            Done
          </button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</div>

import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { PollElement } from '@modules/polls/polls.component';
import { AuthService } from '@services/Auth.service';
import { CoreService } from '@services/CoreService';
import { PollsService } from '@services/Polls.service';
import { language } from '@shared/config/language';
import { constants } from 'buffer';

@Component({
  selector: 'app-polls-card',
  templateUrl: './polls-card.component.html',
  styleUrls: ['./polls-card.component.css'],
})
export class PollsCardComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;

  ELEMENT_DATA: PollElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = ['committeename', 'title', 'active_till'];
  listData = new MatTableDataSource<PollElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<PollElement>(true, []);
  total = 0;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<PollElement>;

  @Input() source: string;

  constructor(
    private pollsService: PollsService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private http: HttpClient,
    private coreservice: CoreService,
    private datePipe: DatePipe
  ) {
    AuthService.authenticate_redir('Users', ['READ']);

    this.listData = new MatTableDataSource<PollElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<PollElement>(true, []);

    console.log(language.message('a'));
  }

  ngOnInit(): void {
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllPolls();
    this.table.renderRows();
  }

  getAllPolls() {
    this.pollsService.getPolls('').subscribe(
      (response) => {
        const result = response.body;

        //      (result['data'] as PollElement[]).forEach(function (value) {
        //            value["familyname"] = value["family"]["name"];
        //      });
        this.total = response.body['data']?.length;
        this.listData.data = response.body['data'] as PollElement[];
        this.listData.data = this.listData.data
          .reverse()
          .map((item) => {
            item['committeename'] = item.committee['title'];
            return item;
          })
          .slice(0, 5);

        this.table.renderRows();
      },
      (err) => {
        if (err.error.status != null) {
          //          this.errmessage = err.error.message;
        } else {
          //         this.errmessage = err.error.errors[0].msg;
        }
      }
    );
  }

  stoppro($event) {
    $event.stopPropagation();
  }
  viewMore() {
    this.router.navigate(['polls']);
  }
}

import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormBuilder,
  FormArray,
  NgForm,
} from '@angular/forms';
import { UserService } from '@services/UserService';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '@shared/services/CoreService';
import { UACService } from '@services/UAC.service';
import { constants } from '@shared/config/constants';
import { language } from '@shared/config/language';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { CommonSpaceService } from '@services/CommonSpace.service';
import { CommonSpaceCategoryService } from '@services/CommonSpaceCategory.service';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-addcommonspace',
  templateUrl: './addcommonspace.component.html',
  styleUrls: ['./addcommonspace.component.css'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class AddcommonspaceComponent implements OnInit {
  CATEGORY_LIST: any;
  rolename = '';

  isEdit: Boolean = false;
  form: FormGroup = new FormGroup({
    _id: new FormControl(0),

    spacename: new FormControl('', Validators.required),
    floor: new FormControl('', Validators.required),
    capacity: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    caretaker: new FormControl('', Validators.required),
    contact: new FormControl(undefined, Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    category: new FormControl('', Validators.required),
    remarks: new FormControl(''),
  });

  ADVISOR_LIST: any;
  ROLES_LIST: any;
  CONSTANT = constants;
  resource = language;
  labelText: String = 'Add Space';

  constructor(
    private commonSpaceService: CommonSpaceService,
    private router: Router,
    private uacservice: UACService,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private fb: FormBuilder,
    private commonSpaceCategoryService: CommonSpaceCategoryService
  ) {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.labelText = 'Edit Space';

        this.commonSpaceService
          .getCommonSpace(data.id)
          .subscribe((response) => {
            const result = response.body['data'];

            this.rolename = result.role;

            this.form.patchValue({
              _id: result._id,
              spacename: result.spacename,
              floor: result.floor,
              capacity: result.capacity,
              city: result.city,
              address: result.address,
              caretaker: result.caretaker,
              contact: result.contact,
              email: result.email,
              category: result.category,
              remarks: result.remarks,
            });

            this.isEdit = true;
            console.log(this.form);
          });
      }
    });
  }

  ngOnInit(): void {
    this.getAllCategories();
  }

  getAllCategories() {
    this.commonSpaceCategoryService.getCommonSpaceCategories().subscribe(
      (response) => {
        const result = response.body;
        this.CATEGORY_LIST = response.body['data'];
        console.log('CATEGORY_LIST', this.CATEGORY_LIST);
      },
      (err) => {}
    );
  }

  submit(): void {
    console.log(this.form);
    if (this.form.invalid) {
      return;
    }

    var rawuser = this.form.getRawValue();
    console.log(rawuser.category);

    if (!this.isEdit) {
      this.form.removeControl('_id');
      rawuser = this.form.getRawValue();
    }

    this.commonSpaceService.addCommonSpace(rawuser).subscribe(
      (response) => {
        const result = response.body;
        if (!this.isEdit) {
          this.coreservice.success(this.resource.message('COMMONSPACE_SAVE'));
        } else {
          this.coreservice.success(this.resource.message('COMMONSPACE_SAVE'));
        }
        this.router.navigate(['/commonspace/commonspaces']);
      },
      (err) => {
        console.log(err.error.errors);
        var errormessages = 'Please correct the errors - \n';
        //err.error.errors.forEach(element => errormessages += " - "+element.msg+"\n");

        // err.error.errors.forEach(element =>  this.coreservice.error(element.msg));

        this.coreservice.error(errormessages);
        this.form.addControl('_id', new FormControl(''));
      }
    );
  }
}

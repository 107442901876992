import {Component, OnInit} from '@angular/core';
import {language} from '@shared/config/language';
import {UserService} from '@services/UserService';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {LevelsComponent} from '@modules/achievements/levels/levels.component';

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.css']
})
export class AchievementsComponent implements OnInit {
  resource = language;
  localstoragedata: any;


  constructor(private userservice: UserService,
              private router: Router,

  ) {
  }

  ngOnInit(): void {

  }


}

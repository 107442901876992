import { Component, Input, OnInit } from '@angular/core';
import { constants } from '@shared/config/constants';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent implements OnInit {

  @Input()
  isTemplate: boolean = false;

  @Input()
  currentStep: number = 0;

  steps=[];


  constructor() { }

  ngOnInit(): void {
    console.log("IS TEMPLATE? "+this.isTemplate)
  
    if(this.isTemplate){
      this.steps = constants.committee_template_steps;
    }else{
      this.steps = constants.committee_steps;
      
    }
  }



}

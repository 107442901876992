<div class="content p30"> 

       
 
    <div id="block_container">
        <a  routerLink="/module" id="bloc1" mat-flat-button class="mat-flat-button-transperent" ><span  class="material-icons">keyboard_backspace</span></a> 
          
        <div id="bloc2"><h5>{{resource.message('MODULES')}} / {{labelText}}</h5></div>
    </div>
    
    <form [formGroup]="form" (ngSubmit)="submit()" >
    <mat-card class="">
      <mat-card-header>            
        <mat-card-title> Module </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <hr />
      
          <mat-form-field appearance="outline">
            <input type="hidden" formControlName="_id" >
            <input type="hidden" formControlName="isDel" >
            <input type="hidden" formControlName="updated_by" >
             <mat-label>Module Name</mat-label>
            <input matInput placeholder="Placeholder" formControlName="module">
          </mat-form-field>

          <mat-form-field appearance="outline">
           
            <mat-label>Icon</mat-label>
           <input matInput placeholder="Placeholder" formControlName="icon">
           <mat-hint><em>View free icons at <a href="https://fontawesome.com/icons?d=gallery&p=2" target="_blank">Font Awesome</a></em></mat-hint>
         </mat-form-field>

         <mat-form-field appearance="outline">
           
          <mat-label>Route</mat-label>
         <input matInput placeholder="Placeholder" formControlName="route">
         <mat-hint><em>Verify your router name with Client Side routes, wrong route names will result error.</em></mat-hint>
       </mat-form-field>
 <br /><br />
       <mat-form-field appearance="outline">
           
        <mat-label>Priority</mat-label>
       <input matInput type="number" placeholder="Priority" formControlName="priority">
     </mat-form-field>

      
       <mat-form-field   >
         <mat-select  placeholder="Menu Item"  formControlName="isMenu"   >
            <mat-option *ngFor="let menu of IS_MENU" [value]="menu.key">{{ menu.val }}</mat-option>
         </mat-select>
         <mat-error>This field is mandatory.</mat-error>
        </mat-form-field>


          <br /><br />
          <mat-form-field   >
            <mat-select  placeholder="Group"  formControlName="group"   >
               <mat-option *ngFor="let group of GROUP_DATA" [value]="group+''">{{ group}}</mat-option>
            </mat-select>
            <mat-error>This field is mandatory.</mat-error>
           </mat-form-field>
 
       


      </mat-card-content>

      <mat-card-actions align="end">
        <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="submit" [disabled]="form.invalid">Save</button>
          <button mat-raised-button color="clear" class="button-wider" routerLink="/module" >Cancel</button>
      </mat-card-actions>
      

    </mat-card>
  </form>


  </div>


import {Injectable} from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators} from '@angular/forms';
import { FormsModule } from '@angular/forms';


@Injectable({
    providedIn:'root'
})

export class LoginFormGroup {
    constructor(){}


    form :FormGroup = new FormGroup({
        email:new FormControl(0,Validators.required),
        password:new FormControl(0,[Validators.required,Validators.minLength(5)])
    })

    
    initializeFormGroup()
    {
        this.form.setValue({
            email:'',
            password:''
        })
    }

}

<div class="content p30"> 

       
 
    <div id="block_container">
        <a  routerLink="/reports" id="bloc1" mat-flat-button class="mat-flat-button-transperent" ><span  class="material-icons">keyboard_backspace</span></a> 
          
        <div id="bloc2"><h5>{{resource.message('CATEGORIES')}} / {{labelText}}</h5></div>
    </div>
    
    <form   >
    <mat-card class="">
      
      <mat-card-content>
        
          <div class="row" *ngFor="let level of LEVELS_LIST;  let last = last; let index=index;" style="margin-top: 40px;">
            <div class="col col-md-6" align='right'>
              
              <mat-form-field appearance="outline" >
                <mat-label>Categories</mat-label>
               
                <mat-select  placeholder="Categories" (selectionChange)="fetchChildCategories($event.value,index+1, true)" [value]="(level.selected!=undefined)?level.selected:currentSelected" >
                    <mat-option *ngFor="let category of level.categories" [value]="category._id+''">{{category.title}}</mat-option>
                 </mat-select>
              </mat-form-field>
              <button class="btn btn-outline-primary btn-sm" (click)="createNewSubLevel(index)">Add More</button>
              
            </div> 
            
            
            <div class="col col-md-5 pt-2" align="right" >
                <button class="btn btn-primary btn-sm" (click)="addSubLevel()" [disabled]="LEVELS_LIST.length > 4" *ngIf="last">Add Sub Level</button>
                <button mat-icon-button (click)="createNewSubLevel(index,'edit')"><mat-icon>mode_edit</mat-icon></button>
                <button mat-icon-button  (click)="removeSubLevel(level, index)" *ngIf="last"><mat-icon>delete_outline</mat-icon></button>
             
            </div>
 
          </div>
      
         
       


      </mat-card-content>

      <mat-card-actions align="end">
        <button mat-raised-button color="primary" class="button-wider"  routerLink="/reports">Done</button>
      </mat-card-actions>
      

    </mat-card>
  </form>


  </div>


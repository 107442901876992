<div class="content p30">
  <h5 class="bold-text">{{ resource.message("FAMILYINFO") }}</h5>
  <mat-card class="">
    <mat-card-header>
      <mat-card-title> {{ resource.message("FAMILYINFO") }} </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>
        <label class="formtitle"> Basic Details </label>
        <!--<a  class="editcss" ><i class="fa fa-pencil-alt"></i>Edit</a>-->
      </div>
      <hr />
      <form class="form-inline">
        <div class="form-group" style="width: 100%">
          <!--   <div class="field-spacer  mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored">
                          <mat-label>Upload Image</mat-label>
                          <input name="File1"  placeholder="Choose File to Upload " type="file" (change)="onFileChange($event,'logo')" />
                         <a class="input-preview-btn1" *ngIf="logo!=''"  (click)="previewimg()"> Preview</a>
                         <span class="input-remove-btn"> Remove</span>
                    </div>-->

          <div class="input-block p-0">
            <mat-label>Upload Image </mat-label><br />
            <label
              style="
                margin-top: 8px;
                margin-bottom: 24px;
                display: inline-block;
              "
            >
              <span style="white-space: nowrap" class="upload-btn">
                Choose File
              </span>

              <input
                style="display: none"
                name="File1"
                placeholder="Profile Photo"
                type="file"
                (change)="onChange($event, 'photo')"
            /></label>
            <div style="display: inline-block">
              <div style="display: flex; align-items: center">
                <div
                  style="
                    display: inline-block;
                    margin: 0;
                    width: 70px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  *ngIf="this.photoName !== ''"
                >
                  {{ this.photoName }}
                </div>
                <div
                  class="input-preview-btn"
                  (click)="remove('logo')"
                  *ngIf="logo !== ''"
                >
                  Remove
                </div>
                &#160; &#160;
                <a
                  class="input-preview-btn"
                  [href]="logo"
                  target="_blank"
                  *ngIf="logo !== ''"
                >
                  Preview
              </a>
              </div>
            </div>
          </div>

          <div class="input-block p-0">
            <input
              type="text"
              name="input-text1"
              spellcheck="false"
              [(ngModel)]="familyname"
            />
            <span class="placeholder"> Family Name </span>
          </div>
          <div class="input-block">
            <mat-label>Members</mat-label>
            <input matInput value="{{ membercount }}" disabled="true" />
          </div>
        </div>
      </form>
      <div class="familytree">
        <label class="formtitle"> Family Tree </label>
        <hr />
        <form
          [formGroup]="familytree"
          style="width: 100%; display: flex; justify-content: space-between"
        >
          <div
            class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored"
          >
            <mat-label> Upload Pdf</mat-label><br />
            <label style="margin-top: 8px; margin-bottom: 56px">
              <span style="white-space: nowrap" class="upload-btn">
                Choose File
              </span>

              <input
                style="display: none"
                type="file"
                name="File1"
                (change)="onChange($event, 'pdf')"
                formControlName="file"
                accept="application/pdf"
            /></label>
            <div style="display: inline-block">
              <div style="display: flex; align-items: center">
                <div
                  style="
                    display: inline-block;
                    margin: 0;
                    width: 70px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  *ngIf="this.pdfName !== ''"
                >
                  {{ this.pdfName }}
                </div>
                <div
                  class="input-preview-btn"
                  (click)="previewimg('tree')"
                  *ngIf="familypdf"
                >
                  Preview
                </div>
              </div>
            </div>
          </div>
          <div class="input-block p-0">
            <button
              mat-raised-button
              color="primary"
              class="addbtn"
              class="btndesign"
              (click)="onSaveDetails()"
            >
              Save
            </button>
          </div>
        </form>
        <mat-tab-group class="tabgroup" [selectedIndex]="activePath">
          <mat-tab label="Photos" class="test">
            <form
              [formGroup]="photoform"
              (ngSubmit)="submitPhoto($event)"
              class="formdesign"
            >
              <div class="input-block p-0">
                <label
                  style="
                    margin-top: 8px;
                    margin-bottom: 24px;
                    display: inline-block;
                  "
                >
                  <span style="white-space: nowrap" class="upload-btn">
                    Choose File
                  </span>

                  <input
                    style="display: none"
                    name="File1"
                    placeholder="Profile Photo"
                    type="file"
                    formControlName="file"
                    (change)="onFileChange($event, 'photo');setValidatorForPhoto();"
                /></label>
                <div style="display: inline-block">
                  <div style="display: flex; align-items: center">
                    <div
                      style="
                        display: inline-block;
                        margin: 0;
                        width: 170px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                      *ngIf="this.selFileName !== ''"
                    >
                      {{ this.selFileName }}
                    </div>
                  </div>
                </div>
                <!-- <input type="hidden" formControlName="type" />
                <input
                  style="width: 220px"
                  type="file"
                  name="File1"
                  formControlName="file"
                  (change)="onFileChange($event, 'photo')"
                />
                <span class="placeholder"> Upload Image </span> -->
              </div>
              <mat-form-field appearance="outline" class="matlabel">
                <mat-label>Title</mat-label>
                <input matInput (click)="setValidatorForPhoto()" formControlName="title" />
              </mat-form-field>
              <div class="input-block p-0">
                <button
                  mat-raised-button
                  color="primary"
                  class="addbtn"
                  type="submit"
                  [disabled]="photoform.invalid && !isUpdatingPhoto"
                  class="btndesign"
                >
                  <ng-container *ngIf="isUpdatingPhoto === true; else elseBlock"
                    >Update</ng-container
                  >
                  <ng-template #elseBlock>Add</ng-template>
                </button>
                <ng-container *ngIf="isUpdatingPhoto === true">
                  <button
                    (click)="cancelUpdatingPhoto()"
                    mat-stroked-button
                    color="primary"
                    style="border: 1px solid"
                  >
                    Cancel
                  </button></ng-container
                >
              </div>
            </form>
            <hr />
            <button
              mat-stroked-button
              color="primary"
              style="border: 1px solid"
              (click)="openPhotoDialog('yes', 'open', 'close')"
            >
              {{ photosLength }} Photos added
            </button>
          </mat-tab>
          <mat-tab label="Media">
            <form
              style="align-items: center"
              [formGroup]="form"
              (ngSubmit)="submitMedia()"
              class="formdesign"
            >
              <div class="">
                <input type="hidden" formControlName="img" />
                <input type="hidden" formControlName="description" />
                <input type="hidden" formControlName="type" />
                <div
                  style="
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    width: max(350px, 100%);

                    margin-right: 16px;
                  "
                >
                  <mat-radio-group aria-label="Select an option">
                    <mat-radio-button
                      style="margin-right: 16px"
                      (change)="onMediaRadioBtnChange($event)"
                      value="file1"
                      >File Upload</mat-radio-button
                    >
                    <mat-radio-button
                      (change)="onMediaRadioBtnChange($event)"
                      value="url"
                      >File link</mat-radio-button
                    >
                  </mat-radio-group>
                  <ng-container
                    *ngIf="mediaRadioBtn === 'file1'; else elseBlock1"
                  >
                    <label
                      style="
                        margin-top: 8px;
                        margin-bottom: 24px;
                        display: inline-block;
                      "
                    >
                      <span style="white-space: nowrap" class="upload-btn">
                        Choose File
                      </span>

                      <input
                        style="display: none"
                        name="File1"
                        placeholder="Profile Photo"
                        type="file"
                        formControlName="file"
                        (change)="onFileChange($event, 'media file');setValidatorForMedia();"
                    /></label>
                    <div style="display: inline-block">
                      <div style="display: flex; align-items: center">
                        <div
                          style="
                            display: inline-block;
                            margin: 0;
                            width: 170px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                          *ngIf="this.selFileName !== ''"
                        >
                          {{ this.selFileName }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #elseBlock1>
                    <mat-form-field
                      style="width: 300px"
                      appearance="outline"
                      class="matlabel"
                    >
                      <mat-label>File Url</mat-label>
                      <input
                        formControlName="external_link"
                        (change)="onFileChange($event, 'media link')"
                        matInput
                        pattern="^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$"
                      />
                      <mat-error *ngIf="this.form.get('external_link').errors"
                        >Enter a valid URL</mat-error
                      >
                    </mat-form-field>
                  </ng-template>
                </div>
              </div>
              <mat-form-field appearance="outline" class="matlabel">
                <mat-label>Title</mat-label>
                <input matInput (click)="setValidatorForMedia()" formControlName="title" />
              </mat-form-field>
              <div class="input-block">
                <button
                  mat-raised-button
                  color="primary"
                  class="btn btn-primary login-btn button-wider"
                  type="submit"
                  [disabled]="form.invalid && !isUpdatingMedia"
                >
                  <ng-container *ngIf="isUpdatingMedia === true; else elseBlock"
                    >Update</ng-container
                  >
                  <ng-template #elseBlock>Add</ng-template>
                </button>
                <ng-container *ngIf="isUpdatingMedia === true">
                  <button
                    (click)="cancelUpdatingMedia()"
                    mat-stroked-button
                    color="primary"
                    style="border: 1px solid"
                  >
                    Cancel
                  </button></ng-container
                >
              </div>
            </form>
            <hr />
            <button
              mat-stroked-button
              color="primary"
              style="border: 1px solid"
              (click)="openMediaDialog('yes', 'open', 'close')"
            >
              {{ mediaLength }} links added
            </button>
          </mat-tab>

          <!--MEMBERS START-->
          <mat-tab label="Members">
            <mat-card class="">
              <mat-card-content>
                <form
                  [formGroup]="filterform"
                  (ngSubmit)="onFilterSubmit($event)"
                  class="form-inline"
                >
                  <div class="form-group mr-2">
                    <button
                      mat-stroked-button
                      [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu"
                      class="filtersortborder"
                    >
                      <i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;Filter
                    </button>
                    <mat-menu #menu="matMenu" class="menustyle">
                      <span
                        (click)="$event.stopPropagation()"
                        style="color: #b4b1b1"
                        >Filter By</span
                      >
                      <div
                        (click)="$event.stopPropagation()"
                        class="menuitemstyle"
                      >
                        <p>Role</p>
                        <!-- <mat-checkbox class="mat-menu-item" (change)="filterData($event,'role',item)" (click)="stoppro($event)" *ngFor="let item of ROLES_LIST; index as i; first as isFirst"> {{item.role}}  </mat-checkbox> -->
                        <!-- <mat-checkbox class="mat-menu-item" (change)="filterData($event,'role',item)" (click)="stoppro($event)" *ngFor="let item of ROLES_LIST; index as i; first as isFirst"> {{item.role}}  </mat-checkbox> -->
                        <mat-checkbox
                          class="mat-menu-item"
                          (change)="filterData($event, 'role', item.role)"
                          *ngFor="
                            let item of ROLES_LIST;
                            index as i;
                            first as isFirst
                          "
                        >
                          {{ item.role }}
                        </mat-checkbox>

                        <hr class="hrclass" />
                        <p>Status</p>
                        <mat-checkbox
                          (change)="filterData($event, 'status', 'active')"
                          class="mat-menu-item"
                          >Active</mat-checkbox
                        >
                        <mat-checkbox
                          (change)="filterData($event, 'status', 'inactive')"
                          class="mat-menu-item"
                          >Inactive</mat-checkbox
                        >
                        <mat-checkbox
                          class="mat-menu-item"
                          (change)="filterData($event, 'status', 'suspended')"
                          (click)="stoppro($event)"
                          >Suspended</mat-checkbox
                        >
                        <hr />

                        <button
                          (click)="$event.stopPropagation()"
                          type="submit"
                          mat-raised-button
                          color="primary"
                        >
                          Apply
                        </button>
                      </div>
                    </mat-menu>
                    <!-- <button mat-stroked-button class="filtersortborder"><i class="material-icons">sort</i>&nbsp;&nbsp;Sort By</button>
                              -->

                    <button
                      (click)="convertToPDF('save')"
                      mat-stroked-button
                      class="iconcss"
                    >
                      <i class="fa fa-file-pdf fa-lg"></i>
                    </button>
                    <button
                      (click)="
                        exporter.exportTable('csv', { fileName: 'family-info' })
                      "
                      mat-stroked-button
                      class="iconcss"
                    >
                      <i class="fa fa-file-excel fa-lg"></i>
                    </button>
                    <button
                      (click)="convertToPDF('print')"
                      mat-stroked-button
                      class="iconcss"
                    >
                      <i class="fa fa-print fa-lg"></i>
                    </button>
                  </div>
                  <span class="example-spacer"></span>
                  <div class="mr-1">
                    <!--<button mat-raised-button color="primary" class="addbutton" class="buttonfloatright" (click)="addUser()"><i class="fas fa-plus" ></i>&nbsp;&nbsp;ADD USER</button>-->
                    <input
                      matInput
                      placeholder="Search by Name/Email ID/Mobile NO"
                      autocomplete="off"
                      name="searchKey"
                      class="searchfloatright"
                      (keyup)="applyfilter($event)"
                      (keydown)="preventSubmit($event)"
                    />
                  </div>
                </form>
                <p>
                  <br />
                  <mat-table
                    #userTable
                    userTable
                    matTableExporter
                    [dataSource]="listData"
                    matSort
                    class="mattable"
                    #exporter="matTableExporter"
                    [hiddenColumns]="[4, 5]"
                  >
                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                      <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Profile Name</mat-header-cell
                      >
                      <mat-cell *matCellDef="let element"
                        >{{ element.name }} {{ element.lastname }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="mobile">
                      <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Mobile No.</mat-header-cell
                      >
                      <mat-cell *matCellDef="let element">{{
                        element.mobile
                      }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="email">
                      <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Email ID</mat-header-cell
                      >
                      <mat-cell *matCellDef="let element">{{
                        element.email
                      }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="role">
                      <mat-header-cell *matHeaderCellDef mat-sort-header
                        >Role</mat-header-cell
                      >
                      <mat-cell *matCellDef="let element">{{
                        element.role
                      }}</mat-cell>
                    </ng-container>
                    <!--   <ng-container matColumnDef="Action">
                          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                           <mat-cell *matCellDef="let row">
                            <button mat-icon-button  (click)="edit(row)"  ><mat-icon>mode_edit</mat-icon></button>
                           <button mat-icon-button color="warn"  (click)="onDelete(row._id)"   ><mat-icon>delete_outline</mat-icon></button>
                           </mat-cell>
                        </ng-container>-->

                    <mat-header-row
                      *matHeaderRowDef="displayedColumns"
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="let row; columns: displayedColumns"
                    ></mat-row>
                  </mat-table>
                  <mat-paginator
                    [pageSizeOptions]="[5, 10, 15, 20]"
                    [pageSize]="5"
                    showFirstLastButtons
                  >
                  </mat-paginator>
                </p>
              </mat-card-content>
              <!---  <mat-card-footer>
                              <button class="footerbtn"  mat-raised-button color="primary">Save</button>
                               <button mat-stroked-button class="footerbtn" >Cancel</button>
                              
                           </mat-card-footer>-->
            </mat-card>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-card-content>
  </mat-card>
</div>

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import { CoreService } from '@services/CoreService';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import { UACService } from '@services/UAC.service';
import { AuthService } from '@services/Auth.service';
import { constants } from '@shared/config/constants';
import { IdpService } from '@services/Idp.service';

@Component({
  selector: 'app-idp',
  templateUrl: './idp.component.html',
  styleUrls: ['./idp.component.css'],
})
export class IdpComponent implements OnInit {
  resource = language;
  CORE_FAMILY = constants.CORE_FAMILY_NAME;
  activePath = 0;
  constructor(
    private router: Router,
    private idp: IdpService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    AuthService.authenticate_redir('Users', ['READ']);
  }
}

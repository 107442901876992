<div class="content p30">
  <div id="block_container">
    <a
      (click)="previous()"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >

    <div id="bloc2"><h5>Knowledge Hub / Add File</h5></div>
  </div>

  <form [formGroup]="form" (ngSubmit)="submit()">
    <mat-card class="">
      <mat-card-header>
        <mat-card-title> Add New </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <hr />

        <mat-grid-list cols="2" rowHeight="100px">
          <mat-grid-tile>
            <div
              class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored"
            >
              <input type="hidden" formControlName="_id" />
              <span class="placeholder">
                Choose File to Upload (PDF, PPT, DOC)</span
              >
              <input
                name="File1"
                placeholder="Choose File to Upload (PDF, PPT, DOC)"
                type="file"
                (change)="onFileChange($event)"
                formControlName="file"
              />

              <span
                class="input-preview-btn"
                (click)="previewimg()"
                *ngIf="knowledgePdf !== '' && knowledgePdf !== null"
                >Preview</span
              >
            </div>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Title</mat-label>
              <input
                matInput
                placeholder="File Title"
                formControlName="title"
              />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field
              appearance="outline"
              class="field-spacer"
              *ngIf="source == 'familyadmin'"
            >
              <mat-label>Category</mat-label>
              <mat-select
                placeholder="Category"
                formControlName="category"
                class="editselect"
              >
                <mat-option
                  *ngFor="let category of CATEGORY_LIST"
                  [value]="category._id"
                  >{{ category.category }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>

      <mat-card-actions align="end">
        <button
          mat-raised-button
          color="primary"
          class="btn btn-primary login-btn button-wider"
          type="submit"
          [disabled]="form.invalid"
        >
          Save
        </button>
        <button
          mat-raised-button
          color="clear"
          class="button-wider"
          (click)="previous()"
        >
          Cancel
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>

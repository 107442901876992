<div class="content p30">
  <div id="block_container">
    <a
      routerLink="/security"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >

    <div id="bloc2">
      <h5>
        {{ resource.message("SECURITY") }} /
        {{ resource.message("ACTIVITY_LOG") }}
      </h5>
    </div>
  </div>

  <form>
    <mat-card class="">
      <mat-card-content>
        <mat-grid-list cols="2" rowHeight="100px">
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Family Name</mat-label>
              <input
                matInput
                placeholder="Family Name"
                [disabled]="true"
                value="{{ FAMILY['name'] }}"
              />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Family Admin Name</mat-label>
              <input
                matInput
                placeholder="Family Admin Name"
                [disabled]="true"
                value="{{
                  FAMILY['user']['name'] + ' ' + FAMILY['user']['lastname']
                }}"
              />
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>

        <div style="margin: 0 20px 0 20px">
          <br />
          <h4>Logs</h4>
          <hr />
          <div align="center" *ngIf="ELEMENT_DATA.length == 0">
            {{ resource.message("ACTIVITY_LOG_NOLOGS") }}
          </div>
          <div
            *ngFor="let logdata of ELEMENT_DATA; index as i; first as isFirst"
          >
            <div class="log-1">
              <div class="logs-line">
                <span class="logs-circle">
                  <div class="dotted-line">
                    <span class="small-circle-line"></span>
                    <span class="small-circle-line"></span>
                    <span class="small-circle-line"></span>
                    <span class="small-circle-line"></span>
                    <span class="small-circle-line"></span>
                    <span class="small-circle-line"></span>
                    <span class="small-circle-line"></span>
                  </div>
                </span>
              </div>

              <div class="logs-content">
                <h5>
                  {{ logdata.user.name }} logged in to the FAP at
                  {{ logdata.logdate | date: "hh:mm a" }}
                </h5>
                <p class="logs-p">
                  {{ logdata.logdate | customDate }}, {{ logdata.ipaddress }},
                  {{ logdata.device }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</div>

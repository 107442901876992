<mat-grid-list cols="2" rowHeight="100px">
    <mat-grid-tile>
      <mat-form-field appearance="outline" class="field-spacer">
        <mat-label>Suggested By</mat-label>
        <input
          matInput
          placeholder="Category"
          formControlName="category"
        />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field appearance="outline" class="field-spacer">
        <mat-label>Agenda Title</mat-label>
        <input matInput placeholder="Goal" formControlName="goal" />
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
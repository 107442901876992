import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BusinessInfoService } from '@services/BusinessInfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogbusinessmediaComponent } from '@shared/components/dialogbusinessmedia/dialogbusinessmedia.component';
import { MatDialog } from '@angular/material/dialog';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormBuilder,
  FormArray,
  NgForm,
} from '@angular/forms';
import { CoreService } from '@services/CoreService';
import { language } from '@shared/config/language';
import { FamilyInfoService } from '@services/FamilyInfo.service';
import { AuthService } from '@services/Auth.service';
import { FileService } from '@services/File.service';

import * as _ from 'lodash';

export interface ImageElement {
  image: string;
  title: string;
}

@Component({
  selector: 'app-businessmedia',
  templateUrl: './businessmedia.component.html',
  styleUrls: ['./businessmedia.component.css'],
})
export class BusinessmediaComponent implements OnInit {
  displayedColumns: string[] = ['image', 'title', 'Action'];
  ELEMENT_DATA: ImageElement[];
  listData = new MatTableDataSource<ImageElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<ImageElement>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('MediaTable') table: MatTable<ImageElement>;
  @Input() modulefor: string;
  parentId: string;
  deleteData: [];
  editindex: string = '-1';
  postform: FormGroup = new FormGroup({
    Photos: new FormArray([]),
  });
  resource = language;
  PERMISSION_EDIT = AuthService.authentication('Media', 'READ');
  PERMISSION_ADD = AuthService.authentication('Media', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Media', 'DELETE');
  searchKey: string;

  constructor(
    private fileService: FileService,
    private businessinfoservice: BusinessInfoService,
    private router: Router,
    private dialog: MatDialog,
    private coreservice: CoreService,
    private familyinfoservice: FamilyInfoService,
    private activeRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    AuthService.authenticate_redir('Media', ['READ']);

    console.log('>>>>>>>>>>>>', this.modulefor);
    this.initialLoad();
  }
  initialLoad() {
    if (this.modulefor == 'parent') {
      this.getparentboarddata();
    } else {
      this.getbusinessunitdata();
    }
    this.listData.sort = this.sort;
    this.listData.paginator = this.paginator;
  }

  getbusinessunitdata() {
    this.businessinfoservice.businessunitget('').subscribe(
      (response: any) => {
        //this.parentId = response['data'][0]['_id'];
        const params = this.activeRoute.snapshot.queryParams;
        let data = _.find(response['data'], {_id:params['id']});
        this.parentId = data['_id'];

        this.businessinfoservice
          .getImageMedia(this.parentId)
          .subscribe((res: any) => {
            const imagedata = res.body;
            console.log(imagedata)
            this.listData.data = imagedata.data[2]['data'] as ImageElement[];

            this.table.renderRows();
          });
      },
      (err) => {}
    );
  }
  getparentboarddata() {
    this.businessinfoservice.businessinfoget().subscribe(
      (response: any) => {
        this.parentId = response['data'][0]['info']['_id'];

        this.businessinfoservice
          .getImageMedia(this.parentId)
          .subscribe((res: any) => {
            const imagedata = res.body;
            console.log(imagedata)

            this.listData.data = imagedata.data[2]['data'] as ImageElement[];
            console.log("imagedata.data[2]['data']", imagedata.data[2]['data']);

            console.log('this.listData', this.listData);
            this.table.renderRows();
          });
      },
      (err) => {}
    );
  }

  openDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogbusinessmediaComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        id: this.parentId,
        index: this.editindex,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.editindex = '-1';
      this.initialLoad();
    });
  }

  edit(rowdata) {
    this.editindex = rowdata;
    this.parentId = rowdata._id;
    this.openDialog('', '', '');
  }
  onDelete(row) {
    this.coreservice
      .openConfirmDialog(this.resource.message('BUSINESS_IMAGE_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.familyinfoservice.removeMedia(row._id).subscribe(
            (response) => {
              let temp = row.img ? row.img : row.url;
              let url = temp.split('?');
              console.log(url);
              this.fileService.deleteFile(url[1]).subscribe(
                (res) => {
                  console.log(res);
                },
                (err) => {
                  console.log(err);
                }
              );
              this.coreservice.success(
                this.resource.message('BUSINESS_IMAGE_DELETED')
              );
              this.initialLoad();
            },
            (err) => {}
          );
        }
      });
  }
  viewImg(img) {
    window.open(img.url, '_blank');
  }
  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      console.log(data);
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }
}

<div align="right">
  <div class="labeltext">
    <span>The attendence quorum condition is not met</span>
  </div>
  <div class="row maindiv">
    <div class="buttonrow">
      <button
        mat-raised-button
        color="primary"
        class="btn btn-primary login-btn button-wider"
        type="button"
      >
        Reschedule Meeting
      </button>
      <button
        mat-raised-button
        color="primary"
        class="btn btn-primary login-btn button-wider"
        type="button"
        (click)="schedule()"
      >
        Overide to Continue
      </button>
    </div>
  </div>
</div>

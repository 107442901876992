<mat-card class="">
  <mat-card-header> </mat-card-header>
  <mat-card-content>
    <form class="form-inline">
      <div class="form-group mr-2">
        <button
          mat-stroked-button
          class="iconcss"
          (click)="convertToPDF('save')"
        >
          <i class="fa fa-file-pdf fa-lg"></i>
        </button>
        <button
          mat-stroked-button
          class="iconcss"
          (click)="
            exporter.exportTable('csv', { fileName: 'common-space-bookings' })
          "
        >
          <i class="fa fa-file-excel fa-lg"></i>
        </button>
        <button
          (click)="convertToPDF('print')"
          mat-stroked-button
          class="iconcss"
          printSectionId="print-section"
        >
          <i class="fa fa-print fa-lg"></i>
        </button>
      </div>
      <span class="example-spacer"></span>

      <div class="mr-1">
        <button
          [disabled]="!PERMISSION_ADD"
          mat-raised-button
          color="primary"
          class="addbutton"
          class="buttonfloatright"
          (click)="addbooking()"
        >
          <i class="fas fa-plus"></i>&nbsp;&nbsp;{{
            resource.message("ADDBOOKING") | uppercase
          }}
        </button>
        <input
          matInput
          [(ngModel)]="searchKey"
          placeholder="{{ resource.message('SEARCH_BY_COMMONSPACE') }}"
          autocomplete="off"
          (keydown)="preventSubmit($event)"
          (keyup)="applyfilter()"
          name="searchKey"
          class="searchfloatright"
        />
      </div>
    </form>
    <p>
      <br />
      <mat-table
        #roleTable
        roleTable
        matTableExporter
        [dataSource]="listData"
        matSort
        class="mattable"
        #exporter="matTableExporter"
        [hiddenColumns]="[5]"
        id="print-section"
      >
        <!-- Space Name Column -->
        <ng-container matColumnDef="spacename">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Space Name</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.spacename }}</mat-cell>
        </ng-container>

        <!-- Address 1 Column -->
        <ng-container matColumnDef="user">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Booked By</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.user }}</mat-cell>
        </ng-container>

        <!-- Address 2 Column -->
        <ng-container matColumnDef="ticket">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Ticket Number</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.ticket }}</mat-cell>
        </ng-container>

        <!-- City Column -->
        <ng-container matColumnDef="start_date">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Start Date and Time</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.start_date | date: 'dd/MM/yyyy hh:mm a' :'UTC'
          }}</mat-cell>
        </ng-container>

        <!-- Created on Column -->
        <ng-container matColumnDef="end_date">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >End Date and Time</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.end_date  | date: 'dd/MM/yyyy hh:mm a' :'UTC'
          }}</mat-cell>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="Action">
          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button
              mat-icon-button
              (click)="edit(row)"
              [disabled]="!PERMISSION_EDIT"
            >
              <mat-icon>mode_edit</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 15, 20]"
        [pageSize]="5"
        showFirstLastButtons
      ></mat-paginator>
    </p>
  </mat-card-content>
</mat-card>

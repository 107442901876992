import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators} from '@angular/forms';
import { UACService } from '@services/UAC.service';
import { ActivatedRoute, Router } from '@angular/router';
import {CoreService} from '../../../../shared/services/CoreService';
import { language } from '@shared/config/language';
import { FamiliesService } from '@services/Families.service';
import { constants } from '@shared/config/constants';
import { CategoriesReportsService } from '@services/CategoriesReports.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogaddreportcategoryComponent } from '@shared/components/dialogaddreportcategory/dialogaddreportcategory.component';
import { ThisReceiver } from '@angular/compiler';


export interface CategoryElement {
  _id: number;
  display_message: string;
  type:string;
  title: string;
  created_at: string;
  updated_at: string;
  parent: any;
  catstring: string[];
}

@Component({
  selector: 'app-addreport',
  templateUrl: './addreport.component.html',
  styleUrls: ['./addreport.component.css']
})
export class AddreportComponent implements OnInit {
  
  action = "add";
  showUpload = false;
  LEVELS_LIST:any[] = [];

  resource = language;
  labelText:String='Add Report';

  currentSelected = "";

  MAIN_REPORT:CategoryElement = null; 

  newCategory = {
    "_id":"",
    "type": "doc",
    "title":"",
    "role": ["facilitator", "member", "office_member"],
    "display_message": "",
    "icon": "",
    "no_of_columns": 1,
    "parent":"",
    "url":"",

  }

  edit_id = "";

  constructor(private categoriesReportsService:CategoriesReportsService, private dialog:MatDialog,    private activeRoute: ActivatedRoute,   private router : Router,
    ) {
       this.activeRoute.queryParams.subscribe(data => {
       if(data.id!=undefined)
        {
            this.edit_id = data.id;
            this.currentSelected = data.id;
            this.getReport(data.id);
            this.action = "edit";
            this.newCategory._id = data.id;
            console.log("*************"+this.currentSelected)
        }else{
          this.initiateLevels();
          this.fetchCategories(0);
        }
      });
  }

  getReport(id)
  {

    this.categoriesReportsService.getDocuments(id).subscribe(response=> {      
          this.MAIN_REPORT = (response.body['data'] as CategoryElement[])[0];
          this.MAIN_REPORT.catstring=[];
          this.fetchChild( (response.body['data'] as CategoryElement[])[0], 0);
    })

   }

   fetchChild(item,index){
      if(item.parent != null){
          this.MAIN_REPORT.catstring.unshift(item.parent);        
          this.fetchChild(item.parent, index);
      }else{
        console.log(this.MAIN_REPORT);
         this.initiateLevels();
        this.fetchCategories(0, true);
      }
   }

  initiateLevels(){
    this.LEVELS_LIST.push({
        level:0,
        categories:[],
        selected:""
    })
  }

  fetchCategories(level:number, fetchRecurrsive=false){
    this.categoriesReportsService.getParentCategories().subscribe(response=> {    
        this.LEVELS_LIST[level].categories = [{
          _id:"",
          title:"Select Category"
        }];   
        this.LEVELS_LIST[level].categories.push(...response.body['data'] as any[]);
        this.LEVELS_LIST[level].selected = "";
       
        if(this.edit_id != "") {
            console.log("########## SETTING "+level+":"+this.MAIN_REPORT.catstring[level]["_id"])
            if(this.action != "edit") this.LEVELS_LIST[level].selected = this.edit_id;
            else this.LEVELS_LIST[level].selected = this.MAIN_REPORT.catstring[level]["_id"];
        }

        console.log("RECCURSIVE:"+fetchRecurrsive);
        if(fetchRecurrsive){
        
        
          if(level != 0 && this.action == "add") this.edit_id =  this.LEVELS_LIST[level].categories[1]._id;
          if(this.action == "edit") this.edit_id =  this.MAIN_REPORT.catstring[level]["_id"];
        
          console.log(this.LEVELS_LIST);
          console.log("IN:"+level+":"+this.edit_id)

         this.fetchChildCategories(this.edit_id, level+1, true);
        }
     });

  }

  fetchChildCategories(parentId:string, level:number, fetchRecurrsive=false){
    if(this.action == "add") this.LEVELS_LIST[level-1].selected = parentId;
   console.log("IN FETCH CHILD CATS:");
   console.log(this.LEVELS_LIST);

    this.categoriesReportsService.getChildCategories(parentId).subscribe(response=> {    

     let documentObjects  = (response.body['data'] as any[]).filter((child)=> child.type == "doc");
     if(documentObjects.length > 0){
      this.newCategory.title = documentObjects[0].title;
      this.newCategory.url = documentObjects[0].url;
     } 
      
      response.body['data']  = (response.body['data'] as any[]).filter((child)=> child.type != "doc");
     

        if((response.body['data'] as any[]).length > 0 && this.LEVELS_LIST[level] == undefined){
          if( level < 5)
            this.addSubLevel();
        }

        this.LEVELS_LIST.splice(level+1,this.LEVELS_LIST.length)
        
        
        if(this.LEVELS_LIST[level] != undefined){
          this.LEVELS_LIST[level].categories = [{
            _id:"",
            title:"Select Category"
          }];
          this.LEVELS_LIST[level].categories.push(...response.body['data'] as any[]);  
          
          if(this.action != "edit") this.LEVELS_LIST[level].selected = (this.LEVELS_LIST[level].categories[1] == undefined)?this.LEVELS_LIST[level].categories[1]._id:"";
          else  this.LEVELS_LIST[level].selected = this.MAIN_REPORT.catstring[level]["_id"];
          if(fetchRecurrsive){
            
            if(this.action == "add") this.fetchChildCategories(this.LEVELS_LIST[level].categories[1]._id, level+1, true);
            else this.fetchChildCategories(this.LEVELS_LIST[level].selected, level+1, true);
          }
        }

        if((response.body['data'] as any[]).length == 0){
          this.showUpload = true;
        }else{
          this.showUpload = false;
        }


      });

  }
 

  addSubLevel(){
    if(this.LEVELS_LIST.length < 5){
    this.LEVELS_LIST.push({
      level:this.LEVELS_LIST.length,
      categories:[],
      selected:""
    })
  }
  }
 

  ngOnInit(): void {  }
  

  saveReport(){
    let index = this.LEVELS_LIST.length-1;
    console.log(index)
    
    let selectedCategory = this.LEVELS_LIST[index].categories.find((category)=> category._id == this.LEVELS_LIST[index].selected);
     this.newCategory.parent = selectedCategory._id;



    let categoryObject = {
      "_id": this.newCategory._id,
      "type": this.newCategory.type,
      "title": this.newCategory.title,
      "role": ["facilitator", "member", "office_member"],
      "display_message": this.newCategory.title,
      "icon": "",
      "no_of_columns": 1,
      "parent":this.newCategory.parent,
      "url":this.newCategory.url
    }

    console.log(categoryObject)

    if(categoryObject._id != ""){
      this.categoriesReportsService.updateCategory(categoryObject).subscribe(response=> {    
        console.log(response);
        this.router.navigate(['/reports']);

      });
    }else{
      this.categoriesReportsService.createCategory(categoryObject).subscribe(response=> {    
        console.log(response);
        this.router.navigate(['/reports']);
      });
    }
 
  }
 
}

<div align="right">
  <div class="content-container">
    <mat-card class="cardclass">
      <mat-card-header>
        <mat-card-title> {{ resource.message("BOOKINGS") }} on {{data.dayTitle}}</mat-card-title>
        <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
      </mat-card-header>
      <mat-card-content>
        <mat-table #mediaTable mediaTable matTableExporter [dataSource]="listData" matSort class="mattable"
          #exporter="matTableExporter" [hiddenColumns]="[1, 2, 3, 4, 5]">
          <!-- Name Column -->
          <ng-container matColumnDef="purpose">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.purpose }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="start_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</mat-header-cell>
            <mat-cell *matCellDef="let element">{{
              element.start_date | date:'d/M/yyyy, h:mm a' :'UTC'
              }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="end_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>End Date</mat-header-cell>
            <mat-cell *matCellDef="let element">{{
              element.end_date | date:'d/M/yyyy, h:mm a' :'UTC'
              }}</mat-cell>
          </ng-container>

          <!-- System Column -->

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 15, 20]" [pageSize]="5" showFirstLastButtons></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
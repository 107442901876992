import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';


@Injectable({
    providedIn:'root'
})

export class AdminKnowledgeHubService {

    constructor(private http: HttpClient){


    }

    getKnwoledgeFiles(source){

      var params = '';
      if(source == 'superadmin') params += "?template=1";

        return this.http.get(apiendpoints.ADMINKNOWLEDGEHUB+params,
            {
                headers: new HttpHeaders()
                  .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
                observe: 'response'
              }
            );      
    }

    getKnwoledgeFile(id){


      return this.http.get(apiendpoints.ADMINKNOWLEDGEHUB+"/"+id,
          {
              headers: new HttpHeaders()
                .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
              observe: 'response'
            }
          );      
    }

    addKnowledgFile(data:any){
      console.log("ADDING...")
      console.log(data);
      return this.http.post<any>(apiendpoints.ADMINKNOWLEDGEHUB, {knowledgebase:data},  {
        headers: new HttpHeaders()
          .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
        observe: 'response'
      });      
    }

    removeKnowledgeFile(id){
    
      const options = {
        headers: new HttpHeaders({
          'Authorization': `Token `+localStorage.getItem('ACCESS_TOKEN')
        }),
        body: {
          knowledgebaseId: id,
        },        
      };
      
      return this.http.delete(apiendpoints.ADMINKNOWLEDGEHUB, options);
    }


   


}

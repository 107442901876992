import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommitteeService } from '@services/Committee.service';
import { constants } from '@shared/config/constants';
import { language } from '@shared/config/language';
import { CoreService } from '@services/CoreService';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSliderModule } from '@angular/material/slider';

@Component({
  selector: 'app-committee-s6',
  templateUrl: './committee-s6.component.html',
  styleUrls: ['./committee-s6.component.css'],
})
export class CommitteeS6Component implements OnInit {
  resource = language;
  steps = [];
  isTemplate: boolean = false;

  TYPE_LIST: any;
  CATEGORY_LIST: any;

  COMMITTEE_LIST: any;
  SUBCOMMITTEE_LIST: any;

  isEdit: Boolean = false;

  CURRENT_COMMITTEE = constants.CURRENT_COMMITTEE;

  COMMITEE_ID = '';

  typeControl = new FormControl('number');
  valueControl = new FormControl('0');
  TEMPLATE = 0;

  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    is_consensus: new FormControl(false),
    use_consensus: new FormControl(''),
    is_voting: new FormControl(false),
    voting_rules: new FormControl(''),
    is_hybrid: new FormControl(false),
    hybrid_rules: new FormControl(''),
    resolution_quorum_type: new FormControl(''),
    resolution_quorum_value: new FormControl(''),
  });

  constructor(
    private committeeService: CommitteeService,
    private coreservice: CoreService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  printlog() {
    console.log(this.form.getRawValue());
  }

  get isFormDisabled() {
    return (!this.form.get('is_consensus').value && !this.form.get('is_voting').value
    && !this.form.get('is_hybrid').value
    );
  }


  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.template != undefined) {
        this.TEMPLATE = data.template;
        if (this.TEMPLATE == 1) this.isTemplate = true;
        else this.isTemplate = false;
      }

      if (this.isTemplate) {
        this.steps = constants.committee_template_steps;
      } else {
        this.steps = constants.committee_steps;
      }
      console.log(this.steps);

      if (data.id != undefined) {
        this.form.patchValue({ _id: data.id });
        this.COMMITEE_ID = data.id;
        this.committeeService
          .getCommittee(this.COMMITEE_ID)
          .subscribe((response) => {
            const result = response.body;
            this.CURRENT_COMMITTEE = result['data'];

            this.form.patchValue({
              _id: this.CURRENT_COMMITTEE['_id'],
              is_consensus: this.CURRENT_COMMITTEE['is_consensus'],
              use_consensus: this.CURRENT_COMMITTEE['use_consensus'],
              is_voting: this.CURRENT_COMMITTEE['is_voting'],
              voting_rules: this.CURRENT_COMMITTEE['voting_rules'],
              is_hybrid: this.CURRENT_COMMITTEE['is_hybrid'],
              hybrid_rules:
                this.CURRENT_COMMITTEE['hybrid_rules'] != null
                  ? this.CURRENT_COMMITTEE['hybrid_rules']
                  : '',
              resolution_quorum_type:
                this.CURRENT_COMMITTEE['resolution_quorum'] != null
                  ? this.CURRENT_COMMITTEE['resolution_quorum'][
                      'resolution_quorum_type'
                    ]
                  : '',
              resolution_quorum_value:
                this.CURRENT_COMMITTEE['resolution_quorum'] != null
                  ? this.CURRENT_COMMITTEE['resolution_quorum'][
                      'resolution_quorum_value'
                    ]
                  : '',
            });
            if(this.CURRENT_COMMITTEE['is_voting']) {
            this.valueControl = new FormControl(
              this.form.get('resolution_quorum_value').value
            );
            this.typeControl = new FormControl(
              this.form.get('resolution_quorum_type').value
            );
            }
            if (this.typeControl.value == '' || this.typeControl.value == null)
              this.typeControl = new FormControl('number');
          });
      } else {
        //    this.router.navigate(['committee']);
      }
    });
  }

  submit(type): void {
    console.log(this.form);
    if (this.form.invalid) {
      return;
    }
    if (
      this.form.get('is_voting').value === false &&
      this.form.get('is_hybrid').value === false &&
      this.form.get('is_consensus').value === false
    ) {
      this.coreservice.error('Error! Please select one option to continue');
      return;
    }
    this.form.patchValue({
      resolution_quorum_value: this.valueControl.value,
    });

    var rawcommittee = this.form.getRawValue();

    rawcommittee.resolution_quorum = {
      resolution_quorum_type: rawcommittee['resolution_quorum_type'],
      resolution_quorum_value: rawcommittee['resolution_quorum_value'],
    };

    delete rawcommittee.resolution_quorum_type;
    delete rawcommittee.resolution_quorum_value;

    this.committeeService.addCommittee(rawcommittee).subscribe(
      (response) => {
        const result = response.body;

        constants.CURRENT_COMMITTEE = result['data'];
        if (this.isTemplate) {
          if (type == 'final') {
            this.router.navigate(['committee/add/s7'], {
              queryParams: {
                id: constants.CURRENT_COMMITTEE['_id'],
                template: this.TEMPLATE,
              },
            });
          } else {
            this.coreservice.success('Committee saved as draft');

            this.router.navigate(['/templatemanagement']);
          }
        } else {
          if (type == 'final') {
            this.router.navigate(['committee/add/s7'], {
              queryParams: {
                id: constants.CURRENT_COMMITTEE['_id'],
                template: this.TEMPLATE,
              },
            });
          } else {
            this.coreservice.success('Committee saved as draft');

            this.router.navigate(['/committee']);
          }
        }
      },
      (err) => {
        console.log(err);
        //      var errormessages = "Please correct the errors - \n";
        //      err.error.errors.forEach(element => errormessages += " - "+element.msg+"\n");
        this.coreservice.error('Invalid Committee - ' + err.error.message);
        //  this.form.addControl('_id', new FormControl(''));
      }
    );
  }

  getCommittees(type, committee) {
    console.log(type);

    this.committeeService
      .getCommitteesFilter(type, committee)
      .subscribe((response) => {
        if (type == 'committee') {
          this.COMMITTEE_LIST = response.body['data'];
        } else if (type == 'subcommittee') {
          this.SUBCOMMITTEE_LIST = response.body['data'];
          console.log(this.SUBCOMMITTEE_LIST);
        }
      });
  }

  previous() {
    this.router.navigate(['committee/add/s5'], {
      queryParams: { id: this.COMMITEE_ID, template: this.TEMPLATE },
    });
  }

  formatLabel(value: number) {
    return value;
  }
}

<div class="content p30"> 

       
 
    <div id="block_container">
        <a  routerLink="/declaration" id="bloc1" mat-flat-button class="mat-flat-button-transperent" ><span  class="material-icons">keyboard_backspace</span></a> 
          
        <div id="bloc2"><h5>{{resource.message('DECLARATION')}} / {{resource.message("DECLARATION_ACTION")}}</h5></div>
    </div>
     

    <form [formGroup]="form" (ngSubmit)="submit()" >
    <mat-card class="">
      <mat-card-content>
        <br />
        <h5>Create Declaration</h5>
        <hr />
      
        <mat-grid-list cols="2" rowHeight="80px">
            <mat-grid-tile >
                <mat-form-field appearance="outline" class="field-spacer">
                    <input type="hidden" formControlName="_id" >
                    <mat-select  placeholder="Member Name"  formControlName="user"  >
                      <mat-option *ngFor="let role of ADVISOR_LIST" [value]="role._id+''">{{ role.name}} {{ role.lastname}}</mat-option>
                   </mat-select>
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
              <mat-form-field appearance="outline" class="field-spacer">
                  <mat-select  placeholder="Category"  formControlName="category"  >
                    <mat-option *ngFor="let role of CONSTANT.declaration_category" [value]="role">{{ role}}</mat-option>
                 </mat-select>
                </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile rowspan="2" colspan="2">
            <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Description</mat-label>
                <textarea matInput
                formControlName="purpose"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
              </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile  colspan="2">
          <mat-divider></mat-divider>
        </mat-grid-tile>
   

        <mat-grid-tile colspan ="2">
          <div class="mr-1" > 
            <h5>Add Amount  <mat-slide-toggle (change)="onChange($event)" style="margin-left: 25px;"   formControlName="amount_added"></mat-slide-toggle> </h5>
        </div>
      </mat-grid-tile>

                     

      <mat-grid-tile *ngIf="this.amountadded == true"  >
        <mat-form-field appearance="outline" class="field-spacer">       
          <mat-label>Amount Spent</mat-label>
          <input matInput placeholder="Placeholder" formControlName="amount_spent">
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile  *ngIf="this.amountadded == true"  >
        <mat-form-field appearance="outline" class="field-spacer">       
          <mat-label>Approved Limit</mat-label>
          <input matInput placeholder="Placeholder" formControlName="amount_expected">
           
        </mat-form-field>
      </mat-grid-tile>

      
      <mat-grid-tile   >
        <mat-form-field appearance="outline" class="field-spacer">       
          <mat-label>Reference</mat-label>
          <input matInput placeholder="Placeholder" formControlName="reference">
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile   >
        <mat-form-field appearance="outline" class="field-spacer">       
          <mat-label>Remarks</mat-label>
          <input matInput placeholder="Placeholder" formControlName="remarks">
        </mat-form-field>
      </mat-grid-tile>
          
 

          </mat-grid-list>
          
 
   
       


      </mat-card-content>

      <mat-card-actions align="end">
        <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="submit" [disabled]="form.invalid">Save</button>
          <button mat-raised-button color="clear" class="button-wider"  routerLink="/declaration">Cancel</button>
      </mat-card-actions>
      

    </mat-card>
  </form>


  </div>

import { Component, OnInit, Input } from '@angular/core';
import { CommitteeService } from '@services/Committee.service';
import { CoreService } from '@services/CoreService';
import { IdpService } from '@services/Idp.service';
import { language } from '@shared/config/language';

@Component({
  selector: 'app-commiteemeetingoutcome',
  templateUrl: './commiteemeetingoutcome.component.html',
  styleUrls: ['./commiteemeetingoutcome.component.css'],
})
export class CommiteemeetingoutcomeComponent implements OnInit {
  outCome: string = '';
  @Input() parentid: String;
  @Input() agendaid: String;
  @Input() viewdata: Boolean;
  comments: any;
  constructor(private idp: IdpService, private committeeService: CommitteeService, private coreservice: CoreService) {}

  ngOnInit(): void {
    console.log(this.viewdata);
    if (this.viewdata === true) {
      this.getMeetings(this.parentid);
    }
  }

  doneClick() {
    if(!this.outCome.trim()) {
      return;
    }
    this.idp
      .idpcommentpost(this.parentid, this.agendaid, this.outCome)
      .subscribe(
        (response: any) => {
          let outcomeData = response.data.agendas.find(
            (outcome) => outcome._id === this.agendaid
          );
          this.comments=outcomeData.comments.filter(data => data.comment)
          this.outCome = "";
          this.coreservice.success(language.message('SAVED_SUCCCESSFULLY'));
        },
        (err) => {
          console.log('errrrrrrr', err);
        }
      );
      
  }
  getMeetings(id) {
    this.committeeService.getCommitteeMeetings("/"+this.parentid)
    .subscribe(
      (response: any) => {
        let outcomeData = response.data.agendas.find(
          (outcome) => outcome._id === this.agendaid
        );
        this.comments=outcomeData.comments.filter(data => data.comment)
        
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { language } from '@shared/config/language';
import { ActivatedRoute, Router } from '@angular/router';
import { IdpService } from '@services/Idp.service';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormBuilder,
  FormArray,
  NgForm,
} from '@angular/forms';
import { FileService } from '@services/File.service';
import { DialogviewattendanceComponent } from '@shared/components/dialogviewattendance/dialogviewattendance.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CoreService } from '@services/CoreService';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DialoginviteesComponent } from '@shared/components/dialoginvitees/dialoginvitees.component';
import { CommitteeService } from '@services/Committee.service';

@Component({
  selector: 'app-minuteofmeeting',
  templateUrl: './minuteofmeeting.component.html',
  styleUrls: ['./minuteofmeeting.component.css'],
})
export class MinuteofmeetingComponent implements OnInit {
  resource = language;
  topics: string[] = [];
  parentid: string;
  start_date: string;
  end_date: string;
  venue: string;
  showtab: boolean = false;
  agendaid: string;
  viewdata: boolean = true;
  showView: Number;
  currentfile = {};
  currentfilename = '';
  selectedfile: any;
  createdfor: string;
  isEdit: boolean = false;
  fileId: string;
  url: string = '';
  title: string = '';
  start_time: string;
  end_time: string;
  meetingData: any = {};
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private idp: IdpService,
    private fileService: FileService,
    private dialog: MatDialog,
    private coreservice: CoreService,
    private committeeService: CommitteeService
  ) {}
  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    file: new FormControl(''),
    filename: new FormControl(''),
    title: new FormControl(''),
  });
  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentid = data.id;
      }
    });
    this.getMeetings(this.parentid);
  }
  getMeetings(id) {
    var filter = '';
    filter = '?filter[_id]=' + id; //+this.localstoragedata._id
    this.idp.idpMeetingGet(filter).subscribe(
      (response: any) => {
        console.log(response.data[0]);
        this.meetingData = {...response.data[0]};
        this.start_date = response.data[0].start_date;
        this.end_date = response.data[0].end_date;
        this.start_time = response.data[0].start_time;
        this.end_time = response.data[0].end_time;
        this.venue = response.data[0].venue;
        this.title = response.data[0].title;
        this.createdfor = response.data[0].created_for._id;
        if (response.data[0].mom_comments.length !== 0) {
          console.log(response.data[0].mom_comments);
          this.isEdit = true;
          this.fileId = response.data[0].mom_comments[0]._id;
          this.form.patchValue({
            title: response.data[0].mom_comments[0].title,
            file_url: response.data[0].mom_comments[0].file_url,
          });
          this.url = response.data[0].mom_comments[0].file_url;
        }
        for (let i = 0; i < response.data[0].agendas.length; i++) {
          this.topics.push(response.data[0].agendas[i]);
        }
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  onFileChange(event) {
    const latestfile = (event.target as HTMLInputElement).files[0];
    this.currentfile = latestfile;
    this.currentfilename = latestfile.name;
    this.selectedfile = latestfile;
    var filename = latestfile.name;

    this.form.patchValue({
      filename: this.currentfilename,
    });

    this.form.patchValue({
      file: this.currentfile,
    });

    this.form.get('file').updateValueAndValidity();
  }
  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    var rawuser = this.form.getRawValue();
    console.log(this.form.get('file').value);
    if (
      this.form.get('file').value !== undefined &&
      this.form.get('file').value !== ''
    ) {
      let fileurl = this.form.get('file').value;
      var formData: any = new FormData();
      formData.append('file', this.form.get('file').value);
      fileurl = await this.fileService.uploadFile(formData, true, false);
      rawuser.file_url = fileurl;
    }

    delete rawuser['file'];
    let finaldata;
    if (this.isEdit === false) {
      finaldata = {
        file_url: rawuser.file_url,
        title: rawuser['title'],
      };
    } else {
      finaldata = {
        file_url: rawuser.file_url,
        title: rawuser['title'],
        _id: this.fileId,
      };
    }
    finaldata['meeting_status'] = 'mom_updated';
    this.idp.idpmompost(finaldata, this.parentid).subscribe(
      (response) => {
        this.coreservice.success(this.resource.message('MOM_PUBLISHED'));

        //  const result = response.body;
        this.router.navigate(['idpdetails'], {
          queryParams: { id: this.createdfor },
        });
      },
      (err) => {}
    );
  }
  outcome_tab(topic, index) {
    console.log(index);
    this.showView = index;
    this.agendaid = topic._id;
  }
  redirectTo() {
    this.router.navigate(['/idpdetails']);
  }
  openparticipants(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialoginviteesComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        id: this.parentid
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  previewImg() {
    //alert(this.url);
    window.open(this.url, '_blank');
  }

  generatePdf() {
    const doc = new jsPDF();
 
    doc.setFont("", "bold");
    doc.setFontSize(11)
    console.log(this.meetingData)
    doc.text("MEETING NAME: "+ this.meetingData.title,10,10);
    let date = this.meetingData.start_date.split('T')[0] ? this.meetingData.start_date.split('T')[0] : '-'
    doc.text("VENUE: "+ this.meetingData.venue,10,20)
     doc.text("DATE: "+ date,10,30);


    let invitees = this.meetingData.members.map((o)=>o.name)
    let invitees_text = invitees.join(', ');
    doc.text("INVITEES: "+invitees_text,10,40,{maxWidth: 210})

    let tmp_invitees = this.meetingData.temporary_members.members.map((o)=>o.name)
    let text_tmp_invitees = tmp_invitees.join(', ');
    doc.text("TEMPORARY INVITEES: "+text_tmp_invitees,10,50)
    doc.line(10, 56, 200, 56); // horizontal line.
    doc.setLineWidth(0.5);

    doc.text("MINUTES OF MEETING",90,65)
    doc.line(88, 70, 138, 70); // horizontal line.
    doc.setLineWidth(0.5);

    

    //let agenda_text = '' 
    let height = 80;
    this.meetingData.agendas.forEach((agenda,index)=> {

      doc.text(`AGENDA ${index+1}: `,10,height)
      doc.text(`TITLE: ${agenda.idp.category}`,10,height+10)
      doc.text(`DESCRIPTION: ${agenda.idp.description}`,80,height+10)
      // autoTable(doc, {
      //   margin: { top: 60 },
      //   head: [['TITLE', 'DESCRIPTION']],
      //   body: [
      //     [agenda.idp.Category, agenda.idp.description]
      //   ],
      // })

      const outcomes = agenda.comments.map((val, index) => [index+1, val.comment]);
      if(outcomes.length) {
        autoTable(doc, {
          startY: height+20,
          head: [['','OUTCOMES']],
          body: outcomes,
        })
      }

      const todos = agenda.tasks.map((val, index) => [index+1, val.title]);
      if(todos.length) {
        autoTable(doc, {
          startY: height+20+(outcomes.length+1)*10,
          head: [['', 'ASSIGNED TASKS']],
          body: todos
        })
      }
      height = height  + (outcomes.length + todos.length + 6) * 10 ;
    });

    doc.line(10, height-20, 200, height-20); // horizontal line.
    doc.setLineWidth(0.5);

    // doc.text(agenda_text,10,60,{maxWidth: 220})
    // doc.text("AGENDA 1: "+'TITLE',10,60)

    // doc.text("DESCRIPTION: "+"This is a description",10,70)

    // let start_date = this.meetingData.start_date.split('T')[0] ? this.meetingData.start_date.split('T')[0] : '-'
    // doc.text("START DATE : "+start_date,10,280)

    // doc.text("START TIME : "+this.meetingData.start_time,80,280)

    // let end_date = this.meetingData.end_date.split('T')[0] ? this.meetingData.end_date.split('T')[0] : '-'
    // doc.text("END DATE : "+end_date,10,290)
    // doc.text("END TIME : "+this.meetingData.end_time,80,290)

    doc.save('MEETING-MINUTES.pdf');
  }
}

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { UserService } from '@services/UserService';
import { CoreService } from '@services/CoreService';
import { MatMenuTrigger } from '@angular/material/menu';
import { language } from '@shared/config/language';
import { AuthService } from '@services/Auth.service';
import { constants } from '@shared/config/constants';
import { FamilyOfficeService } from '@services/FamilyOffice.service';
export interface UserElement {
  createdOn: number;
  ticket: string;
  description: string;
  status: string;
  specialist: string;
}

// REMOVE THIS COMMENTS

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css'],
})
export class TicketComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  showFiller = false;
  ELEMENT_DATA: UserElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = [
    'created_at',
    'ticket_no',
    'specialist',
    'description',
    'request_status',
    'Action',
  ];
  listData = new MatTableDataSource<UserElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<UserElement>(true, []);
  PERMISSION_EDIT = AuthService.authentication('Family Office', 'READ');
  PERMISSION_ADD = AuthService.authentication('Family Office', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Family Office', 'DELETE');
  localstoragedata: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<UserElement>;

  resource = language;
  constructor(
    private userservice: UserService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private coreservice: CoreService,
    private familyoffice: FamilyOfficeService
  ) {
    this.listData = new MatTableDataSource<UserElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<UserElement>(true, []);
  }

  ngOnInit(): void {
    AuthService.authenticate_redir('Family Office', ['READ']);

    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    console.log('LOGGED ROLE', this.localstoragedata.role);
    if (this.localstoragedata.role == 'admin') {
      this.getAllTicket();
    } else {
      this.getTicket();
    }

    this.table.renderRows();
  }

  getTicket() {
    this.familyoffice.assignedticket().subscribe(
      (response: any) => {
        console.log('Ticket Date', response.data);
        //const result = response.body;
        this.listData.data = response.data.reverse() as UserElement[];
        this.table.renderRows();
      },
      (err) => {
        console.log('ERROR', err);
      }
    );
  }
  getAllTicket() {
    this.familyoffice.allticketget().subscribe(
      (response: any) => {
        console.log('Ticket Date', response.data);
        //const result = response.body;
        this.listData.data = response.data.reverse() as UserElement[];
        this.table.renderRows();
      },
      (err) => {
        console.log('ERROR', err);
      }
    );
  }
  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listData.data.forEach((row) => this.selection.select(row));
  }

  addrole() {
    this.router.navigate(['/user/add']);
  }

  edit(data) {
    console.log('*************************');
    this.router.navigate(['tickets/s1'], { queryParams: { id: data._id } });
  }

  filterData(ev, col, val) {
    console.log(ev.checked);
    if (ev.checked) {
      col = String(col);

      let value = { [col]: val };
      this.filterValues.push(value);
    } else {
      let value = { [col]: val };
      const index = this.filterValues.indexOf(value);
      this.filterValues.splice(index - 1, 1);
    }

    this.setupFilter(col);
    this.applyFilter(val);
  }

  stoppro($event) {
    $event.stopPropagation();
  }

  setupFilter(column: string) {
    console.log(column + ':');
    this.listData.filterPredicate = (d: any, filter: string) => {
      console.log('IN1');
      const val = JSON.parse(filter);
      console.log('**');
      console.log(val);
      var flag = false;

      val.forEach(function (item) {
        let obj = item;
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>', obj[column]);
        //const textToSearch = d[column][column] && d[column][column].toLowerCase() || '';
        console.log(d);
        const textToSearch = d[column].toLowerCase() || '';
        if (obj[column] != undefined) {
          if (textToSearch.toLowerCase() == obj[column].toLowerCase()) {
            flag = true;
          }
        }
      });

      if (val.length == 0) flag = true;

      return flag;
    };
  }

  applyFilter(filterValue: string) {
    this.listData.filter = JSON.stringify(this.filterValues); // filterValue.trim().toLowerCase();
  }

  getCol(matrix, col) {
    var column = [];
    for (var i = 0; i < matrix.length; i++) {
      column.push(matrix[i][col]['role']);
    }
    return column; // return column data..
  }

  filterValues = [];

  getFamilyUsers() {
    this.userservice.getFamilyUses().subscribe(
      (response) => {
        const result = response.body;
        this.listData.data = response.body['data'] as UserElement[];
        this.table.renderRows();
      },
      (err) => {
        if (err.error.status != null) {
          //          this.errmessage = err.error.message;
        } else {
          //         this.errmessage = err.error.errors[0].msg;
        }
      }
    );
  }
}

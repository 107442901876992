<div class="content p30">
  <div id="block_container">
    <a
      routerLink="/personaltask"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >

    <div id="bloc2">
      <h5>
        {{ resource.message("PERSONAL_TASK") }} /
        {{ resource.message("PERSONAL_TASKS_ACTION") }}
      </h5>
    </div>
  </div>

  <form [formGroup]="form" (ngSubmit)="submit()">
    <mat-card class="">
      <mat-card-content>
        <br />
        <h5>Create Task</h5>
        <hr />

        <mat-grid-list cols="2" rowHeight="130px">
          
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <input type="hidden" formControlName="_id" />
              <mat-select placeholder="User" formControlName="user">
                <mat-option
                  *ngFor="let role of ADVISOR_LIST"
                  [value]="role._id + ''"
                  >{{ role.name }} {{ role.lastname }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Task Description</mat-label>
              <textarea
                matInput
                formControlName="title"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
              ></textarea>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>

        <br />

        <div class="form-group mr-2">
          <h5>Date and Time</h5>
        </div>
        <span class="example-spacer"></span>
        <div class="mr-1" align="right">
          <h5>
            All Day Event
            <mat-slide-toggle
              (change)="onChange($event)"
              style="margin-left: 25px"
              formControlName="is_full_day"
            ></mat-slide-toggle>
          </h5>
        </div>

        <br />
        <br />

        <hr />

        <mat-grid-list cols="2" rowHeight="80px">
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Start Date and Time</mat-label>
              <input [min]="minDate" matInput type="datetime-local" disabled formControlName="start_date"
              placeholder="Start Date and Time" (change)="onDateRangeChanged('start')"  />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>End Date and Time</mat-label>
            <input matInput type="datetime-local" disabled formControlName="end_date" (change)="onDateRangeChanged('end')" placeholder="End Date and Time"
              [min]="form.get('start_date').value"  />
            </mat-form-field>
          </mat-grid-tile>
          <!-- <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Start Date</mat-label>
              <input
                matInput
                [matDatepicker]="dp1"
                [min]="today"
                disabled
                formControlName="start_date"
                placeholder="Start Date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dp1"
              ></mat-datepicker-toggle>
              <mat-datepicker #dp1 disabled="false"></mat-datepicker>
              <mat-error>This field is mandatory.</mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Start Time </mat-label>
              <input
                [readonly]="true"
                matInput
                formControlName="start_time"
                [ngxMatTimepicker]="time"
                disabled
                placeholder="End time"
              />

              <ngx-mat-timepicker
                class="time"
                #time
                disabled="false"
              ></ngx-mat-timepicker>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>End Date and Time</mat-label>
              <input
                matInput
                [matDatepicker]="dp3"
                [min]="this.form.get('start_date').value"
                disabled
                formControlName="end_date"
                placeholder="End Date and Time"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dp3"
              ></mat-datepicker-toggle>
              <mat-datepicker #dp3 disabled="false"></mat-datepicker>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>End time </mat-label>
              <input
                [readonly]="true"
                matInput
                formControlName="end_time"
                [ngxMatTimepicker]="time1"
                disabled
                placeholder="End time"
              />

              <ngx-mat-timepicker
                class="time"
                #time1
                disabled="false"
              ></ngx-mat-timepicker>
            </mat-form-field>
          </mat-grid-tile> -->
        </mat-grid-list>

        <br />
        <div class="form-group mr-2">
          <h5>Priority</h5>
        </div>
        <span class="example-spacer"></span>
        <div class="mr-1">
          <h5>Status</h5>
        </div>

        <br />
        <br />

        <mat-grid-list cols="2" rowHeight="80px">
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-select placeholder="Prioity" formControlName="priority">
                <mat-option
                  *ngFor="let priority of CONSTANT.task_priority"
                  [value]="priority"
                  >{{ priority }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-select placeholder="Status" formControlName="status">
                <mat-option
                  *ngFor="let status of CONSTANT.task_status"
                  [value]="status"
                  >{{ status }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>

      <mat-card-actions align="end">
        <button
          mat-raised-button
          color="primary"
          class="btn btn-primary login-btn button-wider"
          type="submit"
          [disabled]="form.invalid"
        >
          Save
        </button>
        <button
          mat-raised-button
          color="clear"
          class="button-wider"
          (click)="clearAll()"
          *ngIf="!isEdit"
        >
          Clear All
        </button>

        <button
          mat-raised-button
          color="clear"
          class="button-wider"
          routerLink="/personaltask"
        >
          Cancel
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>

<div class="content p30">
  <div id="block_container">
    <ng-container [ngSwitch]="TEMPLATE">
      <a
        *ngSwitchCase="'1'"
        routerLink="/templatemanagement"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
      <a
        *ngSwitchCase="'0'"
        routerLink="/committee"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
    </ng-container>

    <div id="bloc2"><h5>Committee / Add</h5></div>
  </div>
  <form [formGroup]="form" (ngSubmit)="submit('final')">
    <mat-card class="">
      <mat-card-content>
        <app-stepper [isTemplate]="isTemplate" [currentStep]="0"></app-stepper>

        <br />
        <h4 class="steph4">{{ steps[0] }}</h4>

        <mat-grid-list cols="2" rowHeight="80px">
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <input type="hidden" formControlName="_id" />
              <mat-label>Committee Type</mat-label>
              <mat-select
                placeholder="Committee Type"
                formControlName="type"
                (selectionChange)="changeType($event.value, true)"
              >
                <mat-option
                  *ngFor="let type of TYPE_LIST | keyvalue"
                  [value]="type.key"
                  >{{ type.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Title</mat-label>
              <input matInput placeholder="Title" formControlName="title" />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile
            *ngIf="
              form.get('type').value == 'subcommittee' ||
              form.get('type').value == 'subsubcommittee'
            "
          >
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Committee</mat-label>
              <mat-select
                placeholder="Committee"
                formControlName="committee"
                (selectionChange)="getCommittees('subcommittee', $event.value)"
                required
              >
                <mat-option
                  *ngFor="let type of COMMITTEE_LIST"
                  [value]="type._id"
                  >{{ type.title }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile rowspan="3">
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Purpose</mat-label>
              <textarea
                matInput
                rows="10"
                placeholder="Purpose"
                formControlName="purpose"
              ></textarea>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile *ngIf="form.get('type').value == 'subsubcommittee'">
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Sub-Committee</mat-label>

              <mat-select
                placeholder="Sub Committee"
                formControlName="subcommittee"
              >
                <mat-option
                  *ngFor="let type of SUBCOMMITTEE_LIST"
                  [value]="type._id"
                  >{{ type.title }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <!-- <div
              *ngIf="SUBCOMMITTEE_LIST?.length === 0"
              style="width: 100%; height: 62px"
              class="alert alert-danger"
              role="alert"
              align="center"
            >
              No Sub-Committee found!
            </div> -->
          </mat-grid-tile>

          <mat-grid-tile [ngClass]="{disable: form.get('type').value!=='committee'}">
            <mat-form-field appearance="outline" class="field-spacer">
              <!-- *ngIf="form.get('type').value == 'committee'"-->
              <mat-label>Category </mat-label>
              <mat-select placeholder="Category" formControlName="categories">
                <mat-option
                  *ngFor="let type of CATEGORY_LIST"
                  [value]="type._id"
                  >{{ type.title }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>

      <mat-card-actions>
        <div style="display: flex" class="form-group mr-2">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn wide"
            type="button"
            [disabled]="form.invalid"
            (click)="submit('draft')"
          >
            Save As Draft</button
          ><button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn wide mr-2"
            type="button"
            (click)="clearForm()"
          >
            Clear all
          </button>
        </div>

        <div class="rightpanel" align="right">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn wide"
            type="submit"
            [disabled]="form.invalid"
          >
            Next
          </button>
          <button
            mat-raised-button
            color="clear"
            class="wide"
            [routerLink]="isTemplate ? '/templatemanagement' : '/committee'"
          >
            Cancel
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </form>
</div>

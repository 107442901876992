import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { DeclarationService } from '@services/Declaration.service.';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import { CoreService } from '@services/CoreService';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import { AuthService } from '@services/Auth.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { constants } from '@shared/config/constants';

export interface DeclarationElement {
  _id: number;
  user: any;
  category: any;
  amount_added: boolean;
  status: string;
  amount_expected: string;
  amount_spent: string;
  purpose: string;
  reference: string;
  remarks: string;
  created_at: string;
}

@Component({
  selector: 'app-declaration',
  templateUrl: './declaration.component.html',
  styleUrls: ['./declaration.component.css'],
  providers: [DatePipe],
})
export class DeclarationComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;
  filterValues = { category: [] };
  filterColumns = ['category'];
  CONSTANT = constants;
  ELEMENT_DATA: DeclarationElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = ['fullname', 'created_at', 'category', 'Action'];
  listData = new MatTableDataSource<DeclarationElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<DeclarationElement>(true, []);

  PERMISSION_EDIT = AuthService.authentication('Declaration', 'READ');
  PERMISSION_ADD = AuthService.authentication('Declaration', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Declaration', 'DELETE');

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<DeclarationElement>;

  resource = language;

  constructor(
    private declarationService: DeclarationService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private http: HttpClient,
    private coreservice: CoreService,
    private datePipe: DatePipe
  ) {
    AuthService.authenticate_redir('Declaration', ['READ']);

    this.listData = new MatTableDataSource<DeclarationElement>(
      this.ELEMENT_DATA
    );
    this.selection = new SelectionModel<DeclarationElement>(true, []);

    console.log(language.message('a'));
  }

  ngOnInit(): void {
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllTasks();
    this.table.renderRows();
  }

  getAllTasks() {
    this.declarationService.getDeclarations().subscribe(
      (response) => {
        const result = response.body;

        var declarations = response.body['data'] as DeclarationElement[];

        declarations.forEach(function (value) {
          let lastname = value.user.lastname != null ? value.user.lastname : '';

          if (value.user != null)
            value['fullname'] = value.user.name + ' ' + lastname;
        });

        this.listData.data = response.body['data'] as DeclarationElement[];
        this.table.renderRows();
      },
      (err) => {
        if (err.error.status != null) {
          //          this.errmessage = err.error.message;
        } else {
          //         this.errmessage = err.error.errors[0].msg;
        }
      }
    );
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listData.data.forEach((row) => this.selection.select(row));
  }

  addrole() {
    this.router.navigate(['/declaration/add']);
  }

  edit(role) {
    this.router.navigate(['declaration/add'], {
      queryParams: { id: role._id },
    });
  }

  onDelete(id) {
    this.coreservice
      .openConfirmDialog(this.resource.message('DECLARATION_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.declarationService.removeDeclaration(id).subscribe(
            (response) => {
              const result = response;
              this.getAllTasks();
              this.coreservice.success(
                this.resource.message('DECLARATION_DELETED')
              );
            },
            (err) => {}
          );
        }
      });
  }

  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [['Profile Name', 'Created On', 'Category']];
    this.listData.data.forEach((obj) => {
      let profile_name = obj['user'] != null ? obj['fullname'] : '';
      let created_on = obj['created_at']
        ? this.datePipe.transform(obj['created_at'], 'M/dd/YYYY')
        : '';
      let category = obj['category'] ? obj['category'] : '';

      let arr = [profile_name, created_on, category];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      rowPageBreak: 'avoid',
    });

    if (action === 'save') {
      doc.save('declarations.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      doc.output('dataurlnewwindow', { filename: 'declarations.pdf' });
    }
  }

  filterData(ev, col, val) {
    if (ev.checked) {
      col = String(col);

      // let value = { [col]: val };
      // this.filterValues.push(value);
      if (col === 'category') {
        this.filterValues = {
          category: [...this.filterValues.category, val],
        };
      }
    } else {
      const index = this.filterValues[col].indexOf(val);
      this.filterValues[col].splice(index, 1);
    }

    console.log('filterValues', this.filterValues);

    this.setupFilter();

    this.applyFilter(val);
  }

  stoppro($event) {
    $event.stopPropagation();
  }

  setupFilter() {
    this.listData.filterPredicate = (d: any, filter: string) => {
      const filterVal = JSON.parse(filter);
      console.log('filter', filter);
      console.log(filterVal);
      var flag1 = false;

      if (filterVal.category.length === 0) {
        flag1 = true;
      } else if (filterVal.category.includes(d?.category)) {
        flag1 = true;
      }

      return flag1;
    };
  }

  applyFilter(filterValue: string) {
    console.log(JSON.stringify(this.filterValues));
    this.listData.filter = JSON.stringify(this.filterValues); // filterValue.trim().toLowerCase();
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { language } from '@shared/config/language';

@Component({
  selector: 'app-outcometab',
  templateUrl: './outcometab.component.html',
  styleUrls: ['./outcometab.component.css'],
})
export class OutcometabComponent implements OnInit {
  resource = language;
  @Input() parentid: String;
  @Input() agendaid: String;
  @Input() viewdata: Boolean;

  constructor() {}

  ngOnInit(): void {
    console.log(this.parentid, this.agendaid);
  }
}

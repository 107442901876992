import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators,FormArray,FormBuilder} from '@angular/forms';
import { UACService } from '@services/UAC.service';
import { ActivatedRoute, Router } from '@angular/router';
import {CoreService} from '@services/CoreService';
import { language } from '@shared/config/language';
import { constants } from '@shared/config/constants';
import { FamilyOfficeService } from '@services/FamilyOffice.service';
import { DatePipe } from '@angular/common'
import { FileService } from '@services/File.service';
import { UserService } from '@services/UserService';

export interface Option {
  votes:string,
  total_percentage : string,
  option: string
}
@Component({
  selector: 'app-thirdsection',
  templateUrl: './thirdsection.component.html',
  styleUrls: ['./thirdsection.component.css']
})
export class ThirdsectionComponent implements OnInit {
  notice =new FormControl('internal')
  isEdit:Boolean = false;
  disableText:boolean=true;
  form :FormGroup = new FormGroup({
    _id:new FormControl(0),
    specialist:new FormControl('',Validators.required),
    assign_userid:new FormControl('',Validators.required),
    assign_external_name:new FormControl(''),
    assign_external_email:new FormControl(''),
    assign_external_mobile:new FormControl(''),
    subassign_userid:new FormControl('')
  })

  postform:FormGroup= new FormGroup({
   
  })
  resource = language;
 editId:string;
 ticketno:string;
 localstoragedata:any;
 MEMBER_LIST:any;
 nonadmin:boolean=false;
  constructor(private uacservice: UACService, private router : Router,
    private activeRoute: ActivatedRoute,
   private familyoffice : FamilyOfficeService,
   public datepipe: DatePipe,
   private fileService: FileService,
   private userservice: UserService,
   private coreservice:CoreService,

   
    ) {
      this.localstoragedata=JSON.parse(localStorage.getItem('LOGGED_USER'));
      
     
    this.activeRoute.queryParams.subscribe(data => {

      if(data.id!=undefined)
      {
        this.editId=data.id;
        this.familyoffice.ticketget(data.id).subscribe((response:any)=> {
          const result = response.data[0];
          console.log("RECEIVED EDIT DATA",result.response)
          this.ticketno=result.ticket_no
          this.form.patchValue({
              _id:result._id,
              assign_userid:(result.assign_userid!= null)?result.assign_userid:'',
              subassign_userid:(result.subassign_userid!= null)?result.subassign_userid:'',
              assign_external_name:(result.assign_external_name!= null)?result.assign_external_name:'',
              assign_external_email:(result.assign_external_email!= null)?result.assign_external_email:'',
              assign_external_mobile:(result.assign_external_mobile!= null)?result.assign_external_mobile:'',
              specialist:(result.specialist!= null)?result.specialist:'',
            }) 
            this.notice = new FormControl(this.form.get("specialist").value);
            if(this.localstoragedata.role=='admin')
            {
             this.nonadmin=false;
            }
             else
            {
              this.nonadmin=true;
            }
         })
         

        } 
    })

   }

  ngOnInit(): void {
    this.getAllUsers();
    }

  
  previousPage()
  {
    this.router.navigate(['tickets/s2'],{queryParams: {id: this.editId}});

  }
  getAllUsers()
  {
    this.localstoragedata=JSON.parse(localStorage.getItem('LOGGED_USER'));

    var filter="?filter[family]="+this.localstoragedata.family._id
    this.userservice.getUsers(filter).subscribe(response=> {
      
      const result = response.body;
      this.MEMBER_LIST=response.body['results']
     }, (err) => {

        if(err.error.status != null){
        }else{
        }
   })
}

 async onSubmit()  
 {
   console.log("In submit")
   var req = this.form.getRawValue()
   if(req['subassign_userid']=='')
   {
     delete req['subassign_userid']
   }
   if(req['specialist']=='internal')
   {
     req['assign_external_name']=''
     req['assign_external_email']=''
     req['assign_external_mobile']=''
   }
   if(this.isEdit==false)
    {
   //   delete req['_id']
    }
    else
    {
     // req['_id']=this.dataid
    }
    if(!req.assign_external_email) {
      req = {request_progress:{internal_assigned : {status : true},  request_created : {status : false}}, ...req}
    }
    else {
      req = {request_progress:{external_assigned : {status : true},internal_assigned : {status : true}, request_created : {status : false}}, ...req}
    }
    this.familyoffice.ticketput(req).subscribe(response=> {
      this.coreservice.success(this.resource.message('TICKET_UPDATED'));

      this.router.navigate(['/tickets']);
    }, (err) => {
  })
}
}

<div align="right">
  <div class="content-container">
    <mat-card class="cardclass">
      <mat-card-header>
        <mat-card-title> {{ resource.message("PHOTOS") }} </mat-card-title>
        <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
      </mat-card-header>
      <mat-card-content>
        <mat-table
          #photoTable
          photoTable
          matTableExporter
          [dataSource]="listData"
          matSort
          class="mattable"
          #exporter="matTableExporter"
          [hiddenColumns]="[1, 2, 3, 4, 5]"
        >
          <!-- Name Column -->
          <ng-container matColumnDef="file">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >File</mat-header-cell
            >
            <mat-cell *matCellDef="let element"
              ><img src="{{ element.url }}" class="imgcss" /><button
                class="footerbtn"
                mat-raised-button
                color="primary"
                (click)="viewImg(element)"
              >
                View
              </button>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Title</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
          </ng-container>

          <!-- System Column -->

          <!-- Action Column -->
          <ng-container matColumnDef="Action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button (click)="edit(row)">
                <mat-icon>mode_edit</mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="onDelete(row._id)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 15, 20]"
          [pageSize]="5"
          showFirstLastButtons
        ></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>

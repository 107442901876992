<mat-card class="">
  <mat-card-content>
    <form class="form-inline">
      <div class="form-group mr-2"></div>
      <span class="example-spacer"></span>
      <div class="mr-1">
        <input
          matInput
          placeholder="Search"
          autocomplete="off"
          name="searchKey"
          class="searchfloatright"
          [(ngModel)]="searchKey"
          (keyup)="applyfilter()"
        />
      </div>
    </form>
    <p>
      <br />
      <mat-table
        #MeetingsTable
        MeetingsTable
        matTableExporter
        [dataSource]="listData"
        matSort
        class="mattable"
        #exporter="matTableExporter"
        [hiddenColumns]="[1, 2, 3, 4, 5]"
      >
        <!-- Committee Name -->
        <ng-container matColumnDef="committee">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Committee Name</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.committee ? element.committee.title : ""
          }}</mat-cell>
        </ng-container>
        <!-- Meeting Name -->
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Meeting Name</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
        </ng-container>

        <!-- Previous Meeting -->
        <ng-container matColumnDef="start_date">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Date of Meeting</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.start_date | customDate
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="minutes_of_meeting">
          <mat-header-cell *matHeaderCellDef>Minutes of meeting</mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index">
            <button mat-icon-button>
             <u class="view_more" (click)="getMinutes(row)"> <a>View</a></u>
              <!-- <mat-icon>mode_edit</mat-icon> -->
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 15, 20]"
        [pageSize]="5"
        showFirstLastButtons
      ></mat-paginator>
    </p>
    <div style="display: none">
      <div class="testdata">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga cum doloribus magnam aspernatur facilis inventore atque iusto explicabo fugit nesciunt! Ipsa quis magnam non excepturi veritatis debitis adipisci obcaecati laudantium.
      </div>
    </div>
  </mat-card-content>
</mat-card>

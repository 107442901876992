import { Component, OnInit ,Inject} from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators,FormArray} from '@angular/forms';
import {language} from '@shared/config/language';
import { FileService } from '@services/File.service';
import {BusinessInfoService} from '@services/BusinessInfo.service';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {CoreService} from '@services/CoreService';
import { title } from 'process';
@Component({
  selector: 'app-dialogothersreport',
  templateUrl: './dialogothersreport.component.html',
  styleUrls: ['./dialogothersreport.component.css']
})
export class DialogothersreportComponent implements OnInit {
  file_type = new FormControl('PDF');

  form :FormGroup = new FormGroup({
    type:new FormControl(''),
    business:new FormControl(''),
    title:new FormControl('',Validators.required),
    url:new FormControl(''),
    file:new FormControl(''),
    _id:new FormControl(''),
    date:new FormControl(new Date()),
    uploadtype:new FormControl('PDF')


  })
  resource = language;
  selFile = new Object();
  selFileName = "";
  isEdit:boolean=false;
  constructor(private fileService: FileService,    
    private businessinfoservice: BusinessInfoService,
    public dialogRef : MatDialogRef<DialogothersreportComponent>,
    private coreservice:CoreService,
    @Inject(MAT_DIALOG_DATA) public data,


    ) { }

  ngOnInit(): void {
    if(this.data.editindex!=='-1')
    {
      const loadData=this.data.editindex
      console.log('><><><><><><><',loadData)
      this.form.patchValue({
        type:loadData.type,
        business:loadData.business,
        title:loadData.title,
        url:loadData.url,
        file:null,
        _id:this.data.id,
        date:new Date()
    
      })
      this.file_type.setValue(loadData.uploadtype)

      this.isEdit=true;
    }
    else
    {
      this.form.patchValue({
        business:this.data.id
      })
      this.isEdit=false
    }
    }
  onFileChange(event) {

    
    this.selFile = (event.target as HTMLInputElement).files[0];
    this.selFileName = (event.target as HTMLInputElement).files[0].name;
   }
  
  async submitData(){ 
    if (this.form.invalid) {
      return;
  }
  let fileurl = this.selFileName; 
  var formData: any = new FormData();
  formData.append("file", this.selFile);
 // fileurl = await this.fileService.uploadFile(  formData, true, false );
  var constdata=this.form.getRawValue();

  if(this.selFileName!='')
  {
    fileurl = await this.fileService.uploadFile(  formData, true, false );
 
  }
  var constdata=this.form.getRawValue();
  console.log("*************************",fileurl)
  if(fileurl!=='')
  {
    constdata['url']=fileurl

  }
  constdata['type']='others'
  delete constdata['file'];
  console.log(">>>>>>>>>>>>>>>>>>>",this.data.editindex)
  if(this.data.editindex!=='-1')
  {
    
  }
  else
  {
  //  constdata['type']='financial_statements'
   // constdata['business']=this.data.id
    delete constdata['_id'];
    
  }
  
  delete constdata['file'];
  this.businessinfoservice.postKeyInfo(constdata).subscribe(response=> {
    const result = response.body;
    this.coreservice.success(this.resource.message('BUSINESS_IMAGE_SAVE'));
    this.closeDialog()
    }, (err) => {})
 
}

closeDialog()
  {
    this.dialogRef.close(false)
  }

}

<mat-card class="">
  <mat-card-content>
    <form class="form-inline">
      <div class="form-group mr-2">

        <button
          mat-stroked-button
          class="iconcss"
          (click)="convertToPDF('save')"
        >
          <i class="fa fa-file-pdf fa-lg"></i>
        </button>
        <button
          mat-stroked-button
          class="iconcss"
          (click)="exporter.exportTable('csv', { fileName: 'activity-logs' })"
        >
          <i class="fa fa-file-excel fa-lg"></i>
        </button>
        <button
          mat-stroked-button
          class="iconcss"
          (click)="convertToPDF('print')"
        >
          <i class="fa fa-print fa-lg"></i>
        </button>
        <!-- <button
          mat-stroked-button
          class="iconcss"
          (click)="openLevelModal()"
        >
          ?
        </button> -->
      </div>
      <span class="example-spacer"></span>
      <div class="mr-1">
        <input
          matInput
          placeholder="Search"
          autocomplete="off"
          name="searchKey"
          class="searchfloatright"
          [(ngModel)]="searchKey"
          (keyup)="applyfilter()"
          (keydown.enter)="$event.preventDefault()"
        />
      </div>
    </form>
    <p>
      <br />
      <mat-table
        #ImageTable
        ImageTable
        matTableExporter
        [dataSource]="listData"
        matSort
        class="mattable"
        #exporter="matTableExporter"
      >
        <!-- Name Column -->
        <ng-container matColumnDef="member_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Member Name</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.member_name
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="level">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Grade</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.level }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="adherence_points">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Adherence</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.adherence_points
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="engagement_points">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Engagement</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.engagement_points
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="score">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Total points</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.score }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="getRecord(row)"
        ></mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 15, 20]"
        [pageSize]="5"
        showFirstLastButtons
      ></mat-paginator>
    </p>
  </mat-card-content>
</mat-card>

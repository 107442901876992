<div class="content p30"> 
    <div id="block_container">
          <a  routerLink="/tickets" id="bloc1" mat-flat-button class="mat-flat-button-transperent" ><span  class="material-icons">keyboard_backspace</span></a> 
          <div id="bloc2"><h5>{{resource.message('FAMILYOFFICE')}} /{{resource.message('REQUEST')}}</h5></div>
    </div>
    <form [formGroup]="form">
      <mat-card class="">
        <mat-card-header>            
          <mat-card-title> Request No. {{ticketno}} </mat-card-title>
        </mat-card-header>
        <mat-card-content class="contentclass">
          <hr />
          <mat-grid-list cols="2" rowHeight="100px" >
              <mat-grid-tile  >
                    <mat-form-field appearance="outline" class="field-spacer">
                        <mat-label>Member Name</mat-label>
                        <input matInput placeholder="Member Name" formControlName="member" readonly>
                      </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile  >
                <mat-form-field appearance="outline" class="field-spacer">
                    <mat-label>Created On</mat-label>
                    <input matInput placeholder="Created On" formControlName="created_on" readonly>
                  </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile  >
                    <mat-form-field appearance="outline" class="field-spacer">
                        <mat-label>Description</mat-label>
                        <textarea matInput placeholder="Description" formControlName="description" readonly> </textarea>
                    </mat-form-field>
              </mat-grid-tile>
             
          </mat-grid-list>
        </mat-card-content>
        <hr/>
        <mat-card-actions align="left">
          <div class="form-group mr-2">  
              <button mat-stroked-button type="button" class="wide" [disabled]="disableText">Previous</button>
          </div>
       <div class="rightpanel" align="right"> 
         <button mat-raised-button color="primary" class="btn btn-primary login-btn wide" type="button" (click)="nextPage()">Next</button>
       </div> </mat-card-actions>
      </mat-card>
    </form>
  </div>
import { Component, OnInit } from '@angular/core';
import { BusinessInfoService } from '@services/BusinessInfo.service';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormBuilder,
  FormArray,
  NgForm,
} from '@angular/forms';
import { AuthService } from '@services/Auth.service';
import { CoreService } from '@services/CoreService';
import { language } from '@shared/config/language';
import { FileService } from '@services/File.service';

@Component({
  selector: 'app-parentcompany',
  templateUrl: './parentcompany.component.html',
  styleUrls: ['./parentcompany.component.css'],
})
export class ParentcompanyComponent implements OnInit {
  listData:any;
  registeraddress = [];
  coporateaddress = [];
  form: FormGroup = new FormGroup(
    {
      _id: new FormControl(0),
      name: new FormControl('', Validators.required),
      established: new FormControl('', Validators.required),
      logo: new FormControl(''),
      about: new FormControl(''),
      website: new FormControl('', [
        Validators.required,
        Validators.pattern(
          '((http|https)://)(www.)?' +
            '[a-zA-Z0-9@:%._\\+~#?&//=]' +
            '{2,256}\\.[a-z]' +
            '{2,6}\\b([-a-zA-Z0-9@:%' +
            '._\\+~#?&//=]*)'
        ),
      ]),
      mobile1: new FormControl(''),
      mobile2: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),

      keyperson: new FormControl('', Validators.required),
      registeredaddress: new FormControl('', Validators.required),
      corporateaddress: new FormControl('', Validators.required),
      address: new FormArray([]),
      type: new FormControl(''),
      file: new FormControl(''),
    },
    this.mustBeDifferent('mobile1', 'mobile2')
  );

  PERMISSION_EDIT = AuthService.authentication('ParentCompany', 'READ');
  PERMISSION_ADD = AuthService.authentication('ParentCompany', 'ADD');
  resource = language;
  selFile = new Object();
  selFileName = '';
  maxDate: Date;
  constructor(
    private businessinfoservice: BusinessInfoService,
    private coreservice: CoreService,
    private fileService: FileService
  ) {
    this.maxDate = new Date();
  }

  ngOnInit(): void {
    AuthService.authenticate_redir('ParentCompany', ['READ']);

    this.getbusinessinfodata();
  }

  mustBeDifferent(controlName1: string, controlName2: string) {
    return (formGroup: FormGroup) => {
      const control1 = formGroup.controls[controlName1];
      const control2 = formGroup.controls[controlName2];

      if (control2.errors && !control2.errors.areSame) {
        return;
      }

      if (control1.value === control2.value && control2?.value?.length > 5) {
        control2.setErrors({ areSame: true });
      } else {
        control2.setErrors(null);
      }
      return null;
    };
  }
  getbusinessinfodata() {
    this.businessinfoservice.businessinfoget().subscribe(
      (response: any) => {
        console.log('response', response);
        this.listData = response['data'][0]['info'];
        this.form.setValue({
          _id: this.listData['_id'],
          name: this.listData['name'],
          established:
            this.listData['established'] != undefined
              ? this.listData['established']
              : '',
          logo: this.listData['logo'] ? this.listData['logo'] : '',
          about:
            this.listData['about'] != undefined ? this.listData['about'] : '',
          website:
            this.listData['website'] != undefined
              ? this.listData['website']
              : '',
          mobile1:
            this.listData['mobile1'] != undefined
              ? this.listData['mobile1']
              : '',
          mobile2:
            this.listData['mobile2'] != undefined
              ? this.listData['mobile2']
              : '',
          email: this.listData['email'],
          keyperson:
            this.listData['keyperson'] != null
              ? this.listData['keyperson']
              : '',
          registeredaddress:
            response['data'][0]['info']['address'][1] != undefined
              ? response['data'][0]['info']['address'][1]['address']
              : '',
          corporateaddress:
            response['data'][0]['info']['address'][0] != undefined
              ? response['data'][0]['info']['address'][0]['address']
              : '',
          address: [],
          type: 'business',
          file: '',
        });
      },
      (err) => {}
    );
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      console.log(this.form);
      return;
    }
    console.log(this.listData)

    var constdata = this.form.getRawValue();
    constdata['address'].push(
      {
        type: 'Corporate Office',
        address: this.form.getRawValue().corporateaddress,
      },
      {
        type: 'Registered Office',
        address: this.form.getRawValue().registeredaddress,
      }
    );
    if (this.selFileName != '') {
      let fileurl = this.selFileName;
      var formData: any = new FormData();
      formData.append('file', this.selFile);
      fileurl = await this.fileService.uploadFile(formData, true, false);
      constdata['logo'] = fileurl;
    }
    delete constdata['file'];
    constdata['mobile'] = '1234567890';
    console.log('constdata', constdata);

    if(!this.listData || (this.listData && !this.listData._id))
    this.businessinfoservice
      .postBusinessData(constdata)
      .subscribe(
        (response) => {
          console.log('response', response);

          const result = response.body;
          this.coreservice.success(
            this.resource.message('INFORMATION_UPDATED')
          );
        },
        (err) => {
          console.log(err);
        }
      );
    else {
      this.businessinfoservice
      .updateBusinessData(constdata, this.listData['_id'])
      .subscribe(
        (response) => {
          console.log('response', response);

          const result = response.body;
          this.coreservice.success(
            this.resource.message('INFORMATION_UPDATED')
          );
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  onFileChange(event) {
    this.selFile = (event.target as HTMLInputElement).files[0];
    this.selFileName = (event.target as HTMLInputElement).files[0].name;
  }
  previewimg(eve) {
    window.open(this.form.get('logo').value, '_blank');
  }
  onClear() {
    this.form.patchValue({ mobile1: '', mobile2: '' });
  }
}

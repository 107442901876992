<app-header [parent]="this.drawer"></app-header>
 
<mat-drawer-container class="main-container" autosize>
  <mat-drawer #drawer mode="side" style="background-color:#095294; color: #ffffff;">
    <button (click)="showFiller = !showFiller" mat-raised-button>
     
    </button>
    <div>
      <img src="../../../assets/images/admin.png" class="img-admin" >
     
     </div>
     <div style="padding: 10px;">
      <h5 class="admin-name">Rakesh Joseph</h5>
      <span><i>Family admin</i></span><br>


      <ul> 
        <li class="fullwidth"><a [routerLink]="'/dashboard'" class="sidebarpadding"><i class="fa fa-th-large"></i> <span class=" spanpadding">Dashboard</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"><i class="fa fa-user"></i> <span class="spanpadding">Users&nbsp;&nbsp;</span></a></li>
        <li class="fullwidth"><a [routerLink]="'/familyInfo'" class="sidebarpadding"> <i class="fa fa-group"></i> <span class=" spanpadding">Family info</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"><i class="fa fa-bookmark"></i> <span class=" spanpadding">Memberships</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"><i class="fa fa-th-list"></i> <span class=" spanpadding">Individual Development Plan</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"><i class="fa fa-building-o"></i> <span class=" spanpadding">Business Info</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"> <i class="fa fa-user"></i> <span class=" spanpadding">Committee</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"> <i class="fa fa-user"></i> <span class=" spanpadding">Committee Meetings</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"><i class="fa fa-bookmark"></i> <span class=" spanpadding">Other Meetings</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"><i class="fa fa-bar-chart"></i> <span class=" spanpadding">Polls</span></a></li>
        <li class="fullwidth"><a href="personaltask" class="sidebarpadding"><i class="fa fa-check-circle"></i> <span class=" spanpadding">Personal Tasks</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"><i class="fa fa-list-alt"></i> <span class=" spanpadding">Declarations</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"> <i class="fa fa-book"></i> <span class=" spanpadding">Knowledge Hubs</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"> <i class="fa fa-group"></i> <span class=" spanpadding"> Family Office Team</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"><i class="fa fa-file-text-o"></i> <span class=" spanpadding">Virtual Office</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"><i class="fa fa-map-marker"></i> <span class=" spanpadding"> Common Space</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"><i class="fa fa-trophy"></i> <span class=" spanpadding">Achievements</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"><i class="fa fa-ticket"></i> <span class=" spanpadding">Tickets</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"> <i class="fa fa-info-circle"></i> <span class=" spanpadding">Contact Us</span></a></li>
        <li class="fullwidth"><a href="#" class="sidebarpadding"> <i class="fa fa-star-o"></i> <span class=" spanpadding">  My Subscriptions</span></a></li>
    </ul>
    <a type="button" mat-stroked-button class="btn btn-primary logout-btn" href="index.html"> Logout </a>
    
     </div>
  </mat-drawer>  

  <!-- <app-leftmenu #drawer></app-leftmenu>  -->

  <div class="content p30"> 

    <h5>Roles</h5>    

<!--
    <mat-grid-list cols="2" rowHeight="300px">
      <mat-grid-tile colspan=2 >
        <mat-card class="example-card">
          <mat-card-header>            
            <mat-card-title><i class="fa fa-file-text-o"></i> Dashboard Tile </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
            </p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>  
  -->    

    
    <mat-card class="">
      <mat-card-header >            
        <mat-card-title > Roles </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form class="form-inline">
         <div class="form-group mr-2"> 
          
          <button  mat-stroked-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
          class="filtersortborder">
            <i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;Filter
          </button>
          <mat-menu #menu="matMenu" class="menustyle">
            <span style="color:#b4b1b1">Filter By</span>
           <div class="menuitemstyle">
            <p>Role</p>
            <mat-checkbox class="mat-menu-item" (change)="filterData($event,'role',item.role)" (click)="stoppro($event)" *ngFor="let item of this.listData.data; index as i; first as isFirst"> {{item.role}} </mat-checkbox>
<!--            <mat-checkbox class="mat-menu-item" (click)="filterData($event,'Role','Family member')"> Advisor</mat-checkbox>  -->
          
            <!--<hr class="hrclass" />
            <p>Status</p>           
            <mat-checkbox class="mat-menu-item" (change)="filterData($event,'isDel','true')" (click)="stoppro($event)">Active</mat-checkbox>
            <mat-checkbox class="mat-menu-item" (change)="filterData($event,'isDel','true')" (click)="stoppro($event)">Inactive</mat-checkbox>  
            -->
            <hr/>
            
            <button mat-raised-button color="primary" (click)="closeMyMenu()">Apply</button>
          </div>
          </mat-menu>
         <!-- <button mat-stroked-button class="filtersortborder"><i class="material-icons">sort</i>&nbsp;&nbsp;Sort By</button>
         -->
      
        
          <button mat-stroked-button class="iconcss"><i class="fa fa-file-pdf fa-lg"></i></button>
          <button mat-stroked-button class="iconcss" (click)="exporter.exportTable('csv')"><i class="fa fa-file-excel fa-lg"></i></button>
          <button mat-stroked-button class="iconcss"><i class="fa fa-print fa-lg"></i></button>
         </div>
          <span class="example-spacer"></span>
          <div class="mr-1"> 
        
          <button mat-raised-button color="primary" class="addbutton" class="buttonfloatright" (click)="addrole()"><i class="fas fa-plus"></i>&nbsp;&nbsp;ADD ROLE</button>
          <input matInput [(ngModel)]="searchKey" placeholder="Search by Name/Email ID/Mobile NO" autocomplete="off" (keyup)="applyfilter()" name="searchKey" class="searchfloatright" />
        
        </div>
      </form>
        <p>

<br />
          <mat-table   #roleTable roleTable matTableExporter [dataSource]="listData" matSort class="mattable"  #exporter="matTableExporter" [hiddenColumns]="[0,4,5,6]">

       <!-- Checkbox Column -->
       <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      
      
           
           <!-- Name Column -->
           <ng-container matColumnDef="role">
             <mat-header-cell *matHeaderCellDef mat-sort-header>Role</mat-header-cell>
             <mat-cell *matCellDef="let element">{{element.role}}</mat-cell>
           </ng-container>
     
           <ng-container matColumnDef="created_at">
             <mat-header-cell *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
             <mat-cell *matCellDef="let element">{{element.created_at}}</mat-cell>
           </ng-container>
     
           <ng-container matColumnDef="updated_at">
             <mat-header-cell *matHeaderCellDef mat-sort-header>Last Updated</mat-header-cell>
             <mat-cell *matCellDef="let element">{{element.updated_at}}</mat-cell>
           </ng-container>
           <!-- System Column -->
     
           <!-- Action Column -->
           <ng-container matColumnDef="Action">
             <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
             <mat-cell *matCellDef="let row">
               <button mat-icon-button  (click)="edit(row)"  ><mat-icon>mode_edit</mat-icon></button>
               <button mat-icon-button color="warn"  (click)="onDelete(row._id)"   ><mat-icon>delete_outline</mat-icon></button>
             </mat-cell>
           </ng-container>
            <!-- Loading Column -->
            <ng-container matColumnDef="loading">
             <mat-footer-cell *matFooterCellDef colspan="4">Loading data ...</mat-footer-cell>
           </ng-container>
            <!-- No data Column -->
            <ng-container matColumnDef="noData">
             <mat-footer-cell *matFooterCellDef colspan="4">No data</mat-footer-cell>
           </ng-container>
           <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
           <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
           <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':listData!=null}"></mat-footer-row>
           <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(listData!=null && listData.data.length==0)}"></mat-footer-row>
         </mat-table>
         <mat-paginator [pageSizeOptions]="[5,10,15,20]" [pageSize]="5" showFirstLastButtons></mat-paginator> 


        </p>

        
      </mat-card-content>
    </mat-card>



  </div>

</mat-drawer-container>


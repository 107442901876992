import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators,FormArray,FormBuilder} from '@angular/forms';
import { UACService } from '@services/UAC.service';
import { ActivatedRoute, Router } from '@angular/router';
import {CoreService} from '@services/CoreService';
import { language } from '@shared/config/language';
import { PollsService } from '@services/Polls.service';
import { constants } from '@shared/config/constants';
import {CommitteeService} from '@services/Committee.service';
import {MatChipInputEvent} from '@angular/material/chips';
import * as moment from 'moment';
export interface Option {
  votes:string,
  total_percentage : string,
  option: string
}
@Component({
  selector: 'app-addpolls',
  templateUrl: './addpolls.component.html',
  styleUrls: ['./addpolls.component.css']
})
export class AddpollsComponent implements OnInit {
  today = moment().format('YYYY-MM-DDTHH:mm');
  notice =new FormControl('single')
  isEdit:Boolean = false;
  form :FormGroup = new FormGroup({
    _id:new FormControl(0),
    committee:new FormControl('',Validators.required),
    title:new FormControl('',Validators.required),
    question_type: new FormControl(''),
    question_options: new FormControl(''),
    active_till: new FormControl('',Validators.required),
    total_no_people:new FormControl(''),
    total_votes:new FormControl(''),
    is_expired:new FormControl(''),
    question:new FormControl(''),
    status:new FormControl('')
    
  })

  COMMITTEE_LIST:any;

  resource = language;
  labelText:String='Add Poll';
  option_array=[];
  isDisabled:boolean=true;
  STATUS_LIST:any;

  constructor(private uacservice: UACService, private router : Router,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private pollsService: PollsService,
    private committeeService:CommitteeService,
    private fb: FormBuilder,

   
    ) {
 
      this.STATUS_LIST = constants.poll_status;

    this.activeRoute.queryParams.subscribe(data => {

      if(data.id!=undefined)
      {
        this.isEdit=true
        this.labelText='Edit Poll';
        this.pollsService.getPolls(data.id).subscribe(response=> {
          const result = response.body['data'];
          console.log(new Date(result.active_till).toISOString().slice(0, 16))
          console.log("RECEIVED EDIT DATA",result.question_options)
          this.form.setValue({
            _id:result._id,
            committee:result.committee,
            title:result.title,
            question_type: result.question_type,
            question: result.question,
            question_options:"",
            active_till: moment(result.active_till).local().format('YYYY-MM-DDTHH:mm'),
            is_expired: result.is_expired,
            total_no_people:0,
            total_votes: result.total_votes,
            status:result.status 
        })
        this.form.controls.committee.disable()
        result.question_options.forEach(element => {
          const val=element
          this.option_array.push(val)
        });
        this.committeeService.getCommittee(result.committee).subscribe(val => {
          console.log(val)
          this.form.patchValue({total_no_people: val.body['data'].members.length})
        })
      //  this.option_array.push(result.question_options)
        this.notice = new FormControl(this.form.get("question_type").value);
       })

      } 
    })

   }

  ngOnInit(): void {
    this.getCommittees('committee','');
    this.form.patchValue({
      _id:0,
      committee:'',
      title:'',
      question_type: 'single',
      question: '',
      question_options:"",
      active_till: "",
      is_expired: "",
      total_no_people: "",
      total_votes: 0,
      status:'active'
  })


 

  }

  getCommittees(type, committee){

    console.log(type)
    
        this.committeeService.getCommitteesFilter(type,'').subscribe(response=> {
         this.COMMITTEE_LIST = response.body['data'].filter(val => val.is_published);;
             
        });
      }
    
 
  

  submit():void{ 


    
    if (this.form.invalid) {
        return;
    }

    if(this.option_array.length < 1){
      alert("A poll is required at-least 1 option. In order to delete this option, please provide default option");
      return;
    }
    
    if(!this.isEdit){
      this.form.removeControl('_id');
      this.form.removeControl('status');
    }
    const constdata=this.form.getRawValue();
    constdata['question_options']=this.option_array
    console.log("IN SUB",constdata)
    constdata['active_till'] = moment(constdata['active_till']).utc();
    this.pollsService.postPolls(constdata).subscribe(response=> {
      
      const result = response.body;
      if(!this.isEdit)
      {
        
        this.coreservice.success(this.resource.message('POLLS_SAVE'));
      }
      else
      {
        this.coreservice.success(this.resource.message('POLLS_UPDATE'));
      }
     
     this.router.navigate(['polls']);

     }, (err) => {

      this.coreservice.error(err.error.message);
      this.form.addControl('_id', new FormControl(''));

      
   })
   
  }

  get sociallinks() {
    return this.form.get('question_array') as FormArray;
  }
 
  createQuestion(): FormGroup {
    return this.fb.group({
      question: '',
    });
  }
  add(event: MatChipInputEvent){
    console.log(event)
    const value = (event.value || '').trim();

    // Add our fruit
    
    if (value) {
      this.option_array.push({
      votes : 0,
      total_percentage : 0,
      option : value});
    }

    // Clear the input value
   // event.chipInput!.clear();
  }

  remove(index) {
    if(this.option_array.length > 1){
      this.option_array.splice(index, 1);
    }else{
      alert("A poll is required at-least 1 option. In order to delete this option, please provide default option")
    }

   
  }
  add_option(){
  
      const val={
        votes : 0,
        total_percentage : 0,
        option : this.form.get('question_options').value
      }
      this.option_array.push(val)
      this.form.patchValue({question_options:''})
  
    console.log("FORM VALUES",this.option_array)
  }
}

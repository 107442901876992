import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { constants } from '@shared/config/constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommitteeService } from '@services/Committee.service';
import { CoreService } from '@services/CoreService';

@Component({
  selector: 'app-committee-s5',
  templateUrl: './committee-s5.component.html',
  styleUrls: ['./committee-s5.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CommitteeS5Component implements OnInit {
  notice = new FormControl('15');
  attend = new FormControl('number');
  valueControl = new FormControl('0');

  constructor(
    private coreservice: CoreService,
    private router: Router,
    private committeeService: CommitteeService,
    private activeRoute: ActivatedRoute
  ) {}
  TEMPLATE = 0;
  steps = [];
  isTemplate: boolean = false;
  TYPE_LIST: any;
  COMMITEE_ID = '';
  CATEGORY_LIST: any;

  COMMITTEE_LIST: any;
  SUBCOMMITTEE_LIST: any;

  isEdit: Boolean = false;

  CURRENT_COMMITTEE = constants.CURRENT_COMMITTEE;
  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    meeting_frequency: new FormControl(0, Validators.required),
    meeting_notice: new FormControl(this.notice.value, Validators.required),
    attendence_type: new FormControl(this.attend.value),
    meeting_days: new FormControl(''),
    attendence: new FormControl(''),
    atendence_value: new FormControl(''),
  });
  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.template != undefined) {
        this.TEMPLATE = data.template;
        if (this.TEMPLATE == 1) this.isTemplate = true;
        else this.isTemplate = false;
      }

      if (this.isTemplate) {
        this.steps = constants.committee_template_steps;
      } else {
        this.steps = constants.committee_steps;
      }
      if (data.id != undefined) {
        this.form.patchValue({ _id: data.id });
        this.COMMITEE_ID = data.id;
        this.committeeService
          .getCommittee(this.COMMITEE_ID)
          .subscribe((response) => {
            const result = response.body;
            this.CURRENT_COMMITTEE = result['data'];
            this.form.patchValue({
              _id: this.CURRENT_COMMITTEE['_id'],
              meeting_frequency: this.CURRENT_COMMITTEE['meeting_frequency'],
              attendence:
                this.CURRENT_COMMITTEE['attendance_quorum'] != null
                  ? this.CURRENT_COMMITTEE['attendance_quorum'][
                      'attendance_quorum_value'
                    ]
                  : '',
              attendence_type:
                this.CURRENT_COMMITTEE['attendance_quorum'] != null
                  ? this.CURRENT_COMMITTEE['attendance_quorum'][
                      'attendance_quorum_type'
                    ]
                  : '',
              meeting_notice:
                this.CURRENT_COMMITTEE['meeting_notice'] != null
                  ? this.CURRENT_COMMITTEE['meeting_notice']
                  : '',
              meeting_days: '',
            });
            if (this.CURRENT_COMMITTEE['meeting_notice'] != null) {
              if (
                this.CURRENT_COMMITTEE['meeting_notice'] != '15' &&
                this.CURRENT_COMMITTEE['meeting_notice'] !== '30'
              ) {
                console.log('IN IF');
                this.form.patchValue({
                  notice: 'others',
                  meeting_notice: 'others',
                  meeting_days: this.CURRENT_COMMITTEE['meeting_notice'],
                });
                this.notice = new FormControl('others');
              } else {
                console.log('IN ELSE');
                this.form.patchValue({
                  notice: this.CURRENT_COMMITTEE['meeting_notice'],
                  meeting_notice: this.CURRENT_COMMITTEE['meeting_notice'],
                  meeting_days: '',
                });
                this.notice = new FormControl(
                  this.form.get('meeting_notice').value
                );
              }
            }
            this.attend = new FormControl(
              this.form.get('attendence_type').value
            );
            this.valueControl = new FormControl(
              this.form.get('attendence').value
            );
          });
      } else {
        this.router.navigate(['committee']);
      }
    });

    this.TYPE_LIST = constants.committee_meeting_frquency;
    /*  this.form.patchValue({
      meeting_notice:this.notice.value,
      attendence: this.attend.value,
    })*/
  }
  submit(type): void {
    if (this.form.invalid) {
      return;
    }
    var rawcommittee = this.form.getRawValue();
    //    if(rawcommittee["attendence_type"]=='percentage') this.form.patchValue({"attendence":this.valueControl.value});
    if (rawcommittee['attendence_type'] == 'percentage')
      rawcommittee['attendence'] = this.valueControl.value;

    rawcommittee.attendance_quorum = {
      attendance_quorum_type: rawcommittee['attendence_type'],
      attendance_quorum_value: rawcommittee['attendence'],
    };
    if (this.notice.value !== 'others') {
      rawcommittee.meeting_notice = this.notice.value;
    }
    if(rawcommittee.meeting_notice === 'others') {
      rawcommittee.meeting_notice = rawcommittee.meeting_days;
    }
    delete rawcommittee.meeting_days;
    delete rawcommittee.attendence_type;
    delete rawcommittee.attendence;
    this.committeeService.addCommittee(rawcommittee).subscribe(
      (response) => {
        const result = response.body;

        constants.CURRENT_COMMITTEE = result['data'];
        if (this.isTemplate) {
          if (type == 'final') {
            this.router.navigate(['committee/add/s6'], {
              queryParams: {
                id: constants.CURRENT_COMMITTEE['_id'],
                template: this.TEMPLATE,
              },
            });
          } else {
            this.coreservice.success('Committee saved as draft');

            this.router.navigate(['/templatemanagement']);
          }
        } else {
          if (type == 'final') {
            this.router.navigate(['committee/add/s6'], {
              queryParams: {
                id: constants.CURRENT_COMMITTEE['_id'],
                template: this.TEMPLATE,
              },
            });
          } else {
            this.coreservice.success('Committee saved as draft');
            this.router.navigate(['/committee']);
          }
        }
      },
      (err) => {
        console.log(err);
        //      var errormessages = "Please correct the errors - \n";
        //      err.error.errors.forEach(element => errormessages += " - "+element.msg+"\n");
        //  this.coreservice.error("Invalid Committee - "+err.error.message);
        //  this.form.addControl('_id', new FormControl(''));
      }
    );
  }

  getCommittees(type, committee) {
    console.log(type);

    this.committeeService
      .getCommitteesFilter(type, committee)
      .subscribe((response) => {
        if (type == 'committee') {
          this.COMMITTEE_LIST = response.body['data'];
        } else if (type == 'subcommittee') {
          this.SUBCOMMITTEE_LIST = response.body['data'];
          console.log(this.SUBCOMMITTEE_LIST);
        }
      });
  }

  previous() {
    this.router.navigate(['committee/add/s4'], {
      queryParams: { id: this.COMMITEE_ID, template: this.TEMPLATE },
    });
  }
}

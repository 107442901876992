import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-smstemplate',
  templateUrl: './smstemplate.component.html',
  styleUrls: ['./smstemplate.component.css']
})
export class SmstemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { language } from '@shared/config/language';
import { Router } from '@angular/router';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { IdpService } from '@services/Idp.service';
import {
  MatDialog,
} from '@angular/material/dialog';
import { DialogviewattendanceComponent } from '@shared/components/dialogviewattendance/dialogviewattendance.component';
import { Subscription } from 'rxjs';
import { DialoginviteesComponent } from '@shared/components/dialoginvitees/dialoginvitees.component';
export interface MeetingElement {
  meetingtitle: number;
  datetime: string;
  venue: string;
  participants: string;
  status: string;
}
@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.css'],
})
export class MeetingComponent implements OnInit, OnDestroy {
  resource = language;
  ELEMENT_DATA: MeetingElement[];
  rowModelType: 'serverSide';
  rowData: any;
  getSubscription!: Subscription;
  displayedColumns: string[] = [
    'title',
    'start_date',
    'venue',
    'participants',
    'status',
    'Action2',
  ];
  listData = new MatTableDataSource<MeetingElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<MeetingElement>(true, []);
  localstoragedata: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('meetingTable') table: MatTable<MeetingElement>;
  @Input() uid: string;

  constructor(
    private router: Router,
    private idp: IdpService,
    private changeDetectorRefs: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getMeetings(this.uid);
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
  }

  redirectTo() {
    this.router.navigate(['schedulemeeting'], {
      queryParams: { id: this.uid },
    });
  }

  getMeetings(id) {
    var filter = '';

    filter = '?filter[created_for]=' + id; //+this.localstoragedata._id
    this.getSubscription = this.idp.idpMeetingGet(filter).subscribe(
      (response: any) => {
        this.listData.data = response.data as MeetingElement[];
        this.table.renderRows();
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }

  openparticipants(msg, actionbutton, cancelbutton, row) {
    const dialogRef = this.dialog.open(DialoginviteesComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        id: row._id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('>><<<<>>>>', result.data.length);
    });
  }
  edit(row) {
    const status = row.meeting_status;
    let path = "/committee/meetings"
    if(status === 'mom_updated') {
      path = '';
    }
    else if(status === 'draft') {
      path = '/agenda';
    }
    else if(status === 'published') {
      path = '/rationalise';
    }
    else if(status === 'agenda_rationalized') {
      path = "/attendence";
    }
    else if(status === 'invite_sent') {
      path = "/agenda";
    }
    else if(status === 'marked_attendance') {
      path = '/outcome';
    }
    else if(status === 'added_resolution') {
      path = '/mom';
    }
    this.router.navigate([path], {
      queryParams: { id: row._id },
    });
  }

  getStatus(status: string) {
    if(status === 'mom_updated') {
      return 'Minutes Published'
    }
    else if(status === 'draft') {
      return 'Draft'
    }
    else if(status === 'published') {
      return 'Agenda Published'
    }
    else if(status === 'agenda_rationalized') {
      return 'Agenda Rationalized'
    }
    else if(status === 'invite_sent') {
      return 'Invite Sent'
    }
    else if(status === 'marked_attendance') {
      return 'Marked Attendance'
    }
    else if(status === 'added_resolution') {
      return 'Added Resolution'
    }
    else {
      return status;
    }
  }

  ngOnDestroy(): void {
    this.getSubscription?.unsubscribe();
  }
}

<form>
  <fieldset>
    <!--[disabled]=" (familyname!=select_user_family) ? isDisabled :''"-->
    <mat-card class="">
      <mat-card-content>
        <div class="row cancel">
          <button mat-stroked-button (click)="cancelMeeting()">
            Cancel Meeting
          </button>
        </div>

        <div *ngIf="!showSlab" class="row">
          <div class="row wholerow" *ngFor="let topic of topics; index as i">
            <div class="col-md-12 firstcol">
              <div class="row"><label>Agenda: </label> &nbsp;{{ topic.title }}</div>
            </div>
            <div class="col-md-3 firstcol">
              <strong style="color: black"
                ><label>Description: </label>&nbsp;{{ topic.description }}</strong
              >
            </div>
            <div class="col-md-4 firstcol">
              <strong style="color: black" *ngIf="topic.pre_reads"
                ><label>Pre-Reads: </label><br>&nbsp;{{ topic.pre_reads }}</strong
              >
            </div>
            <div class="col-md-4 firstcol">
              <strong style="color: black"><label>Actions </label></strong>
              <br />
              <button (click)="onEdit(topic)" mat-icon-button>
                <mat-icon>mode_edit</mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="onDelete(topic)">
                <mat-icon>delete_outline</mat-icon>
              </button>
              <button (click)="onDefer(topic)"matTooltip="Deferred Agenda" mat-icon-button>
                <mat-icon>arrow_upward</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div
            class="row suggestedrow"
            *ngFor="let topic of suggestedtopics; index as i"
          >
            <div class="col-md-6 firstcol">
              <div class="row">
                <label>Suggested Agenda: </label>&nbsp; {{ topic.title }}
              </div>
            </div>
            <div class="col-md-6 firstcol">
              <div class="row">
                <label>Suggested By: </label>&nbsp; {{ topic.user.name }}
                {{ topic.user.lastname }}
              </div>
            </div>
            <div class="col-md-3 firstcol">
              <strong style="color: black"
                ><label>Description: </label>&nbsp;{{ topic.description }}</strong
              >
            </div>
            <div class="col-md-4 firstcol">
              <strong style="color: black"
                *ngIf="topic.pre_reads"><label>Pre-Reads: </label>&nbsp;{{ topic.pre_reads }}</strong
              >
            </div>
            <div class="col-md-4 firstcol">
              <strong style="color: black"><label>Actions </label></strong>
              <br />
              <button (click)="onEdit(topic)" mat-icon-button>
                <mat-icon>mode_edit</mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="onDelete(topic)">
                <mat-icon>delete_outline</mat-icon>
              </button>
              <button (click)="onDefer(topic)" mat-icon-button>
                <mat-icon>folder_open</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
      <span class="blankline"></span>
      <div class="form-group mr-2">
        <button mat-stroked-button type="button" routerLink="/committee/agenda"
        [queryParams]="{ id: parentId }"class="wide">PREVIOUS</button>
      </div>
      <mat-card-actions align="end">
        <button
          mat-raised-button
          color="primary"
          class="btn btn-primary login-btn button-wider"
          type="button"
          (click)="addNote()"
          *ngIf="!isRationalized"
        >
          Rationalize Agenda{{isRationalized}}
        </button>
        <button
          mat-raised-button
          color="primary"
          class="btn btn-primary login-btn button-wider"
          type="button"
          routerLink="/committee/rationalized"
          [queryParams]="{ id: parentId }"
          *ngIf="isRationalized"
        >
          Next
        </button>
      </mat-card-actions>
    </mat-card>
  </fieldset>
</form>

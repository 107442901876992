<form>
  <fieldset>
    <!--[disabled]=" (familyname!=select_user_family) ? isDisabled :''"-->
    <mat-card class="">
      <mat-card-content>
        <div class="row">
          <div class="row wholerow" *ngFor="let topic of topics; index as i">
            <div class="col-md-12 firstcol">
              <div class="row"><label>Agenda: </label> &nbsp;{{ topic.title }}</div>
            </div>
            <div class="col-md-3 firstcol">
              <strong style="color: black"
                ><label>Description: </label>&nbsp;{{ topic.description }}</strong
              >
            </div>
            <div class="col-md-4 firstcol">
              <strong style="color: black" *ngIf="topic.pre_reads"
                ><label>Pre-Reads: </label>&nbsp;{{ topic.pre_reads }}</strong
              >
            </div>
            <div class="col-md-4 firstcol">
              <strong style="color: black"><label>Actions </label></strong>
              <br />
              <button (click)="onEdit(topic)" mat-icon-button>
                <mat-icon>mode_edit</mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="onDelete(topic)">
                <mat-icon>delete_outline</mat-icon>
              </button>
              <button (click)="onUndefer(topic)" matTooltip="Restore Agenda" mat-icon-button>
                <mat-icon>arrow_downward</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
      <span class="blankline"></span>
      <mat-card-actions align="start">
        <button
          (click)="previous()"
          mat-stroked-button
          type="button"
          class="wide"
        >
          PREVIOUS
        </button>
     
      </mat-card-actions>
    </mat-card>
  </fieldset>
</form>

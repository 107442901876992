import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';


@Injectable({
    providedIn:'root'
})

export class DeclarationService {

    constructor(private http: HttpClient){


    }

    getDeclarations(){

        return this.http.get(apiendpoints.DECLARATION,
            {
                headers: new HttpHeaders()
                  .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
                observe: 'response'
              }
            );      
    }

    getDeclaration(id){


      return this.http.get(apiendpoints.DECLARATION+"/"+id,
          {
              headers: new HttpHeaders()
                .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
              observe: 'response'
            }
          );      
    }

    addDeclaration(data:any){
      console.log(data);
      return this.http.post(apiendpoints.DECLARATION, {declaration:data},  {
        headers: new HttpHeaders()
          .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
        observe: 'response'
      });      
    }

    removeDeclaration(id){
    
      const options = {
        headers: new HttpHeaders({
          'Authorization': `Token `+localStorage.getItem('ACCESS_TOKEN')
        }),
        body: {
          declarationId: id,
        },        
      };
      
      return this.http.delete(apiendpoints.DECLARATION+"/"+id, options);
    }



 
}

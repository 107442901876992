import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpParams,
} from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getFamilyUses() {
    return this.http.get(apiendpoints.USERS_FAMILY, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }

  getUsers(filter) {
    return this.http.get(apiendpoints.USERS + filter, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }

  getUser(id) {
    return this.http.get(apiendpoints.USERS + '/' + id, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }

  addUser(data: any) {
    console.log(data);
    return this.http.post(
      apiendpoints.USERS,
      { user: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }

  removeRole(id) {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Token ` + localStorage.getItem('ACCESS_TOKEN'),
      }),
      body: {
        roleId: id,
      },
    };

    return this.http.delete(apiendpoints.ROLE, options);
  }

  getPermissions() {
    return this.http.get(apiendpoints.PERMISSIONS, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }

  getModules() {
    return this.http.get(apiendpoints.MODULE, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }

  getModule(id) {
    return this.http.get(apiendpoints.MODULE + '/' + id, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }

  addModule(data: any) {
    console.log(data);
    return this.http.post(
      apiendpoints.MODULE,
      { module: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }

  removeModule(id) {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Token ` + localStorage.getItem('ACCESS_TOKEN'),
      }),
      body: {
        moduleId: id,
      },
    };

    return this.http.delete(apiendpoints.MODULE, options);
  }

  getRoleModule(roleId, moduleId) {
    return this.http.get(
      apiendpoints.ROLEMODULE + '?role=' + roleId + '&module=' + moduleId,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }

  PasswordRecoveryByEmail(data) {
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', data);
    return this.http.post(apiendpoints.PASSWORD_RECOVERY_BY_EMAIL, data, {
      observe: 'response',
    });
  }
  SetNewPasswordUsingToken(data) {
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', data);
    return this.http.post(apiendpoints.SET_NEW_PASSWORD_USING_TOKEN, data, {
      observe: 'response',
    });
  }
  ValidatePasswordToken(data) {
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', data);
    return this.http.post(apiendpoints.VALIDATE_RESET_TOKEN, data, {
      observe: 'response',
    });
  }
  addRoleModule(data: any) {
    console.log(data);
    return this.http.post(
      apiendpoints.ROLEMODULE,
      { rolemodule: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }

  changePassword(data: any) {
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', data);
    return this.http.post(apiendpoints.CHANGE_PASSWORD, data, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { language } from '@shared/config/language';
import {KnowledgeHubService} from '@services/KnowledgeHub.service';
import {CoreService} from '@services/CoreService';


export interface CategoryElement {
  _id: string;
  category: string;
  created_at: string;
  updated_at: string;
}

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  resource = language;

  categories:CategoryElement[];

  constructor(private knowledgeHubService:KnowledgeHubService,
    private coreservice:CoreService) { }

  ngOnInit(): void {

    this.getAllCategories();

  }

  getAllCategories():void {
    this.knowledgeHubService.getKnwoledgeCategories().subscribe(response=> {
      this.categories=response.body['data'] as CategoryElement[]
            this.getAllKnowledge();
    });
  }

  addnewitem():void{

    let item = {
      _id: "",
      category: "",
      created_at: "",
      updated_at: ""
    }
    this.categories.push(item);

  }

  update(item):void{
     
    if(item._id == ""){
      delete item._id;
    }
 

    this.knowledgeHubService.addKnowledgCategory(item).subscribe(response=> {
      this.coreservice.success(this.resource.message('KNOWLEDGE_CATEGORIES_SAVE'));
      this.getAllCategories();
    });
  }

  remove(item):void {

    this.coreservice.openConfirmDialog(this.resource.message('KNOWLEDGE_CATEGORIES_DELETE'))
    .afterClosed().subscribe(res=>{
      if(res){
        this.knowledgeHubService.removeKnowledgeCategory(item._id).subscribe(response=> {
          const result = response;
          this.getAllCategories();
          this.coreservice.success(this.resource.message('KNOWLEDGE_CATEGORIES_DELETED'));

         }, (err) => {
    })

        
        
      }
    })


 
  }




  
 getAllKnowledge()
 {

  
   this.knowledgeHubService.getKnwoledgeFiles().subscribe(response=> {
     
     const result = response.body['data'];

     result.forEach(element => {
             element["child"] = 0;
            this.categories.forEach(catelement => {
                  if(element["category"] != null){
                     
                    if(catelement._id == element["category"]["_id"]){
                      catelement["child"] = 1;
                    }
                  }
                  
            })       
     });
     
    }, (err) => {

     
  })

  


  

  }

}

<div class="content p30">
  <div id="block_container">
    <ng-container [ngSwitch]="TEMPLATE">
      <a
        *ngSwitchCase="'1'"
        routerLink="/templatemanagement"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
      <a
        *ngSwitchCase="'0'"
        routerLink="/committee"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
    </ng-container>
    <div id="bloc2"><h5>Committee / Add</h5></div>
  </div>

  <mat-card class="">
    <mat-card-content>
      <app-stepper [isTemplate]="isTemplate" [currentStep]="2"></app-stepper>

      <br />

      <form class="form-inline">
        <div class="form-group mr-2">
          <h4 class="steph4">{{ steps[2] }}</h4>
        </div>
        <span class="example-spacer"></span>
        <div class="mr-1">
          <button
            mat-raised-button
            color="primary"
            class="addbutton"
            class="buttonfloatright"
            (click)="addMember('msg', 'yes', 'no')"
          >
            <i class="fas fa-plus"></i>&nbsp;&nbsp;ADD
            {{ resource.message("MEMBER") | uppercase }}
          </button>
          <input
            matInput
            [(ngModel)]="searchKey"
            placeholder="{{ resource.message('SEARCH_BY_MEMBER') }}"
            autocomplete="off"
            (keyup)="applyfilter()"
            name="searchKey"
            class="searchfloatright"
          />
        </div>
      </form>
      <p>
        <br />
        <mat-table
          #roleTable
          roleTable
          matTableExporter
          [dataSource]="listData"
          matSort
          class="mattable"
          #exporter="matTableExporter"
          [hiddenColumns]="[1, 2, 3, 4, 5]"
          id="print-section"
        >
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Name</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Type</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              this.constants.committee_member_types[element.type]
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Committee Role</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              this.constants.committee_member_roles[element.committee_role] ? 
              this.constants.committee_member_roles[element.committee_role] : 'Invitee'
            }}</mat-cell>
          </ng-container>

          <!-- 
            <ng-container matColumnDef="type" >
              <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
              <mat-cell *matCellDef="let element" > 
                <mat-select style="width: 200px;"  placeholder="Committee Type" [(ngModel)]="element.type"  (selectionChange)="element.type = $event.value" >
                  <mat-option *ngFor="let type of TYPE_LIST | keyvalue" [value]="type.key">{{ type.value }}</mat-option>
               </mat-select>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="role" >
              <mat-header-cell *matHeaderCellDef mat-sort-header>Committee Role</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-select  style="width: 200px;" placeholder="Committee Type"  [(ngModel)]="element.committee_role" [disabled]="element.type == 'permanent_invitee'"  (selectionChange)="element.committee_role = $event.value" >
                  <mat-option *ngFor="let type of ROLES_LIST | keyvalue" [value]="type.key">{{ type.value }}</mat-option>

               </mat-select>
              </mat-cell>
            </ng-container>
  -->

          <!-- System Column -->

          <!-- Action Column -->
          <ng-container matColumnDef="Action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button color="warn" (click)="onDelete(row)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 15, 20]"
          [pageSize]="5"
          showFirstLastButtons
        ></mat-paginator>
      </p>
    </mat-card-content>

    <mat-card-actions>
      <div class="form-group mr-2">
        <button
          mat-stroked-button
          type="button"
          class="wide"
          (click)="previous()"
        >
          PREVIOUS
        </button>
      </div>
      <div class="rightpanel" align="right">
        <button
          mat-raised-button
          color="primary"
          class="btn btn-primary login-btn wide"
          type="button"
          class="wide"
          (click)="next()"
          [disabled]="listData.data.length == 0"
        >
          Next
        </button>
        <button
          mat-raised-button
          color="clear"
          class="wide"
          [routerLink]="isTemplate ? '/templatemanagement' : '/committee'"
        >
          Cancel
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>

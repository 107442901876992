import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { apiendpoints } from '../config/apiendpoints';


@Injectable({
    providedIn:'root'
})

export class AchievementsService {

    constructor(private http: HttpClient){


    }

    getachievement(){ 
        return this.http.get(apiendpoints.ACHIEVEMENTS, {
            headers: new HttpHeaders()
            .set('Authorization', 'Token ' +localStorage.getItem('ACCESS_TOKEN')),
        });
    }

    getachievementuser(data:any)
    {
        return this.http.post(apiendpoints.ACHIEVEMENT_USER, {user:data},  {
            headers: new HttpHeaders()
              .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
            observe: 'response'
          });  
        
    }
    getsingleachievement(id:string)
    {
        return this.http.get(apiendpoints.ACHIEVEMENTS_BYID+'/'+id,  {
            headers: new HttpHeaders()
              .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
            observe: 'response'
          });  
        
    }
}
<div class="content p30">
  <h5>{{ resource.message("MODULES") }}</h5>

  <!--
    <mat-grid-list cols="2" rowHeight="300px">
      <mat-grid-tile colspan=2 >
        <mat-card class="example-card">
          <mat-card-header>            
            <mat-card-title><i class="fa fa-file-text-o"></i> Dashboard Tile </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
            </p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>  
  -->

  <mat-card class="">
    <mat-card-header>
      <mat-card-title> {{ resource.message("MODULES") }} </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="form-inline">
        <div class="form-group mr-2">
          <!--
          <button  mat-stroked-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
          class="filtersortborder">
            <i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;Filter
          </button>
          <mat-menu #menu="matMenu" class="menustyle">
            <span style="color:#b4b1b1">Filter By</span>
           <div class="menuitemstyle">
            <p> {{resource.message('MODULE')}}</p>
            <mat-checkbox class="mat-menu-item" (change)="filterData($event,'group',item.group)" (click)="stoppro($event)" *ngFor="let item of this.listData.data; index as i; first as isFirst"> {{item.group}} </mat-checkbox>
            <hr/>
            
            <button mat-raised-button color="primary" (click)="closeMyMenu()">Apply</button>
          </div>
          </mat-menu>
-->

          <button
            mat-stroked-button
            class="iconcss"
            (click)="convertToPDF('save')"
          >
            <i class="fa fa-file-pdf fa-lg"></i>
          </button>
          <button
            mat-stroked-button
            class="iconcss"
            (click)="exporter.exportTable('csv', { fileName: 'modules' })"
          >
            <i class="fa fa-file-excel fa-lg"></i>
          </button>
          <button
            mat-stroked-button
            class="iconcss"
            (click)="convertToPDF('print')"
          >
            <i class="fa fa-print fa-lg"></i>
          </button>
        </div>
        <span class="example-spacer"></span>
        <div class="mr-1">
          <button
            mat-raised-button
            color="primary"
            class="addbutton"
            class="buttonfloatright"
            (click)="addmodule()"
          >
            <i class="fas fa-plus"></i>&nbsp;&nbsp;ADD
            {{ resource.message("MODULE") | uppercase }}
          </button>
          <input
            matInput
            [(ngModel)]="searchKey"
            placeholder="{{ resource.message('SEARCH_BY_MODULE') }}"
            autocomplete="off"
            (keyup)="applyfilter()"
            (keydown.enter)="$event.preventDefault()"
            name="searchKey"
            class="searchfloatright"
          />
        </div>
      </form>
      <p>
        <br />
        <mat-table
          #moduleTable
          moduleTable
          matTableExporter
          [dataSource]="listData"
          matSort
          class="mattable"
          #exporter="matTableExporter"
          [hiddenColumns]="[2, 3, 4, 5]"
        >
          <!-- Name Column -->
          <ng-container matColumnDef="module">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Module</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.module }}</mat-cell>
          </ng-container>

          <!-- GROUP Column -->
          <ng-container matColumnDef="group">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Group</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.group }}</mat-cell>
          </ng-container>

          <!-- GROUP Column -->
          <ng-container matColumnDef="priority">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Priority</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              element.priority
            }}</mat-cell>
          </ng-container>

          <!-- System Column -->

          <!-- Action Column -->
          <ng-container matColumnDef="Action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button (click)="edit(row)">
                <mat-icon>mode_edit</mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="onDelete(row._id)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 15, 20]"
          [pageSize]="5"
          showFirstLastButtons
        ></mat-paginator>
      </p>
    </mat-card-content>
  </mat-card>
</div>

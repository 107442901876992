<div class="content p30">
  <div id="block_container">
    <ng-container [ngSwitch]="TEMPLATE">
      <a
        *ngSwitchCase="'1'"
        routerLink="/templatemanagement"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
      <a
        *ngSwitchCase="'0'"
        routerLink="/committee"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
    </ng-container>
    <div id="bloc2"><h5>Committee / Add</h5></div>
  </div>
  <form [formGroup]="form" (ngSubmit)="submit('final')">
    <mat-card class="">
      <mat-card-content>
        <app-stepper [isTemplate]="isTemplate" [currentStep]="1"></app-stepper>

        <br />
        <h4 class="steph4">{{ steps[1] }}</h4>

        <mat-grid-list cols="2" rowHeight="300px">
          <mat-grid-tile>
            <input type="hidden" formControlName="_id" />

            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Chairman's Role</mat-label>
              <textarea
                matInput
                rows="10"
                placeholder="Chairman's Role"
                formControlName="chairman_role"
              ></textarea>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Secretary's Role</mat-label>
              <textarea
                matInput
                rows="10"
                placeholder="Secretary's Role"
                formControlName="secretary_role"
              ></textarea>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>

      <mat-card-actions>
        <!-- <div class="form-group mr-4">
          <button
            mat-stroked-button
            type="button"
            class="wide"
            (click)="previous()"
          >
            PREVIOUS
          </button>
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn wide"
            type="button"
            [disabled]="form.invalid"
            (click)="submit('draft')"
          >
            Save As Draft
          </button>
        </div> -->
        <div class="row" >

          <div class="col col-md-6" align="left">
            <button
            mat-stroked-button
            type="button"
            class="wide mr-4"
            (click)="previous()"
          >
            PREVIOUS
          </button>
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn wide"
            type="button"
            [disabled]="form.invalid"
            (click)="submit('draft')"
          >
            Save As Draft
          </button>
              
          </div>
          <div class="col col-md-6" align="right">
            <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn wide"
            type="submit"
            [disabled]="form.invalid"
          >
            Next
          </button>
          <button mat-raised-button color="clear" class="wide"  [routerLink]="(isTemplate)?'/templatemanagement':'/committee'">Cancel</button>
              
          </div>


        </div>
      </mat-card-actions>
    </mat-card>
  </form>
</div>

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpParams,
} from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  getSettings() {
    return this.http.get(apiendpoints.SETTINGS, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }

  addSettings(data: any) {
    console.log(data);
    return this.http.post(
      apiendpoints.SETTINGS,
      { data: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }

  getDateFormat() {
    let format = 'DD-MMM-YYYY';
    this.getSettings().subscribe((res) => {
      console.log('res', res);
      const dateformat = res['body']['data'].find((item) => {
        return item.key === 'DATEFORMAT';
      });

      if (dateformat !== undefined) {
        console.log('dateformat.values  ', dateformat.values);

        format = dateformat.values;
      }
      localStorage.setItem('DATE_FORMAT', format);
    });
  }

  removeKnowledgeFile(id) {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Token ` + localStorage.getItem('ACCESS_TOKEN'),
      }),
      body: {
        knowledgebaseId: id,
      },
    };

    return this.http.delete(apiendpoints.KNOWLEDGEHUB, options);
  }

  getKnwoledgeCategories() {
    return this.http.get(apiendpoints.KNOWLEDGEHUBCATEGORY, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }

  getKnwoledgeCategory(id) {
    return this.http.get(apiendpoints.KNOWLEDGEHUBCATEGORY + '/' + id, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }

  addKnowledgCategory(data: any) {
    console.log(data);
    return this.http.post(
      apiendpoints.KNOWLEDGEHUBCATEGORY,
      { data: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }

  removeKnowledgeCategory(id) {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Token ` + localStorage.getItem('ACCESS_TOKEN'),
      }),
      body: {
        id: id,
      },
    };

    return this.http.delete(apiendpoints.KNOWLEDGEHUBCATEGORY, options);
  }
}

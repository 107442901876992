<mat-card class="main-card">
  <mat-card-content>
    <textarea class="textarea" rows="6" [(ngModel)]="outCome"></textarea>
    <div class="divfloat">
      <button
        mat-raised-button
        color="primary"
        class="btn btn-primary done-btn button-wider"
        type="button"
        (click)="doneClick()"
      >
        Save
      </button>
    </div>
    <div *ngIf="comments && comments.length>0">
      <br>
      <h4>All Outcomes</h4>
      <mat-list role="list" *ngFor="let comment of comments; let i=index;">
  
        <mat-list-item role="listitem">{{i+1}}. &nbsp;{{comment.comment}}</mat-list-item>
      </mat-list>
    </div>

  </mat-card-content>
</mat-card>

import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking
import { language } from '@shared/config/language';
import { CommonSpaceService } from '@services/CommonSpace.service';
import { DatePipe } from '@angular/common';
import { DialogcalendarComponent } from '@shared/components/dialogcalendar/dialogcalendar.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-viewcalendar',
  templateUrl: './viewcalendar.component.html',
  styleUrls: ['./viewcalendar.component.css']
})
export class ViewcalendarComponent implements OnInit {
  resource = language;
data=[];
  calendarOptions: CalendarOptions;
  pipe = new DatePipe('en-IN'); 
date=new Date();
  constructor(private commonSpaceService: CommonSpaceService,    private dialog :MatDialog, 
    ) { }

  ngOnInit(): void {
    setTimeout(() => {
      return  this.commonSpaceService.getBooking('').subscribe(response=> {
        const result = response.body['data']
        for (let i = 0; i < result.length; i++) {
          let latest_date=new Date(result[i].start_date);
          console.log("LATEST DATE",latest_date)
          this.data.push({ 'id':result[i]._id,'title': result[i].purpose, date: this.pipe.transform(latest_date, 'yyyy-MM-dd')})
        }
        console.log("DATA FOR CALENDAR",this.data)
       }, (err) => {
        if(err.error.status != null){
          }else{
          }
     })
    }, 2000); 
    setTimeout(() => {
      this.calendarOptions = {
     initialView: 'dayGridMonth',
     dateClick: this.handleDateClick.bind(this), // bind is important!
     events: this.data,
     eventClick:this.eventclicked.bind(this)
     };
   }, 3000);
   
  }

  handleDateClick(arg) {
    console.log('date click! ',arg.dateStr)
    const dialogRef = this.dialog.open(DialogcalendarComponent,{
      width:'50vw',
      panelClass:'confirm-dialog-container',
      disableClose:true,
      data:{
          message:arg.dateStr,
        
      }
  })
  dialogRef.afterClosed().subscribe(result => {
 //  this.initialLoad()
  });
  }
  eventclicked(eve)
  {
   // console.log("EVEN CLICKED",eve.event.id)
  }
  getBooking()
  {

    this.commonSpaceService.getBooking('').subscribe(response=> {
      const result = response.body['data']
      for (let i = 0; i < result.length; i++) {
        var data={ title: result[i].purpose, date: '2021-07-13'}
       this.calendarOptions.events[i]=data
        
      }
    
     }, (err) => {

        if(err.error.status != null){
//          this.errmessage = err.error.message;
        }else{
 //         this.errmessage = err.error.errors[0].msg;
        }
   })
}
eventClick(event){
}
}

<div class="content p30">
  <div id="block_container">
    <ng-container [ngSwitch]="TEMPLATE">
      <a
        *ngSwitchCase="'1'"
        routerLink="/templatemanagement"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
      <a
        *ngSwitchCase="'0'"
        routerLink="/committee"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
    </ng-container>
    <div id="bloc2"><h5>Committee / Add</h5></div>
  </div>
  <form [formGroup]="form" (ngSubmit)="submit('final')">
    <mat-card class="">
      <mat-card-content>
        <app-stepper
          [isTemplate]="isTemplate"
          [currentStep]="isTemplate ? 5 : 6"
        ></app-stepper>

        <br />
        <h4 class="steph4">{{ isTemplate ? steps[5] : steps[6] }}</h4>

        <mat-grid-list cols="1" rowHeight="250px">
          <mat-grid-tile>
            <input type="hidden" formControlName="_id" />

            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Code of Conduct</mat-label>
              <textarea
                matInput
                rows="10"
                placeholder="Code of Conduct"
                formControlName="code_of_conduct"
              ></textarea>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>

      <mat-card-actions>
        <!-- <div class="form-group mr-2">  
            
               <button mat-stroked-button type="button" class="wide" (click)="previous()">PREVIOUS</button>
               <button mat-raised-button color="primary" class="btn btn-primary login-btn wide" type="button" [disabled]="form.invalid" (click)="submit('draft')">Save As Draft</button>

          </div> -->
          <div class="row" >
            <div class="col col-md-6" align="left"> <button
              mat-stroked-button
              type="button"
              class="wide"
              (click)="previous()"
            >
              PREVIOUS
            </button>
            <button
              mat-raised-button
              color="primary"
              class="btn btn-primary login-btn wide"
              type="button"
              [disabled]="form.invalid"
              (click)="submit('draft')"
            >
              Save As Draft
            </button></div>
            <div class="col col-md-6" align="right">
              <button
              mat-raised-button
              color="primary"
              class="btn btn-primary login-btn wide"
              type="submit"
              [disabled]="form.invalid"
            >
              Publish
            </button>
            <button mat-raised-button color="clear" class="wide"  [routerLink]="(isTemplate)?'/templatemanagement':'/committee'">Cancel</button>
            </div>
         

        
        </div>
      </mat-card-actions>
    </mat-card>
  </form>
</div>

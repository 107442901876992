import { Component, OnInit, Input } from '@angular/core';
import { CoreService } from '@services/CoreService';
import { IdpService } from '@services/Idp.service';
import { language } from '@shared/config/language';

@Component({
  selector: 'app-newoutcome',
  templateUrl: './newoutcome.component.html',
  styleUrls: ['./newoutcome.component.css'],
})
export class NewoutcomeComponent implements OnInit {
  outCome: string;
  resource = language;
  @Input() parentid: String;
  @Input() agendaid: String;
  @Input() viewdata: Boolean;
  constructor(private idp: IdpService, private coreservice: CoreService) {}

  ngOnInit(): void {
    console.log(this.viewdata);
    if (this.viewdata === true) {
      this.getMeetings(this.parentid);
    }
  }

  doneClick() {
    this.idp
      .idpcommentpost(this.parentid, this.agendaid, this.outCome)
      .subscribe(
        (response: any) => {
          this.coreservice.success(this.resource.message('SAVED_SUCCCESSFULLY'));
        },
        (err) => {
          console.log('errrrrrrr', err);
        }
      );
  }
  getMeetings(id) {
    var filter = '';
    filter = '?filter[_id]=' + id; //+this.localstoragedata._id
    this.idp.idpMeetingGet(filter).subscribe(
      (response: any) => {
        console.log('FINAL::::', response.data[0].agendas);
        let outcomeData = response.data[0].agendas.find(
          (outcome) => outcome._id === this.agendaid
        );
        //  console.log(outcomeData.comments[0].comment);
        if (outcomeData.comments && outcomeData.comments.length)
          this.outCome = outcomeData.comments[0].comment;
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormArray,
} from '@angular/forms';
import { language } from '@shared/config/language';
import { FileService } from '@services/File.service';
import { BusinessInfoService } from '@services/BusinessInfo.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CoreService } from '@services/CoreService';
import { FamilyInfoService } from '@services/FamilyInfo.service';

@Component({
  selector: 'app-dialogbusinessmedia',
  templateUrl: './dialogbusinessmedia.component.html',
  styleUrls: ['./dialogbusinessmedia.component.css'],
})
export class DialogbusinessmediaComponent implements OnInit {
  photoform: FormGroup = new FormGroup({
    title: new FormControl('', Validators.required),
    file: new FormControl('', Validators.required),
    _id: new FormControl(''),
    business: new FormControl(''),
    type: new FormControl(''),
    url: new FormControl('', Validators.required),
    description: new FormControl(''),
    img: new FormControl(''),
  });
  resource = language;
  isSubmitingForm = false;
  selFile = new Object();
  selFileName = '';
  postform: FormGroup = new FormGroup({
    Photos: new FormArray([]),
  });
  constructor(
    private fileService: FileService,
    private businessinfoservice: BusinessInfoService,
    public dialogRef: MatDialogRef<DialogbusinessmediaComponent>,
    private coreservice: CoreService,
    @Inject(MAT_DIALOG_DATA) public data,
    private familyinfoservice: FamilyInfoService
  ) {}

  ngOnInit(): void {
    console.log('IN ELSE', this.data.index);
    if (this.data.index == -1) {
      this.photoform.patchValue({
        business: this.data.id,
      });
    } else {
      console.log('IN ELSE', this.data.index);
      this.photoform.patchValue({
        title: this.data.index.title,
        file: this.data.index.url,
        _id: this.data.index._id,
        business: this.data.index.business,
        type: this.data.index.type,
        url: this.data.index.url,
        description: this.data.index.description,
        img: this.data.index.img,
      });
    }
  }
  onFileChange(event) {
    this.selFile = (event.target as HTMLInputElement).files[0];
    this.selFileName = (event.target as HTMLInputElement).files[0].name;
  }

  async submitPhoto() {
    if (this.photoform.invalid) {
      return;
    }
    this.isSubmitingForm = true;
    var constdata = this.photoform.getRawValue();
    if (this.selFileName != '') {
      // delete previous image
      let temp = this.photoform.get('img').value
        ? this.photoform.get('img').value
        : this.photoform.get('url').value;
      let url = temp.split('?');

      this.fileService.deleteFile(url[1]).subscribe(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
      let fileurl = this.selFileName;
      var formData: any = new FormData();
      formData.append('file', this.selFile);
      fileurl = await this.fileService.uploadFile(formData, true, false);
      constdata['img'] = fileurl;
    }

    constdata['type'] = 'business';

    delete constdata['file'];
    if (this.data.index == -1) {
      delete constdata['_id'];
      // constdata['business']=constdata['_id']
    }
    this.familyinfoservice.addMedia(constdata).subscribe(
      (response) => {
        const result = response.body;
        this.isSubmitingForm = false;
        this.coreservice.success('Media saved');

        this.closeDialog();
      },
      (err) => {
        console.log(err);
        this.isSubmitingForm = false;
        this.coreservice.error('Something went wrong, please try again!');
        // this.form.addControl('_id', new FormControl(''));
      }
    );
  }
  closeDialog() {
    this.photoform.patchValue({
      title: '',
      file: '',
      _id: '',
      business: '',
      type: '',
      url: '',
      description: '',
      img: '',
    });
    this.dialogRef.close(false);
  }
}

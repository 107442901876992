import {Component, OnInit, ViewChild} from '@angular/core';
import {AchievementsService} from '@services/Achievements.service';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {language} from '@shared/config/language';
import {ActivatedRoute, Router} from '@angular/router';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {LevelsComponent} from '@modules/achievements/levels/levels.component';
import {MatDialog} from '@angular/material/dialog';

export interface AchievementsElement {
  member: string;
  grade: string;
  points: string;
  adherence: string;
  engagment: string;
}

@Component({
  selector: 'app-activitylogs',
  templateUrl: './activitylogs.component.html',
  styleUrls: ['./activitylogs.component.css'],
})
export class ActivitylogsComponent implements OnInit {
  displayedColumns: string[] = [
    'member_name',
    'level',
    'adherence_points',
    'engagement_points',
    'score',
  ];
  ELEMENT_DATA: AchievementsElement[];
  listData = new MatTableDataSource<AchievementsElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<AchievementsElement>(true, []);
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('ImageTable') table: MatTable<AchievementsElement>;
  businessid: string;
  editindex: string = '-1';
  resource = language;
  allData: string = '';
  searchKey: string;

  constructor(
    private achievementservice: AchievementsService,
    private router: Router,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.initialLoad();
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
  }

  initialLoad() {
    this.achievementservice.getachievement().subscribe(
      (response: any) => {
        console.log(response);

        response.data.forEach((obj) => {
          this.allData = this.allData + ',' + obj.user;
        });
        this.allData = this.allData.substring(1);

        this.achievementservice
          .getachievementuser(this.allData)
          .subscribe((result: any) => {
            response.data.map((i) => {
              const user = result.body.user.find((u) => {
                return u.user_id === i.user;
              });

              if (user !== undefined) {
                i.member_name = user.name + ' ' + user.lastname;
              } else {
                i.member_name = 'a';
              }
            });
            console.log('USER names', result);
            this.listData.data = response.data as AchievementsElement[];
            this.table.renderRows();
          });
      },
      (err) => {
      }
    );
  }

  getRecord(row) {
    this.router.navigate(['achievements/summary'], {
      queryParams: {id: row._id},
    });
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }

  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [
      ['Member Name', 'Grade', 'Adherence', 'Engagement', 'Total points'],
    ];
    this.listData.data.forEach((obj) => {
      let member_name = obj['user'] ? obj['user'] : '';
      let grade = obj['level'] ? obj['level'] : '';
      let adherence_points = obj['adherence_points']
        ? obj['adherence_points']
        : 0;
      let engagement_points = obj['engagement_points']
        ? obj['engagement_points']
        : 0;
      let total_points = obj['score'] ? obj['score'] : 0;

      let arr = [
        member_name,
        grade,
        adherence_points,
        engagement_points,
        total_points,
      ];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      rowPageBreak: 'avoid',
    });

    if (action === 'save') {
      doc.save('activity-logs.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      doc.output('dataurlnewwindow', {filename: 'activity-logs.pdf'});
    }
  }

  // tslint:disable-next-line:typedef
  openLevelModal() {
    this.dialog.open(LevelsComponent,{
      width: '100%'
    });
  }
}

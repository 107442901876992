
    <mat-card class="">
        <form [formGroup]="form" (ngSubmit)="submit()" >
        <mat-card-content>

           
                <mat-form-field appearance="outline">
                    <mat-label>Message</mat-label>
                    <textarea matInput placeholder="Ex. It makes me feel..." formControlName="message" rows="20"></textarea>

                  </mat-form-field>
            
        </mat-card-content>

        <mat-card-actions align="end">
            <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="submit" [disabled]="form.invalid">Save</button>
          </mat-card-actions>
</form>
      </mat-card>
  
import { Component, OnInit } from '@angular/core';
import { language } from '@shared/config/language';
import { ActivatedRoute, Router } from '@angular/router';
import { IdpService } from '@services/Idp.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DialogviewattendanceComponent } from '@shared/components/dialogviewattendance/dialogviewattendance.component';
import { DialoginviteesComponent } from '@shared/components/dialoginvitees/dialoginvitees.component';
import { CommitteeService } from '@services/Committee.service';

@Component({
  selector: 'app-addoutcome',
  templateUrl: './addoutcome.component.html',
  styleUrls: ['./addoutcome.component.css'],
})
export class AddoutcomeComponent implements OnInit {
  resource = language;
  topics: string[] = [];
  parentid: string;
  start_date: string;
  start_time: string;
  end_date: string;
  end_time: string;
  title: string;
  venue: string;
  showtab: boolean = false;
  agendaid: string;
  showView: Number;
  viewdata: boolean = false;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private idp: IdpService,
    private dialog: MatDialog,
    private committeeService: CommitteeService
  ) {}

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentid = data.id;
      }
    });
    this.getMeetings(this.parentid);
  }
  outcome_tab(topic, index) {
    this.showView = index;
    this.agendaid = topic._id;
    console.log('::::::::::::', topic);
    //this.router.navigate(['/outcometab']);
  }
  getMeetings(id) {
    var filter = '';
    filter = '?filter[_id]=' + id; //+this.localstoragedata._id
    this.idp.idpMeetingGet(filter).subscribe(
      (response: any) => {
        this.start_date = response.data[0].start_date;
        this.end_date = response.data[0].end_date;
        this.start_time = response.data[0].start_time;
        this.end_time = response.data[0].end_time;
        this.venue = response.data[0].venue;
        this.title = response.data[0].title;
        for (let i = 0; i < response.data[0].agendas.length; i++) {
          this.topics.push(response.data[0].agendas[i]);
          console.log(this.topics);
        }
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  onSubmit() {
    this.idp.updateIDPMeetStatus({status : 'added_resolution'}, this.parentid).subscribe(
      (response: any) => {
        console.log(response.data._id);

        this.router.navigate(['mom'], {
          queryParams: {
            id: this.parentid,
          },
        });
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
    
  }
  openparticipants(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialoginviteesComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        id: this.parentid
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}

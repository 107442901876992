<div align="right">
  <div>
    <mat-card class="cardclass">
      <mat-card-header>
        <mat-card-title> {{ resource.message("ADD_REMARKS") }} </mat-card-title>
        <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
      </mat-card-header>
      <mat-card-content
        ><br />
        <form [formGroup]="form" (ngSubmit)="submitRemark()">
          <mat-grid-list cols="1" rowHeight="80px" gutterSize="16px">
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Member/Facilitator</mat-label>
                <mat-select placeholder="Facilitator" formControlName="user">
                  <mat-option
                    *ngFor="let role of ADVISOR_LIST"
                    [value]="role.user_id + ''"
                    >{{ role.name }} {{ role.lastname }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Remark</mat-label>
                <input matInput placeholder="Remark" formControlName="remark" />
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-card-actions align="end">
            <button
              mat-raised-button
              color="primary"
              class="btn btn-primary login-btn button-wider"
              type="submit"
              [disabled]="!this.form.valid"
            >
              Save
            </button>
          </mat-card-actions>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="content p30"> 

       
 
    <div id="block_container">
        <a  routerLink="/achievements" id="bloc1" mat-flat-button class="mat-flat-button-transperent" >
            <span  class="material-icons">keyboard_backspace</span></a> 
          
        <div id="bloc2"><h5>{{resource.message('ACHIEVEMENTS')}} / {{resource.message('ACTIVITYLOGS')}} / {{userName}}</h5></div>
    </div>
    
    <form  >
    <mat-card class="">
        <div class="content1">
        <div class="row-wrap">
              <mat-card >
               <mat-card-content>
                   <p class="first-p">
                       <span class="first-span"><h3>22/30</h3></span>
                       <span class="second-span">Activities</span></p>
                  <p class="second-p">
                  Personal Tasks
                  </p>
                  <p class="third-p">22/10/2021 - 22/10/2021</p>
                </mat-card-content>
               
              </mat-card>

              <mat-card  >
                <mat-card-content>
                    <p class="first-p">
                        <span class="first-span"><h3>22/30</h3></span>
                        <span class="second-span">Activities</span></p>
                   <p class="second-p">Meeting Invites</p>
                   <p class="third-p">22/10/2021 - 22/10/2021</p>
                 </mat-card-content>
                
               </mat-card>

               <mat-card>
                <mat-card-content>
                    <p class="first-p">
                        <span class="first-span"><h3>22/30</h3></span>
                        <span class="second-span">Activities</span></p>
                   <p class="second-p">
                   Participation in Meetings
                   </p>
                   <p class="third-p">22/10/2021 - 22/10/2021</p>
                 </mat-card-content>
                
               </mat-card>

               <mat-card  >
                <mat-card-content>
                    <p class="first-p">
                        <span class="first-span"><h3>22/30</h3></span>
                        <span class="second-span">Activities</span></p>
                   <p class="second-p">
                   Others
                   </p>
                   <p class="third-p">22/10/2021 - 22/10/2021</p>
                 </mat-card-content>
                
               </mat-card>
          </div>
      </div>
      <mat-card-content>
       
      
       

         <div style="margin: 0 20px 0 20px;">
         <br />
          <h4>Logs</h4>
         <hr />
         <div align="center" *ngIf="ELEMENT_DATA.length == 0">{{resource.message('ACTIVITY_LOG_NOLOGS')}}</div>  
         <div  *ngFor="let logdata of ELEMENT_DATA; index as i; first as isFirst" > 

            <div class="log-1">
              <div class="logs-line">   
                <span class="logs-circle">
                  <div class="dotted-line">
                  <span class="small-circle-line"></span>
                  <span class="small-circle-line"></span>
                  <span class="small-circle-line"></span>
                  <span class="small-circle-line"></span>
                  <span class="small-circle-line"></span>
                  <span class="small-circle-line"></span>
                  <span class="small-circle-line"></span>
                </div>
              </span>
              </div>
          
              <div class="logs-content">  
                <h5> {{logdata.type}} </h5>
              </div>
            </div>


        </div>

        </div>
      </mat-card-content>



   
    </mat-card>
  </form>


  </div>

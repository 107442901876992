<mat-card class="">
  <mat-card-content>
    <form class="form-inline">
      <div class="form-group mr-2">
        <label *ngIf="showback">
          <a
            id="bloc1"
            mat-flat-button
            class="mat-flat-button-transperent"
            (click)="getAllCategories()"
            ><span class="material-icons">keyboard_backspace</span></a
          >Back</label
        >&nbsp;
        <label class="labelcss">{{ labelText }}</label>
      </div>
      <span class="example-spacer"></span>
      <div class="mr-1">
        <button
          [disabled]="!PERMISSION_ADD"
          mat-raised-button
          color="primary"
          class="addbutton"
          class="buttonfloatright"
          (click)="openDialog('yes', 'open', 'close')"
        >
          <i class="fas fa-plus"></i>&nbsp;&nbsp;ADD CATEGORY
        </button>
        <input
          matInput
          [(ngModel)]="searchKey"
          placeholder="search"
          autocomplete="off"
          (keyup)="applyfilter()"
          name="searchKey"
          class="searchfloatright"
        />
      </div>
    </form>
    <br />
    <p>
      <mat-table #idpTable1 [dataSource]="listData" matSort class="mattable">
        <ng-container matColumnDef="category_type">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="customTableHeaderStyle"  style="justify-content: left;"
            >Category Type</mat-header-cell
          >
          <mat-cell *matCellDef="let element" style="justify-content: left;"
            >{{ element.parent == undefined ? "Category" : "Sub-Category" }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="customTableHeaderStyle"  style="justify-content: left;"
            >Category Name</mat-header-cell
          >
          <mat-cell *matCellDef="let element" style="justify-content: left;">{{ element.title }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Action" >
          <mat-header-cell *matHeaderCellDef class="customTableHeaderStyle"  style="justify-content: center;">Action</mat-header-cell>
          <mat-cell *matCellDef="let row" style="justify-content: left;">
            <button
              mat-icon-button
              (click)="editRow(row)"
              [disabled]="!PERMISSION_EDIT"
            >
              <mat-icon>mode_edit</mat-icon>
            </button>
            <!-- <button  mat-stroked-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                  class="filtersortborder">
                    <i class="fa fa-sort-down"></i>
                  </button>-->
            <button [matMenuTriggerFor]="menu" class="dropmenu" style="background-color: transparent">
              <mat-icon class="dropicon" style="color: gray">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="menustyle">
              <div class="menuitemstyle">
                <button
                  mat-menu-item
                  (click)="redirectTo(row)"
                  *ngIf="row.parent == undefined"
                >
                  View Sub-categories
                </button>
                <button
                  mat-menu-item
                  [disabled]="row.hasSubCat"
                  (click)="deleteCategory(row)"
                  *ngIf="row.parent == undefined"
                >
                  Delete Category
                </button>
                <button
                  mat-menu-item
                  (click)="deleteCategory(row)"
                  *ngIf="row.parent != undefined"
                >
                  Delete Sub-Category
                </button>
              </div>
            </mat-menu>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 15, 20]"
        [pageSize]="5"
        showFirstLastButtons
      ></mat-paginator>
    </p>
  </mat-card-content>
</mat-card>

<mat-card class="">
  <mat-card-content>
    <form class="form-inline">
      <div class="form-group mr-2">
        <!---<button  mat-stroked-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" class="filtersortborder">
                <i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;Filter
            </button>
            <mat-menu #menu="matMenu" class="menustyle">
                <span style="color:#b4b1b1">Filter By</span>
                <div class="menuitemstyle">
                    <p>Role</p>
                    <mat-checkbox class="mat-menu-item"> Member </mat-checkbox>
                    <mat-checkbox class="mat-menu-item" > Advisor</mat-checkbox>  
                    <hr class="hrclass" />
                    <p>Status</p>           
                    <mat-checkbox class="mat-menu-item" >Active</mat-checkbox>
                    <mat-checkbox class="mat-menu-item" >Inactive</mat-checkbox>  
                    <hr/>
                    <button mat-raised-button color="primary" >Apply</button>
                </div>
            </mat-menu>-->
        <button
          mat-stroked-button
          class="iconcss"
          (click)="convertToPDF('save')"
        >
          <i class="fa fa-file-pdf fa-lg"></i>
        </button>
        <button
          (click)="exporter.exportTable('csv', { fileName: 'business-units' })"
          mat-stroked-button
          class="iconcss"
        >
          <i class="fa fa-file-excel fa-lg"></i>
        </button>
        <button
          (click)="convertToPDF('print')"
          mat-stroked-button
          class="iconcss"
        >
          <i class="fa fa-print fa-lg"></i>
        </button>
      </div>
      <span class="example-spacer"></span>
      <div class="mr-1">
        <button
          mat-raised-button
          color="primary"
          class="addbutton"
          class="buttonfloatright"
          (click)="addUser()"
          [disabled]="!PERMISSION_ADD"
        >
          <i class="fas fa-plus"></i>&nbsp;&nbsp;ADD NEW
        </button>
        <input
          matInput
          placeholder="Search by Business Name"
          autocomplete="off"
          name="searchKey"
          class="searchfloatright"
          [(ngModel)]="searchKey"
          (keyup)="applyfilter()"
        />
      </div>
    </form>
    <p>
      <br />
      <mat-table
        #BusinessTable
        userTable
        matTableExporter
        [dataSource]="listData"
        matSort
        class="mattable"
        #exporter="matTableExporter"
        [hiddenColumns]="[4]"
      >
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Business Unit Name</mat-header-cell
          >
          <mat-cell *matCellDef="let element"
            >{{ element.name }}</mat-cell
          >
        </ng-container>
        <ng-container matColumnDef="mobile1">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Phone No.</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.mobile1 }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="keyperson">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Key Person's Name</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.keyperson }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="website">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Website</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.website }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button
              mat-icon-button
              (click)="edit(row)"
              [disabled]="!PERMISSION_EDIT"
            >
              <mat-icon>mode_edit</mat-icon>
            </button>
            <button
              mat-icon-button
              color="warn"
              (click)="onDelete(row._id)"
              [disabled]="!PERMISSION_DELETE"
            >
              <mat-icon>delete_outline</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 15, 20]"
        [pageSize]="5"
        showFirstLastButtons
      ></mat-paginator>
    </p>
  </mat-card-content>
</mat-card>

import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { language } from '@shared/config/language';
import { constants } from '@shared/config/constants';
import { OtherMeetingService } from '@services/OtherMeeting.service';
import { IdpService } from '@services/Idp.service';
import { CommitteeService } from '@services/Committee.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '@services/CoreService';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-editgoal',
  templateUrl: './editgoal.component.html',
  styleUrls: ['./editgoal.component.css'],
})
export class EditgoalComponent implements OnInit {
  labelText: string = 'Edit Goal';
  resource = language;
  resultId='';
  form: FormGroup = new FormGroup({
    category: new FormControl('', Validators.required),
    subcategory: new FormControl('', Validators.required),
    goal_name: this.fb.array([this.createGoalItem()]),
    supporters: new FormControl(''),
    todolist: new FormControl(''),
    tododate: new FormControl(''),
    user: new FormControl(''),
    category_map: new FormControl(''),
    subcategory_map: new FormControl(''),
  });
  parentid: string;

  CONSTANT = constants;
  SUPPORTERS: any;
  isEdit: boolean = false;
  CATEGORY: any;
  SUB_CATEGORY: any;
  showsub: boolean = false;

  constructor(
    private fb: FormBuilder,
    private othermeetinginfoservice: OtherMeetingService,
    private idp: IdpService,
    private committeeService: CommitteeService,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private router: Router,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getAllUsers();
    this.getAllCategories();
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentid = data.id;
        var filter = '';
        this.isEdit = true;
        filter = '?filter[_id]=' + data.id;
        this.idp.useridpget(filter).subscribe((response: any) => {
          const result = response.data[0];
          console.log('EDIT DATA', response.data);
          this.resultId = result._id;
           this.form.patchValue({
              user: result.user._id,
            });
          /*
          const categoryData = this.CATEGORY.find(
            (cat) => cat.title === response.data[0].category
          );
          const subcategoryData = this.SUB_CATEGORY.find(
            (cat) => cat._id === response.data[0].subcategory
          );*/
          this.subcategoryGet(response.data[0].category_map);
          this.form.patchValue({
            category: response.data[0].category_map,
            subcategory: response.data[0].subcategory_map,
          });

          for (let i = 0; i < response.data.length; i++) {
            console.log(response.data[i].supporters.map(val=> val._id))
            const supporters = response.data[i].supporters.map(val=> val._id);
            this.goal_name.push(
              this.fb.group({
                goal: [response.data[i].description, Validators.required],
                goal_date: [this.datepipe.transform(
                  response.data[i].created_at,
                  constants.YYYY_MM_DD_DATE_FORMAT
                ), Validators.required],
                supporters: new FormControl([...supporters], Validators.required),
              })
            );
          }
          console.log(this.goal_name)
          if (this.goal_name.length > 1) {
            this.goal_name.removeAt(0);
          }
        });
      }
    });
  }
  createGoalItem(): FormGroup {
    return this.fb.group({ goal: ['', Validators.required], goal_date: ['', Validators.required], supporters: ['', Validators.required] });
  }
  get goal_name() {
    return this.form.get('goal_name') as FormArray;
  }
  addSocial() {
    this.goal_name.push(
     this.fb.group({ goal: ['', Validators.required], goal_date: ['', Validators.required], supporters: ['', Validators.required] })
    );
  }

  deleteSocial(index) {
    this.goal_name.removeAt(index);
  }
  getAllUsers() {
    var filter = '';
    this.othermeetinginfoservice.getFamilyUsers(filter).subscribe(
      (response) => {
        this.SUPPORTERS = response.body['data'];
      },
      (err) => {
        if (err.error.status != null) {
        } else {
        }
      }
    );
  }
  submit(): void {
    if (this.form.invalid) {
      return;
    }
   
    const constdata = this.form.getRawValue();

    // constdata.goal_name.length;
    if (!this.isEdit) {
      //    this.form.removeControl('_id');
    }
    if (constdata.goal_name.length > 0) {
      const categoryName = this.CATEGORY.find(
        (catid) => catid._id == constdata['category']
      );
      let subcategoryname;
      if (this.showsub) {
        subcategoryname = this.SUB_CATEGORY.find(
          (subcatid) => subcatid._id == constdata['subcategory']
        );
      }
      console.log('CONST:::', constdata);
      console.log('LENGTH:::', constdata.goal_name.length);
      for (var i = 0; i < constdata.goal_name.length; i++) {
        let submitdata = constdata;

        console.log('CAT NAME:::', categoryName);
        submitdata['user'] = constdata.user;
        submitdata['category_map'] = constdata.category;
        submitdata['category'] = categoryName.title;
        if (subcategoryname != undefined) {
          submitdata['subcategory_map'] = constdata.subcategory;
          submitdata['subcategory'] = subcategoryname.title;
        } else {
          delete submitdata['subcategory'];
          delete submitdata['subcategory_map'];
        }
        submitdata['description'] = constdata.goal_name[i].goal;
        submitdata['supporters'] = constdata.goal_name[i].supporters;
        this.idp.useridpUpdate(submitdata, this.resultId).subscribe(
          (response) => {
            const result = response;
            this.coreservice.success(this.resource.message('GOAL_ADD'));
            this.router.navigate(['idp']);
          },
          (err) => {
            //        this.form.addControl('_id', new FormControl(''));
          }
        );
      }
    }
    //  this.router.
  }
  getAllCategories() {
    this.committeeService.getCommitteeCategories('').subscribe(
      (response) => {
        const result = response.body;
        console.log(response.body['data']);
        this.CATEGORY = response.body['data'].filter(
          (catid) => catid.parent == undefined
        );
      },
      (err) => {}
    );
  }
  subcategoryGet(catid) {
    this.SUB_CATEGORY = []
    this.form.controls.subcategory.disable();
    this.showsub = false;
    this.form.patchValue({subcategory: ''})
    this.committeeService.subCategoryGet(catid).subscribe(
      (response: any) => {
        this.SUB_CATEGORY = response['data'];
        if (response.data && response['data'] != '' && response.data.length) {
          console.log(this.SUB_CATEGORY);
          this.form.controls.subcategory.enable();
          this.showsub = true;
        } else {
          this.showsub = false;
        }
      },
      (err) => {}
    );
  }
}

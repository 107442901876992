import { Component, OnInit } from '@angular/core';
import {constants} from '@shared/config/constants'; 
import { language } from '@shared/config/language';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  constant = constants;
  CORE_FAMILY = constants.CORE_FAMILY_NAME;
  localstoragedata:any;
  family_name:string=''
  resource = language;
  constructor() {
    this.localstoragedata=JSON.parse(localStorage.getItem('LOGGED_USER'));
    if(this.localstoragedata?.family!=null) this.family_name=this.localstoragedata.family.name
   }

  ngOnInit(): void {
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>",this.constant.getConstant('DATE_FORMAT'))
  }

}

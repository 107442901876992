import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';


@Injectable({
    providedIn:'root'
})

export class KnowledgeHubService {

    constructor(private http: HttpClient){


    }

    getKnwoledgeFiles(){

        return this.http.get(apiendpoints.KNOWLEDGEHUB,
            {
                headers: new HttpHeaders()
                  .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
                observe: 'response'
              }
            );      
    }

    getKnwoledgeFile(id){


      return this.http.get(apiendpoints.KNOWLEDGEHUB+"/"+id,
          {
              headers: new HttpHeaders()
                .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
              observe: 'response'
            }
          );      
    }

    addKnowledgFile(data:any){
      console.log(data);
      return this.http.post(apiendpoints.KNOWLEDGEHUB, {data:data},  {
        headers: new HttpHeaders()
          .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
        observe: 'response'
      });      
    }

    removeKnowledgeFile(id){
    
      const options = {
        headers: new HttpHeaders({
          'Authorization': `Token `+localStorage.getItem('ACCESS_TOKEN')
        }),
        body: {
          id: id,
        },        
      };
      
      return this.http.delete(apiendpoints.KNOWLEDGEHUB+"/"+id, options);
    }



     getKnwoledgeCategories(){

      return this.http.get(apiendpoints.KNOWLEDGEHUBCATEGORY,
          {
              headers: new HttpHeaders()
                .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
              observe: 'response'
            }
          );      
  }

  getKnwoledgeCategory(id){


    return this.http.get(apiendpoints.KNOWLEDGEHUBCATEGORY+"/"+id,
        {
            headers: new HttpHeaders()
              .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
            observe: 'response'
          }
        );      
  }

  addKnowledgCategory(data:any){
    console.log(data);
    return this.http.post(apiendpoints.KNOWLEDGEHUBCATEGORY, {data:data},  {
      headers: new HttpHeaders()
        .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
      observe: 'response'
    });      
  }

  removeKnowledgeCategory(id){
  
    const options = {
      headers: new HttpHeaders({
        'Authorization': `Token `+localStorage.getItem('ACCESS_TOKEN')
      }),
      body: {
        id: id,
      },        
    };
    
    return this.http.delete(apiendpoints.KNOWLEDGEHUBCATEGORY, options);
  }


}

import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators} from '@angular/forms';
import { UACService } from '@services/UAC.service';
import { SecurityLogsService } from '@services/SecurityLogs.service';
import { ActivatedRoute, Router } from '@angular/router';
import {CoreService} from '../../../shared/services/CoreService';
import * as moment from 'moment';
import { language } from '@shared/config/language';
import { constants } from '@shared/config/constants';
import {AchievementsService} from '@services/Achievements.service';
import { FamilyInfoService } from '@services/FamilyInfo.service';
export interface LogElement {
  _id: string;
  user: {
    name:"",
    lastname:"",
    family: {
      name:""
    }
  };
  logdate: string;
  ipaddress: string;
  device: string;
  created_at: string;
  updated_at: string;
}

@Component({
  selector: 'app-triggerpoints',
  templateUrl: './triggerpoints.component.html',
  styleUrls: ['./triggerpoints.component.css']
})
export class TriggerpointsComponent implements OnInit {

  resource = language;


  isEdit:Boolean = false;
   
  ELEMENT_DATA: LogElement[];
  
  userName:string;

  constructor(private uacservice: UACService, private router : Router,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private securityLogsService:SecurityLogsService,
    private singleAchievement : AchievementsService,
    private singleUser: FamilyInfoService
   
    ) {

    this.activeRoute.queryParams.subscribe(data => {

      if(data.id!=undefined)
      {
        this.getSingleachievement(data.id)
        /*this.securityLogsService.getSecurityLogs(data.id).subscribe(response=> {
      
          this.ELEMENT_DATA = response.body["logs"] as LogElement[];

          this.ELEMENT_DATA.forEach(element => {
            element.logdate = moment(element.logdate).utcOffset(element.logdate).format('MM/DD/YYYY HH:mm:ss')

          });

          console.log(this.ELEMENT_DATA)
        });

        this.familiesServer.getFamily(data.family).subscribe(response=>{
            this.FAMILY  =  response.body["family"];
        })*/


      } else{
        this.router.navigate(['security']);
      }
    })

   }

  ngOnInit(): void {

    this.ELEMENT_DATA = [];

  }

  getSingleachievement(id)
   {
     this.singleAchievement.getsingleachievement(id).subscribe((response:any)=>{
       console.log("USER NAME",response.body)
       this.ELEMENT_DATA = response.body.data[0].goals_achieved as LogElement[];

       this.singleUser.singleuserget(response.body.data[0].user).subscribe((result:any)=>
       {
         this.userName=result.data.name+' '+result.data.lastname 
          console.log("USER DATA",result.data.name)
       })
     })
   }
 
 
}

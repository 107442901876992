import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { UACService } from '@services/UAC.service';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import { CoreService } from '@services/CoreService';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import { AuthService } from '@services/Auth.service';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export interface RoleElement {
  roleId: number;
  role: string;
  family: string;
  familyname: string;
  created: string;
  updated: string;
}

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css'],
})
export class RoleComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;

  ELEMENT_DATA: RoleElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = ['role', 'familyname', 'Action'];
  listData = new MatTableDataSource<RoleElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<RoleElement>(true, []);

  PERMISSION_EDIT = AuthService.authentication('Roles', 'READ');
  PERMISSION_ADD = AuthService.authentication('Roles', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Roles', 'DELETE');

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<RoleElement>;

  resource = language;

  constructor(
    private uacservice: UACService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private http: HttpClient,
    private coreservice: CoreService
  ) {
    AuthService.authenticate_redir('Users', ['READ']);

    this.listData = new MatTableDataSource<RoleElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<RoleElement>(true, []);

    console.log(language.message('a'));
  }

  ngOnInit(): void {
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllRoles();
    this.table.renderRows();
  }

  getAllRoles() {
    this.uacservice.getRoles().subscribe(
      (response) => {
        const result = response.body;

        (result['results'] as RoleElement[]).forEach(function (value) {
          value['familyname'] = value['family']['name'];
        });

        this.listData.data = response.body['results'] as RoleElement[];

        this.table.renderRows();
      },
      (err) => {
        if (err.error.status != null) {
          //          this.errmessage = err.error.message;
        } else {
          //         this.errmessage = err.error.errors[0].msg;
        }
      }
    );
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listData.data.forEach((row) => this.selection.select(row));
  }

  addrole() {
    this.router.navigate(['/role/add']);
  }

  edit(role, check) {
    if(check) {
      this.coreservice.error(this.resource.message('ROLE_NOT_EDIT'));
      return;
    }
    this.router.navigate(['role/add'], { queryParams: { id: role._id } });
  }

  onDelete(id, check) {
    if(check) {
      this.coreservice.error(this.resource.message('ROLE_NOT_DELETE'));
      return;
    }
    this.coreservice
      .openConfirmDialog(this.resource.message('ROLE_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.uacservice.removeRole(id).subscribe(
            (response) => {
              const result = response;
              this.getAllRoles();
              this.coreservice.success(this.resource.message('ROLE_DELETED'));
            },
            (err) => {}
          );
        }
      });
  }

  closeMyMenu() {
    this.trigger.closeMenu();
  }

  filterData(ev, col, val) {
    if (ev.checked) {
      col = String(col);

      let value = { [col]: val };
      this.filterValues.push(value);
    } else {
      let value = { [col]: val };
      const index = this.filterValues.indexOf(value);
      this.filterValues.splice(index - 1, 1);
    }

    this.setupFilter(col);
    this.applyFilter(val);
  }

  stoppro($event) {
    $event.stopPropagation();
  }

  setupFilter(column: string) {
    this.listData.filterPredicate = (d: any, filter: string) => {
      const val = JSON.parse(filter);
      var flag = false;

      val.forEach(function (item) {
        let obj = item;
        const textToSearch = (d[column] && d[column].toLowerCase()) || '';
        if (obj[column] != undefined) {
          if (
            textToSearch.toLowerCase().indexOf(obj[column].toLowerCase()) !== -1
          ) {
            flag = true;
          }
        }
      });
      return flag;
    };
  }

  applyFilter(filterValue: string) {
    this.listData.filter = JSON.stringify(this.filterValues); // filterValue.trim().toLowerCase();
  }

  filterValues = [];

  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [['Role', 'Family']];
    this.listData.data.forEach((obj) => {
      let arr = [obj['role'], obj['familyname']];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      rowPageBreak: 'avoid',
    });
    if (action === 'save') {
      doc.save('roles.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      // window.open(doc.output('bloburl') as unknown as string, '_blank');
      doc.output('dataurlnewwindow', { filename: 'roles.pdf' });
    }
  }

  /*print(){
    let doc = new jsPDF(); 
    doc.autoTable({
      head: [['NAme','approved','utilised', 'available','asd','sadadasada','asdas']],
      body: this.dataSource.filteredData //returning [["log1", "$100"], ["log2", "$200"]]
    });
    doc.save('table.pdf')
  }
*/
}

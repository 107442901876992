<div class="content p30">
  <h5>{{ resource.message("DECLARATIONS") }}</h5>

  <!--
    <mat-grid-list cols="2" rowHeight="300px">
      <mat-grid-tile colspan=2 >
        <mat-card class="example-card">
          <mat-card-header>            
            <mat-card-title><i class="fa fa-file-text-o"></i> Dashboard Tile </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
            </p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>  
  -->

  <mat-card class="">
    <mat-card-header>
      <mat-card-title> {{ resource.message("DECLARATIONS") }} </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="form-inline">
        <div class="form-group mr-2">
          <button
            mat-stroked-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
            class="filtersortborder"
          >
            <i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;Filter
          </button>
          <mat-menu #menu="matMenu" class="menustyle">
            <span (click)="$event.stopPropagation()" style="color: #b4b1b1"
              >Filter By</span
            >
            <div (click)="$event.stopPropagation()" class="menuitemstyle">
              <p>Category</p>
              <!-- <mat-checkbox class="mat-menu-item" (change)="filterData($event,'role',item)" (click)="stoppro($event)" *ngFor="let item of ROLES_LIST; index as i; first as isFirst"> {{item.role}}  </mat-checkbox> -->
              <!-- <mat-checkbox class="mat-menu-item" (change)="filterData($event,'role',item)" (click)="stoppro($event)" *ngFor="let item of ROLES_LIST; index as i; first as isFirst"> {{item.role}}  </mat-checkbox> -->
              <mat-checkbox
                class="mat-menu-item"
                (change)="filterData($event, 'category', item)"
                *ngFor="
                  let item of CONSTANT.declaration_category;
                  index as i;
                  first as isFirst
                "
              >
                {{ item }}
              </mat-checkbox>

              <button
                (click)="$event.stopPropagation()"
                type="submit"
                mat-raised-button
                color="primary"
              >
                Apply
              </button>
            </div>
          </mat-menu>
          <button
            mat-stroked-button
            class="iconcss"
            (click)="convertToPDF('save')"
          >
            <i class="fa fa-file-pdf fa-lg"></i>
          </button>
          <button
            mat-stroked-button
            class="iconcss"
            (click)="exporter.exportTable('csv', { fileName: 'declaration' })"
          >
            <i class="fa fa-file-excel fa-lg"></i>
          </button>
          <button
            mat-stroked-button
            class="iconcss"
            (click)="convertToPDF('print')"
          >
            <i class="fa fa-print fa-lg"></i>
          </button>
        </div>
        <span class="example-spacer"></span>
        <div class="mr-1">
          <button
            [disabled]="!PERMISSION_ADD"
            mat-raised-button
            color="primary"
            class="addbutton"
            class="buttonfloatright"
            (click)="addrole()"
          >
            <i class="fas fa-plus"></i>&nbsp;&nbsp;ADD
            {{ resource.message("DECLARATION") | uppercase }}
          </button>
          <input
            matInput
            [(ngModel)]="searchKey"
            placeholder="{{ resource.message('SEARCH_BY_DECLARATION') }}"
            autocomplete="off"
            (keyup)="applyfilter()"
            name="searchKey"
            class="searchfloatright"
          />
        </div>
      </form>
      <p>
        <br />
        <mat-table
          #roleTable
          roleTable
          matTableExporter
          [dataSource]="listData"
          matSort
          class="mattable"
          #exporter="matTableExporter"
          [hiddenColumns]="[3]"
          id="print-section"
        >
          <!-- Name Column -->
          <ng-container matColumnDef="fullname">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Profile Name</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              element.user != null ? element.fullname : ""
            }}</mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Created On</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              element.created_at | customDate
            }}</mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="category">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Category</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              element.category
            }}</mat-cell>
          </ng-container>

          <!-- System Column -->

          <!-- Action Column -->
          <ng-container matColumnDef="Action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button
                mat-icon-button
                [disabled]="!PERMISSION_EDIT"
                (click)="edit(row)"
              >
                <mat-icon>mode_edit</mat-icon>
              </button>
              <button
                mat-icon-button
                [disabled]="!PERMISSION_DELETE"
                color="warn"
                (click)="onDelete(row._id)"
              >
                <mat-icon>delete_outline</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 15, 20]"
          [pageSize]="5"
          showFirstLastButtons
        ></mat-paginator>
      </p>
    </mat-card-content>
  </mat-card>
</div>

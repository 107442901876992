import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommitteeService } from '@services/Committee.service';
import { constants } from '@shared/config/constants';
import { language } from '@shared/config/language';
import { CoreService } from '@services/CoreService';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-committee-s4',
  templateUrl: './committee-s4.component.html',
  styleUrls: ['./committee-s4.component.css'],
})
export class CommitteeS4Component implements OnInit {
  resource = language;
  steps = [];
  isTemplate: boolean = false;

  TYPE_LIST: any;
  CATEGORY_LIST: any;

  COMMITTEE_LIST: any;
  SUBCOMMITTEE_LIST: any;

  isEdit: Boolean = false;

  CURRENT_COMMITTEE = constants.CURRENT_COMMITTEE;

  COMMITEE_ID = '';
  TEMPLATE = 0;

  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    chairman_rules: new FormControl('', Validators.required),
    member_rules: new FormControl('', Validators.required),
    secretary_rules: new FormControl('', Validators.required),
    appointment_of_members: new FormControl(''),
    removal_of_members: new FormControl('', Validators.required),
  });

  constructor(
    private committeeService: CommitteeService,
    private coreservice: CoreService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.template != undefined) {
        this.TEMPLATE = data.template;
        if (this.TEMPLATE == 1) this.isTemplate = true;
        else this.isTemplate = false;
      }

      if (this.isTemplate) {
        this.steps = constants.committee_template_steps;
      } else {
        this.steps = constants.committee_steps;
      }

      if (data.id != undefined) {
        this.form.patchValue({ _id: data.id });
        this.COMMITEE_ID = data.id;
        this.committeeService
          .getCommittee(this.COMMITEE_ID)
          .subscribe((response) => {
            const result = response.body;
            this.CURRENT_COMMITTEE = result['data'];

            this.form.patchValue({
              _id: this.CURRENT_COMMITTEE['_id'],
              chairman_rules: this.CURRENT_COMMITTEE['chairman_rules'],
              member_rules: this.CURRENT_COMMITTEE['member_rules'],
              secretary_rules: this.CURRENT_COMMITTEE['secretary_rules'],
              appointment_of_members:
                this.CURRENT_COMMITTEE['appointment_of_members'],
              removal_of_members: this.CURRENT_COMMITTEE['removal_of_members'],
            });
          });
      } else {
        this.router.navigate(['committee']);
      }
    });
  }

  submit(type): void {
    console.log(this.form);
    if (this.form.invalid) {
      return;
    }

    var rawcommittee = this.form.getRawValue();

    this.committeeService.addCommittee(rawcommittee).subscribe(
      (response) => {
        const result = response.body;

        constants.CURRENT_COMMITTEE = result['data'];
        if (this.isTemplate) {
          if (type == 'final') {
            this.router.navigate(['committee/add/s5'], {
              queryParams: {
                id: constants.CURRENT_COMMITTEE['_id'],
                template: this.TEMPLATE,
              },
            });
          } else {
            this.coreservice.success('Committee saved as draft');

            this.router.navigate(['/templatemanagement']);
          }
        } else {
          if (type == 'final') {
            this.router.navigate(['committee/add/s5'], {
              queryParams: {
                id: constants.CURRENT_COMMITTEE['_id'],
                template: this.TEMPLATE,
              },
            });
          } else {
            this.coreservice.success('Committee saved as draft');

            this.router.navigate(['/committee']);
          }
        }
      },
      (err) => {
        console.log(err);
        //      var errormessages = "Please correct the errors - \n";
        //      err.error.errors.forEach(element => errormessages += " - "+element.msg+"\n");
        this.coreservice.error('Invalid Committee - ' + err.error.message);
        //  this.form.addControl('_id', new FormControl(''));
      }
    );
  }

  getCommittees(type, committee) {
    console.log(type);

    this.committeeService
      .getCommitteesFilter(type, committee)
      .subscribe((response) => {
        if (type == 'committee') {
          this.COMMITTEE_LIST = response.body['data'];
        } else if (type == 'subcommittee') {
          this.SUBCOMMITTEE_LIST = response.body['data'];
          console.log(this.SUBCOMMITTEE_LIST);
        }
      });
  }

  previous() {
    if (this.TEMPLATE == 0) {
      this.router.navigate(['committee/add/s3'], {
        queryParams: {
          id: constants.CURRENT_COMMITTEE['_id'],
          template: this.TEMPLATE,
        },
      });
    } else {
      this.router.navigate(['committee/add/s2'], {
        queryParams: {
          id: constants.CURRENT_COMMITTEE['_id'],
          template: this.TEMPLATE,
        },
      });
    }
    //  this.router.navigate(['committee/add/s3'], { queryParams: { id:this.COMMITEE_ID, template: this.TEMPLATE} });
  }
}

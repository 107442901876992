 <div class="content p30"> 
      <h5 class="bold-text">{{resource.message('BUSINESSINFO')}}</h5>
         <mat-card class="">
            <mat-card-header>            
               <mat-card-title > {{resource.message('BUSINESSINFOTITLE')}} </mat-card-title>
               
            </mat-card-header>
            <mat-card-content>
               <mat-tab-group class="tabgroup" [(selectedIndex)]="selectedTab">
                <!--Parent Company-->
                <mat-tab label="Parent Company" class="tabcolor">
              <app-parentcompany></app-parentcompany>
                </mat-tab>
            
                <!--Business Unit-->
                <mat-tab label="Business Unit" class="tabcolor">
                   <app-businessunit></app-businessunit>
                </mat-tab>
               
                <!--Leadership-->
                <mat-tab label="Leadership" class="tabcolor">
                  <app-leadership [modulefor]="parent"></app-leadership>
                </mat-tab>

                 <!--Board Directors-->
                 <mat-tab label="Board Directors" class="tabcolor">
                  <app-boarddirectors [modulefor]="parent"></app-boarddirectors>
                </mat-tab>

                <!--Images-->
                <mat-tab label="Images" class="tabcolor">
                   <app-businessimage  [modulefor]="parent"></app-businessimage>
                </mat-tab>
                
                
                <!--Media-->
                <mat-tab label="Media" class="tabcolor">
                   <app-businessmedia [modulefor]="parent"></app-businessmedia>
                </mat-tab>

                <!--Key Info-->
                <mat-tab label="Key Info" class="tabcolor">
                  <app-keyinfo [modulefor]="parent"></app-keyinfo>
                </mat-tab>
            
            </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div>
import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';
import { constants } from '@shared/config/constants';


@Injectable({
    providedIn:'root'
})

export class UACService {

    constructor(private http: HttpClient){


    }

    getRoles(){

        var filter = "";
        var localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
      

        if(localstoragedata.family.name != constants.CORE_FAMILY_NAME) filter = "?filter[family]="+JSON.parse(localStorage.getItem('LOGGED_USER')).family._id;


        
        return this.http.get(apiendpoints.ROLE+filter,
            {
                headers: new HttpHeaders()
                  .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
                observe: 'response'
              }
            );      
    }

    getRole(id){


      return this.http.get(apiendpoints.ROLE+"/"+id,
          {
              headers: new HttpHeaders()
                .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
              observe: 'response'
            }
          );      
    }

    addRole(data:any){
      console.log(data);
      return this.http.post(apiendpoints.ROLE, {role:data},  {
        headers: new HttpHeaders()
          .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
        observe: 'response'
      });      
    }

    removeRole(id){
    
      const options = {
        headers: new HttpHeaders({
          'Authorization': `Token `+localStorage.getItem('ACCESS_TOKEN')
        }),
        body: {
          roleId: id,
        },        
      };
      
      return this.http.delete(apiendpoints.ROLE, options);
    }





  getPermissions(){

    return this.http.get(apiendpoints.PERMISSIONS,
        {
            headers: new HttpHeaders()
              .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
            observe: 'response'
          }
        );      
  }



  getModules(){

      return this.http.get(apiendpoints.MODULE,
          {
              headers: new HttpHeaders()
                .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
              observe: 'response'
            }
          );      
  }

  getModule(id){


    return this.http.get(apiendpoints.MODULE+"/"+id,
        {
            headers: new HttpHeaders()
              .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
            observe: 'response'
          }
        );      
  }


  getAllowedModule(){

    var localstoragedata=JSON.parse(localStorage.getItem('LOGGED_USER'));
    var roleId = localstoragedata.role_uac._id;
  
    if(roleId){
        return this.http.get(apiendpoints.ROLEMODULESPECIFIC+"?role="+roleId,
        {
            headers: new HttpHeaders()
              .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
            observe: 'response'
          }
        );
    }
          
  }

  
  addModule(data:any){
    console.log(data);
    return this.http.post(apiendpoints.MODULE, {module:data},  {
      headers: new HttpHeaders()
        .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
      observe: 'response'
    });      
  }

  removeModule(id){
  
    const options = {
      headers: new HttpHeaders({
        'Authorization': `Token `+localStorage.getItem('ACCESS_TOKEN')
      }),
      body: {
        moduleId: id,
      },        
    };
    
    return this.http.delete(apiendpoints.MODULE, options);
  }



  getRoleModule(roleId, moduleId){


    return this.http.get(apiendpoints.ROLEMODULE+"?role="+roleId+"&module="+moduleId,
        {
            headers: new HttpHeaders()
              .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
            observe: 'response'
          }
        );      
  }


  addRoleModule(data:any){
    console.log(data);
    return this.http.post(apiendpoints.ROLEMODULE, {rolemodule:data},  {
      headers: new HttpHeaders()
        .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
      observe: 'response'
    });      
  }

  removeRoleModule(roleId, moduleId, permissionId){

    const options = {
      headers: new HttpHeaders({
        'Authorization': `Token `+localStorage.getItem('ACCESS_TOKEN')
      }),
      body: {
        roleId: roleId,
        moduleId: moduleId,
        permissionId: permissionId,
      },        
    };
    
    return this.http.delete(apiendpoints.ROLEMODULE, options);      

    
    

  }

  addLogs(data:any){
    return this.http.post(apiendpoints.USER_LOGS, {logsuser:data},  {
      headers: new HttpHeaders()
        .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
      observe: 'response'
    });      
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { CommitteeService } from '@services/Committee.service';
import { CoreService } from '@services/CoreService';
import { language } from '@shared/config/language';
@Component({
  selector: 'app-agendatab',
  templateUrl: './agendatab.component.html',
  styleUrls: ['./agendatab.component.css'],
})
export class AgendatabComponent implements OnInit {
  resource = language;
  show = true;
  endpointResults: any;
  type = '';
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private dialog: MatDialog,
    private committeeService: CommitteeService,
    private coreService: CoreService
  ) {}
  topics: any[] = [];
  defered_topics: any[] = [];
  parentId = '';

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentId = data.id;
        this.getMeetingData(this.parentId);
      }
    });
  }

  getMeetingData(id) {
    var filter = '/' + id;

    this.committeeService.getCommitteeMeetings(filter).subscribe(
      (response: any) => {
        const result = response.data;
        this.endpointResults = result;
        console.log('result main', result);

        this.defered_topics = [];
        if (result.deferred_agendas.length > 0) {
          for (let i = 0; i < result.deferred_agendas.length; i++) {
            this.defered_topics.push(result.deferred_agendas[i]);
          }
        }
        this.topics = [];
        if (result.agendas.length > 0) {
          for (let i = 0; i < result.agendas.length; i++) {
            this.topics.push(result.agendas[i]);
          }
        }
        const is_consensus = result.committee.is_consensus;
        const is_voting = result.committee.is_voting;
        const is_hybrid = result.committee.is_hybrid;
        if(is_consensus) {
          this.type = 'is_consensus'
        }
        else if(is_voting) {
          this.type = 'is_voting'
        }
        else {
          this.type = 'is_hybrid'
        }
        console.log(this.type)
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  onReload() {
    this.getMeetingData(this.parentId);
  }
}

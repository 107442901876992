import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';


@Injectable({
    providedIn:'root'
})

export class OnboardingService {

    constructor(private http: HttpClient){


    }

     

    getMessage(){


      return this.http.get(apiendpoints.ONBOARDING,
          {
              headers: new HttpHeaders()
                .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
              observe: 'response'
            }
          );      
    }

    updateMessage(data:any){
      console.log(data);
      return this.http.post(apiendpoints.ONBOARDING, {message:data},  {
        headers: new HttpHeaders()
          .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
        observe: 'response'
      });      
    }

    

}

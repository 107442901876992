import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';


@Injectable({
    providedIn:'root'
})

export class CommonSpaceService {

    constructor(private http: HttpClient){


    }

   
    getCommonSpaces(filter){

      return this.http.get(apiendpoints.COMMON_SPACE+filter,
          {
              headers: new HttpHeaders()
                .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
              observe: 'response'
            }
          );      
  }

  getCommonSpace(id){


    return this.http.get(apiendpoints.COMMON_SPACE+"/"+id,
        {
            headers: new HttpHeaders()
              .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
            observe: 'response'
          }
        );      
  }

  addCommonSpace(data:any){
    console.log(data);
    return this.http.post(apiendpoints.COMMON_SPACE, {data:data},  {
      headers: new HttpHeaders()
        .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
      observe: 'response'
    });      
  }

  removeCommonSpace(id){
  
    const options = {
      headers: new HttpHeaders({
        'Authorization': `Token `+localStorage.getItem('ACCESS_TOKEN')
      }),
      body: {
        id: id,
      },        
    };
    
    return this.http.delete(apiendpoints.COMMON_SPACE, options);
  }
  addBooking(data:any)
  {
    return this.http.post(apiendpoints.COMMON_SPACE_BOOKING, {data:data},  {
      headers: new HttpHeaders()
        .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
      observe: 'response'
    });   
  }

  getBooking(id)
  {
    return this.http.get(apiendpoints.COMMON_SPACE_BOOKING+'/'+id,  {
      headers: new HttpHeaders()
        .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
      observe: 'response'
    });   
  }
getBookingByDate(date)
{
  return this.http.get(apiendpoints.BOOKINGBYDATE+'?fromdate='+date,  {
    headers: new HttpHeaders()
      .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
    observe: 'response'
  });  
}

}

<form>
  <fieldset>
    <!--[disabled]=" (familyname!=select_user_family) ? isDisabled :''"-->
    <mat-card class="">
      <mat-card-content>
        <div class="row cancel">
          <button mat-stroked-button (click)="cancelMeeting()">
            Cancel Meeting
          </button>
        </div>
        <div class="row maindiv" *ngIf="showSlab">
          <div class="buttonrow">
            <button
             *ngIf="!isRationalized"
              mat-raised-button
              color="primary"
              class="btn btn-primary login-btn button-wider"
              type="button"
              (click)="openAgendaDialog()"
            >
              Add Agenda
            </button>
            <button
              mat-raised-button
              color="primary"
              class="btn btn-primary login-btn button-wider"
              type="button"
              (click)="publishClick()"
            >
              Publish Offline Agenda
            </button>
          </div>
        </div>
        <div class="row" *ngIf="!showSlab">
          <div class="row cancel">
            <button mat-stroked-button (click)="openAgendaDialog()"  *ngIf="!isRationalized">
              Add Agenda
            </button>
          </div>
          <div
            class="row wholerow"
            *ngFor="let topic of topics; index as i; trackBy: trackItem"
          >
            <div class="col-md-12 firstcol">
              <div class="row" *ngIf="topic.title"><label>Agenda: </label>&nbsp; {{ topic.title }}</div>
              <div class="row" *ngIf="!topic.title"><label>Offline Agenda: </label></div>
            </div>
            <div class="data-row">
            <div class="col-md-3 firstcol">
              <strong style="color: black"
                ><label>Description: </label>&nbsp;{{ topic.description }}</strong
              >
            </div>
            <div class="col-md-4 firstcol">
              <strong style="color: black"
                *ngIf="topic.pre_reads && topic.title">Pre-Reads:<br><a>{{ topic.pre_reads }}</a></strong
              >
              <strong style="color: black"
                *ngIf="topic.pre_reads && !topic.title"><label>Pre-Reads: </label>&nbsp;<a [href]="topic?.pre_reads" target="_blank">
                  {{ (topic?.pre_reads?.length>90)? (topic?.pre_reads | slice:0:90)+'...':(topic?.pre_reads) }}</a></strong
              >
            </div>
            <div class="col-md-4 firstcol">
              <strong style="color: black"><label>Actions </label></strong>
              <br />
              <button (click)="onEdit(topic)" mat-icon-button>
                <mat-icon>mode_edit</mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="onDelete(topic)">
                <mat-icon>delete_outline</mat-icon>
              </button>
              <button (click)="onDefer(topic)" matTooltip="Defer Agenda" mat-icon-button>
                <mat-icon>arrow_upward</mat-icon>
              </button>
            </div>
          </div>
          </div>
        </div>
      </mat-card-content>
      <span class="blankline"></span>
      <div class="form-group mr-2">
        <button
        routerLink="/committee/participants"
        [queryParams]="{ id: parentId }"
          mat-stroked-button
          type="button"
          class="wide"
        >
          PREVIOUS
        </button>
      </div>
      <mat-card-actions align="end">
        <button
          mat-raised-button
          color="primary"
          class="btn btn-primary login-btn button-wider"
          type="button"
          (click)="show_suggested()"
          [disabled]="!topics.length"
        >
          Publish Agenda
        </button>
      </mat-card-actions>
    </mat-card>
  </fieldset>
</form>

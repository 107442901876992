<div class="content p30"> 

       
 
    <div id="block_container">
        <a  routerLink="/membership" id="bloc1" mat-flat-button class="mat-flat-button-transperent" ><span  class="material-icons">keyboard_backspace</span></a> 
          
        <div id="bloc2"><h5>{{resource.message('MEMBERSHIP_TASK')}} / {{resource.message("MEMBERSHIP_ACTION")}}</h5></div>
    </div>
     

    <form [formGroup]="form" (ngSubmit)="submit()" >
    <mat-card class="">
      <mat-card-content>
        <br />
        <h5>Create Membership</h5>
        <hr />
      
        <mat-grid-list cols="2" rowHeight="130px">
            <mat-grid-tile >
                <mat-form-field appearance="outline" class="field-spacer">
                    <input type="hidden" formControlName="_id" >
                    <mat-select  placeholder="Member Name"  formControlName="user"  >
                      <mat-option *ngFor="let role of ADVISOR_LIST" [value]="role._id+''">{{ role.name}} {{ role.lastname}}</mat-option>
                   </mat-select>
                  </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile >
                
              <div class="div1">
                  <label>Category :  </label><br />
                  <mat-radio-group appearance="outline" class="field-spacer"  [formControl]="category">
                      <mat-radio-button value="personal" [checked]="category.value == 'personal'" (change)="this.form.patchValue({'type':'personal'})" >Personal</mat-radio-button>
                      <mat-radio-button value="professional" [checked]="category.value == 'professional'" class="m20" (change)="this.form.patchValue({'type':'professional'})">Professional</mat-radio-button>
                  </mat-radio-group>
                </div>

            </mat-grid-tile>
            
           

          <mat-grid-tile   >
            <mat-form-field appearance="outline"class="field-spacer">
                <mat-label>Start Date</mat-label>
                <input matInput [min]="todayDate" [matDatepicker]="dp1" disabled formControlName="start_date" placeholder="Start Date">
                <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                 
              </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile   >
          <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Valid Till</mat-label>
              <input matInput [matDatepicker]="dp2" [min]="todayDate" disabled formControlName="valid_till" placeholder="Valid Till">
              <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
              <mat-datepicker #dp2 disabled="false"></mat-datepicker>
               
            </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile   >
        <mat-form-field appearance="outline" class="field-spacer">       
          <mat-label>Membership Name</mat-label>
          <input matInput placeholder="Placeholder" formControlName="name">
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile   >
        <mat-form-field appearance="outline" class="field-spacer">       
          <mat-label>Membership Type</mat-label>
          <input matInput placeholder="Placeholder" formControlName="subtype">
        </mat-form-field>
      </mat-grid-tile>

      
      <mat-grid-tile colspan ="2">
        <mat-form-field appearance="outline" class="field-spacer">
            <mat-label>Description</mat-label>
            <textarea matInput
            formControlName="description"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"></textarea>
          </mat-form-field>
    </mat-grid-tile>
          
 

          </mat-grid-list>
          
 
   
       


      </mat-card-content>

      <mat-card-actions align="end">
        <button mat-raised-button color="primary" class="btn btn-primary login-btn button-wider" type="submit" [disabled]="form.invalid">Save</button>
          <button mat-raised-button color="clear" class="button-wider"  routerLink="/membership">Cancel</button>
      </mat-card-actions>
      

    </mat-card>
  </form>


  </div>

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { language } from '@shared/config/language';
import { AuthService } from '@services/Auth.service';
import { constants } from '@shared/config/constants';
import { DialogparticipantsComponent } from '@shared/components/dialogparticipants/dialogparticipants.component';
import { MatDialog } from '@angular/material/dialog';
import { OtherMeetingService } from '@services/OtherMeeting.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { CoreService } from '@services/CoreService';

export interface MeetingElement {
  createdOn: string;
  requested_by: string;
  start_date: string;
  end_date: string;
  participants: string;
}

// REMOVE THIS COMMENTS

@Component({
  selector: 'app-othermeetings',
  templateUrl: './othermeetings.component.html',
  styleUrls: ['./othermeetings.component.css'],
  providers: [DatePipe],
})
export class OthermeetingsComponent implements OnInit {
  showFiller = false;

  ROLE_DATA: string[];
  ELEMENT_DATA: MeetingElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = [
    'createdOn',
    'requestedBY',
    'start_date',
    'end_date',
    'participants',
    'Action',
  ];
  listData = new MatTableDataSource<MeetingElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<MeetingElement>(true, []);

  PERMISSION_EDIT = AuthService.authentication('Users', 'READ');
  PERMISSION_ADD = AuthService.authentication('Users', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Users', 'DELETE');
  localstoragedata: any;
  familyname = '';
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<MeetingElement>;

  resource = language;
  CORE_FAMILY = constants.CORE_FAMILY_NAME;

  constructor(
    private coreservice: CoreService,

    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog,
    private othermeetinginfoservice: OtherMeetingService,
    private datePipe: DatePipe
  ) {
    this.listData = new MatTableDataSource<MeetingElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<MeetingElement>(true, []);
  }

  ngOnInit(): void {
    AuthService.authenticate_redir('Users', ['READ']);

    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();

    this.getothermeetingdata();
    this.table.renderRows();
  }
  getothermeetingdata() {
    var filter = '';
    this.othermeetinginfoservice.getOtherMeetings(filter).subscribe(
      (response: any) => {
        this.listData.data = response.body['data'] as MeetingElement[];

        this.listData.data.forEach(function (value) {
          value['requestedBY'] =
            value.requested_by['name'] + ' ' + value.requested_by['lastname'];
        });

        this.table.renderRows();
      },
      (err) => {}
    );
  }
  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key]; // + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  addrole() {
    this.router.navigate(['/othermeeting/add']);
  }
  removeMeteting(data) {
      this.coreservice
        .openConfirmDialog(this.resource.message('OTHER_MEET_DELETE'))
        .afterClosed()
        .subscribe((res) => {
          if (res) {
            this.othermeetinginfoservice.removeOtherMeetings(data._id).subscribe(
              (response) => {
                const result = response.body;
                this.getothermeetingdata();
                this.coreservice.success('Meeting deleted successfully');
              },
              (err) => {
                console.log(err);
              }
            );
          }
        });
  }

  edit(role) {
    console.log('*************************', role);
    this.router.navigate(['othermeeting/add'], {
      queryParams: { id: role._id },
    });
  }

  openParticipantsDialog(msg, actionbutton, cancelbutton, id) {
    console.log('ID::::::::::', id);
    return this.dialog.open(DialogparticipantsComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        parentId: id,
      },
    });
  }

  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [
      ['Title', 'Requested By', 'Start Date and Time', 'End Date and Time'],
    ];
    this.listData.data.forEach((obj) => {
      let title = obj['title'] ? obj['title'] : '';
      let requested_by = obj['requestedBY'] ? obj['requestedBY'] : '';
      let start_date_time = obj['start_date']
        ? this.datePipe.transform(obj['start_date'])
        : '';
      let end_date_time = obj['end_date']
        ? this.datePipe.transform(obj['end_date'])
        : '';

      let arr = [title, requested_by, start_date_time, end_date_time];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      rowPageBreak: 'avoid',
    });
    if (action === 'save') {
      doc.save('other-meetings.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      // window.open(doc.output('bloburl') as unknown as string, '_blank');
      doc.output('dataurlnewwindow', { filename: 'other-meetings.pdf' });
    }
  }
}

import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { UserService } from '@services/UserService';
import { language } from '@shared/config/language';
import { UserElement } from '../dialoginternalparticipants/dialoginternalparticipants.component';
import { DialogmeetingparticipantsComponent } from '../dialogmeetingparticipants/dialogmeetingparticipants.component';
import { DialogtemporaryinviteesComponent } from '../dialogtemporaryinvitees/dialogtemporaryinvitees.component';

@Component({
  selector: 'app-dialog-view-invitee-list',
  templateUrl: './dialog-view-invitee-list.component.html',
  styleUrls: ['./dialog-view-invitee-list.component.css'],
})
export class DialogViewInviteeListComponent implements OnInit {
  resource = language;
  ELEMENT_DATA: UserElement[];
  searchKey: string;
  rowData: any;
  displayedColumns: string[] = ['name', 'email'];
  listData = new MatTableDataSource<UserElement>();
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('roleTable') table: MatTable<UserElement>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  labelText: string;
  specialInvitee: any = [];
  mail: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogtemporaryinviteesComponent>,
    private changeDetectorRefs: ChangeDetectorRef,

    private dialog: MatDialog
  ) {
    this.listData = new MatTableDataSource<UserElement>(this.ELEMENT_DATA);
  }

  ngOnInit(): void {
    console.log(this.data);
    this.listData.paginator = this.paginator;

    this.listData.sort = this.sort;
    this.listData.data = this.data.temporary_members;
    this.changeDetectorRefs.detectChanges();
    this.table.renderRows();
  }
  closeDialog() {
    this.dialogRef.close(false);
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  openDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogmeetingparticipantsComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        temporary_members: this.specialInvitee,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result.users) this.specialInvitee = result.users;
      if (result.mail) this.mail = result.mail;
    });
  }
}

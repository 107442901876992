import { Component, OnInit } from '@angular/core';
import { language } from '@shared/config/language';
import { CommitteeService } from '@services/Committee.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { FileService } from '@services/File.service';
import { IdpService } from '@services/Idp.service';
import { CoreService } from '@services/CoreService';
import { DialogaddnoteComponent } from '@shared/components/dialogaddnote/dialogaddnote.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogviewattendanceComponent } from '@shared/components/dialogviewattendance/dialogviewattendance.component';

@Component({
  selector: 'app-committeratonalize',
  templateUrl: './committeratonalize.component.html',
  styleUrls: ['./committeratonalize.component.css'],
})
export class CommitteratonalizeComponent implements OnInit {
  resource = language;
  parentId: string;
  endpointResults: any;
  start_date: string;
  end_date: string;
  venue: string;
  currentfile = {};
  currentfilename = '';
  selectedfile: any;
  createdfor: string;
  isEdit: boolean = false;
  fileId: string;
  url: string = '';
  members = [];
  offlineURL = false;
  uploadedURL = "";
  constructor(
    private committeeService: CommitteeService,
    private router: Router,
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    private fileService: FileService,
    private idp: IdpService,
    private coreservice: CoreService
  ) {}
  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    file: new FormControl(''),
    filename: new FormControl(''),
  });
  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentId = data.id;
        this.getAllCommiteeMeetings(this.parentId);
      }
    });
    this.activeRoute.url.subscribe((data) => {
      if(data[1].path === "upload-offline-mom") {
       this.offlineURL = true;
      }
      else {
        this.offlineURL = false;
      }
    });
  }
  getAllCommiteeMeetings(id) {
    var filter = '/' + id;

    this.committeeService.getCommitteeMeetings(filter).subscribe(
      (response: any) => {
        const result = response.data;
        this.endpointResults = result;
        console.log(response.data.start_date);
        this.start_date = result.start_date;
        this.end_date = result.end_date;
        this.venue = result.venue;
        this.uploadedURL = result.mom;
        let special_invitees = response.data.temporary_members.members
        this.members = [...response.data.members,...special_invitees];
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  openparticipants(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogviewattendanceComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        members: this.members,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        parentId: this.parentId,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('>><<<<>>>>', result.data.length);
    });
  }
  onFileChange(event) {
    const latestfile = (event.target as HTMLInputElement).files[0];
    this.currentfile = latestfile;
    this.currentfilename = latestfile.name;
    this.selectedfile = latestfile;
    var filename = latestfile.name;

    this.form.patchValue({
      filename: this.currentfilename,
    });

    this.form.patchValue({
      file: this.currentfile,
    });

    this.form.get('file').updateValueAndValidity();
  }

  async onMOMFileChange(event) {
    console.log(event.target.files)
    const latestfile = (event.target).files[0];
    
    var formData: any = new FormData();
    formData.append('file', latestfile);
    this.uploadedURL = await this.fileService.uploadFile(formData, true, false);
    this.endpointResults.mom = this.uploadedURL;
  }

  cancelMeeting() {
    const dialogRef = this.dialog.open(DialogaddnoteComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: 'MEETING CANCELLED',
        actionbutton: '',
        cancelbutton: '',
        id: this.parentId,
        type: 'cancel',
        meeting: 'committee-meetings',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
    //   this.idp.idpcancel(this.parentid).subscribe(
    //     (response: any) => {
    //       //    this.router.navigate(['idp']);
    //     },
    //     (err) => {
    //       console.log('errrrrrrr', err);
    //     }
    //   );
  }
  submitMOM() {
    this.committeeService.committeemompost({mom: this.uploadedURL}, this.parentId).subscribe(
      (response) => {
        this.coreservice.success(this.resource.message('MOM_PUBLISHED'));

        // const result = response.body;
        this.router.navigate(['committee-meetings']);
      },
      (err) => {}
    );
  }
  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    var rawuser = this.form.getRawValue();
    console.log(this.form.get('file'));
    if (
      this.form.get('file').value !== undefined &&
      this.form.get('file').value !== ''
    ) {
      let fileurl = this.form.get('file').value;
      var formData: any = new FormData();
      formData.append('file', this.form.get('file').value);
      fileurl = await this.fileService.uploadFile(formData, true, false);
      rawuser.file_url = fileurl;
    }

    delete rawuser['file'];
    let finaldata;
    if (this.isEdit === false) {
      finaldata = {
        pre_reads: rawuser.file_url,
      };
    } else {
      finaldata = {
        pre_reads: rawuser.file_url,
      };
    }
    if(this.endpointResults?.agendas[0]?.pre_reads) {
      this.committeeService
        .updateCommitteeAgenda(finaldata, this.parentId, this.endpointResults?.agendas[0]?._id)
        .subscribe(
          (response) => {
            //this.coreservice.success(this.resource.message('AGENDA_EDITED'));
            this.router.navigate(['/committee/upload-offline-mom'], {
              queryParams: { id: this.parentId },
            });
          },
          (err) => {}
        );
        return;
    }
    this.committeeService.committeeAgenda(finaldata, this.parentId).subscribe(
      (response) => {
        this.coreservice.success(this.resource.message('AGENDA_PUBLISHED'));

        this.router.navigate(['/committee/upload-offline-mom'], {
          queryParams: { id: this.parentId },
        });
      },
      (err) => {}
    );
  }
}

<div align="right">
  <div class="content-container">
    <mat-card class="cardclass">
      <mat-card-header>
        <mat-card-title>
          {{ resource.message("MEETINGPARTICIPANTS") }}
        </mat-card-title>
        <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form">
          <mat-grid-list
            style="max-height: 70vh !important; overflow-y: auto !important"
            cols="3"
            rowHeight="80px"
            formArrayName="temporary_members"
          >
            <div
              *ngFor="
                let item of temporary_members.controls;
                let pointIndex = index
              "
              [formGroupName]="pointIndex"
            >
              <mat-grid-tile>
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Name</mat-label>
                  <input matInput placeholder="Name" formControlName="name" />
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile>
                <mat-form-field appearance="outline" class="field-spacer">
                  <mat-label>Email</mat-label>
                  <input
                    matInput
                    placeholder="Email"
                    formControlName="email"
                  /><mat-error *ngIf="item.get('email').errors"
                    >Please enter a valid email.</mat-error
                  >
                </mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile style="padding: 0px">
                <button
                  style="height: 80%; width: 50%"
                  mat-button
                  type="button"
                  color="warn"
                  appearance="outline"
                  class="field-spacer"
                  (click)="deleteTemp(pointIndex)"
                  *ngIf="pointIndex != temporary_members.controls.length - 1"
                >
                  <span
                    class="material-icons"
                    inline="true"
                    style="transform: scale(1.5)"
                    >delete_outline</span
                  >
                </button>
                <button
                  style="height: 80%; width: 50%"
                  mat-button
                  type="button"
                  color="primary"
                  appearance="outline"
                  class="field-spacer"
                  (click)="addSocial()"
                  *ngIf="pointIndex == temporary_members.controls.length - 1"
                >
                  <span
                    class="material-icons"
                    inline="true"
                    style="transform: scale(1.5)"
                    >note_add</span
                  >
                </button>
              </mat-grid-tile>
            </div>
          </mat-grid-list>
          <mat-card-actions align="end">
            <button
              mat-raised-button
              color="primary"
              class="btn btn-primary login-btn button-wider"
              type="submit"
              [disabled]="temporary_members.invalid"
              (click)="onSubmit()"
            >
              Next
            </button>
          </mat-card-actions>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { language } from '@shared/config/language';
import { IdpService } from '@services/Idp.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommitteeService } from '@services/Committee.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { constants } from '@shared/config/constants';
import { DialogremarkComponent } from '@shared/components/dialogremark/dialogremark.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CoreService } from '@services/CoreService';

@Component({
  selector: 'app-goalprogress',
  templateUrl: './goalprogress.component.html',
  styleUrls: ['./goalprogress.component.css'],
})
export class GoalprogressComponent implements OnInit {
  resource = language;
  STATUS_LIST: any;
  parentId: string;
  form: FormGroup = new FormGroup({
    category: new FormControl(''),
    subcategory: new FormControl(''),
    status: new FormControl(''),
    goal: new FormControl(''),
  });
  subcategory: any = '';
  allTodo: any;
  allRemarks: any;
  constructor(
    private idp: IdpService,
    private activeRoute: ActivatedRoute,
    private committeeService: CommitteeService,
    private router: Router,
    private dialog: MatDialog,
    private coreservice: CoreService,
  ) {
    this.STATUS_LIST = constants.IDP_STATUSES;
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentId = data.id;
        this.getGoals(data.id);
      }
    });
  }
  getGoals(id) {
    var filter = '';

    filter = '?filter[_id]=' + id; //+this.localstoragedata._id
    this.idp.useridpget(filter).subscribe(
      (response: any) => {
        const result = response;
        this.subcategory = response.data[0].subcategory;
        console.log('>>>>>>>>>>>>>>', response.data[0]);
        this.form.patchValue({
          category: response.data[0].category,
          subcategory: response.data[0].subcategory,
          status: response.data[0].status,
          goal: response.data[0].description,
        });
        this.allTodo = response.data[0].todos;
        this.allRemarks = response.data[0].remarks;
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  edit() {
    this.router.navigate(['goal/edit'], {
      queryParams: { id: this.parentId },
    });
  }
  openRemark(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogremarkComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        parentId: this.parentId,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getGoals(this.parentId);
    });
  }

  onStatusUpdate(status: string){
    this.idp.updateGoalStatus(this.parentId, status).subscribe(
      (response: any) => {
        console.log('>>>>>>>>>>>>>>', response.data);
        this.coreservice.success(this.resource.message('GOAL_STATUS_UPDATE'));
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
}

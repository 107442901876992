import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { SubscriptionService } from '@services/Subscription.service';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import { MatMenuTrigger } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import { CoreService } from '@services/CoreService';
import { AuthService } from '@services/Auth.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export interface SubscriptionElement {
  id: number;
  subscription: string;
  details: string;
  users_allowed: string;
  cost: string;
  cycle: string;
  created: string;
  updated: string;
}

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css'],
})
export class SubscriptionComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;

  ELEMENT_DATA: SubscriptionElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = [
    'subscription',
    'users_allowed',
    'cost',
    'cycle',
    'Action',
  ];
  listData = new MatTableDataSource<SubscriptionElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<SubscriptionElement>(true, []);

  PERMISSION_EDIT = AuthService.authentication(
    'Subscription Management',
    'READ'
  );
  PERMISSION_ADD = AuthService.authentication('Subscription Management', 'ADD');
  PERMISSION_DELETE = AuthService.authentication(
    'Subscription Management',
    'DELETE'
  );

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<SubscriptionElement>;

  resource = language;

  constructor(
    private subscriptionService: SubscriptionService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private http: HttpClient,
    private coreservice: CoreService
  ) {
    AuthService.authenticate_redir('Subscription Management', ['READ']);

    this.listData = new MatTableDataSource<SubscriptionElement>(
      this.ELEMENT_DATA
    );
    this.selection = new SelectionModel<SubscriptionElement>(true, []);

    console.log(language.message('a'));
  }

  ngOnInit(): void {
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllSubscriptions();
    this.table.renderRows();
  }

  getAllSubscriptions() {
    this.subscriptionService.getSubscriptions().subscribe(
      (response) => {
        const result = response.body;
        this.listData.data = response.body['results'] as SubscriptionElement[];
        this.table.renderRows();
      },
      (err) => {
        if (err.error.status != null) {
          //          this.errmessage = err.error.message;
        } else {
          //         this.errmessage = err.error.errors[0].msg;
        }
      }
    );
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listData.data.forEach((row) => this.selection.select(row));
  }

  addKnwoledge() {
    this.router.navigate(['/subscriptions/add']);
  }

  edit(role) {
    this.router.navigate(['subscriptions/add'], {
      queryParams: { id: role._id },
    });
  }

  onDelete(id) {
    this.coreservice
      .openConfirmDialog(this.resource.message('SUBSCRIPTION_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.subscriptionService.removeSubscription(id).subscribe(
            (response) => {
              const result = response;
              this.getAllSubscriptions();
              this.coreservice.success(
                this.resource.message('SUBSCRIPTION_SAVE')
              );
            },
            (err) => {}
          );
        }
      });
  }

  closeMyMenu() {
    this.trigger.closeMenu();
  }

  filterData(ev, col, val) {
    if (ev.checked) {
      col = String(col);

      let value = { [col]: val };
      this.filterValues.push(value);
    } else {
      let value = { [col]: val };
      const index = this.filterValues.indexOf(value);
      this.filterValues.splice(index - 1, 1);
    }

    this.setupFilter(col);
    this.applyFilter(val);
  }

  stoppro($event) {
    $event.stopPropagation();
  }

  setupFilter(column: string) {
    this.listData.filterPredicate = (d: any, filter: string) => {
      const val = JSON.parse(filter);
      var flag = false;

      val.forEach(function (item) {
        let obj = item;
        const textToSearch = (d[column] && d[column].toLowerCase()) || '';
        if (obj[column] != undefined) {
          if (
            textToSearch.toLowerCase().indexOf(obj[column].toLowerCase()) !== -1
          ) {
            flag = true;
          }
        }
      });
      return flag;
    };
  }

  applyFilter(filterValue: string) {
    this.listData.filter = JSON.stringify(this.filterValues); // filterValue.trim().toLowerCase();
  }

  filterValues = [];
  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [['Subscription', 'Allowed Users', 'Cost', 'Cycle']];
    this.listData.data.forEach((obj) => {
      let subscription = obj['subscription'] ? obj['subscription'] : '';
      let users_allowed = obj['users_allowed'] ? obj['users_allowed'] : '';
      let cost = obj['cost'] ? obj['cost'] : '';
      let cycle = obj['cycle'] ? obj['cycle'] : '';

      let arr = [subscription, users_allowed, cost, cycle];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      rowPageBreak: 'avoid',
    });

    if (action === 'save') {
      doc.save('subscriptons.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      doc.output('dataurlnewwindow', { filename: 'subscriptons.pdf' });
    }
  }
}

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { language } from '@shared/config/language';
import { DialogmeetingdataComponent } from '@shared/components/dialogmeetingdata/dialogmeetingdata.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { IdpService } from '@services/Idp.service';
import { DialogviewattendanceComponent } from '@shared/components/dialogviewattendance/dialogviewattendance.component';
import { DialogaddnoteComponent } from '@shared/components/dialogaddnote/dialogaddnote.component';
import { DialoginviteesComponent } from '@shared/components/dialoginvitees/dialoginvitees.component';
import { CommitteeService } from '@services/Committee.service';

export interface GoalElement {
  development: number;
  category: string;
  status: string;
}
@Component({
  selector: 'app-takeattendence',
  templateUrl: './takeattendence.component.html',
  styleUrls: ['./takeattendence.component.css'],
})
export class TakeattendenceComponent implements OnInit {
  resource = language;

  ELEMENT_DATA: GoalElement[];
  rowModelType: 'serverSide';
  rowData: any;
  mail: any;
  displayedColumns: string[] = ['name', 'status'];
  listData = new MatTableDataSource<GoalElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<GoalElement>(true, []);
  localstoragedata: any;
  form: FormGroup = new FormGroup({
    _id: new FormControl(''),
    topic: new FormControl('', Validators.required),
  });
  start_date: string;
  end_date: string;
  start_time: string;
  end_time: string;
  venue: string;
  title: string;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('goalTable') table: MatTable<GoalElement>;
  parentid: string;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private idp: IdpService,
    private activeRoute: ActivatedRoute,
    private changeDetectorRefs: ChangeDetectorRef,
    private committeeService: CommitteeService
  ) {}

  ngOnInit(): void {
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentid = data.id;
      }
    });
    this.getMeetings(this.parentid);
  }
  openDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogmeetingdataComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  onSubmit() {
    this.idp.updateIDPMeetStatus({status : 'marked_attendance'}, this.parentid).subscribe(
      (response: any) => {
        console.log(response.data._id);

        this.router.navigate(['outcome'], {
          queryParams: {
            id: this.parentid,
          },
        });
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
    
  }
  getMeetings(id) {
    var filter = '';
    filter = '?filter[_id]=' + id; //+this.localstoragedata._id
    this.idp.idpMeetingGet(filter).subscribe(
      (response: any) => {
        this.listData.data = [
          ...response.data[0].members,
          ...response.data[0].temporary_members.members,
        ];
        this.start_date = response.data[0].start_date;
        this.end_date = response.data[0].end_date;
        this.start_time = response.data[0].start_time;
        this.end_time = response.data[0].end_time;
        this.venue = response.data[0].venue;
        this.title = response.data[0].title;
        this.mail = response.data[0].temporary_members;
        console.log(response.data[0], 'response');
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }

  setTemporaryMemberAttendance(member: any) {
    const req = {};
    req['temporary_members'] = {
      subject: this.mail.subject,
      mailbody: this.mail.message,
      attachment: this.mail.file_url,
      members: [...this.mail.members.filter(val => val._id !== member._id), member],
    };
    this.committeeService.committeeParticipants(req, this.parentid).subscribe(
      (response: any) => {
        this.getMeetings(this.parentid);
      })
  } 
  markAttendence(status, member) {
    console.log(member)
    if(member.email) {
      this.setTemporaryMemberAttendance({...member, attendance: status})
      return;
    }
    let data = [];

    data.push({
      member_id: member.id,
      attendance: status,
    });

    this.idp.idpstatuspost(data, this.parentid).subscribe(
      (response: any) => {
        console.log(data);
        this.getMeetings(this.parentid);
        this.table.renderRows();
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  openparticipants(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialoginviteesComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        id: this.parentid
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  
  cancelMeeting() {
    const dialogRef = this.dialog.open(DialogaddnoteComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: 'MEETING CANCELLED',
        actionbutton: '',
        cancelbutton: '',
        id: this.parentid,
        type: 'cancel',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
    //   this.idp.idpcancel(this.parentid).subscribe(
    //     (response: any) => {
    //       //    this.router.navigate(['idp']);
    //     },
    //     (err) => {
    //       console.log('errrrrrrr', err);
    //     }
    //   );
  }
}

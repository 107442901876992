import { Component, OnInit, ViewChild ,ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { UACService } from '@services/UAC.service';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import {CoreService} from '@services/CoreService';
import { MatMenuTrigger } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import {FormGroup,FormControl, FormControlName,Validator, Validators} from '@angular/forms';
import { AuthService } from '@services/Auth.service';
 

@Component({
  selector: 'app-rolepermission',
  templateUrl: './rolepermission.component.html',
  styleUrls: ['./rolepermission.component.css']
})
export class RolepermissionComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;


  ROLES_LIST:any;
  MODULES_LIST:any;
  PERMISSION_LIST:any;
  ROLEPERMISSION_LIST:any;
  
  resource = language;

  
  PERMISSION_EDIT = AuthService.authentication('Role Permissions','READ');
  PERMISSION_READ = AuthService.authentication('Role Permissions','EDIT');
  PERMISSION_DELETE = AuthService.authentication('Role Permissions','DELETE');
  
  form :FormGroup = new FormGroup({
    role:new FormControl(''),
    module:new FormControl(''),
    permission:new FormControl('') 
  })

  constructor(private uacservice: UACService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router : Router,
    private http: HttpClient,
    private coreservice:CoreService
    ) {

      AuthService.authenticate_redir('Role Permissions', ['READ']);

   }

  ngOnInit(): void {
    
    this.getAllModules();
    this.getAllRoles();
    this.getAllPermissions();
  
  }


  getAllRoles()
  {

    this.uacservice.getRoles().subscribe(response=> {
      const result = response.body;
      this.ROLES_LIST =response.body['results'];

 

     }, (err) => {

     })

  }
  
  getAllModules()
  {

    this.uacservice.getModules().subscribe(response=> {
      const result = response.body;
      this.MODULES_LIST =response.body['results'];
     }, (err) => {

     })

  }

    
  getAllPermissions()
  {

    this.uacservice.getPermissions().subscribe(response=> {
      const result = response.body;
      this.PERMISSION_LIST =response.body['results'];
      this.PERMISSION_LIST.forEach((obj, i) => {
        obj.checked = false
        obj.permissionId = "";
      });

     }, (err) => {

     })

  }


  getRoleModulePermissions(){
    let filter = this.form.getRawValue();

    
    if(filter.role != "" && filter.module != ""){
      this.uacservice.getRoleModule(filter.role, filter.module).subscribe(response=> {
       
        const result = response.body;
        this.ROLEPERMISSION_LIST =response.body['results'];

        this.PERMISSION_LIST.forEach((obj, i) => {
          obj.checked = false
           
        });

        this.PERMISSION_LIST.forEach((obj, i) => {
           
          this.ROLEPERMISSION_LIST.forEach((permission, j) => {
              if(obj._id == permission.permission._id) {
                    obj.checked = true
                    obj.permissionId = permission._id;
                }
            
          });

        });

      }, (err) => {
  
       })  
    }


  }
 

  updatepermission($event, pid){
    
    console.log(pid)
    if($event.checked){

      let filter = this.form.getRawValue();

      let data = {
        role: filter.role,
        module: filter.module,
        permission: pid
      }
      
      this.uacservice.addRoleModule(data).subscribe(response=> {
         //   console.log(JSON.stringify(response))
         this.coreservice.success(this.resource.message("ROLEPERMISSION_UPDATE"));
            this.getRoleModulePermissions();
      });
    }else{


      

      
      let filter = this.form.getRawValue();

      
      this.uacservice.removeRoleModule(filter.role, filter.module, pid).subscribe(response=> {
         //   console.log(JSON.stringify(response))
         this.coreservice.success(this.resource.message("ROLEPERMISSION_UPDATE"));
            this.getRoleModulePermissions();
      });


      
    }
  }

}

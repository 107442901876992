import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { language } from '@shared/config/language';
import { UserService } from '@services/UserService';
import { MatPaginator } from '@angular/material/paginator';

export interface UserElement {
  userId: number;
  name: string;
}
@Component({
  selector: 'app-dialoginternalparticipants',
  templateUrl: './dialoginternalparticipants.component.html',
  styleUrls: ['./dialoginternalparticipants.component.css'],
})
export class DialoginternalparticipantsComponent implements OnInit {
  resource = language;
  ELEMENT_DATA: UserElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = ['select', 'name'];
  listData = new MatTableDataSource<UserElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<UserElement>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('roleTable') table: MatTable<UserElement>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  localstoragedata: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialoginternalparticipantsComponent>,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog,
    private userservice: UserService
  ) {
    this.listData = new MatTableDataSource<UserElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<UserElement>(true, []);
  }

  ngOnInit(): void {
    this.localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    this.listData.paginator = this.paginator;

    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllUsers();
  }
  closeDialog() {
    this.dialogRef.close({ data: this.selection['_selected'] });
  }

  getAllUsers() {
    var filter = '';
    filter = '?filter[family]=' + this.localstoragedata.family._id;
    this.userservice.getUsers(filter).subscribe(
      (response) => {
        const result = response.body;
        console.log('::::::::::::::::::', response.body['results']);
        this.listData.data = response.body['results'] as UserElement[];
        const membersListId = this.data.members.map(val => val.id)
        this.listData.data.forEach((row) => {
          if(membersListId.includes(row['user_id']))
            this.selection.select(row);
        })
        console.log(this.data)
        this.table.renderRows();
      },
      (err) => {
        if (err.error.status != null) {
        } else {
        }
      }
    );
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listData.data.forEach((row) => {
          this.selection.select(row);
        });
  }
}

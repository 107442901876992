<div>
  <form class="form-inline">
    <div class="form-group mr-2"></div>
    <span class="example-spacer"></span>
    <div class="mr-1">
      <button
        mat-raised-button
        color="primary"
        class="addbutton"
        class="buttonfloatright"
        (click)="redirectTo()"
      >
        <i class="fas fa-plus"></i>&nbsp;&nbsp;{{
          resource.message("SCHEDULE") | uppercase
        }}
      </button>
    </div>
    <br />
    <br />
    <div class="pclass table-responsive">
      <mat-table
        #meetingTable
        meetingTable
        matTableExporter
        [dataSource]="listData"
        matSort
        class="mattable"
        #exporter="matTableExporter"
      >
        <ng-container matColumnDef="title">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="customTableHeaderStyle"
            >Meeting Title</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="start_date">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="customTableHeaderStyle"
            >Date and Time</mat-header-cell
          >
          <mat-cell *matCellDef="let element"
            >{{ element.start_date | customDate }}
            {{ element.start_time }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="venue">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="customTableHeaderStyle"
            >Venue</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.venue }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="participants">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="customTableHeaderStyle"
            style="justify-content: center"
            >Participants</mat-header-cell
          >
          <mat-cell *matCellDef="let element" style="justify-content: center">
            <button
              (click)="openparticipants('', '', '', element)"
              mat-button
              color="primary"
              class="viewButton"
            >
              View
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="customTableHeaderStyle"
            style="justify-content: center"
            >Status</mat-header-cell
          >
          <mat-cell *matCellDef="let element" style="justify-content: center"
            ><ng-container *ngIf="element.status !== 'cancel'"><span
              class="capitalize"
              [ngClass]="
                element.meeting_status == 'mom_updated'
                  ? 'status-active'
                  : '' || element.meeting_status == 'abandoned'
                  ? 'status-inactive'
                  : '' || element.meeting_status == 'in_review'
                  ? 'status-completed'
                  : '' || element.meeting_status == 'agenda_rationalized'
                  ? 'status-progress'
                  : 'status-progress'
              "
              >{{ getStatus(element.meeting_status) }}</span
            >
            </ng-container>
            <ng-container *ngIf="element.status === 'cancel'">
            <span
              class="capitalize"
              style="color:red;"
              >Cancelled</span
            ></ng-container></mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="Action2">
          <mat-header-cell
            *matHeaderCellDef
            class="customTableHeaderStyle"
            style="justify-content: center"
            >Action</mat-header-cell
          >
          <mat-cell *matCellDef="let row" style="justify-content: center">
            <button mat-icon-button (click)="edit(row)" [disabled]="(row.status === 'cancel' || row.meeting_status === 'mom_updated')">
              <mat-icon>mode_edit</mat-icon>
            </button>
            <!--   <button mat-icon-button color="warn"  (click)="onDelete(row._id)"   ><mat-icon>delete_outline</mat-icon></button>-->
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 15, 20]"
        [pageSize]="5"
        showFirstLastButtons
      ></mat-paginator>
    </div>

    <!-- <div class="row w-100">
      <div class="col-sm-12 text-right mr-0 pr-0">
        <button
          mat-stroked-button
          color="primary"
          class="addbutton"
          class="buttonfloatright"
          style="border: 1px solid #095294"
        >
          {{ "view all" | titlecase }}
        </button>
      </div>
    </div> -->
  </form>
</div>

<mat-card class="">
  <mat-card-content>
    <form class="form-inline" *ngIf="allowsearch == true">
      <div class="form-group mr-2">
        <button
          mat-stroked-button
          [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu"
          class="filtersortborder"
        >
          <i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;Filter
        </button>
        <mat-menu #menu="matMenu" class="menustyle">
          <span style="color: #b4b1b1">Filter By</span>
          <div class="menuitemstyle">
            <p>{{ resource.message("COMMITTEES") }}</p>
            <mat-checkbox
              class="mat-menu-item"
              (change)="filterData($event, 'type', item.value)"
              (click)="stoppro($event)"
              *ngFor="let item of TYPE_LIST | keyvalue"
            >
              {{ item.value }}
            </mat-checkbox>

            <hr class="hrclass" />
            <p>Category</p>
            <mat-checkbox
              class="mat-menu-item"
              (click)="stoppro($event)"
              *ngFor="let item of CATEGORY_LIST"
              (change)="filterData($event, 'category', item.title)"
              >{{ item.title }}</mat-checkbox
            >

            <hr />
            <button mat-raised-button color="primary" (click)="closeMyMenu()">
              Apply
            </button>
          </div>
        </mat-menu>
        <!-- <button mat-stroked-button class="filtersortborder"><i class="material-icons">sort</i>&nbsp;&nbsp;Sort By</button>
              -->
        <button
          (click)="convertToPDF('save')"
          mat-stroked-button
          class="iconcss"
        >
          <i class="fa fa-file-pdf fa-lg"></i>
        </button>
        <button
          mat-stroked-button
          class="iconcss"
          (click)="
            exporter.exportTable('csv', { fileName: 'committee-templates' })
          "
        >
          <i class="fa fa-file-excel fa-lg"></i>
        </button>
        <button
          mat-stroked-button
          class="iconcss"
          printSectionId="print-section"
          (click)="convertToPDF('print')"
        >
          <i class="fa fa-print fa-lg"></i>
        </button>
      </div>
      <span class="example-spacer"></span>
      <div class="mr-1">
        <button
          *ngIf="allowAdd == true"
          mat-raised-button
          color="primary"
          class="addbutton"
          class="buttonfloatright"
          (click)="addrole()"
          matTooltip="{{ tooltip }}"
        >
          <i class="fas fa-plus"></i>&nbsp;&nbsp;ADD
          {{
            modulefor == "template"
              ? resource.message("COMMITTEE_TEMPLATE")
              : (resource.message("COMMITTEE") | uppercase)
          }}
        </button>
        <input
          matInput
          [(ngModel)]="searchKey"
          placeholder="{{ resource.message('SEARCH_BY_COMMITTEE') }}"
          autocomplete="off"
          (keyup)="applyfilter()"
          name="searchKey"
          class="searchfloatright"
          (keydown)="preventSubmit($event)"
        />
      </div>
    </form>
    <p>
      <br />
      <mat-table
        #roleTable
        roleTable
        matTableExporter
        [dataSource]="listData"
        matSort
        class="mattable"
        #exporter="matTableExporter"
        [hiddenColumns]="showTemplate ? [3] : [4]"
        id="print-section"
      >
        <!-- Name Column -->
        <ng-container matColumnDef="category">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Category</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element?.categories?.title
          }}</mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="committeeType">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Committee Type</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            constant.committee_categoryies[element.type]
          }}</mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Committee Name</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="is_published">
          <mat-header-cell
            style="flex-basis: 60px"
            *matHeaderCellDef
            mat-sort-header
            >Status</mat-header-cell
          >
          <mat-cell style="flex-basis: 60px" *matCellDef="let element">
            <span
              style="width: 125px"
              [ngClass]="
                element.is_published == true
                  ? 'status-completed'
                  : 'status-progress'
              "
            >
              {{ element.is_published == true ? "Published" : "Draft" }}
            </span>
          </mat-cell>
        </ng-container>

        <!-- System Column -->

        <!-- Action Column -->
        <ng-container matColumnDef="Action">
          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="edit(row)" *ngIf="showEdit">
              <mat-icon>mode_edit</mat-icon>
            </button>
            <button
              mat-icon-button
              color="warn"
              (click)="onDelete(row)"
              *ngIf="showDelete"
            >
              <mat-icon>delete_outline</mat-icon>
            </button>
            <button
              mat-button
              (click)="onPublic(row)"
              class="user-template"
              *ngIf="showTemplate"
            >
              Use Template
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 15, 20]"
        [pageSize]="5"
        showFirstLastButtons
      ></mat-paginator>
    </p>
  </mat-card-content>
</mat-card>

<div align="right">
    <div class="content-container">

        <span class="content-span full-width">{{data.message}}</span>
        <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
    </div>
    <button mat-flat-button id="yes-button" [mat-dialog-close]="true" color="primary">{{data.actionbutton}}</button>
    <button mat-stroked-button  id="no-button" [mat-dialog-close]="false" color="primary">{{data.cancelbutton}}</button>

</div>

import { Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@services/Auth.service';
import { UserService } from '@services/UserService';
import { language } from '@shared/config/language';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OtherMeetingService } from '@services/OtherMeeting.service';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormBuilder,
  FormArray,
  NgForm,
} from '@angular/forms';

export interface UserElement {
  name: number;
  attendence: string;
  fullname: string,
}

@Component({
  selector: 'app-dialogparticipants',
  templateUrl: './dialogparticipants.component.html',
  styleUrls: ['./dialogparticipants.component.css'],
})
export class DialogparticipantsComponent implements OnInit {
  ELEMENT_DATA: UserElement[];
  searchKey: string;
  rowData: any;
  displayedColumns: string[] = ['fullname', 'attendence'];
  listData = new MatTableDataSource<UserElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<UserElement>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<UserElement>;
  resource = language;
  attending: boolean;
  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    isPresent: new FormControl(false),
  });
  participantsarray = [];
  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private userservice: UserService,
    public dialogRef: MatDialogRef<DialogparticipantsComponent>,
    private othermeetinginfoservice: OtherMeetingService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.listData = new MatTableDataSource<UserElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<UserElement>(true, []);
  }

  ngOnInit(): void {
    AuthService.authenticate_redir('Users', ['READ']);
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllUsers();
  }
  getAllUsers() {
    var filter = '?filter[_id]=' + this.data.parentId;
    this.othermeetinginfoservice.getOtherMeetings(filter).subscribe(
      (response: any) => {
        let result = response.body['data'][0]['participants'];
        this.participantsarray = result;
        this.listData.data = result as UserElement[];
        this.listData.data.forEach(function (value) {
          value["fullname"] = value["user"]["name"]+" "+value["user"]["lastname"];
          value["attendance"] = value["isPresent"] ? "Attending" : "Rejected"
        }); 


        this.table.renderRows();
      },
      (err) => {}
    );
  }

  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          
          return currentTerm + data[key]; // + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    console.log(this.searchKey.trim().toLowerCase());
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }


  setupFilter(column: string) {
    this.listData.filterPredicate = (d: any, filter: string) => {
      const val = JSON.parse(filter);
      console.log('**');
      console.log(val);
      var flag = false;

      val.forEach(function (item) {
        let obj = item;
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>', obj[column]);
        //const textToSearch = d[column][column] && d[column][column].toLowerCase() || '';
        const textToSearch = d[column].toLowerCase() || '';
        if (obj[column] != undefined) {
          if (column == 'role') {
            if (
              textToSearch
                .toLowerCase()
                .indexOf(obj[column][column].toLowerCase()) !== -1
            ) {
              flag = true;
            }
          } else if (column == 'status') {
            if (textToSearch.toLowerCase() == obj[column].toLowerCase()) {
              flag = true;
            }
          }
        }
      });

      if (val.length == 0) flag = true;

      return flag;
    };
  }

  applyFilter(filterValue: string) {
    this.listData.filter = JSON.stringify(filterValue); // filterValue.trim().toLowerCase();
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
  onChange(eve, data) {
    let element = this.listData.data.find((item) => item['_id'] === data._id);
    element['isPresent'] = eve.checked;
    element['attendance'] = element["isPresent"] ? "Attending" : "Rejected";
    

    // this.participantsarray.map((element) => {
    //   if (element['_id'] === data._id) {
    //     this.participantsarray
    //   }
    // });
  }
  saveAttendence() {
    console.log(this.listData.data);
    let praticipantarr = [];
    var req: any;
    for (let i = 0; i < this.listData.data.length; i++) {
      praticipantarr.push({
        isPresent: this.listData.data[i]['isPresent'],
        _id: this.listData.data[i]['_id'],
        user: this.listData.data[i]['user']['_id'],
      });
      //   console.log('praticipantarr:', praticipantarr);
    }
    req = { participants: praticipantarr };

    console.log(praticipantarr);
    this.othermeetinginfoservice
      .updateOtherMeetings(req, this.data.parentId)
      .subscribe(
        (response) => {
          const result = response.body;
          this.closeDialog();
        },
        (err) => {
          var errormessages = 'Please correct the errors - \n';
          err.error.errors.forEach(
            (element) => (errormessages += ',' + element.msg + '\n')
          );
        }
      );
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { language } from '@shared/config/language';
@Component({
  selector: 'app-commiteeoutcometab',
  templateUrl: './commiteeoutcometab.component.html',
  styleUrls: ['./commiteeoutcometab.component.css'],
})
export class CommiteeoutcometabComponent implements OnInit {
  resource = language;
  @Input() parentid: String;
  @Input() agendaid: String;
  @Input() viewdata: Boolean;
  @Input() isVoting: boolean;

  constructor() {}

  ngOnInit(): void {
    console.log(this.parentid, this.agendaid);
  }
}

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpParams,
} from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';

@Injectable({
  providedIn: 'root',
})
export class OtherMeetingService {
  constructor(private http: HttpClient) {}

  getOtherMeetings(filter) {
    return this.http.get(apiendpoints.OTHERMEETINGS + filter, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }

  postOtherMeetings(data: any) {
    return this.http.post(
      apiendpoints.OTHERMEETINGS,
      { meeting: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }
  removeOtherMeetings(id: any) {
    return this.http.delete(apiendpoints.OTHERMEETINGS + '/' + id, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }
  getFamilyUsers(filter) {
    return this.http.get(apiendpoints.USERS_FAMILY + filter, {
      headers: new HttpHeaders().set(
        'Authorization',
        `Token ` + localStorage.getItem('ACCESS_TOKEN')
      ),
      observe: 'response',
    });
  }

  updateOtherMeetings(data: any, id) {
    return this.http.put(
      apiendpoints.OTHERMEETINGS + '/' + id,
      { meeting: data },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Token ` + localStorage.getItem('ACCESS_TOKEN')
        ),
        observe: 'response',
      }
    );
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { ForgetComponent } from './modules/forget/forget.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { ChangePasswordComponent } from './modules/change-password/change-password.component';
import { FamilyInfoComponent } from './modules/family-info/family-info.component';
import { PersonaltaskComponent } from './modules/personaltask/personaltask.component';
import { PlaygroundComponent } from './modules/playground/playground.component';
import { RoleComponent } from './modules/uac/role/role.component';
import { AddroleComponent } from './modules/uac/role/addrole/addrole.component';
import { ModuleComponent } from './modules/uac/module/module.component';
import { AddmoduleComponent } from './modules/uac/module/addmodule/addmodule.component';
import { RolepermissionComponent } from './modules/uac/rolepermission/rolepermission.component';
import { UserComponent } from './modules/user/user.component';
import { AdduserComponent } from './modules/user/adduser/adduser.component';
import { FamiliesComponent } from './modules/families/families.component';
import { AddfamilyComponent } from './modules/families/addfamily/addfamily/addfamily.component';
import { AdminknowledgehubComponent } from './modules/adminknowledgehub/adminknowledgehub.component';
import { AddknowledgehubComponent } from './modules/adminknowledgehub/addknowledgehub/addknowledgehub.component';
import { SubscriptionComponent } from '@modules/subscription/subscription.component';
import { AddsubscriptionComponent } from '@modules/subscription/addsubscription/addsubscription.component';
import { SecuritylogsComponent } from '@modules/securitylogs/securitylogs.component';
import { DetailsComponent } from '@modules/securitylogs/details/details.component';
import { AuthGuardService } from './shared/services/Auth-guard.service';
import { AddmemberComponent } from './modules/family-info/addmember/addmember.component';
import { ForgotidComponent } from './modules/forget/forgotid/forgotid.component';
import { TemplatemanagementComponent } from './modules/templatemanagement/templatemanagement.component';
import { KnowledgehubComponent } from '@modules/knowledgehub/knowledgehub.component';
import { OthertemplateComponent } from './modules/templatemanagement/othertemplate/othertemplate.component';
import { MysubscriptionComponent } from '@modules/mysubscription/mysubscription.component';
import { AddpersonaltaskComponent } from '@modules/personaltask/addpersonaltask/addpersonaltask.component';
import { MembershipComponent } from '@modules/membership/membership.component';
import { AddmembershipComponent } from '@modules/membership/addmembership/addmembership.component';
import { DeclarationComponent } from '@modules/declaration/declaration.component';
import { AdddeclarationComponent } from '@modules/declaration/adddeclaration/adddeclaration.component';
import { CommitteetabsComponent } from '@modules/committeetabs/committeetabs.component';
import { SendotpComponent } from './modules/forget/sendotp/sendotp.component';
import { BusinessinfoComponent } from './modules/businessinfo/businessinfo.component';
import { AddbusinessunitComponent } from './modules/businessinfo/businessunit/addbusinessunit/addbusinessunit.component';
import { CommitteeS1Component } from '@modules/committeetabs/addcommittee/committee-s1/committee-s1.component';
import { CommitteeS2Component } from '@modules/committeetabs/addcommittee/committee-s2/committee-s2.component';
import { CommitteeS4Component } from '@modules/committeetabs/addcommittee/committee-s4/committee-s4.component';
import { CommitteeS7Component } from '@modules/committeetabs/addcommittee/committee-s7/committee-s7.component';
import { CommitteeS6Component } from '@modules/committeetabs/addcommittee/committee-s6/committee-s6.component';
import { CommitteeS3Component } from '@modules/committeetabs/addcommittee/committee-s3/committee-s3.component';
import { CommonspacetabsComponent } from '@modules/commonspacetabs/commonspacetabs.component';
import { CommonspacecategoryComponent } from '@modules/commonspacetabs/commonspacecategory/commonspacecategory.component';
import { CommonspacesComponent } from '@modules/commonspacetabs/commonspaces/commonspaces.component';
import { AddcommonspaceComponent } from '@modules/commonspacetabs/commonspaces/addcommonspace/addcommonspace.component';
import { CategoryComponent } from '@modules/knowledgehub/category/category.component';
import { FafilesComponent } from '@modules/knowledgehub/fafiles/fafiles.component';
import { SafilesComponent } from '@modules/knowledgehub/safiles/safiles.component';
import { CommitteeS5Component } from '@modules/committeetabs/addcommittee/committee-s5/committee-s5.component';
import { SettingsComponent } from '@modules/settings/settings.component';
import { PollsComponent } from '@modules/polls/polls.component';
import { AddpollsComponent } from '@modules/polls/addpolls/addpolls.component';
import { AchievementsComponent } from './modules/achievements/achievements.component';
import { TriggerpointsComponent } from './modules/achievements/triggerpoints/triggerpoints.component';
import { CommonspacebookingComponent } from '@modules/commonspacetabs/commonspacebooking/commonspacebooking.component';
import { AddbookingComponent } from '@modules/commonspacetabs/commonspacebooking/addbooking/addbooking.component';
import { FamilyofficeComponent } from '@modules/familyoffice/familyoffice.component';
import { ViewcalendarComponent } from './modules/commonspacetabs/commonspacebooking/viewcalendar/viewcalendar.component';
import { FirstsectionComponent } from '@modules/familyoffice/ticket/addticket/firstsection/firstsection.component';
import { SecondsectionComponent } from '@modules/familyoffice/ticket/addticket/secondsection/secondsection.component';
import { ThirdsectionComponent } from '@modules/familyoffice/ticket/addticket/thirdsection/thirdsection.component';
import { IdpComponent } from '@modules/idp/idp.component';
import { MeetingComponent } from './modules/idp/meeting/meeting.component';
import { GoalComponent } from './modules/idp/goal/goal.component';
import { AddgoalComponent } from './modules/idp/goal/addgoal/addgoal.component'; // a plugin!
import { OthermessagetemplateComponent } from './modules/othermessagetemplate/othermessagetemplate.component';
import { OthermeetingsComponent } from './modules/othermeetings/othermeetings.component'; // a plugin!
import { AddothermeetingsComponent } from './modules/othermeetings/addothermeetings/addothermeetings.component'; // a plugin!
import { IdpdetailsComponent } from './modules/idp/idpdetails/idpdetails.component'; // a plugin!
import { SchedulemeetingComponent } from './modules/idp/meeting/schedulemeeting/schedulemeeting.component'; // a plugin!
import { CreatemeetingComponent } from './modules/idp/meeting/createmeeting/createmeeting.component'; // a plugin!
import { MeetingagendaComponent } from './modules/idp/meeting/meetingagenda/meetingagenda.component'; // a plugin!
import { RationaliseagendaComponent } from './modules/idp/meeting/rationaliseagenda/rationaliseagenda.component'; // a plugin!
import { TakeattendenceComponent } from './modules/idp/meeting/takeattendence/takeattendence.component'; // a plugin!
import { AddoutcomeComponent } from './modules/idp/meeting/addoutcome/addoutcome.component'; // a plugin!
import { OutcometabComponent } from './modules/idp/meeting/newoutcome/outcometab/outcometab.component'; // a plugin!
import { ReportsComponent } from '@modules/reports/reports.component';
import { ReportCategoryComponent } from '@modules/reports/category/reportcategory.component';
import { ReportlistComponent } from '@modules/reports/reportlist/reportlist.component';
import { AddcategoryComponent } from '@modules/reports/category/addcategory/addcategory.component';
import { AddreportComponent } from '@modules/reports/reportlist/addreport/addreport.component';
import { MinuteofmeetingComponent } from './modules/idp/meeting/minuteofmeeting/minuteofmeeting.component'; // a plugin!
import { EditgoalComponent } from './modules/idp/goal/editgoal/editgoal.component'; // a plugin!
import { GoalprogressComponent } from './modules/idp/goal/goalprogress/goalprogress.component'; // a plugin!
import { CommitteemeetingtabComponent } from './modules/CommitteeMeeting/committeemeetingtab/committeemeetingtab.component';
import { CreatecommitteemeetingComponent } from './modules/CommitteeMeeting/createcommitteemeeting/createcommitteemeeting.component'; // a plugin!
import { MeetingParticipantsComponent } from './modules/CommitteeMeeting/meeting-participants/meeting-participants.component'; // a plugin!
import { AgendatabComponent } from './modules/CommitteeMeeting/agendatab/agendatab.component';
import { CommitteratonalizeComponent } from './modules/CommitteeMeeting/committeratonalize/committeratonalize.component'; // a plugin!
import { CommitteemomComponent } from './modules/CommitteeMeeting/committeemom/committeemom.component'; // a plugin!
import { CommittesuggestedtabComponent } from './modules/CommitteeMeeting/committesuggestedtab/committesuggestedtab.component'; // a plugin!
import { CommitteemeetingrationalizedComponent } from './modules/CommitteeMeeting/committeemeetingrationalized/committeemeetingrationalized.component'; // a plugin!
import { CommitteeattendenceComponent } from './modules/CommitteeMeeting/committeeattendence/committeeattendence.component'; // a plugin!
import { CommitteeresolutionComponent } from './modules/CommitteeMeeting/committeeresolution/committeeresolution.component'; // a plugin!
import { PasswordRecoveryByEmailComponent } from '@modules/forget/password-recovery-by-email/password-recovery-by-email.component';
import { PasswordResetTokenComponent } from '@modules/forget/password-reset-token/password-reset-token.component';
import { RoleGuardService } from '@services/Role-guard.service';
import { LoginGuardService } from '@services/Login-guard.service';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuardService],
  },
  {
    path: 'forget',
    component: ForgetComponent,
    canActivate: [LoginGuardService],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'changePassword',
    component: ChangePasswordComponent,
    canActivate: [LoginGuardService],
  },
  {
    path: 'familyinfo',
    component: FamilyInfoComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'personaltask',
    component: PersonaltaskComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'personaltask/add',
    component: AddpersonaltaskComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  { path: 'logout', component: LoginComponent },
  {
    path: 'play',
    component: PlaygroundComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  }, //canActivate: [AuthGuard]
  {
    path: 'role',
    component: RoleComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'role/add',
    component: AddroleComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'user',
    component: UserComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'user/add',
    component: AdduserComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'module',
    component: ModuleComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'module/add',
    component: AddmoduleComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'families',
    component: FamiliesComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'families/add',
    component: AddfamilyComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'adminknowledgehub',
    component: AdminknowledgehubComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'adminknowledgehub/add',
    component: AddknowledgehubComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'subscriptions',
    component: SubscriptionComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'subscriptions/add',
    component: AddsubscriptionComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'rolepermission',
    component: RolepermissionComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'security',
    component: SecuritylogsComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'security/details',
    component: DetailsComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'forgotId',
    component: ForgotidComponent,
    canActivate: [LoginGuardService],
  },
  {
    path: 'password-recovery-by-email',
    component: PasswordRecoveryByEmailComponent,
    canActivate: [LoginGuardService],
  },
  {
    path: 'password-reset-token',
    component: PasswordResetTokenComponent,
    canActivate: [LoginGuardService],
  },

  {
    path: 'templatemanagement',
    component: TemplatemanagementComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'knowledgehub',
    component: KnowledgehubComponent,
    canActivate: [AuthGuardService, RoleGuardService],
    children: [
      {
        path: 'category',
        component: CategoryComponent,
        pathMatch: 'full',
      },
      {
        path: 'faknowledgehub',
        component: FafilesComponent,
        pathMatch: 'full',
      },
      {
        path: 'saknowledgehub',
        component: SafilesComponent,
        pathMatch: 'full',
      },
    ],
  },

  {
    path: 'messagetemplate',
    component: OthertemplateComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'mysubscription',
    component: MysubscriptionComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },

  {
    path: 'membership',
    component: MembershipComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'membership/add',
    component: AddmembershipComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'declaration',
    component: DeclarationComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'declaration/add',
    component: AdddeclarationComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee',
    component: CommitteetabsComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/add/s1',
    component: CommitteeS1Component,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/add/s2',
    component: CommitteeS2Component,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/add/s3',
    component: CommitteeS3Component,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/add/s4',
    component: CommitteeS4Component,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/add/s5',
    component: CommitteeS5Component,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/add/s6',
    component: CommitteeS6Component,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/add/s7',
    component: CommitteeS7Component,
    canActivate: [AuthGuardService, RoleGuardService],
  },

  {
    path: 'commonspace',
    component: CommonspacetabsComponent,
    canActivate: [AuthGuardService, RoleGuardService],
    children: [
      {
        path: 'category',
        component: CommonspacecategoryComponent,
        pathMatch: 'full',
      },
      {
        path: 'commonspaces',
        component: CommonspacesComponent,
        pathMatch: 'full',
      },
      {
        path: 'booking',
        component: CommonspacebookingComponent,
        pathMatch: 'full',
      },
    ],
  },

  {
    path: 'commonspace/add',
    component: AddcommonspaceComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'sendotp',
    component: SendotpComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'businessinfo',
    component: BusinessinfoComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'businessunit/add',
    component: AddbusinessunitComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'polls',
    component: PollsComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'polls/add',
    component: AddpollsComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'achievements',
    component: AchievementsComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'achievements/summary',
    component: TriggerpointsComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'booking/add',
    component: AddbookingComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'tickets',
    component: FamilyofficeComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'calendar',
    component: ViewcalendarComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'tickets/s1',
    component: FirstsectionComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'tickets/s2',
    component: SecondsectionComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'tickets/s3',
    component: ThirdsectionComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'idp',
    component: IdpComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'goal',
    component: GoalComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'meeting',
    component: MeetingComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'goal/add',
    component: AddgoalComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'othermessage',
    component: OthermessagetemplateComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'othermeeting',
    component: OthermeetingsComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'othermeeting/add',
    component: AddothermeetingsComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'idpdetails',
    component: IdpdetailsComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'schedulemeeting',
    component: SchedulemeetingComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'createmeeting',
    component: CreatemeetingComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'agenda',
    component: MeetingagendaComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'rationalise',
    component: RationaliseagendaComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'attendence',
    component: TakeattendenceComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'outcome',
    component: AddoutcomeComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'outcometab',
    component: OutcometabComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'reports/category/add',
    component: AddcategoryComponent,
    canActivate: [AuthGuardService, RoleGuardService],
    pathMatch: 'full',
  },
  {
    path: 'reports/report/add',
    component: AddreportComponent,
    canActivate: [AuthGuardService, RoleGuardService],
    pathMatch: 'full',
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuardService, RoleGuardService],
    children: [
      {
        path: 'category',
        component: ReportCategoryComponent,
        pathMatch: 'full',
      },
      {
        path: 'reportlist',
        component: ReportlistComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'mom',
    component: MinuteofmeetingComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'goal/edit',
    component: EditgoalComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'goal/progress',
    component: GoalprogressComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee-meetings',
    component: CommitteemeetingtabComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/meetings',
    component: CreatecommitteemeetingComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/participants',
    component: MeetingParticipantsComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/agenda',
    component: AgendatabComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/rationalize',
    component: CommitteratonalizeComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/upload-offline-mom',
    component: CommitteratonalizeComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/mom',
    component: CommitteemomComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/suggested',
    component: CommittesuggestedtabComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/rationalized',
    component: CommitteemeetingrationalizedComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/attendence',
    component: CommitteeattendenceComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
  {
    path: 'committee/resolution',
    component: CommitteeresolutionComponent,
    canActivate: [AuthGuardService, RoleGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  public static publicrouters = [
    '/login',
    '/register',
    '/forget',
    '/invalidurl',
    '/forgotId',
  ];

  public static userroutes = [];

  constructor(private router: Router) {}
}

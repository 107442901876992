<mat-card class="">
  <mat-card-header> </mat-card-header>
  <mat-card-content>
    <form class="form-inline">
      <div class="form-group mr-2">
        <button
          mat-stroked-button
          class="iconcss"
          (click)="convertToPDF('save')"
        >
          <i class="fa fa-file-pdf fa-lg"></i>
        </button>
        <button
          mat-stroked-button
          class="iconcss"
          (click)="exporter.exportTable('csv', { fileName: 'common-spaces' })"
        >
          <i class="fa fa-file-excel fa-lg"></i>
        </button>
        <button
          mat-stroked-button
          class="iconcss"
          (click)="convertToPDF('print')"
        >
          <i class="fa fa-print fa-lg"></i>
        </button>
      </div>
      <span class="example-spacer"></span>

      <div class="mr-1">
        <button
          [disabled]="!PERMISSION_ADD"
          mat-raised-button
          color="primary"
          class="addbutton"
          class="buttonfloatright"
          (click)="addrole()"
        >
          <i class="fas fa-plus"></i>&nbsp;&nbsp;{{
            resource.message("ADDSPACE") | uppercase
          }}
        </button>
        <input
          matInput
          [(ngModel)]="searchKey"
          placeholder="{{ resource.message('SEARCH_BY_COMMONSPACE') }}"
          autocomplete="off"
          (keyup)="applyfilter()"
          name="searchKey"
          class="searchfloatright"
        />
      </div>
    </form>
    <p>
      <br />
      <mat-table
        #roleTable
        roleTable
        matTableExporter
        [dataSource]="listData"
        matSort
        class="mattable"
        #exporter="matTableExporter"
        [hiddenColumns]="[4]"
        id="print-section"
      >
        <!-- Space Name Column -->
        <ng-container matColumnDef="spacename">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Space Name</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.spacename }}</mat-cell>
        </ng-container>
        <!-- City Column -->
        <ng-container matColumnDef="city">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >City</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.city }}</mat-cell>
        </ng-container>
        <!-- Address 1 Column -->
        <ng-container matColumnDef="caretaker">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Contact Person Name</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.caretaker }}</mat-cell>
        </ng-container>
        <!-- Created on Column -->
        <ng-container matColumnDef="contact">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Contact Number</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.contact }}</mat-cell>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="Action">
          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button
              mat-icon-button
              (click)="edit(row)"
              [disabled]="!PERMISSION_EDIT"
            >
              <mat-icon>mode_edit</mat-icon>
            </button>
            <!-- <button  mat-stroked-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
               class="filtersortborder">
                 <i class="fa fa-sort-down"></i>
               </button>-->
            <button [matMenuTriggerFor]="menu" class="dropmenu">
              <mat-icon class="dropicon">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="menustyle">
              <div class="menuitemstyle">
                <button mat-menu-item (click)="redirectTo('booking')">
                  Booking
                </button>
                <button mat-menu-item (click)="redirectTo('calendar')">
                  Calendar
                </button>
              </div>
            </mat-menu>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 15, 20]"
        [pageSize]="5"
        showFirstLastButtons
      ></mat-paginator>
    </p>
  </mat-card-content>
</mat-card>

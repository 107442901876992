import { Component, OnInit } from '@angular/core';
import { language } from '@shared/config/language';
import { CommitteeService } from '@services/Committee.service';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormArray,
  FormBuilder,
} from '@angular/forms';
import { constants } from '@shared/config/constants';
import { CoreService } from '@shared/services/CoreService';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-createcommitteemeeting',
  templateUrl: './createcommitteemeeting.component.html',
  styleUrls: ['./createcommitteemeeting.component.css'],
})
export class CreatecommitteemeetingComponent implements OnInit {
  resource = language;
  COMMITTEE_LIST: any;
  today: Date;
  minDate = moment().format('YYYY-MM-DDTHH:mm');
  isSubmit: boolean = false;

  TYPE_LIST: any;
  isEdit: boolean = false;
  parentId: string = '';
  form: FormGroup = new FormGroup(
    {
      _id: new FormControl(0),
      committee: new FormControl('', Validators.required),
      meeting_notice: new FormControl('15'),
      title: new FormControl('', Validators.required),
      venue: new FormControl('', Validators.required),
      meeting_days: new FormControl(''),
      frequency: new FormControl('', Validators.required),
      occurrence: new FormControl(0, [Validators.required]),
      start_time: new FormControl(''),
      end_time: new FormControl(''),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('',  Validators.required),
      is_full_day: new FormControl(true),
      type: new FormControl('committee'),
    },
    this.timeValidator('start_time', 'end_time')
  );

  constructor(
    private committeeService: CommitteeService,
    private router: Router,
    private coreservice: CoreService,
    private activeRoute: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.today = new Date();
  }

  ngOnInit(): void {
    
    this.getCommittees('committee', '');
    this.TYPE_LIST = constants.committee_meeting_frquency;
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentId = data.id;
        this.isEdit = true;
        this.getAllCommiteeMeetings(this.parentId);
      }
    });
  }

  timeValidator(controlName1: string, controlName2: string) {
    return (formGroup: FormGroup) => {
      const start_date = formGroup.controls['start_date'];
      const end_date = formGroup.controls['end_date'];

      const start_date_time = new Date(
        this.datePipe.transform(start_date.value) 
      );
      const end_date_time = new Date(
        this.datePipe.transform(end_date.value) 
      );
      console.log('start_date_time ', start_date_time);
      console.log(
        'start_date_time > end_date_time',
        start_date_time > end_date_time
      );

      if (
        start_date_time > end_date_time &&
        formGroup.controls['is_full_day'].value
      ) {
        end_date.setErrors({ endTimeGreaterThanStartTime: true });
      } else {
        end_date.setErrors(null);
      }
      return null;
    };
  }

  onDateRangeChanged(type: 'start' | 'end') {
    if (moment(this.form.value.start_date) > moment(this.form.value.end_date)) {
      if (type == 'start') {
        this.form.controls['start_date'].setErrors({ invalidDate: true });
      } else {
        this.form.controls['end_date'].setErrors({ invalidDate: true });
      }
    }
  }
  getAllCommiteeMeetings(id) {
    var filter = '/' + id;

    this.committeeService.getCommitteeMeetings(filter).subscribe(
      (response: any) => {
        const result = response.data;
        console.log('response', response);
        this.form.patchValue({
          _id: id,
          committee: result.committee._id,
          meeting_notice: result.meeting_notice,
          title: result.title,
          venue: result.venue,
          meeting_days: result.meeting_days,
          frequency: result.frequency,
          occurrence: result.occurrence,
          start_time: result.start_time,
          end_time: result.end_time,
          start_date: result.start_date ? new Date(result.start_date ).toISOString().slice(0, 16): undefined,
          end_date: result.end_date ? new Date(result.end_date).toISOString().slice(0, 16): undefined,
          is_full_day: result.is_full_day,
          type: result.type,
        });
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  getCommittees(type, committee) {
    console.log(type);

    this.committeeService
      .getCommitteesFilter(type, '')
      .subscribe((response) => {
        this.COMMITTEE_LIST = response.body['data'].filter(val => val.is_published);
        console.log(this.COMMITTEE_LIST);
      });
  }
  submitData() {
    if (this.form.invalid) {
      console.log(this.form);
      return;
    }
    this.isSubmit = true
    if (this.isEdit && this.parentId !== '') {
      if(this.form.dirty) {
        sessionStorage.setItem(this.parentId, 'true')
      }
      this.form.removeControl('_id');
      const postData = this.form.getRawValue();
      if(!postData.end_date) {
        postData.end_date = undefined
        postData.end_time = undefined
      }
      else {
        postData.end_time = postData.end_date.split('T')[1];
      }

      if(!postData.start_date) {
        postData.start_date = undefined; 
        postData.start_time = undefined;
      }
      else {
        postData.start_time = postData.start_date.split('T')[1];
      }

      //return;
      this.committeeService
        .updateCommitteeMeetings(this.parentId, postData)
        .subscribe(
          (response: any) => {
            console.log(response.data._id);

            this.router.navigate(['/committee/participants'], {
              queryParams: {
                id: response.data._id,
              },
            });
          },
          (err) => {
            this.isSubmit = false
            console.log('errrrrrrr', err);
          }
        );
    } else {
      this.form.removeControl('_id');
      const postData = this.form.getRawValue();
      if(postData.end_date === "" ) {
        postData.end_date = undefined
        postData.end_time = undefined
      }
      else {
        postData.end_time = postData.end_date.split('T')[1];
      }

      if(postData.start_date === "" ) {
        postData.start_date = undefined; 
        postData.start_time = undefined;
      }
      else {
        postData.start_time = postData.start_date.split('T')[1];
      }
      //return;
      this.committeeService.postCommitteeMeetings(postData).subscribe(
        (response: any) => {
          console.log(response.data._id);

          this.router.navigate(['/committee/participants'], {
            queryParams: {
              id: response.data._id,
            },
          });
        },
        (err) => {
          this.isSubmit = false
          console.log('errrrrrrr', err);
        }
      );
    }
  }
}

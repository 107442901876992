<div align="right">
  <div class="content-container">
    <mat-card class="cardclass">
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title class="text-left"> {{ resource.message("MEMBER") }} </mat-card-title>
          <mat-card-subtitle class="mat-card-subtitle"> One secretary & One chairman should be selected</mat-card-subtitle>
        </mat-card-title-group>

        <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
      </mat-card-header>
      <hr />
      <mat-card-content>
        <p class="text-danger text-center" *ngIf="errorMessage">{{errorMessage}}</p>
        <mat-form-field appearance="outline" class="field-spacer1">
          <mat-label>Search</mat-label>
          <input
            matInput
            [(ngModel)]="searchKey"
            placeholder="{{ resource.message('SEARCH_BY_MEMBER') }}"
            autocomplete="off"
            (keyup)="applyfilter()"
            name="searchKey"
            class="searchfloatright"
          />
        </mat-form-field>

        <div class="memberslist">
          <mat-table
            #roleTable
            roleTable
            matTableExporter
            [dataSource]="listData"
            matSort
            class="mattable"
          >
            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                >
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngStyle]="{'border-top': (this.uncompletedDataSet[row['_id']] == true) ? '1px solid red' : '', 'border-bottom': (this.uncompletedDataSet[row['_id']] == true) ? '1px solid red' : ''}">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? checkboxToggle($event, row) : null"
                  [checked]="selection.isSelected(row)"
                >
                </mat-checkbox>
              </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                >Name</mat-header-cell
              >
              <mat-cell *matCellDef="let element" [ngStyle]="{'border-top': (this.uncompletedDataSet[element['_id']] == true) ? '1px solid red' : '', 'border-bottom': (this.uncompletedDataSet[element['_id']] == true) ? '1px solid red' : ''}">{{ element.name }}</mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="role">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                >Role</mat-header-cell
              >
              <mat-cell *matCellDef="let element; let $i = index;" [ngStyle]="{'border-top': (this.uncompletedDataSet[element['_id']] == true) ? '1px solid red' : '', 'border-bottom': (this.uncompletedDataSet[element['_id']] == true) ? '1px solid red' : ''}">
                <mat-select style="text-align: left"
                  placeholder="Committee Role"
                  [disabled]="element.type == 'permanent_invitee'"
                  (selectionChange)="
                    onRoleChange($event, element, $i)
                  "
                  [value]="element.type == 'permanent_invitee' ? 'invitee' : ''"
                >
                  <ng-container *ngFor="let type of ROLES_LIST | keyvalue">
                    <ng-container *ngIf="rolesCondition(type, element) === true"
                      ><mat-option [value]="type.key">{{
                        type.value
                      }}</mat-option></ng-container
                    >
                  </ng-container>
                </mat-select>
              </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                >Type</mat-header-cell
              >
              <mat-cell *matCellDef="let element; let $i = index;" [ngStyle]="{'border-top': (this.uncompletedDataSet[element['_id']] == true) ? '1px solid red' : '', 'border-bottom': (this.uncompletedDataSet[element['_id']] == true) ? '1px solid red' : ''}">
                <mat-select style="text-align: left"
                  placeholder="Committee Type"
                  (selectionChange)="onTypeChanged($event, element, $i)"
                >
                  <mat-option
                    *ngFor="let type of TYPE_LIST | keyvalue"
                    [value]="type.key"
                    >{{ type.value }}</mat-option
                  >
                </mat-select>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns" (click)="selectedRow=row;"></mat-row>
          </mat-table>
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <button
          mat-raised-button
          color="primary"
          class="btn btn-primary login-btn button-wider wide"
          type="button"
          (click)="save()"
        >
          Save
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

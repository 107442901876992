import { Component, OnInit, Inject } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormArray,
} from '@angular/forms';
import { language } from '@shared/config/language';
import { FileService } from '@services/File.service';
import { BusinessInfoService } from '@services/BusinessInfo.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CoreService } from '@services/CoreService';
import { CommitteeService } from '@services/Committee.service';

@Component({
  selector: 'app-dialoggoalcategory',
  templateUrl: './dialoggoalcategory.component.html',
  styleUrls: ['./dialoggoalcategory.component.css'],
})
export class DialoggoalcategoryComponent implements OnInit {
  file_type = new FormControl('category');

  form: FormGroup = new FormGroup({
    title: new FormControl(''),
    parent: new FormControl(''),
    _id: new FormControl(''),
    categroy_type: new FormControl('category'),
    icon: new FormControl('newicon'),
    color: new FormControl('black'),
    description: new FormControl(''),
  });
  resource = language;
  isEdit: boolean = false;
  CATEGORY: any;
  constructor(
    public dialogRef: MatDialogRef<DialoggoalcategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private committeeService: CommitteeService,
    private coreservice: CoreService
  ) {}

  ngOnInit(): void {
    var filter = '';
    if (this.data.id !== '') {
      filter = '?filter[_id]=' + this.data.id;
      this.getEditData(filter);
      this.form.patchValue({
        _id: this.data.id,
      });
    }
    this.getAllCategories(filter);

    this.isEdit = this.data.isEdit;
  }

  async submitData() {
    const type1 = this.form.getRawValue();

    console.log(type1);
    if (this.form.invalid) {
      return;
    }
    //return;
    const type = this.form.getRawValue();
    if (type['categroy_type'] == 'category') {
      this.form.removeControl('parent');
    }
    this.form.removeControl('category_type');

    if (!this.isEdit) this.form.removeControl('_id');

    const req = this.form.getRawValue();
    req['description'] = req['title'];
    this.committeeService.postCommitteeCategories(req).subscribe(
      (response) => {
        const result = response.body;
        this.coreservice.success(this.resource.message('CATEGORY_SAVE'));

        this.closeDialog();
      },
      (err) => {}
    );
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
  getAllCategories(id) {
    // alert('IN');
    this.committeeService.getCommitteeCategories(id).subscribe(
      (response) => {
        this.CATEGORY = response.body['data'].filter(
          (catid) => catid.parent === undefined
        );
      },
      (err) => {}
    );
  }

  getEditData(id) {
    this.committeeService.getCommitteeCategories(id).subscribe(
      (response) => {
        console.log(response.body['data']);
        const titleName = response.body['data'].filter(
          (catid) => catid._id == this.data.id
        );
        console.log(':::::::::::::', titleName[0]);
        if (titleName[0].parent !== undefined) {
          this.file_type.setValue('sub-category');
          this.form.patchValue({
            parent: titleName[0].parent._id,
          });
        } else {
          this.file_type.setValue('category');
        }
        this.form.patchValue({
          title: titleName[0].title,
        });
      },
      (err) => {}
    );
  }
}

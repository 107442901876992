<div class="content p30">
  <h5>{{ resource.message("IDP") }}</h5>
  <mat-card class="">
    <mat-card-header>
      <mat-card-title> {{ resource.message("IDPTITLE") }} </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group class="tabgroup" [selectedIndex]="activePath">
        <mat-tab label="IDP" class="test">
          <app-idplisting></app-idplisting>
        </mat-tab>
        <mat-tab label="Category" class="test">
          <app-idpcategory></app-idpcategory>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>

import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import { environment } from '@environments/environment';
import {LoginFormGroup} from '@models/login.formgroup'
import {FormGroup,FormControl, FormControlName,Validator, Validators} from '@angular/forms';
import { AuthService } from '@services/Auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.css']
}) 

export class ForgetComponent implements OnInit {

  loginform:FormGroup;
  submitted:Boolean = false;
  errmessage = "";

  field = "Username";

  constructor(private loginformservice:LoginFormGroup, 
    private authservice:AuthService,
    private router : Router) {

      

   }

  ngOnInit(): void {
    

    this.loginformservice.initializeFormGroup();
    this.loginform = this.loginformservice.form;
    if(this.router.url.includes("username")){
        this.field = "Username";
    }
    else if(this.router.url.includes("password")){
      this.field = "Password";
    }
    
  }




  login():void{
    this.submitted = true;

    
    if (this.loginform.invalid) {
      
        return;
    }
    this.authservice.login(this.loginform.getRawValue()).subscribe(response=> {
      
      const result = response.body;
      this.authservice.localsave(result);
      this.router.navigate(['dashboard']);

     }, (err) => {

        if(err.error.status != null){
          this.errmessage = err.error.message;
        }else{
          this.errmessage = err.error.errors[0].msg;
        }
      
   })
  }

  get f() { return this.loginform.controls; }

  submitData(){
    this.router.navigate(['sendotp']);

    
  }

}






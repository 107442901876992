import { Component, OnInit ,Inject} from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators,FormArray} from '@angular/forms';
import {language} from '@shared/config/language';
import { FileService } from '@services/File.service';
import {BusinessInfoService} from '@services/BusinessInfo.service';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {CoreService} from '@services/CoreService';
import { title } from 'process';
import { constants } from '@shared/config/constants';

@Component({
  selector: 'app-dialogboardreads',
  templateUrl: './dialogboardreads.component.html',
  styleUrls: ['./dialogboardreads.component.css']
})
export class DialogboardreadsComponent implements OnInit {
  file_type = new FormControl('PDF');
  today: Date;
  form :FormGroup = new FormGroup({
    type:new FormControl(''),
    business_information:new FormControl(''),
    title:new FormControl('',Validators.required),
    url:new FormControl(''),
    file:new FormControl(''),
    _id:new FormControl(''),
    date:new FormControl(new Date()),
    uploadtype:new FormControl('',Validators.required),
    date_to:new FormControl('',Validators.required)

  }) 
  resource = language;
  selFile = new Object();
  selFileName = "";
  isEdit:boolean=false;
  CATEGORY_LIST:any;
  CONSTANT = constants;

  constructor(private fileService: FileService,    
    private businessinfoservice: BusinessInfoService,
    public dialogRef : MatDialogRef<DialogboardreadsComponent>,
    private coreservice:CoreService,
    @Inject(MAT_DIALOG_DATA) public data,


    ) { 
    this.today = new Date();
    }

  ngOnInit(): void {
    this.CATEGORY_LIST = constants.reads_category;
    this.form.patchValue({'uploadtype': 'PDF'});

    if(this.data.editindex!=='-1')
    {
      const loadData=this.data.editindex
      console.log('><><><><><><><',loadData)
      this.form.patchValue({
        type:loadData.type,
        business_information:loadData.business_information,
        title:loadData.title,
        url:loadData.url,
        file:null,
        _id:this.data.id,
        date:loadData.date,
        date_to:loadData.date_to
    
      })
      this.file_type.setValue(loadData.uploadtype)
      this.isEdit=true;
    }
    else
    {
      this.form.patchValue({
        business_information:this.data.id
      })
      this.isEdit=false
    }
    }

    onChangeUploadType(value: string) {
       console.log("upload type is: ",value)
      this.form.patchValue({'uploadtype': value});
    }

  onFileChange(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    this.selFile =element.files[0];
    this.selFileName = element.files[0].name;
   }
  
  async submitData(){ 
    console.log(this.form)
    if (!this.form.valid) {
      return;
  }
  
  let fileurl = this.selFileName; 
  var formData: any = new FormData();
  formData.append("file", this.selFile);
  if(this.selFileName!='')
  {
    fileurl = await this.fileService.uploadFile(  formData, true, false );
 
  }
  var constdata=this.form.getRawValue();
  console.log("*************************",fileurl)
  if(fileurl!=='')
  {
    constdata['url']=fileurl
    
  
  }
  delete constdata['file'];
  console.log(">>>>>>>>>>>>>>>>>>>",this.data.editindex)
  if(this.data.editindex!=='-1')
  {
    
  }
  else
  {
  //  constdata['type']='financial_statements'
   // constdata['business']=this.data.id
    delete constdata['_id'];
    
  }
  this.businessinfoservice.postBoardReadsInfo(constdata).subscribe(response=> {
    const result = response.body;
    this.coreservice.success(this.resource.message('BUSINESS_IMAGE_SAVE'));
    this.closeDialog()
    }, (err) => {})
 
 // delete constdata['_id'];
 
 
}

closeDialog()
  {
    this.dialogRef.close(false)
  }

}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommitteeService } from '@services/Committee.service';
import { constants } from '@shared/config/constants';
import { language } from '@shared/config/language';
import { CoreService } from '@services/CoreService';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-committee-s7',
  templateUrl: './committee-s7.component.html',
  styleUrls: ['./committee-s7.component.css'],
})
export class CommitteeS7Component implements OnInit {
  resource = language;
  steps = [];
  isTemplate: boolean = false;

  TYPE_LIST: any;
  CATEGORY_LIST: any;

  COMMITTEE_LIST: any;
  SUBCOMMITTEE_LIST: any;

  isEdit: Boolean = false;

  CURRENT_COMMITTEE = constants.CURRENT_COMMITTEE;

  COMMITEE_ID = '';

  TEMPLATE: Boolean = false;

  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    code_of_conduct: new FormControl('', Validators.required),
    is_published: new FormControl(''),
  });

  constructor(
    private committeeService: CommitteeService,
    private coreservice: CoreService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.template != undefined) {
        this.TEMPLATE = JSON.parse(data.template);
        //        if (this.TEMPLATE == 1) this.isTemplate = true;
        //        else this.isTemplate = false;
      }

      if (this.isTemplate) {
        this.steps = constants.committee_template_steps;
      } else {
        this.steps = constants.committee_steps;
      }

      if (data.id != undefined) {
        this.form.patchValue({ _id: data.id });
        this.COMMITEE_ID = data.id;
        this.committeeService
          .getCommittee(this.COMMITEE_ID)
          .subscribe((response) => {
            const result = response.body;
            this.CURRENT_COMMITTEE = result['data'];

            this.form.patchValue({
              _id: this.CURRENT_COMMITTEE['_id'],
              code_of_conduct: this.CURRENT_COMMITTEE['code_of_conduct'],
            });
          });
      } else {
        this.router.navigate(['committee']);
      }
    });
  }

  submit(type): void {
    console.log(this.form);
    if (this.form.invalid) {
      return;
    }

    var rawcommittee = this.form.getRawValue();
    if (type === 'final') {
      rawcommittee['is_published'] = true;
    } else {
      rawcommittee['is_published'] = false;
    }

    this.committeeService.addCommittee(rawcommittee).subscribe(
      (response) => {
        const result = response.body;

        constants.CURRENT_COMMITTEE = result['data'];
        if (type == 'final') {
          if (this.TEMPLATE == false) {
            this.coreservice.success(
              this.resource.message('COMMITTEE_PUBLISH')
            );
            this.router.navigate(['committee']);
          } else {
            this.coreservice.success(this.resource.message('COMMITTEE_SAVE'));
            this.router.navigate(['templatemanagement']);
          }
        } else {
          if (this.TEMPLATE == false) {
            this.coreservice.success('Committee saved as draft');

            this.router.navigate(['committee']);
          } else {
            this.coreservice.success('Committee saved as draft');
            // this.coreservice.success(this.resource.message('COMMITTEE_SAVE'));
            this.router.navigate(['templatemanagement']);
          }
        }
      },
      (err) => {
        console.log(err);
        //      var errormessages = "Please correct the errors - \n";
        //      err.error.errors.forEach(element => errormessages += " - "+element.msg+"\n");
        this.coreservice.error('Invalid Committee - ' + err.error.message);
        console.log('error  :::', err);

        //  this.form.addControl('_id', new FormControl(''));
      }
    );
  }

  getCommittees(type, committee) {
    console.log(type);

    this.committeeService
      .getCommitteesFilter(type, committee)
      .subscribe((response) => {
        if (type == 'committee') {
          this.COMMITTEE_LIST = response.body['data'];
        } else if (type == 'subcommittee') {
          this.SUBCOMMITTEE_LIST = response.body['data'];
          console.log(this.SUBCOMMITTEE_LIST);
        }
      });
  }

  previous() {
    this.router.navigate(['committee/add/s6'], {
      queryParams: { id: this.COMMITEE_ID, template: this.TEMPLATE },
    });
  }
}

<mat-card class="">
  <mat-card-content>
    <form class="form-inline">
      <div class="form-group mr-2">
        <button
          mat-stroked-button
          [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu"
          class="filtersortborder"
        >
          <i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;Filter
        </button>
        <mat-menu #menu="matMenu" class="menustyle">
          <span style="color: #b4b1b1">Filter By</span>
          <div class="menuitemstyle">
            <p>Status</p>
            <mat-checkbox
              class="mat-menu-item"
              (change)="filterData($event, 'request_status', 'in_review')"
              (click)="stoppro($event)"
              >In Review</mat-checkbox
            >
            <mat-checkbox
              class="mat-menu-item"
              (change)="filterData($event, 'request_status', 'in_progress')"
              (click)="stoppro($event)"
              >In Progress</mat-checkbox
            >
            <mat-checkbox
              class="mat-menu-item"
              (change)="filterData($event, 'request_status', 'completed')"
              (click)="stoppro($event)"
              >Completed</mat-checkbox
            >
            <mat-checkbox
              class="mat-menu-item"
              (change)="filterData($event, 'request_status', 'declined')"
              (click)="stoppro($event)"
              >Declined</mat-checkbox
            >
            <hr />
            <button mat-raised-button color="primary">Apply</button>
          </div>
        </mat-menu>
      </div>
      <span class="example-spacer"></span>
      <div class="mr-1">
        <input
          matInput
          [(ngModel)]="searchKey"
          placeholder="Search"
          autocomplete="off"
          (keyup)="applyfilter()"
          name="searchKey"
          class="searchfloatright"
        />
      </div>
    </form>
    <p>
      <br />
      <mat-table
        #roleTable
        roleTable
        matTableExporter
        [dataSource]="listData"
        matSort
        class="mattable"
        #exporter="matTableExporter"
        [hiddenColumns]="[5, 6]"
      >
        <!-- Name Column -->
        <ng-container matColumnDef="created_at">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Created On</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.created_at | customDate
          }}</mat-cell>
        </ng-container>

        <!-- Mobile Column -->
        <ng-container matColumnDef="ticket_no">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Ticket Number</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.ticket_no }}</mat-cell>
        </ng-container>
        <!-- Ticket Assigned To -->
        <ng-container matColumnDef="specialist">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Assigned To</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.specialist
          }}</mat-cell>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Description</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.description
          }}</mat-cell>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="request_status">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Status</mat-header-cell
          >
          <mat-cell *matCellDef="let element"
            ><span
              [ngClass]="
                element.request_status == 'completed'
                  ? 'status-active'
                  : '' || element.request_status == 'declined'
                  ? 'status-inactive'
                  : '' || element.request_status == 'in_review'
                  ? 'status-completed'
                  : 'status-progress'
              "
            ></span
          ></mat-cell>
        </ng-container>
        <!-- Action Column -->
        <ng-container matColumnDef="Action">
          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button
              mat-icon-button
              (click)="edit(row)"
              [disabled]="!PERMISSION_EDIT"
            >
              <mat-icon>mode_edit</mat-icon>
            </button>
            <!--   <button mat-icon-button color="warn"  (click)="onDelete(row._id)"   ><mat-icon>delete_outline</mat-icon></button>-->
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 15, 20]"
        [pageSize]="5"
        showFirstLastButtons
      ></mat-paginator>
    </p>
  </mat-card-content>
</mat-card>

import { environment } from '../../../environments/environment';

export class apiendpoints {
  public static PASSWORD_RECOVERY_BY_EMAIL =
    environment.webservices + '/core/users/reset-password-by-email';
  public static VALIDATE_RESET_TOKEN =
    environment.webservices + '/core/users/ValidPasswordToken';
  public static SET_NEW_PASSWORD_USING_TOKEN =
    environment.webservices + '/core/users/new-password';

  public static AUTH_LOGIN = environment.webservices + '/core/users/login';
  public static AUTH_FAMILY_INFO = environment.webservices + '/family/info';
  public static ROLE = environment.webservices + '/core/role';
  public static MODULE = environment.webservices + '/core/module';
  public static PERMISSIONS = environment.webservices + '/core/permission';
  public static ROLEMODULE = environment.webservices + '/core/rolemodule';
  public static ROLEMODULESPECIFIC =
    environment.webservices + '/core/rolemodule/role';
  public static USERS = environment.webservices + '/core/users';
  public static USERS_FAMILY = environment.webservices + '/family/users';
  public static FAMILIES = environment.webservices + '/core/families';
  public static ADMINKNOWLEDGEHUB =
    environment.webservices + '/core/knowledgebase';
  public static FILE_REMOTE =
    environment.webservices + '/core/media/uploadbyurl';
  public static KNOWLEDGEHUB =
    environment.webservices + '/family/knowledgebase';
  public static KNOWLEDGEHUBCATEGORY =
    environment.webservices + '/family/knowledgebasecategory';
  public static SUBSCRIPTION = environment.webservices + '/core/subscriptions';
  public static SECURITY = environment.webservices + '/core/logsuser';
  public static FAMILY_INFO_USER = environment.webservices + '/family/users';
  public static FAMILY_INFO_MEDIA = environment.webservices + '/family/media';
  public static FAMILY_INFO_PHOTO = environment.webservices + '/family/photos';
  public static USER_LOGS = environment.webservices + '/core/logsuser';
  public static ONBOARDING = environment.webservices + '/core/onboarding';
  public static MESSAGE_ADD = environment.webservices + '/core/messagequeue';
  public static FILE_UPLOAD = environment.webservices + '/core/media/upload';
  public static FILE_DOWNLOAD =
    environment.webservices + '/core/media/download';
  public static FILE_DELETE =
    environment.webservices + '/core/media/deleteFile';
  public static TASKS = environment.webservices + '/committees/tasks';
  public static MEMBERSHIP = environment.webservices + '/family/memberships';
  public static DECLARATION = environment.webservices + '/family/declarations';
  public static COMMITTEE =
    environment.webservices + '/committees/all/committees';
  public static CREATECOMMITTEE = environment.webservices + '/committees';
  public static COMMITTEECATEGORIES =
    environment.webservices + '/committees/categories/';
  public static BUSINESS_UNIT =
    environment.webservices + '/family/business/units';
  public static BUSINESS_INFO =
    environment.webservices + '/family/business/info';
  public static BUSINESS_UPDATE = environment.webservices + '/family/business';
  public static COMMON_SPACE_CATEGORY =
    environment.webservices + '/family/commonspacecategory';
  public static COMMON_SPACE = environment.webservices + '/family/commonspace';
  public static CHANGE_PASSWORD =
    environment.webservices + '/core/users/change-password';
  public static SETTINGS = environment.webservices + '/family/settings';
  public static POLLS = environment.webservices + '/executor/poll';
  public static FAMILYTREE = environment.webservices + '/family/tree';
  public static BUSINESS_INFORMATION =
    environment.webservices + '/family/business/information';
  public static BUSINESS_UNIT_IMAGE =
    environment.webservices + '/family/business/units/info';
  public static BUSINESS_BOARD_READS =
    environment.webservices + '/family/business/information/reads';
  public static ACHIEVEMENTS = environment.webservices + '/achievements/all';
  public static ACHIEVEMENT_USER =
    environment.webservices + '/core/users/getbulkusers';
  public static ACHIEVEMENTS_BYID =
    environment.webservices + '/achievements/byid';
  public static COMMON_SPACE_BOOKING =
    environment.webservices + '/family/booking';
  public static TICKET = environment.webservices + '/family/requests';
  public static ADMINTICKET = environment.webservices + '/family/requests/all';
  public static ASSIGNEDTICKET =
    environment.webservices + '/family/requests/assign';
  public static BOOKINGBYDATE =
    environment.webservices + '/family/booking/searchbydate';
  public static IDPUSERDASHBOARD =
    environment.webservices + '/committees/idp/user/dashboard';
  public static IDPUSERLISTING = environment.webservices + '/committees/idp';
  public static OTHERMEETINGS =
    environment.webservices + '/committees/othermeetings';
  public static IDPMEETINGS =
    environment.webservices + '/committees/idp/user/meetings';
  public static IDPMEETINGSADD =
    environment.webservices + '/committees/meetings';
  public static IDPCATEGORYCHECK = environment.webservices + '/committees/idp';
  public static REPORT_PARENT_CATEGORIES =
    environment.webservices + '/family/vo/categories';
  public static REPORT_CHILD_CATEGORIES =
    environment.webservices + '/family/vo';
  public static REPORT_PARENT_DOCUMENTS =
    environment.webservices + '/family/vo/documents';
  public static SUBCATEGORIES =
    environment.webservices + '/committees/categories/parent';
  public static IDPREMARK = environment.webservices + '/committees/idp';
}

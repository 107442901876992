import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators, FormBuilder, FormArray, NgForm } from '@angular/forms';
import { UserService } from '@services/UserService';
import { ActivatedRoute, Router } from '@angular/router';
import {CoreService} from '../../../shared/services/CoreService';
import { UACService } from '@services/UAC.service';
import { constants } from '@shared/config/constants';
import { language } from '@shared/config/language';
import { DeclarationService } from '@services/Declaration.service.';


@Component({
  selector: 'app-adddeclaration',
  templateUrl: './adddeclaration.component.html',
  styleUrls: ['./adddeclaration.component.css']
})
export class AdddeclarationComponent implements OnInit {

 
rolename = "";
category = new FormControl('personal');


amountadded = false;

  isEdit:Boolean = false;
  form :FormGroup = new FormGroup({
    _id:new FormControl(0),
    user: new FormControl(0),
    category:new FormControl('',Validators.required),
    amount_added:new FormControl(false), 
    amount_expected:new FormControl('',[ Validators.pattern("^[0-9]*$")]),
    amount_spent:new FormControl('',[ Validators.pattern("^[0-9]*$")]),
    purpose:new FormControl('',Validators.required),
    reference:new FormControl(''),
    remarks:new FormControl('',Validators.required)
   })

  

 
  ADVISOR_LIST:any;
  ROLES_LIST:any;
  CONSTANT = constants;
  resource = language;
  labelText:String='Add Member';  

  PRIORITY = constants.task_priority;
  STATUS = constants.task_status;

  get f(){

    return this.form.controls;

  }

  constructor(private userservice: UserService, private router : Router, private declarationService: DeclarationService,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private fb: FormBuilder
   
    ) {
 
      console.log(this.CONSTANT.task_status)

       this.getAllAdvisors();

    this.activeRoute.queryParams.subscribe(data => {

      if(data.id!=undefined)
      {
        this.labelText='Edit Task'

        this.declarationService.getDeclaration(data.id).subscribe(response=> {
      
          const result = response.body["data"];
        
          
           this.category.setValue(result.type);


          this.form.patchValue({
            _id:result._id,
            user:result.user._id,
            category:result.category,
            amount_added:result.amount_added,
            amount_expected:result.amount_expected,
            amount_spent:result.amount_spent,
            purpose:result.purpose,
            reference:result.reference,         
            remarks:result.remarks             
            
          })

          this.amountadded = result.amount_added;
 
            this.changeToggle({checked :this.amountadded })
            this.isEdit = true;
            console.log(this.form);
        });
      } 
    })

   }

   changeToggle(event) {
    console.log(event)
    if(event.checked) {
      
      this.form.controls["amount_spent"].setValidators([Validators.required]);
      this.form.controls["amount_expected"].setValidators([Validators.required]);
      this.form.controls["amount_spent"].updateValueAndValidity();
      this.form.controls["amount_expected"].updateValueAndValidity();

    }
    else  {
      this.form.controls["amount_spent"].setValidators(null);
      this.form.controls["amount_expected"].setValidators(null);
      this.form.controls["amount_spent"].updateValueAndValidity();
      this.form.controls["amount_expected"].updateValueAndValidity();

    }
    console.log(this.form)
  }

  ngOnInit(): void {
  

  }
  

getAllAdvisors()
{

  this.userservice.getFamilyUses().subscribe(response=> {
    const result = response.body;
    this.ADVISOR_LIST =response.body['data'];
   }, (err) => {

   })

}

onChange(obj):void{
 
    this.amountadded = obj.checked;
    this.changeToggle(obj)
}

  submit():void{ 

    console.log(this.form)
    if (this.form.invalid) {
        return;
    }

    this.form.patchValue({"amount_added":this.amountadded});
 
    var rawuser = this.form.getRawValue();
   
    if(!this.isEdit) {
      this.form.removeControl('_id');
      rawuser = this.form.getRawValue();
    }
  

    this.declarationService.addDeclaration(rawuser).subscribe(response=> {
      
      const result = response.body;
   
      this.coreservice.success("Declaration saved successfully ");
     this.router.navigate(['declaration']);

     }, (err) => {


      console.log(err.error.errors)
      var errormessages = "Please correct the errors - \n";
      err.error.errors.forEach(element => errormessages += " - "+element.msg+"\n");

     // err.error.errors.forEach(element =>  this.coreservice.error(element.msg));

      
      this.coreservice.error(errormessages);
      this.form.addControl('_id', new FormControl(''));

      
   })
   
  }
     

 
 
}



import { Component, OnInit } from '@angular/core';
import { language } from '@shared/config/language';
import { CommonSpaceCategoryService } from '@services/CommonSpaceCategory.service';
import { CoreService } from '@services/CoreService';
import { AuthService } from '@services/Auth.service';

export interface CategoryElement {
  _id: string;
  category: string;
  created_at: string;
  updated_at: string;
}

@Component({
  selector: 'app-commonspacecategory',
  templateUrl: './commonspacecategory.component.html',
  styleUrls: ['./commonspacecategory.component.css'],
})
export class CommonspacecategoryComponent implements OnInit {
  PERMISSION_EDIT = AuthService.authentication('Common', 'EDIT');
  PERMISSION_ADD = AuthService.authentication('Users', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('Users', 'DELETE');

  resource = language;

  categories: CategoryElement[];

  constructor(
    private commonSpaceCaategoryService: CommonSpaceCategoryService,
    private coreservice: CoreService
  ) {}

  ngOnInit(): void {
    this.getAllCategories();
  }

  getAllCategories(): void {
    this.commonSpaceCaategoryService
      .getCommonSpaceCategories()
      .subscribe((response) => {
        this.categories = response.body['data'] as CategoryElement[];
      });
  }

  addnewitem(): void {
    let item = {
      _id: '',
      category: '',
      created_at: '',
      updated_at: '',
    };
    this.categories.push(item);
  }

  update(item): void {
    if (item._id == '') {
      delete item._id;
    }

    this.commonSpaceCaategoryService
      .addCommonSpaceCategory(item)
      .subscribe((response) => {
        console.log(response);

        this.coreservice.success(
          this.resource.message('KNOWLEDGE_CATEGORIES_SAVE')
        );
        this.getAllCategories();
      });
  }

  remove(item): void {
    this.coreservice
      .openConfirmDialog(this.resource.message('SPACE_TYPE_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.commonSpaceCaategoryService
            .removeCommonSpaceCategory(item._id)
            .subscribe(
              (response) => {
                const result = response;
                this.getAllCategories();
                this.coreservice.success(
                  this.resource.message('SPACE_TYPE_DELETED')
                );
              },
              (err) => {}
            );
        }
      });
  }
}

import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators, FormBuilder, FormArray, NgForm } from '@angular/forms';
import { UserService } from '@services/UserService';
import { ActivatedRoute, Router } from '@angular/router';
import {CoreService} from '../../../shared/services/CoreService';
import { UACService } from '@services/UAC.service';
import { constants } from '@shared/config/constants';
import {FamilyInfoService} from '@services/FamilyInfo.service';


@Component({
  selector: 'app-addmember',
  templateUrl: './addmember.component.html',
  styleUrls: ['./addmember.component.css']
})
export class AddmemberComponent implements OnInit {

  marriedControl = new FormControl('UnMarried');

  isEdit:Boolean = false;
  form :FormGroup = new FormGroup({
    _id:new FormControl(0),
    
    name:new FormControl('',Validators.required),
    middlename:new FormControl(''),
    lastname:new FormControl(''),
    role:new FormControl('',Validators.required),
    profile_photo:new FormControl(''),
    address: new FormControl(''),
    mobile: new FormControl(undefined,Validators.required),
    email: new FormControl('',Validators.required),
    dob: new FormControl(''),
    married: new FormControl(this.marriedControl.value),
    dateofmarriage: new FormControl(''),
    advisor: new FormControl(''),
    isDel: new FormControl('',Validators.required),
    family: new FormControl(''),
    updated_by: new FormControl('',Validators.required),
    social_links: this.fb.array([this.createSocialItem()]),
    designation: this.fb.array([this.createDesignationItem()]),
    id_details: this.fb.array([this.createIdItem()])
  })

 
  ADVISOR_LIST:any;
  ROLES_LIST:any;
  CONSTANT = constants;

  

  constructor(
    private userservice: UserService, private router : Router, private uacservice: UACService,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private fb: FormBuilder,
    private familyinfoservice:FamilyInfoService, 

    ) {

      this.getAllRoles();
      this.getAllAdvisors();

    this.activeRoute.queryParams.subscribe(data => {

      if(data.id!=undefined)
      {

        this.familyinfoservice.singleuserget(data.id).subscribe(response=> {
      
          const result = response["data"];
        
          this.marriedControl.setValue(result.married);

          this.form.patchValue({
            _id:result._id,
              name:result.name,
              middlename:result.middlename,
              lastname:result.lastname,
              role:result.role._id,
              profile_photo:result.profile_photo,
              address:result.address,
              mobile:result.mobile,
              email:result.email,
              dob:result.dob,
              dateofmarriage:result.anniversary_date,
              advisor:result.coach,              
              family:result.family,      
              social_links: result.social_links,
              designation: result.designation,
              married:this.marriedControl.value,
              id_details: result.id_details,
            isDel:false, 
            updated_by:"5e3a77df4a0460002a8083ae", 
            password:"admin123#", 
            
          })
            this.isEdit = true;
            console.log(this.form);
        });
      } 
    })

   }


   get sociallinks() {
    return this.form.get('social_links') as FormArray;
  }

  get designation() {
    return this.form.get('designation') as FormArray;
  }

  get ids() {
    return this.form.get('id_details') as FormArray;
  }

  ngOnInit(): void {

    this.form.patchValue({
      isDel:false, 
      updated_by:"5e3a77df4a0460002a8083ae", 
      password:"admin123#", 
      married:this.marriedControl.value,
      family: "5e3a75004a0460002a8083a7"    
    })

    
      

  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid;
}

getAllRoles()
{

  this.uacservice.getRoles().subscribe(response=> {
    const result = response.body;
    this.ROLES_LIST =response.body['results'];
   }, (err) => {

   })

}

getAllAdvisors()
{

  this.userservice.getUsers('').subscribe(response=> {
    const result = response.body;
    this.ADVISOR_LIST =response.body['results'];
   }, (err) => {

   })

}

  submit():void{ 

    console.log(this.form)
    if (this.form.invalid) {
        return;
    }

    var rawuser = this.form.getRawValue();

    if(!this.isEdit) {
      this.form.removeControl('_id');

      rawuser = this.form.getRawValue();
      
      rawuser.password = "FamilyBook123#";
      
    }


    rawuser.mobile = Number(rawuser.mobile);

    this.userservice.addUser(rawuser).subscribe(response=> {
      
      const result = response.body;
   
      this.coreservice.success("User saved");
     this.router.navigate(['user']);

     }, (err) => {


      console.log(err.error.errors)
      var errormessages = "Please correct the errors - \n";
      err.error.errors.forEach(element => errormessages += " - "+element.msg+"\n");

     // err.error.errors.forEach(element =>  this.coreservice.error(element.msg));

      
      this.coreservice.error(errormessages);
      this.form.addControl('_id', new FormControl(''));

      
   })
   
  }

  createIdItem(): FormGroup {
    return this.fb.group({
      id_no: '',
      id_type: ''
    });
  }

  createDesignationItem(): FormGroup {
    return this.fb.group({
      business: '',
      designation: ''
    });
  }
  createSocialItem(): FormGroup {
    return this.fb.group({
      url: '',
      network: ''
    });
  }
  
  addSocial() {
    this.sociallinks.push(this.fb.group({url:'', network:''}));
  }

  deleteSocial(index) {
    this.sociallinks.removeAt(index);
  }

  addDesignation() {
    this.designation.push(this.fb.group({business:'', designation:''}));
  }

  deleteDesignation(index) {
    this.designation.removeAt(index);
  }

  
  addId() {
    this.ids.push(this.fb.group({id_no:'', id_type:''}));
  }

  deleteId(index) {
    this.ids.removeAt(index);
  }


  getFormErrors(form: NgForm) {
    if (form instanceof FormControl) {
        // Return FormControl errors or null
        return form.errors ?? null;
    }
    if (form instanceof FormGroup) {
        const groupErrors = form.errors;
        // Form group can contain errors itself, in that case add'em
        const formErrors = groupErrors ? {groupErrors} : {};
        Object.keys(form.controls).forEach(key => {
            // Recursive call of the FormGroup fields
            const error = this.getFormErrors(form.get[key]);
            if (error !== null) {
                // Only add error if not null
                formErrors[key] = error;
            }
        });
        // Return FormGroup errors or null
        return Object.keys(formErrors).length > 0 ? formErrors : null;
    }
}
 
}


import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import { environment } from '@environments/environment';
import {LoginFormGroup} from '@models/login.formgroup'
import {FormGroup,FormControl, FormControlName,Validator, Validators} from '@angular/forms';
import { AuthService } from '@services/Auth.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'
import{UACService} from '@services/UAC.service';
import { language } from '@shared/config/language';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginform:FormGroup;
  submitted:Boolean = false;
  errmessage = "";
  myDate = new Date();
  logData :FormGroup = new FormGroup({
    user: new FormControl(0),
    logdate:new FormControl(0),
    ipaddress: new FormControl(0),
    device: new FormControl(0) 
  })
  constructor(private loginformservice:LoginFormGroup, 
    private authservice:AuthService,
    private router : Router,
    public datepipe: DatePipe,
    private uacservice:UACService ) {

   

   }

  ngOnInit(): void {
    
    this.loginformservice.initializeFormGroup();
    this.loginform = this.loginformservice.form;
    if(this.router.url.includes("logout")){
        this.authservice.logout();
        this.router.navigate(['login']);
    }
    
  }




  login():void{
    this.submitted = true;
    this.authservice.logout();
    console.log("CALLED")
    if (this.loginform.invalid) {
      
        return;
    }
    this.authservice.login(this.loginform.getRawValue()).subscribe(response=> {
      
      const result = response.body;

      if(result['user']["family"]["admin_portal_status"] == true){

                  this.authservice.localsave(result);
                  
                  this.logData.setValue({
                    user: result['user']._id,
                    logdate:this.datepipe.transform(this.myDate, 'yyyy-MM-dd hh:mm:ss'),
                    ipaddress: "197.112.1.1",
                    device: "Windows" 
                  })
                  
                  if(result['user'].logs==true){
                          this.uacservice.addLogs(this.logData.getRawValue()).subscribe(response=> {
                          const result = response.body;
                          window.open('dashboard','_self'); 
                     //     this.router.navigate(['dashboard']);
                          }, (err) => {
                        })
                  }
                  else{
                    window.open('dashboard','_self');
                  // this.router.navigate(['dashboard']);
                  }
      }else{
        this.errmessage = language.message("ACCOUNT_DISABLE");
      }


    
    }, (err) => {

        if(err.error.status != null){
          this.errmessage = err.error.message;
        }else{
          this.errmessage = err.error.errors[0].msg;
        }
      
   })
  }

  get f() { return this.loginform.controls; }

}

import { Component, OnInit ,ViewChild,Input} from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {BusinessInfoService} from '@services/BusinessInfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {FormGroup,FormControl, FormControlName,Validator, Validators, FormBuilder, FormArray, NgForm } from '@angular/forms';
import {CoreService} from '@services/CoreService';
import {language} from '@shared/config/language';
import { DialogboardreadsComponent } from '@shared/components/dialogboardreads/dialogboardreads.component';

export interface BoardReadsElement {
  date: string;
  title: string;
}

@Component({
  selector: 'app-boardreads',
  templateUrl: './boardreads.component.html',
  styleUrls: ['./boardreads.component.css'],
})
export class BoardreadsComponent implements OnInit {
  displayedColumns: string[] = [ 'date','title','pdf','Action'];
   ELEMENT_DATA: BoardReadsElement[];
   listData = new MatTableDataSource <BoardReadsElement>();
   dataSource: MatTableDataSource<any>;
   selection = new SelectionModel<BoardReadsElement>(true, []);
   @ViewChild(MatSort, { static: true }) sort: MatSort;
   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   @ViewChild('ImageTable') table: MatTable<BoardReadsElement>;
   @Input() id: string;
   deleteData:[]
   editindex:string='-1';
   postform:FormGroup= new FormGroup({
     Photos: new FormArray([])
   })
   resource=language;
   searchKey: string;
 
   constructor(    
      private businessinfoservice: BusinessInfoService,
      private dialog :MatDialog, 
      private coreservice:CoreService,
 
     ) { }
 
   ngOnInit(): void {
    this.listData.sort = this.sort;
     this.listData.paginator=this.paginator;
 
     this.initialLoad();
   }
   initialLoad()
   {
   
     console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^",this.id)
     this.businessinfoservice.getBoardReads(this.id).subscribe((response:any)=> {
       const result=response.body
       const fData = [];
       result.data.forEach(val => {
        fData.push(...val.data)
       })
       console.log("&*&*&&***&&&*&*&*",result)
        this.listData.data=fData as BoardReadsElement[];
       this.table.renderRows();
      }, (err) => {   
     })
   }
 
   openDialog(msg,actionbutton, cancelbutton)
   {
    const dialogRef = this.dialog.open(DialogboardreadsComponent,{
            width:'50vw',
            panelClass:'confirm-dialog-container',
            disableClose:true,
            data:{
                message:msg,
                actionbutton: actionbutton,
                cancelbutton: cancelbutton,
                id:this.id,
                editindex:this.editindex
            }
        })
        dialogRef.afterClosed().subscribe(result => {
          this.initialLoad()
         });
    }
    onDelete(row)
    {
     this.coreservice.openConfirmDialog(this.resource.message('BOARD_READS_DELETE'))
     .afterClosed().subscribe(res=>{
       if(res){
         this.businessinfoservice.deleteBuisnessReadInfo(row._id).subscribe((response:any)=> {
           this.initialLoad()
           this.coreservice.success(this.resource.message('BOARD_READS_DELETED'));
         }, (err) => {
       })
     }
   })
    
    }
    
    edit(data){
     const dialogRef = this.dialog.open(DialogboardreadsComponent,{
       width:'50vw',
       panelClass:'confirm-dialog-container',
       disableClose:true,
       data:{
           message:'',
           actionbutton: '',
           cancelbutton: '',
           id:data._id,
           editindex:data
       }
   })
   dialogRef.afterClosed().subscribe(result => {
     this.initialLoad()
    });
    }
    onSearchClear()
    {
      this.searchKey="";    
      this.applyfilter();
    }
    applyfilter()
    {
     this.listData.filterPredicate = 
     function (data, filter) {
       console.log(data)
         var dataStr = Object.keys(data).reduce(function (currentTerm, key) {
             return currentTerm + data[key] + '◬';
         }, '').toLowerCase();
         var transformedFilter = filter.trim().toLowerCase();
         return dataStr.indexOf(transformedFilter) != -1;
     };
      this.listData.filter=this.searchKey.trim().toLowerCase();
    }
 
 }

<form>
  <mat-table
    #idpTable
    idpTable
    matTableExporter
    [dataSource]="listData"
    matSort
    class="mattable"
    #exporter="matTableExporter"
    [hiddenColumns]="[5, 6]"
  >
    <!-- Name Column -->
    <ng-container matColumnDef="member">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Member Name</mat-header-cell
      >
      <mat-cell *matCellDef="let element"
        >{{ element.name ? element.name : "" }} &nbsp;
        {{ element.lastname ? element.lastname : "" }}
      </mat-cell>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="Action">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button
          mat-icon-button
          aria-label="Example icon button with a heart icon"
          [ngClass]="{'status-gray': row.votes !== true, 'status-green': row.votes === true}"
          (click)="votes(true, row.id)"
        >
          <mat-icon>thumb_up</mat-icon>
        </button>
        <button
          mat-icon-button
          aria-label="Example icon button with a heart icon"
          [ngClass]="{'status-gray': row.votes !== false, 'status-red': row.votes === false}"
          (click)="votes(false, row.id)"
        >
          <mat-icon>thumb_down</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    [pageSizeOptions]="[5, 10, 15, 20]"
    [pageSize]="5"
    showFirstLastButtons
  ></mat-paginator>
  <div class="card">
    <div><h5>Voting Result</h5></div>
    <br /><br />
    <div *ngIf="overrideText != ''" class="rideText">{{ overrideText }}</div>
    <br /><br />
    <div class="row div1">
      <div class="col-md-6 voteyes" [ngStyle]="yesCss">
        YES <br />{{ yesText }}
      </div>
      <div class="col-md-6 voteno" [ngStyle]="noCss">NO<br />{{ noText }}</div>
      <br /><br />
      <button
        mat-raised-button
        color="primary"
        class="btn btn-primary done-btn button-wider"
        type="button"
        (click)="openparticipants()"
        *ngIf="yes < no"
      >
        Override
      </button>
    </div>
  </div>
</form>

import { Component, OnInit } from '@angular/core';
import { language } from '@shared/config/language';
import { constants } from '@shared/config/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { OtherMeetingService } from '@services/OtherMeeting.service';

@Component({
  selector: 'app-idpdetails',
  templateUrl: './idpdetails.component.html',
  styleUrls: ['./idpdetails.component.css'],
})
export class IdpdetailsComponent implements OnInit {
  resource = language;
  CONSTANT = constants;
  user_data: any;
  data_id: string;
  constructor(
    private activeRoute: ActivatedRoute,
    private othermeetinginfoservice: OtherMeetingService
  ) {}

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.getAllUsers(data.id);
        this.data_id = data.id;
        //  this.getGoals(data.id)
      }
    });
  }
  getAllUsers(id) {
    var filter = '/' + id;
    this.othermeetinginfoservice.getFamilyUsers(filter).subscribe(
      (response) => {
        this.user_data = response.body['data'];
        console.log(this.user_data);
      },
      (err) => {
        if (err.error.status != null) {
        } else {
        }
      }
    );
  }
}

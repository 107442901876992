import { Component, OnInit } from '@angular/core';
import { language } from '@shared/config/language';
import { constants } from '@shared/config/constants';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MatDialog,
} from '@angular/material/dialog';
import { DialoginviteesComponent } from '@shared/components/dialoginvitees/dialoginvitees.component';
import { ActivatedRoute, Router } from '@angular/router';
import { IdpService } from '@services/Idp.service';
import { DialogaddnoteComponent } from '@shared/components/dialogaddnote/dialogaddnote.component';

@Component({
  selector: 'app-meetingagenda',
  templateUrl: './meetingagenda.component.html',
  styleUrls: ['./meetingagenda.component.css'],
})
export class MeetingagendaComponent implements OnInit {
  resource = language;
  CONSTANT = constants;
  form: FormGroup = new FormGroup({
    _id: new FormControl(''),
    category: new FormControl(''),
    goal: new FormControl(''),
  });
  topics: string[] = [];
  parentId: string = '';
  start_date: string;
  end_date: string;
  start_time: string;
  title: string;
  end_time: string;
  venue: string;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private idp: IdpService
  ) {}

  ngOnInit(): void {
    // this.getMeetings(this.parentId);
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentId = data.id;
        this.getMeetings(this.parentId);
      }
    });
  }
  openDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialoginviteesComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        id: this.parentId,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  cancelMeeting() {
    const dialogRef = this.dialog.open(DialogaddnoteComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: 'MEETING CANCELLED',
        actionbutton: '',
        cancelbutton: '',
        id: this.parentId,
        type: 'cancel',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
    //   this.idp.idpcancel(this.parentid).subscribe(
    //     (response: any) => {
    //       //    this.router.navigate(['idp']);
    //     },
    //     (err) => {
    //       console.log('errrrrrrr', err);
    //     }
    //   );
  }
  submit() {
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>');
    //this.router.navigate(['/rationalise/id=' + this.parentId]);
    this.router.navigate(['rationalise'], {
      queryParams: {
        id: this.parentId,
      },
    });
  }

  getMeetings(id) {
    var filter = '';
    filter = '?filter[_id]=' + id; //+this.localstoragedata._id
    this.idp.idpMeetingGet(filter).subscribe(
      (response: any) => {
        for (let i = 0; i < response.data[0].agendas.length; i++) {
          if(response.data[0].agendas[i].idp && response.data[0].agendas[i].idp.description)
          this.topics.push(response.data[0].agendas[i].idp.description);
          console.log('response::::', response.data[0]);
        }
        this.start_date = response.data[0].start_date;
        this.end_date = response.data[0].end_date;
        this.start_time = response.data[0].start_time;
        this.end_time = response.data[0].end_time;
        this.venue = response.data[0].venue;
        this.title = response.data[0].title;
        this.form.patchValue({
          category: response.data[0].agendas[0].idp && response.data[0].agendas[0].idp.category ? response.data[0].agendas[0].idp.category : null, 
          goal: response.data[0].agendas[0].idp && response.data[0].agendas[0].idp.description ? response.data[0].agendas[0].idp.description: null,
        });
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
}

import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';
import { constants } from '@shared/config/constants';


@Injectable({
    providedIn:'root'
})

export class FamiliesService {

    constructor(private http: HttpClient){


    }

    getFamilies(){

      
    var filter = "";
    var localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    if(localstoragedata.family.name != constants.CORE_FAMILY_NAME) filter = "?filter[_id]="+JSON.parse(localStorage.getItem('LOGGED_USER')).family._id;

 
  

        return this.http.get(apiendpoints.FAMILIES+filter,
            {
                headers: new HttpHeaders()
                  .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
                observe: 'response'
              }
            );      
    }

    getFamily(id){


      return this.http.get(apiendpoints.FAMILIES+"/"+id,
          {
              headers: new HttpHeaders()
                .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
              observe: 'response'
            }
          );      
    }


    addFamily(data:any){
      console.log(data);
      return this.http.post(apiendpoints.FAMILIES, {family:data},  {
        headers: new HttpHeaders()
          .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
        observe: 'response'
      });      
    }


    addUser(data:any){
      console.log(data);
      return this.http.post(apiendpoints.USERS, {user:data},  {
        headers: new HttpHeaders()
          .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
        observe: 'response'
      });      
    }

    removeRole(id){
    
      const options = {
        headers: new HttpHeaders({
          'Authorization': `Token `+localStorage.getItem('ACCESS_TOKEN')
        }),
        body: {
          roleId: id,
        },        
      };
      
      return this.http.delete(apiendpoints.ROLE, options);
    }





  getPermissions(){

    return this.http.get(apiendpoints.PERMISSIONS,
        {
            headers: new HttpHeaders()
              .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
            observe: 'response'
          }
        );      
  }



  getModules(){

      return this.http.get(apiendpoints.MODULE,
          {
              headers: new HttpHeaders()
                .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
              observe: 'response'
            }
          );      
  }

  getModule(id){


    return this.http.get(apiendpoints.MODULE+"/"+id,
        {
            headers: new HttpHeaders()
              .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
            observe: 'response'
          }
        );      
  }

  
  addModule(data:any){
    console.log(data);
    return this.http.post(apiendpoints.MODULE, {module:data},  {
      headers: new HttpHeaders()
        .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
      observe: 'response'
    });      
  }

  removeModule(id){
  
    const options = {
      headers: new HttpHeaders({
        'Authorization': `Token `+localStorage.getItem('ACCESS_TOKEN'),
      }),
      body: {
        moduleId: id,
      },        
    };
    
    return this.http.delete(apiendpoints.MODULE, options);
  }



  getRoleModule(roleId, moduleId){


    return this.http.get(apiendpoints.ROLEMODULE+"?role="+roleId+"&module="+moduleId,
        {
            headers: new HttpHeaders()
              .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
            observe: 'response'
          }
        );      
  }


  addRoleModule(data:any){
    console.log(data);
    return this.http.post(apiendpoints.ROLEMODULE, {rolemodule:data},  {
      headers: new HttpHeaders()
        .set('Authorization', `Token `+localStorage.getItem('ACCESS_TOKEN')),
      observe: 'response'
    });      
  }


}

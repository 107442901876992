import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { constants } from '@shared/config/constants';
import { language } from '@shared/config/language';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { IdpService } from '@services/Idp.service';
import { CoreService } from '@services/CoreService';
import {filter} from "rxjs/operators";

export interface GoalElement {
  development: number;
  category: string;
  date: string;
  status: string;
}
@Component({
  selector: 'app-goal',
  templateUrl: './goal.component.html',
  styleUrls: ['./goal.component.css'],
})
export class GoalComponent implements OnInit {
  resource = language;
  ELEMENT_DATA: GoalElement[];
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = [
    'development',
    'category',
    'date',
    'status',
    'Action2',
  ];
  listData = new MatTableDataSource<GoalElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<GoalElement>(true, []);
  localstoragedata: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('goalTable') table: MatTable<GoalElement>;
  @Input() uid: string;

  previouseUrl: any;

  constructor(
    private router: Router,
    private idp: IdpService,
    private changeDetectorRefs: ChangeDetectorRef,
    private coreservice: CoreService
  ) {

  }

  ngOnInit(): void {
    console.log('User id::::::::::', this.uid);
    this.getGoals(this.uid);
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
  }

  addgoal() {
    this.router.navigate(['goal/add'], { queryParams: { id: this.uid } });
  }
  onDelete(el) {
    this.coreservice
      .openConfirmDialog(this.resource.message('GOAL_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.idp.useridp_delete(el._id).subscribe(
            (res) => {
              this.getGoals(this.uid);
              this.coreservice.success('Goal deleted sucessfully!');
            },
            (err) => {
              this.coreservice.error('Something went wrong, try again!');
            }
          );
        }
      });
  }
  edit(row) {
    console.log('::::Row', row);
    this.router.navigate(['goal/progress'], { queryParams: { id: row._id } });

    // this.router.navigate(['goal/add']);
  }
  getGoals(id) {
    var filter = '';

    filter = '?filter[user]=' + id; //+this.localstoragedata._id
    this.idp.useridpget(filter).subscribe(
      (response: any) => {
        const result = response;
        console.log('>>>>>>>>>>>>>>', response.data);
        this.listData.data = response.data as GoalElement[];
        this.table.renderRows();
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
}

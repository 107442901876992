import { Component, OnInit, Inject } from '@angular/core';
import { language } from '@shared/config/language';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormBuilder,
  FormArray,
  NgForm,
} from '@angular/forms';
import { BusinessInfoService } from '@services/BusinessInfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '@services/CoreService';
import { FileService } from '@services/File.service';
import { UACService } from '@services/UAC.service';
import { UserService } from '@services/UserService';
import { OtherMeetingService } from '@services/OtherMeeting.service';

@Component({
  selector: 'app-dialogboarddirectorsteam',
  templateUrl: './dialogboarddirectorsteam.component.html',
  styleUrls: ['./dialogboarddirectorsteam.component.css'],
})
export class DialogboarddirectorsteamComponent implements OnInit {
  resource = language;
  todayDate:Date = new Date();
  
  listData = [];
  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    user: new FormControl(''),
    profile_photo: new FormControl(''),
    name: new FormControl('', Validators.required),
    designation: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    mobile: new FormControl('', Validators.required),
    department: new FormControl(''),
    start_date: new FormControl('', Validators.required),
    end_date: new FormControl(''),
    file: new FormControl(''),
    role: new FormControl(''),
  }, { validators : this.dateLessThan('start_date', 'end_date')});
  postform: FormGroup = new FormGroup({
    board_of_directors: new FormArray([]),
  });
  isEdit: boolean = false;
  selFile = new Object();
  selFileName = '';
  ROLES_LIST: any;
  localstoragedata: any;
  select_user_family: string;
  rolename = '';
  TEAM_MEMBER: any;
  disabledlabel = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogboarddirectorsteamComponent>,
    private businessinfoservice: BusinessInfoService,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private fileService: FileService,
    private uacservice: UACService,
    private userservice: UserService,
    private othermeetinginfoservice: OtherMeetingService
  ) {}

  ngOnInit(): void {
    this.getAllRoles();
    this.getAllUsers();
    if (this.data.index != -1) {
      const ind = this.data.index;
      console.log(this.data.prevdata[ind]);
      console.log('DATA INDEX', this.data.prevdata[ind]);
      this.form.patchValue({
        _id: this.data.id,
        user: this.data.prevdata[ind]['user'],
        profile_photo:
          this.data.prevdata[ind]['profile_photo'] != null
            ? this.data.prevdata[ind]['profile_photo']
            : '',
        name: this.data.prevdata[ind]['name'],
        designation: this.data.prevdata[ind]['designation'],
        email: this.data.prevdata[ind]['email'],
        mobile:
          this.data.prevdata[ind]['mobile_no'] != null
            ? this.data.prevdata[ind]['mobile_no']
            : '',
        department: this.data.prevdata[ind]['department'],
        start_date: this.data.prevdata[ind]['start_date'],
        end_date: this.data.prevdata[ind]['end_date'],
        file: '',
        role: this.data.prevdata[ind]['role'],
      });
      this.data.prevdata.splice(ind, 1);
    }
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): {[key: string]: any} => {
     let f = group.controls[from];
     let t = group.controls[to];
     console.log(new Date(f.value).getTime(),new Date(t.value).getTime())
     if (new Date(f.value).getTime() > new Date(t.value).getTime()) {
       t.setErrors({ endTimeGreaterThanStartTime: true });
       return {
         dates: "Start Date should be less than end Date"
       };
     }
     t.setErrors(null);
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  setrole($event) {
    this.TEAM_MEMBER.forEach((element) => {
      if (element._id == $event.value) {
        console.log(element);
        this.form.patchValue({
          user: element._id,
          profile_photo:
            element.profile_photo !== undefined ? element.profil_photo : '',
          mobile: element.mobile,
          role: element.role_uac,
          email: element.email,
          name: element.name,
        });
      }
    });
  }

  async submit() {
    var constdata = this.form.getRawValue();
    //  constdata['user'] = '608fdb1c7cceaa001ed7fc37';
    constdata['_id'] = this.data.id;
    if (this.form.invalid) {
      console.log('><><><><>');
      return;
    }

    var finalData = this.postform.getRawValue();
    let fileurl = this.selFileName;
    var formData: any = new FormData();
    formData.append('file', this.selFile);
    if (this.selFileName != '') {
      fileurl = await this.fileService.uploadFile(formData, true, false);
    }
    if (fileurl !== '') {
      constdata['profile_photo'] = fileurl;
    }
    for (let i = 0; i < this.data.prevdata.length; i++) {
      finalData['board_of_directors'].push(this.data.prevdata[i]);
    }
    const len = this.data.prevdata.length + 1;
    const userInfo = this.TEAM_MEMBER.find(val => val._id === constdata.user)
    console.log(this.TEAM_MEMBER)
    console.log(userInfo)
    finalData['board_of_directors'].push({
      user: constdata['user'],
      name: constdata['name'],
      designation: constdata['designation'],
      profile_photo: constdata['profile_photo'],
      start_date: constdata['start_date'],
      end_date: constdata['end_date'],
      mobile_no: constdata['mobile'],
      email: constdata['email'],
      department: constdata['department'],
      role: constdata['role'],
    });

    this.businessinfoservice
      .updateBusinessData(finalData, constdata['_id'])
      .subscribe(
        (response) => {
          const result = response.body;
          this.coreservice.success(this.resource.message('BOARDDIRECTORS_SAVE'));
          this.closeDialog();
        },
        (err) => {}
      );
  }

  onFileChange(event) {
    this.selFile = (event.target as HTMLInputElement).files[0];
    this.selFileName = (event.target as HTMLInputElement).files[0].name;
  }
  getAllRoles() {
    this.uacservice.getRoles().subscribe(
      (response) => {
        const result = response.body;
        this.ROLES_LIST = response.body['results'];
      },
      (err) => {}
    );
  }
  getAllUsers() {
    var filter = '';
    this.othermeetinginfoservice.getFamilyUsers(filter).subscribe(
      (response) => {
        this.TEAM_MEMBER = response.body['data'];
      },
      (err) => {
        if (err.error.status != null) {
        } else {
        }
      }
    );
  }
}

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { language } from '@shared/config/language';
import { IdpService } from '@services/Idp.service';
import { CommitteeService } from '@services/Committee.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DialogoverrideComponent } from '@shared/components/dialogoverride/dialogoverride.component';

export interface MeetingElement {
  member: number;
}

@Component({
  selector: 'app-commiteevotes',
  templateUrl: './commiteevotes.component.html',
  styleUrls: ['./commiteevotes.component.css'],
})
export class CommiteevotesComponent implements OnInit {
  @Input() parentid: String;
  @Input() agendaid: String;
  @Input() viewdata: Boolean;
  ELEMENT_DATA: MeetingElement[];
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = ['member', 'Action'];
  listData = new MatTableDataSource<MeetingElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<MeetingElement>(true, []);
  localstoragedata: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('idpTable') table: MatTable<MeetingElement>;
  searchKey: string;

  resource = language;
  voteList: any;
  totalMembers: any;
  yesCss: any;
  noCss: any;
  yesText: string;
  noText: string;
  yes: any;
  no: any;
  overrideText: string = '';
  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private committeeService: CommitteeService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    console.log(this.parentid);
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllCommiteeMeetings(this.parentid);
  }
  getAllCommiteeMeetings(id) {
    this.changeDetectorRefs.detectChanges();

    var filter = '/' + id;

    this.committeeService.getCommitteeMeetings(filter).subscribe(
      (response: any) => {
        console.log(response.data.members);
        const currentAgenda = response.data.agendas.find(val => val._id === this.agendaid);
        const filteredResponse = response.data.members.filter(data => data.attendance === true).map(val => ({...val, votes: ''}))
        filteredResponse.forEach(val => {
          currentAgenda.votes.forEach(voting => {
            if(voting.user._id === val.id) {
              val.votes = voting.positive;
            }
          })
        })
        this.listData.data = filteredResponse;
        console.log('Final data', this.listData.data);
        this.table.renderRows();
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
    this.votingResult();
  }
  votes(vote, id) {
    const resp = [];
    this.listData.data.forEach((val: any) => {
      if(val.id === id) {
        resp.push({user : id, positive: vote}) 
      }
      else {
        resp.push({user : val.id, positive: val.votes}) 
      }
    })
    
    let data = resp.filter(val => val.positive !== '' );
    this.committeeService
      .committeevotes(this.parentid, this.agendaid, data)
      .subscribe((response: any) => {
        this.getAllCommiteeMeetings(this.parentid);
      });
  }
  votingResult() {
    let counterPositive = 0;
    let counterNegative = 0;
    this.committeeService
      .getCommitteevotes(this.parentid, this.agendaid)
      .subscribe((response: any) => {
        console.log('Total response', response);

        for (let list = 0; list < response.data.agendas.length; list++) {
          let currentList = response.data.agendas[list];
          if (currentList._id == this.agendaid) {
            this.totalMembers = response.data.members.filter(data => data.attendance === true).length;
            this.voteList = currentList.votes;
          }
        }
        console.log('::::', this.voteList);

        for (let i = 0; i < this.voteList.length; i++) {
          if (this.voteList[i].positive == true) {
            counterPositive = counterPositive + 1;
          }
          if (this.voteList[i].positive == false) {
            counterNegative = counterNegative + 1;
          }
        }
        this.yes = (counterPositive * 100) / this.totalMembers;
        this.yes = this.yes % 1 !== 0 ? this.yes.toFixed(2) : this.yes;
        this.yesCss = {
          height: 'calc(0px + ' + this.yes + 'px)',
        };
        this.yesText = this.yes + '%';

        this.no = (counterNegative * 100) / this.totalMembers;
        this.no = this.no % 1 !== 0 ? this.no.toFixed(2) : this.no;
        this.noCss = {
          height: 'calc(0px + ' + this.no + 'px)',
        };
        this.noText = this.no + '%';

        console.log(counterNegative, counterPositive);
      });
  }

  override() {}
  openparticipants(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogoverrideComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: 'OVERRRIDE POWER',
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        meeting: this.parentid,
        agendaId: this.agendaid,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.overrideText =
        "The Resolution has been passed with Chairman's veto power";
    });
  }
}

<mat-card class="">
  <mat-card-content>
    <form class="form-inline">
      <div class="form-group mr-2">
        <button
          mat-stroked-button
          class="iconcss"
          (click)="convertToPDF('save')"
        >
          <i class="fa fa-file-pdf fa-lg"></i>
        </button>
        <button
          (click)="exporter.exportTable('csv', { fileName: 'leadership' })"
          mat-stroked-button
          class="iconcss"
        >
          <i class="fa fa-file-excel fa-lg"></i>
        </button>
        <button
          (click)="convertToPDF('print')"
          mat-stroked-button
          class="iconcss"
        >
          <i class="fa fa-print fa-lg"></i>
        </button>
      </div>
      <span class="example-spacer"></span>
      <div class="mr-1">
        <button
          mat-raised-button
          color="primary"
          class="addbutton"
          class="buttonfloatright"
          (click)="this.editindex = '-1'; openDialog('yes', 'open', 'close')"
        >
          <i class="fas fa-plus"></i>&nbsp;&nbsp;ADD TEAM
        </button>
        <input
          matInput
          placeholder="Search by Name/Mobile NO"
          autocomplete="off"
          name="searchKey"
          class="searchfloatright"
          [(ngModel)]="searchKey"
          (keyup)="applyfilter()"
        />
      </div>
    </form>
    <p>
      <br />
      <mat-table
        #LeadershipTable
        LeadershipTable
        matTableExporter
        [dataSource]="listData"
        matSort
        class="mattable"
        #exporter="matTableExporter"
        [hiddenColumns]="[4]"
      >
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Team Member</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="mobile_no">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Mobile No.</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.mobile_no }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Email ID</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="designation">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Designation</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.designation
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index">
            <button mat-icon-button (click)="edit(row)">
              <mat-icon>mode_edit</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="onDelete(i)">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 15, 20]"
        [pageSize]="5"
        showFirstLastButtons
      ></mat-paginator>
    </p>
  </mat-card-content>
</mat-card>

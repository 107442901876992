<div class="source != 'admin'? 'content p30' : 'content'">
  <h5 *ngIf="source != 'admin'">{{ resource.message("POLLS") }}</h5>

  <!--
    <mat-grid-list cols="2" rowHeight="300px">
      <mat-grid-tile colspan=2 >
        <mat-card class="example-card">
          <mat-card-header>            
            <mat-card-title><i class="fa fa-file-text-o"></i> Dashboard Tile </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
            </p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>  
  -->

  <mat-card class="">
    <mat-card-header>
      <mat-card-title *ngIf="source != 'admin'">
        {{ resource.message("POLLS") }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="form-inline">
        <div class="form-group mr-2" *ngIf="source != 'admin'">
          <button
            mat-stroked-button
            class="iconcss"
            (click)="convertToPDF('save')"
          >
            <i class="fa fa-file-pdf fa-lg"></i>
          </button>
          <button
            mat-stroked-button
            class="iconcss"
            (click)="exporter.exportTable('csv', { fileName: 'polls' })"
          >
            <i class="fa fa-file-excel fa-lg"></i>
          </button>
          <button
            mat-stroked-button
            class="iconcss"
            (click)="convertToPDF('print')"
          >
            <i class="fa fa-print fa-lg"></i>
          </button>
        </div>
        <span class="example-spacer"></span>
        <div class="mr-1" *ngIf="source != 'admin'">
          <button
            [disabled]="!PERMISSION_ADD"
            mat-raised-button
            color="primary"
            class="addbutton"
            class="buttonfloatright"
            (click)="addrole()"
          >
            <i class="fas fa-plus"></i>&nbsp;&nbsp;ADD
            {{ resource.message("POLL") | uppercase }}
          </button>
          <input
            matInput
            [(ngModel)]="searchKey"
            placeholder="{{ resource.message('SEARCH_BY_POLL') }}"
            autocomplete="off"
            (keyup)="applyfilter()"
            (keydown.enter)="$event.preventDefault()"
            name="searchKey"
            class="searchfloatright"
          />
        </div>
      </form>
      <p>
        <br />
        <mat-table
          #roleTable
          roleTable
          matTableExporter
          [dataSource]="listData"
          matSort
          class="mattable"
          #exporter="matTableExporter"
          [hiddenColumns]="[5]"
          id="print-section"
        >
          <!-- Name Column -->
          <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Created On</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              element.created_at | customDate
            }}</mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Poll Title</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="committeename">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Committee</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              element?.committeename
            }}</mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="active_till">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Active Till</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              element.active_till | date: 'dd-MM-yyyy hh:mm a'
            }}</mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="status">
            <mat-header-cell
              style="flex-basis: 48px"
              *matHeaderCellDef
              mat-sort-header
              >Status</mat-header-cell
            >
            <mat-cell style="flex-basis: 48px" *matCellDef="let element"
            
              ><span
                [ngClass]="
                  element.status == 'active'
                    ? 'status-active'
                    : element.status == 'cancelled'
                    ? 'status-inactive'
                    : 'status-completed'
                "
                >{{ element.status | titlecase }}</span
              ></mat-cell
            >
          </ng-container>

          <!-- System Column -->

          <!-- Action Column -->
          <ng-container matColumnDef="Action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button
                mat-icon-button
                (click)="edit(row)"
                [disabled]="row.isSystem"
                [disabled]="!PERMISSION_EDIT || row.isSystem || row.status == 'cancelled' || row.status == 'completed'"
              >
                <mat-icon>mode_edit</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 15, 20]"
          [pageSize]="5"
          showFirstLastButtons
        ></mat-paginator>
      </p>
    </mat-card-content>
  </mat-card>
</div>

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { language } from '@shared/config/language';
import { constants } from '@shared/config/constants';
import { FormControl, FormGroup } from '@angular/forms';
import { CoreService } from '@shared/services/CoreService';
import { IdpService } from '@services/Idp.service';

@Component({
  selector: 'app-newagenda',
  templateUrl: './newagenda.component.html',
  styleUrls: ['./newagenda.component.css'],
})
export class NewagendaComponent implements OnInit {
  resource = language;
  CONSTANT = constants;
  addAgenda: string = 'true';
  form: FormGroup = new FormGroup({
    _id: new FormControl(''),
    category: new FormControl(''),
    goal: new FormControl(''),
  });
  topics = [];
  suggested_agenda = [];
  @Output() newItemEvent = new EventEmitter<string>();
  @Input() uid: string;

  constructor(private coreservice: CoreService, private idp: IdpService) {}

  ngOnInit(): void {
    this.getMeetings(this.uid);
  }
  add_agenda(topicData) {
    console.log('in', topicData);

    this.idp
      .idpperformaction(this.uid, topicData._id, { action: 'add' })
      .subscribe(
        (response: any) => {
          this.addAgenda = 'false';
          this.topics = [];
          this.getMeetings(this.uid);
        },
        (err) => {
          console.log('errrrrrrr', err);
        }
      );
    //    this.coreservice.success(this.resource.message('INVITE'));
    //  this.newItemEvent.emit(this.addAgenda);
  }
  delete_agenda(topicData) {
    console.log('in', topicData);

    this.idp
      .idpperformaction(this.uid, topicData._id, { action: 'defer' })
      .subscribe(
        (response: any) => {
          this.addAgenda = 'false';
          this.topics = [];
          this.getMeetings(this.uid);
        },
        (err) => {
          console.log('errrrrrrr', err);
        }
      );
    //    this.coreservice.success(this.resource.message('INVITE'));
    //  this.newItemEvent.emit(this.addAgenda);
  }
  getMeetings(uid) {
    var filter = '';
    filter = '?filter[_id]=' + uid; //+this.localstoragedata._id
    this.idp.idpMeetingGet(filter).subscribe(
      (response: any) => {
        for (let i = 0; i < response.data[0].suggested_agendas.length; i++) {
          this.topics.push(response.data[0].suggested_agendas[i]);
          console.log('response::::', response.data[0].suggested_agendas[i]);
        }
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-publishtemplate',
  templateUrl: './publishtemplate.component.html',
  styleUrls: ['./publishtemplate.component.css']
})
export class PublishtemplateComponent implements OnInit {
  sendto =new FormControl('admins')

  form :FormGroup = new FormGroup({
    _id:new FormControl(''),
    sent_to:new FormControl('',Validators.required),
    family_admin : new FormControl('',Validators.required),
    family_member : new FormControl('',Validators.required),
    send_by : new FormControl('',Validators.required)
  })
  constructor() { }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpParams,
} from '@angular/common/http';
import { apiendpoints } from '../config/apiendpoints';
import { CoreService } from '@services/CoreService';
import { Observable } from 'rxjs';

interface ApiUploadResult {
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private httpClient: HttpClient,
    private coreservice: CoreService
  ) {}

  public async uploadFile(formdata, ispublic, download): Promise<any> {
    var result = await this.httpClient
      .post<ApiUploadResult>(
        apiendpoints.FILE_UPLOAD +
          '?ispublic=' +
          ispublic +
          '&download=' +
          download,
        formdata,
        {
          headers: {
            Authorization: `Token ` + localStorage.getItem('ACCESS_TOKEN'),
          },
        }
      )
      .toPromise();

    return result['data'];
  }

  public deleteFile(url): Observable<any> {
    return this.httpClient.get(apiendpoints.FILE_DELETE + '?' + url, {
      headers: {
        Authorization: `Token ` + localStorage.getItem('ACCESS_TOKEN'),
        observe: 'response',
      },
    });
  }

  public async fetchRemoteFile(formdata, ispublic, download): Promise<any> {
    var result = await this.httpClient
      .post<ApiUploadResult>(
        apiendpoints.FILE_REMOTE +
          '?ispublic=' +
          ispublic +
          '&download=' +
          download,
        formdata,
        {
          headers: {
            Authorization: `Token ` + localStorage.getItem('ACCESS_TOKEN'),
          },
        }
      )
      .toPromise();
    return result['data'];
  }
}

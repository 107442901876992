import { Component, OnInit } from '@angular/core';
import { FileService } from '@services/File.service';

@Component({
  selector: 'app-emailtemplate',
  templateUrl: './emailtemplate.component.html',
  styleUrls: ['./emailtemplate.component.css']
})
export class EmailtemplateComponent implements OnInit {
  selFile = new Object();

  selFileName = "";
  constructor( private fileService: FileService) { }

  ngOnInit(): void {
  }
  onFileChange(event,col) {
    this.selFile = (event.target as HTMLInputElement).files[0];
    this.selFileName = (event.target as HTMLInputElement).files[0].name;
 }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meetingnote',
  templateUrl: './meetingnote.component.html',
  styleUrls: ['./meetingnote.component.css']
})
export class MeetingnoteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div align="right">
  <div class="content-container">
    <mat-card class="cardclass">
      <mat-card-header>
        <mat-card-title>
          {{ resource.message("BOARDDIRECTORSTEAM") }}
        </mat-card-title>
        <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
      </mat-card-header>
      <hr />
      <form [formGroup]="form">
        <mat-card-content>
          <mat-grid-list cols="2" rowHeight="100px">
            <!-- <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <input type="hidden" formControlName="_id" />
                <mat-label>Team Member</mat-label>
                <input
                  matInput
                  placeholder="Team Member"
                  formControlName="name"
                />
              </mat-form-field>
            </mat-grid-tile> -->
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <input type="hidden" formControlName="_id" />

                <mat-label>Team Member</mat-label>
                <mat-select
                  placeholder="Team Member"
                  formControlName="user"
                  (selectionChange)="setrole($event)"
                >
                  <mat-option
                    *ngFor="let user of TEAM_MEMBER"
                    [value]="user._id + ''"
                    >{{ user.name }} {{ user.lastname }}</mat-option
                  >
                </mat-select>
                <mat-error>This field is mandatory.</mat-error>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <div
                class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored"
              >
                <input type="hidden" formControlName="_id" />
                <mat-label>Profile Photo</mat-label>
                <input
                  name="File1"
                  placeholder="File"
                  type="file"
                  (change)="onFileChange($event)"
                  formControlName="file"
                />
                <a
                  href="{{ this.form.get('profile_photo').value }}"
                  target="_blank"
                  class="input-preview-btn"
                  *ngIf="this.form.get('profile_photo').value !== ''"
                >
                  Preview</a
                >
              </div>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Email ID</mat-label>
                <input
                  matInput
                  placeholder="Email ID"
                  formControlName="email"
                  type="email"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Mobile Number</mat-label>
                <input
                  matInput
                  placeholder
                  placeholder="Mobile Number"
                  formControlName="mobile"
                  disabled="true"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Designation</mat-label>
                <input
                  matInput
                  placeholder="Designation"
                  formControlName="designation"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Role Name</mat-label>
                <mat-select
                  placeholder="Role"
                  formControlName="role"
                  required
                  (selectionChange)="setrole($event)"
                >
                  <mat-option
                    *ngFor="let role of ROLES_LIST"
                    [value]="role._id + ''"
                    >{{ role.role }}</mat-option
                  >
                </mat-select>
                <mat-error>This field is mandatory.</mat-error>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Start Date</mat-label>
                <input
                [min]="todayDate"
                  matInput
                  [matDatepicker]="dp3"
                  disabled
                  formControlName="start_date"
                  placeholder="Start Date"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dp3"
                ></mat-datepicker-toggle>
                <mat-datepicker #dp3 disabled="false"></mat-datepicker>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>End Date</mat-label>
                <input
                  matInput
                  [min]="todayDate"
                  [matDatepicker]="end3"
                  disabled
                  formControlName="end_date"
                  placeholder="End Date"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="end3"
                ></mat-datepicker-toggle>
                <mat-datepicker #end3 disabled="false"></mat-datepicker>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            (click)="submit()"
            [disabled]="form.invalid"
          >
            Save
          </button>
          <button
            mat-raised-button
            color="clear"
            class="button-wider"
            (click)="closeDialog()"
          >
            Close
          </button>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
</div>

import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FamilyInfoService } from '@services/FamilyInfo.service';
import { language } from '@shared/config/language';
import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from '@services/File.service';
import { CoreService } from '@services/CoreService';

export interface MediaElement {
  photoname: number;
  title: string;
}

@Component({
  selector: 'app-dialogmedia',
  templateUrl: './dialogmedia.component.html',
  styleUrls: ['./dialogmedia.component.css'],
})
export class DialogmediaComponent implements OnInit {
  displayedColumns: string[] = ['file', 'title', 'Action'];
  ELEMENT_DATA: MediaElement[];
  listData = new MatTableDataSource<MediaElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<MediaElement>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('mediaTable') table: MatTable<MediaElement>;
  resource = language;
  constructor(
    private fileService: FileService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogmediaComponent>,
    private changeDetectorRefs: ChangeDetectorRef,
    private familyinfoservice: FamilyInfoService,
    private router: Router,
    private coreservice: CoreService
  ) {
    this.listData = new MatTableDataSource<MediaElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<MediaElement>(true, []);
  }

  ngOnInit(): void {
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getMedia();
    this.table.renderRows();
  }
  getMedia() {
    this.familyinfoservice.familyinfoget().subscribe(
      (response: any) => {
        this.listData.data = Object.values(
          response['data'][3]['data']
        ) as MediaElement[];
        console.log('this.listData', this.listData.data);
        this.table.renderRows();
      },
      (err) => {
        if (err.error.status != null) {
        } else {
        }
      }
    );
  }
  closeDialog() {
    this.dialogRef.close(false);
  }

  onDelete(row) {
    this.coreservice
      .openConfirmDialog(this.resource.message('MEDIA_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.familyinfoservice.removeMedia(row._id).subscribe(
            (response: any) => {
              // delete previous image
              if (row.url.startsWith('https://fbafrontend.5mi.in/api')) {
                let url = row.url.split('?');
                this.fileService.deleteFile(url[1]).subscribe(
                  (res) => {
                    console.log(res);
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              }

              this.getMedia();
            },
            (err) => {
              if (err.error.status != null) {
              } else {
              }
            }
          );
      }
      })
  }
  viewImg(img) {
    window.open(img.url, '_blank');
  }

  edit(row) {
    this.router.navigate(['/familyinfo'], {
      queryParams: { id: row._id, rowfor: 'media' },
    });
    this.closeDialog();
  }
  decideImage(url: string) {
    if (url.startsWith('https://fbafrontend.5mi.in/api')) {
      return url;
    } else {
      return '/assets/images/URL_image.jpeg';
    }
  }
}

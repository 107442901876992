<mat-card class="">
  <mat-card-content>
    <form>
      <div class="form-group mr-2">
        <button
          (click)="convertToPDF('save')"
          mat-stroked-button
          class="iconcss"
        >
          <i class="fa fa-file-pdf fa-lg"></i>
        </button>
        <button
          (click)="convertToPDF('print')"
          mat-stroked-button
          class="iconcss"
        >
          <i class="fa fa-print fa-lg"></i>
        </button>
      </div>
    </form>
    <br />

    <!-- <mat-grid-list cols="2" rowHeight="50px" class="gridlistclass">
           <mat-grid-tile
                *ngFor="let tile of tiles"
                [colspan]="tile.cols"
                [rowspan]="tile.rows"
                [style.background]="tile.color"
                class="gridtitileclass">
                <span class="makespace">{{tile.levelname}}</span>  <span class="makespace1">{{tile.position}}</span> <span class="makespace1">{{tile.points}} </span>
            </mat-grid-tile>
          </mat-grid-list>-->

    <div class="row mainrow">
      <div class="col-md-12">
        <div class="row leftrows">
          <div class="col-md-5 span1">Novice</div>
          <div class="col-md-3 span2">Level 1</div>
          <div class="col-md-4 span2">
            00 - 09&nbsp;&nbsp;&nbsp;&nbsp;Points
          </div>
        </div>
        <div class="row leftrows">
          <div class="col-md-5 span1">Rising Star</div>
          <div class="col-md-3 span2">Level 2</div>
          <div class="col-md-4 span2">
            10 - 39&nbsp;&nbsp;&nbsp;&nbsp;Points
          </div>
        </div>
        <div class="row leftrows">
          <div class="col-md-5 span1">All Star</div>
          <div class="col-md-3 span2">Level 3</div>
          <div class="col-md-4 span2">
            40 - 59&nbsp;&nbsp;&nbsp;&nbsp;Points
          </div>
        </div>
        <div class="row leftrows">
          <div class="col-md-5 span1">Super Star</div>
          <div class="col-md-3 span2">Level 4</div>
          <div class="col-md-4 span2">
            60 - 79&nbsp;&nbsp;&nbsp;&nbsp;Points
          </div>
        </div>
        <div class="row leftrows">
          <div class="col-md-5 span1">Mega Star</div>
          <div class="col-md-3 span2">Level 5</div>
          <div class="col-md-4 span2">80 - 100&nbsp;&nbsp;&nbsp;Points</div>
        </div>
      </div>
     <!-- <div class="col-md-6">
        <div class="card">
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
      </div>-->
    </div>
  </mat-card-content>
</mat-card>

<form [formGroup]="form">
  <mat-grid-list cols="10" rowHeight="80px" formArrayName="social_links">
    <div
      *ngFor="let item of sociallinks.controls; let pointIndex = index"
      [formGroupName]="pointIndex"
    >
      <mat-grid-tile colspan="6">
        <mat-form-field appearance="outline" class="field-spacer">
          <mat-label>Todos</mat-label>
          <input matInput placeholder="Todo" formControlName="todo" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile style="padding: 0px">
        <button
          style="height: 80%; width: 50%"
          mat-button
          type="button"
          color="warn"
          appearance="outline"
          class="field-spacer"
          *ngIf="pointIndex != sociallinks.controls.length - 1"
        >
          <span
            class="material-icons"
            inline="true"
            style="transform: scale(1.5)"
            >delete_outline</span
          >
        </button>

        <button
          style="height: 80%; width: 50%"
          mat-button
          type="button"
          color="primary"
          appearance="outline"
          class="field-spacer"
          (click)="addSocial()"
          *ngIf="
            pointIndex == sociallinks.controls.length - 1 && viewdata !== true
          "
        >
          <span
            class="material-icons"
            inline="true"
            style="transform: scale(1.5)"
            >note_add</span
          >
        </button>
      </mat-grid-tile>
    </div>
  </mat-grid-list>
  <button
    style="height: 80%; float: right"
    mat-flat-button
    type="button"
    color="primary"
    class="field-spacer"
    (click)="addTodos()"
    *ngIf="viewdata !== true"
  >
    Save
  </button>
</form>

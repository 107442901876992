<div style="background: #fff; padding: 16px; border-radius: 5px">
  <h4><i class="fa fa-file-alt"></i>&nbsp;Family Office</h4>
  <mat-table
    #roleTable
    roleTable
    matTableExporter
    [dataSource]="listData"
    matSort
    class="mattable card"
    #exporter="matTableExporter"
    [hiddenColumns]="[5, 6]"
  >
    <!-- Name Column -->
    <ng-container matColumnDef="created_at">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Created On</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{
        element.created_at | customDate
      }}</mat-cell>
    </ng-container>

    <!-- Mobile Column -->
    <ng-container matColumnDef="ticket_no">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Ticket Number</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{ element.ticket_no }}</mat-cell>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Description</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="request_status">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Status</mat-header-cell
      >
      <mat-cell *matCellDef="let element"
        ><span
          [ngClass]="
            element.request_status == 'completed'
              ? 'status-active'
              : '' || element.request_status == 'declined'
              ? 'status-inactive'
              : '' || element.request_status == 'in_review'
              ? 'status-completed'
              : 'status-progress'
          "
        ></span
      ></mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <div *ngIf="total > 5" style="display: flex; justify-content: end">
    <button
      (click)="viewMore()"
      style="float: none"
      mat-stroked-button
      class="viewmore"
    >
      View More
    </button>
  </div>
</div>

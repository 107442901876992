import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/Auth.service';
import { UACService } from '@services/UAC.service';

@Component({
  selector: 'app-leftmenu',
  templateUrl: './leftmenu.component.html',
  styleUrls: ['./leftmenu.component.css'],
})
export class LeftmenuComponent implements OnInit {
  @ViewChild('any') childdrawer: any;
  showFiller = false;

  showFilter = false;
  mayur = false;
  corechild: any;
  moduleList: any;
  firstname: string;
  lastname: string;
  emailId: string;
  localstoragedata: any;
  rolename: string;
  photo: string;
  constructor(
    private uacservice: UACService,
    private authservice: AuthService,
    private router: Router
  ) {
    console.log('LEFT constructor');
  }

  ngOnInit(): void {
    this.localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    console.log(
      '>>>>>>>>>>>>>>>>>>>',
      JSON.parse(localStorage.getItem('LOGGED_USER'))
    );
    this.firstname = this.localstoragedata.name;
    this.emailId = this.localstoragedata.email;
    this.rolename = this.localstoragedata.role;
    this.photo = this.localstoragedata.profile_photo;
    this.lastname = this.localstoragedata.lastname;
    this.corechild = this.childdrawer;
    this.uacservice.getAllowedModule().subscribe(
      (response) => {
        const result = response.body;
        console.log('result', result);

        this.moduleList = response.body['results'];
        localStorage.setItem(
          'ALLOWED_MODULES',
          JSON.stringify(response.body['results'])
        );
        var uniquearr = [];

        this.moduleList.forEach(function (value) {
          var isExists = false;
          uniquearr.forEach(function (subvalue) {
            if (subvalue._id == value.module._id) {
              isExists = true;
            }
          });

          if (!isExists) uniquearr.push(value.module);
        });

        this.moduleList = uniquearr;

        this.moduleList.sort((a, b) => (a.priority > b.priority ? 1 : -1));
        console.log('this.moduleList', this.moduleList);
      },
      (err) => {
        if (err.error.status != null) {
        } else {
        }
      }
    );
  }

  logout() {
    this.authservice.logout();
    this.router.navigate(['logout']);
  }
}

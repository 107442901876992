import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BusinessInfoService } from '@services/BusinessInfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '@services/CoreService';
import { language } from '@shared/config/language';
import { AuthService } from '@services/Auth.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export interface UnitElement {
  businessunit: string;
  phone: string;
  keyperson: string;
  webiste: string;
}
@Component({
  selector: 'app-businessunit',
  templateUrl: './businessunit.component.html',
  styleUrls: ['./businessunit.component.css'],
})
export class BusinessunitComponent implements OnInit {
  displayedColumns: string[] = [
    'name',
    'mobile1',
    'keyperson',
    'website',
    'action',
  ];
  ELEMENT_DATA: UnitElement[];
  listData = new MatTableDataSource<UnitElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<UnitElement>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('BusinessTable') table: MatTable<UnitElement>;
  resource = language;
  searchKey: string;
  PERMISSION_EDIT = AuthService.authentication('BusinessUnit', 'READ');
  PERMISSION_ADD = AuthService.authentication('BusinessUnit', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('BusinessUnit', 'DELETE');

  constructor(
    private businessinfoservice: BusinessInfoService,
    private router: Router,
    private coreservice: CoreService
  ) {}

  ngOnInit(): void {
    AuthService.authenticate_redir('BusinessUnit', ['READ']);

    this.getbusinessunitdata();
    this.listData.sort = this.sort;
    this.listData.paginator = this.paginator;
  }

  getbusinessunitdata() {
    this.businessinfoservice.businessunitget('').subscribe(
      (response: any) => {
        console.log('response', response);

        this.listData.data = Object.values(response['data']) as UnitElement[];

        this.table.renderRows();
      },
      (err) => {}
    );
  }
  addUser() {
    this.router.navigate(['/businessunit/add']);
  }
  edit(rowdata) {
    this.router.navigate(['businessunit/add'], {
      queryParams: { id: rowdata._id },
    });
  }

  onDelete(id) {
    this.coreservice
      .openConfirmDialog(this.resource.message('BUSINESS_UNIT_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.businessinfoservice.removeUnit(id).subscribe(
            (response) => {
              const result = response;
              this.getbusinessunitdata();
              this.coreservice.success(
                this.resource.message('BUSINESS_UNIT_DELETED')
              );
            },
            (err) => {}
          );
        }
      });
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [
      ['Business Unit Name', 'Phone No.', "Key Person's Name", 'Website'],
    ];
    this.listData.data.forEach((obj) => {
      let name = obj['name'] ? obj['name'] : '';
      let lastname = obj['lastname'] ? obj['lastname'] : '';
      let mobile = obj['mobile'] ? obj['mobile'] : '';
      let keyperson = obj['keyperson'] ? obj['keyperson'] : '';
      let website = obj['website'] ? obj['website'] : '';

      let arr = [name + ' ' + lastname, mobile, keyperson, website];

      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      styles: {
        cellWidth: 'wrap',
      },
      columnStyles: {
        3: { cellWidth: 'auto' },
      },
      rowPageBreak: 'avoid',
    });

    if (action === 'save') {
      doc.save('business-units.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      // window.open(doc.output('bloburl') as unknown as string, '_blank');
      doc.output('dataurlnewwindow', { filename: 'business-units.pdf' });
    }
  }
}

<div class="content p30">
  <div id="Dashboard" class="tabcontent">
    <div class="row">
      <div class="col-sm-12">
        <h2 class="main-head">{{ resource.message("DASHBOARD") }}</h2>
      </div>
    </div>

    <app-family-office-card></app-family-office-card>
    <br /><br />
    <app-meetings-card></app-meetings-card>
    <div style="display: flex; gap: 24px; width: 100%">
      <app-polls-card style="width: 50%"></app-polls-card>
      <app-my-tasks-card style="width: 50%"></app-my-tasks-card>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { language } from '@shared/config/language';
import { constants } from '@shared/config/constants';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MatDialog,
} from '@angular/material/dialog';
import { DialogaddnoteComponent } from '@shared/components/dialogaddnote/dialogaddnote.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '@shared/services/CoreService';
import { IdpService } from '@services/Idp.service';

@Component({
  selector: 'app-rationaliseagenda',
  templateUrl: './rationaliseagenda.component.html',
  styleUrls: ['./rationaliseagenda.component.css'],
})
export class RationaliseagendaComponent implements OnInit {
  resource = language;
  CONSTANT = constants;
  addAgenda: string = 'true';
  showbtn: string = 'false';
  nextbtn: boolean = false;
  form: FormGroup = new FormGroup({
    _id: new FormControl(''),
    category: new FormControl(''),
    goal: new FormControl(''),
  });
  topics: string[] = [];
  parentid: string;
  suggested_agenda = [];

  constructor(
    private coreservice: CoreService,
    private dialog: MatDialog,
    private router: Router,
    private idp: IdpService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id !== undefined) {
        this.parentid = data.id;
        this.getMeetings(this.parentid);
      }
    });
  }
  add_agenda() {
    console.log('in');
    this.coreservice.success(this.resource.message('INVITE'));
    this.addAgenda = 'false';
  }
  getagenda(event) {
    console.log('EVENT called', event);
    this.addAgenda = event;
  }
  addNote() {
    this.showbtn = 'true';
    this.nextbtn = true;
    this.openDialog('', '', '');
  }

  openDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogaddnoteComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: 'RATIONALISE AGENDA',
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        id: this.parentid,
        type: 'ratinalize',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  take_attendence() {
    // this.router.navigate(['/attendence']);
    this.router.navigate(['attendence'], {
      queryParams: {
        id: this.parentid,
      },
    });
  }
  getMeetings(id) {
    //alert('IN');
    var filter = '';
    filter = '?filter[_id]=' + id; //+this.localstoragedata._id
    this.idp.idpMeetingGet(filter).subscribe(
      (response: any) => {
        console.log(response);
        if (response.data[0].meeting_status === 'agenda_rationalized') {
          this.nextbtn = true;
        }
        for (let i = 0; i < response.data[0].agendas.length; i++) {
          this.topics.push(response.data[0].agendas[i].idp.description);
        }
        if (response.data[0].suggested_agendas !== undefined) {
          for (let i = 0; i < response.data[0].suggested_agendas.length; i++) {
            this.suggested_agenda.push(response.data[0].suggested_agendas[i]);
          }
        }
        console.log('::::::::::::', response);
        this.form.patchValue({
          category: response.data[0].agendas[0].idp.category,
          goal: response.data[0].agendas[0].idp.description,
        });
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  cancelMeeting() {
    const dialogRef = this.dialog.open(DialogaddnoteComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: 'MEETING CANCELLED',
        actionbutton: '',
        cancelbutton: '',
        id: this.parentid,
        type: 'cancel',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
    //   this.idp.idpcancel(this.parentid).subscribe(
    //     (response: any) => {
    //       //    this.router.navigate(['idp']);
    //     },
    //     (err) => {
    //       console.log('errrrrrrr', err);
    //     }
    //   );
  }
  showNote() {
    const dialogRef = this.dialog.open(DialogaddnoteComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: 'NOTE',
        actionbutton: '',
        cancelbutton: '',
        id: this.parentid,
        type: 'shownote',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}

import { Component, OnInit } from '@angular/core';
import {
  FormControl, FormGroup, Validators
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonSpaceService } from '@services/CommonSpace.service';
import { CommonSpaceCategoryService } from '@services/CommonSpaceCategory.service';
import { UserService } from '@services/UserService';
import { constants } from '@shared/config/constants';
import { language } from '@shared/config/language';
import { CoreService } from '@shared/services/CoreService';
import * as moment from 'moment';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-addbooking',
  templateUrl: './addbooking.component.html',
  styleUrls: ['./addbooking.component.css'],
})
export class AddbookingComponent implements OnInit {
  CATEGORY_LIST: any;
  rolename = '';
  SPACENAME_LIST: any[] = [];
  FILTERED_SPACENAME_LIST: any[] = [];
  isEdit: Boolean = false;
  form: FormGroup = new FormGroup({
    _id: new FormControl(0),
    spacename: new FormControl('', Validators.required),
    user: new FormControl('', Validators.required),
    ticket: new FormControl('', Validators.required),
    purpose: new FormControl('', Validators.required),
    capacity: new FormControl('', Validators.required),
    start_date: new FormControl('', Validators.required),
    end_date: new FormControl('', Validators.required),
    category: new FormControl(''),
  });

  CONSTANT = constants;
  resource = language;
  labelText: String = '';
  localstoragedata: any;
  MEMBER_LIST: any;
  minDate = new Date();
  id: string;
  constructor(
    private commonSpaceService: CommonSpaceService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private userservice: UserService,
    private commonSpaceCategoryService: CommonSpaceCategoryService,
    private snackbar: MatSnackBar
  ) {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.labelText = '/ Edit Booking';
        this.isEdit = true;
        this.id = data.id;
      }
    });
  }

  ngOnInit(): void {
    this.getAllCategories();
    //  this.getSpaceName();
    this.getAllUsers();
    this.getSpaceName();
  }

  getSpaceName() {
    this.commonSpaceService.getCommonSpaces('').subscribe((response) => {
      const result = response.body;
      console.log(result, "getSpaceName");

      this.SPACENAME_LIST = response.body['data'];

      if (this.id) {
        this.commonSpaceService.getBooking(this.id).subscribe((response) => {
          const result = response.body['data'];
          this.rolename = result.role;
          this.form.patchValue({
            _id: result._id,
            spacename: result.spacename,
            user: result.user,
            ticket: result.ticket,
            purpose: result.purpose,
            capacity: result.capacity,
            start_date: new Date(result.start_date).toISOString().slice(0, 16),
            end_date: new Date(result.end_date).toISOString().slice(0, 16),
            category: result.category,
          });
          this.onDateRangeChanged("end");
          this.filterSpaceNames(this.form.value.category, true);

        });
      }
    });
  }

  filterSpaceNames(id, ignoreResetForFirstEditCall?: boolean) {
    this.FILTERED_SPACENAME_LIST = this.SPACENAME_LIST.filter((item) => {
      return item.category === id;
    });

    console.log('FILTERED_SPACENAME_LIST', this.FILTERED_SPACENAME_LIST);
    if (!ignoreResetForFirstEditCall) {
      this.form.patchValue({ spacename: null });
      this.setMaxCapacity(null);
    }
  }

  getAllCategories() {
    this.commonSpaceCategoryService.getCommonSpaceCategories().subscribe(
      (response) => {
        const result = response.body;
        this.CATEGORY_LIST = response.body['data'];
        console.log(' this.CATEGORY_LIST   ', this.CATEGORY_LIST);
      },
      (err) => { }
    );
  }
  getAllUsers() {
    this.localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));

    var filter = '?filter[family]=' + this.localstoragedata.family._id;
    this.userservice.getUsers(filter).subscribe(
      (response) => {
        const result = response.body;
        this.MEMBER_LIST = response.body['results'];
      },
      (err) => {
        if (err.error.status != null) {
        } else {
        }
      }
    );
  }

  submit(): void {
    console.log(this.form);
    if (this.form.invalid) {
      return;
    }

    var rawuser = this.form.getRawValue();

    console.log('RAW FORM', rawuser);
    if (!this.isEdit) {
      this.form.removeControl('_id');
      rawuser = this.form.getRawValue();
    }

    this.commonSpaceService.addBooking(rawuser).subscribe(
      (response) => {
        const result = response.body;
        if (!this.isEdit) {
          this.coreservice.success(this.resource.message('BOOKING_SAVE'));
        } else {
          this.coreservice.success(this.resource.message('BOOKING_UPDATE'));
        }
        this.router.navigate(['commonspace/booking']);
      },
      (err) => {
        console.log(err.error.errors);
        var errormessages = 'Please correct the errors - \n';
        err.error.errors.forEach(
          (element) => (errormessages += ' - ' + element.msg + '\n')
        );

        // err.error.errors.forEach(element =>  this.coreservice.error(element.msg));

        this.coreservice.error(errormessages);
        this.form.addControl('_id', new FormControl(''));
      }
    );
  }

  viewcalendar() {
    this.router.navigate(['/calendar']);
  }
  getSpaces(value) {
    console.log(value);

    this.filterSpaceNames(value);
  }

  onDateRangeChanged(type: 'start' | 'end') {
    if (Date.parse(this.form.value.start_date) >= Date.parse(this.form.value.end_date)) {
      if (type == 'start') {
        this.snackbar.open("Start time cannot be greater than end time", "OK", { panelClass: 'snackred' });
        // this.form.patchValue({ start_date: null });
        this.form.controls['start_date'].setErrors({ invalidDate: true });
        this.form.controls['end_date'].setErrors(null);
      } else {
        this.snackbar.open("End time cannot be lesser than start time", "OK", { panelClass: 'snackred' });
        // this.form.patchValue({ end_date: null });
        this.form.controls['end_date'].setErrors({ invalidDate: true });
        this.form.controls['start_date'].setErrors(null);

      }
    }
  }

  setMaxCapacity(value) {
    if (value) {
      let maxCapacity = this.FILTERED_SPACENAME_LIST.find(x => x._id == value).capacity;
      this.form.get('capacity').setValidators([Validators.max(maxCapacity), Validators.required]);
    }
    else {
      this.form.get('capacity').setValidators(Validators.required);
      this.form.get('capacity').setErrors(null);
    }
  }
}

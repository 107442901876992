import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { language } from '@shared/config/language';
import { UserService } from '@services/UserService';
import { MatPaginator } from '@angular/material/paginator';
import { DialogmeetingparticipantsComponent } from '@shared/components/dialogmeetingparticipants/dialogmeetingparticipants.component';

export interface UserElement {
  userId: number;
  name: string;
}
@Component({
  selector: 'app-dialogcommitteeparticipants',
  templateUrl: './dialogcommitteeparticipants.component.html',
  styleUrls: ['./dialogcommitteeparticipants.component.css'],
})
export class DialogcommitteeparticipantsComponent implements OnInit {
  resource = language;
  ELEMENT_DATA: UserElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = ['select', 'name'];
  listData = new MatTableDataSource<UserElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<UserElement>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('roleTable') table: MatTable<UserElement>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  localstoragedata: any;
  labelText: string;
  specialInvitee: any = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogcommitteeparticipantsComponent>,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog,
    private userservice: UserService
  ) {
    this.listData = new MatTableDataSource<UserElement>(this.ELEMENT_DATA);
    console.log(this.data.selected)
    if(this.data.selected)
    this.selection = new SelectionModel<UserElement>(true, [...this.data.selected]);
    else 
    this.selection = new SelectionModel<UserElement>(true, []);
  }

  ngOnInit(): void {
    this.labelText = this.data.message;
    this.localstoragedata = JSON.parse(localStorage.getItem('LOGGED_USER'));
    this.listData.paginator = this.paginator;

    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllUsers();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  closeSaveDialog() {
    this.dialogRef.close({
      data: this.selection['_selected'],
      special: this.specialInvitee,
    });
  }

  getAllUsers() {
    var filter = '';
    filter = '?filter[family]=' + this.localstoragedata.family._id;
    this.userservice.getUsers(filter).subscribe(
      (response) => {
        const result = response.body['results'];
        console.log('::::::::::::::::::', this.data.ids);
        console.log('::::::::::::::::::', result.user_id);
        let allParticipants = [];
        for (let i = 0; i < result.length; i++) {
          //allParticipants = result.filter((res) => res.user_id === );
        }
        this.listData.data = response.body['results'] as UserElement[];
        this.table.renderRows();
      },
      (err) => {
        if (err.error.status != null) {
        } else {
        }
      }
    );
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      const keys = ['name', 'lastname']
      var dataStr = keys
        .reduce(function (currentTerm, key) {
          console.log(currentTerm + data[key])
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
        //console.log(dataStr)
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    }; 
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listData.data.forEach((row) => {
          this.selection.select(row);
        });
  }
  openDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogmeetingparticipantsComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      this.specialInvitee = result;
    });
  }
}

<div class="content p30">
  <div id="block_container">
    <a
      routerLink="/idp"
      id="bloc1"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >
    <div id="bloc2">
      <h5>{{ resource.message("IDP") }} / Details</h5>
    </div>
  </div>
  <form>
    <fieldset>
      <!--[disabled]=" (familyname!=select_user_family) ? isDisabled :''"-->
      <mat-card class="">
        <mat-card-content>
          <div class="row mb-5">
            <div class="col-sm-12">
              <div class="row maindiv">
                <div class="col-lg-4 col-sm-12">
                  <div class="row">
                    <div class="col-md-12 firstcol">Family Member Name</div>
                    <div class="col-md-12 secondcol">
                      {{ user_data?.name }} {{ user_data?.lastname }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-sm-12">
                  <div class="row">
                    <div class="col-md-12 firstcol">Mobile Number</div>
                    <div class="col-md-12 secondcol">
                     {{ user_data?.mobile }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-sm-12">
                  <div class="row">
                    <div class="col-md-12 firstcol">Email ID</div>
                    <div class="col-md-12 secondcol">
                     {{ user_data?.email }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="col-sm-12">
                <span class="devarea">Development Area </span>
                <hr />
              </div>
            </div>
          </div>
          <app-goal [uid]="data_id"></app-goal>

          <br />
          <br />
          <br />
          <br />
          <br />

          <div class="row">
            <div class="col-sm-12">
              <div class="col-sm-12">
                <span class="devarea">All Meetings</span>
                <hr />
              </div>
            </div>
          </div>
          <app-meeting [uid]="data_id"></app-meeting>

        </mat-card-content>

        <mat-card-actions align="end"> </mat-card-actions>
      </mat-card>
    </fieldset>
  </form>
</div>

<div class="content p30"> 
    <div id="block_container">
          <a  routerLink="/tickets" id="bloc1" mat-flat-button class="mat-flat-button-transperent" ><span  class="material-icons">keyboard_backspace</span></a> 
          <div id="bloc2"><h5>{{resource.message('FAMILYOFFICE')}} /{{resource.message('REQUEST')}}</h5></div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" >
      <mat-card class="">
        <mat-card-header>            
          <mat-card-title> Request No. {{ticketno}} </mat-card-title>
        </mat-card-header>
        <mat-card-content class="contentclass">
          <hr />
          <mat-grid-list cols="2" rowHeight="100px" >
              <mat-grid-tile  >
                <mat-label class="assigned_to"> Assigned to : &nbsp; </mat-label>
                <mat-radio-group appearance="outline" class="smallRadio"   [formControl]="notice" [disabled]="nonadmin">
                    <mat-radio-button value="internal" [checked]="notice.value == 'internal'" class="m20" (change)="this.form.patchValue({'specialist':'internal'})" ><span>Internal</span> </mat-radio-button>
                    <mat-radio-button value="external"  [checked]="notice.value == 'external'" class="m20" (change)="this.form.patchValue({'specialist':'external'})" >External</mat-radio-button>
                </mat-radio-group>
              </mat-grid-tile>
              <mat-grid-tile  *ngIf="notice.value=='internal'" >
                <mat-form-field appearance="outline" class="field-spacer">       
                    <mat-label>Members Name</mat-label>
                        <mat-select  placeholder="Members Name"  formControlName="assign_userid"  [disabled]="nonadmin" >
                        <mat-option *ngFor="let member of MEMBER_LIST" [value]="member.user_id+''">{{member.name}} {{member.lastname}}</mat-option>
                </mat-select>
                  </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile  *ngIf="notice.value=='external'" >
                <mat-form-field appearance="outline" class="field-spacer">       
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="Name" formControlName="assign_external_name" [readonly]="nonadmin">
                  </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile  *ngIf="notice.value=='external'" >
                <mat-form-field appearance="outline" class="field-spacer">       
                    <mat-label>Email ID</mat-label>
                    <input matInput placeholder="Email ID" formControlName="assign_external_email" [readonly]="nonadmin">
                  </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile  *ngIf="notice.value=='external'" >
                <mat-form-field appearance="outline" class="field-spacer">       
                    <mat-label>Contact Number</mat-label>
                    <input matInput placeholder="Contact Number" formControlName="assign_external_mobile" [readonly]="nonadmin">
                  </mat-form-field>
              </mat-grid-tile>
          </mat-grid-list>
          <div  *ngIf="notice.value=='external'">
            <hr/>
            <mat-label >Internal Reference Details</mat-label>
            <hr/>
            <mat-grid-list cols="2" rowHeight="100px" >
                <mat-grid-tile  >
                    <mat-form-field appearance="outline" class="field-spacer">       
                        <mat-label>Members Name</mat-label>
                            <mat-select  placeholder="Members Name"  formControlName="assign_userid"  [disabled]="nonadmin">
                            <mat-option *ngFor="let member of MEMBER_LIST" [value]="member.user_id+''">{{member.name}} {{member.lastname}}</mat-option>
                    </mat-select>
                      </mat-form-field>
                  </mat-grid-tile>
            </mat-grid-list>
        </div>
        </mat-card-content>
        <hr/>
        <mat-card-actions align="left">
          <div class="form-group mr-2">  
              <button mat-stroked-button type="button" class="wide" (click)="previousPage()">Previous</button>
          </div>
       <div class="rightpanel" align="right"> 
         <button mat-raised-button color="primary" class="btn btn-primary login-btn wide" type="submit">Send</button>
       </div> </mat-card-actions>
      </mat-card>
    </form>
  </div>
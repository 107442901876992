<div class="content p30">
  <h5>{{ resource.message("OTHERMEETING") }}</h5>
  <mat-card class="">
    <mat-card-header>
      <mat-card-title> {{ resource.message("OTHERMEETING") }} </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="form-inline">
        <div class="form-group mr-2">
          <button
            mat-stroked-button
            class="iconcss"
            (click)="convertToPDF('save')"
          >
            <i class="fa fa-file-pdf fa-lg"></i>
          </button>
          <button
            (click)="
              exporter.exportTable('csv', { fileName: 'other-meetings' })
            "
            mat-stroked-button
            class="iconcss"
          >
            <i class="fa fa-file-excel fa-lg"></i>
          </button>
          <button
            (click)="convertToPDF('print')"
            mat-stroked-button
            class="iconcss"
          >
            <i class="fa fa-print fa-lg"></i>
          </button>
        </div>
        <span class="example-spacer"></span>
        <div class="mr-1">
          <button
            [disabled]="!PERMISSION_ADD"
            mat-raised-button
            color="primary"
            class="addbutton"
            class="buttonfloatright"
            (click)="addrole()"
          >
            <i class="fas fa-plus"></i>&nbsp;&nbsp;CREATE MEETING
          </button>
          <input
            matInput
            [(ngModel)]="searchKey"
            placeholder="Search"
            autocomplete="off"
            (keyup)="applyfilter()"
            name="searchKey"
            class="searchfloatright"
          />
        </div>
      </form>
      <p>
        <br />
        <mat-table
          #exporter="matTableExporter"
          matTableExporter
          #roleTable
          roleTable
          [dataSource]="listData"
          matSort
          class="mattable"
          [hiddenColumns]="[4, 5]"
        >
          <!-- Name Column -->
          <ng-container matColumnDef="createdOn">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Title</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="requestedBY">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Requested By</mat-header-cell
            >
            <mat-cell *matCellDef="let element"
              >{{ element.requestedBY }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="start_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Start Date and Time</mat-header-cell
            >
            <mat-cell *matCellDef="let element"
              >{{ element.start_date  | date: 'dd/MM/yyyy hh:mm a' :'UTC' }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="end_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >End Date and Time</mat-header-cell
            >
            <mat-cell *matCellDef="let element"
              >{{ element.end_date   | date: 'dd/MM/yyyy hh:mm a' :'UTC' }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="participants">
            <mat-header-cell
              style="flex-basis: 36px"
              *matHeaderCellDef
              mat-sort-header
              >Participants</mat-header-cell
            >
            <mat-cell style="flex-basis: 36px" *matCellDef="let element"
              ><button
                mat-button
                (click)="
                  openParticipantsDialog('yes', 'open', 'close', element._id)
                "
              >
                View
              </button></mat-cell
            >
          </ng-container>

          <!-- System Column -->

          <!-- Action Column -->
          <ng-container matColumnDef="Action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button
                mat-icon-button
                (click)="edit(row)"
                [disabled]="!PERMISSION_EDIT"
              >
                <mat-icon>mode_edit</mat-icon></button
              ><button
                mat-icon-button
                color="warn"
                (click)="removeMeteting(row)"
                [disabled]="!PERMISSION_DELETE"
              >
                <mat-icon>delete_outline</mat-icon>
              </button>
              <!--   <button mat-icon-button color="warn"  (click)="onDelete(row._id)"   ><mat-icon>delete_outline</mat-icon></button>-->
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 15, 20]"
          [pageSize]="5"
          showFirstLastButtons
        ></mat-paginator>
      </p>
    </mat-card-content>
  </mat-card>
</div>

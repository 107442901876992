<div class="content p30">
  <div id="block_container">
    <ng-container>
      <a
        routerLink="/committee-meetings"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
    </ng-container>
    <div id="bloc2">
      <h5>{{ resource.message("COMMITTEEMEETINGS") }} / Create Meeting</h5>
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="submitData()">
    <mat-card class="">
      <mat-card-content>
        <span class="devarea">Meeting Details</span>
        <hr />
        <mat-grid-list cols="2" rowHeight="80px">
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Select Committee *</mat-label>
              <mat-select
                placeholder="Committee Type"
                formControlName="committee"
              >
                <mat-option
                  *ngFor="let type of COMMITTEE_LIST"
                  [value]="type._id"
                  >{{ type.title }}</mat-option
                >
              </mat-select>
              <mat-error>This field is mandatory.</mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <label>Meeting Notice : </label><br />
            <mat-radio-group
              appearance="outline"
              class="smallRadio"
              formControlName="meeting_notice"
            >
              <mat-radio-button
                value="15"
                [checked]="this.form.get('meeting_notice').value == '15'"
                class="m20"
                (change)="this.form.patchValue({ meeting_notice: '15' })"
                ><span>15 Days</span>
              </mat-radio-button>
              <mat-radio-button
                value="30"
                [checked]="this.form.get('meeting_notice').value == '30'"
                class="m20"
                (change)="this.form.patchValue({ meeting_notice: '30' })"
                >30 Days</mat-radio-button
              >
              <mat-radio-button
                value="others"
                [checked]="this.form.get('meeting_notice').value == 'others'"
                class="m20"
                (change)="this.form.patchValue({ meeting_notice: 'others' })"
                >Others</mat-radio-button
              >
            </mat-radio-group>
          </mat-grid-tile>
          <mat-grid-tile
            *ngIf="this.form.get('meeting_notice').value == 'others'"
          >
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Meeting Days</mat-label>
              <input
                type="number"
                min="0"
                required
                matInput
                formControlName="meeting_days"
              />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Meeting Title</mat-label>
              <input
                matInput
                placeholder="Meeting Title"
                formControlName="title"
              />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Venue</mat-label>
              <input matInput placeholder="Venue" formControlName="venue" />
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
        <span class="devarea">Frequency</span>
        <hr />
        <mat-grid-list cols="2" rowHeight="80px">
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-select
                placeholder="Meeting Frequency"
                formControlName="frequency"
              >
                <mat-option
                  *ngFor="let type of TYPE_LIST | keyvalue"
                  [value]="type.key"
                  >{{ type.value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Occurence</mat-label>

              <input
                type="number"
                min="0"
                matInput
                formControlName="occurrence"
              />
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
        <span class="devarea"
          >Date and time
          <mat-slide-toggle formControlName="is_full_day"></mat-slide-toggle>
        </span>
        <hr />
        <mat-grid-list
          [hidden]="!this.form.get('is_full_day').value"
          cols="2"
          rowHeight="100px"
        >
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Start date  and Time</mat-label>
              <input matInput type="datetime-local" disabled formControlName="start_date"
                placeholder="Start Date and Time" [min]="minDate" (change)="onDateRangeChanged('start')"  [max]="form.get('end_date').value" />
              <!-- <input
                matInput
                [matDatepicker]="dp3"
                [min]="this.today"
                disabled
                [required]="this.form.get('is_full_day').value"
                formControlName="start_date"
                placeholder="Start date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dp3"
              ></mat-datepicker-toggle>
              <mat-datepicker #dp3 disabled="false"></mat-datepicker> -->
            </mat-form-field>
          </mat-grid-tile>
          <!-- -------------------------------------- -->
          <!-- <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>Start time </mat-label>
              <input
                [readonly]="true"
                matInput
                formControlName="start_time"
                [required]="this.form.get('is_full_day').value"
                [ngxMatTimepicker]="time"
                disabled
                placeholder="Start time"
              />

              <ngx-mat-timepicker
                class="time"
                #time
                disabled="false"
              ></ngx-mat-timepicker>
            </mat-form-field>
          </mat-grid-tile> -->
          <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>End Date and Time</mat-label>
              <input matInput type="datetime-local" disabled formControlName="end_date" (change)="onDateRangeChanged('end')" placeholder="End Date and Time"
                [min]="form.get('start_date').value" />
              <!-- <mat-label>End date</mat-label>
              <input
                matInput
                [matDatepicker]="dp4"
                disabled
                [min]="this.form.get('start_date').value"
                [required]="this.form.get('is_full_day').value"
                formControlName="end_date"
                placeholder="End date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dp4"
              ></mat-datepicker-toggle> -->
              <!-- <mat-datepicker #dp4 disabled="false"></mat-datepicker> -->
            </mat-form-field>
          </mat-grid-tile>
          <!-- <mat-grid-tile>
            <mat-form-field appearance="outline" class="field-spacer">
              <mat-label>End time </mat-label>
              <input
                [readonly]="true"
                matInput
                formControlName="end_time"
                [ngxMatTimepicker]="time1"
                [required]="this.form.get('is_full_day').value"
                disabled
                placeholder="End time"
              />

              <ngx-mat-timepicker
                class="time"
                #time1
                disabled="false"
              ></ngx-mat-timepicker>
              <mat-error
                *ngIf="
                  this.form.get('end_time')?.errors?.endTimeGreaterThanStartTime
                "
                >End time can not be greater than start time .</mat-error
              >
            </mat-form-field>
          </mat-grid-tile> -->
        </mat-grid-list>
      </mat-card-content>
      <mat-card-actions>
        <div class="row">
          <div class="col col-md-6" align="left"></div>
          <div class="col col-md-6" align="right">
            <button
              mat-raised-button
              color="primary"
              class="btn btn-primary login-btn wide"
              type="submit"
              [disabled]="isSubmit"
            >
              Next
            </button>
            <button mat-raised-button color="clear" class="wide">Cancel</button>
          </div>
        </div>
      </mat-card-actions>
    </mat-card>
  </form>
</div>

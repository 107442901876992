import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { UACService } from '@services/UAC.service';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import { CoreService } from '@services/CoreService';
import { MatMenuTrigger } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export interface ModuleElement {
  moduleId: number;
  module: string;
  group: string;
  icon: string;
  created: string;
  updated: string;
}

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.css'],
})
export class ModuleComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;

  ELEMENT_DATA: ModuleElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = ['module', 'group', 'priority', 'Action'];
  listData = new MatTableDataSource<ModuleElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<ModuleElement>(true, []);

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('moduleTable') table: MatTable<ModuleElement>;

  resource = language;

  constructor(
    private uacservice: UACService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private http: HttpClient,
    private coreservice: CoreService
  ) {
    this.listData = new MatTableDataSource<ModuleElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<ModuleElement>(true, []);
  }

  ngOnInit(): void {
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
    this.getAllModules();
    this.table.renderRows();
  }

  getAllModules() {
    this.uacservice.getModules().subscribe(
      (response) => {
        const result = response.body;
        this.listData.data = response.body['results'] as ModuleElement[];
        console.log(this.listData.data);
        this.table.renderRows();
      },
      (err) => {
        if (err.error.status != null) {
          //          this.errmessage = err.error.message;
        } else {
          //         this.errmessage = err.error.errors[0].msg;
        }
      }
    );
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listData.data.forEach((row) => this.selection.select(row));
  }

  addmodule() {
    this.router.navigate(['/module/add']);
  }

  edit(module) {
    this.router.navigate(['module/add'], { queryParams: { id: module._id } });
  }

  onDelete(id) {
    this.coreservice
      .openConfirmDialog(this.resource.message('MODULE_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.uacservice.removeModule(id).subscribe(
            (response) => {
              const result = response;
              this.getAllModules();
              this.coreservice.success(this.resource.message('MODULE_DELETED'));
            },
            (err) => {}
          );
        }
      });
  }

  closeMyMenu() {
    this.trigger.closeMenu();
  }

  filterData(ev, col, val) {
    if (ev.checked) {
      col = String(col);

      let value = { [col]: val };
      this.filterValues.push(value);
    } else {
      let value = { [col]: val };
      const index = this.filterValues.indexOf(value);
      this.filterValues.splice(index - 1, 1);
    }

    this.setupFilter(col);
    this.applyFilter(val);
  }

  stoppro($event) {
    $event.stopPropagation();
  }

  setupFilter(column: string) {
    this.listData.filterPredicate = (d: any, filter: string) => {
      const val = JSON.parse(filter);
      var flag = false;

      val.forEach(function (item) {
        let obj = item;
        const textToSearch = (d[column] && d[column].toLowerCase()) || '';
        if (obj[column] != undefined) {
          if (
            textToSearch.toLowerCase().indexOf(obj[column].toLowerCase()) !== -1
          ) {
            flag = true;
          }
        }
      });
      return flag;
    };
  }

  applyFilter(filterValue: string) {
    this.listData.filter = JSON.stringify(this.filterValues); // filterValue.trim().toLowerCase();
  }

  filterValues = [];
  convertToPDF(action) {
    const doc = new jsPDF('p', 'mm', 'a4');
    let data = [];
    const head = [['Module', 'Group', 'Priority']];
    this.listData.data.forEach((obj) => {
      let module = obj['module'] ? obj['module'] : '';
      let group = obj['group'] ? obj['group'] : '';
      let priority = obj['priority'] ? obj['priority'] : '';

      let arr = [module, group, priority];
      data.push(arr);
    });

    autoTable(doc, {
      head: head,
      body: data,
      rowPageBreak: 'avoid',
    });

    if (action === 'save') {
      doc.save('modules.pdf');
    } else if (action === 'print') {
      doc.autoPrint();
      doc.output('dataurlnewwindow', { filename: 'modules.pdf' });
    }
  }
}

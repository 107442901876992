import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { UACService } from '@services/UAC.service';
import { HttpClient } from '@angular/common/http';
import { apiendpoints } from '@shared/config/apiendpoints';
import { CoreService } from '@services/CoreService';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { language } from '@shared/config/language';
import * as jsPDF from 'jspdf';
import { constants } from '@shared/config/constants';
import { CommitteeService } from '@services/Committee.service';
import { MatDialog } from '@angular/material/dialog';
import { AddcommitteemembersComponent } from './addcommitteemembers/addcommitteemembers.component';
//import autoTable from 'jspdf-autotable';

export interface MemberElement {
  id: number;
  name: string;
  type: string;
}

@Component({
  selector: 'app-committee-s3',
  templateUrl: './committee-s3.component.html',
  styleUrls: ['./committee-s3.component.css'],
})
export class CommitteeS3Component implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  showFiller = false;

  steps = [];
  isTemplate: boolean = false;
  COMMITEE_ID = '';

  ELEMENT_DATA: MemberElement[];
  searchKey: string;
  rowModelType: 'serverSide';
  rowData: any;
  displayedColumns: string[] = ['name', 'type', 'role', 'Action'];
  listData = new MatTableDataSource<MemberElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<MemberElement>(true, []);

  MEMBERS = [];
  TEMPLATE = 0;

  constants = constants;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('roleTable') table: MatTable<MemberElement>;

  resource = language;

  TYPE_LIST = constants.committee_member_types;
  ROLES_LIST = constants.committee_member_roles;

  constructor(
    private uacservice: UACService,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private http: HttpClient,
    private coreservice: CoreService,
    private activeRoute: ActivatedRoute,
    private committeeService: CommitteeService,
    private dialog: MatDialog
  ) {
    this.listData = new MatTableDataSource<MemberElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<MemberElement>(true, []);

    console.log(language.message('a'));
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.template != undefined) {
        this.TEMPLATE = data.template;
        if (this.TEMPLATE == 1) this.isTemplate = true;
        else this.isTemplate = false;
      }

      if (this.isTemplate) {
        this.steps = constants.committee_template_steps;
      } else {
        this.steps = constants.committee_steps;
      }

      if (data.id != undefined) {
        this.COMMITEE_ID = data.id;

        this.listData.paginator = this.paginator;
        this.listData.sort = this.sort;
        this.changeDetectorRefs.detectChanges();
        this.getAllRoles();
        this.table.renderRows();
      } else {
        this.router.navigate(['committee']);
      }
    });
  }

  getAllRoles() {
    this.committeeService
      .getCommittee(this.COMMITEE_ID)
      .subscribe((response) => {
        const result = response.body;
        this.listData.data = response.body['data'][
          'members'
        ] as MemberElement[];
        this.MEMBERS = response.body['data']['members'] as MemberElement[];
        this.table.renderRows();
      });
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyfilter();
  }
  applyfilter() {
    this.listData.filterPredicate = function (data, filter) {
      var dataStr = Object.keys(data)
        .reduce(function (currentTerm, key) {
          return currentTerm + data[key] + '◬';
        }, '')
        .toLowerCase();
      var transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) != -1;
    };
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listData.data.forEach((row) => this.selection.select(row));
  }

  onDelete(id) {
    this.coreservice
      .openConfirmDialog(this.resource.message('MEMBER_DELETE'))
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.MEMBERS = this.MEMBERS.filter((obj) => obj !== id);
          this.listData.data = this.MEMBERS;
          this.table.renderRows();

          this.committeeService
            .addCommittee({ members: this.MEMBERS, _id: this.COMMITEE_ID })
            .subscribe(
              (response) => {
                const result = response.body;
                constants.CURRENT_COMMITTEE = result['data'];
              },
              (err) => {
                console.log(err);
                this.coreservice.error(
                  'Invalid Committee - ' + err.error.message
                );
              }
            );
        }
      });
  }

  closeMyMenu() {
    this.trigger.closeMenu();
  }

  filterData(ev, col, val) {
    if (ev.checked) {
      col = String(col);

      let value = { [col]: val };
      this.filterValues.push(value);
    } else {
      let value = { [col]: val };
      const index = this.filterValues.indexOf(value);
      this.filterValues.splice(index - 1, 1);
    }

    this.setupFilter(col);
    this.applyFilter(val);
  }

  stoppro($event) {
    $event.stopPropagation();
  }

  setupFilter(column: string) {
    this.listData.filterPredicate = (d: any, filter: string) => {
      const val = JSON.parse(filter);
      var flag = false;

      val.forEach(function (item) {
        let obj = item;
        const textToSearch = (d[column] && d[column].toLowerCase()) || '';
        if (obj[column] != undefined) {
          if (
            textToSearch.toLowerCase().indexOf(obj[column].toLowerCase()) !== -1
          ) {
            flag = true;
          }
        }
      });
      return flag;
    };
  }

  applyFilter(filterValue: string) {
    this.listData.filter = JSON.stringify(this.filterValues); // filterValue.trim().toLowerCase();
  }

  filterValues = [];

  /*print(){
    let doc = new jsPDF(); 
    doc.autoTable({
      head: [['NAme','approved','utilised', 'available','asd','sadadasada','asdas']],
      body: this.dataSource.filteredData //returning [["log1", "$100"], ["log2", "$200"]]
    });
    doc.save('table.pdf')
  }
*/

  addMember(msg, actionbutton, cancelbutton) {
    let dia = this.dialog.open(AddcommitteemembersComponent, {
      width: '40vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        committeeId: this.COMMITEE_ID,
        members: this.MEMBERS,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
      },
    });

    dia.afterClosed().subscribe((result) => {
      this.getAllRoles();
      this.table.renderRows();
    });
  }

  previous() {
    console.log('committee/add/s1');
    this.router.navigate(['committee/add/s2'], {
      queryParams: { id: this.COMMITEE_ID, template: this.TEMPLATE },
    });
  }

  next() {
    const chairmandata = this.MEMBERS.filter(
      (commitee) => commitee.committee_role == 'chairman'
    );
    const secretarydata = this.MEMBERS.filter(
      (commitee) => commitee.committee_role == 'secretary'
    );
    if (secretarydata.length < 1) {
      this.coreservice.success(
        this.resource.message('COMMITTEE_MEMBER_MESSAGE')
      );
    } else if (chairmandata.length < 1) {
      this.coreservice.success(
        this.resource.message('COMMITTEE_MEMBER_MESSAGE_CHMN')
      );
    }
     else {
      this.router.navigate(['committee/add/s4'], {
        queryParams: { id: this.COMMITEE_ID, template: this.TEMPLATE },
      });
    }
    console.log(chairmandata);
    // this.router.navigate(['committee/add/s4'], {
    //   queryParams: { id: this.COMMITEE_ID, template: this.TEMPLATE },
    // });
  }
}

<div class="content p30">
  <div id="block_container">
    <a
      routerLink="/outcome"
      id="bloc1"
      [queryParams]="{ id: parentid }"
      mat-flat-button
      class="mat-flat-button-transperent"
      ><span class="material-icons">keyboard_backspace</span></a
    >
    <div id="bloc2">
      <h5>{{ resource.message("IDP") }} / Create Meeting</h5>
    </div>
  </div>
  <form [formGroup]="form">
    <fieldset>
      <mat-card class="">
        <mat-card-content>
          <span class="devarea">Add Outcome</span>
          <br />
          <hr />
          <div class="row maindiv">
            <div class="row">
              <div class="meetingcol">{{title}}</div>
            </div>
            <div class="row">
              <div class="col-md-3 firstcol">Start Date and Time</div>
              <div class="col-md-3 firstcol">End Date and Time</div>
              <div class="col-md-2 firstcol">Participants</div>
              <div class="col-md-2 firstcol">Venue</div>
              <div class="col-md-2 firstcol">Actions</div>
              <div class="col-md-3 secondcol">
                {{ start_date | customDate }} {{ start_time }}
              </div>
              <div class="col-md-3 secondcol">
                {{ end_date | customDate }} {{ end_time }}
              </div>
              <div class="col-md-2 secondcol1">
                <button
                  mat-button
                  color="primary"
                  (click)="openparticipants('', '', '')"
                >
                  View Invitees
                </button>
              </div>
              <div class="col-md-2 secondcol">{{ venue }}</div>
              <div class="col-md-2 secondcol">
                <button mat-icon-button disabled="true">
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </div>
            </div>
          </div>

          <br />
          <div class="row maindiv">
            <div class="row">
              <div class="meetingcol">Agenda</div>
            </div>
            <div class="row wholerow" *ngFor="let topic of topics; index as i">
              <div class="col-md-6 firstcol">
                <div class="row">
                  {{
                    topic.idp && topic.idp.category === "Topic"
                      ? topic.idp.category
                      : "Development"
                  }}
                </div>
                <div class="row">
                  <strong style="color: black" *ngIf="topic.idp">{{
                    topic.idp.description
                  }}</strong>
                </div>
              </div>
              <div class="col-md-6 firstcol">
                <button
                  mat-stroked-button
                  type="button"
                  class="wide1"
                  (click)="outcome_tab(topic, i)"
                >
                  View Outcome
                </button>
              </div>
              <div class="col-md-12 newcomp" *ngIf="showView === i">
                <app-outcometab
                  [parentid]="parentid"
                  [agendaid]="agendaid"
                  [viewdata]="viewdata"
                ></app-outcometab>
              </div>
            </div>
          </div>
          <br />
          <br />
          <form [formGroup]="form">
            <fieldset>
              <!--[disabled]=" (familyname!=select_user_family) ? isDisabled :''"-->

              <mat-grid-list cols="2" rowHeight="100px">
                <mat-grid-tile>
                  <mat-form-field appearance="outline" class="field-spacer">
                    <mat-label>Meeting</mat-label>
                    <input
                      matInput
                      placeholder="Meeting "
                      required
                      formControlName="title"
                    />
                  </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile>
                  <div
                    class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored"
                  >
                    <button
                    (click)="generatePdf()"
                    mat-stroked-button
                    type="button"
                    class="wide"
                  >
                    MEETING-MINUTES.pdf
                  </button>
                    <!-- <mat-label>MOM Pdf</mat-label> -->
                    <!-- <input
                      name="File1"
                      placeholder="MOM url"
                      type="file"
                      (change)="onFileChange($event)"
                      formControlName="file"
                    /> -->
                    <!-- <a
                      class="input-preview-btn"
                      *ngIf="url != '' && url != null"
                      (click)="previewImg()"
                    >
                      Preview</a
                    > -->
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
              <!-- </mat-card-content>

                <mat-card-actions align="end">
                  <button
                    mat-raised-button
                    color="primary"
                    class="btn btn-primary login-btn button-wider"
                    type="button"
                    (click)="submit()"
                  >
                    Save
                  </button>
                </mat-card-actions>
              </mat-card> -->
            </fieldset>
          </form>
          <!-- <mat-grid-list cols="2" rowHeight="100px" class="row maindiv">
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Minutes </mat-label>
                <input
                  matInput
                  placeholder="Minutes Pdf"
                  required
                  formControlName="filename"
                />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <input
                  name="File1"
                  placeholder="Copy paste url link"
                  type="file"
                  (change)="onFileChange($event)"
                  formControlName="file"
                />
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list> -->
        </mat-card-content>

        <span class="blankline"></span>
        <div class="form-group mr-2">
          <button
            mat-stroked-button
            type="button"
            class="wide"
            routerLink="/outcome"
            [queryParams]="{ id: parentid }"
          >
            PREVIOUS
          </button>
        </div>
        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="button"
            (click)="submit()"
            [disabled]="!form.valid"
          >
            Publish MoM
          </button>
        </mat-card-actions>
      </mat-card>
    </fieldset>
  </form>
</div>

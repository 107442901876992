<div class="content p30">
  <div id="block_container">
    <ng-container>
      <a
        *ngIf="!offlineURL"
        routerLink="/committee/participants"
        [queryParams]="{ id: parentId }"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
      <a
        *ngIf="offlineURL"
        routerLink="/committee/rationalize"
        [queryParams]="{ id: parentId }"
        id="bloc1"
        mat-flat-button
        class="mat-flat-button-transperent"
        ><span class="material-icons">keyboard_backspace</span></a
      >
    </ng-container>
    <div id="bloc2">
      <h5>
        <h5>
          Committee Meetings / {{ endpointResults?.committee?.title }} /
              {{ endpointResults?.title }}
        </h5>
      </h5>
    </div>
  </div>
  <form [formGroup]="form">
    <fieldset>
      <mat-card class="">
        <mat-card-content>
          <div class="devarea">
            <span *ngIf="!offlineURL">Offline Agenda</span>
            <span *ngIf="offlineURL">Offline MOM</span>
          </div>
          <hr />
          <div class="row cancel">
            <button type="button" (click)="cancelMeeting()" mat-stroked-button>
              Cancel Meeting
            </button>
          </div>
          <div class="row maindiv">
            <div class="row">
              <div class="meetingcol">{{ endpointResults?.title }}</div>
            </div>

            <div class="row">
              <div class="col-md-3 firstcol">Start Date and Time</div>
              <div class="col-md-3 firstcol">End Date and Time</div>
              <div class="col-md-2 firstcol">Participants</div>
              <div class="col-md-2 firstcol">Venue</div>
              <div class="col-md-2 firstcol">Actions</div>
              <div class="col-md-3 secondcol">
                {{ start_date | customDate }}
              </div>
              <div class="col-md-3 secondcol">{{ end_date | customDate }}</div>
              <div class="col-md-2 secondcol1">
                <button
                 mat-button
                  color="primary"
                  (click)="openparticipants('', '', '')"
                >
                  View Invitees
                </button>
              </div>
              <div class="col-md-2 secondcol">{{ venue }}</div>
              <div class="col-md-2 secondcol">
                <button mat-icon-button routerLink="/createmeeting">
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </div>
            </div>
            
            <div class="row thirdrow upload-file" *ngIf="!offlineURL">
              <h6>Agenda</h6>
              <div style="margin: 20px;">
              <input
                name="File1"
                placeholder="MOM url"
                type="file"
                (change)="onFileChange($event)"
                formControlName="file"
              />
              <a *ngIf="endpointResults?.agendas[0]?.pre_reads" [href]="endpointResults.agendas[0].pre_reads" target="_blank">PREVIEW</a>
            </div>
              <!-- <mat-grid-list cols="2" rowHeight="100px">
                <mat-grid-tile>
                  <div
                    class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored"
                  >
                    <mat-label>Upload Agenda</mat-label>
                    <input
                      name="File1"
                      placeholder="MOM url"
                      type="file"
                      (change)="onFileChange($event)"
                      formControlName="file"
                    />
                    
                    
                      <a
                      class="input-preview-btn"
                      *ngIf="url != '' && url != null"
                      (click)="previewImg()"
                    >
                      Preview</a
                    >
                  </div>
                </mat-grid-tile>
              </mat-grid-list> -->
            </div>
            
            <div class="row thirdrow upload-file" *ngIf="offlineURL">
              <div style="margin: 20px;"><h6>Uploaded Agenda</h6><a *ngIf="endpointResults?.agendas[0]?.pre_reads" [href]="endpointResults.agendas[0].pre_reads" target="_blank">PREVIEW</a></div>
              <div style="margin: 20px;">
              <h6>Upload Offline MOM</h6>
              <input
                name="File2"
                placeholder="MOM url"
                type="file"
                (change)="onMOMFileChange($event)"
                formControlName="file"
              />
              <a *ngIf="endpointResults?.mom" [href]="endpointResults.mom" target="_blank">PREVIEW</a>
            </div>
            </div>
            
          </div>
          
          <fieldset>
            <!--[disabled]=" (familyname!=select_user_family) ? isDisabled :''"-->

            <!-- <mat-grid-list cols="2" rowHeight="100px">
              <mat-grid-tile>
                <div
                  class="field-spacer mat-input-element mat-form-field-autofill-control ng-tns-c123-1 ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored"
                >
                  <mat-label>Upload Agenda</mat-label>
                  <input
                    name="File1"
                    placeholder="MOM url"
                    type="file"
                    (change)="onFileChange($event)"
                    formControlName="file"
                  />
                  
                    
                      <a
                      class="input-preview-btn"
                      *ngIf="url != '' && url != null"
                      (click)="previewImg()"
                    >
                      Preview</a
                    >
                </div>
              </mat-grid-tile>
            </mat-grid-list> -->
           
          </fieldset>
        </mat-card-content>
       
        <span class="blankline"></span>
        <div class="form-group mr-2">
          <button mat-stroked-button type="button" [queryParams]="{ id: parentId }" class="wide" *ngIf="!offlineURL" routerLink="/committee/participants">
            PREVIOUS
          </button>
          <button mat-stroked-button type="button" class="wide" [queryParams]="{ id: parentId }" *ngIf="offlineURL" routerLink="/committee/rationalize">
            PREVIOUS
          </button>
        </div>
        <mat-card-actions align="end" *ngIf="!offlineURL">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            (click)="submit()"
          >
            Publish Offline Agenda
          </button>
        </mat-card-actions>
        <mat-card-actions align="end" *ngIf="offlineURL"> 
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            (click)="submitMOM()"
          >
            Publish Offline MOM
          </button>
        </mat-card-actions>
      </mat-card>
    </fieldset>
  </form>
</div>

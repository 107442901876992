import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IdpService } from '@services/Idp.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommitteeService } from '@services/Committee.service';

@Component({
  selector: 'app-dialogoverride',
  templateUrl: './dialogoverride.component.html',
  styleUrls: ['./dialogoverride.component.css'],
})
export class DialogoverrideComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  notes: string;
  labelText: string;
  hidebtn: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogoverrideComponent>,
    private idp: IdpService,
    private router: Router,
    private committeeService: CommitteeService
  ) {}

  ngOnInit(): void {
    this.labelText = this.data.message;
  }

  doneClick() {
    this.committeeService
      .getCommitteeoverride(this.data.meeting, this.data.agendaId)
      .subscribe(
        (response: any) => {
          this.dialogRef.close(false);
        },
        (err) => {
          console.log('errrrrrrr', err);
        }
      );
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
  getMeetings(id) {
    //alert('IN');
    var filter = '';
    filter = '?filter[_id]=' + id; //+this.localstoragedata._id
    this.idp.idpMeetingGet(filter).subscribe(
      (response: any) => {
        console.log(response.data[0].rationalize_notes);
        this.notes = response.data[0].rationalize_notes;
        this.hidebtn = true;
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
}

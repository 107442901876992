import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { language } from '@shared/config/language';
import { CommitteeService } from '@services/Committee.service';
import {
  FormGroup,
  FormControl,
  FormControlName,
  Validator,
  Validators,
  FormArray,
  FormBuilder,
} from '@angular/forms';
import { constants } from '@shared/config/constants';
import { CoreService } from '@shared/services/CoreService';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogtemporaryinviteesComponent } from '@shared/components/dialogtemporaryinvitees/dialogtemporaryinvitees.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthService } from '@services/Auth.service';
import { DialogViewInviteeListComponent } from '@shared/components/dialog-view-invitee-list/dialog-view-invitee-list.component';
import { environment } from '@environments/environment';

export interface MeetingElement {
  name: string;
  type: string;
  meeting_role: string;
}

@Component({
  selector: 'app-meeting-participants',
  templateUrl: './meeting-participants.component.html',
  styleUrls: ['./meeting-participants.component.css'],
})
export class MeetingParticipantsComponent implements OnInit {
  resource = language;
  COMMITTEE_LIST: any;
  displayedColumns: string[] = ['name', 'type', 'meeting_role', 'Action'];
  ELEMENT_DATA: MeetingElement[];
  listData = new MatTableDataSource<MeetingElement>();
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<MeetingElement>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('MeetingsTable') table: MatTable<MeetingElement>;
  PERMISSION_EDIT = AuthService.authentication('CommitteeMeeting', 'READ');
  PERMISSION_ADD = AuthService.authentication('CommitteeMeeting', 'ADD');
  PERMISSION_DELETE = AuthService.authentication('CommitteeMeeting', 'DELETE');
  parentId: string;
  participantsId = [];
  showInvitee: boolean = false;
  isEdit: boolean = false;
  endpointResults: any;
  allMembers = [];
  checkMemberUpdate = false;
  members = [];
  temporary: any = [];
  mail: any;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private coreservice: CoreService,
    private activeRoute: ActivatedRoute,
    private changeDetectorRefs: ChangeDetectorRef,
    private commiteeService: CommitteeService
  ) {}

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((data) => {
      if (data.id != undefined) {
        this.parentId = data.id;
        this.getAllCommiteeMeetings(this.parentId);
      }
    });
    this.listData.paginator = this.paginator;
    this.listData.sort = this.sort;
    this.changeDetectorRefs.detectChanges();
  }

  openDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogtemporaryinviteesComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
        ids: this.participantsId,
        specialInvitee: this.temporary,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('>><<<<>>>>', result);
      if (result.special != '') {
        this.showInvitee = true;
        this.temporary = result.special;
        this.mail = result.mail;
      }
      if(this.showInvitee == true){
        this.allMembers=this.allMembers.filter(x=>x.type!='Special Invitee');
        this.temporary.map((x,i)=>{
          this.allMembers.push({
            committee_role: 'Special Invitee',
            id: i,
            mobile: x.email,
            name: x.name,
            profile_photo: '',
            role: 'Guest',
            type: 'Special Invitee',
          });
        })
        // this.temporary=[];
      }
      for (let i = 0; i < result.data.length; i++) {
        //  result.data[i].committee.members[i].type = 'Permanent Member';
        this.participantsId.push({
          id: result.data[i].user_id,
        });
        this.allMembers.push({
          committee_role: result.data[i].role,
          id: result.data[i].user_id,
          mobile: result.data[i].mobile,
          name: result.data[i].name + ' ' + result.data[i].lastname,
          profile_photo: result.data[i].profile_photo,
          role: result.data[i].role,
          type: 'Temporary Invitee',
        });
        this.members.push({
          committee_role: result.data[i].role,
          id: result.data[i]._id,
          mobile: result.data[i].mobile,
          name: result.data[i].name + '' + result.data[i].lastname,
          profile_photo: result.data[i].profile_photo,
          role: result.data[i].role,
          type: 'Temporary Invitee',
        });
      }
      console.log('::::::::::::', this.allMembers);
      sessionStorage.setItem(this.parentId, 'true')
      this.listData.data = this.allMembers;
      this.table.renderRows();
      this.changeDetectorRefs.detectChanges();
    });
  }
  // Fetch committee meetings
  getAllCommiteeMeetings(id) {
    var filter = '?filter[type]=committee&filter[_id]=' + id;

    this.commiteeService.getCommitteeMeetings(filter).subscribe(
      (response: any) => {
        const result = response;
        this.endpointResults = result.data[0];
        console.log(result);
        for (let i = 0; i < result.data[0].committee.members.length; i++) {
          result.data[0].committee.members[i].type = result.data[0].committee.members[i].committee_role === '' ? 'Permanent Invitee' : 'Permanent Member';
          console.log(result.data[0].committee.members[i]);

          this.participantsId.push({
            id: result.data[0].committee.members[i].id,
          });
          this.allMembers.push(result.data[0].committee.members[i]);
        }
        this.temporary = [...result.data[0]['temporary_members'].members];
        this.temporary.map((x,i)=>{
          this.allMembers.push({
            committee_role: 'Special Invitee',
            id: i,
            mobile: x.email,
            name: x.name,
            profile_photo: '',
            role: 'Guest',
            type: 'Special Invitee',
          });
        })
        this.mail = { ...result.data[0]['temporary_members'] };
        delete this.mail.temporary_members;
        if (this.temporary.length >= 1) {
          this.showInvitee = true;
        }
        const temp = [];
        result.data[0].members.forEach((user) => {
          const index = result.data[0].committee.members.findIndex(val => val.id === user.id);
          if(index === -1) {
            this.participantsId.push({
              id: user.id,
            });
            temp.push({ ...user, type:'Temporary Invitee', committee_role: ''});
          }
        });

        this.allMembers = [...this.allMembers, ...temp];
        this.members = [...this.members, ...temp];
        
        this.listData.data = this.allMembers;
        this.table.renderRows();
        console.log("mail", this.mail)
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
  }
  openViewInviteesDialog() {
    console.log('openViewInviteesDialog');

    const dialogRef = this.dialog.open(DialogViewInviteeListComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        temporary_members: this.temporary,
        actionbutton: '',
        cancelbutton: '',
      },
    });
    // dialogRef.afterClosed().subscribe((result) => {
    //   console.log(result);
    //   if (result.users) this.specialInvitee = result.users;
    // });
  }
  onSubmit() {
    let url = 'committee/agenda';
    if(this.endpointResults.mom && 
      this.endpointResults.mom.startsWith(environment.webservices+'/core/media/publicdownload?') &&
      this.endpointResults.members.filter(val => val.invite_status === 'pending').length === this.endpointResults.members.length) {
      url = 'committee/rationalize'
    }
    const meetingId = sessionStorage.getItem(this.parentId);
    if(!meetingId && this.endpointResults.members.length) {
      this.router.navigate([url], {
        queryParams: {
          id: this.parentId,
        },
      });
      sessionStorage.removeItem(this.parentId);
      return;
    }
    const req = [];
    const data = [];
    
    if (this.showInvitee && this.mail) {
      req['temporary_members'] = {
        subject: this.mail.subject,
        mailbody: this.mail.message,
        attachment: this.mail.file_url,
        members: this.temporary,
      };
    }
    if (this.allMembers && this.allMembers.length) {
      req['members'] = this.allMembers.map(val => ({
        committee_role : val.committee_role || 'Invitee',
        id : val.id,
        invite_status : val.invite_status,
        mobile : val.mobile,
        name : val.name,
        profile_photo : val.profile_photo,
        role : val.role
      }));
    }
    req['meeting_status'] = "invite_sent"
    req['members'] = req['members'].filter(val => val.committee_role!=='Special Invitee');
    // data.push(this.members)
    console.log('Invitee', req);

    this.commiteeService.committeeParticipantsAdd(req, this.parentId).subscribe(
      (response: any) => {
       sessionStorage.removeItem(this.parentId);
        this.coreservice.success(this.resource.message('INVITE'));
        console.log(response.data._id);

        this.router.navigate([url], {
          queryParams: {
            id: this.parentId,
          },
        });
      },
      (err) => {
        console.log('errrrrrrr', err);
      }
    );
    // this.router.navigate(['/agenda'], {
    //   queryParams: {},
    // });
  }
  previous() {
    this.router.navigate(['committee/meetings'], {
      queryParams: { id: this.parentId },
    });
  }

  onDelete(id) {
    this.commiteeService
      .deleteMeetingInviteeById(this.parentId, id)
      .subscribe((res) => {
        console.log('id ', id);

        console.log(res);
        sessionStorage.setItem(this.parentId, 'true')
        this.coreservice.success('Invitee deleted sucessfullly!');
        this.allMembers = this.allMembers.filter((user) => {
          return user.id !== id;
        });

        this.listData.data = this.allMembers;
        this.table.renderRows();
        this.changeDetectorRefs.detectChanges();

        this.participantsId = this.participantsId.filter((user) => {
          return user.id !== id;
        });
      });
  }
}

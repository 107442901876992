import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, FormControlName,Validator, Validators, FormBuilder, FormArray, NgForm } from '@angular/forms';
import { UserService } from '@services/UserService';
import { ActivatedRoute, Router } from '@angular/router';
import {CoreService} from '../../../shared/services/CoreService';
import { UACService } from '@services/UAC.service';
import { constants } from '@shared/config/constants';
import { language } from '@shared/config/language';
import { MembershipService } from '@services/Membership.service';


@Component({
  selector: 'app-addmembership',
  templateUrl: './addmembership.component.html',
  styleUrls: ['./addmembership.component.css']
})
export class AddmembershipComponent  implements OnInit {

 
rolename = "";
category = new FormControl('personal');
todayDate:Date = new Date();

  isEdit:Boolean = false;
  form :FormGroup = new FormGroup({
    _id:new FormControl(0),
    user: new FormControl(0),
    name:new FormControl('',Validators.required),
    start_date:new FormControl('', Validators.required),
    valid_till:new FormControl('', Validators.required),
    description:new FormControl(''),
    subtype:new FormControl(''),
    type: new FormControl(this.category.value,Validators.required),
  }, { validators : this.dateLessThan('start_date', 'valid_till')})

 


 
  ADVISOR_LIST:any;
  ROLES_LIST:any;
  CONSTANT = constants;
  resource = language;
  labelText:String='Add Member';  

  PRIORITY = constants.task_priority;
  STATUS = constants.task_status;

  constructor(private userservice: UserService, private router : Router, private membershipService: MembershipService,
    private activeRoute: ActivatedRoute,
    private coreservice: CoreService,
    private fb: FormBuilder
   
    ) {
 
      console.log(this.CONSTANT.task_status)

       this.getAllAdvisors();

    this.activeRoute.queryParams.subscribe(data => {

      if(data.id!=undefined)
      {
        this.labelText='Edit Task'

        this.membershipService.getMembership(data.id).subscribe(response=> {
      
          const result = response.body["data"];
        
          
           this.category.setValue(result.type);


          this.form.patchValue({
            _id:result._id,
            user:result.user._id,
            name:result.name,
            start_date:result.start_date,
            valid_till:result.valid_till,
            description:result.description,
            subtype:result.subtype,
            type:result.type             
            
          })

            this.isEdit = true;
            console.log(this.form);
        });
      } 
    })

   }

 

  ngOnInit(): void {
  

  }
  

getAllAdvisors()
{

  this.userservice.getFamilyUses().subscribe(response=> {
    const result = response.body;
    this.ADVISOR_LIST =response.body['data'];
   }, (err) => {

   })

}

dateLessThan(from: string, to: string) {
  return (group: FormGroup): {[key: string]: any} => {
   let f = group.controls[from];
   let t = group.controls[to];
   if (f.value > t.value) {
     t.setErrors({ endTimeGreaterThanStartTime: true });
     return {
       dates: "Start Date should be less than end Date"
     };
   }
   t.setErrors(null);
  }
}

onChange(obj):void{
 
    this.form.patchValue({
      "is_full_day":obj.checked
    })
}

  submit():void{ 

    console.log(this.form)
    if (this.form.invalid) {
        return;
    }

    var rawuser = this.form.getRawValue();

   
    if(!this.isEdit) {
      this.form.removeControl('_id');
      rawuser = this.form.getRawValue();
    }


    this.membershipService.addMembership(rawuser).subscribe(response=> {
      
      const result = response.body;
   
      this.coreservice.success("Membership saved successfully");
     this.router.navigate(['membership']);

     }, (err) => {


      console.log(err.error.errors)
      var errormessages = "Please correct the errors - \n";
      err.error.errors.forEach(element => errormessages += " - "+element.msg+"\n");

     // err.error.errors.forEach(element =>  this.coreservice.error(element.msg));

      
      this.coreservice.error(errormessages);
      this.form.addControl('_id', new FormControl(''));

      
   })
   
  }
     

 
 
}


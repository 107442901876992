import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { UserService } from '@services/UserService';
import { FormControl,FormControlName,FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  constructor(private _location: Location,
    private userservice: UserService,
    private router :Router,
    private _snackBar: MatSnackBar) { }
    form :FormGroup = new FormGroup({
      new_password:
      new FormControl('',(Validators.required,Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}'))
      ),
      confirmPassword: new FormControl('',Validators.required)
    })

  ngOnInit(): void {
  }

  backClicked() {
    this._location.back();
  }
  checkPassword()
  {
    if(this.form.value['new_password']===this.form.value['confirmPassword'])
    {
      this.submit()
    }
    else
    {
      this._snackBar.open('Password and Confirm Password needs to be same ', 'Done');
    }
  }
   submit(){ 
    if (this.form.invalid) {return;}
   // if(this.checkPassword()){return;}
    var rawuser = this.form.getRawValue();
     delete rawuser['confirmPassword']
     rawuser["new_password"]=this.form.value['new_password'];
     this.userservice.changePassword(rawuser).subscribe(response=> {
      const result = response.body;
      this._location.back();
      }, (err) => {
        var errormessages = "Please correct the errors - \n";
        err.error.errors.forEach(element => errormessages += ","+element.msg+'\n');
      })
  }
}

<div align="right">
  <div class="dialogcontent-container">
    <mat-card class="cardclass">
      <mat-card-header>
        <mat-card-title>
          {{ labelText }}
        </mat-card-title>
        <mat-icon id="close-icon" (click)="closeDialog()"> close</mat-icon>
      </mat-card-header>
      <hr />
      <form [formGroup]="form" (ngSubmit)="submitData()">
        <mat-card-content>
          <mat-grid-list cols="2" rowHeight="100px">
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Agenda Title</mat-label>
                <input
                  matInput
                  placeholder="Agenda Title"
                  formControlName="title"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <input type="hidden" formControlName="_id" />
              <div class="category-type">
              <mat-label>Category Type : </mat-label>
              <mat-radio-group
                appearance="outline"
                class="smallRadio"
                [formControl]="file_type"
              >
                
                <mat-radio-button
                  *ngIf="data.type=='is_consensus' || data.type=='is_hybrid'"
                  value="consensus"
                  [checked]="file_type.value == 'consensus'"
                  (change)="this.form.patchValue({ resolution: 'consensus' })"
                  class="m20"
                  ><span>Consensus</span>
                </mat-radio-button>
                &nbsp;&nbsp;&nbsp;
                <mat-radio-button
                *ngIf="data.type=='is_voting' || data.type=='is_hybrid'"
                  value="voting"
                  [checked]="file_type.value == 'voting'"
                  (change)="this.form.patchValue({ resolution: 'voting' })"
                  class="m20"
                  >Voting</mat-radio-button
                >
              </mat-radio-group>
            </div>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer">
                <mat-label>Description</mat-label>
                <textarea
                  matInput
                  placeholder="Description Name"
                  formControlName="description"
                ></textarea>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile></mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field appearance="outline" class="field-spacer" >
                <mat-label>Pre-Reads</mat-label>
                <div class="d-flex"><mat-select
                  (click)="$event.stopPropagation()"
                  style="flex-basis: 30%"
                  formControlName="protocol"
                >
                <mat-option value="http://"
                >http://</mat-option
                ><mat-option value="https://"
                >https://</mat-option
              >
                </mat-select>
                <input
                  (click)="$event.stopPropagation()"
                  (focusout)="updatepreReads()"
                  matInput
                  placeholder="Paste Pre Reads url"
                  formControlName="pre_reads"
                />
              </div>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="submit"
            *ngIf="isEdit"
          >
            Save
          </button>
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary login-btn button-wider"
            type="submit"
            *ngIf="!isEdit"
          >
            Add
          </button>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
</div>

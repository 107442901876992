<div class="content p30">
  <h5>
    Committee Meetings / {{ endpointResults?.committee?.title }} /
        {{ endpointResults?.title }}
  </h5>
  <mat-card class="">
    <mat-card-content *ngIf="show">
      <span class="devarea">Add Agenda</span>
      <hr />
      <mat-tab-group class="tabgroup">
        <mat-tab label="Agenda" class="test">
          <app-committeeagenda
            [type]="type"
            [_topics]="topics"
            [isRationalized] = "endpointResults?.rationalize_notes ? true : false"
            (reload)="onReload()"
          ></app-committeeagenda>
        </mat-tab>
        <mat-tab label="Deferred Agenda" class="test">
          <app-agendadeferred
          [type]="type"
            [defered_topics]="defered_topics"
            (reload)="onReload()"
          ></app-agendadeferred>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>

import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-dialogdelete',
  templateUrl: './dialogdelete.component.html',
  styleUrls: ['./dialogdelete.component.css']
})
export class DialogdeleteComponent implements OnInit {

  

  
  constructor( @Inject(MAT_DIALOG_DATA) public data,
  public dialogRef : MatDialogRef<DialogdeleteComponent>) { }

  ngOnInit(): void {
  }

  closeDialog()
  {
    this.dialogRef.close(false)
  }

}

<div class="container-fluid">
  <div class="row">
    <div class="col-sm-7">
      <div *ngIf="!emailExists" class="main-div align-middle">
        <h3 class="bold-text">Password Recovery</h3>
        <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="form-group">
            <label class="login-label"> Email</label>
            <input type="text" formControlName="email" class="form-control" />
            <div
              class="alert alert-danger"
              role="alert"
              *ngIf="errorMessage"
              align="center"
            >
              {{ errorMessage }}
            </div>
          </div>
          <button
            [disabled]="form.invalid"
            class="btn btn-primary login-btn"
            type="submit"
          >
            LOGIN
          </button>
        </form>

        <div class="backtologin">
          <button type="button" class="btn btn-primary back-login-btn">
            <a href="index.html">
              <i class="fa fa-arrow-left"></i> &nbsp; Back to Login
            </a>
          </button>
        </div>
      </div>
      <div *ngIf="emailExists" class="main-div align-middle">
        <div
          style="padding: 8px"
          class="alert alert-success"
          role="alert"
          align="center"
        >
          Your password recovery email has been sent to
          {{ form.get("email").value }}
        </div>
      </div>
    </div>
    <div class="col-sm-5">
      <div class="login-bg">
        <img src="../../../assets/images/fb-logo-white.png" class="fb-logo" />
      </div>
    </div>
  </div>
</div>
<!--container-fluid-->

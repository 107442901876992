import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormArray,
  FormBuilder,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { language } from '@shared/config/language';
import { DialogemailinviteComponent } from '@shared/components/dialogemailinvite/dialogemailinvite.component';

@Component({
  selector: 'app-dialogmeetingparticipants',
  templateUrl: './dialogmeetingparticipants.component.html',
  styleUrls: ['./dialogmeetingparticipants.component.css'],
})
export class DialogmeetingparticipantsComponent implements OnInit {
  resource = language;
  form: FormGroup = new FormGroup({
    _id: new FormControl(''),
    participantname: new FormControl('', Validators.required),
    participantemail: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    temporary_members: this.fb.array([this.createTemp()]),
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DialogmeetingparticipantsComponent>,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    if (this.data.temporary_members.length !== 0) {
      this.temporary_members.clear();
    }
    this.data.temporary_members.forEach((user) => {
      this.temporary_members.push(
        this.fb.group({
          name: new FormControl(user.name, Validators.required),
          email: new FormControl(user.email, [
            Validators.required,
            Validators.email,
          ]),
        })
      );
    });
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  onSubmit() {
    console.log(this.form.get('temporary_members').value);
    this.openDialog('', '', '');
  }
  openDialog(msg, actionbutton, cancelbutton) {
    const dialogRef = this.dialog.open(DialogemailinviteComponent, {
      width: '50vw',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
        message: msg,
        actionbutton: actionbutton,
        cancelbutton: cancelbutton,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      const userData = {
        mail: result,
        users: this.temporary_members.getRawValue(),
      };
      console.log(userData);
      this.dialogRef.close(userData);
      console.log(result);
    });
  }
  createTemp(): FormGroup {
    return this.fb.group({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }
  get temporary_members() {
    return this.form.get('temporary_members') as FormArray;
  }
  addSocial() {
    this.temporary_members.push(
      this.fb.group({
        name: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, Validators.email]),
      })
    );
  }

  deleteTemp(index) {
    this.temporary_members.removeAt(index);
  }
}
